<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <TopHeader class="top_header" title="마이페이지" @goBack="goBack" />
    <div class="contents">
      <div class="contents_header">
        <div class="my_info">
          <div v-if="appVersion" class="user_setting_wrap">
            <a href="javascript:void(0)" title="설정" @click="goSetting" v-if="userId">설정</a>
            <a href="javascript:void(0)" title="본인인증하기" @click="goAuthentication" v-else>회원가입하기</a>
          </div>
          <div v-if="userId">
            <div class="user_name">{{ userInfo.memberName }}</div>
            <div class="user_phone">{{ userInfo.phoneNumber }}</div>
          </div>
          <div v-else>
            <div class="user_name">놀장</div>
            <div class="user_pass" @click="goAuthentication">회원가입하기</div>
          </div>
        </div>

        <div class="pay_point_wrap">
          <div class="unit">
            <div class="unit_contents">
              <a href="javascript:void(0)" title="QR 결제" class="unit_con qr" @click="goQrPay">QR 결제</a>
            </div>
          </div>
          <div class="unit">
            <div class="unit_contents nj_point">
              <a href="javascript:void(0)" class="unit_con" @click="moveToPoint">
                <span class="point" v-if="getPoint">{{ $formatPrice(point) }}<span>P</span></span>
                <span class="point_t">포인트</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <MyPageList :userId="userId" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TopHeader from "@/components/new/TopHeader";
import MyPageList from "@/components/new/MyPageList";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "MyPage",
  props: {},
  data: function () {
    return {
      userId: this.$store.state.userId,
      userInfo: {},
      isProgressing: true,
      point: null,
    };
  },
  components: {
    LoadingIndicator,
    MyPageList,
    TopHeader,
  },
  methods: {
    ...mapActions(["appDownloadModal"]),
    moveToPoint: function () {
      if (this.appVersion) {
        // INFO :: 전체메뉴 화면 내에도 동일 로직 있음, 수정 시 같이 반영해야 함.
        if (this.$store.state.userId != null) {
          if (this.$isProd()) {
            // 운영
            this.$navigateTo("open-url", {
              title: "포인트 내역",
              url: "http://noljang.co.kr/deployment/V3/member/point-history/view.html?userId=" + this.$store.state.userId,
            });
          } else {
            // 개발
            this.$navigateTo("open-url", {
              title: "포인트 내역",
              url: "http://noljang.com/test/V3/member/point-history/view.html?userId=" + this.$store.state.userId,
            });
          }
        } else {
          // console.log('비회원 상태');

          const _this = this;
          const modalName = "authentication";
          const compProps = {
            title: "휴대전화 본인인증",
            msgArr: ["휴대전화 본인 인증이 필요합니다."],
            btnArr: [
              {
                type: "type1 inverse",
                txt: "본인 인증 하기",
                btnId: "",
                btnData: {},
                btnCallback: function () {
                  _this.$modal.hideAll();
                  _this.$navigateTo("authentication");
                },
              },
            ],

            closeModal: function () {
              _this.$modal.hideAll();
            },
          };

          this.$store.dispatch(
            "showModal",
            {
              name: modalName,
              compProps: compProps,
              modalProps: {
                width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
                height: "auto",
              },
            },
            { root: true },
          );
        }
      } else {
        this.appDownloadModal();
      }
    },
    goBack: function () {
      this.$router.push({
        name: "놀장 메인",
      });
    },

    onBackPressed: function () {
      if (document.querySelector("#modals-container .vm--container")) {
        this.$modal.hideAll();
      } else {
        this.goBack();
      }
    },

    goSetting: function () {
      this.$router.push({ name: "설정" });
    },

    goAuthentication: function () {
      if (this.appVersion) {
        this.$navigateTo("authentication");
      } else {
        this.appDownloadModal();
      }
    },

    getUserInfo: async function () {
      // console.log("userId ::: " + this.userId);
      if (!this.userId) {
        return false;
      }
      // console.log("userResponse");
      // console.log("userResponse");
      // console.log("userResponse");
      // console.log(this.userId);

      const userResponse = await this.$axios1.get("/v3/user/member/information/" + this.userId + "/personal");
      if (userResponse.data.responseCode == 2000) {
        // console.log(userResponse);
        this.userInfo = userResponse.data.result;
      }

      // 주소지 정보 없을때 대응 : 필요할지 몰라 주석처리함
      /*
      let userAddress = JSON.parse(localStorage.getItem("userAddress"));
      if (this.userId && !userAddress) {
        console.log('주소지 정보가 없을때 :::::::: ');
        try {
          const userAddressUrl = `/v3/user/member/address/${this.userId}/ship-to`;
          const response = await this.$axios1.get(userAddressUrl);
          if (response.data.responseCode == 2000) {
            console.log(response.data);
            console.log(response.data.result);
            const result = response.data.result;
            const userAddressData = {
              roadNameAddress: result.shipToRoad,
              lotNumberAddress: result.shipToJibun,
              detailAddress: result.shipToDetail,
              alias: "",
              latitude: result.latitude,
              longitude: result.longitude,
              zipCode: result.zipCode
            };
            localStorage.setItem("userAddress", JSON.stringify(userAddressData));
          } else {
            // alert(response.data.errorObject.errorMessage);
          }
        } catch (err) {
          return false;
        }
      }
      */
    },

    goQrPay: function () {
      if (this.appVersion) {
        if (this.userId == null) {
          // console.log(this.userId);
          // console.log("this.userId == null");
          // 가입 모달 출력
          // console.log("authentication");

          const _this = this;
          const modalName = "authentication";
          const compProps = {
            title: "휴대전화 본인인증",
            msgArr: ["휴대전화 본인 인증이 필요합니다."],
            btnArr: [
              {
                type: "type1 inverse",
                txt: "본인 인증 하기",
                btnId: "",
                btnData: {},
                btnCallback: function () {
                  _this.$modal.hideAll();
                  _this.$navigateTo("authentication");
                },
              },
            ],

            closeModal: function () {
              this.$modal.hideAll();
            },
          };

          this.$store.dispatch(
            "showModal",
            {
              name: modalName,
              compProps: compProps,
              modalProps: {
                width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
                height: "auto",
              },
            },
            { root: true },
          );

          return;
        } else {
          // console.log(this.userId);
          // console.log("this.userId");
          this.$navigateTo("qr-reader");
        }
      } else {
        this.appDownloadModal();
      }
    },

    getPoint: function () {
      // console.log("getPoint");
      // console.log(this.$store.state.userId);
      if (this.userId) {
        this.$axiosLegacy
          .get("/api2/user/member/point?userId=" + this.userId)
          .then((response) => {
            if (response.data.responseCode == 2000) {
              this.isProgressing = false;
              // console.log(response);
              this.point = response.data.point;
            } else {
              this.isProgressing = false;
              alert(response.data.errorObject.errorMessage);
            }
          })
          .catch(() => {
            this.isProgressing = false;
            return false;
          });
      } else {
        this.isProgressing = false;
      }
    },

    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });

      debugger;

      this.userId = userId;

      this.getUserInfo();
      this.getPoint();
    },
    qrPointPayResult(data) {
      // qr 결재 포인트 사용후 포인트 업데이트
      // console.log("qrPointPayResult");
      const remain = JSON.parse(data)?.remain;
      if (remain) {
        this.point = Number(remain.replace(/,/gi, ""));
      }
      // console.log(this.point);
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.authResult = this.authResult;
    window.qrPointPayResult = this.qrPointPayResult;
  },
  // beforeCreate() {
  //   var userId = this.$route.params.userId;
  //   this.userId = userId;
  // },
  created() {
    this.getUserInfo();
    this.getPoint();
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.contents_header {
  width: 100%;
  padding: 20px 16px;
  background-color: $background-color-type1;

  .my_info {
    position: relative;
    height: 91px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;

    .user_setting_wrap {
      position: absolute;
      top: 16px;
      right: 16px;

      a {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-top: 4px;
        background-image: url("~svg/icon_mypage_setting.svg");
        vertical-align: top;
        text-indent: -9999px;
      }
    }

    .user_name {
      @include font(20, 24, 0, medium);
      color: $black;
    }

    .user_phone {
      margin: 10px 0 0;
      @include font(16, 20, 0, medium);
    }

    .user_pass {
      display: inline-block;
      padding: 5px 20px 0 0;
      background-image: url("~svg/icon_more_02.svg");
      background-repeat: no-repeat;
      background-position: right 12px;
      @include font(16, 24, -0.96, thin);
      color: $black;
      cursor: pointer;
    }
  }

  .pay_point_wrap {
    line-height: 0;
    margin: 14px 0 0 0;

    .unit {
      display: inline-block;
      width: 50%;
      text-align: center;
      vertical-align: top;

      .unit_contents {
        position: relative;
        margin: 0 7px 0 0;
        background-color: #fff;
        border-radius: 20px;
      }

      &:nth-child(even) {
        .unit_contents {
          margin: 0 0 0 7px;
        }
      }
    }

    .unit_con {
      display: block;
      height: 104px;
      padding: 75px 0 0;
      @include font(14, 18, 0, medium);
      color: $black;

      .point_t {
        margin-bottom: 5px;
        line-height: 0.8;
      }
    }

    .qr {
      background-image: url("~svg/icon_qr_img.svg");
      background-position: center 11px;
      background-repeat: no-repeat;
    }

    .point {
      position: absolute;
      top: 35px;
      left: 0;
      width: 100%;
      //@include font(26, 30, -1.04, bold);
      font-family: "NanumSquareEB";
      font-size: 26px;
      font-weight: 800;
      letter-spacing: -1.04px;
      color: $active-color;
      text-align: center;
      line-height: 0.8;

      span {
        margin: 0 0 0 3px;
        @include font(16, 20, 0, bold);
        color: $black;
      }
    }
  }
}
</style>
