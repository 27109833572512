<template>
  <div>
    <TopHeader class="top_header" title="이벤트" :isCartVisible="false" @goBack="goBack" />
    <!-- 해당 시장에 매핑된 이벤트 배너 이미지 노출 -->
    <div class="contents">
      <ul class="banner_list" v-if="notices">
        <li v-for="notice in notices" :key="notice.noticeId" :data-code="notice.noticeId">
          <a
            href="javascript:void(0)"
            :data-notice-id="notice.noticeId"
            :data-detail-url="notice.detailUrl"
            :data-title="notice.title"
            @click="clickBanner"
            v-if="notice.detailUrl && notice.bannerYn"
          >
            <img :src="notice.bannerImage" :alt="notice.title" />
          </a>
          <img v-else :src="notice.bannerImage" :alt="notice.title" />
        </li>
      </ul>
    </div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";
import router from "@/router";

export default {
  name: "Event",
  props: {},
  data: function () {
    return {
      marketId: null,
      notices: null,
      isProgressing: true,
    };
  },
  components: {
    TopHeader,
    LoadingIndicator,
  },
  methods: {
    clickBanner: async function (event) {
      // console.log(event.currentTarget.dataset);
      if (event.currentTarget.dataset.detailUrl) {
        const eventDataset = event.currentTarget.dataset;
        await router.push({
          name: "이벤트상세",
          params: {
            title: eventDataset.title,
            url: eventDataset.detailUrl,
          },
        });
      }
    },
    getEventList: function (marketId) {
      this.isProgressing = true;
      const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;
      this.$axios2
        .get("/v3/notices/member-notices/markets/" + marketId + "?page=0&size=30&userId=" + userId)
        .then((response) => {
          if (response.data.responseCode == 2000) {
            // console.log(response.data.result.notices);
            // this.notices = response.data.result.notices;
            // console.log(response.data.result.billboardBanners);
            this.notices = response.data.result.billboardBanners;
            this.isProgressing = false;
          } else {
            this.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
    },
    goBack: function () {
      this.$navigateBack();
    },
    onBackPressed: function () {
      // console.log("onBackPressed");
      // window.history.back();
      // this.$router.back();
      this.goBack();
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
  created() {
    var marketId = this.$route.params.marketId;
    if (!marketId) {
      marketId = "1";
    }
    this.getEventList(marketId);
  },
};
</script>

<style lang="scss" scoped>
.banner_list {
  li {
    padding: 0px 0px 10px 0px;
    text-align: center;
    &:last-child {
      padding: 0px;
    }
  }
}
</style>
