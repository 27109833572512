<template>
  <div class="panel_wrap">
    <transition name="fade">
      <div class="store_card_wrap" v-if="isStoreCardShow && !isStoreDetailShow">
        <div class="store_card">
          <!--          상점 대표 이미지 -->
          <div class="store_img_wrap">
            <img class="store_main_img" :src="storeInfo.representativeImage" />
            <div class="store_info_icon_wrap left">
              <PanelBtn type="call" @click="moveToApp({ type: 'CALL' })" bottomText="전화걸기" />
            </div>
            <div class="store_info_icon_wrap right">
              <PanelBtn
                :type="isRegularStore ? 'regular_active' : 'regular'"
                @click="showLayer"
                :value="formatRegularCount"
                :bottomText="isRegularStore ? '단골끊기' : '단골맺기'"
              />
            </div>
          </div>
          <div class="store_info_txt" @click="setStoreDetailShow(true)">매장정보 보러가기</div>
        </div>
      </div>
    </transition>
    <div class="tab_wrap" :class="{ top_space: isStoreCardShow && !isStoreDetailShow }">
      <div class="store_info_page_wrap" v-if="isStoreDetailShow">
        <div class="store_info_title">매장정보</div>
        <div class="store_info_content">
          <div class="store_info_wrap">
            <div class="store_info_label">대표자명</div>
            <div class="store_info_value">
              {{ businessInfo.representativeName }}
            </div>
          </div>
          <div class="store_info_wrap">
            <div class="store_info_label">상호명</div>
            <div class="store_info_value">{{ businessInfo.businessName }}</div>
          </div>
          <div class="store_info_wrap">
            <div class="store_info_label">전화번호</div>
            <div class="store_info_value">
              {{ $formatTelNumber(businessInfo.businessCall) }}
            </div>
          </div>
          <div class="store_info_wrap">
            <div class="store_info_label">사업자 등록번호</div>
            <div class="store_info_value">
              {{ businessInfo.businessNumber }}
            </div>
          </div>
          <div class="store_info_wrap">
            <div class="store_info_label">사업자주소</div>
            <div class="store_info_value">
              {{ businessInfo.businessAddress }}
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <StoreProductTab :currentTabId="currentTabId" :tabList="tabList" @click="onTabClick"></StoreProductTab>
        <div class="tab_content">
          <StoreProductList
            v-for="tabItem in tabList"
            v-bind:key="tabItem.id"
            :label="tabItem.label"
            :products="tabItem.products"
            :tabId="tabItem.id"
          />
        </div>
      </template>
    </div>

    <Layer :displayLayer="displayLayer" displayType="center" :closeLayer="hideLayer">
      <template v-slot:content>
        <div class="layer_contents">
          <div class="layer_title">
            {{ isRegularStore ? "단골끊기" : "단골맺기" }}
          </div>
          <p class="layer_txt">
            해당 상점에 단골을
            {{ isRegularStore ? "끊으시겠습니까" : "맺으시겠습니까" }} ?
          </p>
        </div>
        <ul class="half_list type1 mt_10">
          <li>
            <Btn type="type1 none_border" value="취소" @btnCallback="hideLayer" />
          </li>
          <li>
            <Btn type="type1 reverse" btnId="REGULAR" :value="isRegularStore ? '단골끊기' : '단골맺기'" @btnCallback="hideLayer" />
          </li>
        </ul>
      </template>
    </Layer>
  </div>
</template>

<script>
import StoreProductTab from "./StoreProductTab";
import StoreProductList from "./StoreProductList";
import PanelBtn from "@/components/PanelBtn";
import { mapActions, mapGetters, mapState } from "vuex";
import Layer from "@/components/Layer";
import Btn from "@/components/Btn";

export default {
  name: "StoreProductPanel",
  props: {},
  data: function () {
    return {
      currentTabId: 1,
    };
  },
  methods: {
    ...mapActions("deliveryStore", ["moveToApp", "setStoreDetailShow", "toggleRegular", "showLayer", "hideLayer"]),
    onTabClick: function (tabId) {
      this.currentTabId = tabId;

      const tabContent = this.$el.querySelectorAll(".list_wrap_" + tabId, ".list_title")[0];
      if (tabContent) {
        tabContent.scrollIntoView(true);
      }
    },
  },
  components: {
    Btn,
    Layer,
    PanelBtn,
    StoreProductTab,
    StoreProductList,
  },
  computed: {
    ...mapState({
      storeInfo: (state) => state.deliveryStore.storeInfo, // 상점 정보 (대표 이미지, 단골 수)
      regularInfo: (state) => state.deliveryStore.regularInfo, // 단골 여부
      businessInfo: (state) => state.deliveryStore.businessInfo, // 상점 사업자 정보 (전화걸기, 매장정보 보러가기)
      isStoreDetailShow: (state) => state.deliveryStore.isStoreDetailShow, // 매장정보 화면 표시 여부
      displayLayer: (state) => state.deliveryStore.displayLayer, // 단골 맺기/끊기 Confirm 표시 여부
    }),
    ...mapGetters({
      tabList: "deliveryStore/tabList",
    }),
    isRegularStore: function () {
      return this.regularInfo.regularYn == "Y" ? true : false;
    },
    isStoreCardShow: function () {
      return this.currentTabId == 1;
    },
    formatRegularCount: function () {
      const regularCnt = this.storeInfo.favoriteCount ? this.storeInfo.favoriteCount : 0;
      return regularCnt < 1000 ? regularCnt.toString() : "999+";
    },
  },
};
</script>

<style lang="scss" scoped>
.panel_wrap {
  /* 상단바 제외 높이 */
  height: calc(100% - 44px);

  .layer_title {
    margin: 10px 0 0;
    @include font(20, 26, -1.2, bold);
    color: $nj_black;
  }
  .layer_txt {
    margin: 20px 0 0;
    @include font(16, 24, -0.96);
    color: $nj_text_sub_gray;
  }
}

.tab_wrap {
  background: $nj_background_gray;
  border-radius: 20px 20px 0px 0px;
  height: 100%;
  padding: 10px 20px 0px 20px;
  transition: all 0.5s;
}

.tab_wrap.top_space {
  padding-top: 130px;
  height: calc(100% - 100px);
}

.store_card_wrap {
  height: 100px;
  position: relative;
  text-align: center;
}

.store_card_wrap .store_card {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.store_card_wrap .store_card .store_img_wrap {
  height: 160px;
  margin: 0 auto;
  position: relative;

  .store_main_img {
    width: 160px;
    height: 160px;
    border-radius: 20px;
    border: none;
    box-shadow: 0px 1px 0px $nj_shadow_gray;
    object-fit: cover;
  }

  .store_info_icon_wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: 20px;
    }

    &.right {
      right: 20px;
    }
  }
}

.store_card_wrap .store_card .store_info_txt {
  margin: 10px 0 20px 0;
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.84px;
  color: $nj_red;
}

/* 진행 과정 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s, height 0.5s;
}

/* 시작 직전 / 종료 결과 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
  height: 0px;
}

.fade-enter-active .store_card,
.fade-leave-active .store_card {
  transition: top 0.5s;
}

.fade-enter .store_card,
.fade-leave-to .store_card {
  top: -120px;
}

.tab_wrap .tab_content {
  height: calc(100% - 45px);
  padding-bottom: 20px;
  overflow: scroll;
}

.tab_wrap .tab_content .list_wrap:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.store_info_title {
  padding: 10px 0;
  font: normal normal normal 18px/28px Noto Sans CJK KR Bold;
  letter-spacing: -1.08px;
  color: $nj_black;
  border-bottom: 1px solid $nj_divider_gray;
}

.store_info_content {
  padding-top: 20px;
}

.store_info_wrap {
  margin-bottom: 20px;
}

.store_info_wrap .store_info_label {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  letter-spacing: -0.84px;
  color: $nj_text_main_gray;
  margin-bottom: 2px;
}

.store_info_wrap .store_info_value {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -1.08px;
  color: $nj_black;
}
</style>
