<template>
  <div>
    <LoadingIndicator v-if="isProgressing" />
    <TopHeader class="top_header" title="서비스탈퇴" @goBack="goBack" />
    <div class="contents">
      <div class="withdraw_area">
        <p>유의사항</p>
        <p class="sub_txt">회원 탈퇴 전에 꼭 확인하세요.</p>
      </div>
      <div class="warning_area">
        <ul>
          <li>서비스 탈퇴 시 회원이 보유하고 있던 포인트, 쿠폰은 모두 소멸되며, 복구가 불가능합니다.</li>
          <li>서비스 탈퇴 재가입하더라도 탈퇴 전의 회원정보, 거래정보 및 포인트, 쿠폰 등은 복구되지 않습니다.</li>
          <li>서비스 탈퇴 시 이미 결제가 완료된 건은 서비스 탈퇴로 취소되지 않습니다.</li>
          <li>
            소비자 보호에 관한 법률 제6조에 의거 계약 또는 청약 철회 등에 관한 기록은 5년, 대금 결제 및 재화 등의 공급에 관한 기록은 3년 동안
            보관됩니다. 동 개인정보는 법률에 의한 보유 목적 외에 다른 목적으로는 이용되지 않습니다.
          </li>
          <li>서비스 탈퇴 시 회원정보 및 서비스 이용기록은 모두 삭제되며, 삭제된 데이터는 복구가 불가능 합니다.</li>
          <li>서비스 탈퇴 후 7일 동안 재가입할 수 없습니다.</li>
        </ul>
        <div class="point_wrap">
          <div style="padding: 0 35px">
            <span>보유포인트</span>
            <span v-if="getPoint" class="point_txt">{{ $formatPrice(point) }}P</span>
          </div>
        </div>
      </div>
      <div class="withdraw_btn">
        <div class="agree_wrap">
          <input type="checkbox" id="agree" @click="activeWithdraw" />
          <label for="agree">유의사항을 모두 확인하였으며, 회원탈퇴 시 회원 등급, 포인트, 쿠폰 소멸에 동의합니다.</label>
        </div>
        <div class="unit">
          <Btn type="type2" value="취소" @btnCallback="goBack" />
        </div>
        <div class="unit right_contents">
          <Btn type="type4" value="탈퇴" @btnCallback="goWithdraw" id="withdrawBtn" :class="{ disabled: btnDisabled }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";
import Btn from "@/components/new/Btn";
import { mapState, mapActions, mapMutations } from "vuex";
// import Modal from "@/components/new/Modal";

export default {
  name: "WithdrawService",
  beforeCreate() {
    this.isProgressing = false;

    this.$store.dispatch("withdraw/initState", {
      userId: localStorage.getItem("userId"),
    });
  },
  computed: {
    ...mapState({
      userId: (state) => state.withdraw.userId,
    }),
  },
  props: {},
  data: function () {
    return {
      point: null,
      btnDisabled: true,
    };
  },
  components: {
    LoadingIndicator,
    TopHeader,
    Btn,
  },
  methods: {
    ...mapMutations("withdraw", []),
    ...mapActions("withdraw", ["updateWithdraw", "withdrawal"]),

    goWithdraw: function () {
      // console.log("withdraw");

      const _this = this;
      const modalName = "withdraw";
      const compProps = {
        title: "회원탈퇴",
        msgArr: ["탈퇴 신청 30일 후 회원탈퇴가 <br>완료되며 쿠폰 포인트 및 <br>모든 데이터가 삭제됩니다.<br>회원탈퇴를 진행하시겠습니까?"],
        btnArr: [
          {
            type: "type1 inverse",
            txt: "탈퇴취소",
            btnId: "",
            btnData: {},
            btnCallback: function () {
              _this.$modal.hideAll();
            },
          },
          {
            type: "type1",
            txt: "회원탈퇴",
            btnId: "WITHDRAW",
            btnData: {},
            btnCallback: function () {
              _this.$modal.hideAll();

              _this.isProgressing = true;

              const userId = localStorage.getItem("userId");
              const axiosUrl = "/v3/user/member/information/" + userId + "/withdrawal";
              _this.$axios1
                .delete(axiosUrl)
                .then((response) => {
                  if (response.data.responseCode == 2000) {
                    // console.log(response.data);
                    _this.$callNative("withdrawal", { userId: userId }, false);
                    _this.isProgressing = false;
                    // _this.$router.push({name: '놀장 메인'});
                  } else {
                    alert(response.data.errorObject.errorMessage);
                  }
                  _this.isProgressing = false;
                })
                .catch(() => {
                  _this.isProgressing = false;
                });
            },
          },
        ],

        closeModal: function () {
          this.$modal.hideAll();
        },
      };

      this.$store.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
        },
        { root: true },
      );

      return;
    },

    getPoint: function () {
      this.$axiosLegacy
        .get("/api2/user/member/point?userId=" + this.userId)
        .then((response) => {
          if (response.data.responseCode === 2000) {
            this.isProgressing = false;
            // console.log(response);
            this.point = response.data.point;
          } else {
            this.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
    },

    goBack: function () {
      // console.log("goBack ::::: ");
      // this.$router.back();
      this.$navigateBack();
    },

    activeWithdraw: function () {
      this.btnDisabled = !document.getElementById("agree").checked;
    },

    onBackPressed: function () {
      // console.log("onBackPressed");
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        // this.$router.back();
        this.$navigateBack();
      }
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
  created() {
    this.getPoint();
  },
};
</script>

<style lang="scss" scoped>
.contents {
  overflow: auto;
  background-color: #fafafa;
}
.withdraw_area {
  padding: 10px 20px;
  background-color: $white;
}

.warning_area {
  display: flex;
  flex-direction: column;
  height: calc(100% - 229px);
  margin: 10px 0 0;
  background-color: $white;

  ul {
    flex: 1;
    list-style: disc outside none;
    padding: 0 35px;
  }
  li {
    padding: 6px 0 0;
  }
}

.point_wrap {
  height: 56px;
  vertical-align: middle;

  .point_txt {
    @include font(16, 58, normal, bold);
    float: right;
  }

  span {
    display: inline-block;
    margin: auto;
    @include font(16, 58, normal, thin);
  }
}

p {
  margin: 0;
  @include font(16, 26, 0, medium);
  color: $black;
}

p.sub_txt {
  margin: 0;
  @include font(16, 26, 0, thin);
  color: $black;
}

.withdraw_btn {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 135px;
  margin: 10px 0 0;
  padding: 13px 16px;
  background-color: $white;
  border: {
    width: 1px 0 0 0;
    style: solid;
    color: $border-type1;
  }

  a {
    width: 100%;
    @include border-radius(5px);
    @include font(16, 49, normal, bold);
    text-align: center;

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      background-color: gray;
    }
  }

  input {
    position: relative;
    top: 1.8px;
    margin: 0 7px 0 0;
  }

  .agree_wrap {
    padding: 0 0 5px;
  }

  .unit {
    display: inline-block;
    width: 50%;
    padding: 0 8px 0 0;
    text-align: center;

    &.right_contents {
      padding: 0 0 0 8px;
    }
  }
}
</style>
