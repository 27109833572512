<template>
  <div @touchstart="marketWrapTouchStart($event)" @touchmove="marketWrapTouchMove($event)" @touchend="marketWrapTouchEnd($event)">
    <ReloadingIndicator :isReloading="isReloading" :class="{ reloading: isReloading }" />
    <LoadingIndicator v-if="!isReloading && (isInitStateProgressing || isProgressing)" />
    <div
      class="market_container"
      :class="{
        fixed: topFixed,
        ['fixed_' + activeTabItem]: true,
        fixed_tab: tabFixed,
      }"
      id="marketMainWrap"
    >
      <div class="scroll_top" :class="{ active: showScroll }" @click="scrollTop">
        <img src="../assets/images/scroll.svg" alt="scroll button" />
      </div>

      <div class="top_banner" v-if="topBanner">
        <a href="javascript:void(0);" @click="clickBanner">
          <img
            style="width: 100%"
            :src="topBanner.bannerImage"
            :data-title="topBanner.title"
            :data-detail-url="topBanner.detailUrl"
            :data-notice-id="topBanner.noticeId"
            @load="loadTopBanner"
            id="topBanner"
          />
        </a>
      </div>

      <TopHeader class="top_header" :isHomeVisible="true" :title="marketName" @goBack="goBack" />

      <div class="second_header">
        <div class="search_wrap">
          <SearchInput placeholder="상품명을 입력하세요" @search="search" />
        </div>

        <div style="min-height: 43px; background-color: #fff" ref="mainTab">
          <Tab
            :tabItems="isReloading ? previousTabItems : tabItems"
            @click="clickTab"
            v-if="isFirstLoading"
            :isPreviousData="true"
            tabType="type1"
            :activeTabItem="activeTabItem"
          />
        </div>
        <div v-if="activeTabItem == 'at'">
          <Tab
            :tabItems="atEventCategoryList"
            @click="clickCategoryTab({ categoryId: $event.dataType, event: $event })"
            v-if="atEventCategoryList"
            :isPreviousData="true"
            tabType="type2"
          />
        </div>
        <div v-if="activeTabItem == 'hot'">
          <Tab
            :tabItems="hotSaleCategory"
            @click="clickCategoryTab({ categoryId: $event.dataType, event: $event })"
            v-if="hotSaleCategory"
            :isPreviousData="true"
            tabType="type2"
          />
        </div>
        <div v-else-if="activeTabItem == 'new'">
          <Tab
            :tabItems="newCategory"
            @click="clickCategoryTab({ categoryId: $event.dataType, event: $event })"
            v-if="newCategory"
            :isPreviousData="true"
            tabType="type2"
          />
        </div>
        <div v-else-if="activeTabItem == 'quick'">
          <Tab
            :tabItems="quickStoreCategory"
            @click="clickCategoryTab({ categoryId: $event.dataType, event: $event })"
            v-if="quickStoreCategory"
            :isPreviousData="true"
            tabType="type2"
          />
        </div>
        <div v-else-if="activeTabItem === 'product' && categoryList.length > 0">
          <DoubleTab
            :largeTabItems="productLargeTab.tabItems"
            :largeTabActiveIndex="productLargeTab.tabActiveIndex"
            :mediumTabItems="productMediumTab.tabItems"
            :mediumTabActiveIndex="productMediumTab.tabActiveIndex"
            :isMediumTabVisible="productMediumTab.isMediumTabVisible"
            @clickLargeTab="switchProductLargeTabActiveIndex"
            @clickMediumTab="switchProductMediumTabActiveIndex"
            @clickBackArrow="clickProductMediumTabBackArrow"
            tabType="type2"
          />
        </div>
      </div>

      <div class="market_contents" ref="contents" style="position: relative">
        <!-- :style="{ 'min-height': windowHeight + 'px' }" -->
        <div style="overflow-y: hidden" :style="[activeTabItem === 'main' ? { height: 'auto' } : { height: 0 }]">
          <!--        <div v-if="activeTabItem == 'main'">-->
          <div class="banner_wrap ignore_touch_x" v-if="banners && banners.length > 0" @click="clickBanner">
            <swiper
              ref="mySwiper"
              :options="swiperOptions"
              :auto-destroy="true"
              :delete-instance-on-destroy="true"
              :cleanup-styles-on-destroy="true"
              @touchEnd="touchEnd"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="(banner, index) in banners" :key="'banner_' + index">
                <img
                  :src="banner.bannerImage"
                  :alt="banner.title"
                  :data-title="banner.title"
                  :data-detail-url="banner.detailUrl"
                  :data-notice-id="banner.noticeId"
                />
              </swiper-slide>
            </swiper>
            <span v-if="banners.length > 1" class="absolute_right allEvent"> 전체보기 {{ bannerActiveIndex }}/{{ banners.length }} </span>
          </div>
          <div class="market_info relative">
            <div class="icon_coordinate">{{ marketAddress }}</div>
            <p class="explain" v-if="marketDescription">
              {{ marketDescription }}
            </p>
            <a href="javascript:void(0)" class="btn_share" @click="share" v-if="appVersion && Number(appVersion.replace(/\./gi, '')) > 3474">
              공유하기</a
            >
          </div>

          <!-- 마천중앙 -->
          <div class="market_status_wrap" v-if="Number(marketId) === 14">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 산본 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 24">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 5:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 명일전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 25">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 9:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 성내전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 158">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 우림골목 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 140">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 돌곶이 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 68">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 장위전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 67">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:30 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 용산용문 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 36">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 11:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 수유 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 22">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 6:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 신창 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 16">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 방학동도깨비 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 32">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 망원 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 29">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 성대전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 66">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 8:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 목사랑 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 17">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                  오전 10:00~오후 13:00 주문 건, 2시 출고<br />
                  오후 13:00~오후 16:00 주문 건, 5시 출고<br />
                </li>
                <li>
                  <span class="subject">배송비</span><br />
                  <span v-html="'기본 ' + orderInfo.deliveryFee.replace(/\n/gi, '<br />')" />
                </li>
              </ul>
            </div>
          </div>

          <!-- 목동깨비 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 65">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 중동사랑 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 31">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 6:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 역곡남부 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 108">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 계산 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 33">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
                <li>
                  <span class="subject">배달 주문</span><br />
                  오전 10:00~오후 5:00 (월~금)<br />
                </li>
                <li>
                  <span class="subject">배송비</span><br />
                  기본 4,000원<br />
                  2만원 이상 구매 시 무료배달
                </li>
              </ul>
            </div>
          </div>

          <!-- 계양산전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 48">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 6:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 논산화지중앙 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 45">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
                <li>
                  <span class="subject">배달 주문</span><br />
                  10:00~12:30 주문, 오후 1시 배달출발<br />
                  12:30~15:00 주문, 오후 3시 30분 배달출발<br />
                  15:00~17:00 주문, 오후 5시 30분 배달출발<br />
                </li>
                <li>
                  <span class="subject">배송비</span><br />
                  4,000원<br />
                  2만원 이상 구매 시 무료배송
                </li>
              </ul>
            </div>
          </div>

          <!-- 언양알프스 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 42">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 5:00 (주말 포함)<br />
                </li>
                <li>
                  <span class="subject">배달 주문</span><br />
                  오전 10:00~오후 5:00 (월~금)<br />
                </li>
                <li>
                  <span class="subject">배송비</span><br />
                  <span v-html="'기본 ' + orderInfo.deliveryFee.replace(/\n/gi, '<br />')" />
                </li>
              </ul>
            </div>
          </div>

          <!-- 태화종합 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 91">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 6:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 명서전통 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 34">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 중마 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 35">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 7:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <!-- 청호 -->
          <div class="market_status_wrap" v-else-if="Number(marketId) === 166">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">포장 주문</span><br />
                  오전 10:00~오후 6:00 (주말 포함)<br />
                </li>
              </ul>
            </div>
          </div>

          <div class="market_status_wrap" v-else-if="orderType === 'BD' && orderInfo">
            <div class="market_status" :class="{ open: !lunarHoliday && marketDeliveryYn === 'Y' }">
              <p class="status">
                <span class="open_txt">{{ orderInfo.marketStatus }}</span>
              </p>
              <div class="bg"></div>
              <ul class="market_status_contents">
                <li>
                  <span class="subject">주문시간</span><br />
                  <span v-html="orderInfo.orderTime.replace(/\n/gi, '<br />')" />
                </li>
              </ul>
            </div>
          </div>

          <div class="banner" v-if="atEventProducts && atEventProducts.length > 0" style="display: none">
            <a href="javascript:void(0);" @click="clickBanner">
              <img
                src="~img/at_event_banner.png"
                style="width: 100%"
                alt="농할 이벤트"
                data-title="농할 이벤트"
                data-detail-url="https://s3.ap-northeast-2.amazonaws.com/noljang-ops/event/detail_20220113_134649.html"
              />
            </a>
          </div>

          <div class="mt_10" v-if="recommendEvent">
            <router-link :to="{ name: '추천인 이벤트', params: recommendEvent }"
              ><img src="~img/recommend_banner.png" style="width: 100%" alt="추천인 이벤트"
            /></router-link>
          </div>
          <div class="banner mt_10" v-else-if="strapBanner">
            <a href="javascript:void(0);" @click="clickBanner">
              <img
                :src="strapBanner.bannerImage"
                :data-title="strapBanner.title"
                :data-detail-url="strapBanner.detailUrl"
                :data-notice-id="strapBanner.noticeId"
              />
            </a>
          </div>

          <div class="category_wrap" v-if="categoryList && categoryList.length > 0">
            <div class="contents_title type1">
              <div class="title">카테고리</div>
            </div>

            <ul class="inline_list type3">
              <!-- 단골가게 -------------------------------------->
              <!-- 단골가게 아이콘은 서버에서 받아오는 데이터가 아님   -->
              <!-- 사용자 등록된 단골가게가 없으면 노출하지 않게 처리  -->
              <!--            <li v-for="category in categoryList.length > 0? categoryList.filter((category, index) => index > 0) : categoryList"-->
              <!--                :key="category.dataType"-->
              <!--            >-->

              <!--            <li v-for="category in userId && regularList && regularList.length > 0? categoryList : categoryList.filter((category, index) => index > 0)"-->
              <!--                :key="category.dataType"-->
              <!--            >-->
              <li v-for="category in categoryList" :key="category.dataType">
                <a
                  href="javascript:void(0);"
                  class="icon_category_01"
                  :class="category.categoryImageUrl == 'icon_favorite_store' ? 'icon_favorite_store' : null"
                  :style="
                    category.categoryImageUrl && category.categoryImageUrl != 'icon_favorite_store'
                      ? {
                          backgroundImage: 'url(' + category.categoryImageUrl + ')',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition:
                            'center ' + (category.dataType == '105' || category.dataType == '5' ? '5px' : category.dataType == '0' ? '3px' : '0'),
                          backgroundSize: '30px auto',
                        }
                      : null
                  "
                  @click="clickCategory({ categoryId: category.dataType })"
                  >{{ category.text }}</a
                >
              </li>
            </ul>
          </div>

          <div class="contents_unit mt_10" v-if="atEventProducts && atEventProducts.length > 0 && discountType != 'SEOUL' && discountType != 'NONE'">
            <div class="contents_title type1">
              <div class="title" v-if="Number(marketId) !== 168 && Number(marketId) !== 169">
                <!-- TODO 절임배추 판매로 보이지 않게 처리 : 절임배추 판매 종료 시 169 조건에서 삭제 : 2023-09-27 -->
                농할 이벤트 30% 할인 상품전
              </div>
              <div class="title" v-else>해남절임배추 반값 할인</div>
              <a href="javascript:void(0);" class="icon_more" @click="moveToMore({ type: 'at', event: $event })">더보기</a>
            </div>
            <div class="product_list type1" v-if="atEventProducts && atEventProducts.length > 0">
              <ProductList :products="atEventProducts" :marketId="marketId" />
            </div>
          </div>

          <div class="contents_unit" v-if="hotSaleProductList && hotSaleProductList.length > 0">
            <div class="contents_title type1">
              <div class="title">핫세일</div>
              <a href="javascript:void(0);" class="icon_more" @click="moveToMore({ type: 'hot', event: $event })">더보기</a>
            </div>
            <div class="product_list type1" v-if="hotSaleProductList && hotSaleProductList.length > 0">
              <ProductList :products="hotSaleProductList" :marketId="marketId" />
            </div>
          </div>

          <div class="contents_unit" v-if="newProductList && newProductList.length > 0">
            <div class="contents_title type1">
              <div class="title">새로 들어왔어요</div>
              <a href="javascript:void(0);" class="icon_more" @click="moveToMore({ type: 'new', event: $event })">더보기</a>
            </div>
            <div class="product_list type1" v-if="newProductList && newProductList.length > 0">
              <ProductList :products="newProductList" :marketId="marketId" />
            </div>
          </div>

          <div class="contents_unit" v-if="quickStoreList && quickStoreList.length > 0">
            <div class="contents_title type1">
              <div class="title">음식배달</div>
              <a
                href="javascript:void(0);"
                class="icon_more"
                v-if="quickStoreList && quickStoreList.length > 0"
                @click="moveToMore({ type: 'quick', event: $event })"
                >더보기</a
              >
            </div>
            <div class="store_list type2 horizontal_list" v-if="quickStoreList && quickStoreList.length > 0">
              <StoreList :stores="quickStoreList" />
            </div>
          </div>

          <div class="contents_unit">
            <div class="contents_title type1">
              <div class="title">내가 본 상품</div>
            </div>
            <div class="product_list type1" v-if="recentProductList && recentProductList.length > 0">
              <ProductList :products="recentProductList" :marketId="marketId" />
            </div>
            <div class="empty_list" v-else-if="orderType === 'BD'">
              <strong class="accent_txt">고객님, 상품을 한 번 둘러보세요.</strong>
              <p class="txt">
                시장에서 당일 준비된 값 싸고, 신선한 상품을 <br />둘러보세요. 주문 후 2시간 이내에 고객님의 <br />문 앞까지 배달됩니다.<br /><br />
                주문시간 : 오전 10시 ~ 오후 5시
              </p>
            </div>
            <div class="empty_list" v-else-if="orderType === 'TB'">
              <strong class="accent_txt">산지의 신선한 상품을 둘러보세요.</strong>
              <p class="txt">주문 후 2~3일 (영업일 기준) 이내에 배송됩니다.<br /><br /></p>
            </div>
          </div>

          <!--   && serviceStoreList && serviceStoreList.length > 0 -->
          <div class="contents_unit type1" v-if="userId && serviceStoreList && serviceStoreList.length > 0">
            <div class="contents_title type1">
              <div class="title">단골상점</div>
              <a
                href="javascript:void(0);"
                class="icon_more"
                v-if="serviceStoreList && serviceStoreList.length > 0"
                @click="moveToMore({ type: 'favorite', event: $event })"
                >더보기</a
              >
            </div>
            <div class="store_list type2 horizontal_list" v-if="serviceStoreList && serviceStoreList.length > 0">
              <StoreList :stores="mainTabFavoriteStores" :regularBoolean="true" :displayMarketName="false" />
            </div>
          </div>

          <div class="contents_unit" v-if="products && products.length > 0">
            <div class="contents_title type1">
              <div class="title">전체상품</div>
              <a
                href="javascript:void(0);"
                class="icon_more"
                v-if="products && products.length > 0"
                @click="moveToMore({ type: 'product', event: $event })"
                >더보기</a
              >
            </div>
            <div class="product_list type1" v-if="products && products.length > 0">
              <ProductList :products="products" :marketId="marketId" />
            </div>
          </div>

          <div class="contents_unit" v-if="stores && stores.length > 0">
            <div class="contents_title type1">
              <div class="title">전체상점</div>
              <a
                href="javascript:void(0);"
                class="icon_more"
                v-if="stores && stores.length > 0"
                @click="moveToMore({ type: 'stores', event: $event })"
                >더보기</a
              >
            </div>
            <div class="store_list type2 horizontal_list" v-if="stores && stores.length > 0">
              <StoreList :stores="mainTabStores" :displayMarketName="false" />
            </div>
          </div>
        </div>

        <div class="market_tab_container" v-if="activeTabItem == 'at'">
          <div
            class="absolute_contents type2"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="product_list vertical accept_touch_x" v-if="atEventCategoryProducts && atEventCategoryProducts.length > 0">
              <ProductList :products="atEventCategoryProducts" :marketId="marketId" />
            </div>
          </div>
        </div>

        <div class="market_tab_container" v-else-if="activeTabItem == 'hot'">
          <div
            class="absolute_contents type2"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="product_list vertical accept_touch_x" v-if="hotSaleCategoryProductList && hotSaleCategoryProductList.length > 0">
              <ProductList :products="hotSaleCategoryProductList" :marketId="marketId" />
            </div>
          </div>
        </div>

        <div class="market_tab_container" v-else-if="activeTabItem == 'new'">
          <div
            class="absolute_contents type2"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="product_list vertical accept_touch_x" v-if="newCategoryProductList && newCategoryProductList.length > 0">
              <ProductList :products="newCategoryProductList" :marketId="marketId" />
            </div>
          </div>
        </div>

        <div class="market_tab_container" v-else-if="activeTabItem == 'quick'">
          <div
            class="absolute_contents type2"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; padding: 20px 16px; overflow: unset"
          >
            <div class="store_list vertical_list accept_touch_x">
              <StoreList :stores="quickStoreList" v-show="!isReloading" />
            </div>
          </div>
        </div>
        <div class="market_tab_container" v-else-if="activeTabItem == 'product'">
          <div
            class="absolute_contents type2"
            id="productList"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="product_list vertical accept_touch_x" v-if="productCategoryProductList && productCategoryProductList.length > 0">
              <ProductList :products="productCategoryProductList" :marketId="marketId" />
            </div>
          </div>
        </div>
        <div class="market_tab_container" v-else-if="activeTabItem == 'favorite'">
          <div
            class="absolute_contents type3"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="store_list vertical_list accept_touch_x">
              <StoreList :stores="regularList" :regularBoolean="true" :displayMarketName="false" v-show="!isReloading" />
            </div>
          </div>
        </div>
        <div class="market_tab_container" v-else-if="activeTabItem == 'store' || activeTabItem == 'stores'">
          <div
            class="absolute_contents type3"
            style="position: relative; top: unset; left: unset; right: unset; bottom: unset; height: 100%; overflow: unset"
          >
            <div class="store_list vertical_list accept_touch_x">
              <StoreList :stores="stores" :displayMarketName="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

import ReloadingIndicator from "@/components/new/ReloadingIndicator";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import TopHeader from "@/components/new/TopHeader";
import SearchInput from "@/components/new/SearchInput";
// import TabList from "@/components/new/TabList";
import ProductList from "@/components/new/ProductList";
import DoubleTab from "@/components/new/DoubleTab";
import Tab from "@/components/new/Tab";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import StoreList from "@/components/new/StoreList";

// 전국배송 특화시장 여부 확인
export default {
  name: "Market",
  props: {
    // test: String,
  },
  data: function () {
    return {
      windowHeight: window.innerHeight,
      swiperOptions: {
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        autoplay: {
          delay: 3000,
        },
        slidesPerView: "auto",
        touchRatio: 0.2,
        // slideToClickedSlide: true
      },
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      isNotWeekend: new Date().getDay() !== 0 && new Date().getDay() !== 6,
      showScroll: false,
      lastScroll: 0,
      lunarHoliday: new Date().getDate() >= 9 && new Date().getDate() <= 12,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.marketMain.userId,
      isReloading: (state) => state.marketMain.isReloading,
      isInitStateProgressing: (state) => state.marketMain.isInitStateProgressing, // initState loading
      isProgressing: (state) => state.marketMain.isProgressing, // loading
      isFirstLoading: (state) => state.marketMain.isFirstLoading, // 페이지 첫 진입시 탭 메뉴 정의가 완료된 시점
      marketId: (state) => state.marketMain.marketId,
      marketName: (state) => state.marketMain.marketName,
      marketAddress: (state) => state.marketMain.marketAddress,
      marketDescription: (state) => state.marketMain.marketDescription,
      orderType: (state) => state.marketMain.orderType, // 시장 배송 타입

      topFixed: (state) => state.marketMain.topFixed,
      tabFixed: (state) => state.marketMain.tabFixed,
      topBannerHeight: (state) => state.marketMain.topBannerHeight,
      topBanner: (state) => state.marketMain.topBanner, // 상단탭 활성화 변수

      strapBanner: (state) => state.marketMain.strapBanner, // 띠 배너

      banners: (state) => state.marketMain.banners, // 상단탭 활성화 변수
      bannerActiveIndex: (state) => state.marketMain.bannerActiveIndex, // 슬라이딩 배너 활성화 index
      activeTabItem: (state) => state.marketMain.activeTabItem, // 활성화된 Tab dataType
      tabItems: (state) => state.marketMain.tabItems, // Tab
      previousTabItems: (state) => state.marketMain.previousTabItems, // previous Tab
      categoryList: (state) => state.marketMain.categoryList,

      recommendEvent: (state) => state.marketMain.recommendEvent,

      hotSaleCategory: (state) => state.marketMain.hotSaleCategory, // 핫세일 카테고리
      hotSaleCategoryProductList: (state) => state.marketMain.hotSaleCategoryProductList, // 핫세일 카테고리 상품

      newCategory: (state) => state.marketMain.newCategory, // 새로들어왔어요 카테고리
      newCategoryProductList: (state) => state.marketMain.newCategoryProductList, // 새로들어왔어요 카테고리 상품

      quickStoreCategory: (state) => state.marketMain.quickStoreCategory, // 음식배달 카테고리
      quickStoreList: (state) => state.marketMain.quickStoreList, // 음식배달
      quickStoreCategoryStore: (state) => state.marketMain.quickStoreCategoryStore, // 음식배달 카테고리의 상점 목록

      productActiveCategory: (state) => state.marketMain.productActiveCategory, // 전체상품 카테고리
      productCategoryProductList: (state) => state.marketMain.productCategoryProductList, // 전체상품 카테고리 상품
      regularList: (state) => state.marketMain.regularList, // 단골상점 탭용

      hotSaleProductList: (state) => state.marketMain.hotSaleProductList, // 핫세일
      newProductList: (state) => state.marketMain.newProductList, // 오늘 들어왔어요
      serviceStoreList: (state) => state.marketMain.serviceStoreList, // 단골상점
      recentProductList: (state) => state.marketMain.recentProductList, // 내가 본 상품
      products: (state) => state.marketMain.products, // 전체상품
      stores: (state) => state.marketMain.stores, // 전체상점

      atEventProducts: (state) => state.marketMain.atEventProducts, // AT 이벤트 상품 목록
      atEventCategoryList: (state) => state.marketMain.atEventCategoryList, // AT 이벤트 카테고리
      atEventActiveCategory: (state) => state.marketMain.atEventActiveCategory, // AT 이벤트 활성화된 카테고리
      atEventCategoryProducts: (state) => state.marketMain.atEventCategoryProducts,

      productLargeTab: (state) => state.marketMain.productLargeTab, // 전체 상품 대분류 탭
      productMediumTab: (state) => state.marketMain.productMediumTab, // 전체 상품 대분류 탭

      marketDeliveryYn: (state) => state.marketMain.marketDeliveryYn,
      orderInfo: (state) => state.marketMain.orderInfo, // 영업상태
      sssMarkets: (state) => state.sssMarkets, // 서울시특판 시장 아이디 배열

      appVersion: (state) => state.appVersion,
    }),
    swiper() {
      return this.$refs.mySwiper?.$swiper; // 배너
    },
    mainTabStores() {
      return this.stores.filter((store, index) => index < 10);
    },
    mainTabFavoriteStores() {
      return this.serviceStoreList.filter((store, index) => index < 10);
    },
  },
  methods: {
    ...mapMutations("marketMain", [
      "goBack",
      "reset",
      "setBannerActiveIndex",
      "setRefTab",
      "search",
      "updateState",
      "showPopup", // 이미지 배너
      "moveToRecommendEvent",
    ]),
    ...mapActions("marketMain", [
      "clickBanner", // 배너 클릭
      "clickCategory", // 카테고리 클릭
      "clickTab", // 탭메뉴 클릭
      "clickCategoryTab", // 카테고리 탭 클릭
      "moveToMore",
      "handleScroll", // 스크롤 이벤트
      "setProductRecent", // 백버튼 진입시 내가본 상품 업데이트
      "loadTopBanner",
      "switchProductLargeTabActiveIndex", // 전체상품 대분류 탭 리스트 선택
      "switchProductMediumTabActiveIndex", // 전체상품 중분류 탭 리스트 선택
      "clickProductMediumTabBackArrow", // 전체상품 중분류 이전메뉴 선택
      "onBackPressedEvent", // 안드로이드 백 버튼 클릭 시 이벤트
      "marketWrapTouchStart",
      "marketWrapTouchMove",
      "marketWrapTouchEnd",
      "share",
      "initState",
    ]),

    touchEnd() {
      // 슬라이딩 배너에서 사용자 터치가 종료 되었을때 autoplay 적용
      const _this = this;
      _this.swiper?.autoplay.stop();
      setTimeout(function () {
        _this.swiper?.autoplay.start();
      }, 1000);
    },
    onSlideChange() {
      // 현재 슬라이드 index 설정
      // console.log(this.$refs.mySwiper.$swiper.activeIndex);
      // console.log(this.banners.length);
      const activeIndex = (this.$refs.mySwiper.$swiper.activeIndex % this.banners.length) + 1;
      this.setBannerActiveIndex(activeIndex);
    },

    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });

      this.updateState({ type: "userId", updateData: userId });

      this.setProductRecent();

      // document.querySelector('.search_header').style.backgroundColor = '#f00';
    },
    onBackPressed() {
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        this.onBackPressedEvent(() => {
          this.goBack();
        });
      }
    },
    initParams: function (data) {
      // console.log('initParams');
      if (typeof data == "string") {
        data = JSON.parse(data);
      }
      // console.log(data);
      this.$store.dispatch("rootInitState", data);
    },
    shareFromKakao(json) {
      const data = JSON.parse(json);
      // console.log(data);

      const userId = this.$route.params.userId || localStorage.getItem("userId");
      this.$store.dispatch("marketMain/initState", {
        userId: userId,
        marketId: data.marketId,
        marketName: data.marketName,
      });
    },
    disableRefresh(event) {
      // 새로고침 방지
      if (event.key === "F5" || event.key === "F11" || ((event.ctrlKey || event.metaKey) && event.keyCode === 82)) {
        event.preventDefault();
        this.initState({ isReload: true });
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    watchScrollY() {
      const scrollY = window.scrollY;
      if (scrollY === 0 || scrollY > this.lastScroll) {
        this.showScroll = false;
      } else if (scrollY < this.lastScroll) {
        this.showScroll = true;
      }

      this.lastScroll = scrollY;
      this.handleScroll();
    },
  },
  components: {
    ReloadingIndicator,
    LoadingIndicator,
    TopHeader,
    SearchInput,
    // TabList,
    Swiper,
    SwiperSlide,
    ProductList,
    StoreList,
    DoubleTab,
    Tab,
  },
  beforeCreate() {
    if (!this.$route.params.marketId) {
      return;
    }

    let userId = this.$route.params.userId || localStorage.getItem("userId");
    let marketId = this.$route.params.marketId;
    let marketName = this.$route.params.marketName;

    this.$store.dispatch("marketMain/initState", {
      userId: userId,
      marketId: marketId,
      marketName: marketName,
    });
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.shareFromKakao = this.shareFromKakao;

    // ref를 store 모듈에 등록
    this.setRefTab({
      mainTab: this.$refs.mainTab,
      contents: this.$refs.contents,
    });

    window.authResult = this.authResult;
  },
  beforeMount() {
    window.addEventListener("keydown", this.disableRefresh);
  },
  destroyed() {
    window.removeEventListener("scroll", this.watchScrollY);
    window.removeEventListener("keydown", this.disableRefresh);
  },
  created() {
    // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX");
    // console.log(this.$route?.params?.marketId);

    if (!this.$route.params.marketId) {
      // 백버튼 진입시 marketId가 없을때 탭버튼 노출 대응
      this.setProductRecent();
      // console.log(this.activeTabItem);
      if (this.activeTabItem == "product") {
        this.$store.commit("marketMain/changeCategoryPosition", "contents");
      }
    }

    window.addEventListener("scroll", this.watchScrollY);
    window.initParams = this.initParams;

    // this.authResult('{"userId":"B6545D8E-ECE0-4044-9D52-C05496D4D410"}');

    // console.log('this.$route.params.marketId ::: ' + this.$route.params.marketId);
    // console.log(this.$store.state.devMode);
    // var marketId = this.$route.params.marketId;
    // if (!marketId) { //  && this.$store.state.devMode
    //   marketId = '2';
    // }
    // console.log('marketId ::: ' + marketId);
    // this.$store.dispatch('marketMain/initState', {marketId: marketId});
  },
  beforeDestroy() {
    // this.reset();
  },
  watch: {
    activeTabItem: function () {
      if (this.activeTabItem === "main") {
        this.swiper?.autoplay.start();
      } else {
        this.swiper?.autoplay.stop();
      }
    },
    banners(val) {
      if (val !== null) {
        this.swiperOptions.loop = val.length > 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll_top {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  bottom: 0;
  right: 15px;
  width: 70px;
  height: 70px;
  z-index: 999;
  transition: 0.5s ease-out;

  &.active {
    visibility: visible;
    opacity: 1;
    bottom: 30px;
  }

  img {
    position: absolute;
    width: 70px;
    height: 70px;
    cursor: pointer;
    object-fit: contain;
  }
}

.market_status_wrap {
  padding: 0 15px 15px;
  background-color: $white;
  .market_status {
    position: relative;
    padding: 7px 0 10px;
    background-color: #cdcbc9;
    border-radius: 10px;
    overflow: hidden;
    .bg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 168px;
      height: 141px;
      background-image: url("~img/market_close.png");
      background-repeat: no-repeat;
      background-position: left bottom;
    }
    //.open_txt, .close_txt {
    //  display: none;
    //}
    .status {
      font: {
        size: 18px;
        weight: bold;
        family: "GmarketSans";
      }
      color: #222;
      text-align: center;
      //.open_txt {
      //  display: none;
      //}
      //.close_txt {
      //  display: inline;
      //}
    }
    .market_status_contents {
      padding: 10px;
      margin: 5px 15px 5px 105px;
      background-color: #fcfcfc;
      border: {
        style: solid;
        color: #4d4b46;
        width: 2px;
        radius: 5px;
      }
      font: {
        size: 12px;
        color: #4c4c4c;
        weight: 500;
      }
      .subject {
        font: {
          size: 14px;
          color: #363635;
          //weight: 500;
        }
      }
      li {
        position: relative;
        line-height: 1.4;
        padding: 0 0 5px 15px;
        &:last-child {
          padding: 0 0 0 15px;
        }
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 7px;
          left: 0px;
          width: 8px;
          height: 8px;
          border: {
            style: solid;
            color: #777;
            width: 2px;
            radius: 5px;
          }
        }
      }
    }
    &.open {
      background-color: #f8eed8;
      .bg {
        background-image: url("~img/market_open.png");
      }
      .status {
        color: #992f13;
        .open_txt {
          display: inline;
        }
      }
      .market_status_contents {
        li {
          &::before {
            border: {
              color: #992f13;
            }
          }
        }
      }
    }
  }
}
.tab_wrap {
  padding: 0 15px;
  overflow-x: auto;
  &.market_tab {
    height: 43px;
    min-height: 43px;
    background-color: #fff;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
  }
  &.type1 {
    background-color: $background-color-type2;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
  }
}
.banner_wrap {
  position: relative;
  background-color: $white;
  //max-height: 347px;
  .absolute_right {
    position: absolute;
    top: auto;
    bottom: 20px;
    right: 15px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.5);
    @include border-radius(15px);
    @include font(13, 24, -0.2);
    color: $white;
    z-index: 99;
    &:hover {
      color: $white;
    }
  }
  img {
    width: 100%;
    height: 100%;
    //max-height: 347px;
    object-fit: cover;
  }
}
.market_info {
  position: relative;
  padding: 14px 16px 16px;
  background-color: $white;
  .icon_coordinate {
    padding: 0 30px 0 20px;
    background-position: 0 6px;
    @include font(16, 30, -0.64, bold);
    color: $gray-dark;
  }
  .explain {
    margin: 5px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
}

.contents_unit {
  margin: 10px 0 0;
  background-color: $white;
  &:last-child {
    padding: 0 0 30px;
  }
  &.type1 {
    padding: 0 0 15px;
  }
}

.category_wrap {
  padding: 0 0 10px;
  margin: 10px 0 0;
  background-color: $white;
  text-align: center;
  .contents_title {
    text-align: left;
  }
  .inline_list {
    width: 100%;
    max-width: 650px;
  }
}

.empty_list {
  padding: 20px 20px 30px;
  background-color: $white;
  text-align: center;
  .accent_txt {
    @include font(17, 24, 0);
    color: $black;
  }
  .txt {
    margin: 11px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
  //.btn_view_all_market {
  //  display: inline-block;
  //  width: 160px;
  //  height: 36px;
  //  background-color: $active-background-color;
  //  @include font(15, 37, 0);
  //  @include border-radius(20px);
  //  color: $white;
  //  &:hover {
  //    color: $white;
  //  }
  //}
}

.market_tab_container {
  position: relative;
  min-height: 750px;
  background-color: $white;
  //padding: 50px 0 0;
  .tab_wrap {
    position: relative;
    //position: static;
    //position: absolute;
    //top: 0;
    //position: fixed;
    //top: 155px;
    //left: 0;
    width: 100%;
    z-index: 99;
  }
}

.search_wrap {
  position: relative;
  height: 55px;
  background-color: #fff;
}
.market_container {
  &.fixed {
    .header_wrap {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
    }

    .search_wrap {
      height: 113px;
      padding: 58px 0 0;
      background-color: #fff;
    }
    &.fixed_tab {
      .second_header {
        position: fixed;
        top: -56px;
        width: 100%;
        z-index: 100;
      }
      .market_contents {
        padding: 156px 0 0 0;
      }
      &.fixed_product,
      &.fixed_at,
      &.fixed_hot,
      &.fixed_new,
      &.fixed_quick {
        .market_contents {
          padding: 207px 0 0 0;
        }
      }
      /*.search_wrap {
        height: 156px;
        padding: 58px 0 43px;
      }
      .tab_wrap.market_tab {
        position: fixed;
        top: 58px;
        width: 100%;
        background-color: #fff;
        z-index: 999;
      }
      .tab_wrap.productTab {
        position: fixed;
        top: 101px;
        width: 100%;
        z-index: 999;
      }
      .market_tab_container {
        position: relative;
        padding: 50px 0 0;
      }*/

      /*.market_tab_container {
        //padding: 50px 0 0;
        .tab_wrap.productTab {
          position: fixed;
          top: 100px;
          width: 100%;
          //background-color: #fff;
          //z-index: 999;
        }
      }*/
    }
  }
}

.btn_share {
  position: absolute;
  top: 14px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  background-image: url("~image/version_3_5/share.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  text-indent: -9999px;
}
</style>
