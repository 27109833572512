<template>
  <div>
    <TopHeader title="보유쿠폰" theme="nj_white" :isButton="true" @goBack="goBack" />
    <div class="content">
      <div class="coupon_input_wrap">
        <NJInput placeholder="쿠폰 일련번호를 입력해주세요." :value="couponNumber" @input="setCouponNumber($event)" />
        <span class="add_btn" @click="addCoupon({ userId, couponNumber })">등록</span>
      </div>
      <CouponList :coupons="coupons" :isSelectable="isSelectable" />
    </div>

    <Alert
      :displayLayer="displayAlertLayer"
      :texts="['쿠폰 일련번호를 입력해주세요.']"
      :textClass="[]"
      :btns="[
        {
          type: null,
          btnId: '',
          btnData: {},
          value: '확인',
        },
      ]"
      :showHideLayer="showHideAlertLayer"
    />
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import TopHeader from "@/components/TopHeader";
import NJInput from "@/components/NJInput";
import CouponList from "@/page/Coupon/Components/CouponList";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Coupon",
  components: {
    CouponList,
    NJInput,
    TopHeader,
    Alert,
  },
  data: function () {
    return {
      userId: this.$route.query.userId,
      marketId: this.$route.query.marketId,
      couponId: this.$route.query.couponId,
      totalPrice: this.$route.query.totalPrice,
    };
  },
  methods: {
    ...mapMutations("coupon", ["showHideAlertLayer"]),
    ...mapActions("coupon", ["addCoupon", "setCouponNumber", "getCoupon", "goBack"]),
  },
  created() {
    const data = {
      userId: this.userId,
      marketId: this.marketId,
      couponId: this.couponId,
      totalPrice: this.totalPrice,
    };

    this.$store.dispatch("coupon/initParams", data);
    this.getCoupon();
  },
  computed: {
    ...mapState({
      coupons: (state) => state.coupon.coupons,
      couponNumber: (state) => state.coupon.inputCouponNumber,
      displayAlertLayer: (state) => state.coupon.displayAlertLayer,
    }),
    isSelectable: function () {
      return this.marketId ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 44px);
  background: $nj_background_gray;
  overflow: hidden;

  .coupon_input_wrap {
    position: relative;
    padding: 20px;

    .nj_input_wrap {
      padding: 15px 90px 15px 20px;
    }
    .add_btn {
      position: absolute;
      top: 50%;
      right: 20px;
      padding: 0px 20px;
      border-left: 1px solid $nj_divider_gray;
      @include font(16, 24, -0.96);
      color: $nj_red;
      transform: translateY(-50%);
    }
  }
}
</style>
