<template>
  <div class="wrap">
    <TopHeader :title="title" theme="nj_white" @goBack="goBack" />

    <div class="contents_wrap">
      <div>
        <div class="contents_header_wrap">
          <div class="contents_header">
            <div class="title">상품 판매중</div>
          </div>
        </div>
        <div v-if="serviceOnStoreList.length == 0" class="empty_txt">판매중인 상품이 없습니다.</div>
        <StoreList v-else :list="serviceOnStoreList" @goLink="goLink" serviceOnOff="On" />
      </div>

      <div>
        <div class="contents_header_wrap">
          <div class="contents_header">
            <div class="title">SOLD OUT</div>
          </div>
        </div>
        <div v-if="serviceOffStoreList.length == 0" class="empty_txt">준비중인 상품이 없습니다.</div>

        <StoreList v-else :list="serviceOffStoreList" @goLink="goLink" serviceOnOff="Off" />
      </div>
    </div>

    <Layer :displayLayer="displayLayer" displayType="center" :closeLayer="showHideLayer">
      <template v-slot:content>
        <div class="layer_contents">
          <div class="layer_title">단골끊기</div>
          <p class="layer_txt">해당 상점에 단골을 끊으시겠습니까?</p>
        </div>
        <ul class="half_list type1 mt_10">
          <li>
            <Btn type="type1 none_border" value="취소" @btnCallback="showHideLayer" />
          </li>
          <li>
            <Btn type="type1 reverse" btnId="REGULAR_Y" value="단골끊기" @btnCallback="showHideLayer" />
          </li>
        </ul>
      </template>
    </Layer>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import StoreList from "../../components/list/StoreList";
import Layer from "@/components/Layer";
import Btn from "@/components/Btn";
import { mapState, mapActions } from "vuex";

export default {
  name: "FavoriteStore",
  props: {},
  computed: {
    ...mapState({
      title: (state) => state.favoriteStore.title,
      serviceOffStoreList: (state) => state.favoriteStore.serviceOffStoreList,
      serviceOnStoreList: (state) => state.favoriteStore.serviceOnStoreList,
      displayLayer: (state) => state.favoriteStore.displayLayer,
    }),
  },
  beforeCreate() {
    this.$store.dispatch("favoriteStore/initState", {
      userId: this.$route.query.userId,
    });
  },
  methods: {
    ...mapActions("favoriteStore", ["goBack", "goLink", "showHideLayer"]),
  },
  components: {
    TopHeader,
    StoreList,
    Layer,
    Btn,
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}

.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  //background-color: $nj_background_gray;
  overflow: auto;
}

.contents_header_wrap {
  padding: 0 20px;
  .contents_header {
    height: 50px;
    padding: 18px 0 0;
    border-style: solid;
    border-color: $nj_divider_gray;
    border-width: 0 0 1px;
    // height: 54px;
    // padding: 19px 0 0;
  }
  .title {
    @include font(14, 20, -0.84);
    color: $nj_text_main_gray;
  }
}

.empty_txt {
  height: 100px;
  padding: 20px 0 0;
  @include font(15, 22, -1.2);
  text-align: center;
  color: $nj_placeholder_gray;
}
.layer_title {
  margin: 10px 0 0;
  @include font(20, 26, -1.2, bold);
  color: $nj_black;
}
.layer_txt {
  margin: 20px 0 0;
  @include font(16, 24, -0.96);
  color: $nj_text_sub_gray;
}
</style>
