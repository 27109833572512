<template>
  <div class="page_wrap">
    <TopHeader @goBack="goBack" :title="title" theme="nj_red"> </TopHeader>
    <NoticeTab :tabList="tabList" :currentTabId="currentTabId" @click="onTabClick"></NoticeTab>
    <div class="content" @scroll="handleScroll">
      <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
      <!--      <keep-alive>-->
      <component v-else :is="currentTab.component" :notices="currentTab.notices" @onNoticeClick="returnDetail" />
      <!--      </keep-alive>-->
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import NoticeTab from "./components/NoticeTab";
import NotificationList from "./components/NotificationList";
import NoticeList from "./components/NoticeList";
import ManualList from "./components/ManualList";
import LoadingIndicator from "../../components/LoadingIndicator";

export default {
  name: "Notice",
  props: {},
  data: function () {
    return {
      title: "알림화면",
      currentTabId: 1,
      tabList: [
        {
          id: 1,
          label: "전체알림",
          component: NotificationList,
          isBadge: false,
          notices: [],
          url: "all-markets/banners",
          curPage: 0,
        },
        {
          id: 2,
          label: "공지사항",
          component: NoticeList,
          isBadge: false,
          notices: [],
          url: "all-markets/articles",
          curPage: 0,
        },
        {
          id: 3,
          label: "사용법",
          component: ManualList,
          isBadge: false,
          notices: [],
          url: "manuals",
          curPage: 0,
        },
      ],
      pageSize: 30,
      isProgressing: false,
    };
  },
  components: {
    TopHeader,
    NoticeTab,
    NotificationList,
    NoticeList,
    ManualList,
    LoadingIndicator,
  },
  methods: {
    handleScroll: function (event) {
      if (!this.isProgressing) {
        const eventTarget = event.currentTarget;
        if (eventTarget.scrollTop + eventTarget.offsetHeight > eventTarget.scrollHeight - 50) {
          this.isProgressing = true;
          this.getMoreNotice();
        }
      }
    },
    goBack: function () {
      this.$closeView();
    },
    onTabClick: function (tabId) {
      this.currentTabId = tabId;
    },
    getAllNoticeData: async function () {
      this.isProgressing = true;

      const urlArr = ["all-markets/banners", "all-markets/articles", "manuals"];

      const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;

      for (let index = 0; index < urlArr.length; index++) {
        await this.$axios2
          .get("/v3/notices/member-notices/" + urlArr[index] + "?page=" + this.curPage + "&size=" + this.pageSize + "&userId=" + userId)
          .then((response) => {
            if (response.data.responseCode == 2000) {
              this.tabList[index].notices = response.data.result.notices;
              this.tabList[index].isBadge = response.data.result.hasNewNotices == "Y" ? true : false;
              this.tabList[index].hasNewNotices = response.data.result.hasNewNotices == "Y" ? true : false;
            } else {
              alert(response.data.errorObject.errorMessage);
              this.tabList[index].notices = [];
              this.tabList[index].isBadge = false;
              return false;
            }
          })
          .catch(() => {
            this.isProgressing = false;
            return false;
          });
      }
      this.isProgressing = false;
    },
    getMoreNotice: async function () {
      this.isProgressing = true;
      const url = this.currentTab.url;
      const page = this.currentTab.curPage + 1;

      const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;

      await this.$axios2
        .get("/v3/notices/member-notices/" + url + "?page=" + page + "&size=" + this.pageSize + "&userId=" + userId)
        .then((response) => {
          if (response.data.responseCode == 2000) {
            this.currentTab.notices = [...this.currentTab.notices, ...response.data.result.notices];
            this.currentTab.isBadge = response.data.result.hasNewNotices == "Y" ? true : false;
            this.currentTab.hasNewNotices = response.data.result.hasNewNotices == "Y" ? true : false;
          } else {
            alert(response.data.errorObject.errorMessage);
            this.currentTab.notices = [];
            this.currentTab.isBadge = false;
            return false;
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
      this.isProgressing = false;
    },
    returnDetail: function (notice) {
      const payload = {
        noticeId: notice.noticeId,
        noticeTitle: notice.title,
        noticeDetailUrl: notice.detailImage,
      };
      this.$callNative("showNoticeDetail", payload);
    },
  },
  computed: {
    currentTab() {
      return this.tabList.find((el) => el.id === this.currentTabId) || {};
    },
  },
  created: function () {
    this.getAllNoticeData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="./Notice.scss"></style>
