const state = {
  marketId: null,
  storeId: null,
  userId: null,
  isTbStore: null,

  storeInfo: {}, // 상점 정보
  businessInfo: {}, // 상점 사업자 정보
  regularInfo: {}, // 단골 여부 정보

  representProduct: [], // 대표 상품
  saleProducts: [], // 세일 상품
  newProducts: [], // 오늘 들어왔어요 상품
  products: [], // 기본 상품

  isStoreDetailShow: false, // 매장정보 화면 표시 여부
  isProgressing: false, // API 프로세스 처리 진행 중 여부
  displayLayer: false, // 단골 맺기/끊기 Confirm 표시 여부
};

const mutations = {
  initState: function (state, data) {
    Object.keys(data).forEach((item) => {
      state[item] = data[item];
    });
  },
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  moveToApp: function (state, data) {
    if (data.type == "PRODUCT") {
      this._vm.$callNative("moveToProduct", { productId: data.productId });
    } else if (data.type == "PRODUCT_OPTION") {
      this._vm.$callNative("moveToProductOption", {
        productId: data.productId,
      });
    } else if (data.type == "CALL") {
      this._vm.$callNative("showCall", {
        phoneNumber: state.businessInfo.businessCall,
      });
    }
  },
  goBack: function () {
    this._vm.$closeView();
  },
};

const actions = {
  initState: async function (store, data) {
    if (!data.marketId || !data.storeId) {
      return false;
    }

    store.commit("updateState", {
      type: "marketId",
      updateData: data.marketId,
    });

    // 택배 시장 구분 하드 코딩
    store.commit("updateState", {
      type: "isTbStore",
      updateData: data.marketId == 23 ? true : false,
    });

    store.commit("updateState", {
      type: "storeId",
      updateData: data.storeId,
    });

    store.commit("updateState", {
      type: "userId",
      updateData: data.userId,
    });

    store.commit("updateState", {
      type: "isProgressing",
      updateData: true,
    });

    let regularResponse = {};

    const axios = this._vm.$axios1;
    try {
      if (data.userId) {
        regularResponse = await getRegularInfo(axios);
      }

      const storeResponse = await this._vm.$axios1.get("v3/market/delivery/store/" + state.marketId + "/" + state.storeId + "/information");
      const businessResponse = await this._vm.$axios1.get(
        "v3/market/delivery/store/" + state.marketId + "/" + state.storeId + "/business/information",
      );

      const legacyAxios = this._vm.$axiosLegacy;

      if (!state.isTbStore) {
        const representProductResponse = await getProduct(ProductType.REPRESENT, data, axios);
        const saleProductResponse = await getProduct(ProductType.SALE, data, axios);
        const newProductResponse = await getProduct(ProductType.NEW, data, axios);
        const productResponse = await getProduct(ProductType.PRODUCTS, data, legacyAxios);

        productResponse.data.result.forEach((product) => {
          product.productImageUrl = product.imgUrl;
          product.productName = product.productNm;
          product.productLikeCount = product.likeCount;
          product.unitNumber = product.saleUnit;
          product.unitName = product.unitNm;
          if (product.paymentTypeList) {
            product.paymentTypeList.forEach((paymentType) => {
              paymentType["paymentName"] = paymentType.paymentType;
            });
          }
        });

        store.commit("initState", {
          storeInfo: storeResponse.data.result, // 상점 정보
          businessInfo: businessResponse.data.result, // 상점 사업자 정보
          regularInfo: Object.keys(regularResponse).length ? regularResponse.data.result : {},
          representProduct: representProductResponse.data.result ? new Array(representProductResponse.data.result) : [], // 대표 상품 정보
          saleProducts: saleProductResponse.data.result.productList, // 세일 상품 정보
          newProducts: newProductResponse.data.result.productList, // 오늘 들어왔어요 상품 정보
          products: productResponse.data.result, // 기본 상품 정보
        });
      }

      store.commit("updateState", {
        type: "isProgressing",
        updateData: false,
      });
    } catch (e) {
      console.error(e);
    }
  },
  goBack: function (store) {
    if (state.isStoreDetailShow) {
      store.dispatch("setStoreDetailShow", false);
    } else {
      store.commit("goBack");
    }
  },
  moveToApp: function (store, data) {
    store.commit("moveToApp", data);
  },
  setStoreDetailShow: function (store, isShow) {
    store.commit("updateState", {
      type: "isStoreDetailShow",
      updateData: isShow,
    });
  },
  toggleRegular: async function (store) {
    if (!state.userId) {
      this.$callNative("showIdentifyVerification");
      return false;
    }

    try {
      const toggleResponse = await this._vm.$axios1.put(
        "v3/user/store/market/" + state.marketId + "/store/" + state.storeId + "/regular/" + state.userId,
        { regularYn: state.regularInfo.regularYn == "Y" ? "N" : "Y" },
      );
      if (toggleResponse.data.responseCode == 2000) {
        const regularRes = await getRegularInfo(this._vm.$axios1);
        store.commit("updateState", {
          type: "regularInfo",
          updateData: regularRes.data.result,
        });

        const storeResponse = await this._vm.$axios1.get("v3/market/delivery/store/" + state.marketId + "/" + state.storeId + "/information");
        store.commit("updateState", {
          type: "storeInfo",
          updateData: storeResponse.data.result, // 상점 정보
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  showLayer: function (store) {
    store.commit("updateState", {
      type: "displayLayer",
      updateData: true,
    });
  },
  hideLayer: function (store, data) {
    if (data.btnId && data.btnId == "REGULAR") {
      store.dispatch("toggleRegular");
    }
    state.displayLayer = false;
  },
};

const getters = {
  title: (state) => {
    return state.storeInfo.storeName;
  },
  tabList: (state) => {
    return [
      { id: 1, label: "대표상품", products: state.representProduct },
      { id: 2, label: "세일상품", products: state.saleProducts },
      { id: 3, label: "오늘 들어왔어요", products: state.newProducts },
      { id: 4, label: "기본상품", products: state.products },
    ];
  },
};

const ProductType = {
  REPRESENT: "representative",
  SALE: "discount",
  NEW: "new-merchandise",
  PRODUCTS: "",
};

const getRegularInfo = function (axios) {
  let response = {};

  try {
    response = axios.get("v3/market/delivery/store/market/" + state.marketId + "/store/" + state.storeId + "/regular?userId=" + state.userId);
  } catch (e) {
    console.error(e);
  }
  return response;
};

const getProduct = function (type, { marketId, storeId }, axios) {
  let url = "";

  if (type != ProductType.PRODUCTS) {
    type = type ? "/" + type : type;
    url = "/v3/market/delivery/product/market/" + marketId + "/store/" + storeId + type + "/bd";
  } else {
    url = "/v3/product/market/bd/store/product/" + marketId + "/" + storeId;
  }

  let response = {};
  try {
    response = axios.get(url);
  } catch (e) {
    console.log(url);
    console.error(e);
    throw e;
  }

  return response;
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
