<template>
  <ul class="list" :class="{ view_list: pageMode == 'view' ? true : false }">
    <li v-for="(list, index) in inquiryList" :key="list.id ? list.id : list.keyId" :id="'inquiry_' + list.keyId">
      <div class="store_name">{{ list.storeName }}</div>
      <div class="item">
        <div class="list_title">
          <span>{{ list.productName }}, </span>
          <span v-if="list.weight">{{ $formatPrice(list.weight) + "g" }} </span>
          <span v-if="list.unitNumber">{{ list.unitNumber }}{{ list.unitName }}</span>
        </div>
        <div class="list_price">주문수량 : {{ $formatPrice(list.quantity) }}</div>

        <div v-if="pageMode != 'write'" class="inquiry_qty">문의개수 : {{ $formatPrice(list.inquiryQty) }}</div>
        <div v-else class="inquiry_qty plus_minus">
          <div class="quantity_wrap">
            <a
              href="javascript:void(0)"
              class="quantity_btn btn_minus"
              @click="
                $emit('inquiryIncreaseDecrease', {
                  type: 'DECREASE',
                  productId: list.keyId,
                  inquiryQty: list.inquiryQty,
                  event: $event,
                })
              "
              >감소</a
            >
            <div class="quantity_txt">
              {{ list.inquiryQty ? list.inquiryQty : 0 }}
            </div>
            <a
              href="javascript:void(0)"
              class="quantity_btn btn_plus"
              @click="
                $emit('inquiryIncreaseDecrease', {
                  type: 'INCREASE',
                  productId: list.keyId,
                  inquiryQty: list.inquiryQty,
                  event: $event,
                })
              "
              >증가</a
            >
          </div>
        </div>
      </div>

      <ul class="opt_list" v-if="list.optionGroups">
        <li v-for="(optionGroup, groupIndex) in list.optionGroups" :key="optionGroup.optionGroupId">
          <span class="opt" v-for="(option, index) in optionGroup.optionsItems" :key="option.optionItemId">
            {{ groupIndex != 0 || index != 0 ? "/" : null }}
            {{ option.optionItemName }}

            <span>({{ "+" + $formatPrice(Number(option.optionPrice)) + "원" }})</span>
          </span>
        </li>
      </ul>

      <div v-if="pageMode == 'view'">
        <div class="msg" v-if="list.message">
          <div class="message">{{ list.message }}</div>
        </div>
        <div class="img_area" v-for="(img, index) in list.imgUrlList" :key="list.inquiryId + '_' + index">
          <img :src="img" alt="" />
        </div>
      </div>
      <div v-else>
        <div class="targetContainer">
          <div class="item msg" :class="{ disabled: list.inquiryQty > 0 ? false : true }">
            <div
              class="contenteditable"
              :contenteditable="list.inquiryQty > 0 ? true : false"
              :placeholder="list.inquiryQty > 0 ? '상품문의 사유를 입력해 주세요.' : '상품문의 개수를 추가해 주세요.'"
              :data-id="list.productId"
              :data-product-id="list.keyId"
              :data-order-product-id="list.orderProductId"
              :data-market-id="list.marketId"
              :id="'contenteditable_' + list.keyId"
            />
          </div>

          <div class="input_wrap" :class="{ inactive: list.inquiryQty > 0 ? false : true }">
            <!-- v-if="isIos" -->
            <!--            <img style="width: 500px;" id="preview-image" src="https://dummyimage.com/500x500/ffffff/000000.png&text=preview+image">-->

            <input
              type="file"
              class="inquiryInput"
              accept="image/*"
              :id="'fileInput_' + list.keyId"
              :disabled="list.inquiryQty > 0 ? false : true"
              @change="
                $emit('onChangeFileUpload', {
                  type: 'FILE',
                  orderProductId: list.keyId,
                  event: $event,
                  index: index,
                })
              "
            /><!-- accept="image/*" multiple="multiple" -->

            <input
              type="file"
              capture="camera"
              accept="image/*"
              :id="'fileInput_camera_' + list.keyId"
              @change="
                $emit('onChangeFileUpload', {
                  type: 'FILE',
                  orderProductId: list.keyId,
                  event: $event,
                  index: index,
                })
              "
            />

            <div class="input_title">첨부하기</div>
            <div class="absolute_right">
              <label :for="'fileInput_camera_' + list.keyId" class="icon_device_camera">카메라</label>
              <label :for="'fileInput_' + list.keyId" class="icon_device_image ml_20">파일업로드</label>
            </div>

            <div class="mt_15" :id="'imgPreview_' + list.keyId"></div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
// import Icon from '@/components/Icon';

export default {
  name: "InquiryList",
  props: {
    pageMode: String,
    inquiryList: Array,
    isIos: Boolean,
  },
  // methods: {
  // },

  // data: function () {
  //   return {
  //     inquiryList: [
  //       {
  //         imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/inquiry/20200527150549-1590559534663_image.jpg',
  //         inquiryQty: 1,
  //         marketId: 2,
  //         message: '고기시켰는데 에그가 옴',
  //         orderId: 'OID2020052723F1030D0D8F418F977E248DAD8C8363',
  //         orderQty: 1,
  //         pickupStatus: 'D',
  //         price: 2000,
  //         procStatus: '1',
  //         productId: 4100,
  //         productName: '클로렐라고기햄버거',
  //         receiptYn: 'Y',
  //         saleQty: 1,
  //         saleUnit: '개',
  //         storeId: 11,
  //         storeName: '클로렐라 햄버거',
  //       },
  //       {
  //         imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/inquiry/20200527150549-1590559534663_image.jpg',
  //         inquiryQty: 1,
  //         marketId: 2,
  //         message: '고기시켰는데 에그가 옴 1',
  //         orderId: 'OID2020052723F1030D0D8F418F977E248DAD8C8363',
  //         orderQty: 1,
  //         pickupStatus: 'D',
  //         price: 2000,
  //         procStatus: '1',
  //         productId: 4101,
  //         productName: '클로렐라고기햄버거 1',
  //         receiptYn: 'Y',
  //         saleQty: 1,
  //         saleUnit: '개',
  //         storeId: 11,
  //         storeName: '클로렐라 햄버거 1',
  //       }
  //     ]
  //   }
  // },
  components: {
    // Icon,
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-user-select: none;
  user-select: none;
}

input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.store_name {
  margin: 15px 0 0;
  @include font(14, 22, -0.5);
  font-weight: 100;
  color: $active-color;
}

.list_title {
  //padding: 0 30px 0 0;
  padding: 0;
  @include font(16, 22, -0.5, medium);
  color: $black;
  //> span {
  //  display: inline-block;
  //}
}
.list_right {
  position: absolute;
  top: 20px;
  right: 20px;
  @include font(12, 22, -0.5, bold);
  color: $nj_red !important;
}
.list_price {
  margin: 3px 0 0;
  @include font(13, 18, -0.5);
  color: $nj_text_sub_gray;
}
.list {
  &.view_list {
    > li {
      // padding: 20px 0;
      padding: 0 0 20px;
      //&:first-child {
      //  padding: 0 0 20px;
      //}
      //&:last-child {
      //  padding: 20px 0;
      //}

      .item {
        padding: 0 100px 20px 0px;
      }
    }
    .list_right {
      top: 25px;
    }
  }
  > li {
    // padding: 20px 0 20px;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: $nj_divider_gray;

    //&:first-child {
    //  padding: 0 0 20px;
    //}
    &:last-child {
      border-width: 0;
    }
  }
  .item {
    display: block;
    position: relative;
    padding: 0 140px 0px 0px;
    //padding: 21px 140px 20px 0px;
    background: $white;
    //@include border-radius(20px);
    //box-shadow: 0px 1px 0px $nj_shadow_gray;
    color: $nj_black;
    &:hover {
      color: $nj_black;
    }
  }
  .msg {
    height: auto;
    padding: 11px 10px 10px;
    //margin: 10px 0 0;
    border: {
      style: solid;
      color: $border-type1;
      width: 1px;
    }
    @include border-radius(5px);
    &.active {
      display: block;
    }

    &.disabled {
      background-color: $background-color-type1;
    }
  }

  .message {
    display: block;
    width: 100%;
    // min-height: 110px;
    height: auto;
    border-width: 0;
    @include font(16, 24, -0.5);
    color: $black;
    outline: none;
    //white-space: nowrap;
    overflow: hidden;
  }

  .img_area {
    margin: 20px 0 0;
    text-align: center;
    img {
      @include border-radius(10px);
    }
  }

  .inquiry_qty {
    position: absolute;
    top: 3px;
    right: 0;
    //transform: translate(0, -55%);
    @include font(16, 20, -0.5, medium);
    color: $nj_black;
    //&.plus_minus {
    //transform: translate(0, -66%);
    //}
  }

  input[type="file"] {
    display: none;
  }
  .input_wrap {
    position: relative;
    //height: 45px;
    padding: 14px 0;
    &.inactive {
      opacity: 0.3;
    }
    .input_title {
      @include font(16, 18, 0, bold);
      color: $black;
    }
    //label {
    //  top: 5px;
    //}

    .absolute_right {
      top: 10px;
    }
  }
}

.opt_list {
  line-height: 14px;
  margin: 5px 0 0;
  > li {
    display: inline;
    @include font(14, 14, -0.72);
    color: $gray-light;
  }
}

.quantity_wrap {
  //margin-top: 10px;
  height: 30px;

  & > div,
  & > a {
    display: inline-block;
    vertical-align: top;
  }

  .quantity_btn {
    //width: 36px;
    //height: 100%;
    width: 46px;
    height: 40px;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    text-indent: -9999px;
    &.btn_minus {
      background: url("~svg/btn_minus.svg");
    }

    &.btn_plus {
      background: url("~svg/btn_plus.svg");
      //background: url('../../assets/images/icon_plus.png');
    }
  }

  .quantity_txt {
    height: 100%;
    padding: 7px 10px;
    @include font(22, 27, -1.32, medium);
    color: #262626;
    //font-size: 22px;
    //line-height: 30px;
  }
}

.contenteditable:empty:before {
  content: attr(placeholder);
  display: block;
  //color: $nj_placeholder_gray;
  color: $gray-middle-light;
}
.contenteditable {
  display: block;
  width: 100%;
  min-height: 110px;
  height: auto;
  border-width: 0;
  @include font(15, 22, -0.96);
  color: $nj_text_main_gray;
  outline: none;
  //white-space: nowrap;
  overflow: hidden;
}
.contenteditable br {
  display: none;
}
.contenteditable * {
  display: inline;
  white-space: nowrap;
}
</style>
