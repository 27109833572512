<template>
  <div class="header_wrap" :class="type">
    <div class="back_btn_wrap" v-if="isBackVisible">
      <a href="javascript:void(0)" class="icon_top_back" @click="onBackPressed" title="뒤로가기">뒤로가기</a>
    </div>
    <div class="title_wrap" :class="{ back_hidden: !isBackVisible, type1: isHomeVisible }">
      <img v-if="icon" :src="icon" loading="lazy" />
      <span>{{ title }}</span>
    </div>
    <div class="btn_wrap absolute_right">
      <template v-if="isHomeVisible">
        <a href="javascript:void(0)" class="icon_top_home" @click="onHomePressed" title="홈">홈 </a>
      </template>
      <template v-if="isCartVisible && appVersion">
        <a href="javascript:void(0)" class="icon_top_cart" @click="onCartPressed" title="장바구니"
          >장바구니
          <div class="badge" v-if="userCartTotalCount">
            {{ userCartTotalCount }}
          </div>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TopHeader",
  props: {
    title: String,
    icon: String,
    isBackVisible: {
      type: Boolean,
      default: true,
    },
    isCartVisible: {
      type: Boolean,
      default: true,
    },
    isHomeVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "default",
      // validator: function (value) {
      //   // 값이 아래 문자열 중 하나여야 합니다.
      //   return ["default", "type1"].indexOf(value) !== -1;
      // },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      userCartTotalCount: (state) => state.userCartTotalCount, // 장바구니 카운터
      appVersion: (state) => state.appVersion,
    }),
  },
  methods: {
    // 뒤로 가기 버튼 클릭 시
    onBackPressed(e) {
      if (Object.keys(this.data).length > 0) {
        e.data = this.data;
      }
      this.$emit("goBack", e);
    },
    // 장바구니 버튼 클릭 시
    onCartPressed() {
      //  Route To Cart
      this.$router.push({ name: "장바구니", params: { reload: true } });
    },
    onHomePressed() {
      this.$router.push({ name: "놀장 메인" });
    },
  },
  created() {
    if (navigator.userAgent.match(/Android/i)) {
      document.querySelector("#app").classList.add("android");
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      document.querySelector("#app").classList.add("ios");
    }
  },
};
</script>

<style lang="scss" scoped>
.header_wrap {
  position: relative;
  height: 58px;
  background-color: $white;
  border: {
    width: 0 0 1px 0;
    style: solid;
    color: $border-type1;
  }
  &.version_3_5 {
    border: {
      width: 0;
    }
  }

  &.fixed {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
  }

  a {
    display: block;
    text-indent: -9999px;

    > div {
      text-indent: 0;
    }
  }

  > a {
    display: inline-block;
    vertical-align: top;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .back_btn_wrap {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    .icon_top_back {
    }
  }

  .btn_wrap {
    top: calc(50% + 2px);
    right: 25px;
    transform: translateY(-50%);

    .icon_top_cart .badge {
      display: inline-block;
      position: absolute;
      top: -11px;
      right: -10px;
      min-width: 18px;
      max-height: 19px;
      padding: 2px 4px 0;
      background-color: $active-background-color;
      @include border-radius(15px);
      font-family: "gothic_a1_semibold";
      font-size: 10px;
      color: $white;
      text-align: center;
      text-indent: 0;
    }
  }

  .title_wrap {
    position: absolute;
    top: 50%;
    left: 35px;
    width: calc(100% - 35px);
    //height: 100%;
    transform: translateY(-50%);
    @include text_ellipsis(1, 30);

    &.back_hidden {
      left: 10px;
    }

    &.type1 {
      padding: 0 100px 0 0;
    }

    > img,
    > span {
      display: block;
    }

    > img {
      margin-right: 10px;
      height: 40px;
    }

    > span {
      width: 100%;
      //line-height = header_wrap 높이 - 20px
      @include font(20, normal, normal, medium);
    }
  }

  &.type1 {
    border: {
      width: 0 0 3px 0;
      style: solid;
      color: $border-type1;
    }
  }
}

.android {
  .header_wrap {
    .title_wrap {
      padding: 2px 0 0 0;
      &.type1 {
        padding: 2px 90px 0 0;
      }
    }
  }
}

.ios {
  .btn_wrap {
    .icon_top_cart {
      .badge {
        padding: 0 4px 0;
      }
    }
  }
}
</style>
