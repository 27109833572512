<template>
  <div class="page_wrap">
    <TopHeader @goBack="goBack" :title="title" theme="nj_red" />
    <div class="content">
      <FAQList :faqs="faqs" />

      <!--      <div class="btn_wrap">-->
      <!--        <Btn value="이메일 보내기" type="type1 reverse"></Btn>-->
      <!--      </div>-->

      <div class="footer_wrap mt_50">
        <span class="title">카카오톡 채널 문의</span>
        <span class="tel">@noljang</span>
      </div>
      <div class="footer_wrap mb_20">
        <span class="title">문의</span>
        <span @click="goCall" class="tel">{{ tel }}</span>
        <span class="time">평일 10:00 - 18:30</span>
        <span class="time">점심 12:00 - 13:00</span>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import FAQList from "@/page/FAQ/components/FAQList";
// import Btn from "@/components/Btn";

export default {
  name: "FAQ",
  props: {},
  data: function () {
    return {
      title: "고객센터",
      faqs: [],
      tel: this.$getTelNumber(),
    };
  },
  beforeCreate: function () {
    const axiosUrl = "/api/getFaq";
    this.$axiosLegacy
      .post(axiosUrl)
      .then((response) => {
        // console.log(response);
        if (response.data.resultCode == 200) {
          this.faqs = response.data.result.csList;
          this.isProgressing = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this.isProgressing = false;
      })
      .catch(() => {
        this.isProgressing = false;
      });
  },
  components: {
    // Btn,
    TopHeader,
    FAQList,
  },
  methods: {
    goBack: function () {
      this.$closeView();
    },
    goCall: function () {
      this.$callNative("showCall", {
        phoneNumber: this.tel.replaceAll(".", ""),
      });
    },
  },
  computed: {},
  created: function () {},
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page_wrap {
  .content {
    position: relative;
    height: calc(100vh - 44px);
    padding: 20px;
    overflow-x: auto;

    .btn_wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
    }
  }
}

.footer_wrap {
  padding: 20px 0 0;
  text-align: center;

  span {
    display: block;
  }

  .title {
    @include font(16, 24, -0.96, bold);
    color: $nj_black;
  }

  .tel {
    @include font(24, 36, -0.48, bold);
    color: $nj_red;
  }

  .time {
    @include font(16, 24, -0.64);
    color: $nj_text_main_gray;
  }
}
</style>
