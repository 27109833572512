<template>
  <div class="address_main">
    <TopHeader class="top_header" :title="contentsType == 'select' ? '배송지 선택' : '배송지 설정'" :isCartVisible="false" @goBack="goBack" />
    <div class="contents">
      <LoadingIndicator v-if="isProgressing" />
      <!--      배송지 선택 -->
      <div class="address_select" v-if="contentsType == 'select'">
        <div class="btn_wrap">
          <Btn
            class="add_address_btn"
            :value="userId ? '배송지 추가' : Object.keys(shippingAddress).length ? '배송지 변경' : '배송지 설정'"
            type="type2"
            @btnCallback="onAddAddressClick"
          />
        </div>
        <template v-if="isAddressEmpty">
          <div class="address_empty_wrap">
            <span class="icon_logo"></span>
            <span class="desc_main">
              대한민국 전통시장이<br />
              손 안에 들어오다
            </span>
            <span class="desc_sub">
              전통시장 장보기와 배달을 한 번에~<br />
              배달받을 주소를 설정하고 시작해보세요!
            </span>
          </div>
        </template>
        <template v-else>
          <div class="address_list vertical_list">
            <ul>
              <!--            집 주소 리스트-->
              <template v-if="homeAddresses.length > 0">
                <li v-for="address in homeAddresses" :key="address.shipToId" class="address_item" :class="{ active: address.isActive }">
                  <a href="javascript:void(0)" @click="handleClick($event, address)">
                    <span class="icon icon_home" :class="{ active: address.isActive }" />
                    <div class="address_info_wrap">
                      <span class="title">{{ address.shipToName }}</span>
                      <div class="address_wrap mt_10">
                        <span>{{ address.shipToDoro }}</span>
                        <span>{{ address.shipToDetail }}</span>
                      </div>
                    </div>
                    <span class="icon_close" v-if="userId && !address.isActive"></span>
                  </a>
                </li>
              </template>
              <!--            집 주소 없을 경우-->
              <li class="address_item" v-else-if="userId">
                <a href="javascript:void(0)" @click="setContentsType({ type: 'setting', data: 'home' })">
                  <span class="icon icon_home"></span>
                  <div class="address_info_wrap">
                    <span class="title">집 추가</span>
                  </div>
                  <span class="icon_more_arrow"></span>
                </a>
              </li>

              <!--            회사 주소 리스트-->
              <template v-if="companyAddresses.length > 0">
                <li v-for="address in companyAddresses" :key="address.shipToId" class="address_item" :class="{ active: address.isActive }">
                  <a href="javascript:void(0)" @click="handleClick($event, address)">
                    <span class="icon icon_company" :class="{ active: address.isActive }" />
                    <div class="address_info_wrap">
                      <span class="title">회사</span>
                      <div class="address_wrap mt_10">
                        <span>{{ address.shipToDoro }}</span>
                        <span>{{ address.shipToDetail }}</span>
                      </div>
                    </div>
                    <span class="icon_close" v-if="userId && !address.isActive"></span>
                  </a>
                </li>
              </template>
              <!--            회사 주소 없을 경우-->
              <li class="address_item" v-else-if="userId">
                <a href="javascript:void(0)" @click="setContentsType({ type: 'setting', data: 'company' })">
                  <span class="icon icon_company"></span>
                  <div class="address_info_wrap">
                    <span class="title">회사 추가</span>
                  </div>
                  <span class="icon_more_arrow"></span>
                </a>
              </li>

              <!--              집/회사를 제외한 등록 주소-->
              <li v-for="address in generalAddresses" :key="address.shipToId" class="address_item" :class="{ active: address.isActive }">
                <a href="javascript:void(0)" @click="handleClick($event, address)">
                  <span class="icon_address" :class="{ active: address.isActive }"></span>
                  <div class="address_info_wrap">
                    <!--                    서버에서 shipToName 이 Empty String 이 아닌 'null' 로 내려올 때도 있어 방어 코드 추가-->
                    <span class="title" v-if="address.shipToName && address.shipToName != 'null'">{{ address.shipToName }}</span>
                    <div class="address_wrap" :class="{ sub: address.shipToName }">
                      <span>{{ address.shipToDoro }}</span>
                      <span>{{ address.shipToDetail }}</span>
                    </div>
                  </div>
                  <span class="icon_close" v-if="userId && !address.isActive"></span>
                  <span class="icon_address_check" v-else-if="address.isActive"></span>
                </a>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <!--      배송지 검색 -->
      <div class="address_setting" v-else-if="contentsType == 'setting'">
        <SearchInput
          class="search_input"
          type="type1"
          placeholder="건물명, 도로명 또는 번지로 검색"
          ref="searchInput"
          :value="latestKeyword"
          @search="search"
          @focusin="setIsSearching(true)"
          @focusout="setIsSearching(false)"
        />
        <template v-if="!searchAddresses">
          <div class="search_tip_wrap">
            <span>TIP</span>
            <span>아래와 같은 조합으로 검색을 하시면 더욱 정확한 결과가 검색됩니다.</span>
            <div class="search_tip_list vertical_list">
              <ul>
                <li>
                  <span class="title">도로명 + 건물번호</span>
                  <span class="content">예) 판교역로 235, 제주 첨단로 242</span>
                </li>
                <li>
                  <span class="title">지역명(동/리) + 번지</span>
                  <span class="content">예) 삼평동 681, 제주 양평동 2181</span>
                </li>
                <li>
                  <span class="title">지역명(동/리) + 건물명(아파트명)</span>
                  <span class="content">예) 분당 주공, 연수동 주공 3차</span>
                </li>
                <li>
                  <span class="title">사서함명 + 번호</span>
                  <span class="content">예) 분당우체국사서함 1~100</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-else-if="searchAddresses">
          <div class="search_address_list vertical_list" @scroll="handleScroll">
            <template v-if="searchAddresses.length > 0">
              <ul>
                <li v-for="(address, idx) in searchAddresses" :key="idx">
                  <a href="javascript:void(0)" @click="setContentsType({ type: 'detail', data: address })">
                    <span class="jibun">{{ address.addressJibun }}</span>
                    <span class="doro_wrap">
                      <span class="red">도로명</span>
                      <span class="doro">{{ address.addressDoro }}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </template>
            <div class="no_result_msg" v-else>검색된 주소가 없습니다.</div>
          </div>
        </template>
      </div>
      <!--      배송지 상세-->
      <div class="address_detail" v-else-if="contentsType == 'detail'">
        <div class="address_detail_wrap">
          <span class="jibun">{{ currentSearchAddress.addressJibun }}</span>
          <span class="doro_wrap">
            <span class="red">도로명</span>
            <span class="doro">{{ currentSearchAddress.addressDoro }}</span>
          </span>
        </div>
        <div class="input_wrap">
          <input ref="detailInput" placeholder="상세주소 (아파트/동/호)" @input="addressDetail = $event.target.value" />
          <input
            placeholder="주소의 별칭을 입력해주세요. (집/회사)"
            @input="addressAlias = $event.target.value"
            v-model="addressAlias"
            :disabled="addressType"
          />
        </div>
        <div class="bottom_wrap">
          <Btn
            type="type4"
            value="완료"
            @btnCallback="
              setCurrentAddress({
                addressAlias,
                addressDetail,
                onInvalid: onInvalid,
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TopHeader from "@/components/new/TopHeader";
import Btn from "@/components/new/Btn";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import SearchInput from "@/components/new/SearchInput";

export default {
  name: "Address",
  props: {},
  data: function () {
    return {
      isSearching: false,
      addressDetail: "",
      addressAlias: "",
    };
  },
  components: {
    SearchInput,
    LoadingIndicator,
    Btn,
    TopHeader,
  },
  methods: {
    ...mapActions("address", ["initState", "goBack", "setContentsType", "doSearch", "handleScroll", "setCurrentAddress", "delAddress"]),
    search(keyword) {
      this.doSearch(keyword);
      this.setIsSearching(false);
    },
    setIsSearching(isSearching) {
      this.isSearching = isSearching;
    },
    onInput(type, event) {
      const value = event.target.value;
      if (type == "detail") {
        this.addressDetail = value;
      } else if (type == "alias") {
        this.addressAlias = value;
      }
    },
    async onAddAddressClick() {
      await this.setContentsType({ type: "setting" });
      this.$refs.searchInput.focusInput();
    },
    // 배송지 추가 처리 중, 상세주소가 없는 경우 호출되는 콜백
    onInvalid() {
      this.$refs.detailInput.focus();
    },
    handleClick: function (event, { shipToId, addressDetail, addressAlias }) {
      if (event.target.classList.contains("icon_close")) {
        this.delAddress(shipToId);
      } else if (!event.target.closest(".address_item").classList.contains("active")) {
        this.setCurrentAddress({ shipToId, addressDetail, addressAlias });
      } else {
        this.goBack();
        // this.setCurrentAddress();
        // console.log("current Address");
      }
    },
    /**
     * 뒤로가기 버튼 클릭 시 기능 처리
     */
    onBackPressed() {
      this.goBack();
    },
  },
  computed: {
    ...mapState({
      isProgressing: (state) => state.address.isProgressing,
      contentsType: (state) => state.address.contentsType,
      addresses: (state) => state.address.addresses,
      searchAddresses: (state) => state.address.searchAddresses,
      shippingAddress: (state) => state.address.shippingAddress,
      latestKeyword: (state) => state.address.latestKeyword,
      currentSearchAddress: (state) => state.address.currentSearchAddress,
      userId: (state) => state.address.userId,
      addressType: (state) => state.address.addressType,
    }),
    ...mapGetters({
      homeAddresses: "address/homeAddresses",
      companyAddresses: "address/companyAddresses",
      generalAddresses: "address/generalAddresses",
    }),
    isAddressEmpty: function () {
      // 로컬/서버 양쪽 모두에 등록된 주소가 없는 경우 (최초 접속)
      return !this.isProgressing && Object.keys(this.shippingAddress).length == 0 && this.addresses.length == 0;
    },
  },
  beforeCreate() {
    const isFromPayment = this.$route.params.isFromPayment ? JSON.parse(this.$route.params.isFromPayment) : false;
    this.$store.dispatch("address/initState", { isFromPayment });
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    // console.log(this.generalAddresses);
  },
  watch: {
    contentsType: function (next, prev) {
      if (prev == "setting" && next == "detail") {
        if (this.addressType == "home") {
          this.addressAlias = "집";
        } else if (this.addressType == "company") {
          this.addressAlias = "회사";
        } else {
          this.addressAlias = "";
        }
      } else if (prev == "detail" && next == "setting") {
        if (!this.addressType) {
          this.addressAlias = "";
        }

        this.addressDetail = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  // 배송지 선택 화면
  .address_select {
    height: calc(100vh - 58px);

    .btn_wrap {
      width: 100%;
      padding: 20px 16px 15px 16px;

      .add_address_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        @include font(16, normal, normal, bold);
        color: $btn-type2-txt-color !important;
      }
    }

    .address_empty_wrap {
      padding: 50px 0 0 0;
      text-align: center;

      > span {
        display: block;

        &.icon_logo {
          //width: 100%;
          height: 50px;
          background-image: url("~image/noljang_logo.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &.desc_main {
          margin: 35px 0 0 0;
          @include font(18, 24, normal, medium);
        }

        &.desc_sub {
          margin: 20px 0 0 0;
          @include font(15, 22);
        }
      }
    }

    .address_list {
      height: calc(100% - 79px);
      padding: 0 16px;

      .address_item {
        position: relative;

        a {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 100%;
          height: 100%;
          padding: 20px 0 20px 5px;
          border: {
            width: 0 0 1px 0;
            style: solid;
            color: $separator-type1;
          }

          > span {
            float: left;
            display: inline-block;
            vertical-align: top;

            &.icon {
              margin-right: 5px;
            }

            &.icon_more_arrow {
              position: absolute;
              top: 50%;
              right: 0px;
              transform: translateY(-50%);
            }

            &.icon_close,
            &.icon_address_check {
              position: absolute;
              top: 14px;
              right: -5px;
              width: 34px;
              height: 34px;

              &.icon_close {
                background-image: url("~svg/icon_close.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 14px;
                text-indent: -99999px;
              }
            }
          }

          > .address_info_wrap {
            float: left;
            width: calc(100% - 55px);

            .title {
              height: 24px;
              @include text_ellipsis(1, 24);
              @include font(16, 24, normal, bold);
              color: $black;
            }

            .address_wrap {
              color: $gray-light;

              > span {
                display: block;
                @include font(13, 24, normal);
              }

              &.sub {
                margin-top: 10px;

                > span {
                  @include font(13, 16, normal);
                }
              }
            }
          }
        }

        &.active {
          .title {
            color: $active-color !important;
          }
        }
      }
    }
  }

  // 배송지 설정 (검색) 화면
  .address_setting {
    height: calc(100vh - 58px);

    .search_input {
      width: 100%;
      height: 70px;
      padding: 10px 16px 20px 16px;
    }

    .search_tip_wrap {
      padding: 0 16px;

      > span {
        display: block;

        &:first-child {
          @include font(16, 18, normal, bold);
        }

        &:nth-child(2) {
          margin: 10px 0 20px 0;
          @include font(16, 19, 0.02);
        }
      }

      .search_tip_list {
        padding: 20px 0 0 0;
        border: {
          width: 1px 0 0 0;
          style: solid;
          color: $border-type1;
        }

        ul {
          li {
            margin: 20px 0 0 0;

            &:first-child {
              margin: 0;
            }

            > span {
              display: block;

              &.title {
                @include font(16, 18, normal, medium);
              }

              &.content {
                margin: 10px 0 0 0;
                @include font(14, 16, normal);
                color: $gray-light;
              }
            }
          }
        }
      }
    }

    .search_address_list {
      height: calc(100vh - 130px);
      padding: 0 16px;
      border: {
        width: 1px 0 0 0;
        style: solid;
        color: $border-type1;
      }
      overflow-y: scroll;

      ul {
        li {
          border: {
            width: 0 0 1px 0;
            style: solid;
            color: $border-type1;
          }

          a {
            padding: 20px 0;

            display: block;
            > span {
              display: block;
              color: $black;

              &.jibun {
                @include font(16, 24, normal, bold);
              }

              &.doro_wrap {
                margin: 10px 0 0 0;

                > span {
                  display: inline-block;
                  vertical-align: top;
                }

                .red {
                  width: 45px;
                  margin: 0 10px 0 0;
                  @include font(14, 22, normal, bold);
                  color: $active-color;
                }

                .doro {
                  width: calc(100% - 55px);
                  @include font(16, 22, normal);
                }
              }
            }
          }
        }
      }

      .no_result_msg {
        width: 100%;
        padding: 25px 0 0 0;
        @include font(16, normal, normal, medium);
        text-align: center;
      }
    }
  }

  // 배송지 설정 (상세) 화면
  .address_detail {
    .address_detail_wrap {
      padding: 30px 16px;
      background-color: $background-color-type1;

      > span {
        display: block;
        color: $black;

        &.jibun {
          @include font(16, 24, normal, bold);
        }

        &.doro_wrap {
          margin: 10px 0 0 0;

          > span {
            display: inline-block;
            vertical-align: top;
          }

          .red {
            width: 45px;
            margin: 0 10px 0 0;
            @include font(14, 22, normal, bold);
            color: $active-color;
          }

          .doro {
            width: calc(100% - 55px);
            @include font(16, 22, normal);
          }
        }
      }
    }

    .input_wrap {
      padding: 0 16px;
      > input {
        display: block;
        width: 100%;
        height: 48px;
        margin: 20px 0 0 0;
        padding: 10px 0;
        border: {
          width: 0 0 1px 0;
          style: solid;
          color: $border-type1;
        }
        @include font(16, 18, normal, bold);

        &::placeholder {
          font-weight: normal;
          color: $placeholder-color;
        }

        &:disabled {
          //@include border-radius(20px);
          background-color: $white;
        }
      }
    }

    .bottom_wrap {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      padding: 13px 16px;
      background-color: $white;
      border: {
        width: 1px 0 0 0;
        style: solid;
        color: $border-type1;
      }

      > a {
        width: 100%;
        //height: 54px;
        //@include border-radius(10px);
        @include font(16, 54, normal, bold);
        text-align: center;
      }
    }
  }
}
</style>
