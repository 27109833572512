<template>
  <PanelWrap title="배달지">
    <template v-slot:content>
      <div class="info_wrap">
        <div class="info_item">
          {{ deliveryAddr.shipToDoro }}
        </div>
        <div class="info_item">
          {{ deliveryAddr.shipToDetail }}
        </div>
      </div>
    </template>
  </PanelWrap>
</template>

<script>
import PanelWrap from "./PanelWrap";
import { mapState } from "vuex";
export default {
  name: "AddressInfo",
  components: { PanelWrap },
  computed: {
    ...mapState({
      deliveryAddr: (state) => state.cartMarket.deliveryAddr,
    }),
  },
};
</script>

<style lang="scss" scoped>
.change_addr_btn {
  @include font(14, 29, -0.84);
  color: $nj_text_main_gray;
}

.info_wrap {
  .info_item {
    @include font(16, 30, -0.96);
    color: $nj_text_main_gray;
  }
}
</style>
