<template>
  <!--  상점별 상품 목록-->
  <div class="store_product_item_wrap">
    <ProductList
      :compoundKey="compoundKey"
      :marketName="marketName"
      :marketId="marketId"
      :productId="productId"
      :name="name"
      :storeName="storeName"
      :quantity="quantity"
      :price="realPrice"
      :discountPrice="realDiscountPrice"
      :saleYn="saleYn"
      :paymentTypes="paymentTypes"
      :productImageUrl="productImageUrl"
      :requestInput="true"
      :unitName="unitName"
      :unitNumber="unitNumber"
      :originName="originName"
      :optionGroups="optionGroups"
      @delete="showLayer(compoundKey)"
      @input="setProductRequest({ compoundKey: compoundKey, input: $event })"
      @add="addProductQnt(compoundKey)"
      @remove="delProductQnt(compoundKey)"
      @storeClick="goLink({ type: 'STORE', marketId: marketId, storeId: storeId })"
      @productClick="goLink({ type: 'PRODUCT', productId: productId })"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProductList from "@/components/cart/ProductList";
export default {
  name: "ProductItem",
  props: {
    compoundKey: String,
    productId: Number,
    productImageUrl: String,
    name: String,
    marketId: Number,
    marketName: String,
    storeId: Number,
    storeName: String,
    quantity: {
      type: Number,
      default: 1,
    },
    optionGroups: Array,
    paymentTypes: Array,
    realPrice: Number,
    realDiscountPrice: Number,
    originName: String,
    unitNumber: Number,
    unitName: String,
    saleYn: String,
  },
  data: function () {
    return {};
  },
  components: {
    ProductList,
  },
  methods: {
    ...mapActions("cartMarket", [
      "delProductQnt", // 상품 빼기
      "addProductQnt",
      "setProductRequest",
      "goLink",
      "showLayer",
    ]),
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.store_product_item_wrap {
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
