<template>
  <div class="title_wrap">
    <div :id="id" class="product_title">
      {{ name }}
      <div class="product_sale_unit" :class="{ overflow: isTextOverflow }">
        {{ isTextOverflow ? "... " + saleUnit : saleUnit }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductTitle",
  props: {
    id: String,
    name: String,
    saleUnit: String,
  },
  data: function () {
    return {
      isTextOverflow: false,
    };
  },
  mounted() {
    const title = document.getElementById(this.id);
    const totalHeight = title.scrollHeight;
    const clientHeight = title.clientHeight;
    this.isTextOverflow = totalHeight > clientHeight;
  },
};
</script>

<style lang="scss" scoped>
.title_wrap {
  max-height: 44px;

  .product_title {
    position: relative;
    width: 100%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    @include font(13, 22, 0, 400);
    color: #707070;

    .product_sale_unit {
      display: inline-block;
      line-height: 22px;

      &.overflow {
        position: absolute;
        bottom: -2px;
        right: 0;
        padding: 0 3px 0 20px;
        //background-color: #fafafa;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.5), white 30%);
      }
    }
  }
}
</style>
