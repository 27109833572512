<template>
  <div @click="$emit('click', value)" :value="value" :class="{ active: active }">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: "NJOption",
  props: {
    value: String,
    selected: String,
  },
  data: function () {
    return {};
  },
  components: {},
  computed: {
    active: function () {
      return this.value == this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  text-align: left;
  /*height: 30px;*/
  /*line-height: 30px;*/
  padding: 10px 0;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
  color: $nj_placeholder_gray;
}

div.active {
  color: $nj_black;
}
</style>
