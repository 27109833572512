import router from "@/router";

const state = {
  userId: localStorage.getItem("userId"),
  customerMarginTop: "",
  markets: [],
  reload: true,
  strapBanner: null,
  deliveryFees: null,
  zipCode: null,
  atRemainPoint: null,
  atEventText: null,
  isProgressing: false,
};

const mutations = {
  updateState: function (state, data) {
    if (data.append) {
      // 데이터를 추가할때 적용
      if (state[data.type]) {
        state[data.type] = state[data.type].concat(data.updateData);
      } else {
        state[data.type] = data.updateData;
      }
    } else {
      state[data.type] = data.updateData;
    }
  },
  initState: function (state, data) {
    state["markets"] = data.updateData.markets;
  },
  goBack: function () {
    this._vm.$navigateBack();
  },
  deleteProduct: async function (state, { marketId, compoundKey }) {
    const market = state.markets.find((market) => market.marketId == marketId);
    const delProduct = market.products.find((product) => product.compoundKey == compoundKey);

    // 시장 장바구니 총 상품금액 수정
    market.totalPrice -= ((delProduct.discountPrice || delProduct.price) + delProduct.totalOptionPrice) * delProduct.quantity;

    // 무게할증/박스할증 수정 (비즈 - 시장 배송비 설정 참고)
    if (delProduct.deliveryMethod !== "TB" && delProduct.shippingType) {
      if (delProduct.shippingType === "WEIGHT") {
        market.productTotalWeight -= delProduct.totalWeight;
      } else if (delProduct.shippingType === "PACKING") {
        market.boxQuantity -= delProduct.quantity;
      }
    }

    // 앱으로 상품삭제 알림 전송
    this._vm.$callNative(
      "deleteProduct",
      {
        compoundKey: delProduct.compoundKey,
        marketId: market.marketId,
      },
      false,
    );

    const leftProducts = market.products.filter((product) => product.compoundKey !== delProduct.compoundKey);

    // 상품을 삭제한 후 장바구니에 상품이 남아 있는 경우
    if (leftProducts.length > 0) {
      // 시장 장바구니 상품 초기화
      const bundleProductCount = leftProducts.filter((item) => item.deliveryBundleId).length;
      let bundleIndex = 1;
      market.products = leftProducts.map((product) => {
        // 묶음상품 초기화
        if (product.deliveryBundleId) {
          // 묶음 상품이 1개만 남은 경우
          if (bundleProductCount === 1) {
            product.lastList = false;
            product.bundleIndex = null;
          } else {
            product.lastList = bundleIndex === bundleProductCount;
            product.bundleIndex = bundleIndex++;
          }
        }

        return product;
      });

      // 배송비 계산
      let deliveryFeeProducts = [];
      market.products.forEach((product) => {
        let options = [];
        if (product.options) {
          product.options.forEach((opt) => {
            opt.options.forEach((op) => {
              options.push(op.optionId);
            });
          });
        }

        deliveryFeeProducts.push({
          compoundKey: product.compoundKey,
          productId: product.productId,
          marketId: product.marketId,
          storeId: product.storeId,
          quantity: product.quantity,
          orderType: product.deliveryMethod === "TB" ? "TB" : "BD",
          price: product.price,
          sellerPrice: product.sellerPrice,
          optionItems: options.length ? options.join() : null,
        });
      });

      try {
        if (market.marketId === 17 && new Date().getHours() >= 16) {
          return;
        }

        const userAddress = JSON.parse(localStorage.getItem("userAddress"));
        const res = await this._vm.$axios2.post("/v3/delivery/fee", {
          userId: state.userId,
          lotNumberAddress: userAddress.lotNumberAddress,
          roadNameAddress: userAddress.roadNameAddress,
          products: deliveryFeeProducts,
          zipCode: state.zipCode,
          lat: userAddress.latitude,
          lng: userAddress.longitude,
        });

        if (res.data.responseCode === 2000) {
          const result = res.data.result;
          const deliveryFees = result.deliveryFees;
          let cashDeliveryFee = 0;

          // 배달/포장 상품 배송비 초기화
          if (market.orderType !== "TB") {
            market.chargeWtInfoText = deliveryFees[0].chargeWtInfoText;
            market.chargeBoxInfoText = deliveryFees[0].chargeBoxInfoText;
            market.onSiteYn = market.marketOrderType === "TG" ? "Y" : deliveryFees[0].onSiteYn;
          }
          // 택배 상품 배송비 초기화
          else {
            deliveryFees.forEach((delFee) => {
              const index = market.products.findIndex((product) => product.compoundKey === delFee.compoundKey);

              // 상품 배송비 초기화
              market.products[index].deliveryFee = delFee.deliveryFee;

              // 묶음배송비 초기화
              if (market.products[index].deliveryBundleId) {
                market.products[index].bundleDeliveryFee = delFee.deliveryFee;
              }

              // 상품 제주/도서산간 설명 초기화
              if (delFee.additionalFeeIslandMountainComment !== "") {
                market.products[index].guideTxt = delFee.additionalFeeIslandMountainComment;
              } else {
                market.products[index].guideTxt = null;
              }

              // 착불 배송비
              if (delFee.deliveryFeeMethod === "CASH_DELIVERY") {
                cashDeliveryFee += delFee.deliveryFee;
              }
            });
          }

          // 총 배송비 초기화
          if (market.marketOrderType === "TG") {
            market.totalDeliveryFee = 0;
            market.cashDeliveryTbPrice = 0;
          } else {
            market.totalDeliveryFee = result.deliveryFeeSum;
            market.cashDeliveryTbPrice = cashDeliveryFee;
          }
        }
      } catch (e) {
        console.error(e);
      }

      // 시장 AT설정 초기화
      if (market.products.findIndex((product) => product.atYn === "Y") === -1) {
        market.atYn = "N";
      } else {
        market.atYn = "Y";
      }

      state.markets = state.markets.map((oldMarket) => (oldMarket.marketId === market.marketId ? market : oldMarket));
    } else {
      state.markets = state.markets.filter((market) => market.marketId !== marketId);
    }

    this.dispatch("rootInitState", { cart: state.markets });
  },
  updateQuantity: async function (state, { compoundKey, marketId, $event }) {
    const market = state.markets.find((market) => market.marketId == marketId);
    const updateProduct = market.products.find((product) => product.compoundKey == compoundKey);
    const updateProductPrice = updateProduct.discountPrice || updateProduct.price + updateProduct.totalOptionPrice;

    // 삭제 상품이 배달/포장 상품인 경우
    let updateProductWeight = 0;
    if (updateProduct.deliveryMethod !== "TB") {
      if (updateProduct.shippingType === "WEIGHT" && updateProduct.optionWeight) {
        updateProductWeight += updateProduct.optionWeight;
      } else {
        updateProductWeight += updateProduct.weight;
      }
    }

    // 감소
    // 최소 주문수량 예외처리 (초록팜마트 - 고춧가루의 최소 주문수량은 2개)
    if ($event.currentTarget.classList.contains("btn_minus") && updateProduct.quantity > (updateProduct.productId === 102068 ? 2 : 1)) {
      updateProduct.quantity--;
      updateProduct.totalPrice -= updateProductPrice;
      market.totalPrice -= updateProductPrice;

      // 무게할증/박스할증 수정 (비즈 - 시장 배송비 설정 참고)
      if (updateProduct.deliveryMethod !== "TB") {
        if (updateProduct.shippingType === "WEIGHT") {
          updateProduct.totalWeight -= updateProductWeight;
          market.productTotalWeight -= updateProductWeight;
        } else if (updateProduct.shippingType === "PACKING" && market.boxQuantity > 1) {
          market.boxQuantity--;
        }
      }
    }
    // 증가
    else if ($event.currentTarget.classList.contains("btn_plus")) {
      updateProduct.quantity++;
      updateProduct.totalPrice += updateProductPrice;
      market.totalPrice += updateProductPrice;

      // 무게할증/박스할증 수정 (비즈 - 시장 배송비 설정 참고)
      if (updateProduct.deliveryMethod !== "TB") {
        if (updateProduct.shippingType === "WEIGHT") {
          updateProduct.totalWeight += updateProductWeight;
          market.productTotalWeight += updateProductWeight;
        } else if (updateProduct.shippingType === "PACKING") {
          market.boxQuantity++;
        }
      }
    }

    let deliveryFeeProducts = [];
    market.products.map((product) => {
      let options = [];
      if (product.options) {
        product.options.forEach((opt) => {
          opt.options.forEach((op) => {
            options.push(op.optionId);
          });
        });
      }

      deliveryFeeProducts.push({
        compoundKey: product.compoundKey,
        productId: product.productId,
        marketId: product.marketId,
        storeId: product.storeId,
        quantity: updateProduct.compoundKey == product.compoundKey ? updateProduct.quantity : product.quantity,
        orderType: updateProduct.deliveryMethod === "TB" ? "TB" : "BD",
        price: product.price,
        sellerPrice: updateProduct.sellerPrice,
        optionItems: options.length ? options.join() : null,
      });
    });

    try {
      if (market.marketId === 17 && new Date().getHours() >= 16) {
        return;
      }

      const userAddress = JSON.parse(localStorage.getItem("userAddress"));
      const res = await this._vm.$axios2.post("/v3/delivery/fee", {
        userId: state.userId,
        lotNumberAddress: userAddress.lotNumberAddress,
        roadNameAddress: userAddress.roadNameAddress,
        products: deliveryFeeProducts,
        zipCode: state.zipCode,
        lat: userAddress.latitude,
        lng: userAddress.longitude,
      });

      if (res.data.responseCode === 2000) {
        const result = res.data.result;
        const deliveryFees = result.deliveryFees;
        let cashDeliveryTbPrice = 0;

        // 배달/포장 상품 배송비 초기화
        if (market.orderType !== "TB") {
          market.chargeWtInfoText = deliveryFees[0].chargeWtInfoText;
          market.chargeBoxInfoText = deliveryFees[0].chargeBoxInfoText;
          market.onSiteYn = market.marketOrderType === "TG" ? "Y" : deliveryFees[0].onSiteYn;
        }
        // 택배 상품 배송비 초기화
        else {
          deliveryFees.forEach((delFee) => {
            const index = market.products.findIndex((product) => product.compoundKey === delFee.compoundKey);

            // 상품 배송비 초기화
            market.products[index].deliveryFee = delFee.deliveryFee;

            // 묶음배송비 초기화
            if (market.products[index].deliveryBundleId) {
              market.products[index].bundleDeliveryFee = delFee.deliveryFee;
            }

            // 착불 배송비
            if (market.products[index].deliveryFeeMethod === "CASH_DELIVERY") {
              if (market.products[index].deliveryFee !== null) {
                cashDeliveryTbPrice += delFee.deliveryFee;
              } else {
                cashDeliveryTbPrice = "착불";
              }
            }
          });
        }

        // 총 배송비 초기화
        if (market.marketOrderType === "TG") {
          market.totalDeliveryFee = 0;
          market.cashDeliveryTbPrice = 0;
        } else {
          market.totalDeliveryFee = result.deliveryFeeSum;
          market.cashDeliveryTbPrice = cashDeliveryTbPrice;
        }
      }
    } catch (e) {
      console.error(e);
    }

    localStorage.setItem("userCart", JSON.stringify(state.markets));

    // 앱으로 수량조정 알림 전송
    this._vm.$callNative(
      "updateQuantity",
      {
        marketId: market.marketId,
        compoundKey: updateProduct.compoundKey,
        quantity: updateProduct.quantity,
      },
      false,
    );

    state.markets = state.markets.map((oldMarket) => (oldMarket.marketId === market.marketId ? market : oldMarket));
  },
  // 결제하기
  paymentMarket: async function (state, marketId) {
    const _this = this;

    const userId = localStorage.getItem("userId");
    let userAddress = JSON.parse(localStorage.getItem("userAddress"));
    // 주소지정 프로세스
    if (!userAddress) {
      const modalName = "callAddress";
      const compProps = {
        title: "주소 지정",
        msgArr: ["배송할 주소를 설정해주세요."],
        btnArr: [
          {
            txt: "주소 설정하기",
            type: "type1 inverse",
            data: { id: "ok" },
            btnCallback: function () {
              _this._vm.$modal.hideAll();
              router.push({ name: "배송지설정", params: { userId } });
            },
          },
        ],
        closeModal: function () {
          // X 버튼 클릭시
          // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
          _this._vm.$modal.hideAll();
        },
      };
      this.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
        },
        { root: true },
      );

      return;
    }
    // 본인인증 프로세스
    else if (!userId) {
      const modalName = "callAuthentication";
      const compProps = {
        title: "휴대전화 본인인증",
        msgArr: ["휴대전화 본인 인증이 필요합니다."], // html 코드 적용 가능
        btnArr: [
          {
            txt: "본인 인증 하기",
            type: "type1 inverse",
            data: { id: "ok" },
            btnCallback: function () {
              _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
              _this.commit("marketMain/navigateTo", { path: "authentication" });
            },
          },
        ],
        closeModal: function () {
          // X 버튼 클릭시
          // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
          _this._vm.$modal.hideAll();
        },
      };
      this.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
        },
        { root: true },
      );

      return;
    }

    // 시간제 배달구역 확인 프로세스
    var getMarketInfoUrl = "/v3/market/user/user/" + userId + "/market/validation?marketId=" + marketId;
    state.isProgressing = true;
    var marketOrderInfo = null;
    await this._vm.$axios1
      .get(getMarketInfoUrl)
      .then((response) => {
        state.isProgressing = false;

        if (response.data.responseCode == 2000) {
          marketOrderInfo = response.data.result;
        } else {
          const _this = this;
          const modalName = "like_notice_modal";
          const compProps = {
            title: "주문하기 오류 - 1",
            msgArr: [
              "주문 중 오류가 발생하였습니다.<br/>고객센터(02-2039-6742)에 문의해주세요!",
              "오류코드<br/>" + response.data.errorObject.errorMessage,
              // "userId   ::: " + userId,
              // "marketId ::: " + marketId,
              // "getMarketInfoUrl ::: ",
              // getMarketInfoUrl,
            ], // html 코드 적용 가능
            btnArr: [],
            type: "alert",
            closeModal: function () {
              _this._vm.$modal.hide(modalName);
            },
          };
          // 모달 출력
          this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });

          alert(response.data.errorObject.errorMessage);
        }
      })
      .catch(() => {
        const _this = this;
        const modalName = "like_notice_modal";
        const compProps = {
          title: "주문하기 오류 - 2",
          msgArr: [
            "주문 중 오류가 발생하였습니다.<br/>고객센터(02-2039-6742)에 문의해주세요!!!",
            "userId 1 ::: " + userId,
            "userId 2 ::: " + localStorage.getItem("userId"),
          ], // html 코드 적용 가능
          btnArr: [],
          type: "alert",
          closeModal: function () {
            _this._vm.$modal.hide(modalName);
          },
        };
        // 모달 출력
        this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });

        state.isProgressing = false;
      });

    var compareOrderBoolean = false;
    if (marketOrderInfo.enableYn == "Y") {
      const date = new Date();
      compareOrderBoolean =
        localStorage.getItem("timeNoticePopup") ==
        "market_" + String(marketId) + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

    if (marketOrderInfo.areaType == "TIME" && compareOrderBoolean == false) {
      const { enableYn, orderStart, orderEnd, deliveryTime } = marketOrderInfo;
      const modalName = "order_notice";
      const compProps = {
        title: "주문하기",
        type: "order_notice",
        marketId: String(marketId),
        marketOrderInfo: {
          enableYn: enableYn,
          orderStart: orderStart,
          orderEnd: orderEnd,
          deliveryTime: deliveryTime,
        },
        changeCheckbox: function () {
          const checkedMarket = document.querySelector("#closeTimeNotice:checked");
          if (checkedMarket) {
            const date = new Date();
            localStorage.setItem(
              "timeNoticePopup",
              "market_" + String(marketId) + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
            );
          } else {
            localStorage.removeItem("timeNoticePopup");
          }
        },
        closeModal: function (data = null) {
          if (data) {
            const cartData = state.markets;
            const sendData = cartData.filter((market) => market.marketId == marketId);

            _this._vm.$callNative("setCartData", { cart: sendData[0] }, false);
            _this._vm.$navigateTo("payment");
          }

          _this._vm.$modal.hide(modalName);
        },
      };

      this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
      return;
    }
    // }

    // this._vm.$navigateTo('payment', {marketId: marketId});
    const cartData = state.markets;
    const sendData = cartData.filter((market) => market.marketId == marketId);

    const products = [];
    sendData[0].products.forEach((item) => {
      products.push(item.productId);
    });

    const marketOrderType =
      sendData[0].marketOnSites?.defaultType === "BD" && sendData[0].marketOnSites.selectType === "PACKING"
        ? "TG"
        : sendData[0].marketOnSites?.defaultType;

    var cartValidationYn = null;
    var errorMsg = null;
    let isUseBr = true;

    if (sendData[0].orderType != "TB") {
      const userAddress = JSON.parse(localStorage.getItem("userAddress"));
      // console.log(userAddress);
      const productIds = sendData[0].products.map((product) => product.productId);
      // console.log(productIds);

      try {
        // const validationUrl = `v3/marketId/${sendData[0].marketId}/latitude/${userAddress.latitude}/longitude/${userAddress.longitude}/validation?productIds=${productIds.join()}&tgYn=${sendData[0].marketOnSites.selectType === 'TG' ? 'Y' : 'N'}`;
        const validationUrl = `v3/marketId/${sendData[0].marketId}/latitude/${userAddress.latitude}/longitude/${
          userAddress.longitude
        }/validation?productIds=${productIds.join()}&tgYn=${sendData[0].marketOrderType === "TG" ? "Y" : "N"}`;
        const validation = await this._vm.$axios2.get(validationUrl);
        if (validation.data.responseCode == 2000) {
          if (validation.data.result?.orderValidationInfo.marketStatus.includes("정상 영업")) {
            cartValidationYn = "Y";
            // console.log(validation.data.result);
            // console.log(sendData[0]);
          } else {
            cartValidationYn = "N";
            const orderValidationInfo = validation.data.result?.orderValidationInfo;
            errorMsg = `<p style="margin: 0 0 10px;">${
              validation.data.result?.buttonText === "주간문 시간이 종료 되었습니다." ||
              validation.data.result?.buttonText === "유효하지 않은 상품이 존재합니다."
                ? validation.data.result.buttonText
                : orderValidationInfo?.marketStatus
            }</p>
                <div style="display: ${orderValidationInfo?.orderTime ? "flex" : "none"}; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">주문시간</strong>
                    <span>${orderValidationInfo?.orderTime.replace(/\n/gi, "<br/>")}</span>
                </div>
            `;
            /*errorMsg = `<p style="margin: 0 0 10px;">${orderValidationInfo?.marketStatus}</p>
                <div style="display: ${orderValidationInfo?.orderTime? 'flex' : 'none'}; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">주문시간</strong>
                    <span>${orderValidationInfo?.orderTime.replace(/\n/gi, '<br/>')}</span>
                </div>
                <div style="display: ${orderValidationInfo?.deliveryFee? 'flex' : 'none'}; margin: 0 0 10px; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">배달비</strong>
                    <span>${orderValidationInfo?.deliveryFee.replace(/\n/gi, '<br/>')}</span>
                </div>
                <div style="display: ${orderValidationInfo?.tgInfo? 'block' : 'none'}; font-weight: normal; text-align: left;">
                    <span>${orderValidationInfo?.tgInfo.replace(/\n/gi, '<br/>')}</span>
                </div>
            `;*/
            isUseBr = false;
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    if (cartValidationYn != "N") {
      try {
        const cartValidationUrl = "/v3/orders/validation?userId=" + userId + "&productIds=" + products.join() + "&orderType=" + marketOrderType;
        const cartValidation = await this._vm.$axios2.get(cartValidationUrl);
        if (cartValidation.data.responseCode == 2000) {
          cartValidationYn = "Y";
        } else {
          cartValidationYn = "N";
          errorMsg = cartValidation.data.errorObject.errorMessage;
        }
      } catch (e) {
        console.error(e);
      }
    }

    // if (userId == "c131e836-2bd2-4cc6-91ee-84e65fe9d3a9") {
    //   // 윤재환 선임
    //   this._vm.$callNative("setCartData", { cart: sendData[0] }, false);
    //   this._vm.$navigateTo("payment");
    // } else {
    if (cartValidationYn != "N") {
      // 결제하기 웹뷰 적용 ****************************************************************************
      // checkPaymentWebMode return 값이 true 일때 결제하기 웹뷰
      // router.push({ name: "결제하기", params: sendData[0] });
      // return;
      router.push({ name: "결제하기", params: { ...sendData[0], ...{ marketOrderType: marketOrderType } } });

      /*if (await this.dispatch("checkPaymentWebMode")) {
        router.push({ name: "결제하기", params: sendData[0] });
      } else {
        this._vm.$callNative("setCartData", { cart: sendData[0] }, false);
        this._vm.$navigateTo("payment");
      }*/
    } else if (cartValidationYn === "N") {
      this.commit("cart/setAlert", isUseBr ? errorMsg.replace(/\n/gi, "<br>") : errorMsg);
    }
    // }
  },
  setAlert: function (state, msg = null) {
    const _this = this;
    const callback = () => {
      _this._vm.$modal.hideAll();
    };

    const compareMsgs = ["<strong style='display: block; padding: 0 0 20px;'>" + msg + "</strong>"];
    const compProps = {
      title: "주문하기",
      type: "order_notice",
      // marketId: String(2),
      msgArr: compareMsgs, // html 코드 적용 가능
      btnArr: [
        {
          txt: "확인",
          type: "type4 else1",
          btnCallback: () => callback(),
        },
      ],
      marketOrderInfo: {
        enableYn: "N",
      },
      closeModal: function () {
        _this._vm.$modal.hide("order_notice");
      },
    };
    this.dispatch("showModal", { name: "order_notice", compProps: compProps, modalProps: null }, { root: true });

    /*const _this = this;
    const modalName = "like_notice_modal";
    const compProps = {
      // title: "주문하기 오류 - 2",
      msgArr: [msg], // html 코드 적용 가능
      // btnArr: [],
      type: "alert",
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });*/
  },
  clickBanner: function (store, event) {
    if (event.target.tagName == "IMG") {
      if (event.target.dataset.detailUrl) {
        this._vm.$navigateTo("open-url", {
          title: event.target.dataset.title,
          url: event.target.dataset.detailUrl,
        });
      }
    }
  },
  updateZipCode: function (state, zipCode) {
    state.zipCode = zipCode;
    this.commit("updateState", { type: "zipCode", updateData: zipCode });
  },
};

const actions = {
  initState: async function (store, data) {
    var cartData = data.cart;

    store.commit("updateState", { type: "reload", updateData: data.reload });

    const userId = localStorage.getItem("userId");
    const userAddress = JSON.parse(localStorage.getItem("userAddress"));

    store.commit("updateState", { type: "isProgressing", updateData: true });

    if (userId) {
      // state.userId가 누락되는 케이스가 있어 방어코드 적용
      store.commit("updateState", { type: "userId", updateData: userId });

      if (!store.rootState.zipCode) {
        var zipCode = null;
        const zipCodeUrl = `/v3/user/member/address/${userId}/ship-to`;
        await this._vm.$axios1
          .get(zipCodeUrl) // 배송지 정보 확인
          .then((response) => {
            if (response.data.responseCode == 2000) {
              zipCode = response.data.result.zipCode;
            } else {
              alert(response.data.errorObject.errorMessage);
              return false;
            }
          })
          .catch(() => {
            return false;
          });
        const shippingAddress = userAddress;
        const shippingAddressData = {
          shipToDoro: shippingAddress.roadNameAddress,
          shipToJibun: shippingAddress.lotNumberAddress,
          shipToDetail: shippingAddress.detailAddress,
          shipToName: shippingAddress.alias,
          zipCode: zipCode,
        };

        if (!zipCode) {
          const setZipCodeUrl = `/v3/user/member/address/${userId}/ship-to`;
          await this._vm.$axios1
            .put(setZipCodeUrl, shippingAddressData) // 배송지 정보 확인
            .then((response) => {
              if (response.data.responseCode == 2000) {
              } else {
                alert(response.data.errorObject.errorMessage);
                return false;
              }
            })
            .catch(() => {
              return false;
            });

          await this._vm.$axios1
            .get(zipCodeUrl) // 배송지 정보 확인
            .then((response) => {
              if (response.data.responseCode == 2000) {
                zipCode = response.data.result.zipCode;
              } else {
                alert(response.data.errorObject.errorMessage);
                return false;
              }
            })
            .catch(() => {
              return false;
            });
        }

        if (zipCode) {
          store.commit("updateZipCode", zipCode);
        }
      } else {
        store.commit("updateZipCode", store.rootState.zipCode);
      }
    }
    this.dispatch("paymentNew/useCoupon", null); // 결제하기 웹뷰의 쿠폰데이터 초기화 예외처리

    await this._vm.$axios2
      .get("/v3/notices/member-notices/all-markets/banners?size=30&userId=" + state.userId) // 베너 목록
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const result = response.data.result;
          if (result.shoppingBasketStrapBannerImg) {
            const strapData = {
              type: "strapBanner",
              updateData: {
                bannerImage: result.shoppingBasketStrapBannerImg,
                // detailUrl: result.shoppingBasketStrapBannerDetailUrl,
                detailUrl: result.shoppingBasketStrapBannerUrl,
                noticeId: result.shoppingBasketStrapBannerNoticeId,
                title: result.shoppingBasketStrapBannerTitle,
              },
            };
            store.commit("updateState", strapData);
          }
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });

    if (!cartData.length) {
      store.commit("initState", { updateData: { markets: [] } });
      store.commit("updateState", { type: "isProgressing", updateData: false });
      return;
    }

    // compoundKey 에서 productId 를 분리해서 저장
    // '2352!0@1,2,3,4#1@1,2#1@1,2#1@1,2#1@1,2'.split('!')[0]
    var productIds = "";
    cartData.map((market) => {
      market.products.map((product) => {
        product.productId = Number(product.compoundKey.split("!")[0]);
        if (productIds == "") {
          productIds = product.productId;
        } else {
          productIds = productIds + "," + product.productId;
        }
      });
    });

    var deliveryFeeProducts = [];

    // productId 기준으로 상품 정보 서버에서 받음
    const axiosUrl = "/v3/market/delivery/product/products/information?productIds=" + productIds;
    await this._vm.$axios1
      .get(axiosUrl)
      .then(async (response) => {
        // store.commit("updateState", { type: "isProgressing", updateData: false });

        if (response.data.responseCode == 2000) {
          var products = response.data.result.productDataList;
          var productLength = products.length;

          cartData.map(async (market) => {
            market.totalPrice = 0;
            market.boxQuantity = 0;
            market.productTotalWeight = 0;
            market.atYn = "N";
            market.products.map((product) => {
              for (var i = 0; i < productLength; i++) {
                if (product.productId == products[i].productId) {
                  // market.orderType은 22년 4월 8일 기준 택배와 바로배달은 함께 적용할수 없기에 상품 기준으로 정의
                  market.orderType = products[i].deliveryMethod === "TB" ? "TB" : "BD";
                  market.marketName = products[i].marketName;

                  // 현장가, 포장할인가 적용
                  product.packagingDiscountPrice = products[i].packagingDiscountPrice;
                  product.sellerPrice = products[i].sellerPrice;

                  product.marketId = products[i].marketId;
                  product.storeName = products[i].storeName;
                  product.storeId = products[i].storeId;
                  product.name = products[i].productName;
                  product.productName = products[i].productName; // native에 보내는 데이터로 생성
                  product.price = products[i].price;
                  product.discountPrice = products[i].discountPrice;
                  product.paymentTypes = products[i].paymentTypeList;
                  product.paymentTypeList = products[i].paymentTypeList; // native에 보내는 데이터로 생성
                  product.imgUrl = products[i].productThumbnailUrl;
                  product.productImageUrl = products[i].productThumbnailUrl; // native에 보내는 데이터로 생성
                  product.originName = products[i].originName;
                  product.unitNumber = products[i].unitNumber;
                  product.unitName = products[i].unitName;
                  product.weight = products[i].weight;
                  product.totalWeight = product.weight;

                  product.totalOptionPrice = 0;
                  product.marketOpenYn = products[i].marketOpenYn;
                  product.storeOpenYn = products[i].storeOpenYn;
                  product.productSaleYn = products[i].productSaleYn;
                  product.deliveryMethod = products[i].deliveryMethod;

                  product.atYn = products[i].atYn;
                  if (products[i].atYn === "Y") {
                    market.atYn = "Y";
                  }

                  if (!products[i].extraWt && products[i].extraBox) {
                    product.shippingType = null;
                  } else {
                    if (products[i].extraBox && products[i].boxPkgYn === "Y") {
                      product.shippingType = "PACKING"; // 박스포장
                    } else if (products[i].extraWt) {
                      product.shippingType = "WEIGHT"; // 무게 할증
                    } else {
                      product.shippingType = null;
                    }
                  }

                  // 박스 포장일때 상품 개수를 박스 개수로 정의
                  if (product.shippingType === "PACKING") {
                    market.boxQuantity = market.boxQuantity + product.quantity;
                  }

                  if (products[i].productSaleYn == "Y" && products[i].storeOpenYn == "Y") {
                    // && products[i].marketOpenYn == 'Y'
                    product.disabled = false;
                  } else {
                    product.disabled = true;
                  }

                  // 상품 옵션 정의
                  if (products[i].options.length > 0) {
                    const productOptionGroups = products[i].options;

                    var optionGroups = [];

                    if (product.compoundKey.split("!")[1]) {
                      var optGops = product.compoundKey.split("!")[1].split("#");

                      optGops.map(async (item) => {
                        // optGops : local 에 저장된 데이터
                        var opt = item.split("@");
                        await optionGroups.push({
                          optionGroupId: Number(opt[0]), // 그룹 아이디와 분리
                          options: opt[1].split(",").map((optId) => {
                            return {
                              optionId: Number(optId),
                            };
                          }),
                          items: opt[1].split(",").map((optId) => {
                            return {
                              optionId: Number(optId),
                            };
                          }),
                        });
                      });

                      if (!product.isDisabled) {
                        // 화면이 정상적으로 나오는 조건
                        var optionGroupsLength = optionGroups.length;
                        var productOptLength = products[i].options.length;

                        for (var x = 0; x < optionGroupsLength; x++) {
                          var disabledCount = 0;
                          for (var z = 0; z < productOptLength; z++) {
                            if (optionGroups[x].optionGroupId == products[i].options[z].optionGroupId) {
                              disabledCount++;
                            }
                          }
                          if (disabledCount == 0) {
                            product.disabled = true;
                            product.disabledType = "changeOption";
                            optionGroups[x].options = null;
                            break;
                          }
                        }
                      }

                      var totalOptionPrice = 0;
                      var optionGroupLength = productOptionGroups.length;
                      optionGroups.map(async (item) => {
                        for (var k = 0; k < optionGroupLength; k++) {
                          if (item.optionGroupId == productOptionGroups[k].optionGroupId) {
                            await item.options.map((option) => {
                              productOptionGroups[k].items.map((optItem) => {
                                if (option.optionId == optItem.optionItemId) {
                                  option.optionName = optItem.optionItemName;
                                  option.optionPrice = optItem.optionItemPrice;
                                  option.optionItemSellerPrice = optItem.optionItemSellerPrice;
                                  totalOptionPrice = totalOptionPrice + optItem.optionItemPrice;
                                  if (optItem.optionWeight && product.shippingType === "WEIGHT") {
                                    option.optionWeight = optItem.optionWeight;
                                    product.optionWeight = optItem.optionWeight;
                                    product.totalWeight = product.totalWeight + optItem.optionWeight;
                                  }
                                }
                              });

                              if (!option.optionName) {
                                // 상품 옵션값이 다를때
                                product.disabled = true;
                                product.disabledType = "changeOption";
                              }
                            });
                            // native에 보내는 데이터로 생성 위 options와 코드 정리가 필요함
                            await item.items.map((option) => {
                              productOptionGroups[k].items.map((optItem) => {
                                if (option.optionId == optItem.optionItemId) {
                                  option.optionName = optItem.optionItemName;
                                  option.optionPrice = optItem.optionItemPrice;

                                  option.optionItemId = optItem.optionItemId;
                                  option.optionItemName = optItem.optionItemName;
                                  option.optionItemPrice = optItem.optionItemPrice;
                                  option.optionItemSellerPrice = optItem.optionItemSellerPrice;
                                  totalOptionPrice = totalOptionPrice + optItem.optionItemPrice;
                                  if (optItem.optionWeight && product.shippingType === "WEIGHT") {
                                    option.optionWeight = optItem.optionWeight;
                                    // product.totalWeight = product.totalWeight + optItem.optionWeight;
                                  }
                                }
                              });

                              if (!option.optionName) {
                                // 상품 옵션값이 다를때
                                product.disabled = true;
                                product.disabledType = "changeOption";
                              }
                            });
                          }
                        }
                      });
                      product.optionGroups = optionGroups;
                      product.options = optionGroups; // native에 보내는 데이터로 생성
                      product.totalOptionPrice = totalOptionPrice;
                      // product.totalPrice = 0;
                    }
                  }
                  /*
                   */
                  if (product.name) {
                    // 상품이 정상인지 임의로 정의함
                    if (product.discountPrice) {
                      product.totalPrice = (product.discountPrice + product.totalOptionPrice) * product.quantity;
                    } else {
                      product.totalPrice = (product.price + product.totalOptionPrice) * product.quantity;
                    }
                    if (product.shippingType === "WEIGHT") {
                      product.totalWeight = product.totalWeight * product.quantity;
                    }
                  }

                  var options = [];
                  if (product.options) {
                    product.options.forEach((opt) => {
                      opt.options.forEach((op) => {
                        options.push(op.optionId);
                      });
                    });
                  }

                  deliveryFeeProducts.push({
                    compoundKey: product.compoundKey,
                    productId: product.productId,
                    marketId: product.marketId,
                    storeId: product.storeId,
                    quantity: product.quantity,
                    orderType: product.deliveryMethod === "TB" ? product.deliveryMethod : "BD",
                    price: product.price,
                    sellerPrice: product.sellerPrice,
                    optionItems: options.length ? options.join() : null,
                  });

                  // if (!product.disabled) {
                  //     if (products[i].productSaleYn != 'Y') {
                  //         product.disabled = true;
                  //     }
                  // }
                }
              }

              if (product.totalPrice) {
                market.totalPrice = market.totalPrice + product.totalPrice;
              }
              if (product.totalWeight && product.shippingType === "WEIGHT") {
                market.productTotalWeight = market.productTotalWeight + product.totalWeight;
              }
            });

            const shippingPackingType = market.products.filter((item) => item.shippingType === "PACKING");
            const shippingWeightType = market.products.filter((item) => item.shippingType === "WEIGHT");

            if (!shippingPackingType.length && !shippingWeightType.length) {
              market.shippingType = null;
            } else if (shippingPackingType.length === market.products.length) {
              market.shippingType = "PACKING";
            } else if (shippingWeightType.length === market.products.length) {
              market.shippingType = "WEIGHT";
            } else {
              market.shippingType = "MIX_SHIPPING";
            }
          });

          cartData.forEach((market) => {
            const products = market.products;
            const productLength = products.length - 1;
            for (var i = productLength; i > -1; i--) {
              if (!products[i].name) {
                products.splice(i, 1);
              }
            }
          });

          localStorage.setItem("userCart", JSON.stringify(cartData));

          if (state.reload) {
            // 배달, 포장 여부 확인

            // const marketIds = cartData.map((market) => {
            //   if (!market.marketOnSites) {
            //     return { marketId: market.marketId };
            //   }
            // });
            const marketIds = [];
            cartData.forEach((market) => {
              if (!market.marketOnSites && market.marketId) {
                marketIds.push({ marketId: market.marketId });
              }
            });

            try {
              const marketInfoRes = await this._vm.$axios1.post("/v3/markets/on-sites", {
                marketIds: marketIds,
                userId: state.userId,
              });
              if (marketInfoRes.data.responseCode == 2000) {
                const marketOnSites = marketInfoRes.data.result.marketOnSites;
                cartData.forEach((marketCart) => {
                  marketOnSites.forEach((market) => {
                    if (marketCart.marketId === market.marketId) {
                      const withoutId = [95597, 95598, 95817, 95818, 95819]; // 개발 시 93380, 운영 시 95597 || 95598 || 95817 || 95818 || 95819
                      let findProduct = marketCart.products.map(function (item) {
                        return item.productId;
                      });

                      let checkAt = marketCart.atYn === "Y" && withoutId.some((value) => findProduct.includes(value));

                      marketCart.marketOnSites = {
                        marketId: market.marketId,
                        defaultType: market?.defaultType, // 아래코드와 대체 2023-0725
                        // defaultType: checkAt ? 'TG' : market?.defaultType,
                        atYn: checkAt ? "Y" : "N",
                        onSiteYn: market.onSiteYn,
                        selectType: market.defaultType === "BD" && market.onSiteYn === "Y" ? "DELIVERY" : market.defaultType ?? null, // 아래코드와 대체 2023-0725
                        // selectType: checkAt ? "PACKING" : market?.defaultType === "BD" && market.onSiteYn === "Y" ? "DELIVERY" : null,
                        tabData: [
                          // 아래코드와 대체 2023-0725
                          // tabData: checkAt ? [] : [
                          {
                            dataType: "DELIVERY",
                            text: "배달",
                            isActive: true,
                          },
                          {
                            dataType: "PACKING",
                            text: "포장",
                            isActive: false,
                          },
                        ],
                      };
                    }
                  });
                });
              }
            } catch (e) {
              console.error(e);
            }
          }

          if (state.userId) {
            // cartData.forEach((market) => {
            //   if (market.orderType == "TB") {
            //     market.products.forEach((item) => {
            //       item.deliveryFee = null;
            //       item.deliveryFeeMethod = "CASH_DELIVERY";
            //     });
            //   }
            // });

            // try {
            //   const marketInfoRes = await this._vm.$axios2.post("/v3/markets/on-sites", {
            //     marketIds: [],
            //   });
            //   if (marketInfoRes.data.responseCode == 2000) {
            //
            //   }
            // } catch (e) {
            // }

            try {
              const res = await this._vm.$axios2.post("/v3/delivery/fee", {
                userId: state.userId,
                lotNumberAddress: userAddress.lotNumberAddress,
                roadNameAddress: userAddress.roadNameAddress,
                products: deliveryFeeProducts,
                zipCode: zipCode ? zipCode : store.rootState.zipCode,
                lat: userAddress.latitude,
                lng: userAddress.longitude,
              });
              if (res.data.responseCode == 2000) {
                store.commit("updateState", { type: "isProgressing", updateData: false });

                const deliveryFees = res.data.result.deliveryFees;

                store.commit("updateState", { type: "deliveryFees", updateData: deliveryFees });
                cartData.forEach((market) => {
                  if (market.orderType == "BD") {
                    deliveryFees.forEach((deliveryMarket) => {
                      if (market.marketId == deliveryMarket.marketId) {
                        market.deliveryFee = deliveryMarket.deliveryFee;
                        market.orderInfo = deliveryMarket.orderInfo;
                        market.totalDeliveryFee = deliveryMarket.deliveryFee;
                        if (deliveryMarket.chargeWtInfoText) {
                          market.chargeWtInfoText = deliveryMarket.chargeWtInfoText;
                        }
                        if (deliveryMarket.chargeBoxInfoText) {
                          market.chargeBoxInfoText = deliveryMarket.chargeBoxInfoText;
                        }

                        // 방문수령 예외처리 - 둔촌역전통시장 예외처리
                        market.onSiteYn = deliveryMarket.onSiteYn;
                      }
                    });
                  } else if (market.orderType == "TB") {
                    market.orderInfo = null;

                    // 택배 배송일때
                    var productArr = [];
                    var bundleIdProducts = [];
                    var bundleIds = [];

                    var deliveryTbType = null; // 택배 선결재 배송 상세 타입
                    var cashDeliveryCount = 0;
                    var cashDeliveryTbPrice = 0; // 착불 배송비

                    var totalDeliveryFee = 0; // 시장 단위의 배송비
                    // var cashDeliveryCount = 0;
                    market.products.forEach((product) => {
                      deliveryFees.forEach((deliveryFeeProduct) => {
                        if (market.marketId == deliveryFeeProduct.marketId && product.compoundKey === deliveryFeeProduct.compoundKey) {
                          // 배송비 적용
                          product.deliveryFee = deliveryFeeProduct.deliveryFee;
                          product.deliveryFeeMethod = deliveryFeeProduct.deliveryFeeMethod;
                          const comment = deliveryFeeProduct.additionalFeeIslandMountainComment;
                          if (comment) {
                            if (comment.trim()) {
                              product.guideTxt = comment.trim(); // 안내문구
                            }
                          }

                          if (deliveryFeeProduct.deliveryFeeMethod === "CASH_DELIVERY") {
                            cashDeliveryCount++;
                          }

                          if (!deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId) {
                            productArr.push(product);
                            if (product.deliveryFeeMethod === "PREPAYMENT") {
                              totalDeliveryFee = totalDeliveryFee + product.deliveryFee;
                            } else if (deliveryFeeProduct.deliveryFeeMethod === "CASH_DELIVERY") {
                              if (product.deliveryFee != null) {
                                cashDeliveryTbPrice = cashDeliveryTbPrice + product.deliveryFee;
                              }
                            }
                          } else {
                            if (bundleIds.indexOf(deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId) === -1) {
                              bundleIds.push(deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId);
                              if (product.deliveryFeeMethod === "PREPAYMENT") {
                                const bundleProducts = deliveryFees.filter(
                                  (item) => item.tbDeliveryPrePaymentFeeBundleId === deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId,
                                );

                                var bundleDeliveryFee = 0; // 묶은상품 배송비 확인
                                bundleProducts.forEach((item) => {
                                  if (bundleDeliveryFee < item.deliveryFee) {
                                    bundleDeliveryFee = item.deliveryFee;
                                  }
                                });
                                // totalDeliveryFee = totalDeliveryFee + product.deliveryFee;
                                totalDeliveryFee = totalDeliveryFee + bundleDeliveryFee;
                              } else if (deliveryFeeProduct.deliveryFeeMethod === "CASH_DELIVERY") {
                                if (product.deliveryFee !== null) {
                                  cashDeliveryTbPrice = cashDeliveryTbPrice + product.deliveryFee;
                                }
                              }
                            }

                            if (product.deliveryFeeMethod === "PREPAYMENT" || product.deliveryFeeMethod === "CASH_DELIVERY") {
                              const bundleProducts = deliveryFees.filter(
                                (item) => item.tbDeliveryPrePaymentFeeBundleId === deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId,
                              );

                              var bundleProductDeliveryFee = 0; // 묶은상품 배송비 확인
                              bundleProducts.forEach((item) => {
                                if (bundleProductDeliveryFee < item.deliveryFee) {
                                  bundleProductDeliveryFee = item.deliveryFee;
                                }
                              });
                              product.bundleDeliveryFee = bundleProductDeliveryFee;
                            }

                            product.deliveryBundleId = deliveryFeeProduct.tbDeliveryPrePaymentFeeBundleId;
                            bundleIdProducts.push(product);
                          }
                        }
                      });

                      if (cashDeliveryCount === 0) {
                        deliveryTbType = "PREPAYMENT";
                      } else if (market.products.length === cashDeliveryCount) {
                        deliveryTbType = "CASH_DELIVERY";
                      } else if (market.products.length > cashDeliveryCount) {
                        deliveryTbType = "MIXED_PAYMENT";
                      }
                    });
                    market.deliveryTbType = deliveryTbType;
                    market.cashDeliveryTbPrice = cashDeliveryTbPrice;

                    if (deliveryTbType === "CASH_DELIVERY") {
                      if (market.products.filter((item) => item.deliveryFee === null).length === cashDeliveryCount) {
                        market.cashDeliveryTbPrice = "착불";
                        market.deliveryApplyType = null;
                      }
                    }

                    market.totalDeliveryFee = totalDeliveryFee;

                    if (bundleIdProducts.length > 1) {
                      var bundleProductInfo = [];
                      var bundleProducts = [];
                      bundleIds.forEach((id) => {
                        var bundleIdProductArr = bundleIdProducts.filter((prd) => prd.deliveryBundleId === id);
                        const bundleIdProductArrLength = bundleIdProductArr.length;

                        bundleProductInfo.push({
                          id: id,
                          length: bundleIdProductArrLength,
                        });

                        if (bundleIdProductArrLength > 1) {
                          bundleIdProductArr[0].bundleCount = bundleIdProductArrLength; // `묶음 배송 <span style='color: #f00;'>${bundleIdProductArrLength}개 상품</span>`;
                          bundleIdProductArr[bundleIdProductArrLength - 1].bundleTotalCount = bundleIdProductArrLength;
                          bundleIdProductArr[bundleIdProductArrLength - 1].lastList = true;
                          bundleIdProductArr.forEach((item, index) => {
                            item.bundleIndex = index + 1;
                            item.showDeliveryInfo = true;
                          });
                        }

                        bundleProducts = [...bundleProducts, ...bundleIdProductArr];
                      });

                      market.products = [...productArr, ...bundleProducts];
                    } else {
                      market.products = [...productArr, ...bundleIdProducts];
                    }

                    // 묶음배송으로 인한 노출 순서 변경
                    const localStorageCartDate = JSON.parse(localStorage.getItem("userCart"));
                    var compareBundleIds = [];
                    var marketProducts = [];
                    localStorageCartDate.forEach((storageMarket) => {
                      if (storageMarket.marketId === market.marketId) {
                        storageMarket.products.forEach((storageProduct) => {
                          market.products.forEach((item) => {
                            if (storageProduct.compoundKey === item.compoundKey) {
                              if (item.deliveryBundleId) {
                                if (compareBundleIds.indexOf(item.deliveryBundleId) === -1) {
                                  const prd = market.products.filter((filterItem) => filterItem.deliveryBundleId === item.deliveryBundleId);
                                  marketProducts = [...marketProducts, ...prd];
                                  compareBundleIds.push(item.deliveryBundleId);
                                }
                              } else {
                                marketProducts.push(item);
                              }
                            }
                          });
                        });
                      }
                    });
                    //
                    // JSON.parse(localStorage.getItem("userCart"))[0].products.forEach((item) => {
                    // });
                    // market.products.forEach((item) => {
                    // });
                    // marketProducts.forEach((item) => {
                    // });
                    market.products = marketProducts;
                  }
                });
              } else {
                console.log(res.data);
              }
            } catch (e) {
              cartData.forEach((market) => {
                market.products.forEach((product) => {
                  product.disabled = true;
                  product.disabledType = "deliveryFee";
                });
              });
              // console.log(market);

              console.error(e);
            }
          }

          store.commit("updateState", { type: "isProgressing", updateData: false });

          // console.log('cartData :::::::::::::::::::::::::::::::::::::::');
          // console.log(cartData);
          //
          // cartData.forEach(cart => {
          //   try {
          //     const requestResponse = this._vm.$axios2.get(`/v3/market/user/marketId/${market.marketId}/latitude/${userAddress.latitude}/longitude/${userAddress.longitude}/information`);
          //     if (requestResponse.data.responseCode == 2000) {
          //       const deliveryFee = requestResponse.data.result.deliveryFees;
          //       console.log(requestResponse);
          //       // store.commit("updateState", { type: "deliveryFees", updateData: deliveryFees });
          //     } else {
          //       console.log(requestResponse.data);
          //     }
          //   } catch (e) {
          //     console.log(e);
          //   }
          // });
          //

          store.commit("initState", {
            updateData: {
              markets: cartData,
            },
          });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        store.commit("updateState", { type: "isProgressing", updateData: false });
        this._vm.isProgressing = false;
      })
      .catch(() => {
        store.commit("updateState", { type: "isProgressing", updateData: false });
        this._vm.isProgressing = false;
      });
    // store.commit("updateState", { type: "isProgressing", updateData: false });

    // const updateData = {
    //     markets: [
    //         {
    //             marketId: 1,
    //             marketName: '시장 A111',
    //             products: [
    //                 {
    //                     compoundKey: '1',
    //                     productId: 1,
    //                     name: '돼지고기',
    //                     quantity: 10,
    //                     storeName: 'A 상점',
    //                     price: 37900,
    //                     discountPrice: 9100,
    //                     paymentTypes: ['온누리', '제로배달', '제로배달'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     isDisabled: false,
    //                     requestInput: false,
    //                     optionGroups: [
    //                         {
    //                             optionGroupId: 0,
    //                             optionGroupName: '기본',
    //                             requiredYn: 'Y',
    //                             selectCount: 0,
    //                             options: [
    //                                 {
    //                                     optionId: 0,
    //                                     optionName: '양념추가',
    //                                     optionPrice: -3000
    //                                 }
    //                             ],
    //
    //                         },
    //                         {
    //                             optionGroupId: 1,
    //                             optionGroupName: '음료',
    //                             requiredYn: 'Y',
    //                             selectCount: 0,
    //                             options: [
    //                                 {
    //                                     optionId: 1,
    //                                     optionName: '벌꿀칼집',
    //                                     optionPrice: 2000
    //                                 }
    //                             ],
    //                         }
    //                     ],
    //                 },
    //                 {
    //                     compoundKey: '2',
    //                     productId: 2,
    //                     name: '돼지고기 2',
    //                     quantity: 13,
    //                     storeName: 'B 상점',
    //                     price: 37900,
    //                     paymentTypes: ['온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: false,
    //                     requestInput: false,
    //                     options: []
    //                 },
    //             ],
    //         },
    //         {
    //             marketId: 10,
    //             marketName: '시장 B',
    //             products: [
    //                 {
    //                     compoundKey: '3',
    //                     productId: 11,
    //                     name: '돼지고기 돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기',
    //                     quantity: 5,
    //                     storeName: 'A 상점',
    //                     price: 27000,
    //                     discountPrice: 9100,
    //                     paymentTypes: ['온누리', '온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: false,
    //                     requestInput: false
    //                 },
    //             ],
    //         },
    //         {
    //             marketId: 20,
    //             marketName: '시장 C',
    //             products: [
    //                 {
    //                     compoundKey: '4',
    //                     productId: 21,
    //                     name: '돼지고기 돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기',
    //                     quantity: 5,
    //                     storeName: 'A 상점',
    //                     price: 27000,
    //                     paymentTypes: ['온누리', '온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: true,
    //                     requestInput: true
    //                 },
    //             ],
    //         }
    //     ]
    // };
    //
    // store.commit('initState', {
    //     updateData: {
    //         markets: updateData.markets
    //     }
    // });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
  updateQuantity: function (store, { compoundKey, marketId, $event }) {
    store.commit("updateQuantity", { compoundKey, marketId, $event });
  },
  deleteProduct: function (store, data) {
    store.commit("deleteProduct", data);
  },
  orderTab: function (store, data) {
    const dataType = data.dataType;
    const marketId = data.marketId;

    state.markets.forEach((market) => {
      if (market.marketId === marketId && market.marketOnSites?.selectType) {
        market.marketOnSites.selectType = dataType;
        market.marketOnSites.tabData.forEach((tab) => {
          if (tab.dataType === dataType) {
            tab.isActive = true;
          } else {
            tab.isActive = false;
          }
        });
        if (market.marketOnSites.selectType === "PACKING") {
          market.marketOrderType = "TG";
          market.onSiteYn = "Y";
        } else {
          market.marketOrderType = market.orderType;
          market.onSiteYn = "N";
        }
      }
    });
  },
  paymentMarket: function (store, data) {
    const index = store.state.markets.findIndex((e) => e.marketId === data.marketId);
    const selectType = store.state.markets[index].marketOnSites.selectType;

    // 17시 이후 배달주문 시 팝업알림
    if (new Date().getHours() >= 17 && selectType !== "BD") {
      const _this = this;
      const modalName = "seller_delivery_modal";
      const compProps = {
        title: "안내사항",
        msgArr: [],
        btnArr: [],
        type: "alert",
        closeModal: function () {
          _this._vm.$modal.hide(modalName);
        },
      };

      // 포장주문
      if (selectType === "PACKING") {
        compProps.msgArr = ["주문 전 점포 운영시간 확인 후 주문 부탁드립니다."];
        this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
      }
      // 배달주문
      else if (selectType === "DELIVERY") {
        compProps.msgArr = ["17시 이후 주문건에 대해서는 해당 점주님께 문의 부탁드립니다."];
        this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
        return;
      }
    }

    if (data.event.event.currentTarget.closest("li").querySelector(".no_product_wrap")) {
      // this._vm.$callNative('showToast', {message: '상품의 정보가 변경되었습니다.'});
      const _this = this;
      const modalName = "like_notice_modal";
      const compProps = {
        title: "주문하기",
        msgArr: ["장바구니에 유효하지 않은 <br/>상품이 존재합니다.<br/>삭제하신 후 다시 주문해주세요."], // html 코드 적용 가능
        btnArr: [],
        type: "alert",
        closeModal: function () {
          _this._vm.$modal.hide(modalName);
        },
      };
      // 모달 출력
      this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
    } else {
      const index = store.state.markets.findIndex((e) => e.marketId === data.marketId);
      const selectType = store.state.markets[index].marketOnSites.selectType;

      // 포장 주문 시 팝업 알림
      if (selectType === "PACKING" || selectType === "TG") {
        const _this = this;
        const modalName = "seller_delivery_modal";
        const compProps = {
          title: "❗주문 방식을 확인해 주세요",
          msgArr: ["선택하신 주문 방식은 포장 주문입니다.", "해당 점포로 직접 방문하세요."],
          btnArr: [
            {
              txt: "확인",
              type: "type1 inverse",
              btnCallback: function () {
                _this._vm.$modal.hideAll();
                store.commit("paymentMarket", data.marketId);
              },
            },
            {
              txt: "거절",
              type: "type1",
              btnCallback: function () {
                _this._vm.$modal.hideAll();
              },
            },
          ],
          type: "alert",
          closeModal: function () {
            _this._vm.$modal.hide(modalName);
          },
        };

        this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
      } else {
        store.commit("paymentMarket", data.marketId);
      }
    }
  },
};

const getters = {
  title(state) {
    return state.title;
  },
  markets(state) {
    return state.markets;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
