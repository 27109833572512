<template>
  <div class="wrap" v-if="products">
    <div class="market_name">
      <a href="javascript:void(0);" class="name" :class="{ active: expand }" :data-market-id="marketId" @click="$emit('expand', $event)">{{
        marketName
      }}</a>
      <a href="javascript:void(0);" class="payment" :marketKey="marketId" @click="$emit('payment', marketId)">결제하기</a>
    </div>

    <transition name="fade">
      <ul v-show="expand" class="product_list" :style="cssProps">
        <li v-for="product in products" :key="product.id">
          <ProductList
            :compoundKey="product.compoundKey ? product.compoundKey : null"
            :marketId="marketId ? marketId : null"
            :productId="product.productId"
            :storeName="product.storeName"
            :name="product.name"
            :paymentTypes="product.paymentTypes"
            :quantity="product.quantity"
            :price="product.price"
            :discountPrice="product.discountPrice"
            :productImageUrl="product.imgUrl"
            :isDisabled="product.isDisabled"
            :requestInput="product.requestInput"
            :unitName="product.unitName"
            :unitNumber="product.unitNumber"
            :originName="product.originName"
            :optionGroups="product.optionGroups ? (product.optionGroups.length > 0 ? product.optionGroups : null) : null"
            :totalOptionPrice="product.totalOptionPrice ? product.totalOptionPrice : null"
            @add="$emit('add', $event)"
            @remove="$emit('remove', $event)"
            @delete="$emit('delete', $event)"
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import ProductList from "./ProductList";
// import {mapState} from "vuex";

export default {
  name: "MarketProductList",
  props: {
    customerMarginTop: String,
    marketId: Number,
    marketName: String,
    expand: Boolean,
    products: Array,
  },
  components: {
    ProductList,
  },
  computed: {
    cssProps() {
      return {
        "--customer-margin-top": this.customerMarginTop,
      };
    },
  },
  // watch: {
  //   customerMarginTop: function () {
  //     console.log('(this.customerMarginTop) ------------ XXXXXXXXXXX ' + this.customerMarginTop);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
// 목록 노출여부 animation
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  //margin-top: -245px;
  margin-top: var(--customer-margin-top);
}

.product_list {
  > li {
    position: relative;
    padding-bottom: 20px;
  }
}

.market_name {
  position: relative;
  height: 58px;
  padding-top: 20px;
  border-style: solid;
  border-color: #e4e4e4;
  border-width: 1px 0 0;
  background-color: $nj_background_gray;
  z-index: 999;

  .name {
    display: inline-block;
    padding: 0 36px 0 0;
    background-image: url("../../assets/images/arrow_top.png");
    background-repeat: no-repeat;
    background-position: right center;
    font-family: "Noto Sans CJK KR Bold";
    // font: normal normal bold 18px/28px Noto Sans CJK KR;
    font-size: 18px;
    letter-spacing: -1.08px;
    color: $nj_black;
    &:hover {
      color: $nj_black;
    }
    &.active {
      background-image: url("../../assets/images/arrow_bottom.png");
    }
  }
  .payment {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 14px;
    color: $nj_red;
    letter-spacing: -0.84px;
    &:hover {
      color: $nj_red;
    }
  }
}
</style>
