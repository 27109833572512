import router from "@/router";

const getStoreState = () => {
  return {
    userId: localStorage.getItem("userId"), // 사용자 아이디
    isProgressing: true, // 로딩바 Boolean
    marketId: null, // 시장 아이디
    marketName: null, // 시장 이름 String
    storeId: null, // 상점 아이디
    storeName: null, // 상점 이름 String
    storeDetail: null, // 상점 상세 정보
    representativeImageUrl: null, // 상점 대표이미지 String
    regularYn: null, // 단골여부 String : Y/N
    productType: null, // 배송타입 - BD, TB
    serviceQuickYn: null, // 음식배달 여부 Y/N

    favoriteCount: null, // 단골고객수
    productCount: null, // 전체상품
    storeIntro: null, // 상점소개
    storeTel: null, // 상점 전화번호

    topProduct: null, // 대표 상품
    saleProduct: null, // 핫세일 상품
    newProduct: null, // 새로 들어왔어요
    allProduct: null, // 모든 상품
    tabItems: null,

    contents: null,
    position: null, // 대표상품, 세일상품, 오늘들어왔어요, 전체상품
    positionArr: null,

    storeMainTab: null,
    topProductDiv: null,
    saleProductDiv: null,
    newProductDiv: null,
    allProductDiv: null,

    activeCategory: null,
    category: null, // 상품 카테고리

    scrollY: null,

    // tabItems: [// 탭메뉴
    //     {
    //         dataType: 'topProduct',
    //         text: '대표상품',
    //         isActive: true
    //     },
    //     {
    //         dataType: 'saleProduct',
    //         text: '세일상품',
    //         isActive: false
    //     },
    //     {
    //         dataType: 'newProduct',
    //         text: '새로 들어왔어요',
    //         isActive: false
    //     },
    //     {
    //         dataType: 'allProduct',
    //         text: '전체상품',
    //         isActive: false
    //     },
    // ]
  };
};

const state = getStoreState();

const mutations = {
  changeCategoryPosition: function (state, categoryListWrap) {
    // 활성화 탭 좌표가 화면에 가려질때 대응
    const windowWidth = window.innerWidth;
    // console.log(state[tabType]);
    // var categoryListWrap = state.contents.querySelector('.storeMainTab');

    setTimeout(() => {
      const categoryList = categoryListWrap.querySelectorAll("li");
      const categoryListLength = categoryList.length;
      for (var i = 0; i < categoryListLength; i++) {
        if (categoryList[i].querySelector("a").classList.contains("active")) {
          // console.log(windowWidth);
          // console.log(categoryListWrap.scrollLeft);
          // console.log(categoryList[i].offsetLeft);
          // console.log(categoryList[i].offsetWidth);
          if (windowWidth < categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft) {
            categoryListWrap.scrollLeft =
              categoryListWrap.scrollLeft +
              (categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft - windowWidth) +
              16;
          } else if (categoryListWrap.scrollLeft > categoryList[i].offsetLeft + 16) {
            categoryListWrap.scrollLeft = categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryList[i].offsetWidth - 16;
          }
        }
      }
    }, 1);
  },
  setPosition: function (state, data) {
    const contents = state.contents;

    const storeMainTab = contents.querySelector(".storeMainTab");
    const topProductDiv = contents.querySelector(".topProduct");
    const saleProductDiv = contents.querySelector(".saleProduct");
    const newProductDiv = contents.querySelector(".newProduct");
    const allProductDiv = contents.querySelector(".allProduct");
    const allProductTabDiv = contents.querySelector(".allProductTab");

    state.storeMainTab = storeMainTab;
    state.topProductDiv = topProductDiv;
    state.saleProductDiv = saleProductDiv;
    state.newProductDiv = newProductDiv;
    state.allProductDiv = allProductDiv;
    state.allProductTabDiv = allProductTabDiv;

    state.position = {
      tab: storeMainTab.offsetTop,
      top: topProductDiv ? topProductDiv.offsetTop : null,
      sale: saleProductDiv ? saleProductDiv.offsetTop : null,
      new: newProductDiv ? newProductDiv.offsetTop : null,
      all: allProductDiv ? allProductDiv.offsetTop : null,
      allTab: allProductTabDiv ? allProductTabDiv.offsetTop : null,
    };

    state.positionArr = ["tab"];
    if (state.position.top) {
      state.positionArr.push("top");
    }
    if (state.position.sale) {
      state.positionArr.push("sale");
    }
    if (state.position.new) {
      state.positionArr.push("new");
    }
    if (state.position.all) {
      state.positionArr.push("all");
    }
    if (state.position.allTab) {
      state.positionArr.push("allTab");
    }

    if (state.positionArr.indexOf("all") && allProductDiv) {
      state.allProductDiv.classList.remove("active");
    }
    state.allProductDiv.classList.remove("active");

    this.commit("store/handleScroll");
  },
  setRefContents: function (state, data) {
    state.contents = data.ref;
  },
  beforeDestroy: function () {
    state.contents.classList.remove("active");
    state.contents.querySelector(".allProduct").classList.remove("active");
  },
  handleScroll: function (state) {
    state.scrollY = document.querySelector("html").scrollTop;

    if (
      state.scrollY >
      state.contents?.querySelector(".store_header").offsetHeight + state.contents?.querySelector(".search_area").offsetHeight - 2
    ) {
      state.contents?.classList.add("active");
    } else {
      state.contents?.classList.remove("active");
    }

    // 전체상품 예외처리
    if (state.contents?.querySelector(".allProduct")) {
      if (state.scrollY > state.position.allTab - 50) {
        state.contents?.querySelector(".allProduct").classList.add("active");
      } else {
        state.contents?.querySelector(".allProduct").classList.remove("active");
      }
    }

    var dataType = "topProduct";
    const scrollTop = state.scrollY;
    const positionInfo = state.position;

    // console.log(positionInfo);

    if (positionInfo) {
      if (positionInfo.all && positionInfo.all - 180 < scrollTop + 43 + 50) {
        dataType = "allProduct";
      } else if (positionInfo.new && positionInfo.new - 180 < scrollTop + 43 + 50) {
        dataType = "newProduct";
      } else if (positionInfo.sale && positionInfo.sale < scrollTop + 43 + 50 + 8) {
        dataType = "topProduct";
        if (!state.topProduct && !state.topProduct.productId && state.position.sale) {
          state.contents.querySelector(".saleProduct").style.marginTop = "43px";
        }
      } else if (positionInfo.top && positionInfo.top < scrollTop + 43 + 50) {
        dataType = "topProduct";
      } else {
        if (state.contents.querySelector(".saleProduct")) {
          state.contents.querySelector(".saleProduct").style.marginTop = "0";
        }
        // if (state.tabItems[0]) {
        if (state.tabItems.length) {
          dataType = state.tabItems[0].dataType;
        }
        // }
      }
    } else {
      dataType = "topProduct";
    }

    if (state.tabItems) {
      state.tabItems = state.tabItems.map((item) => {
        if (dataType == item.dataType) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      });
    }
  },

  showStoreInfo: function (state, data) {
    const _this = this;
    const modalName = "storeInfo";
    const compProps = {
      title: "매장정보",
      type: "text_list",
      items: [
        {
          title: "대표자명",
          text: state.storeDetail.representativeName,
        },
        {
          title: "상호명",
          text: state.storeDetail.businessName,
        },
        {
          title: "전화번호",
          text: this._vm.$formatTelNumber(state.storeDetail.businessCall),
          type: "call",
          appVersion: this.state.appVersion,
        },
        {
          title: "사업자 등록번호",
          text: state.storeDetail.businessNumber.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3"),
        },
        {
          title: "사업자 주소",
          text: state.storeDetail.businessAddress,
          type: "copy",
          appVersion: this.state.appVersion,
        },
      ],

      explainTxt: state.storeDetail.smartStoreDesc ? state.storeDetail.smartStoreDesc.replace("\n", "<br/>") : null,

      closeModal: function () {
        // X 버튼 클릭시
        // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
        _this._vm.$modal.hideAll();
      },
    };

    this.dispatch(
      "showModal",
      {
        name: modalName,
        compProps: compProps,
        modalProps: {
          name: modalName,
          width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
          height: "auto",
        },
      },
      { root: true },
    );
  },
  callStore: function (state, tel) {
    this._vm.$callNative("goCall", { tel: tel });
  },
  search: function (state, keyword) {
    if (!keyword.trim()) {
      this._vm.$callNative("showToast", { message: "검색어를 입력해주세요." });
    } else {
      if (keyword.trim().length < 1) {
        this._vm.$callNative("showToast", {
          message: "검색어는 1글자 이상 입력해주세요.",
        });
      } else {
        router.push({
          name: "검색",
          query: {
            marketId: state.marketId,
            storeId: state.storeId,
            title: state.storeName,
            productType: state.productType,
            keyword: keyword,
            serviceQuickYn: state.serviceQuickYn,
          },
        });
      }
    }
  },
  moveToPage: function (state, productId) {
    router.push({
      name: "상품 메인",
      params: {
        productId: productId,
      },
    });
  },
  navigateTo: function (state, data) {
    if (data.path) {
      if (data.params) {
        this._vm.$navigateTo(data.path, data.params);
      } else {
        this._vm.$navigateTo(data.path);
      }
    }
  },
  updateState: function (state, data) {
    state[data.type] = data.updateData;
    // console.log('updateState :::::::::::: ');
    // console.log(state);
    if (data.type == "userId") {
      state.userId = data.updateData;
    }
  },
  setStoreInfo: function (state, data) {
    // console.log('setStoreInfo ::: ');
    // console.log(data);
    state.marketId = data.marketId;
    state.marketName = data.marketName;
    state.storeId = data.storeId;
    state.storeName = data.storeName;
    state.favoriteCount = data.favoriteCount;
    state.productCount = data.productCount;
    state.storeIntro = data.storeIntro;
    state.storeTel = data.storeTel;
    state.productType = data.productType;
    state.serviceQuickYn = data.serviceQuickYn;

    state.representativeImageUrl = data.representativeImageUrl;
  },
  initState: function (state, data) {
    state["marketId"] = data.marketId;
    state["marketName"] = data.marketName;
    state["storeId"] = data.storeId;
    state["storeName"] = data.storeName;
    state["scrollY"] = 0;
  },
  goBack: function () {
    this.commit("store/reset");
    // router.back();
    this._vm.$navigateBack();
  },
  reset: function (state) {
    Object.assign(state, getStoreState());
  },
  // setRefTab: function (state, data) {
  //     console.log(data);
  //     if (data.mainTab) {
  //         state['mainTab'] = data.mainTab;
  //     }
  //     if (data.contents) {
  //         state['contents'] = data.contents;
  //     }
  // }
};

const actions = {
  setRegular: function (store, event) {
    // 단골 적용여부
    // console.log('setRegular');

    const _this = this;
    const userId = localStorage.getItem("userId");

    if (!userId) {
      const modalName = "callAuthentication";
      const compProps = {
        title: "휴대전화 본인인증",
        msgArr: ["휴대전화 본인 인증이 필요합니다."], // html 코드 적용 가능
        btnArr: [
          {
            txt: "본인 인증 하기",
            type: "type1 inverse",
            data: { id: "ok" },
            btnCallback: function () {
              _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
              _this.commit("store/navigateTo", { path: "authentication" });
            },
          },
        ],
        closeModal: function () {
          // X 버튼 클릭시
          // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
          _this._vm.$modal.hideAll();
        },
      };

      store.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
          // seconds: 3
        },
        { root: true },
      );

      return;
    }

    const currentTarget = event.currentTarget;
    const regularUrl = "/v3/user/store/market/" + state.marketId + "/store/" + state.storeId + "/regular/" + userId;
    const regularYn = !currentTarget.classList.contains("active") ? "Y" : "N";
    const params = {
      regularYn: regularYn,
    };

    this._vm.$axios1
      .put(regularUrl, params) // 단골 등록/해제
      .then((response) => {
        if (response.data.responseCode == 2000) {
          currentTarget.classList.toggle("active");

          var favoriteCount = state.favoriteCount;
          if (currentTarget.classList.contains("active")) {
            this._vm.$callNative("showToast", {
              message: state.storeName + " 상점과 단골맺기를 하였습니다.",
            });
            favoriteCount++;
          } else {
            this._vm.$callNative("showToast", {
              message: state.storeName + " 상점과 단골끊기를 하였습니다.",
            });
            favoriteCount--;
          }
          // console.log('favoriteCount ::: ' + favoriteCount);
          store.commit("updateState", {
            type: "favoriteCount",
            updateData: favoriteCount,
          });
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  },
  clickTab: async function (store, data) {
    // const dataType = data.dataType;
    // const marketId = state.marketId;

    state.tabItems = state.tabItems.map((item) => {
      if (data.dataType == item.dataType) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });

    state.contents.classList.add("active");

    if (data.dataType == "topProduct") {
      // state.scrollY = state.contents.querySelector('.store_header').offsetHeight + state.contents.querySelector('.search_area').offsetHeight + 1;

      document.querySelector("html").scrollTop =
        state.contents.querySelector(".store_header").offsetHeight + state.contents.querySelector(".search_area").offsetHeight + 1;
      // state.contents.scrollTop = state.contents.querySelector('.store_header').offsetHeight + state.contents.querySelector('.search_area').offsetHeight + 1;
    } else if (data.dataType == "saleProduct") {
      if (!state.topProduct && !state.topProduct.productId && state.position.sale) {
        state.contents.querySelector(".saleProduct").style.marginTop = "43px";
      }
      document.querySelector("html").scrollTop = state.position["sale"] - 102; // - 43;
      // state.scrollY = state.position['sale'] - 43;
      // state.contents.scrollTop = state.position['sale'] - 43;
    } else if (data.dataType == "newProduct") {
      if (!state.topProduct && !state.topProduct.productId && state.position.sale) {
        state.contents.querySelector(".saleProduct").style.marginTop = "43px";
      }
      document.querySelector("html").scrollTop = state.position["new"] - 102;
      // state.scrollY = state.position['new'] - 43;
      // state.contents.scrollTop = state.position['new'] - 43;
    } else if (data.dataType == "allProduct") {
      if (!state.topProduct && !state.topProduct.productId && state.position.sale) {
        state.contents.querySelector(".saleProduct").style.marginTop = "43px";
      }
      document.querySelector("html").scrollTop = state.position["all"] - 102; // - 43 + 10;
      // state.scrollY = state.position['all'] - 43;
    }

    // if (state.contents.scrollTop > state.position.tab + 1) {
    //     state.contents.classList.add('active');
    // }
    // else {
    //     state.contents.classList.remove('active');
    // }

    this.commit("store/changeCategoryPosition", state.contents.querySelector(".storeMainTab"));

    // state.contents.scrollTop
    // this.dispatch('marketMain/clickCategoryTab', {categoryId: data.categoryId});
  },
  clickCategory: function (store, data) {
    state.isProgressing = true;

    const dataType = data.dataType;
    state.category = state.category.map((item) => {
      if (dataType == item.dataType) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });

    const category = dataType.split("_");

    this.commit("store/changeCategoryPosition", state.contents.querySelector(".allProductTab"));

    document.querySelector("html").scrollTop = state.position["all"] - 102 + 49;

    state.activeCategory = dataType;
    const allUrl =
      "/v3/market/delivery/product/market/" +
      state.marketId +
      "/store/" +
      state.storeId +
      "/categories/user/" +
      state.userId +
      "/products/productType/" +
      state.productType +
      "?categoryId=" +
      category[1] +
      "&subCategoryId=" +
      category[2];
    this._vm.$axios1
      .get(allUrl) // 전체상품
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const productList = response.data.result;
          const sendData = {
            type: "allProduct",
            updateData: productList,
          };
          store.commit("updateState", sendData);
          state.isProgressing = false;
        } else {
          state.isProgressing = false;
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        state.isProgressing = false;
        return false;
      });
  },
  initState: async function (store, data) {
    store.commit("initState", data);
    state.isProgressing = true;

    state.tabItems = []; // 탭메뉴

    const userId = state.userId;
    const marketId = data.marketId;
    const storeId = data.storeId;

    if (!marketId || !storeId) {
      state.isProgressing = false;
      return;
    }
    if (data.regularYn) {
      // 단골여부 값이 있으면 적용한다.
      store.commit("updateState", {
        type: "regularYn",
        updateData: data.regularYn,
      });
    }

    const storeUrl = "/v3/market/delivery/store/" + marketId + "/" + storeId + "/information"; // 상점정보
    const storeDetailUrl = "/v3/market/delivery/store/" + marketId + "/" + storeId + "/business/information"; // 상점상세정보
    const topProductUrl = "/v3/market/delivery/product/market/" + marketId + "/store/" + storeId + "/representative/bd"; // 대표상품
    const saleUrl = "/v3/event/discount/market/" + marketId + "/store/" + storeId + "/user/" + userId + "/discount/bd"; // 세일상품
    const newUrl = "/v3/event/new-merchandise/market/" + marketId + "/store/" + storeId + "/user/{userId}/new-merchandise/bd?userId=" + userId; // 새로들어왔어요
    // const categoryUrl = "/v3/market/delivery/category/market/" + marketId + "/store/" + storeId + "/categories/sale"; // 카테고리
    var categoryUrl = "/v3/market/delivery/category/market/" + marketId + "/store/" + storeId + "/sub-categories/products/productType/"; // 카테고리

    var storeProductType = "bd";

    await this._vm.$axios1
      .get(storeUrl) // 상점정보
      .then((response) => {
        if (response.data.responseCode == 2000) {
          if (response.data.result.productType === "TB") {
            categoryUrl = categoryUrl + "TB";
          } else {
            categoryUrl = categoryUrl + "BD";
          }
          store.commit("setStoreInfo", response.data.result);
          storeProductType = response.data.result.productType;
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    await this._vm.$axios1
      .get(storeDetailUrl) // 상점 상세정보
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const sendData = {
            type: "storeDetail",
            updateData: response.data.result,
          };
          store.commit("updateState", sendData);
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });

    if (!data.regularYn || data.regularYn != "Y") {
      // 상점에 단골여부가 체크되지 않았을때 단골여부를 확인한다.
      const regularUrl = "/v3/market/delivery/store/market/" + marketId + "/store/" + storeId + "/regular?userId=" + userId;
      await this._vm.$axios1
        .get(regularUrl) // 단골여부
        .then((response) => {
          if (response.data.responseCode == 2000) {
            const sendData = {
              type: "regularYn",
              updateData: response.data.result.regularYn,
            };
            store.commit("updateState", sendData);
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }

    await this._vm.$axios1
      .get(topProductUrl) // 대표상품
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const productInfo = response.data.result;
          if (productInfo) {
            state.tabItems.push({
              dataType: "topProduct",
              // text: '대표상품',
              text: "핫세일",
              isActive: false,
            });
            const sendData = {
              type: "topProduct",
              updateData: productInfo,
            };
            store.commit("updateState", sendData);
          } else {
            state.topProduct = [];
          }
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    await this._vm.$axios1
      .get(saleUrl) // 세일상품
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const productList = response.data.result.productList;
          if (productList.length == 0) {
            // 목록이 없으면 탭메뉴 삭제
            // state.tabItems = state.tabItems.filter(item => item.dataType != 'saleProduct');
          } else {
            const isTopProduct = (element) => element.dataType == "topProduct";
            const topProductIndex = state.tabItems.findIndex(isTopProduct);
            if (topProductIndex < 0) {
              state.tabItems.push({
                dataType: "topProduct",
                text: "핫세일",
                isActive: false,
              });
            }
          }

          const sendData = {
            type: "saleProduct",
            updateData: productList,
          };
          store.commit("updateState", sendData);
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    await this._vm.$axios1
      .get(newUrl) // 새로 들어왔어요
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const productList = response.data.result.productList;
          if (productList.length == 0) {
            // 목록이 없으면 탭메뉴 삭제
            state.tabItems = state.tabItems.filter((item) => item.dataType != "newProduct");
          } else {
            state.tabItems.push({
              dataType: "newProduct",
              text: "새로 들어왔어요",
              isActive: false,
            });
          }
          const sendData = {
            type: "newProduct",
            updateData: productList,
          };
          store.commit("updateState", sendData);
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });

    await this._vm.$axios1
      .get(categoryUrl) // 전체상품 카테고리
      .then((response) => {
        if (response.data.responseCode == 2000) {
          const categoryList = response.data.result.categoryList;

          state.tabItems.push({
            dataType: "allProduct",
            text: "전체상품",
            isActive: false,
          });

          const category = categoryList.map((item, index) => {
            return {
              dataType: "category_" + item.categoryId + "_" + item.subCategoryId,
              text: item.subCategoryName,
              isActive: index == 0 ? true : false,
            };
          });

          const sendData = {
            type: "category",
            updateData: category,
          };
          store.commit("updateState", sendData);
          state.activeCategory = category[0].dataType;
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });

    // const allUrl = '/v3/market/product/market/' + marketId + '/store/' + storeId + '/categories/user/' + userId + '/products/productType/' + storeProductType + '?categoryId=0&subCategoryId=0';
    const allUrl =
      "/v3/market/delivery/product/market/" +
      marketId +
      "/store/" +
      storeId +
      "/categories/user/" +
      userId +
      "/products/productType/" +
      storeProductType +
      "?categoryId=0&subCategoryId=0";
    await this._vm.$axios1
      .get(allUrl) // 전체상품
      .then((response) => {
        // console.log(response);
        if (response.data.responseCode == 2000) {
          const productList = response.data.result;
          // console.log(response);
          if (productList.length == 0) {
            // 목록이 없으면 탭메뉴 삭제
            state.tabItems = state.tabItems.filter((item) => item.dataType != "allProduct");
          }
          const sendData = {
            type: "allProduct",
            updateData: productList,
          };
          store.commit("updateState", sendData);
          state.isProgressing = false;
        } else {
          state.isProgressing = false;
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        state.isProgressing = false;
        return false;
      });

    if (state.tabItems.length > 0) {
      state.tabItems[0].isActive = true;

      state.contents.classList.remove("active");
      state.contents.querySelector(".allProduct").classList.remove("active");

      setTimeout(() => {
        store.commit("setPosition");
      }, 1);
    }

    // if (state.tabItems.length > 0) {////////////////////////////////
    //     if (state.tabItems[0].dataType == 'allProduct') {
    //         this.commit('store/handleScroll');
    //         // this.commit('store/changeCategoryPosition', state.contents.querySelector('.allProductTab'));
    //     }
    // }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
