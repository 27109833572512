<template>
  <div class="wrap">
    <TopHeader :title="title" theme="nj_red" @goBack="goBack" />
    <div class="contents_wrap">
      <div class="contents_area">
        <p>서비스 탈퇴를 하시면 받아 놓으신 혜택(쿠폰, 포인트) 및 모든 데이터가 초기화 되어 사용하실 수 없으니, 신중하게 결정해 주세요.</p>
        <p class="mt_30">그 동안 서비스 이용에 진심으로 감사드리며 더 좋은 모습으로 찾아뵐 수 있도록 최선을 다하겠습니다.</p>
      </div>
      <div class="absolute_bottom">
        <Btn type="type1 reverse" value="탈퇴하기" @btnCallback="withdraw" />
      </div>
    </div>

    <Alert
      :title="'회원탈퇴'"
      :displayLayer="displayLayer"
      :texts="['탈퇴 신청 30일 후 회원탈퇴가 완료되며 <br>쿠폰 포인트 및 모든 데이터가 삭제됩니다.<br>회원탈퇴를 진행하시겠습니까?']"
      :textClass="[]"
      :btns="[
        {
          type: null,
          btnId: '',
          btnData: {},
          value: '탈퇴취소',
        },
        {
          type: null,
          btnId: 'WITHDRAW',
          btnData: {},
          value: '회원탈퇴',
        },
      ]"
      :showHideLayer="showHideLayer"
    />
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import Btn from "@/components/Btn";
import Alert from "@/components/Alert";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Withdraw",
  beforeCreate: function () {
    this.$store.dispatch("withdraw/initState", {
      userId: this.$route.query.userId,
    });
  },
  computed: {
    ...mapState({
      title: (state) => state.withdraw.title,
      userId: (state) => state.withdraw.userId,
      displayLayer: (state) => state.withdraw.displayLayer,
    }),
  },
  components: {
    TopHeader,
    Alert,
    Btn,
  },
  methods: {
    ...mapMutations("withdraw", []),
    ...mapActions("withdraw", ["goBack", "updateWithdraw", "showHideLayer"]),

    withdraw: function () {
      this.showHideLayer();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}
.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.contents_area {
  padding: {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}
p {
  margin: 0;
  @include font(16, 24, -0.32);
  color: $nj_text_main_gray;
}
.absolute_bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
</style>
