<template>
  <div class="product_list_wrap">
    <!--    Other Product List Title -->
    <div class="product_list_header">
      <div class="product_list_title">이 가게 다른 상품</div>
      <div class="btn_more_product" @click="moveToStore">더보기</div>
    </div>
    <!--    Other Product List -->
    <div class="product_list">
      <ProductItem
        v-for="(storeProduct, idx) in storeProducts"
        v-bind:key="idx"
        :id="storeProduct.productId"
        :productImageUrl="storeProduct.productImageUrl"
        :productName="storeProduct.productName"
        :price="storeProduct.price"
      />
    </div>
  </div>
</template>

<script>
import ProductItem from "./ProductItem";

export default {
  name: "OtherProduct",
  props: {
    marketId: Number,
    storeId: Number,
    storeProducts: Array,
  },
  components: {
    ProductItem,
  },
  methods: {
    moveToStore: function () {
      this.$callNative("moveToStore", {
        marketId: this.marketId,
        storeId: this.storeId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product_list_wrap {
  background: #e2e2e2;
  padding: 20px 0 24px 0;

  .product_list_header {
    & > * {
      padding: 0 20px;
      display: inline-block;
    }

    .product_list_title {
      font: normal normal normal 20px/29px Noto Sans CJK KR Bold;
      letter-spacing: -1.2px;
      color: $nj_black;
      margin-bottom: 10px;
    }

    .btn_more_product {
      float: right;
      font: normal normal normal 16px/29px Noto Sans CJK KR;
      letter-spacing: -0.96px;
      color: $nj_text_main_gray;
    }
  }

  .product_list {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 20px;
  }
}
</style>
