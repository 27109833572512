const state = {
  title: "템플릿",
  orderId: "",
  // orderList: [],
};

const mutations = {
  moveToApp: function (state, data) {
    // console.log(data);
    // this._vm.$callNative('moveToOrder', {orderId: data.orderId});
  },
  initState: function (state, data) {
    // console.log(data);
    //     state['orderList'] = [...state['orderList'], ...data.initData.contents];
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },
};

const actions = {
  initState: function (store, data) {
    state.orderId = data.orderId;

    const axiosUrl = "/v3/reviews/" + state.orderId;
    this._vm.$axios2
      .get(axiosUrl)
      .then((response) => {
        if (response.data.responseCode == 2000) {
          store.commit("initState", {
            initData: response.data.result,
          });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
