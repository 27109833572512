<template>
  <div class="wrap">
    <TopHeader :title="title" theme="nj_white" @goBack="goBack" />
    <div class="contents_wrap">
      <div class="contents_header_wrap">
        <div class="contents_header">
          <SelectList :selectList="selectList" :selectCallback="selectCallback" />
        </div>
      </div>

      <ul class="market_list">
        <li v-for="market in marketList" :key="market.id">
          <div class="market_title_wrap">
            <div class="market_title">
              {{ market.marketName }}
              <Btn type="map" btnId="map" value="시장 지도" link="AAA" :data-market-id="market.marketId" @btnCallback="marketMap" />
              <div class="absolute_right" v-if="marketTypeRadioOption[0].active">
                <span class="delivery" :class="{ active: market.deliveryYn == 'Y' }">
                  {{ market.deliveryYn == "Y" ? "배달가능" : "배달불가" }}
                </span>
              </div>
            </div>
          </div>

          <EventList :market="market" :goEvent="goEvent" :goMarket="goMarket" />
        </li>
      </ul>
    </div>

    <!-- 레이어 팝업 -->
    <Layer :displayLayer="displayLayer" :closeLayer="showHideRadioPop">
      <template v-slot:content>
        <!-- 라디오 목록 -->
        <RadioList :options="marketTypeRadioOption" :checkValue="marketType" :changeRadio="changeRadio" />
      </template>
    </Layer>

    <Alert
      :displayLayer="displayGuideLayer"
      :texts="['서비스 준비중입니다.']"
      :textClass="[]"
      :btns="[
        {
          value: '확인',
        },
      ]"
      :showHideLayer="showHideGuideLayer"
    />
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import Btn from "../../components/Btn";
import Layer from "../../components/Layer";
import Alert from "../../components/Alert";
import RadioList from "../../components/list/RadioList";
import SelectList from "../../components/list/SelectList";
import EventList from "../../components/list/EventList";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Market",
  props: {},
  components: {
    TopHeader,
    Btn,
    Layer,
    RadioList,
    SelectList,
    EventList,
    Alert,
  },
  computed: {
    ...mapState({
      title: (state) => state.market.title,
      deliveryType: (state) => state.market.deliveryType,
      marketType: (state) => state.market.marketType,
      // marketTypeActionString: state => state.market.marketTypeActionString,
      marketTypeRadioOption: (state) => state.market.marketTypeRadioOption,
      deliverySelectType: (state) => state.market.deliverySelectType,
      sortType: (state) => state.market.sortType,
      marketList: (state) => state.market.marketList,
      displayLayer: (state) => state.market.displayLayer,
      selectList: (state) => state.market.selectList,
      displayGuideLayer: (state) => state.market.displayGuideLayer,
    }),
    // this.$route.query.userId ? this.$route.query.userId : null,
  },
  watch: {
    // marketType: function () {
    //   console.log('watch marketType ::::::::::: ');
    //   console.log(this.marketType);
    // },
  },
  data: function () {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch("market/initState", {
      deliveryType: this.$route.query.deliveryType,
      latitude: this.$route.query.latitude,
      longitude: this.$route.query.longitude,
    });
  },
  mounted: function () {
    // this.updateState();
    window.onBackPressed = this.onBackPressed;
  },
  methods: {
    ...mapMutations("market", [
      "showHideRadioPop", // 레이어 팝업 토글
      "showHideGuideLayer", // 서비스 준비중입니다 토글
      "marketMap", // 시장 지도
      "goEvent", // 이벤트 상세페이지 이동
      "goMarket", // 시장 보러가기
    ]),
    ...mapActions("market", [
      "goBack",
      "initState", // 전체 데이터 호출
      "changeRadio", // 레이어 팝업에서 라디오 선택시 : 바로배달 시장, 전국배송 시장, 일반시장
      "selectCallback", // 셀렉트 박스 버튼 클릭
    ]),

    onBackPressed: function () {
      this.showHideRadioPop();
      // this.$refs.carousel.onBackPressed();
    },
  },
};
</script>

<style lang="scss" scoped>
.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  background-color: $nj_background_gray;
  overflow: auto;
}

.contents_header_wrap {
  padding: 0 20px;
  .contents_header {
    height: 50px;
    padding: 15px 0 0;
    // height: 54px;
    //padding: 19px 0 0;
  }
}
.market_title_wrap {
  padding: 0 20px;
}
.market_title {
  position: relative;
  padding: 19px 0 0;
  @include font(20, normal, -1.2);
  color: $nj_text_main_gray;

  border-style: solid;
  border-color: $nj_divider_gray;
  border-width: 1px 0 0;

  .absolute_right {
    position: absolute;
    top: 15px;
    right: 0;
  }
  .delivery {
    @include font(16, normal, -0.96);
    color: $nj_text_main_gray;
    &.active {
      color: $nj_red;
    }
  }
}
.market_list {
  > li {
    min-height: 171px;
  }
}
</style>
