<!-- 장바구니, 결제 시 나오는 상품 리스트 -->
<template>
  <div class="cart_product_container" :class="{ bundle_product: bundleIndex && deliveryBundleId }">
    <div class="no_product_wrap" v-if="disabled">
      <!-- v-if="isDisabled" -->

      <div class="no_product_msg">
        <div class="msg" v-if="disabledType == 'changeOption'">해당 상품의 정보가 변경되었습니다.</div>
        <!--<div class="msg" v-else-if="marketOpenYn=='N'">영업 종료된 시장의 상품입니다.</div>-->
        <div class="msg" v-else-if="storeOpenYn == 'N'">영업 종료된 상점의 상품입니다.</div>
        <div class="msg" v-else-if="productSaleYn == 'N'">해당 상품은 판매 종료되었습니다.</div>
        <!--        <div class="msg" v-else>해당 상품은 판매 종료되었습니다.</div>-->
      </div>

      <div class="absolute_right">
        <Btn type="type6 reverse" value="삭제" @btnCallback="$emit('delete', { marketId, compoundKey })" />
      </div>
    </div>

    <div
      class="product_wrap"
      :class="[isInfo ? (!placeholder ? 'view_info' : 'view_info type1') : isHistory ? 'view_history' : viewMode == 'detail' ? 'view_detail' : '']"
    >
      <div v-if="bundleCount && bundleCount > 1 && bundleIndex === 1 && deliveryBundleId" class="delivery_title mb_15">
        <strong class="title">
          묶음 배송
          <span class="accent">{{ bundleCount }}개 상품</span>
        </strong>
      </div>
      <div class="product_top">
        <div class="product_detail">
          <div class="thumb_wrap" @click="$emit('productClick')" v-if="viewMode != 'detail'">
            <a href="javascript:void(0)" :data-product-id="productId" @click="moveToProduct">
              <img :src="productImageUrl" class="thumb no_img" alt="" />
            </a>
          </div>

          <template v-if="viewMode != 'detail'">
            <div class="store_name_wrap" :class="viewMode != 'default' ? 'type1' : null">
              <ul class="delivery_type_list" v-if="deliveryMethod || atYn == 'Y'">
                <li class="tb" v-if="deliveryMethod.includes('TB')">택배</li>
                <li class="fc" v-if="Number(marketId) === 168">무료배송</li>
                <li
                  class="at"
                  v-if="
                    atYn == 'Y' &&
                    Number(marketId) !== 168 &&
                    discountType != 'SEOUL' &&
                    discountType != 'NONE' &&
                    sssMarkets.indexOf(Number(marketId)) === -1 &&
                    hideAtLabelProducts.indexOf(Number(productId)) === -1
                  "
                  v-text="nonghalText"
                ></li>
              </ul>

              <a href="javascript:void(0)" :data-product-id="productId" @click="moveToProduct">
                <span class="store_name"
                  ><!-- @click="$emit('storeClick')" -->
                  {{ storeName }}
                  <span class="market_name" v-if="viewMode != 'default' && marketName">
                    <template v-if="viewMode == 'info'">
                      {{ marketName }}
                    </template>
                    <template v-else> ({{ viewMode != "default" ? marketName : null }}) </template>
                  </span>
                </span>
              </a>

              <div class="absolute_right" v-if="viewMode != 'info' && viewMode != 'detail' && viewMode != 'payment'">
                <Btn type="type6" value="삭제" @btnCallback="$emit('delete', { marketId, compoundKey })" />
              </div>
            </div>
          </template>
          <!--            <div class="name_repeat mt_5 mb_5" v-if="viewMode == 'info'">{{ marketName }} ({{ storeName }})</div>-->

          <a href="javascript:void(0)" :data-product-id="productId" @click="moveToProduct">
            <div class="product_name" :class="{ shipping_type: shippingType }">
              {{ name }},
              {{ weight ? $formatWeight(weight) : "" }}
              {{ unitNumber ? (unitNumber + unitName !== $formatWeight(weight) ? unitNumber + unitName : "") : "" }}
              <!-- TODO 아래 코드 무게 참조가 되어야함 PACKING WEIGHT -->
              <div class="absolute_right_txt" v-if="shippingType">{{ shippingType === "PACKING" ? "박스포장" : $formatWeight(totalWeight) }}</div>
              <!--                <span v-if="unitName" class="unit">{{ unitNumber + unitName }}</span>-->
            </div>
            <div v-if="originName" class="origin_name">원산지 : {{ originName }}</div>
          </a>

          <!--            <ul class="chip_list mt_6" v-if="isInfo || isHistory">-->
          <!--              <div class="name_repeat mt_5 mb_5">{{ marketName }}-->
          <!--                <template v-if="isInfo">({{ storeName }})</template>-->
          <!--              </div>-->
          <!--            </ul>-->

          <template v-if="viewMode != 'detail' && viewMode != 'info'">
            <ul class="opt_list" v-if="optionGroups">
              <li v-for="optionGroup in optionGroups" :key="optionGroup.optionGroupId">
                <span style="display: block" class="opt" v-for="option in optionGroup.options" :key="option.optionId">
                  <!--                  {{ groupIndex != 0 || index != 0 ? "/" : null }}-->

                  {{ option.optionName }} {{ option.optionWeight ? $formatWeight(option.optionWeight) : "" }}
                  <!-- {{ option.optionWeight ? $formatWeight(option.optionWeight) : option.optionName }} -->

                  <!--                  <span>({{ "+" + $formatPrice(Number(option.optionPrice)) + "원" }})</span>-->
                  <span>({{ (Number(option.optionPrice) >= 0 ? "+" : "") + $formatPrice(Number(option.optionPrice)) + "원" }})</span>
                </span>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="opt_list" v-if="optionGroups">
              <li v-for="optionGroup in optionGroups" :key="optionGroup.optionGroupId">
                <span class="opt" v-for="option in optionGroup.optionsItems" :key="option.optionItemId">
                  <!--                  {{ groupIndex != 0 || index != 0 ? "/" : null }}-->
                  {{ option.optionItemName }}

                  <span>({{ (Number(option.optionPrice) >= 0 ? "+" : "") + $formatPrice(Number(option.optionPrice)) + "원" }})</span>
                </span>
              </li>
            </ul>
          </template>

          <div class="payment_list_wrap" v-if="viewMode != 'detail' || placeholder" :class="{ mt_10: placeholder }">
            <!-- && viewMode != 'info' -->
            <ul class="inline_list type2 icon_card">
              <li v-for="(payment, index) in paymentTypes" :key="'payment_' + index">
                <!-- <p v-if="payment.paymentName === '구로사랑상품권' && marketId === 176">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else-if="payment.paymentName === '신온누리'"></p>
                <p v-else-if="payment.paymentName === '금천G밸리사랑상품권' && marketId === 187">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else-if="payment.paymentName === '금천G밸리사랑상품권' && marketId === 190">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else-if="payment.paymentName === '금천G밸리사랑상품권' && marketId === 192">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else>
                  {{ payment.paymentName }}
                </p> -->
                <p v-if="payment.paymentName === '신온누리'"></p>
                <p v-else>
                  {{ payment.paymentName }}
                </p>
              </li>
            </ul>
          </div>

          <div class="product_bottom" :class="viewMode != 'default' ? 'type1' : null" v-if="!isHistory">
            <div class="quantity_wrap" v-if="viewMode != 'info' && viewMode != 'detail' && viewMode != 'payment'">
              <a href="javascript:void(0)" class="quantity_btn btn_minus" @click="$emit('remove', { compoundKey, marketId, $event })"></a>
              <div class="quantity_txt">{{ quantity }}</div>
              <a href="javascript:void(0)" class="quantity_btn btn_plus" @click="$emit('add', { compoundKey, marketId, $event })"></a>
            </div>

            <div class="order_count" :class="discountPrice ? 'type1' : null" v-if="viewMode != 'default'">
              <!-- 결재완료, 주문상세에서 사용 -->
              <span class="txt">주문</span>수량 : {{ quantity }}개
            </div>

            <div class="price_container">
              <div
                class="discount"
                v-if="(viewMode != 'detail' && viewMode != 'info') || viewMode == 'info' || (viewMode == 'payment' && discountPrice)"
              >
                <div class="price_wrap original_name" v-if="discountPrice">
                  <span class="price">{{ $formatPrice(price * quantity) }}원</span>
                </div>
              </div>
              <div class="price_wrap">
                <span class="price">{{ unitPrice ? $formatPrice(unitPrice * quantity) : $formatPrice(totalProductPrice) }}</span>
                <span class="price_txt">원</span>
              </div>
            </div>

            <input class="request_msg_wrap" v-if="requestInput && orderRequest && viewMode == 'detail'" readonly :value="orderRequest" />
          </div>
        </div>

        <div class="delivery_group_fee" v-if="viewMode === 'default' && deliveryMethod === 'TB'">
          <ul>
            <li>
              <strong class="title">총 상품금액</strong>
              <div class="delivery_fee">
                <strong class="price">{{ unitPrice ? $formatPrice(unitPrice * quantity) : $formatPrice(totalProductPrice) }}</strong>
                <span class="price_txt">원</span>
              </div>
            </li>
            <li v-if="userId">
              <strong class="title">배송비</strong>
              <div class="delivery_fee" v-if="deliveryFee > 0">
                <strong class="price">
                  <span class="cash_delivery" v-if="deliveryFeeMethod && deliveryFeeMethod === 'CASH_DELIVERY'">착불</span>
                  <!-- && !deliveryBundleId -->
                  {{ $formatPrice(deliveryFee) }}
                </strong>
                <span class="price_txt">원</span>
              </div>
              <div class="delivery_fee" v-else-if="deliveryFee === null && deliveryFeeMethod === 'CASH_DELIVERY'">
                <strong class="free">착불</strong>
              </div>
              <div class="delivery_fee" v-else-if="deliveryFee === 0"><strong class="free">무료배송</strong></div>
              <!-- 단일상품 안내문구 -->
              <div class="guide_txt_wrap" v-if="guideTxt && !deliveryBundleId">
                <strong class="guide_txt">{{ guideTxt }}</strong>
              </div>
            </li>

            <li class="last_list" v-if="lastList && userId">
              <strong class="title">묶음배송비</strong>
              <div class="delivery_fee" v-if="bundleDeliveryFee > 0">
                <strong class="cash_delivery" v-if="deliveryFeeMethod && deliveryFeeMethod === 'CASH_DELIVERY'">착불</strong>
                <!--<span class="gray">위 {{ bundleTotalCount }}건 함께 주문 시</span>-->
                <strong class="price ml_5"> {{ $formatPrice(bundleDeliveryFee) }} </strong><span class="price_txt">원</span>
              </div>
              <div class="delivery_fee" v-else-if="!bundleDeliveryFee">
                <!--<span class="gray">위 {{ bundleTotalCount }}건 함께 주문 시</span> -->
                <strong class="ml_20 free">무료배송</strong>
              </div>
              <!-- 묶음상품 안내문구 -->
              <div class="guide_txt_wrap" v-if="guideTxt && deliveryBundleId && lastList && userId">
                <strong class="guide_txt">{{ guideTxt }}</strong>
              </div>
            </li>
          </ul>
        </div>

        <template v-if="placeholder">
          <div class="relative input_area">
            <input
              class="request_msg_wrap"
              :placeholder="placeholder"
              :data-product-id="productId"
              :value="orderRequest"
              @focus="focusProductRequest"
              @input="inputProductRequest($event)"
            />
            <div class="speech_bubble_wrap" v-if="keyIndex == 'key_0' && !orderRequest">
              <div class="speech_bubble" v-if="deliveryMethod === 'TB'">상품관련 요청사항이 있으신가요? (예: 보내는 분, 받는 분)</div>
              <div class="speech_bubble" v-else>상품관련 요청사항이 있으신가요?</div>
            </div>
          </div>
        </template>
        <template v-else-if="requestInput && orderRequest && viewMode == 'info'">
          <div class="request_msg_wrap">{{ orderRequest }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/new/Btn";
import { mapState } from "vuex";

export default {
  name: "ProductList",
  props: {
    userId: String,
    viewMode: {
      type: String,
      default: "default",
    },
    // market: Object,
    // key: String,
    keyIndex: String,
    compoundKey: String,
    marketName: String,

    products: Array,
    productIndex: Number,
    deliveryBundleId: Number,
    deliveryFee: Number,
    deliveryFeeMethod: String,
    bundleCount: Number,
    bundleTotalCount: Number,
    bundleDeliveryFee: Number,
    guideTxt: String,
    lastList: Boolean,
    bundleIndex: Number,
    shippingType: String, // packing = 포장, weight = 무게

    deliveryMethod: String,
    atYn: String,

    marketId: Number,
    productId: Number,

    name: String,
    storeName: String,
    quantity: Number,
    price: Number,
    discountPrice: Number,
    saleYn: {
      type: String,
      default: "Y",
    },
    paymentTypes: Array,
    productImageUrl: String,
    requestInput: Boolean,
    orderRequest: String,
    unitName: String,
    unitNumber: Number,
    originName: String,
    optionGroups: Array,
    totalOptionPrice: Number,
    weight: Number,
    totalWeight: Number,
    unitPrice: Number,

    disabled: Boolean,
    disabledType: String,
    marketOpenYn: String,
    storeOpenYn: String,
    productSaleYn: String,
    isLink: {
      type: Boolean,
      default: true,
    },
    placeholder: null,

    totalPrice: Number,
    inputProductRequest: Function,
    focusProductRequest: Function,
  },
  data: function () {
    return {
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      memberId: this.userId,
      nonghalText: "농할",
      // isVisible: true
    };
  },
  methods: {
    moveToProduct: function (event) {
      if (this.isLink) {
        let productId = event.currentTarget.dataset.productId;
        // console.log(productId);
        // if (productId === '101992') {// 101992
        //   productId = '102548';// 춘천중앙시장 절임배추 예외처리 // 102548
        // }
        // else if (productId === '102855') {
        //   productId = '102856';
        // }
        //
        // console.log(productId);
        // debugger;

        this.$router.push({
          name: "상품 메인",
          params: {
            productId: productId,
          },
        });
      } else {
        return false;
      }
      // console.log(event.target.classList);
      // console.log(event.target.classList.contains('store_name'));
      // console.log(event.target.dataset);
      // if (event.target.classList.contains('store_name')) {// 상점 메인으로 이동
      //   this.$router.push({
      //     name: '상점 메인',
      //     params: {
      //       marketId: event.currentTarget.dataset.marketId,
      //       storeId: event.currentTarget.dataset.storeId,
      //       storeName: event.currentTarget.dataset.storeName,
      //       regularYn: event.currentTarget.dataset.regularYn
      //     }
      //   });
      // }
      // else {// 상품 메인으로 이동
      //   this.$router.push({
      //     name: '상품 메인',
      //     params: {
      //       productId: event.currentTarget.dataset.productId
      //     }
      //   });
      // }
    },
  },
  components: {
    Btn,
  },
  computed: {
    ...mapState({
      sssMarkets: (state) => state.sssMarkets, // 서울시특판 시장 아이디
      hideAtLabelProducts: (state) => state.hideAtLabelProducts, // 농할 할인은 적용되지만 농할 라벨 보이지 않게 처리
    }),
    isDefault: function () {
      return this.viewMode == "default";
    },
    isInfo: function () {
      return this.viewMode == "info";
    },
    isHistory: function () {
      return this.viewMode == "history";
    },
    isDisabled: function () {
      // console.log("isDisabled ::: ");
      return this.productSaleYn == "Y" ? false : true;
    },
    totalProductPrice: function () {
      var returnValue = this.discountPrice ? this.discountPrice * this.quantity : this.price * this.quantity;
      if (this.totalOptionPrice) {
        returnValue = this.discountPrice
          ? (this.discountPrice + this.totalOptionPrice) * this.quantity
          : (this.price + this.totalOptionPrice) * this.quantity;
      }
      return returnValue;
    },
    // finalPrice: function () {
    //   let optionPrice = 0;
    //
    //   if (this.optionGroups) {
    //     this.optionGroups.forEach(optionGroup => {
    //       optionGroup.options.forEach(option => {
    //         optionPrice += option.optionPrice;
    //       });
    //     });
    //   }
    //
    //   const price = (this.discountPrice ? this.discountPrice : this.price) + optionPrice;
    //
    //   return price;
    // }
  },
  watch: {
    userId: function () {
      // console.log("watch userId ::: " + this.userId);
      this.memberId = this.userId;
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery_group_fee {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border: {
    style: solid;
    color: #dcdcdc;
    width: 1px 0 0;
  }
  ul {
    li {
      position: relative;
      min-height: 30px;
      //&.guide_txt_wrap {
      //  min-height: 36px;
      //  padding: 7px 10px 0;
      //  margin: 15px 0 0;
      //  background-color: #ffb64b;
      //  @include border-radius(18px);
      //  text-align: center;
      //}
      .guide_txt_wrap {
        min-height: 36px;
        padding: 0 10px 0 20px;
        background-image: url("~svg/icon_reply.svg");
        background-position: 7px 2px;
        background-repeat: no-repeat;
        color: $gray-light;
      }
    }
  }
  .title {
    font-size: 14px;
    //color: $gray;
  }
  .delivery_fee {
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 14px;
    .price {
      display: inline-block;
      font-size: 18px;
      font-family: gothic_a1_regular;
      vertical-align: middle;
    }
    .free {
      font-size: 15px;
    }
    .price_txt {
      display: inline-block;
      line-height: 1.5;
      margin-left: 2px;
      vertical-align: middle;
    }
  }
  .cash_delivery {
    display: inline-block;
    padding: 1px 7px 0px;
    margin: -3px 5px 0;
    //margin: 0 5px;
    background-color: $active-color;
    @include border-radius(6px);
    @include font(13, 20);
    color: white;
    vertical-align: middle;
  }
  .gray {
    color: $gray;
    font-size: 13px;
    letter-spacing: -0.5px;
  }
  .guide_txt {
    display: block;
    font-size: 13px;
    color: $gray;
  }
  .last_list {
    margin: 15px 0 0;
    .title {
      //font-size: 16px;
      color: $active-color;
    }
    .delivery_fee {
      top: -3px;
    }
  }
}
.delivery_info {
  position: relative;
  margin: 15px 0 0;
  .title {
    font-size: 14px;
  }
  .delivery_fee {
    position: absolute;
    top: 0;
    right: 0;
  }
  .gray {
    color: $gray;
    font-size: 13px;
  }
}
.delivery_title {
  .title {
    font-size: 15px;
  }
  .accent {
    color: $active-color;
  }
}
.input_area {
  position: relative;
  padding: 0 0 20px;

  .speech_bubble {
    position: relative;
    //margin: 50px;
    //width: 400px;
    height: 30px;
    padding: 6px 18px 2px;
    background-color: $active-background-color;
    border-radius: 20px;
    @include font(13, 18, 0);
    color: #fff;
  }
  .speech_bubble:after {
    border-top: 0px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $active-background-color;
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
  }

  .speech_bubble_wrap {
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: 99;
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    bottom: 30px;
  }
  70% {
    opacity: 0;
    bottom: 30px;
  }
  //95% {
  //  bottom: 0px;
  //}
  100% {
    opacity: 1;
    bottom: -5px;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  0% {
    opacity: 0;
    bottom: 30px;
  }
  70% {
    opacity: 0;
    bottom: 30px;
  }
  92% {
    bottom: -8px;
  }
  100% {
    opacity: 1;
    bottom: -5px;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  0% {
    opacity: 0;
    bottom: 30px;
  }
  70% {
    opacity: 0;
    bottom: 30px;
  }
  92% {
    bottom: -8px;
  }
  100% {
    opacity: 1;
    bottom: -5px;
  }
}
@-o-keyframes fadein {
  /* Opera */
  0% {
    opacity: 0;
    bottom: 30px;
  }
  70% {
    opacity: 0;
    bottom: 30px;
  }
  92% {
    bottom: -8px;
  }
  100% {
    opacity: 1;
    bottom: -5px;
  }
}

.cart_product_container {
  position: relative;
  //padding: 0 16px;
  &.bundle_product {
    background-color: $background-color-type2;
    .product_wrap {
      padding: 20px 10px;
      background-color: $background-color-type2;
    }
  }
}
.product_wrap {
  position: relative;
  min-height: 204px;
  padding: 20px 0 20px;
  background-color: $white;
  border: {
    style: solid;
    color: $border-type1;
    //color: $gray-light;
    width: 0 0 1px;
  }

  &.view_info {
    // 결제완료 페이지 적용
    //min-height: 178px;
    position: relative;
    min-height: unset;
    padding: 20px 0 10px;

    &.type1 {
      padding: 20px 0 0;
    }

    .product_detail {
      padding-top: 0;

      .market_name {
        font-weight: normal;
      }

      //.product_name {
      //  .unit {
      //margin: 0;
      //@include font(16, 24, -0.96, bold);
      //color: $black;
      //  }
      //}

      .product_bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 10px 0 0;
        padding: 15px 0;
        min-height: unset;

        .order_count {
          margin: 0;
          padding: 0;

          .txt {
            @include font(14, 18, -0.5, bold);
            display: inline-block;
          }
        }

        .price_container {
          position: initial !important;
          display: flex;
          flex-direction: column;
          margin: 0;

          > .discount {
            min-height: unset;
            margin: 0 0 5px 0;

            .price_wrap {
              height: auto;
              .price {
                display: block;
                font-family: gothic_a1_regular;
                font-size: 12px;
                line-height: 12px;
              }
            }
          }

          > .price_wrap {
            display: flex;
            align-items: center;

            .price {
              font-family: gothic_a1_bold;
              font-size: 16px;
              line-height: 16px;
            }
            .price_txt {
              @include font(15, 15);
              margin: 0;
            }
          }
        }
      }
    }

    //.product_detail {
    //  margin: {
    //    top: 0;
    //  }
    //}
  }

  &.view_history {
    // 주문 내역 페이지 적용
    //min-height: 120px;
    padding: 20px 0 10px;

    //.product_detail {
    //  margin: {
    //    top: 0;
    //  }
    //}
  }

  &.view_detail {
    min-height: unset;

    .product_detail {
      padding: 0;
      min-height: unset;

      .store_name_wrap {
        display: flex;
        align-items: center;

        .store_name {
          //line-height: 1;
          color: $gray !important;
        }

        .icon_more_arrow {
          margin: 0 0 0 10px;
        }
      }

      .product_name {
        margin: 0;
        .unit {
          margin: 0;
          color: $black;
        }
      }

      .origin_name {
        margin: 8px 0 0;
        @include font(12, 16, -0.72);
      }

      .opt_list {
        margin: 0;

        > li {
          @include font(12, 16, -0.48);
          span {
            display: inline-block;
            @include font(12, 16, -0.48);
          }
        }
      }

      .product_bottom {
        display: inherit;
        position: initial;
        min-height: unset;
        margin: 0;
        border: 0;

        .order_count {
          padding: 0;
          margin: 0;
          @include font(12, 16, -0.48);
          font-weight: normal;
          color: $gray-light;
          .txt {
            display: inline-block;
            @include font(12, 16, -0.48);
          }
        }

        .price_container {
          position: inherit;
          margin: 8px 0 0 0;
          text-align: left;

          .price_wrap {
            display: flex;
            align-items: center;

            .price {
              font-family: gothic_a1_extrabold;
              font-size: 16px;
              line-height: 16px;
              //font-width: bold;
            }
            .price_txt {
              margin: 0;
              @include font(15, 15);
            }
          }
        }

        .request_msg_wrap {
          margin: 20px 0 0 0;
        }
      }
    }
  }
}
.no_product_wrap {
  position: absolute;
  top: 0;
  right: -16px;
  left: -16px;
  //width: 100%;
  height: 100%;
  //@include border-radius(20px);
  background: rgba(6, 6, 6, 0.35);
  text-align: center;
  z-index: 1;

  .no_product_msg {
    position: absolute;
    bottom: 20px;
    //transform: translateY(-50%);
    width: 100%;
    padding: 0 20px;
    text-align: center;
    .msg {
      display: inline-block;
      width: 100%;
      max-width: 320px;
      min-width: 240px;
      min-height: 36px;
      padding: 10px;
      background-color: $background-color-type1;
      @include border-radius(20px);
      @include font(13, 18, -1);
      color: $gray;
    }
  }
  .absolute_right {
    top: 18px;
    right: 16px;
  }
}

//.product_top {
//}
.absolute_right_txt {
  position: absolute;
  top: 2px;
  right: 0;
  @include font(14, 18, 0);
  color: #999;
}

.store_name_wrap {
  position: relative;
  padding: 0 50px 0 0;
  @include font(13, 18, 0, bold);
  &.type1 {
    padding: 0;
  }
  .absolute_right {
    top: -5px;
  }
  a.type6 {
    font-weight: 100;
  }
}
.store_name {
  line-height: 18px;
  color: $active-color !important;
  .market_name {
    color: $gray-light;
  }
}

.name_repeat {
  font-size: 16px;
  color: $nj_text_main_gray;
}

.product_detail {
  position: relative;
  min-height: 80px;
  padding: {
    left: 80px;
    top: 3px;
  }
  //margin: {
  //  top: 10px;
  //}
}

@media (min-width: 350px) {
  .product_detail {
    min-height: 86px;
  }
}
.thumb_wrap {
  position: absolute;
  top: 0;
  left: 0;
  .thumb {
    width: 70px;
    height: 70px;
    //@include border-radius(20px);
    object-fit: cover;
    &.no_img {
      //max-height: 80px;
      background-size: 28px;
      color: #000;
    }
  }
}
.product_name {
  position: relative;
  //margin: 5px 0 0;
  @include font(16, 24, 0, bold);
  color: $black;
  // @include text_ellipsis(2, 44px);
  &.shipping_type {
    padding: 0 60px 0 0;
  }
}
.chip_list {
  li {
    display: inline-block;
    height: 16px;
    padding: 0px 10px;
    margin-right: 2px;
    border: 1px solid #e3e3e3;
    @include border-radius(20px);
    @include font(10, 14, -0.6);
    color: $nj_text_main_gray;
    text-align: center;
  }
}

.payment_list_wrap {
  padding: 0 0 8px;
  //border: {
  //  style: solid;
  //  color: $border-type1;
  //  width: 0 0 1px;
  //}
}

.quantity_wrap {
  margin-top: 20px;
  height: 40px;

  & > * {
    display: inline-block;
    vertical-align: top;
  }

  .quantity_btn {
    width: 46px;
    height: 40px;
    font-size: 28px;
    line-height: 28px;
    text-align: center;

    &.btn_minus {
      background: url("~svg/btn_minus.svg");
    }

    &.btn_plus {
      background: url("~svg/btn_plus.svg");
    }
  }

  .quantity_txt {
    height: 100%;
    padding: 6px 10px;
    @include font(22, 27, -1.32, medium);
    color: #262626;
    //font-size: 22px;
    //line-height: 30px;
  }
}

.product_bottom {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 0;
  border: {
    style: solid;
    color: $border-type2;
    width: 1px 0 0;
  }
  vertical-align: top;

  &.type1 {
    // 장바구니가 아닐때
    .order_count {
      //min-height: 50px;
      padding: 10px 0 10px;
      margin: 10px 0 0;
      @include font(14, 18, -0.5, bold);
      &.type1 {
        padding: 25px 0 10px;
      }
      .txt {
        display: none;
      }
    }

    .price_container {
      position: absolute;
      top: auto;
      bottom: 3px;
      right: 0;
      margin: 0;
      text-align: right;
    }
  }

  .price_container {
    margin: 10px 0 0;
  }

  @media (min-width: 350px) {
    .price_container {
      position: absolute;
      top: -3px;
      right: 0;
      margin: 20px 0 0;
      text-align: right;
    }
  }

  .discount {
    min-height: 16px;
    .price_wrap {
      &.original_name {
        //height: 22px;
        height: 16px;
        .price {
          margin-right: -1px;
          //@include font(18, 27, -1.08);
          font-family: "gothic_a1_regular";
          font-size: 13px;
          font-weight: 100;
          color: $gray-light;
        }
        //.price_txt {
        //  @include font(18, 27, -1.08);
        //  color: $nj_text_main_gray
        //}
      }
      .price {
        color: $gray-light;
        text-decoration: line-through;
      }
    }
  }

  .price_wrap {
    //height: 36px;

    & > * {
      display: inline-block;
    }

    .price {
      //@include font(24, 36, -1.44);
      //color: $nj_black;
      font-family: "gothic_a1_regular";
      font-size: 18px;
      font-weight: 600;
      color: $black;
    }
    .price_txt {
      margin-left: 2px;
      @include font(14, 24, -0.84);
      color: $nj_text_main_gray;
    }
  }
}

.request_msg_wrap {
  width: 100%;
  padding: 12px 15px 12px 15px;
  border: {
    width: 1px;
    style: solid;
    color: $border-type2;
  }
  @include border-radius(10px);
  @include font(14, 18, -0.5);
}

.unit {
  margin-left: 10px;
  @include font(16, 24, -0.96);
  color: $nj_text_sub_gray;
}
.origin_name {
  margin: 3px 0 0;
  @include font(12, 20, -0.48);
  color: $gray-light;
}
.opt_list {
  line-height: 14px;
  margin: 8px 0;
  > li {
    //display: inline;
    @include font(13, 14, -0.72);
    color: $gray-light;
  }
}

.delete_product {
  @include font(16, 24, -0.96);
  color: $nj_red !important;
}

.delivery_type_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 4px;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3px 6px 0 0;
    padding: 3px 4px 2px;
    background: gray;
    @include border-radius(3px);
    @include font(12, 16);
    font-weight: 100;
    color: $white;

    &.pkg {
      background-color: #67a3ff;
    }

    &.fd {
      background-color: #ffa033;
    }

    &.tb {
      background-color: white;
      border: 1px solid #707070;
      color: #707070;
    }

    &.at {
      //margin-left: auto;
      padding: 3px 4px 2px;
      border: {
        style: solid;
        width: 1px;
        color: #ff1d48;
        radius: 3px;
        //offset: -1px;
      }
      color: #ff1d48;
      background-color: $white;
      @include font(12, 16);
    }

    &.fc {
      background-color: white;
      border: 1px solid #707070;
      color: #707070;
    }
  }
}

.ios {
  .delivery_type_list {
    > li {
      &.at {
        padding: 1px 4px 1px;
      }
    }
  }
}
</style>
