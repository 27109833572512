import Modal from "@/components/new/Modal";
import router from "@/router";

export default {
  install(Vue) {
    (Vue.prototype.$parseCart = function (cart) {
      let cartArr = [];
      cart.forEach((market, index) => {
        cartArr[index] = {};
        cartArr[index].marketId = market.marketId;
        cartArr[index].products = [];
        market.products.forEach((product) => {
          let parseData = {};
          parseData.compoundKey = product.compoundKey;
          parseData.quantity = product.quantity;
          parseData.productId = product.compoundKey.split("!")[0];
          parseData.option = [];

          if (product.compoundKey.split("!")[1]) {
            product.compoundKey
              .split("!")[1]
              .split("#")
              .forEach((item) => {
                const optionGroupId = item.split("@")[0];
                const optionIdArr = item.split("@")[1].split(",");
                optionIdArr.forEach((item, index) => {
                  optionIdArr[index] = parseInt(item);
                });
                parseData.option[optionGroupId] = optionIdArr;
              });
          }
          cartArr[index].products.push(parseData);
        });
      });
      return cartArr;
    }),
      (Vue.prototype.$getProductIdArr = function (products) {
        let productIdArr = [];
        products.forEach((item) => {
          productIdArr.push(item.productId);
        });

        return productIdArr;
      }),
      (Vue.prototype.$getTelNumber = function () {
        return "02.2039.6742";
      });

    // number 값이 한자리수일때 0과 함께 전달한다. number = 3 ===> return '03'
    Vue.prototype.$formatZeroNumber = function (number = 3) {
      return String(number < 10 ? "0" + number : number);
    };
    // 판매가격, 할인가격으로 할인 퍼센트 값 전달
    Vue.prototype.$formatDiscountPercent = function (discountPrice, regularPrice) {
      const discount = regularPrice - discountPrice;
      const percentage = (discount * 100) / regularPrice;
      return Math.ceil(percentage) + "%";
    };
    // 전화번호 패턴 000.0000.0000 으로 변경
    Vue.prototype.$formatTelNumber = function (num, type) {
      if (typeof num != String) {
        num = String(num);
      }
      var formatNum = "";
      if (num.length == 11) {
        if (type == 0) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1.****.$3");
        } else {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1.$2.$3");
        }
      } else if (num.length == 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, "$1.$2");
      } else {
        if (num.indexOf("02") == 0) {
          if (type == 0) {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1.****.$3");
          } else {
            formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1.$2.$3");
          }
        } else {
          if (type == 0) {
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1.***.$3");
          } else {
            formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3");
          }
        }
      }
      return formatNum;
    };
    Vue.prototype.$formatWeight = function (weight) {
      if (!weight) {
        weight = 0;
      }
      if (typeof weight !== "number") {
        weight = Number(weight);
      }
      var kgBoolean = false;
      if (weight > 999) {
        kgBoolean = true;
      }
      return kgBoolean ? String(weight / 1000) + "kg" : weight.toLocaleString("ko-KR") + "g";
    };
    Vue.prototype.$formatPrice = function (price) {
      if (!price) {
        price = 0;
      }

      if (typeof price != "number") {
        price = Number(price);
      }
      return price.toLocaleString("ko-KR");
    };
    Vue.prototype.$objToQueryString = function (obj) {
      return Object.entries(obj)
        .map((e) => e.join("="))
        .join("&");
    };

    /**
     * 실행환경이 실 운영 환경인지 체크한다
     * @returns {boolean} 실 운영 환경인 경우 true, 그렇지 않은 경우 false
     */
    Vue.prototype.$isProd = function () {
      return process.env.NODE_ENV == "production";
    };

    /**
     * 실행환경이 안드로이드 환경인지 체크한다
     * @returns {boolean} 안드로이드인 경우 true, 그렇지 않은 경우 false
     */
    Vue.prototype.$isAndroid = function () {
      return navigator.userAgent.match(/Android/i) == null ? false : true;
    };

    /**
     * 실행환경이 iOS 환경인지 체크한다
     * @returns {boolean} iOS인 경우 true, 그렇지 않은 경우 false
     */
    Vue.prototype.$isIos = function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
    };

    /**
     * 네이티브의 closeView (웹뷰 종료) 연동 함수를 호출한다.
     */
    Vue.prototype.$closeView = function () {
      this.$callNative("closeView", null);
    };

    /**
     * 네이티브의 navigateTo 연동 함수를 호출한다.
     * @param {string} path 호출할 화면 경로
     * @param {object} params 쿼리스트링으로 보낼 데이터 오브젝트
     */
    Vue.prototype.$navigateTo = function (path = null, params = null) {
      if (!path) {
        return false;
      }

      if (!path.startsWith("/")) {
        path = "/" + path;
      }

      if (params) {
        path += "?" + this.$objToQueryString(params);
      }
      // console.log(path);
      this.$callNative("navigateTo", { path: path });
    };

    /**
     * 네이티브의 navigateBack 연동 함수를 호출하고 이전 화면으로 이동한다.
     */
    Vue.prototype.$navigateBack = function () {
      this.$callNative("navigateBack");
      //router.back();
      //router.go(-1);
      //const history = window.location.href;
      setTimeout(function () {
        //if (history === window.location.href){
        // router.push({ name: "놀장 메인" });
        router.go(-1);
        //}
      }, 200);
    };
    /**
     * 네이티브의 연동 함수를 호출한다.
     * @param {string} path 호출할 연동함수 이름
     * @param {object} payload 보낼 데이터 오브젝트
     * @param {boolean} isCommon iOS의 경우, 공통 연동함수인 경우 true, 화면별 연동함수인 경우 false
     */
    Vue.prototype.$callNative = function (name, payload, isCommon = true) {
      if (!payload) {
        payload = {};
      }

      const handler = (isCommon ? "common" : "page") + "Handler";
      payload.name = name;

      // 안드로이드
      if (this.$isAndroid()) {
        payload = Object.keys(payload).length ? JSON.stringify(payload) : null;
        if (window[handler]) {
          if (Object.keys(payload).length) {
            window[handler].postMessage(payload);
          } else {
            window[handler].postMessage();
          }
        } else {
          // console.log(name);
          if (Object.keys(payload).length) {
            // console.log(payload);
          }
        }
      }
      // iOS
      else if (this.$isIos()) {
        if (window.webkit) {
          window.webkit.messageHandlers[handler].postMessage(payload);
        }
      } else {
        // console.log("Call Method Name: " + name);
        payload.name = name;
        // console.log("Android Parameter: " + JSON.stringify(payload));
        // console.log("iOS Parameter: " + JSON.stringify(payload));
      }
    };

    /**
     * 로컬 스토리지에 내가 본 상품 데이터 정보 저장
     * @param {number} marketId 현재 조회 중인 상품의 상점 ID
     * @param {number} productId 현재 조회 중인 상품의 I$getRecentProductD
     */
    Vue.prototype.$setRecentProduct = function (marketId, productId) {
      let recentProducts = this.$getRecentProduct(marketId, productId);
      if (recentProducts.length > 10) {
        // 내가 본 상품 10개만 저장하도록 수정
        recentProducts = recentProducts.slice(recentProducts.length - 10, recentProducts.length - 1);
      }
      recentProducts.push({ productId: parseInt(productId) });
      localStorage.setItem("product_recent_" + marketId, JSON.stringify(recentProducts));
    };

    /**
     * 로컬 스토리지에서 내가 본 상품 데이터 정보 조회
     * @param {number} marketId 현재 조회 중인 상품의 상점 ID
     * @param {number} productId 현재 조회 중인 상품의 ID
     * @returns {Array} 현재 상점 내의 내가 본 상품 데이터 중 현재 조회 중인 상품을 제외한 데이터 반환
     */
    Vue.prototype.$getRecentProduct = function (marketId, productId) {
      let recentProducts = localStorage.getItem("product_recent_" + marketId);
      if (!recentProducts) {
        recentProducts = [];
      } else {
        recentProducts = JSON.parse(recentProducts);
        recentProducts = recentProducts.filter((item) => {
          return item.productId != productId;
        });
      }

      return recentProducts;
    };

    /**
     * 토스트를 출력하는 연동 함수를 호출한다.
     * @param {string} msg 출력할 메시지
     */
    Vue.prototype.$showToast = function (msg) {
      this.$callNative("showToast", { message: msg });
    };
    /**
     * 공통 Alert
     * @param {string} title 표시할 타이틀
     * @param {Array} msgArr 표시할 메시지 배열
     * @returns {Promise} 모달 Close 시 resolve 처리되는 Promise
     */
    Vue.prototype.$alert = function (title, msgArr) {
      return new Promise((resolve) => {
        const compProps = {
          title: title,
          msgArr: msgArr,
          btnArr: [
            {
              txt: "확인",
              type: "type1 inverse",
              btnCallback: () => {
                this.$modal.hideAll();
                resolve();
              },
            },
          ],
          closeModal: function () {
            this.$modal.hideAll();
            resolve();
          },
        };

        const modal = this.$modal;
        modal.show(Modal, compProps, {
          width: "90%",
          maxWidth: 400,
          height: "auto",
          adaptive: true,
        });
      });
    };

    /**
     * 공통 Confirm
     * @param {string} title 표시할 타이틀
     * @param {Array<String>} msgArr 표시할 메시지 배열
     * @param {Array<{txt: String, type: String, data: any}>} btnArr 표시할 버튼 배열
     * @returns {Promise} 모달 Close 시 resolve 처리되는 Promise, 확인 버튼을 누른 경우 true 반환
     */
    Vue.prototype.$confirm = function (title, msgArr, btnArr = null) {
      return new Promise((resolve) => {
        const callback = (result) => {
          this.$modal.hideAll();
          resolve(result);
        };

        if (!btnArr) {
          btnArr = [
            {
              txt: "닫기",
              data: false,
            },
            {
              txt: "확인",
              type: "type1 inverse",
              data: true,
            },
          ];
        }
        btnArr.forEach((btn) => {
          btn.btnCallback = () => {
            return callback(btn.data || false);
          };
        });

        const compProps = {
          title: title,
          msgArr: msgArr,
          btnArr: btnArr,
          closeModal: function () {
            callback(false);
          },
        };

        const modal = this.$modal;
        modal.show(Modal, compProps, {
          width: "90%",
          maxWidth: 400,
          height: "auto",
          adaptive: true,
        });
      });
    };

    /**
     * 공통 Select Modal
     * @param {Object} selectData {text:string, items:Array} 형태의 오브젝트
     * @returns {Promise} 모달 Close 시, 아이템 선택 시 resolve 처리되는 Promise, 확인 버튼을 누른 경우 true 반환
     */
    Vue.prototype.$select = function (selectData) {
      return new Promise((resolve) => {
        const compProps = {
          title: selectData.text + " 선택",
          type: "select",
          items: selectData.items,
          selectedItem: selectData.selectedItem,
          closeModal: function (e) {
            this.$modal.hideAll();
            resolve(e);
          },
        };

        const modal = this.$modal;
        modal.show(Modal, compProps, {
          width: "90%",
          maxWidth: 400,
          height: "auto",
          adaptive: true,
        });
      });
    };
  },
};
