<template>
  <a
    href="javascript:void(0)"
    :class="type ? type : 'type1'"
    :title="title ? title : value"
    @click="
      $emit('btnCallback', {
        btnId: btnId,
        data: data ? data : null,
        link: link ? link : null,
        event: $event,
      })
    "
    >{{ value }}</a
  >
</template>

<script>
export default {
  name: "Btn",
  props: {
    type: String,
    value: String,
    btnId: String,
    link: null,
    data: null,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
$type1-active-color: #ff1d48;
$black-active-color: #686868;

.type1 {
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: white;
  border: {
    style: solid;
    color: $type1-active-color;
    width: 1px;
  }
  @include font(20, 29, -1.2);
  @include border-radius(20px);
  color: $type1-active-color !important;
  text-align: center;
  line-height: 2.8;
  vertical-align: middle;
  &.reverse {
    background-color: $type1-active-color;
    color: white !important;
  }
  &.none_border {
    border-width: 0;
  }
}
.type2 {
  // 점점점 세로 버튼
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../assets/images/icon_dot_vertical.svg");
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -99999px;
  vertical-align: middle;
}
.type3 {
  display: inline-block;
  padding: 0 30px 0 0;
  background-image: url("../assets/images/arrow_bottom.png");
  background-repeat: no-repeat;
  background-position: right 9px;
  color: $nj_black !important;
  // font: normal normal medium 18px/2 Noto Sans CJK KR;
  @include font(16);
  letter-spacing: -1.08px;
  vertical-align: middle;
  &:hover {
    color: $nj_black !important;
  }
}
.type4 {
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: white;
  border: {
    style: solid;
    color: $nj_text_sub_gray;
    width: 1px;
  }
  @include font(20, 29, -1.2);
  @include border-radius(20px);
  color: $nj_text_sub_gray !important;
  text-align: center;
  line-height: 2.8;
  vertical-align: middle;
  &.reverse {
    background-color: $active-color;
    color: white !important;
  }
}
.map {
  display: inline-block;
  width: 26px;
  height: 22px;
  background-image: url("~image/icon_map.svg");
  background-repeat: no-repeat;
  background-position: center 0;
  text-indent: -99999px;
  vertical-align: middle;
}

.black {
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: transparent;
  border: {
    style: solid;
    color: $black-active-color;
    width: 1px;
  }
  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -1.2px;
  @include border-radius(20px);
  color: $black-active-color !important;
  text-align: center;
  line-height: 2.8;
  &.reverse {
    background-color: $black-active-color;
    color: white !important;
  }
}

.inactive {
  display: inline-block;
  width: 100%;
  height: 60px;
  background-color: $nj_divider_gray;
  border: {
    style: solid;
    color: $nj_divider_gray;
    width: 1px;
  }
  @include font(20, 29, -1.2);
  @include border-radius(20px);
  color: $white !important;
  text-align: center;
  line-height: 2.8;
}

.slide_check {
  display: inline-block;
  width: 54px;
  height: 30px;
  background-image: url("~image/slide_check.svg");
  background-repeat: no-repeat;
  background-position: center 0;
  text-indent: -9999px;
  &.active {
    background-image: url("~image/slide_check_active.svg");
  }
}
.search_red {
  display: inline-block;
  width: 20px;
  height: 21px;
  background-image: url("~image/icon_search.png");
  background-repeat: no-repeat;
  background-position: 0;
  text-indent: -9999px;
}
</style>
