<template>
  <div class="reload_scroll_wrap">
    <div class="reload_wrap">
      <div class="reload_icon_wrap">
        <div class="reload_icon" v-if="!isReloading" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="25 25 50 50" v-else>
          <circle
            cx="50"
            cy="50"
            r="16"
            fill="none"
            stroke-width="5"
            stroke="#262626"
            stroke-linecap="square"
            stroke-dashoffset="0"
            stroke-dasharray="100, 200"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="2.5s"
              repeatCount="indefinite"
            />
            <animate attributeName="stroke-dashoffset" values="0;-30;-124" dur="1.25s" repeatCount="indefinite" />
            <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="1.25s" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReloadingIndicator",
  props: {
    isReloading: {
      type: Boolean,
      default: false,
    },
    deg: {
      type: Number,
      default: 30,
    },
  },
  mounted() {
    const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
    reloadScrollWrapEl.scrollTop = 120;
  },
};
</script>

<style lang="scss" scoped>
.reload_scroll_wrap {
  position: fixed;
  z-index: 10000;
  display: none;
  top: -44px;
  left: calc(50vw - 22px);
  width: 44px;
  height: 160px;
  overflow-y: hidden;
  .reload_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .reload_icon_wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
      .reload_icon {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: opacity 0.15s;
        opacity: 0.3;
        background: {
          image: url("https://upload.wikimedia.org/wikipedia/commons/c/ce/Ic_refresh_48px.svg");
          position: center;
          repeat: no-repeat;
          size: 30px;
        }
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

@keyframes outline {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 300;
  }
  100% {
    stroke-dashoffset: 600;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(-1turn);
  }
}
</style>
