<template>
  <div>
    <TopHeader class="top_header" title="설정" @goBack="goBack" />
    <div class="contents">
      <div class="contents_area">
        <ul class="set_list">
          <li>
            <p class="title">내 정보</p>
            <ul class="detail_list">
              <li class="more_item" @click="moveToUrl('withdraw')">서비스탈퇴</li>
            </ul>
          </li>

          <li>
            <p class="title">서비스 설정</p>
            <ul class="detail_list">
              <li>
                푸시 알림
                <div class="absolute_right">
                  <Btn
                    :type="pushYn == 'Y' ? 'slide_toggle active' : 'slide_toggle'"
                    btnId="PUSH"
                    value="푸시 알림 선택/해제"
                    @btnCallback="setPush"
                  />
                </div>
              </li>
            </ul>
          </li>

          <li>
            <p class="title">약관 및 정책</p>
            <ul class="detail_list">
              <li class="more_item" @click="moveToUrl('terms')">놀장 이용약관</li>
              <li class="more_item" @click="moveToUrl('policy')">개인정보취급방침</li>
            </ul>
          </li>

          <li>
            <p class="title">버전 정보</p>
            <ul class="detail_list">
              <li>
                현재 버전
                <span class="absolute_right type1">Ver {{ thisVer }}</span>
              </li>
              <!--              <li>최신 버전 <span class="absolute_right type1">{{thisVer}}</span></li>-->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapState, mapActions } from "vuex";
import Btn from "@/components/new/Btn";

export default {
  name: "Setting",
  computed: {
    ...mapState({
      userId: (state) => state.setting.userId,
      pushYn: (state) => state.setting.pushYn,
      thisVer: (state) => state.setting.thisVer,
    }),
  },
  beforeCreate() {
    this.$store.dispatch("setting/initState", {
      userId: localStorage.getItem("userId"),
      // thisVer: this.$route.query.thisVer,
    });
  },
  props: {},
  data: function () {
    return {};
  },
  components: {
    TopHeader,
    Btn,
  },
  methods: {
    ...mapActions("setting", ["setPush"]),

    goBack: function () {
      // console.log("goBack ::::: ");
      this.$navigateBack();
    },
    onBackPressed() {
      this.goBack();
    },

    moveToUrl: function (type) {
      if (type == "withdraw") {
        this.$router.push({ name: "서비스탈퇴" });
      } else if (type == "terms") {
        // 이용약관
        this.$navigateTo("open-url", {
          title: "놀장 이용약관",
          url: "http://13.209.246.38:8080/api/term_1",
        });
      } else if (type == "policy") {
        // 취급방침
        this.$navigateTo("open-url", {
          title: "개인정보취급방침",
          url: "http://13.209.246.38:8080/api/term_2",
        });
      }
    },
    // initParams: function (data = null) {// native에서 전달해주는 데이터
    //   console.log(JSON.parse(data));
    // },
    currentAppVersion: function (data = null) {
      // native에서 전달해주는 데이터
      // console.log('currentAppVersion :::::::');
      // console.log('현재 버전 업데이트 필요함');
      // console.log('현재 버전 업데이트 필요함');
      // console.log('현재 버전 업데이트 필요함');
      // console.log(JSON.parse(data));
      if (data) {
        this.$store.dispatch("setting/setVersion", {
          version: JSON.parse(data).version,
        });
      }
    },
  },
  mounted() {
    // console.log("mounted ::: ");
    window.onBackPressed = this.onBackPressed;
    window.initParams = this.initParams;
    window.currentAppVersion = this.currentAppVersion;
  },
};
</script>

<style lang="scss" scoped>
.set_list {
  > li {
    border-style: solid;
    border-color: $border-type1;
    border-width: 1px 0 0;
    &:first-child {
      border-width: 0;
    }
    .title {
      display: block;
      height: 41px;
      border-style: solid;
      border-color: $border-type1;
      border-width: 0 0 1px;
      padding: 0 16px;
      @include font(14, 42, 0, medium);
      color: $active-color;
      background-color: $background-color-type2;
    }
  }
  .detail_list {
    padding: 0 20px;
    > li {
      position: relative;
      height: 67px;
      @include font(16, 69, 0, medium);
      color: $black;
      border-style: solid;
      border-color: $border-type1;
      border-width: 0 0 1px;

      &:last-child {
        border-width: 0;
      }
    }
    a {
      color: $black;
    }
  }
}

.absolute_right {
  position: absolute;
  top: 18px;
  right: 0;
  &.type1 {
    top: 20px;
    @include font(14, 24, 0, thin);
    color: $gray_normal;
  }
}

.more_item {
  background-image: url("~svg/icon_more_02.svg");
  background-repeat: no-repeat;
  background-position: right;
}
</style>
