<template>
  <div class="addr_item" v-bind:class="{ detail_item: isDetail }" @click="onAddrItemClick">
    <div class="jibun_addr">
      {{ shipToJibun }}
    </div>
    <div class="doro_addr_wrap">
      <div class="doro_addr_icon_wrap">
        <span>도로명</span>
      </div>
      <div class="doro_addr">{{ shipToDoro }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeliverySearchAddrItem",
  props: {
    shipToDoro: String,
    shipToJibun: String,
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    ...mapActions("deliveryAddr", ["selectSearchAddress"]),
    onAddrItemClick: function () {
      if (!this.isDetail) {
        this.selectSearchAddress({
          shipToDoro: this.shipToDoro,
          shipToJibun: this.shipToJibun,
        });
      }
    },
  },
  components: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
* {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
}

.addr_item {
  padding: 20px 0;
  border-bottom: 1px solid $nj_divider_gray;
}

.addr_item.first {
  padding-top: 0px;
}

.addr_item.detail_item {
  padding: 30px 0 0 0;
  border-bottom: none;
}

.addr_item > div {
  display: inline-block;
  width: 100%;
  line-height: 24px;
}

.jibun_addr {
  color: $nj_black;
}

.doro_addr_wrap > div {
  float: left;
}

.doro_addr_wrap > .doro_addr {
  width: calc(100% - 55px);
  color: $nj_text_main_gray;
}

.doro_addr_icon_wrap {
  font-size: 10px;
  color: #ffffff;
  margin-right: 10px;
}

.doro_addr_icon_wrap > span {
  font-size: 10px;
  color: #ffffff;
  width: 40px;
  height: 16px;
  padding: 0px 7px 1px 7px;
  background: $nj_red;
  border-radius: 10px;
  box-sizing: border-box;
  letter-spacing: -0.6px;
}
</style>
