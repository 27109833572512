<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>

    <TopHeader
      class="top_header"
      :title="storeName"
      @goBack="goBack"
      :isHomeVisible="true"
      style="position: fixed; top: 0; width: 100%; z-index: 999"
    />

    <div class="contents type2" ref="contents" style="margin: 58px 0 0">
      <div class="scroll_top" :class="{ active: showScroll }" @click="scrollTop">
        <img src="../assets/images/scroll.svg" alt="scroll button" />
      </div>

      <div class="store_header">
        <div class="store_info">
          <div class="thumb">
            <img :src="representativeImageUrl" alt="" v-if="representativeImageUrl" />
          </div>

          <div>
            <div class="market_name">{{ marketName }}</div>
            <div class="store_name">{{ storeName }}</div>
            <div class="store_explain">{{ storeIntro }}</div>

            <div class="absolute_right" v-if="Number(marketId) !== 168">
              <Btn type="type3 reverse" value="매장정보" @btnCallback="showStoreInfo" />
            </div>
          </div>
        </div>
        <div class="store_unit_info">
          <ul class="inline_list unit_type">
            <li>
              <span
                >단골고객<strong>{{ favoriteCount }}</strong></span
              >
            </li>
            <li>
              <span
                >전체상품<strong>{{ productCount }}</strong></span
              >
            </li>
            <li v-if="appVersion">
              <a href="javascript:void(0);" class="icon_regular" :class="regularYn == 'Y' ? 'active' : null" @click="setRegular">{{
                regularYn == "Y" ? "단골끊기" : "단골맺기"
              }}</a>
            </li>
            <li v-if="appVersion">
              <a href="javascript:void(0);" class="icon_call_red" @click="callStore(storeTel)">전화걸기</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="search_area">
        <SearchInput placeholder="상품명을 입력하세요" @search="search" />
      </div>

      <div class="tab_wrap storeMainTab">
        <!-- :class="scrollY? position.tab <= scrollY? 'active': null : null" :class="scrollY? (position.tab + 1) <= scrollY? 'active': null : null" -->
        <TabList :tabItems="tabItems" type="default type5" @click="clickTab" />
      </div>

      <div
        class="contents_unit top_product topProduct"
        :class="tabItems.length ? (tabItems[0].dataType == 'topProduct' ? 'first' : null) : null"
        style="padding: 20px 16px 10px 190px"
        v-if="topProduct && topProduct.productId"
      >
        <a href="javascript:void(0);" :data-product-id="topProduct.productId" @click="moveToPage(topProduct.productId)">
          <div class="thumb">
            <img :src="topProduct.productImageUrl" />
            <span class="regular_indicator best_product">추천</span>
          </div>
          <div class="top_product_detail">
            <ul class="delivery_type_list" v-if="topProduct.deliveryMethod"></ul>

            <div class="product_name">
              <ProductTitle
                :id="topProduct.storeId + '_' + topProduct.productId"
                :name="topProduct.productName"
                :saleUnit="topProduct.unitNumber + topProduct.unitName"
              />
            </div>

            <div class="discount_area" v-if="topProduct.discountPrice">
              <span class="discount_percent">{{
                topProduct.discountPrice ? $formatDiscountPercent(topProduct.price, topProduct.discountPrice) : ""
              }}</span>
              <span class="discount">{{ topProduct.discountPrice ? $formatPrice(topProduct.price) + "원" : "" }}</span>
            </div>

            <div class="price">
              {{ topProduct.discountPrice ? $formatPrice(topProduct.discountPrice) + "원" : $formatPrice(topProduct.price) + "원" }}
            </div>

            <div class="absolute_bottom">
              <div class="icon_like2 active" v-if="topProduct.productLikeCount">
                {{ topProduct.productLikeCount }}
              </div>
              <ul class="inline_list type2 icon_card">
                <li v-for="(payment, index) in topProduct.paymentTypeList" :key="'payment_' + index">
                  {{ payment.paymentName ? payment.paymentName : payment }}
                </li>
              </ul>
            </div>
          </div>
        </a>
      </div>

      <div class="contents_unit saleProduct" style="padding: 15px 0 0; margin: 0" v-if="saleProduct && saleProduct.length > 0">
        <!--      <div class="contents_unit saleProduct" :class="tabItems.length? tabItems[0].dataType == 'saleProduct'? 'first' : null : null" v-if="saleProduct && saleProduct.length > 0">-->
        <!--        <div class="contents_title type1">
          <div class="title">세일상품</div>
        </div>-->
        <div class="product_list type1 vertical" v-if="saleProduct && saleProduct.length > 0">
          <ProductList :products="saleProduct" noneStoreName="true" />
        </div>
      </div>

      <div
        class="contents_unit newProduct"
        :class="tabItems.length ? (tabItems[0].dataType == 'newProduct' ? 'first' : null) : null"
        v-if="newProduct && newProduct.length > 0"
      >
        <div class="contents_title type1">
          <div class="title">새로 들어왔어요</div>
        </div>
        <div class="product_list type1 vertical" v-if="newProduct && newProduct.length > 0">
          <ProductList :products="newProduct" noneStoreName="true" />
        </div>
      </div>

      <div
        class="contents_unit allProduct"
        :class="tabItems.length ? (tabItems[0].dataType == 'allProduct' ? 'first' : null) : null"
        v-if="allProduct && allProduct.length > 0"
      >
        <div class="contents_title type1">
          <div class="title">전체상품</div>
        </div>
        <div class="tab_wrap none_border allProductTab" :class="{ vertical_scroll: !appVersion }" v-if="category.length > 0">
          <TabList :tabItems="category" type="type1 reverse" @click="clickCategory" />
        </div>
        <div class="product_list type1 vertical" v-if="allProduct && allProduct.length > 0">
          <ProductList :products="allProduct" noneStoreName="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

import LoadingIndicator from "@/components/new/LoadingIndicator";
import TopHeader from "@/components/new/TopHeader";
import SearchInput from "@/components/new/SearchInput";
import TabList from "@/components/new/TabList";

import ProductTitle from "@/components/new/ProductTitle";
import ProductList from "@/components/new/ProductList";

import Btn from "@/components/new/Btn";

export default {
  name: "Store",
  props: {},
  data: function () {
    return {
      showScroll: false,
      lastScroll: 0,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.userId,
      isProgressing: (state) => state.store.isProgressing,
      marketId: (state) => state.store.marketId,
      marketName: (state) => state.store.marketName,
      storeId: (state) => state.store.storeId,
      storeName: (state) => state.store.storeName,
      representativeImageUrl: (state) => state.store.representativeImageUrl,
      tabItems: (state) => state.store.tabItems,
      appVersion: (state) => state.appVersion,

      regularYn: (state) => state.store.regularYn,
      favoriteCount: (state) => state.store.favoriteCount,
      productCount: (state) => state.store.productCount,
      storeIntro: (state) => state.store.storeIntro,
      storeTel: (state) => state.store.storeTel,

      topProduct: (state) => state.store.topProduct,
      saleProduct: (state) => state.store.saleProduct,
      newProduct: (state) => state.store.newProduct,
      allProduct: (state) => state.store.allProduct,
      category: (state) => state.store.category,

      contents: (state) => state.store.contents,
      position: (state) => state.store.position,
      scrollY: (state) => state.store.scrollY,
    }),
  },
  methods: {
    ...mapMutations("store", [
      "goBack",
      "reset",
      "search",
      "callStore",
      "showStoreInfo",
      "handleScroll",
      "setRefContents",
      "moveToPage",
      "updateState",
      "beforeDestroy",
    ]),
    ...mapActions("store", [
      "setRegular", // 단골 적용 여부
      "clickTab", // 탭메뉴 클릭
      "clickCategory", // 전체상품 카테고리 클릭
    ]),
    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });

      this.updateState({ type: "userId", updateData: userId });
    },
    onBackPressed() {
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        this.goBack();
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    watchScrollY() {
      const scrollY = window.scrollY;
      if (scrollY === 0 || scrollY > this.lastScroll) {
        this.showScroll = false;
      } else if (scrollY < this.lastScroll) {
        this.showScroll = true;
      }

      this.lastScroll = scrollY;
      this.handleScroll();
    },
  },
  components: {
    LoadingIndicator,
    TopHeader,
    SearchInput,
    TabList,
    ProductTitle,
    ProductList,
    Btn,
  },
  // beforeDestroy() {
  //   this.reset();
  // },
  beforeCreate() {
    // this.$store.commit('store/reset');
    var params = this.$route.params;
    var storeName = null;
    // console.log(!this.$route.params);

    if (this.$route.params.storeName) {
      localStorage.setItem("storeInfo", JSON.stringify(this.$route.params));
    } else {
      params = JSON.parse(localStorage.getItem("storeInfo"));
    }

    // console.log(params);

    if (params.marketId) {
      this.$store.dispatch("store/initState", {
        userId: params.userId,
        marketId: params.marketId,
        marketName: params.marketName,
        storeId: params.storeId,
        storeName: storeName,
        regularYn: params.regularYn,
      });
    }
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    this.setRefContents({ ref: this.$refs.contents });
    window.authResult = this.authResult;
  },
  created() {
    window.addEventListener("scroll", this.watchScrollY);
    this.handleScroll();
  },
  beforeDestroy() {
    this.beforeDestroy();
  },
  destroyed() {
    window.removeEventListener("scroll", this.watchScrollY);
  },
};
</script>

<style lang="scss" scoped>
.scroll_top {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  bottom: 0;
  right: 15px;
  width: 70px;
  height: 70px;
  z-index: 999;
  transition: 0.5s ease-out;

  &.active {
    visibility: visible;
    opacity: 1;
    bottom: 30px;
  }

  img {
    position: absolute;
    width: 70px;
    height: 70px;
    cursor: pointer;
    object-fit: contain;
  }
}

.store_header {
  position: relative;
  min-height: 186px;
  padding: 16px;
  background-color: $background-color-type5;
  .store_info {
    position: relative;
    min-height: 100px;
    padding: 0 0 0 115px;
    //background-color: #f00;
  }
  .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }
  .absolute_right {
    position: absolute;
    top: -5px;
    right: 0;
  }
  .store_detail {
    position: relative;
  }
  .market_name {
    padding: 0 70px 0 0;
    @include font(14, 18, 0);
    color: $active-color;
  }
  .store_name {
    margin: 5px 0 0;
    @include font(18, 24, 0, bold);
    color: $black;
  }
  .store_explain {
    margin: 10px 0 0;
    @include font(14, 20, 0);
    color: $gray;
  }
}
.store_unit_info {
  margin: 16px 0 0;
}
.search_area {
  padding: 10px 0 15px;
  background-color: $white;
}
.tab_wrap {
  height: 50px;
  background-color: $white;
  border-style: solid;
  border-color: #dedede;
  border-width: 1px 0;
  overflow-x: auto;
  overflow-y: hidden;
  &.none_border {
    padding: 0 16px;
    border-width: 0;
  }
  //&.active {
  //  position: fixed;
  //  top: 58px;
  //  left: 0;
  //  right: 0;
  //  height: 43px;
  //  border-width: 0 0 1px;
  //  border-color: $white transparent $border-type3;
  //  z-index: 99;
  //}
}

.top_product {
  position: relative;
  min-height: 165px;
  padding: 20px 16px 10px 160px;
  background-color: $white;
  .thumb {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 130px;
    height: 130px;
  }

  .top_product_detail {
    position: relative;
    padding: 0 0 80px;
  }
  .product_name {
    @include font(14, 18, 0, bold);
    color: $black;
  }

  .discount_area {
    line-height: 1;
    margin: 10px 0 0;
  }
  .discount {
    line-height: 1;
    margin: 0 0 0 5px;
    font-family: "gothic_a1_regular";
    font-size: 12px;
    text-decoration: line-through;
    color: $gray-light;
  }
  .discount_percent {
    line-height: 1;
    font-family: "gothic_a1_bold";
    font-size: 12px;
    letter-spacing: 0;
    color: $active-color;
    vertical-align: middle;
  }
  .price {
    position: relative;
    font-family: "gothic_a1_extrabold";
    font-size: 22px;
    color: $black;
    letter-spacing: 0;
  }
  .icon_like,
  .icon_like2 {
    //display: block;
    //margin: 30px 0 5px;
    position: absolute;
    top: -20px;
    left: 3px;
  }
  .absolute_bottom {
    position: absolute;
    left: -3px;
    bottom: 15px;
    min-height: 47px;
  }
}
@media (min-width: 380px) {
  .top_product {
    padding: 20px 16px 10px 190px;
    .thumb {
      width: 160px;
      height: 160px;
    }
    //.top_product_detail {
    //  padding: 0 0 100px;
    //}
  }
}

.contents_unit {
  margin: 10px 0 0;
  background-color: $white;
  &.first {
    margin: 0;
  }
  &.allProduct.active {
    .product_list {
      margin: 50px 0 0;
    }
    .tab_wrap {
      position: fixed;
      top: 108px;
      width: 100%;
      background-color: $white;
      border: {
        style: solid;
        width: 0 0 1px;
        color: $border-type1;
      }
      z-index: 999;
    }
  }
}
.contents {
  &.active {
    .contents_unit {
      &.first {
        margin: 50px 0 0;
      }
    }

    .tab_wrap.storeMainTab {
      position: fixed;
      width: 100%;
      border-width: 0 0 1px;
      border-color: $white transparent $border-type3;
      z-index: 99;

      transition-property: top;
      transition-duration: 10s;
      top: 58px;
    }
  }
}

.delivery_type_list {
  display: flex;
  flex-wrap: wrap;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 6px 0 0;
    padding: 3px 4px 2px;
    background: gray;
    @include border-radius(3px);
    @include font(12, 16);
    color: $white;

    &:last-child {
      margin: -3px 0 10px 0;
    }

    &.pkg {
      background-color: #67a3ff;
    }

    &.fd {
      background-color: #ffa033;
    }

    &.tb {
      background-color: #cf86ff;
    }
  }
}
</style>
