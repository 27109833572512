<template>
  <!--  메뉴 리스트 영역입니다.-->
  <div class="menu_list_wrap">
    <MenuItem v-for="(menu, idx) in menus" v-bind:key="idx" :title="menu.title" :link="menu.link" :badgeUrl="menu.badgeUrl" />
  </div>
</template>

<script>
import MenuItem from "./MenuItem";
export default {
  name: "MenuList",
  components: { MenuItem },
  data: function () {
    return {
      menus: [
        {
          title: "주문내역",
          link: "showOrderList",
        },
        {
          title: "보유쿠폰",
          badgeUrl: this.$route.query.userId ? "member/" + this.$route.query.userId + "/coupons/all" : "",
          link: "showCouponList",
        },
        {
          title: "QR 결제",
          link: "showQR",
        },
        // {
        //   title: '이용약관',
        //   link: 'showTerms',
        // },
        // {
        //   title: '개인정보 취급방침',
        //   link: 'showTerms',
        // },
        {
          title: "고객지원",
          link: "showFAQ",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.menu_list_wrap {
  padding-top: 80px;
}
</style>
