<template>
  <ul class="order_list mt_10">
    <li v-for="order in orders" :key="order.orderId">
      <a href="javascript:void(0);" class="item" @click="$emit('moveToApp', { orderId: order.orderId, event: $event })">
        <div class="order_title">{{ order.orderTitle }}</div>
        <div class="order_status">{{ order.orderStatus }}</div>
        <div class="order_price">{{ $formatPrice(order.payment) }} 원</div>
        <ul class="order_etc_list">
          <li>주문번호 : {{ order.orderId.slice(-4, order.orderId.length) }}</li>
          <li>
            주문일시 :
            {{ $moment(order.orderDateTime).format("YYYY.MM.DD dddd a HH:mm") }}
          </li>
        </ul>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "OrderHistoryList",
  props: {
    orders: Array,
    reviewMode: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.order_title {
  width: calc(100% - 45px);
  @include font(18, 22, -1.08, medium);
  @include text_ellipsis();
  color: $nj_black;
}
.order_status {
  position: absolute;
  top: 20px;
  right: 20px;
  @include font(12, 22, -0.72, bold);
  color: $nj_red;
}
.order_price {
  margin: 3px 0 0;
  @include font(16, 24, -0.96);
  color: $nj_text_sub_gray;
}
.order_etc_list {
  margin: 10px 0 0;
  @include font(12, 18, -0.72);
  color: $nj_placeholder_gray;
}
.order_list {
  > li {
    padding: 0 0 20px;
    &:last-child {
      padding: 0;
      border-width: 0;
    }
  }
  .item {
    display: block;
    position: relative;
    padding: 23px 20px 20px;
    background: $white;
    @include border-radius(20px);
    box-shadow: 0px 1px 0px $nj_shadow_gray;
    // margin-bottom: 10px;
    color: $nj_black;
    &:hover {
      color: $nj_black;
    }
  }
}
</style>
