import router from "@/router";

const state = {
  title: "결제완료",
  marketId: null,
  marketName: null,
  onSiteYn: null,
  orderId: null,
  orderType: null,
  order: {},
  products: [
    //{
    // storeName
    // productName
    // saleUnit
    // unitName
    // originName
    // optionGroups
    // payment
    //}
  ],
  delivery: {
    // receiverName: // 사용자 이름
    // receiverTel: // 번호
    // roadAddressFull: 주소
  },
  totalPayment: {
    // productsPrice: 상품들의 원래 가격 (할인 미적용, 옵션 가격 적용)
    // etcDiscount: 총 할인 ???????????????
    // deliveryFee: 배송비
    // deliveryDiscount: 배송비 할인
    // payment: 결제하는 총 금액
    // point: 놀장포인트
    // discountPackagingPrice: 포장주문 할인가
  },
};

const mutations = {
  initState: function (state, data) {
    state["marketId"] = data.marketId;
    state["marketName"] = data.marketName;
    state["orderId"] = data.orderId;
    state["orderType"] = data.orderType;
    state["delivery"] = data.delivery ? data.delivery : {};
    state["totalPayment"] = data.totalPayment ? data.totalPayment : {};
    state["products"] = data.products ? data.products : [];
    // state["paymentInfo"] = data.paymentInfo ? data.paymentInfo : null;

    var userCart = JSON.parse(localStorage.getItem("userCart"));
    userCart = userCart.filter((cart) => cart.marketId != state.marketId);

    localStorage.setItem("userCart", JSON.stringify(userCart));
    localStorage.setItem("userCartTotalCount", userCart.length > 0 ? userCart.length : "");

    this._vm.$callNative("succeedPayment", { marketId: state.marketId }, false);
  },
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  reset: function (state) {
    state.marketId = null;
    state.marketName = null;
    state.orderId = null;
    state.products = [];
    state.delivery = {};
    state.totalPayment = {};
  },
};

const actions = {
  initState: async function (store, data) {
    if (!data.userId || !data.orderId) {
      return false;
    }
    const url = "/v3/orders/members/" + data.userId + "/" + data.orderId + "/success";
    let initData = {};
    try {
      const paymentResult = await this._vm.$axios2.get(url);
      if (paymentResult.data.responseCode == 2000) {
        const result = paymentResult.data.result;
        initData["marketId"] = result.marketId;
        initData["marketName"] = result.marketName;
        initData["orderId"] = result.orderId;
        initData["orderType"] = result.orderType;
        initData["delivery"] = result.delivery;
        initData["totalPayment"] = result.totalPayment;
        initData["products"] = result.products;
        initData["onSiteYn"] = result.onSiteYn;

        if (result.onSiteYn === "Y") {
          let totalDiscountPackagingPrice = 0;
          result.products.forEach((product) => {
            if (product.productPrice > product.sellerPrice) {
              totalDiscountPackagingPrice += product.totalDiscountPackagingPrice; // discountPackagingPrice
            }
          });

          if (totalDiscountPackagingPrice) {
            initData["totalPayment"].totalDiscountPackagingPrice = totalDiscountPackagingPrice;
          }
        }

        store.commit("updateState", {
          type: "onSiteYn",
          updateData: result.onSiteYn,
        });
        store.commit("updateState", {
          type: "order",
          updateData: paymentResult.data.result,
        });

        if (paymentResult.data.result.nicePaymentTrResponse && paymentResult.data.result.nicePaymentTrResponse.payMethod === "VBANK") {
          const time = paymentResult.data.result.nicePaymentTrResponse.vbankExpTime;
          const hour = time.slice(0, 2);
          const minute = time.slice(2, 4);
          const second = time.slice(4);
          const formatTime = `${hour}:${minute}:${second}까지`;

          const date = paymentResult.data.result.nicePaymentTrResponse.vbankExpDate;
          const year = date.slice(0, 4);
          const month = date.slice(4, 6);
          const day = date.slice(6);
          const formatDate = `${year}년 ${month}월 ${day}일`;

          const _this = this;
          const modalName = "virtual_account";
          const compProps = {
            title: "결제 요청 안내",
            msgArr: [
              `아래 계좌로 입금하시면 주문이 완료됩니다.`,
              "예금주: 주식회사위주",
              `계좌번호: ${paymentResult.data.result.nicePaymentTrResponse.vbankName} ${paymentResult.data.result.nicePaymentTrResponse.vbankNum}`,
              `입금액: ${paymentResult.data.result.totalPayment.payment.toLocaleString()}원`,
              `입금기한: ${formatDate} ${formatTime}`,
            ],
            btnArr: [],
            type: "alert",
            closeModal: function () {
              _this._vm.$modal.hide(modalName);
            },
          };

          this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
        }
      }
    } catch (e) {
      console.error(e);
    }

    store.commit("initState", initData);
  },
  goBack: function (store) {
    store.commit("reset");
    router.push({ name: "장바구니", params: { reload: true } });
    // this._vm.$navigateBack();
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
