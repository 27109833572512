const getState = () => {
  return {
    title: null,
    isProgressing: false,
    brandId: null,
    marketId: null,
    storeId: null,
    keyword: null,
    products: [],
    productType: null,
    filters: [
      {
        text: "상품권",
        type: "paymentType",
        selectedItem: {
          text: "전체상품권",
          type: "ALL",
        },
        items: [
          {
            text: "전체상품권",
            type: "ALL",
          },
          {
            text: "온누리상품권",
            type: "ONR",
          },
          {
            text: "수산물상품권",
            type: "SEA",
          },
          {
            text: "서울사랑상품권",
            type: "ZB",
          },
        ],
      },
      {
        text: "정렬",
        type: "sort",
        selectedItem: {
          text: "좋아요순",
          type: "likeCount,desc",
        },
        items: [
          {
            text: "좋아요순",
            type: "likeCount,desc",
          },
          {
            text: "가격낮은순",
            type: "price,asc",
          },
          {
            text: "가격높은순",
            type: "price,desc",
          },
        ],
      },
    ],
  };
};

const state = getState();

const mutations = {
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  goBack: function () {
    // router.back();
    this._vm.$navigateBack();
  },
  reset: function (state) {
    Object.assign(state, getState());
  },
  selectFilterItem: function (state, { filterType, selectedItem }) {
    const filter = state.filters.find((item) => item.type == filterType);
    filter.selectedItem = selectedItem;
  },
};

const actions = {
  initState: async function (store, data) {
    console.log("initState ::::::::::::::");

    store.commit("updateState", {
      type: "title",
      updateData: data.title,
    });

    store.commit("updateState", {
      type: "brandId",
      updateData: data?.brandId,
    });

    store.commit("updateState", {
      type: "marketId",
      updateData: data?.marketId,
    });

    store.commit("updateState", {
      type: "productType",
      updateData: data.productType,
    });

    store.commit("updateState", {
      type: "storeId",
      updateData: data.storeId,
    });

    if (!state.keyword) {
      store.commit("updateState", {
        type: "keyword",
        updateData: data.keyword,
      });
    }

    // 바로배달인 경우 시장 조건 항목 추가
    if (data.productType == "BD") {
      const filters = state.filters;
      const isExist = state.filters.find((filter) => {
        return filter.type == "deliveryMethod";
      });

      if (!isExist) {
        const deliveryMethodFilter = {
          text: "배송",
          type: "deliveryMethod",
          selectedItem:
            !data.storeId && data.serviceQuickYn === "Y"
              ? {
                  text: "음식배달",
                  type: "FD",
                }
              : {
                  text: "전체배송",
                  type: "ALL",
                },
          items: [
            {
              text: "전체배송",
              type: "ALL",
            },
            {
              text: "묶음배달",
              type: "PKG",
            },
            {
              text: "음식배달",
              type: "FD",
            },
            {
              text: "묶음+음식배달",
              type: "FDPKG",
            },
          ],
        };

        filters.splice(1, 0, deliveryMethodFilter);
        store.commit("updateState", {
          type: "filters",
          updateData: filters,
        });
      }
    } else {
      store.commit("updateState", {
        type: "filters",
        updateData: state.filters.filter((filter) => {
          return filter.type != "deliveryMethod";
        }),
      });
    }

    // 초기 설정된 키워드가 있는 경우 검색 프로세스 호출
    if (state.keyword) {
      if (state.marketId || state.brandId) {
        store.dispatch("search", state.keyword);
      }
    }
  },
  goBack: function (store) {
    store.commit("reset");
    store.commit("goBack");
  },
  search: async function (store, keyword) {
    keyword = keyword ? keyword.trim() : "";

    store.commit("updateState", {
      type: "keyword",
      updateData: keyword,
    });

    let products = [];

    // 입력한 키워드가 없을 경우 초기화
    if (keyword) {
      // 검색어가 있으나 두 자 미만일 시

      // Memo :: 요청 사항에 따라 기능 주석 처리 (2021.11.08 - 서정민)
      // if (keyword.length < 2) {
      //   // this._vm.$alert(' ', new Array('검색어는 2글자 이상 입력해주세요.'));
      //   this._vm.$callNative("showToast", {
      //     message: "검색어는 2글자 이상 입력해주세요.",
      //   });
      //   return;
      // }

      if (state.brandId) {
        // console.log('state.brandId ::: ' + state.brandId);
        try {
          const userId = localStorage.getItem("userId");
          let data = {};
          state.filters.forEach((filter) => {
            data[filter.type] = Object.keys(filter.selectedItem).length ? filter.selectedItem.type : null;
          });
          const productUrl = `/v3/brand-mall/search/product-list/dummy?sort=${data.sort}&userId=${userId}`;
          // console.log(productUrl);
          // console.log(data);
          const response = await this._vm.$axios1.post(
            productUrl,
            {
              brandId: state.brandId,
              productName: keyword,
              paymentType: data.paymentType,
            },
            {
              headers: { "Content-Type": "application/json" },
            },
          );

          if (response.data.responseCode == 2000) {
            products = response.data.result.productList;
          } else {
          }
        } catch (e) {
          store.commit("updateState", {
            type: "isProgressing",
            updateData: false,
          });
        }
      } else {
        try {
          store.commit("updateState", {
            type: "isProgressing",
            updateData: true,
          });
          const url = "/v3/market/product/search/by-" + (state.storeId ? "store" : "market");

          let data = {
            marketId: state.marketId,
            searchText: keyword,
          };

          const userId = localStorage.getItem("userId");

          // userId가 있는 경우 단골여부 표시를 위해 userId 설정
          if (userId) {
            data["userId"] = userId;
          }

          // 상점 기준 검색인 경우 상점 ID 설정
          if (state.storeId) {
            data["storeId"] = state.storeId;
          }

          // 검색 조건 반영
          state.filters.forEach((filter) => {
            data[filter.type] = Object.keys(filter.selectedItem).length ? filter.selectedItem.type : null;
          });

          if (state.productType == "TB") {
            data.deliveryMethod = "TB";
          }

          const productsResponse = await this._vm.$axios1.get(url, {
            params: data,
          });
          store.commit("updateState", {
            type: "isProgressing",
            updateData: false,
          });

          if (productsResponse.data.responseCode == 2000) {
            products = productsResponse.data.result.productList;
          }
        } catch (e) {
          store.commit("updateState", {
            type: "isProgressing",
            updateData: false,
          });
          console.log(e);
        }
      }
    }

    store.commit("updateState", {
      type: "products",
      updateData: products,
    });
  },

  showFilter: async function (store, filter) {
    const selectedItem = await this._vm.$select(filter);

    if (selectedItem) {
      store.commit("selectFilterItem", {
        filterType: filter.type,
        selectedItem: selectedItem,
      });
      store.dispatch("search", state.keyword);
    }
  },
  setKeyword: function (store, keyword) {
    store.commit("updateState", {
      type: "keyword",
      updateData: keyword,
    });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
