<template>
  <ul class="radio_list" :class="type ? type : ''">
    <li v-for="option in options" :key="option.code" :class="{ active: option.active }">
      <input
        type="radio"
        name="radio_name"
        :id="`radio_${option.code}`"
        :value="option.code"
        :data-text="option.text"
        @change="changeRadio"
        :checked="checkValue == option.code ? true : false"
      />
      <label class="radio_icon_label" :for="`radio_${option.code}`">{{ option.text }}</label>
      <label class="radio_text_label" :for="`radio_${option.code}`">{{ option.text }}</label>
      <!--      {{ option.code }}  {{ checkValue }}-->
    </li>
  </ul>
</template>

<script>
export default {
  name: "RadioList",
  props: {
    options: Array,
    checkValue: String,
    changeRadio: Function,
    type: String,
  },
  // data: function () {
  //   return {
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.radio_list {
  &.type1 {
    > li {
      height: 40px;
      line-height: 40px;
      border-width: 0;
      text-align: left;
      &.active {
        .radio_icon_label {
          background-image: none;
          background-color: #fff;
          border : {
            style: solid;
            color: $active-border-color;
            width: 7px;
            radius: 10px;
          }
        }
      }
    }
    .radio_text_label {
      //margin-left: 0px;
      padding: 0;
      @include font(15, normal, -1.2);
      vertical-align: middle;
    }
    .radio_icon_label {
      width: 20px;
      height: 20px;
      background-image: none;
      background-color: #fff;
      border : {
        style: solid;
        color: #ddd;
        width: 7px;
        radius: 10px;
      }
      vertical-align: middle;
      //background-size: 30px;
    }
  }
  > li {
    position: relative;
    height: 70px;
    line-height: 70px;
    border-style: solid;
    border-color: $nj_divider_gray;
    border-width: 0 0 1px;
    overflow: hidden;
    &:last-child {
      border-width: 0;
    }
    &.active {
      .radio_icon_label {
        background-image: url("../../assets/images/icon_radio_active.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -99999px;
    //opacity: 0.5;
  }
  .radio_icon_label {
    display: inline-block;
    width: 36px;
    height: 40px;
    background-image: url("../../assets/images/icon_radio.svg");
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    vertical-align: middle;
  }
  .radio_text_label {
    display: inline-block;
    padding-bottom: 5px;
    margin-left: 10px;
    @include font(20, normal, -1.2);
    color: $nj_text_main_gray;
    vertical-align: middle;
    cursor: pointer;
  }
}
</style>
