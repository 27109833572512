const state = {
  title: "설정",
  userId: "",
  pushYn: "",
  thisVer: "",
  memberName: "",
  phoneNumber: "",
};

const mutations = {
  moveToApp: function (state, event) {
    const eventType = event.currentTarget.dataset.type;
    this._vm.$callNative("moveToUrl", { title: eventType });
  },
  initState: function (state, data) {
    state["pushYn"] = data.initData.pushYn;
    state["memberName"] = data.initData.memberName;
    state["phoneNumber"] = data.initData.phoneNumber;
  },
  setPush: function (state, data) {
    // console.log('setPush');
    // console.log(data);
    state["pushYn"] = data.pushYn;
  },
  setVersion: function (state, { version }) {
    state["thisVer"] = version;
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },
};

const actions = {
  initState: function (store, data) {
    state.userId = data.userId;
    state.thisVer = data.thisVer;

    state.memberName = data.memberName;
    state.phoneNumber = data.phoneNumber;

    const axiosUrl = "/v3/user/member/information/" + state.userId + "/personal";
    this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        if (response.data.responseCode == 2000) {
          // console.log(response.data);
          store.commit("initState", {
            initData: response.data.result,
          });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });

    const userResponse = this._vm.$axios1.get("/v3/user/member/information/" + state.userId + "/personal");
    if (userResponse.data.responseCode === 2000) {
      store.commit("initState", {
        initData: userResponse.data.result,
      });
    }
  },
  setPush: function (store, data) {
    const formdata = new FormData();
    formdata.append("push_yn", state.pushYn == "Y" ? "N" : "Y");
    formdata.append("member_id", state.userId);

    const axiosUrl = "/api/setMemberPushYnUpdate";
    this._vm.$axiosLegacy
      .post(axiosUrl, formdata)
      .then((response) => {
        if (response.data.resultCode == 200) {
          store.commit("setPush", { pushYn: response.data.result.push_yn });
          this._vm.isProgressing = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  setVersion: function (store, { version }) {
    store.commit("setVersion", { version });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
