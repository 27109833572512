const getState = () => {
  return {
    isProgressing: true,
    title: "문의보기",
    pageMode: null,
    inquiryId: null,
    inquiryList: [],
    inquiryStatus: null,
    resultMsg: null,

    loadComplete: false,

    orderId: null,
    userId: "",
    marketId: null,
    orderList: [],
    guideTxt: "",
    displayGuideLayer: false,
    imageFiles: [],
    hasImageFiles: [],
    displayLayer: false,
    isIos: false,

    previewImages: [],
    productImages: [], // 아이디 별로 이미지를 배열로 저장한다.
  };
};

const state = getState();

const mutations = {
  showHideGuideLayer: function (state) {
    state.displayGuideLayer = !state.displayGuideLayer;

    const _this = this;
    const modalName = "like_notice_modal";
    const compProps = {
      title: "상품 문의",
      msgArr: [state.guideTxt], // html 코드 적용 가능
      btnArr: [],
      type: "alert",
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    // 찜 등록/취소 안내 모달 출력
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
  },
  inquiryIncreaseDecrease: function (state, data) {
    const { type, productId, inquiryQty } = data;
    if (type == "DECREASE") {
      if (inquiryQty > 0) {
        state.inquiryList = state.inquiryList.map((order) => {
          if (order.keyId == productId) {
            order.inquiryQty--;
          }
          return order;
        });
      }
      if (inquiryQty == 1) {
        // 상품개수가 0이 될때 input의 텍스트를 지운다.
        document.querySelector('.contenteditable[data-product-id="' + String(productId) + '"]').innerHTML = "";
      }
    } else if (type == "INCREASE") {
      state.inquiryList = state.inquiryList.map((order) => {
        if (order.keyId == productId) {
          if (order.quantity > inquiryQty) {
            order.inquiryQty++;
          }
        }
        return order;
      });
    }
  },

  // moveToApp: function (state, data) {
  //     console.log(data);
  //     this._vm.$callNative('moveToOrder', {orderId: data.orderId});
  // },
  initState: function (state, data) {
    const inquiryList = data.inquiryList;
    const inquiryListLength = inquiryList.length;
    state["inquiryList"] = inquiryList;
    state["inquiryStatus"] = data.inquiryStatus;
    if (data.resultMsg) {
      state["resultMsg"] = data.resultMsg;
    }
    for (var i = 0; i < inquiryListLength; i++) {
      state["productImages"].push({ keyId: inquiryList[i].keyId });
      // state['productImages'].push({keyId: inquiryList[i].keyId, productId: inquiryList[i].productId});
    }

    // console.log(state['productImages']);

    state["loadComplete"] = true;
  },
  goBack: function () {
    Object.assign(state, getState());
    // vueContainer.$closeView();
    this._vm.$navigateBack();
  },

  reset: function (state) {
    state.inquiryList = [];
    Object.assign(state, getState());
  },
};

const actions = {
  onChangeCameraUpload: function (store, data) {},
  // onChangeFileUpload: function (store, file) {
  onChangeFileUpload: function (store, data) {
    // state.hasImageFiles = [];
    //
    // var targetIndex = null;
    // const ul = data.event.currentTarget.closest('ul');
    // state.inquiryList.map((item, index) => {
    //     console.log(':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::');
    //     console.log(ul.querySelector('#fileInput_' + data.orderProductId).value);
    //     state.hasImageFiles.push(ul.querySelector('#fileInput_' + data.orderProductId).value? true : false);
    //     // state.hasImageFiles.push(ul.children[index].querySelector('input[type=file].inquiryInput').value? true : false);
    //     if (item.keyId == data.orderProductId) {
    //         targetIndex = index;
    //     }
    // });
    //
    // var file = data.event.target.files;
    // console.log(file);
    //
    // if (file.length) {
    //     state.imageFiles[targetIndex] = file[0];
    // }
    // else {
    //     state.imageFiles.splice(targetIndex, 1);
    // }

    const files = data.event.target.files;
    const fileLength = files.length;
    const imgPreview = document.querySelector("#imgPreview_" + data.orderProductId);
    // console.log('#imgPreview_' + data.index + '_' + data.orderProductId);
    // console.log(imgPreview);
    imgPreview.innerHTML = "";

    state.imageFiles = [];

    for (var i = 0; i < fileLength; i++) {
      // files[i].name = files[i].name.trim();
      const file = files[i];

      if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
        return alert(file.name + " is not an image");
      }

      state.imageFiles.push(file);

      const reader = new FileReader();
      reader.addEventListener("load", function () {
        var image = new Image();
        // image.height = 100;
        image.title = file.name;
        image.src = this.result;
        imgPreview.appendChild(image);
      });

      reader.readAsDataURL(file);
      // console.log(file);
      // console.log(state.imageFiles);
    }

    state["productImages"].map((item) => {
      if (item.keyId == data.orderProductId) {
        item.imageFiles = [];
        item.imageFiles = files;
      }
      return item;
    });
    // console.log(files);
    // console.log(files[0]);
    // console.log(state['productImages']);

    /*
        console.log('onChangeFileUpload :::::::::::::::::::::::::::::::: ---------------- inquiryInput');
        console.log(document.querySelectorAll('input[type=file].inquiryInput').length);
        // console.log(document.querySelectorAll('input[type=file].inquiryInput')[0].value);
        // console.log(document.querySelectorAll('input[type=file].inquiryInput')[1].value);
        state.hasImageFiles = [];
        var targetIndex = null;
        const ul = data.event.currentTarget.closest('ul');

        console.log(state);

        state.inquiryList.map((item, index) => {
        // state.orderList.map((item, index) => {
            console.log(':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::');
            console.log(ul.children[index].querySelector('input[type=file].inquiryInput').value);

            state.hasImageFiles.push(ul.children[index].querySelector('input[type=file].inquiryInput').value? true : false);
            if (item.orderProductId == data.orderProductId) {
                targetIndex = index;
            }
        });

        console.log(state.hasImageFiles);

        var file = data.event.target.files;
        if (file.length) {
            // let url = URL.createObjectURL(file[0]);
            state.imageFiles[targetIndex] = file[0];
        }
        else {
            state.imageFiles.splice(targetIndex, 1);
            // state.imageFiles[targetIndex] = null;
        }
        console.log(state.imageFiles);
        console.log(state);
        console.log(file);
        */
  },

  registInquiry: function (store, data) {
    // console.log('----------------------------------');
    // console.log(state);

    // const list = data.data.ref;
    // const nodeList = list.querySelectorAll('.contenteditable');
    // const nodeLength = nodeList.length;

    var sendData = {
      imageFiles: [],
      marketId: state.marketId,
      messages: [],
      orderId: state.orderId,
      productIds: [],
      orderProductIds: [],
      quantities: [],
      userId: state.userId,
    };

    var inquiryCount = 0;
    var messageCount = 0;

    // const productImages = [];

    sendData.imageFiles = [];

    var images = [];
    var emptyImages = [];

    state.productImages.forEach((product) => {
      if (!product.imageFiles) {
        emptyImages.push(product);
      } else {
        images.push(product);
      }
    });
    state.productImages = [...images, ...emptyImages];

    state.productImages.forEach((product) => {
      const node = document.querySelector("#contenteditable_" + product.keyId);
      // const node = nodeList[index];
      // const listContainer = nodeList[index].closest('li');

      // console.log(listContainer);

      if (node.getAttribute("contenteditable") == "true") {
        inquiryCount++;
      }
      if (node.innerText.trim().length > 0) {
        messageCount++;
      }

      if (String(product.keyId) == String(node.dataset.productId)) {
        if (product.imageFiles) {
          product.imageFiles.forEach((image) => {
            sendData.imageFiles.push(image);
            sendData.productIds.push(node.dataset.id);
            sendData.messages.push(node.innerText);
            sendData.quantities.push(Number(node.closest("li").querySelector(".quantity_txt").innerText));
            sendData.orderProductIds.push(node.dataset.orderProductId);
          });
        } else {
          sendData.imageFiles.push("");
          sendData.productIds.push(node.dataset.id);
          sendData.messages.push(node.innerText);
          sendData.quantities.push(Number(node.closest("li").querySelector(".quantity_txt").innerText));
          sendData.orderProductIds.push(node.dataset.orderProductId);
        }
      }
    });

    if (inquiryCount == 0) {
      state.guideTxt = "상품 문의 개수를 등록해주세요.";
      store.commit("showHideGuideLayer");
      return;
    } else if (messageCount < inquiryCount) {
      state.guideTxt = "상품 문의 사유를 입력해주세요.";
      store.commit("showHideGuideLayer");
      return;
    }

    state.isProgressing = true;

    const formdata = new FormData();
    formdata.append("marketId", sendData.marketId);
    // formdata.append('messages', sendData.messages);
    formdata.append("orderId", sendData.orderId);
    // formdata.append('productIds', sendData.productIds);
    // formdata.append('quantities', sendData.quantities);
    formdata.append("userId", sendData.userId);

    // console.log(sendData.imageFiles);
    const uploadImageLength = sendData.imageFiles.length;
    if (uploadImageLength) {
      for (var i = 0; i < uploadImageLength; i++) {
        if (sendData.quantities[i] > 0) {
          formdata.append("messages", sendData.messages[i]);
          formdata.append("productIds", sendData.productIds[i]);
          formdata.append("quantities", sendData.quantities[i]);
          formdata.append("orderProductIds", sendData.orderProductIds[i]);

          if (sendData.imageFiles[i] != "") {
            formdata.append("imageFiles", sendData.imageFiles[i]);
          }
        }
      }
    } else {
      formdata.append("messages", sendData.messages);
      formdata.append("productIds", sendData.productIds);
      formdata.append("quantities", sendData.quantities);
      formdata.append("orderProductIds", sendData.orderProductIds);
    }

    // FormData의 key 확인
    // for (let key of formdata.keys()) {
    //     console.log(key);
    // }

    // FormData의 value 확인
    // for (let value of formdata.values()) {
    //     console.log(value);
    // }

    state.displayLayer = true;
    const axiosUrl = "/v3/inquiry";
    this._vm.$axios2
      .post(axiosUrl, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
        enctype: "multipart/form-data",
      })
      .then((response) => {
        // console.log(formdata);
        console.log(response);
        state.displayLayer = false;
        if (response.data.responseCode == 2000) {
          // console.log('상품문의 완료 XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
          // console.log('상품문의 완료 XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
          // console.log('상품문의 완료 XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');

          this._vm.$navigateBack();

          // this._vm.$closeView();
        } else {
          state.guideTxt = response.data.errorObject.errorMessage;
          store.commit("showHideGuideLayer");
        }
        state.isProgressing = false;
      })
      .catch((e) => {
        state.isProgressing = false;
      });
  },

  initState: async function (store, data) {
    state.productImages = [];

    const userId = localStorage.getItem("userId");
    const pageMode = data.pageMode ? data.pageMode : "view";

    state.userId = userId;
    state.pageMode = pageMode;
    state.marketId = data.marketId;
    state.orderId = data.orderId;

    state.isProgressing = true;

    if (pageMode == "view") {
      const axiosUrl = "/v3/inquiry/orderId/" + data.orderId;
      await this._vm.$axios2
        .get(axiosUrl)
        .then((response) => {
          state.isProgressing = false;
          if (response.data.responseCode == 2000) {
            // console.log(response.data.result.orderProduct[0].inquiries);

            // inquiryStatus: "ANSWERED"
            // inquiryStatusDescription: "처리 완료"
            let inquiryList = response.data.result.orderProduct;
            inquiryList.forEach((product) => {
              if (product.inquiries[0]) {
                product.imgUrlList = product.inquiries[0].imgUrlList;
                product.inquiryId = product.inquiries[0].inquiryId;
                product.inquiryProcSt = product.inquiries[0].inquiryProcStatus;
                product.inquiryProcSt = product.inquiries[0].inquiryProcStatusDescription;
                product.inquiryQty = product.inquiries[0].inquiryQty;
                product.message = product.inquiries[0].message;
                product.orderProductId = product.inquiries[0].orderProductId;
                product.procStatus = product.inquiries[0].procStatus;
                product.resultMsg = product.inquiries[0].resultMsg;
              }
            });
            store.commit("initState", {
              inquiryList: inquiryList,
              inquiryStatus: response.data.result.inquiryStatus == "CREATED" ? "1" : response.data.result.inquiryStatus == "ANSWERED" ? "D" : "1",
              resultMsg: response.data.result.resultMsg,
            });
            state.isProgressing = false;
          } else {
            alert(response.data.errorObject.errorMessage);
          }
        })
        .catch(() => {
          state.isProgressing = false;
        });
    } else if (data.pageMode == "write") {
      const axiosUrl = "/v3/orders/members/" + userId + "/" + data.orderId;
      await this._vm.$axios2
        .get(axiosUrl)
        .then((response) => {
          state.isProgressing = false;
          if (response.data.responseCode == 2000) {
            const productList = response.data.result.productList.map((product) => {
              product.inquiryQty = 0;

              product.keyId = product.productId;

              if (product.optionGroups.length > 0) {
                // optionGroups
                //      optionsItems

                product.optionGroups.forEach((group) => {
                  product.keyId = product.keyId + "_";
                  // if (groupIndex == 0) {
                  //     product.keyId = product.keyId + '!';
                  // }
                  // else {
                  //     product.keyId = product.keyId + '#';
                  // }
                  product.keyId = product.keyId + group.optionGroupId;
                  if (group.optionsItems.length > 0) {
                    group.optionsItems.forEach((item) => {
                      product.keyId = product.keyId + "_" + item.optionItemId;
                      // if (itemIndex == 0) {
                      //     product.keyId = product.keyId + '@' + item.optionItemId;
                      // }
                      // else {
                      //     product.keyId = product.keyId + ',' + item.optionItemId;
                      // }
                    });
                  }
                });
              }
              return product;
            });
            store.commit("initState", {
              inquiryList: productList,
              //     inquiryStatus: response.data.hasInquiry,
              //     resultMsg: response.data.resultMsg? response.data.resultMsg : null,
            });
            state.isProgressing = false;
          } else {
            alert(response.data.errorObject.errorMessage);
          }
          state.isProgressing = false;
        })
        .catch(() => {
          state.isProgressing = false;
        });
    }
    // state.isProgressing = false;
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
