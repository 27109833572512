<template>
  <div class="wrap">
    <div class="title">놀장 사용자 앱 리뉴얼 웹뷰 화면</div>
    <div class="schedule_wrap">
      <div class="schedule_header">5월 1주차</div>
      <div class="link">
        <router-link to="/delivery-address">배달지 설정 및 추가 [uId]</router-link>
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">5월 2주차</div>
      <div class="link">
        <router-link to="/notice">알림화면</router-link>
      </div>
      <div class="link">
        <router-link to="/my-page-prev">마이페이지 [uId*]</router-link>
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">5월 3주차</div>
      <div class="link">
        <router-link to="/delivery-product?userId=B6545D8E-ECE0-4044-9D52-C05496D4D410&productId=12707">상품 정보 [pId*, uId]</router-link>
      </div>
      <div class="link">
        <router-link to="/delivery-store">상점 정보 [sId*, uId]</router-link>
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">5월 4주차</div>
      <div class="link">
        <router-link to="/cart-market">장바구니 [pId*, uId]</router-link>
      </div>
      <div class="link">
        <router-link :to="{ name: '장바구니(배송지)', params: { cart: cart } }">장바구니-배송지 [mId*, pId*, uId]</router-link>
        <!--        <router-link to="/cart-ship">장바구니-배송지 [mId*, pId*, uId]</router-link>-->
      </div>
      <div class="link">
        <router-link to="/payment?userId=B6545D8E-ECE0-4044-9D52-C05496D4D410&orderId=OID202107162170364E5A244C878EE70C829C2E784B"
          >결제완료 [uId]</router-link
        >
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">6월 1주차</div>
      <div class="link">
        <!--        <router-link :to="{name: '카테고리 상품목록', params: {categoryProduct: categoryProduct}}">카테고리 상품목록</router-link>-->
        <router-link to="/category-product?marketId=2&categoryId=3&pageType=">카테고리 상품목록</router-link>
        <!--        <router-link to="/category-product?latitude=37.479661&longitude=126.8526366&pageType=today">카테고리 상품목록</router-link>-->
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">6월 2주차</div>
      <div class="link">
        <router-link to="/coupon-prev">보유쿠폰 [uId*]</router-link>
      </div>
      <div class="link">
        <router-link to="/faq">고객센터</router-link>
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">6월 3주차</div>
      <div class="link">
        <router-link to="/order-history-prev?userId=efe7f717-fdae-4ab1-a08f-d7c6e8803c9c">주문내역 [uId*]</router-link>
      </div>
      <div class="link">
        <router-link to="/order-detail-prev?userId=B6545D8E-ECE0-4044-9D52-C05496D4D410&orderId=OID20210714E56D45038D604F27AEBFDE0C64EBE505"
          >주문상세 [uId*, oId*]</router-link
        >
      </div>
      <div class="link">
        <router-link to="/market?deliveryType=BD&latitude=37.5432900176718&longitude=126.728080590524">전체시장 리스트 [uId*]</router-link>
      </div>
      <div class="link">
        <router-link to="/favorite-store?userId=efe7f717-fdae-4ab1-a08f-d7c6e8803c9c">단골가게 [uId*]</router-link>
      </div>
    </div>
    <div class="schedule_wrap">
      <div class="schedule_header">7월 1주차</div>
      <div class="link">
        <router-link to="/review-prev?pageMode=write&userId=B6545D8E-ECE0-4044-9D52-C05496D4D410&orderId=OID2021093063DB3DAD28E54BFB84657CD7E889BB42"
          >리뷰 쓰기/보기 [pageMode(write, view), uId*, oId*]</router-link
        >
        <!--        <router-link to="/review?pageMode=view&userId=56a28267-4a57-4e60-b9c9-f8bc1d215e1c&orderId=OID202107026E8E6CBFAB7245F2A28F6B674A142100">리뷰 쓰기/보기 [pageMode(write, view), uId*, oId*]</router-link>-->
      </div>
      <div class="link">
        <router-link to="/withdraw?userId=56a28267-4a57-4e60-b9c9-f8bc1d215e1c">서비스 탈퇴 [uId*]</router-link>
      </div>
      <div class="link">
        <router-link to="/setting-prev?userId=56a28267-4a57-4e60-b9c9-f8bc1d215e1c&thisVer=2.0.26">설정 [uId*]</router-link>
      </div>
      <div class="link">
        <!--        <router-link to="/view-product-inquiry?orderId=IQID202005273F47457253BA4D46A589240E63BBA758">상품문의보기 [inquiryId == oId*]</router-link>-->
        <router-link to="/view-product-inquiry-prev?orderId=IQID202107223020B85D7D2A4D1DA4B69C50CB2529F9"
          >상품문의보기 [inquiryId == oId*]</router-link
        >
      </div>
      <div class="link">
        <router-link to="/write-product-inquiry-prev?userId=B6545D8E-ECE0-4044-9D52-C05496D4D410&orderId=OID2021093063DB3DAD28E54BFB84657CD7E889BB42"
          >상품문의쓰기 [uId*, oId*]</router-link
        >
      </div>
      <div class="link">
        <router-link to="/test-template?orderId=OID2020031205C195135A5947D89A430B81AC202BE4">템플릿 [orderId*]</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {},
  data: function () {
    return {
      // categoryProduct: {
      //   marketId: 3,
      //   // categoryId: 2,
      //   pageType: 'today'// 일반 = null, 핫세일 = hot, 오늘 들어 왔어요 = today, 특산품 = special
      // },
      cart: [
        {
          marketId: 22,
          products: [
            {
              // compoundKey: '42500!1@1,2#3@5#4@8',
              compoundKey: "8108!113@181,183#114@184",
              quantity: 7,
            },
            {
              compoundKey: "42501",
              quantity: 1,
            },
          ],
        },
        /*{
          marketId: 38,
          products:[
            {
              compoundKey: '41000',
              quantity: 2,
            },
          ]
        },
        {
          marketId: 34,
          products:[
            {
              compoundKey: '42200',
              quantity: 1
            },
            {
              compoundKey: '42201',
              quantity: 2,
            },
          ]

        }*/
      ],
    };
  },
  components: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrap {
  padding: 20px;
  height: 100%;
}

.title {
  font-family: Noto Sans CJK KR Bold;
  font-size: 24px;
  margin-bottom: 30px;
}

.schedule_wrap {
  padding: 5px;
  width: 50%;
  min-height: 100px;
  float: left;
  margin-bottom: 15px;
}

.schedule_wrap .schedule_header {
  padding: 5px 10px;
  margin-bottom: 5px;

  font-family: Noto Sans CJK KR;
  font-size: 16px;
  color: white;

  background: #ff6767;
  border-radius: 10px;
}

.schedule_wrap .link {
  padding: 0 10px;
  font-family: Noto Sans CJK KR;
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration: underline;
}

.schedule_wrap .link > a {
  color: $nj_text_main_gray;
}
</style>
