var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"store_list"},_vm._l((_vm.list),function(item){return _c('li',{key:item.storeId,attrs:{"data-list-id":item.storeId,"data-service-on-off":_vm.serviceOnOff}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"thumb_wrap"},[(item.representativeImageUrl)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('goLink', {
              type: 'STORE',
              marketId: item.marketId,
              storeId: item.storeId,
              event: $event,
            })}}},[_c('img',{staticClass:"thumb",attrs:{"src":item.representativeImageUrl,"alt":item.storeName}})]):_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('goLink', {
              type: 'STORE',
              marketId: item.marketId,
              storeId: item.storeId,
              event: $event,
            })}}},[_c('div',{staticClass:"empty_store"})])]),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"market_name"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('goLink', {
                type: 'MARKET',
                marketId: item.marketId,
                storeId: item.storeId,
                event: $event,
              })}}},[_vm._v(" "+_vm._s(item.marketName)+" ")])]),_c('div',{staticClass:"store_name"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('goLink', {
                type: 'STORE',
                marketId: item.marketId,
                storeId: item.storeId,
                event: $event,
              })}}},[_vm._v(" "+_vm._s(item.storeName)+" ")])]),_c('div',{staticClass:"favorite_like"},[_c('FavoriteLike',{attrs:{"store":item.favoriteCount,"like":item.productLikeCount}})],1)]),_c('a',{staticClass:"hang_up",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('goLink', {
            type: 'HANG_UP',
            marketId: item.marketId,
            storeId: item.storeId,
            event: $event,
          })}}},[_vm._v(" 단골끊기 ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }