<template>
  <div id="app">
    <div class="layout_wrap">
      <div class="bg_wrap"></div>
      <div class="side_wrap">
        <div class="side_view_wrap">
          <div class="side_view_title">전통시장 대표 플랫폼</div>
          <img class="side_view_logo" src="./assets/images/noljang_logo.png" alt="놀장 로고" />
          <p class="side_view_desc">
            전통시장의 가치를 모으고,<br />
            잇고, 공유함으로써 사회문제를<br />
            해결하는 기업, 고객과 함께하는<br />
            기업을 만들어 가고있습니다.
          </p>
          <div class="qr_wrap">
            <img class="qr_icon" src="./assets/images/noljang_qr.png" alt="놀장 아이폰 QR코드" />
          </div>
        </div>
      </div>
      <div class="view_wrap">
        <router-view :key="$route.fullPath"></router-view>
        <transition name="fade">
          <Msg :type="msgType" :text="msgText" :addText="msgAddText" :callback="msgCallback" v-if="msgText" />
        </transition>

        <transition name="">
          <ModalBottom
            :type="modalBottom.type"
            :name="modalBottom.name"
            :title="modalBottom.title"
            :activeId="modalBottom.activeId"
            :options="modalBottom.options"
            :callback="modalBottom.callback"
            v-if="modalBottom.showHide && modalBottom.type"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Msg from "@/components/new/Msg";
import ModalBottom from "@/components/new/ModalBottom";
import MobileDetect from "mobile-detect";
export default {
  name: "app",
  data: function () {
    return {
      scrollModal: false,
    };
  },
  methods: {
    ...mapActions(["deleteCartByMarketId"]),
    handleRefresh(e) {
      e.preventDefault();
      e.returnValue = "";
    },
  },
  components: {
    ModalBottom,
    Msg,
  },
  computed: {
    ...mapState({
      msgType: (state) => state.msgType,
      msgText: (state) => state.msgText,
      msgAddText: (state) => state.msgAddText,
      msgCallback: (state) => state.msgCallback,
      modalBottom: (state) => state.modalBottom,
      appVersion: (state) => state.appVersion,
      appYn: (state) => state.appYn,
    }),
  },
  mounted() {
    window.deleteCartByMarketId = this.deleteCartByMarketId;
    console.log("appVersionyn****", this.appYn);
    console.log("appyn", this.$store.state.appYn);
    let md = new MobileDetect(
      "Mozilla/5.0 (Linux; U; Android 4.0.3; en-in; SonyEricssonMT11i" +
        " Build/4.1.A.0.562) AppleWebKit/534.30 (KHTML, like Gecko)" +
        " Version/ 4.0 모바일 사파리/534.30",
    );
    // console.log(md.mobile());
    // console.log(!md.mobile());
    if (!md.mobile()) {
      //pc version
      window.addEventListener("scroll", function () {
        alert("앱으로 사용해주세요");
      });
    }

    const _this = this;
    window.navigateTo = function (path) {
      if (!path.startsWith("/")) {
        path = "/" + path;
      }
      const pathArr = path.split("?");

      if (pathArr.length > 1) {
        const route = _this.$router.options.routes.find((route) => {
          return pathArr[0] == route.path;
        });

        let params = {};
        const paramArr = pathArr[1].split("&");

        paramArr.forEach((param) => {
          const kv = param.split("=");
          params[kv[0]] = kv[1];
        });
        _this.$router.push({ name: route.name, params: params });
      } else {
        _this.$router.push({ path: path });
      }
    };
  },
  beforeMount() {
    if (window.innerWidth > 1024) {
      window.addEventListener("beforeunload", this.handleRefresh);
    }
  },
};
</script>
<style lang="scss">
.bg_wrap,
.side_wrap {
  display: none;
}

.view_wrap {
  overflow: hidden;
}

@media (min-width: 1024px) {
  .layout_wrap {
    .bg_wrap {
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-image: url(./assets/images/webpg_background.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .side_wrap {
      display: block;
      position: fixed;
      width: calc(50vw - (50vw - 500px));
      height: 100vh;
      margin-left: calc(50vw - 500px);
      background-color: #f8f8f8;
      z-index: 99;

      .side_view_wrap {
        position: fixed;
        top: 20vh;
        text-align: center;
        padding-left: 50px;

        .side_view_title {
          font-size: 33px;
          font-weight: 600;
        }

        .side_view_logo {
          max-height: 100px;
        }

        .side_view_desc {
          font-size: 22px;
          line-height: 1.5;
          margin-top: 30px;
          margin-bottom: 30px;
          text-align: left;
        }

        .qr_wrap {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;

          .qr_icon {
            max-width: 110px;
            margin: 0 auto 10px;
          }

          &:after {
            content: "*상품 구매는 놀장 앱 설치하고 이용해주세요";
            position: absolute;
            bottom: -30px;
            left: 0;
            width: 100%;
          }
        }
      }
    }

    .view_wrap {
      position: relative;
      margin-left: 50vw;

      .all_menu_container {
        left: 50vw;
      }

      &,
      .main_header_wrap,
      .loading_wrap > div,
      .bottom_menu_wrap,
      .top_header,
      .second_header,
      .tab_wrap,
      .absolute_bottom,
      .bottom_wrap,
      .modal_bottom_wrap,
      .market_container,
      .all_menu_container,
      .all_menu_bg,
      .overlay {
        width: clamp(400px, 30vw, 450px) !important;
      }

      .reload_scroll_wrap {
        left: calc(50vw + clamp(400px, 30vw, 480px) / 2 - 20px) !important;
      }

      .reloading {
        display: block !important;
        top: 110px;
      }

      .main_wrap,
      .market_container,
      .contents {
        .scroll_top {
          right: auto;
          transform: translateX(clamp(330px, 24vw, 370px));
        }
      }
    }
  }
}
</style>
