<template>
  <div>
    <div class="addr_item" @click="updateCurrentShipTo(shipToId)">
      <div>
        <div class="jibun_addr">
          {{ shipToJibun }}
        </div>
        <a href="javascript:void(0)" class="del_addr_btn" @click.stop="showHideDeleteAlertLayer({ shipToId })">
          <!--          <img src="@/assets/images/Xbtn.png"/>-->
        </a>
      </div>
      <div class="doro_addr_wrap">
        <div class="doro_addr_icon_wrap">
          <span>도로명</span>
        </div>
        <div class="doro_addr">{{ shipToDoro }}</div>
      </div>
      <div class="ship_to_detail">
        {{ shipToDetail }}
      </div>
      <div class="ship_to_name" v-if="shipToName">
        {{ shipToName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DeliveryAddrItem",
  props: {
    shipToId: Number,
    shipToDoro: String,
    shipToJibun: String,
    shipToDetail: String,
    shipToName: String,
  },
  data: function () {
    return {};
  },
  methods: {
    ...mapActions("deliveryAddr", ["updateCurrentShipTo", "showHideDeleteAlertLayer"]),
  },
  computed: {
    ...mapState("deliveryAddr", ["displayDeleteAlertLayer"]),
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.addr_item {
  margin-bottom: 40px;
}

.addr_item * {
  letter-spacing: -0.96px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
}

.addr_item > div {
  display: inline-block;
  width: 100%;
  line-height: 24px;
}

.jibun_addr {
  font: normal normal normal 16px/24px Noto Sans CJK KR Medium;
  letter-spacing: -0.96px;
  color: $nj_black;
  width: calc(100% - 24px);
  float: left;
}

.del_addr_btn {
  float: right;
  width: 14px;
  height: 24px;

  background-image: url("~image/Xbtn.png");
  background-repeat: no-repeat;
  background-position: center;
}

.doro_addr_wrap {
  margin-bottom: 10px;
}

.doro_addr_wrap > div {
  float: left;
}

.doro_addr_wrap > .doro_addr {
  width: calc(100% - 55px);
  color: $nj_text_main_gray;
}

.doro_addr_icon_wrap {
  font-size: 10px;
  color: #ffffff;
  margin-right: 10px;
}

.doro_addr_icon_wrap > span {
  font: normal normal normal 10px/15px Noto Sans CJK KR;
  color: #ffffff;
  width: 40px;
  height: 16px;
  padding: 1px 7px;
  background: $nj_red;
  border-radius: 10px;
  box-sizing: border-box;
  letter-spacing: -0.6px;
}

.doro_addr {
  color: $nj_text_main_gray;
}

.ship_to_detail {
  padding: 10px 0;
  border-bottom: 1px solid $nj_divider_gray;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  color: $nj_black;
}

.ship_to_name {
  padding: 10px 0;
  border-bottom: 1px solid #d4d4d4;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  color: $nj_black;
}
</style>
