<template>
  <div>
    <TopHeader title="마이페이지" theme="nj_red type1" :isButton="false"></TopHeader>
    <div class="content_wrap">
      <div class="content">
        <MyProfile />
        <MenuList />
        <div class="footer_wrap">
          <span class="title">문의</span>
          <span @click="goCall" class="tel">{{ tel }}</span>
          <span class="time">평일 10:00 - 18:30</span>
          <span class="time">점심 12:00 - 13:00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import MyProfile from "./components/MyProfile";
import MenuList from "./components/MenuList";

export default {
  name: "MyPage",
  props: {},
  data: function () {
    return {
      tel: this.$getTelNumber(),
    };
  },
  components: {
    MenuList,
    MyProfile,
    TopHeader,
  },
  methods: {
    goCall: function () {
      this.$callNative("showCall", {
        phoneNumber: this.tel.replaceAll(".", ""),
      });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.content_wrap {
  width: 100%;
  height: calc(100vh - 104px);
  //padding-top: 60px;
  background: $nj_red;
  overflow-y: auto;

  .content {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background: $nj_background_gray;
  }

  .footer_wrap {
    padding: 30px 0 70px;
    text-align: center;

    span {
      display: block;
    }

    .title {
      @include font(16, 24, -0.96, bold);
      color: $nj_black;
    }

    .tel {
      @include font(24, 36, -0.48, bold);
      color: $nj_red;
    }

    .time {
      @include font(16, 24, -0.64);
      color: $nj_text_main_gray;
    }
  }
}
</style>
