<template>
  <div class="panel_wrap">
    <div class="title_wrap">
      <div class="title">
        {{ title }}
      </div>
      <div class="title_right" v-if="!!this.$slots.title_right">
        <slot name="title_right"></slot>
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "PanelWrap",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.panel_wrap {
  border-bottom: 1px solid $nj_divider_gray;
  padding-top: 20px;
  padding-bottom: 30px;

  .title_wrap {
    margin-bottom: 10px;

    & > * {
      display: inline-block;
    }

    .title {
      @include font(20, 29, -1.2);
      color: $nj_black;
    }

    .title_right {
      float: right;
    }

    &:not(:first-child) {
      padding-top: 20px;
    }
  }
}
</style>
