<template>
  <div>
    <PanelWrap title="수령방법">
      <template v-slot:content>
        <NJSelect placeholder="선택해주세요." :items="receiptMethods" @select="$emit('select', $event)"></NJSelect>
      </template>
    </PanelWrap>
    <PanelWrap title="배송요청사항">
      <template v-slot:content>
        <NJInput placeholder="배송관련 요청사항을 적어주세요." @input="$emit('input', $event)"></NJInput>
      </template>
    </PanelWrap>
  </div>
</template>

<script>
import NJSelect from "../../../components/NJSelect";
import NJInput from "../../../components/NJInput";
import PanelWrap from "./PanelWrap";

export default {
  name: "DeliveryInfo",
  data: function () {
    return {
      receiptMethods: ["문 앞에 놓고 벨 눌러주세요.", "문 앞에 놓고 노크해주세요.", "도착 전 미리 연락주세요."],
    };
  },
  components: { PanelWrap, NJSelect, NJInput },
};
</script>

<style lang="scss" scoped></style>
