import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import { MdProgress } from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import axios from "axios";
import common from "./assets/js/common";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/ko";
import vModal from "vue-js-modal";
import VueClipboard from "vue-clipboard2";
import LoadScript from "vue-plugin-load-script";

const url = process.env.NODE_ENV === "dev" ? "https://noljang.com" : "https://www.noljang.co.kr";
const bjbAxios = axios.create({
  baseURL: location.origin === process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BJB_API_URL : `${url}:8102`,
});

bjbAxios.interceptors.request.use(
  function (config) {
    let isValid = true;

    const originUrl = location.origin; // 현재 URL
    const baseUrl = location.origin === process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : url; // 정상 URL
    if (originUrl != baseUrl) {
      isValid = false;
    }

    // 실행 환경(모드)와 호스트가 매칭되지 않는 경우
    // if (!isValid) {
    //   throw new axios.Cancel("현재 실행 환경(모드)가 호스트와 매칭되지 않습니다.");
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

bjbAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      console.error("Request canceled", error.message);
    }
    return Promise.reject(error);
  },
);

const jjyAxios = axios.create({
  baseURL: location.origin === process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_JJY_API_URL : `${url}:8101`,
});

jjyAxios.interceptors.request.use(
  function (config) {
    let isValid = true;

    const originUrl = location.origin; // 현재 URL
    const baseUrl = location.origin === process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : url; // 정상 URL
    if (originUrl != baseUrl) {
      isValid = false;
    }

    // 실행 환경(모드)와 호스트가 매칭되지 않는 경우
    // if (!isValid) {
    //   throw new axios.Cancel("현재 실행 환경(모드)가 호스트와 매칭되지 않습니다.");
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

jjyAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      console.error("Request canceled", error.message);
    }
    return Promise.reject(error);
  },
);

const legacyAxios = axios.create({
  baseURL: location.origin === process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_LEGACY_API_URL : `${url}:8080`,
});

router.beforeEach((to, from, next) => {
  const fromPath = from.path == "/" ? "/" : from.path;
  const toPath = to.path == "/" ? "/" : to.path;
  // const fromPath = from.path == '/' ? '/' : from.path.split('/')[1];
  // const toPath = to.path == '/' ? '/' :  to.path.split('/')[1];

  if (fromPath != toPath || (fromPath == "/" && toPath == "/")) {
    if (to.path === "/market-main") {
      Vue.prototype.$navigateTo(to.path, to.params);
    } else {
      Vue.prototype.$navigateTo(to.path);
    }
  }

  next();
});

router.afterEach((to, from) => {
  const fromPath = from.path == "/" ? "/" : from.path;
  const toPath = to.path == "/" ? "/" : to.path;
  // const fromPath = from.path == '/' ? '/' : from.path.split('/')[1];
  // const toPath = to.path == '/' ? '/' :  to.path.split('/')[1];

  if (fromPath != toPath || (fromPath == "/" && toPath == "/")) {
    if (!to.path) {
      return false;
    }

    if (!to.path.startsWith("/")) {
      to.path = "/" + to.path;
    }

    Vue.prototype.$callNative("completeNavigation", { path: to.path });
  }
});

Vue.config.productionTip = false;
Vue.use(MdProgress);
Vue.use(common);
Vue.use(router);
Vue.use(VueMoment, { moment });
Vue.use(store);
Vue.use(LoadScript);

Vue.use(vModal, { dialog: true });
Vue.use(VueClipboard);

Vue.prototype.$axios = axios;
Vue.prototype.$axios1 = bjbAxios;
Vue.prototype.$axios2 = jjyAxios;
Vue.prototype.$axiosLegacy = legacyAxios;

Vue.loadScript("https://rawgithub.com/tomyun/crypto-js/seed-3.1.2/build/rollups/seed.js");

new Vue({
  router,
  store,
  vModal,
  render: (h) => h(App),
}).$mount("#app");
