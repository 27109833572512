<template>
  <div class="top_header" :class="theme">
    <span class="btn_back" v-if="isButton" @click="onBackPressed ? onBackPressed : $emit('goBack', vueContainer)">
      <slot name="btn_back_icon">
        <img v-if="theme != 'nj_red'" src="@/assets/images/btn_back.png" loading="lazy"/>
        <img v-else src="@/assets/images/btn_back_white.png" loading="lazy"/>
      </slot>
    </span>
    <span class="menu_title">{{ title }}</span>
    <slot name="absolute_right"></slot>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  props: {
    title: String,
    theme: {
      type: String,
      default: "nj_white",
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    onBackPressed: Function,
  },
  data: function () {
    return {
      vueContainer: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.top_header {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  &.type1 {
    height: 104px;
  }

  &.nj_white {
    background: #ffffff;
    color: $nj_black;
  }

  &.nj_gray {
    background: #e2e2e2;
    color: $nj_black;
  }

  &.nj_red {
    background: $nj_red;
    color: #ffffff;
  }

  .btn_back {
    position: absolute;
    left: 10px;
    width: 29px;
    height: 29px;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: none;
    }
  }

  .menu_title {
    display: block;
    padding: 8px 0 7px 0;
    font: normal normal normal 20px/29px Noto Sans CJK KR Bold;
    letter-spacing: -1.2px;
  }
}

.top_absolute {
  position: absolute;
  top: 9px;
  right: 20px;

  .btn_search {
    display: inline-block;
    width: 23px;
    height: 23px;
    vertical-align: middle;
    background-image: url("../assets/images/icon_search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px;
    text-indent: -99999px;
  }
  .btn_store {
    display: inline-block;
    width: 23px;
    height: 23px;
    vertical-align: middle;
    background-image: url("../assets/images/icon_store.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 21px 19px;
    text-indent: -99999px;
  }
}
</style>
