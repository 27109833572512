<script>
export default {
  name: "ReportingItem",
  props: {
    reports: {},
  },
  data: function () {
    return {
      headers: [
        {
          text: "글번호",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "제목", value: "title" },
        { text: "내용", value: "content" },
      ],
      activeId: null,
      userId: localStorage.getItem("userId"),
    };
  },
  components: {},
  computed: {},
  methods: {
    updateActive(id) {
      if (this.activeId === id) {
        this.activeId = null;
      } else {
        this.activeId = id;
      }
    },
    cateKR(cate) {
      if (cate === "PRICE") {
        return "가격";
      }
    },
    pushEdit(id) {
      this.$router.push({ name: "reportwrite", params: { id } });
    },
  },
  mounted() {},
  created: function () {},
};
</script>

<template>
  <div class="flex bg-red-500">
    <ul class="report_wrap">
      <li class="report_list report_header">
        <div class="id_row">글번호</div>
        <div class="cate_row">신고유형</div>
        <div class="detail_row" style="text-align: center">제목</div>
      </li>
      <li v-for="report in reports.filter((rep) => rep.viewBoard)" :key="report.id" @click="updateActive(report.id)" class="report_list">
        <div class="report_row">
          <div class="id_row">{{ report.id }}</div>
          <div class="cate_row">{{ cateKR(report.cate) }}</div>
          <div class="detail_row">
            <div class="report_title" :class="{ seen: report.checkBoard }">{{ report.title }}</div>
          </div>
        </div>
        <div v-show="activeId === report.id" class="report_content_wrap">
          <div class="report_content">{{ report.content }}</div>
          <ul>
            댓글
            <li v-for="comment in report.comment" :key="comment.id" class="report_comment">
              <span>{{ comment.content }}</span>
            </li>
          </ul>
          <div class="report_edit" v-if="userId !== undefined">
            <button @click="pushEdit(report.id)">수정</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
.report_wrap {
  .report_header {
    display: flex;
    background-color: lightsalmon;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .report_list {
    .report_row {
      display: flex;
    }

    &:not(&:first-child) {
      border-bottom: 1px solid gray;
    }

    .id_row {
      width: 25%;
      text-align: center;
    }

    .cate_row {
      width: 30%;
      text-align: center;
    }

    .detail_row {
      width: 100%;
    }

    .report_title {
      position: relative;

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "미처리";
        color: gray;
        font-size: 10px;
      }

      &.seen:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "처리완료";
        color: lightsalmon;
        font-size: 10px;
      }
    }

    .report_content_wrap {
      padding: 10px;

      .report_content {
        border: 1px solid gray;
        padding: 10px 15px;
        margin-bottom: 10px;
      }

      .report_comment {
        list-style: inside;
      }

      .report_edit {
        display: flex;
        justify-content: center;

        button {
          margin: 0 auto;
          background-color: lightsalmon;
          font-size: 15px;
          font-weight: bold;
          padding: 2px 30px;
          border-radius: 5px;
          border: none;
          color: white;
        }
      }
    }
  }
}
</style>
