<template>
  <div class="relative">
    <div class="event_list_wrap">
      <div v-if="market.marketEventList.length == 0" class="empty_list">등록된 이벤트가 없습니다.</div>
      <ul
        v-else
        :style="{
          width: (market.marketEventList.length + 1) * 114 + 10 - 40 + 'px',
        }"
      >
        <li v-for="(marketEvent, idx) in market.marketEventList" :key="marketEvent.eventId">
          <a
            href="javascript:void(0)"
            :data-market-id="market.marketId"
            :data-market-name="market.marketName"
            :data-event-id="marketEvent.eventId"
            :data-open-yn="market.openYn"
            @click="goMarket"
          >
            <div class="event_num">EVENT {{ getEventNumber(idx) }}</div>
            <div class="event_txt" v-html="marketEvent.eventName"></div>
          </a>
        </li>
      </ul>
    </div>
    <div class="more_wrap">
      <a
        href="javascript:void(0)"
        class="more_txt"
        :class="{ active: market.openYn == 'Y' ? true : false }"
        v-html="market.openYn == 'Y' ? '시장<br>보러가기' : '상품<br/>준비중'"
        :data-market-id="market.marketId"
        :data-market-name="market.marketName"
        :data-open-yn="market.openYn"
        @click="goMarket"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EventList",
  props: {
    market: Object,
    goEvent: Function,
    goMarket: Function,
  },
  methods: {
    getEventNumber: function (num) {
      num++;
      var returnValue = String(num);
      if (num < 10) {
        returnValue = "0" + String(num);
      }
      return returnValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.event_list_wrap {
  position: relative;
  margin-top: 20px;
  padding-bottom: 18px;
  overflow: auto;
  > ul {
    // width: 1000px;
    padding: 0 74px 0 20px;
    > li {
      display: inline-block;
      position: relative;
      width: 104px;
      height: 80px;
      margin-left: 10px;
      background: $white 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 0px $nj_shadow_gray;
      border-radius: 20px;
      > a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &:first-child {
        margin-left: 0;
      }

      .event_num {
        // display: inline-block;
        padding-top: 11px;
        @include font(10, normal, -0.6);
        color: #4a9e67;
        text-align: center;
      }
      .event_txt {
        padding: 0 10px;
        @include text_ellipsis($line: 2, $maxHeight: 45);
        @include font(16, medium, -0.96);
        color: $nj_black;
        text-align: center;
      }

      &:nth-child(3n-2) {
        .event_num {
          color: #4a9e67;
        }
      }
      &:nth-child(3n-1) {
        .event_num {
          color: #6c79a9;
        }
      }
      &:nth-child(3n) {
        .event_num {
          color: #a98b6c;
        }
      }
    }
  }
}
.more_wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 82px;
  padding: 10px 0 0 10px;
  background-color: $nj_background_gray;

  .more_txt {
    display: inline-block;
    min-width: 42px;
    padding-bottom: 30px;
    background-image: url("../../assets/images/icon_stop.png");
    background-repeat: no-repeat;
    background-position: 0 bottom;
    color: $nj_black;
    cursor: default;
    @include font(12, 17, -0.72, medium);
    &:hover {
      color: $nj_black;
    }
    &.active {
      background-image: url("../../assets/images/icon_start.png");
      background-repeat: no-repeat;
      background-position: 0 bottom;
      cursor: pointer;
    }
  }
}
.empty_list {
  padding: 20px 74px 0 20px;
  @include font(15, 22, -1.2);
  text-align: center;
  color: $nj_placeholder_gray;
}
</style>
