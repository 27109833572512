<template>
  <div class="cs_item">
    <div class="cs_main" @click="toggle">
      <div class="cs_title">{{ title }}</div>
      <div class="toggle_wrap">
        <a href="javascript:void(0)" :class="{ active: isToggle }"></a>
      </div>
    </div>
    <transition name="slide">
      <div class="cs_detail" v-if="isToggle">
        <div class="content">{{ content }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ServiceCenterItem",
  props: {
    id: Number,
    title: String,
    content: String,
  },
  data: function () {
    return {
      isToggle: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    toggle: function () {
      this.isToggle = !this.isToggle;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.cs_item {
  padding: 15px 0;
  border-bottom: 1px solid $border-type1;

  &:last-child {
    border-bottom: none;
  }

  .cs_main {
    & > * {
      display: inline-block;
      vertical-align: top;
    }

    .cs_title {
      width: calc(100% - 50px);
      @include font(15, 24, -0.5, bold);
      color: $nj_black;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
    }

    .toggle_wrap {
      float: right;
      width: 50px;
      height: 28px;
      text-align: right;

      a {
        display: inline-block;
        width: 16px;
        height: 100%;
        background-image: url("~svg/icon_more_03.svg");
        background-position: center 7px;
        background-repeat: no-repeat;

        &.active {
          transform: rotate(180deg);
          background-position: center 14px;
        }
      }
    }
  }

  .cs_detail {
    padding-top: 10px;
    //padding-bottom: 5px;

    .content {
      @include font(14, 22, -0.5);
      color: $gray-normal;
      white-space: pre-line;
    }
  }
}
</style>
