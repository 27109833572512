var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('TopHeader',{attrs:{"title":_vm.title,"theme":"nj_red"},on:{"goBack":_vm.goBack}}),_c('div',{staticClass:"contents_wrap"},[_vm._m(0),_c('div',{staticClass:"absolute_bottom"},[_c('Btn',{attrs:{"type":"type1 reverse","value":"탈퇴하기"},on:{"btnCallback":_vm.withdraw}})],1)]),_c('Alert',{attrs:{"title":'회원탈퇴',"displayLayer":_vm.displayLayer,"texts":['탈퇴 신청 30일 후 회원탈퇴가 완료되며 <br>쿠폰 포인트 및 모든 데이터가 삭제됩니다.<br>회원탈퇴를 진행하시겠습니까?'],"textClass":[],"btns":[
      {
        type: null,
        btnId: '',
        btnData: {},
        value: '탈퇴취소',
      },
      {
        type: null,
        btnId: 'WITHDRAW',
        btnData: {},
        value: '회원탈퇴',
      },
    ],"showHideLayer":_vm.showHideLayer}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents_area"},[_c('p',[_vm._v("서비스 탈퇴를 하시면 받아 놓으신 혜택(쿠폰, 포인트) 및 모든 데이터가 초기화 되어 사용하실 수 없으니, 신중하게 결정해 주세요.")]),_c('p',{staticClass:"mt_30"},[_vm._v("그 동안 서비스 이용에 진심으로 감사드리며 더 좋은 모습으로 찾아뵐 수 있도록 최선을 다하겠습니다.")])])
}]

export { render, staticRenderFns }