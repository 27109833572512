// import router from '@/router';

const getState = () => {
  return {
    userId: localStorage.getItem("userId"),
    activeTabItem: null,
    likeProduct: null,
    isProgressing: false,
  };
};

const state = getState();

const mutations = {
  callbackRemoveLike: function (state, data) {
    state.likeProduct = state.likeProduct.filter((product) => product.productId != data.productId);
  },
  updateLikeList: function (state, data) {
    state["likeProduct"] = data;
  },
  goBack: function () {
    this._vm.$navigateBack();
  },
  reset: function (state) {
    state.likeProduct = [];
    Object.assign(state, getState());
  },
};

const logError = (response, message) => {
  console.error(
    "Response Code:: " + response.responseCode + "\n" + "Error Message:: " + response.errorObject.errorMessage + "\n" + "Message:: " + message,
  );
};

const actions = {
  callbackRemoveLike: async function (store, data) {
    const productId = data.productId;
    try {
      const wishResponse = await this._vm.$axios1.post("/v3/product/wish", {
        userId: localStorage.getItem("userId"),
        productId: productId,
      });
      if (wishResponse.data.responseCode == 2000) {
        // const _this = this;
        // const modalName = 'like_notice_modal';
        // const compProps = {
        //     title: '찜에서 삭제하였습니다.',
        //     msgArr: ['찜 리스트<br>에서 삭제되었습니다.'],// html 코드 적용 가능
        //     closeModal: function() {
        //         _this.$modal.hide(modalName);
        //     }
        // }
        store.commit("callbackRemoveLike", { productId: productId });
        // // 찜 등록/취소 안내 모달 출력
        // store.dispatch('showModal', ({name: modalName, compProps: compProps, modalProps: null, seconds: 3}), {root: true});
      } else {
        logError(wishResponse.data, "찜 삭제에 실패하였습니다.");
      }
    } catch (e) {
      console.error(e);
    }
  },
  clickTab: function (store, data) {
    store.commit("reset");

    const dataType = data.dataType;
    this.dispatch("like/updateLikeList", data);
    state.tabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });
    state.activeTabItem = dataType;
  },
  updateLikeList: function (store, data) {
    console.log(data);
    if (!state.userId) {
      state.userId = localStorage.getItem("userId");
    }
    state.isProgressing = true;

    let productList = [];

    this._vm.$axios1
      .get("/v3/market/delivery/product/" + state.userId + "/product/type/BD/wish")
      .then((response) => {
        if (response.data.responseCode == 2000) {
          productList.push(...response.data.result.productList);
        } else {
          state.isProgressing = false;
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        state.isProgressing = false;
        return false;
      });

    this._vm.$axios1
      .get("/v3/market/delivery/product/" + state.userId + "/product/type/TB/wish")
      .then((response) => {
        if (response.data.responseCode == 2000) {
          productList.push(...response.data.result.productList);
        } else {
          state.isProgressing = false;
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        state.isProgressing = false;
        return false;
      });

    store.commit("updateLikeList", productList);
    state.isProgressing = false;
  },
  initState: async function (store, boolean) {
    if (!state.activeTabItem || boolean) {
      state.activeTabItem = "BD";
    }

    this.dispatch("like/clickTab", { dataType: state.activeTabItem });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
