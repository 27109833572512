const getState = () => {
  return {
    isProgressing: false,
    title: "추천인 이벤트",
    userId: null,
    marketId: 2,
    // marketName: "광명전통시장",
    // eventTitle: "놀장 친구추천 이벤트",
    // eventDateInfo: "21.10.18~소진시까지",
    rewardPoint: null, //2000,
    recipientPoint: null, //3000,
    eventCode: null,
    eventInfo: null,
    applicationType: null,
  };
};

const state = getState();

const mutations = {
  setAlert: function (msg) {
    const _this = this;
    const modalName = "like_notice_modal";
    const compProps = {
      // title: "주문하기 오류 - 2",
      msgArr: [msg], // html 코드 적용 가능
      // btnArr: [],
      type: "alert",
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
  },
  goKakaoTalk: function () {
    this._vm.$callNative("openKakaoChannel");
  },
  goCall: function (state, phoneNumber) {
    this._vm.$callNative("goCall", { tel: phoneNumber });
  },
  updateState: function (state, { type, updateData }) {
    state[type] = updateData;
    state.isProgressing = false;
  },
  callAuthentication: function () {
    console.log("callAuthentication");
    const _this = this;
    const modalName = "callAuthentication";
    const compProps = {
      title: "휴대전화 본인인증",
      msgArr: ["휴대전화 본인 인증이 필요합니다."], // html 코드 적용 가능
      btnArr: [
        {
          txt: "본인인증하기",
          type: "type1 inverse",
          data: { id: "ok" },
          btnCallback: function () {
            _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
            _this.commit("marketMain/navigateTo", {
              path: "authentication",
            });
          },
        },
      ],
      closeModal: function () {
        // X 버튼 클릭시
        _this._vm.$modal.hideAll();
      },
    };
    this.dispatch(
      "showModal",
      {
        name: modalName,
        compProps: compProps,
        modalProps: {
          width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
          height: "auto",
        },
        // seconds: 3
      },
      { root: true },
    );
  },
  shareAppWithKakao: function () {
    this._vm.$callNative("shareAppWithKakao");
  },
  initState: function (state, data) {
    console.log("data.initData :::::::::::::::::::::: ");
    console.log(data.initData);
    state.userId = data.userId;
    state.marketId = data.params.marketId;
    state.rewardPoint = data.params.rewardPoint;
    state.recipientPoint = data.params.recipientPoint;
    state.eventInfo = data.params;
  },
  goBack: function () {
    this._vm.$navigateBack();
  },
  reset: function (state) {
    Object.assign(state, getState());
  },
};

const actions = {
  changePhoneNumber: function (store, event) {
    event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, "");
  },
  sendPhoneNumber: async function (store) {
    console.log("sendPhoneNumber :::::::::: ");
    const phoneNumber = document.querySelector("#inputPhoneNumber").value;
    console.log(phoneNumber);

    try {
      state.isProgressing = true;

      const recommendValidationUrl =
        "/v3/events/recommends/members/" + state.userId + "/phone/" + document.querySelector("#inputPhoneNumber").value + "/validation";
      const recommendValidation = await this._vm.$axios2.get(recommendValidationUrl);
      console.log(recommendValidation);

      state.isProgressing = false;
      if (recommendValidation.data.responseCode == 2000) {
        console.log(recommendValidation.data);

        try {
          const sendData = {
            userId: state.userId,
            marketId: state.marketId,
            recommendedMemberId: recommendValidation.data.result.userId,
          };
          console.log(sendData);
          const sendRecommend = await this._vm.$axios2.post("/v3/events/recommends/members/", sendData);

          console.log(sendRecommend);
          if (sendRecommend.data.responseCode == 2000) {
            // 추천인 이벤트 등록 완료
            console.log(sendRecommend.data.result.message);
            store.commit("updateState", { type: "eventCode", updateData: 10440 });
          } else {
            console.log(sendRecommend.data);
            console.log(sendRecommend.data.errorObject.errorCode);
            const errorCode = sendRecommend.data.errorObject.errorCode;
            if (errorCode == 10440 || errorCode == 10445) {
              // 10440 : 이미 추천인 이벤트에 참여하셨습니다.
              // 10445 : 이벤트 시작 이후 가입자는 추천받기만 가능합니다.
              console.log("errorCode ::: " + errorCode);
              store.commit("updateState", { type: "eventCode", updateData: errorCode });
            }
          }
        } catch (e) {
          store.commit("setAlert", "시스템 오류가 발생하였습니다.<br/>고객센터(02-2039-6742)에 문의해주세요!!!");
          console.log("시스템 오류가 발생하였습니다. 고객센터로 문의해주세요.");
        }
      } else {
        console.log(recommendValidation.data);
        const eventCode = recommendValidation.data.errorObject.errorCode;
        if (eventCode == 10441 || eventCode == 10442 || eventCode == 10443 || eventCode == 10444) {
          console.log("eventCode ::: " + eventCode);
          store.commit("setAlert", recommendValidation.data.errorObject.errorMessage);
          if (eventCode == 10444) {
            // 예산소진으로 인하여 이벤트가 종료되었습니다.
            store.commit("goBack");
          }
        } else {
          store.commit("setAlert", "시스템 오류가 발생하였습니다.<br/>고객센터(02-2039-6742)에 문의해주세요!");
          console.log("시스템 오류가 발생하였습니다. 고객센터로 문의해주세요.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  initState: async function (store, data) {
    console.log(data);

    // state.isProgressing = true;
    // data.userId = null; // 본인 인증 테스트시 주석 해제

    console.log(state.userId);
    console.log(state.marketId);
    console.log(state.rewardPoint);
    console.log(state.recipientPoint);
    console.log(state.eventInfo);

    if (data.userId) {
      store.commit("updateState", { type: "userId", updateData: data.userId });
    }

    if (data.params) {
      // const marketId = data.params.marketId;
      // store.commit("initState", data);
      store.commit("updateState", { type: "marketId", updateData: data.params.marketId });
      store.commit("updateState", { type: "rewardPoint", updateData: data.params.rewardPoint });
      store.commit("updateState", { type: "recipientPoint", updateData: data.params.recipientPoint });
      store.commit("updateState", { type: "eventInfo", updateData: data.params });

      store.commit("updateState", { type: "applicationType", updateData: data.params.recommendEventApplicationType });
    }

    // state.userId = data.userId;
    // state.marketId = data.params.marketId;
    // state.rewardPoint = data.params.rewardPoint;
    // state.recipientPoint = data.params.recipientPoint;
    // state.eventInfo = data.params;

    if (state.userId && state.marketId) {
      state.isProgressing = true;

      try {
        const eventValidationUrl = "/v3/events/recommends/members/" + data.userId + "/market/" + state.marketId + "/validation";
        console.log(eventValidationUrl);
        const eventValidation = await this._vm.$axios2.get(eventValidationUrl);
        if (eventValidation.data.responseCode == 2000) {
          console.log(eventValidation.data);
          const sendData = {
            type: "eventCode",
            updateData: eventValidation.data.responseCode,
          };
          store.commit("updateState", sendData);
        } else {
          console.log(eventValidation.data);
          const eventCode = eventValidation.data.errorObject.errorCode;

          if (eventCode == 10440 || eventCode == 10445) {
            // 10440 : 이미 추천인 이벤트에 참여하셨습니다.
            // 10445 : 이벤트 시작 이후 가입자는 추천받기만 가능합니다.
            console.log(eventValidation.data);
            const sendData = {
              type: "eventCode",
              updateData: eventCode,
            };
            store.commit("updateState", sendData);
          } else {
            store.commit("setAlert", eventValidation.data.errorObject.errorCode);
            store.commit("goBack");
            // 10450 : 요청하신 시장의 추천인 이벤트가 존재하지 않습니다.
            // 10438 : 이벤트 기간이 종료되었습니다. - 유효하지 않은 추천인 이벤트 기간
            // 10439 : 이벤트 기간이 종료되었습니다. - 삭제된 추천인 이벤트
            logError(eventValidation.data);
          }
        }
      } catch (e) {
        console.log(e);
      }
      state.isProgressing = false;
    }

    // const eventValidationResponse = await this._vm.$axios1.get(
    //     "/v3/market/delivery/product/" +
    //     data.productId +
    //     "/store/products/user/" +
    //     userId +
    //     "/" +
    //     productType
    // );
    // if (otherProductsResponse.data.responseCode == 2000) {
    //   initData["otherProducts"] = otherProductsResponse.data.result;
    //   // initData['otherProducts'].forEach((item) => {
    //   //     item.productThumbnailUrl = item.productImageUrl;
    //   // });
    // } else {
    //   logError(
    //       otherProductsResponse.data,
    //       "이 가게 다른 상품 리스트 조회에 실패하였습니다."
    //   );
    // }

    // store.commit("updateState", {
    //   type: "userId",
    //   updateData: data.userId,
    // });
  },
  goBack: function (store) {
    store.commit("goBack");
  },
};

const getters = {};

const logError = (response, message = null) => {
  console.error(
    "Response Code:: " + response.responseCode + "\n" + "Error Message:: " + response.errorObject.errorMessage + "\n" + "Message:: " + message,
  );
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
