<template>
  <div>
    <LoadingIndicator v-if="isProgressing" />
    <div>
      <TopHeader :title="title" @goBack="goBack" :isCartVisible="false" />
      <div class="contents" :class="{ view: pageMode == 'view' }">
        <div class="contents_area">
          <div class="sub_title">주문한 상품 목록</div>
          <reviewList :reviewMode="true" :orderList="orderList" :pageMode="pageMode" @toggleReview="toggleReview" @toggleLike="toggleLike" />
        </div>
      </div>

      <div class="absolute_bottom" v-if="pageMode == 'write'">
        <Btn type="type5" value="리뷰등록" @btnCallback="registReview" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/new/LoadingIndicator";
import TopHeader from "@/components/new/TopHeader";
import reviewList from "@/components/new/reviewList";
import Btn from "@/components/new/Btn";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Review",
  components: {
    TopHeader,
    reviewList,
    Btn,
    LoadingIndicator,
  },
  computed: {
    ...mapState({
      isProgressing: (state) => state.review.isProgressing,
      title: (state) => state.review.title,
      userId: (state) => state.review.userId,
      orderId: (state) => state.review.orderId,
      orderList: (state) => state.review.orderList,
      pageMode: (state) => state.review.pageMode,
    }),
  },
  methods: {
    ...mapMutations("review", ["moveToApp", "toggleReview", "reset"]),
    ...mapActions("review", ["goBack", "toggleLike", "registReview"]),
  },
  beforeCreate: function () {
    this.$store.dispatch("review/initState", {
      userId: this.$route.params.userId ? this.$route.params.userId : localStorage.getItem("userId"),
      orderId: this.$route.params.orderId,
      pageMode: this.$route.params.pageMode,
    });
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style lang="scss" scoped>
.contents {
  height: calc(100vh - 58px - 80px);
  &.view {
    height: calc(100vh - 58px);
  }
  .contents_area {
    padding: 0 16px;
  }
}
.sub_title {
  margin: 20px 0;
  @include font(16, 18, -0.65, bold);
  color: $black;
}

.absolute_bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 13px 16px;
  background-color: $white;
  border: {
    width: 1px 0 0 0;
    style: solid;
    color: $border-type1;
  }
}
</style>
