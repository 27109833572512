<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <TopHeader class="top_header" title="전체시장" @goBack="goBack" style="position: fixed; top: 0; width: 100%; z-index: 999" />
    <div class="tab_wrap marketTab" style="position: fixed; top: 58px; width: 100%; z-index: 999">
      <TabList :tabItems="tabItems" type="default type5" @click="onTabItemClick($event.dataType)" />
    </div>
    <div class="contents bg_white" style="min-height: 100vh">
      <MarketInfoList
        :directMarkets="marketList"
        :tabType="tabType"
        :moveToMarket="moveToMarket"
        :moveToMarketMap="moveToMarketMap"
        addClass="all_market_list"
      />
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";
import MarketInfoList from "@/components/new/MarketInfoList";
import TabList from "@/components/new/TabList";
import { mapActions, mapState } from "vuex";

export default {
  name: "MarketList",
  props: {},
  data: function () {
    return {
      marketId: null,
      marketName: null,
      marketFullyAddress: null,
      marketIntro: null,
      distance: null,
    };
  },
  components: {
    LoadingIndicator,
    TopHeader,
    MarketInfoList,
    TabList,
  },
  created() {
    this.getMarketList();
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
  methods: {
    ...mapActions("marketList", ["getMarketList", "setContentsType", "reset"]),
    changeCategoryPosition: function (state, tabType) {
      // 활성화 탭 좌표가 화면에 가려질때 대응
      const windowWidth = window.innerWidth;
      // console.log("changeCategoryPosition ::::: ");
      // console.log(state[tabType]);
      var categoryListWrap = document.querySelector(".marketTab");
      // console.log(categoryListWrap);
      if (!categoryListWrap) {
        // 방어코드
        const _this = this;
        setTimeout(function () {
          _this.changeCategoryPosition();
        }, 10);
      } else {
        setTimeout(function () {
          if (categoryListWrap.scrollLeft == 0) {
            // 백버튼으로 진입되었을때 방어코드
            categoryListWrap = document.querySelector(".marketTab");
          }
          const categoryList = categoryListWrap.querySelectorAll("li");
          const categoryListLength = categoryList.length;

          for (var i = 0; i < categoryListLength; i++) {
            if (categoryList[i].querySelector("a")) {
              // console.log(categoryList[i].querySelector('a'));
              if (categoryList[i].querySelector("a").classList.contains("active")) {
                // console.log(windowWidth);
                // console.log(categoryListWrap.scrollLeft);
                // console.log(categoryList[i].offsetLeft);
                // console.log(categoryList[i].offsetWidth);
                if (windowWidth < categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft) {
                  categoryListWrap.scrollLeft =
                    categoryListWrap.scrollLeft +
                    (categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft - windowWidth) +
                    16;
                } else if (categoryListWrap.scrollLeft > categoryList[i].offsetLeft + 16) {
                  categoryListWrap.scrollLeft = categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryList[i].offsetWidth - 16;
                }
              }
            }
          }
        }, 10);
      }
    },
    onTabItemClick: function (dataType) {
      this.setContentsType(dataType);
      this.changeCategoryPosition(dataType);
    },
    goBack: function () {
      this.reset();
      this.$router.push({
        name: "놀장 메인",
      });
    },
    moveToMarket: function (event) {
      // console.log(event.currentTarget.dataset.marketId);
      this.$router.push({
        name: "시장 메인",
        params: {
          marketId: event.currentTarget.dataset.marketId,
          marketName: event.currentTarget.dataset.marketName,
        },
      });
    },
    moveToMarketMap: function (event) {
      // console.log(event.currentTarget.dataset.marketId);
      // console.log(event.currentTarget.classList.contains('none_underline'));
      if (event.currentTarget.classList.contains("none_underline")) {
        this.$showToast("지도 준비중입니다.");
      } else {
        this.$navigateTo("market-map", {
          marketId: event.currentTarget.dataset.marketId,
        });
      }
      // this.$navigateTo('market-map?marketId=' + event.currentTarget.dataset.marketId );
    },
    onBackPressed: function () {
      this.goBack();
    },
  },
  computed: {
    ...mapState({
      marketList: (state) => state.marketList.marketList,
      tabItems: (state) => state.marketList.tabItems,
      tabType: (state) => state.marketList.tabType,
      isProgressing: (state) => state.marketList.isProgressing,
    }),
  },
};
</script>

<style lang="scss" scoped>
.tab_wrap {
  //padding: 0 15px;
  overflow-x: auto;
  background: $white;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  .inline_list.default.type5 {
    height: 42px;
  }
}

.contents_title .icon_more {
  top: 17px !important;
}

.contents_title.type3[data-v-503291c7] {
  padding: 17px 40px 5px 15px;
}

.contents {
  padding: 101px 0 0;
  height: 100%;
  //overflow: unset;
  //height: calc(100vh - 58px - 43px);

  //.market_info_wrap::v-deep {
  //  li {
  //    min-height: 84px;
  //  }
  //}
}
.contents_wrap {
  .contents_title {
    position: relative;
    padding: 17px 15px 0;

    .title {
      @include font(17, 20, 0.37, bold);
      color: $black;
    }

    //.icon_more {
    //  position: absolute;
    //  top: 20px;
    //  right: 17px;
    //}

    .icon_view_map {
      display: inline-block;
      min-height: 20px;
      padding: 0 0 0 16px;
      margin: 0 0 0 15px;
      background-image: url("~svg/icon_view_map.svg");
      background-repeat: no-repeat;
      background-position: 0 3px;
      font-family: "gothic_a1_regular";
      font-size: 15px;
      color: $gray-normal;
    }
  }
}
</style>
