<template>
  <div class="tab_wrap">
    <NoticeTabItem
      v-for="tab in tabList"
      v-bind:key="tab.id"
      :id="tab.id"
      :label="tab.label"
      :isBadge="tab.isBadge"
      @click="$emit('click', tab.id)"
      :currentTabId="currentTabId"
    >
    </NoticeTabItem>
  </div>
</template>

<script>
import NoticeTabItem from "./NoticeTabItem";

export default {
  name: "NoticeTab",
  props: {
    tabList: Array,
    currentTabId: Number,
  },
  data: function () {
    return {};
  },
  components: {
    NoticeTabItem,
  },
  computed: {},
  methods: {
    // setTab: function (tab) {
    //   this.curTab = tab;
    // }
  },
};
</script>

<style lang="scss" scoped>
.tab_wrap {
  width: 100%;
  height: 55px;
  border-top: 1px solid $nj_divider_gray;
  background: #ffffff;
}
</style>
