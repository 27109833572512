<template>
  <div class="list_wrap">
    <span class="coupon_cnt">
      <template v-if="coupons && coupons.length > 0"> 보유쿠폰 {{ coupons.length }}장</template>
    </span>
    <template v-if="coupons && coupons.length > 0">
      <ul class="coupon_list" :class="{ short: isSelectable }">
        <CouponItem
          v-for="coupon in coupons"
          v-bind:key="coupon.couponId"
          :couponId="coupon.couponId"
          :couponName="coupon.couponName"
          :discountPoint="coupon.discountPoint"
          :minOrderPay="coupon.minOrderPay"
          :notice="coupon.notice"
          :enableYn="coupon.enableYn"
          :isSelected="coupon.isSelected"
          :isSelectable="isSelectable"
        />
      </ul>
      <template v-if="isSelectable">
        <Btn
          class="coupon_btn"
          :type="selectedCoupon ? '' : 'type1 reverse'"
          :value="selectedCoupon ? '쿠폰적용' : '적용안함'"
          @btnCallback="applyCoupon"
        />
      </template>
    </template>
    <template v-else>
      <div class="no_coupon_msg">보유하신 쿠폰이 없습니다.</div>
    </template>
  </div>
</template>

<script>
import CouponItem from "@/page/Coupon/Components/CouponItem";
import Btn from "@/components/Btn";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CouponList",
  components: { CouponItem, Btn },
  props: {
    coupons: {
      type: Array,
      default: new Array(),
    },
    isSelectable: Boolean,
  },
  methods: {
    ...mapActions("coupon", ["applyCoupon"]),
  },
  computed: {
    ...mapGetters({
      selectedCoupon: "coupon/selectedCoupon",
    }),
  },
};
</script>

<style lang="scss" scoped>
.list_wrap {
  height: calc(100% - 55px);
  padding: 0 20px;

  .coupon_cnt {
    display: block;
    //padding: 0 20px 20px 20px;
    //margin: 0 20px;
    height: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $nj_divider_gray;
    @include font(14, 20, -0.84);
    color: $nj_text_main_gray;
    //margin-top: 10px;
  }

  .no_coupon_msg {
    padding: 100px 20px 20px 20px;
    @include font(16, 24, -0.96);
    color: $nj_text_main_gray;
    text-align: center;
  }

  .coupon_list {
    max-height: calc(100% - 70px);
    padding: 20px 0;
    margin: 0;
    overflow-y: auto;

    &.short {
      max-height: calc(100% - 170px);
    }
  }

  .coupon_btn {
    width: calc(100% - 40px);
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}
</style>
