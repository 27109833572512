<template>
  <div>
    <div class="main_header_wrap">
      <div class="main_header">
        <h1 style="font-size: 16px">놀장</h1>
        <div class="absolute_right">
          <ul class="inline_list">
            <li>
              <router-link to="/delivery-address">배송지설정</router-link>
            </li>
            <li><a href="javascript:void(0);">검색</a></li>
            <li><router-link to="/cart">장바구니</router-link></li>
          </ul>
        </div>
      </div>

      <div class="tab_wrap">
        <TabList :tabItems="topMenuItems" />
      </div>
    </div>

    <div class="main_contents">
      <div class="store_area" style="padding: 20px 10px">
        <div class="store_list type1 horizontal_list">
          <StoreList :stores="dummyStores" />
        </div>
        <div class="store_list type1 vertical_list">
          <StoreList :stores="dummyStores" />
        </div>
      </div>
    </div>

    <div class="bottom_menu_wrap">
      <ul class="inline_list">
        <li><a href="javascript:void(0);">전체메뉴</a></li>
        <li><a href="javascript:void(0);">검색</a></li>
        <li><router-link to="/">홈</router-link></li>
        <li><router-link to="/cart">장바구니</router-link></li>
        <li><router-link to="/my-page-new">마이페이지</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
import StoreList from "@/components/new/StoreList";
import { mapState } from "vuex";
import TabList from "@/components/new/TabList";
export default {
  name: "Dummy",
  components: { TabList, StoreList },
  data: function () {
    return {
      dummyStores: [
        {
          marketId: 1,
          marketName: "Test 시장",
          storeId: 1,
          storeName: "Test 상점",
          storePr: "상점 설명",
          regularYn: "N",
          favoriteCount: 25,
          storeOpenYn: "N",
        },
        {
          marketId: 2,
          marketName: "Test 시장2",
          storeId: 2,
          storeName: "Test 상점2",
          storePr: "상점 설명2",
          regularYn: "Y",
          favoriteCount: 25,
          storeOpenYn: "Y",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      topMenuItems: (state) => state.main.topMenuItems,
    }),
  },
};
</script>

<style lang="scss" scoped>
.main_header_wrap {
  position: relative;
  height: 100px;
  .main_header {
    height: 58px;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    .absolute_right {
      top: 10px;
      right: 15px;
    }
  }
}

.main_contents {
  position: absolute;
  top: 100px;
  bottom: 80px;
  width: 100%;
  padding: 0 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
}
.main_contents_title {
  position: relative;
  padding: 0 0 0 15px;

  &.type1 {
    padding: 0 0 0 100px;
    .title {
      position: absolute;
      top: 0;
      left: 15px;
    }
  }
  .absolute_right {
    right: 15px;
  }
}
</style>
