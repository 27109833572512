<template>
  <div>
    <TopHeader title="보유쿠폰" :isCartVisible="false" @goBack="goBack" />
    <div class="contents">
      <LoadingIndicator v-if="isProgressing" />
      <div class="coupon_input_wrap">
        <input placeholder="쿠폰 일련번호를 입력해 주세요." @input="couponNumber = $event.target.value" :value="couponNumber" />
        <a class="coupon_add_btn" href="javascript:void(0)" @click="addCouponClick">등록</a>
      </div>
      <div class="coupon_tab_wrap">
        <span class="title">보유쿠폰</span>
        <TabList class="coupon_tab" :tabItems="couponTabItems" @click="setContentsType({ dataType: $event.dataType })" />
        <div class="coupon_tab_content" :class="{ short: selectedCoupon }">
          <div class="coupon_list vertical">
            <ul>
              <template v-if="contentsType == 'enable'">
                <li
                  v-for="coupon in usableCoupons"
                  :key="coupon.couponId"
                  class="coupon_item"
                  :class="[contentsType, selectedCoupon && coupon.couponId == selectedCoupon.couponId ? 'active' : '']"
                >
                  <a href="javascript:void(0)" @click="isSelectable ? selectCoupon(coupon) : ''">
                    <div class="coupon_info">
                      <span class="coupon_price_wrap">
                        <span class="coupon_price">{{ $formatPrice(coupon.discount) }}</span>
                        <span class="coupon_price_txt">원</span>
                      </span>
                      <span class="coupon_name">{{ coupon.couponName }}</span>
                      <span class="coupon_notice_msg">예산 소진 시에 조기 종료될 수 있습니다.</span>
                      <span class="coupon_notice_detail">{{ $formatPrice(coupon.minProductsPrice) }}원 이상 구매 시 사용 가능합니다.</span>
                    </div>
                    <div class="coupon_is_usable">
                      <span class="coupon_icon" />
                      <span>COUPON</span>
                      <span>사용가능</span>
                    </div>
                  </a>
                </li>
              </template>
              <template v-else-if="contentsType == 'disable'">
                <li v-for="coupon in unusableCoupons" :key="coupon.couponId" class="coupon_item" :class="contentsType">
                  <a href="javascript:void(0)">
                    <div class="coupon_info">
                      <span class="coupon_price_wrap">
                        <span class="coupon_price">{{ $formatPrice(coupon.discount) }}</span>
                        <span class="coupon_price_txt">원</span>
                      </span>
                      <span class="coupon_name">{{ coupon.couponName }}</span>
                      <span class="coupon_notice_msg">사용이 완료된 쿠폰입니다.</span>
                      <span class="coupon_notice_detail">3일 뒤에 자동 삭제 됩니다.</span>
                    </div>
                    <div class="coupon_is_usable">
                      <span class="coupon_icon" />
                      <span>COUPON</span>
                      <span>사용불가</span>
                    </div>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <template v-if="contentsType == 'enable' && marketId">
            <div class="coupon_btn_wrap">
              <Btn class="coupon_btn" type="type4" @btnCallback="applyCoupon">
                <template v-if="selectedCoupon"> 쿠폰 적용하기 </template>
                <template v-else> 쿠폰 적용 안 함 </template>
              </Btn>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import TabList from "@/components/new/TabList";
import { mapActions, mapState } from "vuex";
import LoadingIndicator from "@/components/LoadingIndicator";
import Btn from "@/components/new/Btn";
export default {
  name: "Coupon",
  props: {},
  data: function () {
    return {
      couponNumber: "",
    };
  },
  components: {
    Btn,
    LoadingIndicator,
    TabList,
    TopHeader,
  },
  beforeCreate() {
    // 실 운영 코드
    const params = {
      userId: localStorage.getItem("userId"),
      marketId: this.$route.params.marketId,
      couponId: this.$route.params.couponId,
      totalPrice: this.$route.params.totalPrice,
    };

    this.$store.dispatch("coupon/initState", params);
  },
  mounted() {
    window.onBackPressed = this.goBack;
  },
  methods: {
    ...mapActions("coupon", ["setContentsType", "selectCoupon", "addCoupon", "goBack", "applyCoupon"]),
    async addCouponClick() {
      const result = await this.addCoupon(this.couponNumber);
      if (result) {
        this.couponNumber = "";
        await this.$store.dispatch("coupon/getCouponList");
      }
    },
  },
  computed: {
    ...mapState({
      title: (state) => state.coupon.title,
      isProgressing: (state) => state.coupon.isProgressing,
      couponTabItems: (state) => state.coupon.couponTabItems,
      usableCoupons: (state) => state.coupon.usableCoupons,
      unusableCoupons: (state) => state.coupon.unusableCoupons,
      contentsType: (state) => state.coupon.contentsType,
      marketId: (state) => state.coupon.marketId,
      selectedCoupon: (state) => state.coupon.selectedCoupon,
    }),
    isSelectable: function () {
      return this.marketId ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  .coupon_input_wrap {
    position: relative;
    width: 100%;
    padding: 20px 16px;
    background-color: $background-color-type1;
    border: {
      width: 0 0 1px 0;
      style: solid;
      color: $border-type2;
    }

    > input {
      width: 100%;
      height: 44px;
      padding: 14px 20px;
      border: none;
      @include border-radius(15px);

      &,
      &::placeholder {
        @include font(14, 16);
      }

      &::placeholder {
        color: $placeholder-color;
      }
    }

    > .coupon_add_btn {
      position: absolute;
      top: 50%;
      right: 36px;
      transform: translateY(-50%);
      padding: 0 0 0 20px;
      border: {
        width: 0 0 0 1px;
        style: solid;
        color: $border-type2;
      }
      color: $active-color;
      @include font(14, normal, normal, bold);
    }
  }

  .coupon_tab_wrap {
    width: 100%;
    height: calc(100% - 85px);

    .title {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 16px;
      @include font(16, normal, normal, bold);
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }
    }

    .coupon_tab::v-deep {
      width: 100%;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }

      > li {
        width: 50%;
        margin: 0;
        text-align: center;

        > a {
          margin: 0 16px;
          padding: 0;
          font-size: Min(4vw, 17px);

          &:not(.active) {
            color: $gray;
          }
        }
      }
    }

    .coupon_tab_content {
      height: calc(100% - 103px);
      overflow-y: scroll;
      padding: 20px 16px 100px;

      &.short {
        padding: 20px 16px 0 16px;
        height: calc(100% - 183px);
      }

      .coupon_list {
        .coupon_item {
          width: 100%;
          margin: 20px 0 0 0;

          &:first-child {
            margin: 0;
          }
          //height: 130px;

          > a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            .coupon_info {
              width: calc(100% - 110px);
              height: 100%;
              padding: 20px;
              background-color: $background-color-type2;
              border-radius: 15px 0 0 15px;
              border: {
                width: 1px 0 1px 1px;
                style: solid;
                color: $border-type2;
              }

              > span {
                display: block;

                &.coupon_price_wrap {
                  display: flex;
                  align-items: center;
                  color: black;

                  > .coupon_price {
                    @include font(22, 22, normal, bold);
                    font-family: gothic_a1_extrabold;
                  }

                  > .coupon_price_txt {
                    @include font(19, 19);
                  }
                }

                &.coupon_name {
                  margin-top: 6px;
                  color: black;
                  @include font(16, 22, normal, bold);
                  word-break: break-all;
                }

                &.coupon_notice_msg {
                  margin-top: 14px;
                  @include font(13, 17, -0.52);
                  color: $gray;
                }

                &.coupon_notice_detail {
                  margin-top: 9px;
                  @include font(12, 16, -0.48);
                  color: $gray-light;
                }
              }
            }

            .coupon_is_usable {
              position: absolute;
              top: 0;
              right: 0;
              padding: 20px 0 0 20px;
              width: 110px;
              height: 100%;
              border-radius: 0 15px 15px 0;
              color: white;

              > span {
                display: block;
                @include font(12, 13.5, normal, bold);

                &:last-child {
                  margin-top: 4px;
                }
              }

              .coupon_icon {
                display: block;
                width: 26px;
                height: 14px;
                margin: 0 0 7px 0;
                background: {
                  image: url("~svg/icon_coupon.svg");
                  repeat: no-repeat;
                }
              }
            }
          }

          &.active {
            .coupon_info {
              border: {
                color: $active-color;
              }
            }
          }

          &.enable {
            .coupon_is_usable {
              background-color: $active-color;
            }
          }

          &.disable {
            .coupon_info {
              span {
                color: $gray-light !important;
              }
            }
            .coupon_is_usable {
              background-color: $background-color-type3;
            }
          }
        }
      }

      .coupon_btn_wrap {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80px;
        padding: 13px 16px;
        background-color: #fff;
        border: {
          style: solid;
          color: #dedede;
          width: 1px 0 0;
        }

        > a {
          width: 100%;
          //height: 54px;
          //@include border-radius(10px);
          @include font(16, 54, normal, bold);
          text-align: center;
        }
      }
    }
  }
}
</style>
