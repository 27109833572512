var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('TopHeader',{attrs:{"title":"장바구니","theme":"nj_white","isButton":true},on:{"goBack":_vm.goBack}}),_c('div',{staticClass:"content"},[(_vm.products && _vm.products.length > 0)?[_c('AddressInfo'),_c('ProductList',{attrs:{"marketId":_vm.marketId,"products":_vm.products}}),_c('DeliveryInfo',{on:{"select":_vm.setReceiveWay,"input":_vm.setDeliveryRequest}}),_c('div',{staticClass:"total_price_wrap"},[_c('div',{staticClass:"price_wrap total_wrap"},[_c('div',{staticClass:"title"},[_vm._v("총 상품금액")]),_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.$formatPrice(_vm.totalPrice))+"원")])])]),_c('div',{staticClass:"btn_wrap"},[_c('div',{staticClass:"btn_purchase",on:{"click":_vm.goPayment}},[_vm._v("결제하기")])])]:_c('div',{staticClass:"no_product_msg"},[_vm._v("장바구니에 담긴 상품이 없습니다.")])],2),_c('Layer',{attrs:{"displayLayer":_vm.displayLayer,"displayType":"center","closeLayer":_vm.hideLayer},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"layer_contents"},[_c('div',{staticClass:"layer_title"},[_vm._v("상품 삭제")]),_c('p',{staticClass:"layer_txt"},[_vm._v("해당 상품을 삭제하시겠습니까 ?")])]),_c('ul',{staticClass:"half_list type1 mt_10"},[_c('li',[_c('Btn',{attrs:{"type":"type1 none_border","value":"취소"},on:{"btnCallback":_vm.hideLayer}})],1),_c('li',[_c('Btn',{attrs:{"type":"type1 reverse","btnId":"DEL_PRODUCT","value":"삭제하기"},on:{"btnCallback":_vm.deleteProduct}})],1)])]},proxy:true}])}),_c('Alert',{attrs:{"displayLayer":_vm.displayAlertLayer,"texts":[_vm.alertText],"textClass":[],"btns":[
      {
        type: null,
        btnId: '',
        btnData: {},
        value: '확인',
      },
    ],"showHideLayer":_vm.showHideAlertLayer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }