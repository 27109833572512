const state = {
  title: "주문문의",
  orderId: null,
  userId: "",
  orderList: [],
  guideTxt: "",
  displayGuideLayer: false,
  imageFiles: [],
  hasImageFiles: [],
  displayLayer: false,
  isIos: false,
};

const mutations = {
  // moveToApp: function (state, data) {
  //     console.log(data);
  //     this._vm.$callNative('moveToOrder', {orderId: data.orderId});
  // },
  showHideLayer: function (state, data = null) {
    if (data) {
      state.displayLayer = !state.displayLayer;
    }
  },
  inquiryIncreaseDecrease: function (state, data) {
    const { type, orderProductId, quantity } = data;
    if (type == "DECREASE") {
      if (quantity > 0) {
        state.orderList = state.orderList.map((order) => {
          if (order.orderProductId == orderProductId) {
            order.quantity--;
          }
          return order;
        });
      }
    } else if (type == "INCREASE") {
      state.orderList = state.orderList.map((order) => {
        if (order.orderProductId == orderProductId) {
          if (order.orderQty > quantity) {
            order.quantity++;
          }
        }
        return order;
      });
    }
  },
  showHideGuideLayer: function (state) {
    state.displayGuideLayer = !state.displayGuideLayer;
  },
  initState: function (state, data) {
    state["orderList"] = data.orderList;
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },
};

const actions = {
  onChangeFileUpload: function (store, data) {
    // console.log('onChangeFileUpload :::::::::::::::::::::::::::::::: ---------------- inquiryInput');
    // console.log(document.querySelectorAll('input[type=file].inquiryInput').length);
    // console.log(document.querySelectorAll('input[type=file].inquiryInput')[0].value);
    // console.log(document.querySelectorAll('input[type=file].inquiryInput')[1].value);
    state.hasImageFiles = [];
    var targetIndex = null;
    const ul = data.event.currentTarget.closest("ul");
    state.orderList.map((item, index) => {
      // console.log(':::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::');
      // console.log(ul.children[index].querySelector('input[type=file].inquiryInput').value);
      state.hasImageFiles.push(ul.children[index].querySelector("input[type=file].inquiryInput").value ? true : false);
      if (item.orderProductId == data.orderProductId) {
        targetIndex = index;
      }
    });

    // console.log(state.hasImageFiles);

    var file = data.event.target.files;
    if (file.length) {
      // let url = URL.createObjectURL(file[0]);
      state.imageFiles[targetIndex] = file[0];
    } else {
      state.imageFiles.splice(targetIndex, 1);
      // state.imageFiles[targetIndex] = null;
    }
    // console.log(state.imageFiles);
  },
  registInquiry: function (store, data) {
    // console.log('----------------------------------');
    // console.log(state);
    // console.log('state.imageFiles');
    // console.log(data);

    const list = data.data.ref;
    const nodeList = list.querySelectorAll(".contenteditable");
    const nodeLength = nodeList.length;

    var sendData = {
      orderId: state.orderId,
      userId: state.userId,
      productIds: [],
      orderProductIds: [],
      quantities: [],
      messages: [],
      imageFiles: [],
    };

    var emptyInput = null;
    for (var i = 0; i < nodeLength; i++) {
      const node = nodeList[i];
      const listContainer = nodeList[i].closest("li");
      // console.log('count ::::::::::::: ' + i);
      // console.log(listContainer.querySelectorAll('input[type=file].inquiryInput').value);
      // console.log(node.closest('.targetContainer').style);

      if (!node.innerText && node.closest(".targetContainer").style.display != "none") {
        // input 창이 노출된 상태고 텍스트가 없을때
        // console.log('메세지 없음');
        emptyInput = node;
        break;
      }
      sendData.orderProductIds.push(node.dataset.orderProductId);
      sendData.productIds.push(node.dataset.productId);
      sendData.messages.push(node.innerText);
      sendData.quantities.push(Number(listContainer.querySelector(".quantity_txt").innerText));
      // sendData.imageFiles.push('');// 웹뷰에서 이미지 등록은 사용하지 않아 빈값으로 보내는 것으로 협의 되었음
    }

    if (emptyInput) {
      // console.log('showHideGuideLayer ::: ');
      state.guideTxt = "상품 문의 사유를 입력해주세요.";
      store.commit("showHideGuideLayer");
      emptyInput.focus();
    } else {
      var inquiryCount = 0;
      var orderLength = sendData.quantities.length;
      for (i = 0; i < orderLength; i++) {
        inquiryCount = inquiryCount + sendData.quantities[i];
      }
      if (inquiryCount == 0) {
        state.guideTxt = "상품 문의 개수를 등록해주세요.";
        store.commit("showHideGuideLayer");
        return;
      }

      const formdata = new FormData();
      formdata.append("orderId", sendData.orderId);
      formdata.append("userId", sendData.userId);
      formdata.append("orderProductIds", sendData.orderProductIds);
      formdata.append("productIds", sendData.productIds);
      formdata.append("messages", sendData.messages);
      formdata.append("quantities", sendData.quantities);
      formdata.append("hasImageFiles", state.hasImageFiles);

      const uploadImageLength = state.imageFiles.length;
      if (uploadImageLength) {
        for (i = 0; i < uploadImageLength; i++) {
          formdata.append("imageFiles", state.imageFiles[i]);
        }
      } else {
        console.log("등록된 이미지가 없음");
      }
      // console.log(state.hasImageFiles);
      // console.log(state.imageFiles);

      state.displayLayer = true;

      const axiosUrl = "/api2/delivery/order/inquiry";
      this._vm.$axiosLegacy
        .post(axiosUrl, formdata, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log(formdata);
          // console.log(response.data);
          state.displayLayer = false;
          if (response.data.responseCode == 2000) {
            this._vm.$closeView();
          } else {
            state.guideTxt = response.data.errorObject.errorMessage;
            store.commit("showHideGuideLayer");
          }
          this._vm.isProgressing = false;
        })
        .catch(() => {
          this._vm.isProgressing = false;
        });
    }
  },
  initState: function (store, data) {
    state.userId = data.userId;
    state.orderId = data.orderId;
    state.isIos = this._vm.$isIos();

    const axiosUrl = "/api2/delivery/order?orderId=" + state.orderId;
    this._vm.$axiosLegacy
      .get(axiosUrl)
      .then((response) => {
        // console.log(response.data);
        if (response.data.responseCode == 2000) {
          // console.log(response.data);
          const orderProductInfos = response.data.orderProductInfos.map((order) => {
            order["quantity"] = 0;
            return order;
          });
          store.commit("initState", { orderList: orderProductInfos });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },

  myAction({ getters }) {
    let user = getters.getUserId;
  },
};

const getters = {
  getUserId(state) {
    return state.userId;
  },
  getOrderId(state) {
    return state.orderId;
  },
  getImageFiles(state) {
    return state.imageFiles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
