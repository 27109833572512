<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" />
    <div class="contents">
      <div class="guide_txt">
        <p class="txt1">
          놀장은 통신판매중개자로 통신판매의 당사자가 아닙니다. 상품상태에 대한 이상, 사진, 설명과 다른 상품 등에 대한 문의는 앱문의가 아닌 점주님께
          직접 문의해 주셔야 됩니다.
        </p>
        <p class="txt2">
          점주님 연락처는 상품 선택 후 나오는 상품 정보 하단에서 확인 가능합니다.(구매하신 상품이 검색되지 않을 경우 점포명으로 검색 후 상품 선택
          부탁드립니다.)
        </p>
      </div>

      <div class="contents_area">
        <div class="contents_title">주문한 상품 목록</div>
        <div class="inquiry_list_wrap" ref="inquiryList">
          <InquiryList
            :inquiryList="orderList"
            pageMode="write"
            @inquiryIncreaseDecrease="inquiryIncreaseDecrease"
            @onChangeFileUpload="onChangeFileUpload"
            :isIos="isIos"
          />
        </div>
      </div>

      <div class="absolute_bottom">
        <Btn type="type1 reverse" value="상품 문의 요청하기" @btnCallback="registInquiry" :data="{ ref: this.$refs.inquiryList }" />
      </div>
    </div>

    <Alert
      :displayLayer="displayGuideLayer"
      :texts="[guideTxt]"
      :textClass="[]"
      :btns="[
        {
          value: '확인',
        },
      ]"
      :showHideLayer="showHideGuideLayer"
    />

    <!-- 레이어 팝업 -->
    <Layer :displayLayer="displayLayer" :closeLayer="showHideLayer" displayType="result">
      <template v-slot:content>
        <div class="layer_txt">처리중 입니다...</div>
      </template>
    </Layer>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import InquiryList from "@/components/list/InquiryList";
import Btn from "@/components/Btn";
import Alert from "@/components/Alert";
import Layer from "@/components/Layer";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ProductInquiryWrite",
  components: {
    TopHeader,
    InquiryList,
    Btn,
    Alert,
    Layer,
  },
  computed: {
    ...mapState({
      title: (state) => state.productInquiryWrite.title,
      orderId: (state) => state.productInquiryWrite.orderId,
      orderList: (state) => state.productInquiryWrite.orderList,
      displayGuideLayer: (state) => state.productInquiryWrite.displayGuideLayer,
      guideTxt: (state) => state.productInquiryWrite.guideTxt,
      displayLayer: (state) => state.productInquiryWrite.displayLayer,
      isIos: (state) => state.productInquiryWrite.isIos,
    }),
  },
  methods: {
    ...mapMutations("productInquiryWrite", ["inquiryIncreaseDecrease", "showHideGuideLayer", "showHideLayer"]),
    ...mapActions("productInquiryWrite", ["goBack", "registInquiry", "onChangeFileUpload"]),
  },
  beforeCreate: function () {
    // console.log(typeof this.$route.query.inquiryId);
    this.$store.dispatch("productInquiryWrite/initState", {
      userId: this.$route.query.userId,
      orderId: this.$route.query.orderId,
    });
  },
};
</script>

<style lang="scss" scoped>
.contents {
  height: calc(100vh - 44px);
  padding: 0 0 20px;
  background: $nj_background_gray;
  overflow-y: auto;
}
.contents_area {
  padding: 23px 20px 20px;
}
.contents_title {
  @include font(18, 22, -1.08);
  color: $nj_black;
}
.guide_txt {
  padding: 20px;
  background-color: $nj_button_gray;

  .txt1 {
    margin: 0;
    @include font(16, 24, -0.96);
    color: $nj_black;
  }
  .txt2 {
    margin: 12px 0 0;
    @include font(14, 20, -0.28);
    color: $nj_text_main_gray;
  }
}
.absolute_bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}
.inquiry_list_wrap {
  margin: 12px 0 0;
  padding: 0 0 60px;
}
</style>
