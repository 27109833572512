import router from "@/router";

const state = {
  isLoading: false,
  // Static Data
  title: "상품 정보",
  swiperOptions: {
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    autoplay: {
      delay: 5000,
    },
    slidesPerView: "auto",
    touchRatio: 0.2,
    slideToClickedSlide: true,
  },

  // Dynamic Data
  isModalOpen: false,
  isProgressing: false,
  productId: null,
  userId: null,
  product: {},
  otherProducts: [],
  recentProducts: [],
  regularInfo: {},
  tabItems: [
    {
      text: "상세정보",
      isActive: true,
      dataType: "detail",
    },
    {
      text: "필수표기정보",
      isActive: false,
      dataType: "information",
    },
  ],
  contentsType: "detail",
};

const mutations = {
  initState: function (state, data) {
    state.productId = data.productId;
    state.product = data.product;
    state.otherProducts = data.otherProducts;
    state.recentProducts = data.recentProducts;
    state.userId = data.userId;
    if (data.regularInfo) {
      state.regularInfo = data.regularInfo;
    }

    state.isLoading = true;
  },
  updateState: function (state, { type, updateData }) {
    state[type] = updateData;
  },
  moveToStore: function (state) {
    var regularYn = "N";
    if (state.regularInfo) {
      if (state.regularInfo.regularYn == "Y") {
        regularYn = "Y";
      }
    }
    const params = {
      marketId: state.product.marketId,
      marketName: state.product.marketName,
      storeId: state.product.storeId,
      storeName: state.product.storeName,
      regularYn: regularYn,
    };
    router.push({ name: "상점 메인", params: params });
  },
  navigateToProductOption: function (state, appVersion) {
    const passData = {
      productId: state.product.productId,
    };
    router.push({
      name: "상품옵션",
      params: {
        productId: state.productId,
        userId: state.userId,
      },
    });
    /*
    if (appVersion) {
      // 소비자앱 Flutter 적용 시
      router.push({ name: "상품옵션", params: {
        productId: state.productId,
        userId: state.userId,
      } });
    }
    else {
      this._vm.$navigateTo("product-option", passData);
    }
    */
  },
  goBack: function () {
    this._vm.$navigateBack();
  },
  reset: function (state) {
    state.productId = null;
    state.userId = null;
    state.product = {};
    state.otherProducts = [];
    state.recentProducts = [];
    state.regularInfo = {};
    state.descriptionImageUrlYn = null;
    state.descriptionImageUrl = null;
    state.contentsType = "detail";
    state.tabItems = [
      {
        text: "상세정보",
        isActive: true,
        dataType: "detail",
      },
      {
        text: "필수표기정보",
        isActive: false,
        dataType: "information",
      },
    ];
  },
  setContentsType: function (state, data) {
    // 상단 탭 메뉴 클릭
    const dataType = data.dataType;
    state.tabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });
    state["contentsType"] = dataType;
  },
  setIsModalOpen: function (state, isOpen) {
    state.isModalOpen = isOpen;
  },
  setIsProgressing: function (state, isProgressing) {
    state.isProgressing = isProgressing;
  },
  toggleProductLike: function (state) {
    if (state.product.wishProductYn == "Y") {
      state.product.productLikeCount--;
    } else {
      state.product.productLikeCount++;
    }
    state.product.wishProductYn = state.product.wishProductYn == "Y" ? "N" : "Y";
  },
};

const actions = {
  initState: async function (store, data) {
    store.commit("updateState", { type: "isLoading", updateData: false });

    let initData = {
      productId: data.productId,
      product: {},
      otherProducts: [],
      recentProducts: [],
      userId: data.userId,
    };

    if (!initData.productId) {
      return false;
    }

    store.commit("setIsProgressing", true);

    try {
      // 상품 정보 조회
      const productUrl = "/v3/market/delivery/product/" + initData.productId + "/user/" + (initData.userId ? initData.userId : "null");
      const productResponse = await this._vm.$axios1.get(productUrl);
      if (productResponse.data.responseCode == 2000) {
        let product = productResponse.data.result;
        if (product.notationalInformationList) {
          product.notationalInformationList = product.notationalInformationList.filter((item) => {
            return item.title || item.content;
          });
        }

        product.productTypes = [product.productType];

        if (product.serviceQuickYn == "Y") {
          product.productTypes.push("FD");
        }

        if (product.deliveryMethod === "TB") {
          try {
            const productMemberUrl = `/v3/product/tb/members/markets/${product.marketId}/stores/${product.storeId}/products/${product.productId}`;
            const productMemberResponse = await this._vm.$axios2.get(productMemberUrl);
            if (productMemberResponse.data.responseCode === 2000) {
              const productMember = productMemberResponse.data.result;
              product = { ...product, ...productMember };
            }
          } catch (e) {
            console.error(e);
          }
        }
        initData["product"] = product;
        // 내가 본 상품 등록
        this._vm.$setRecentProduct(initData["product"].marketId, initData["product"].productId);
      } else {
        logError(productResponse.data, "상품 정보 조회에 실패하였습니다.");
      }

      // 이 가게 다른 상품 조회
      const productType = initData["product"].productType.toLowerCase();
      let apiUrl = "";

      // 택배 상점
      if (productType === "tb") {
        apiUrl = `/v3/market/delivery/product/list/market/${initData.product.marketId}/store/${initData.product.storeId}/tb`;
      }
      // 배달 상점
      else {
        apiUrl = `/v3/market/delivery/product/list/market/${initData.product.marketId}/store/${initData.product.storeId}`;
      }

      const otherProductsResponse = await this._vm.$axios1.get(apiUrl);
      if (otherProductsResponse.data.responseCode == 2000) {
        const result = otherProductsResponse.data.result;

        initData["otherProducts"] = result
          .map((res) => {
            return {
              productId: res.productId,
              productName: res.productNm,
              marketId: res.marketId,
              marketName: res.marketNm,
              storeId: res.storeId,
              storeName: res.storeNm,
              productImageUrl: res.productThumbnail,
              price: res.productPrice,
              discountPrice: res.productDiscountPrice,
              unitName: res.productSaleUnitNm,
              unitNumber: res.productSaleUnit,
              atYn: res.productAtYn,
              deliveryMethod: res.deliveryMethod,
              productSaleYn: res.productSaleYn,
              productLikeCount: res.productLikeCount,
              paymentTypeList: res.paymentTypeList,
              deliveryFeeCondition: res.tbDeliveryFeeCondition,
              deliveryFee: res.tbDeliveryFee,
              openStoreYn: res.openStoreYn,
            };
          })
          .filter((e) => e.productId !== initData.product.productId);
      } else {
        logError(otherProductsResponse.data, "이 가게 다른 상품 리스트 조회에 실패하였습니다.");
      }

      // 단골 여부 정보 조회
      if (initData.userId) {
        const regularInfo = await this._vm.$axios1.get(
          "/v3/market/delivery/store/product/" + initData["product"].productId + "/regular?userId=" + initData.userId,
        );
        if (regularInfo.data.responseCode == 2000) {
          initData["regularInfo"] = regularInfo.data.result;
        } else {
          logError(regularInfo.data, "단골 정보 조회에 실패하였습니다.");
        }
      }
    } catch (e) {
      console.error(e);
    }
    store.commit("setIsProgressing", false);
    store.commit("initState", initData);
  },
  reset: function (store) {
    store.commit("reset");
  },
  goBack: function (store) {
    store.commit("goBack");
  },
  share: function (store) {
    const shareInfo = {
      productId: String(state.product.productId),
      productName:
        state.product.productName + " " + this._vm.$formatWeight(state.product.weight) + " " + state.product.unitNumber + state.product.unitName,
      productDesc: state.product.storeName + "•" + state.product.marketName,
      productImage: state.product.productImageUrl,
      urlLink: location.href + "?shard=true",
      regularPrice: state.product.discountPrice,
      discountPrice: state.product.price,
      discountRate: state.product.discountPrice
        ? this._vm.$formatDiscountPercent(state.product.price, state.product.discountPrice).replace("%", "")
        : null,
    };
    this._vm.$callNative("shareAppWithKakao", { shareInfo: JSON.stringify(shareInfo) });
  },
  clickCategory: function (store, data) {
    // 바로배달에서 탭 클릭
    store.commit("clickCategory", data);
  },
  setContentsType: function (store, data) {
    // 상단 탭 메뉴 클릭
    store.commit("setContentsType", data);
  },
  toggleProductLike: async function (store) {
    const _this = this;
    const userId = localStorage.getItem("userId");

    if (!userId) {
      const modalName = "callAuthentication";
      const compProps = {
        title: "휴대전화 본인인증",
        msgArr: ["휴대전화 본인 인증이 필요합니다."], // html 코드 적용 가능
        btnArr: [
          {
            txt: "본인 인증 하기",
            type: "type1 inverse",
            data: { id: "ok" },
            btnCallback: function () {
              store.commit("setIsModalOpen", false);
              _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
              _this.commit("store/navigateTo", { path: "authentication" });
            },
          },
        ],
        closeModal: function () {
          // X 버튼 클릭시
          // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
          store.commit("setIsModalOpen", false);
          _this._vm.$modal.hideAll();
        },
      };

      store.commit("setIsModalOpen", true);
      store.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
          // seconds: 3
        },
        { root: true },
      );

      return;

      // const result = await this._vm.$confirm('휴대전화 본인인증', ['휴대전화 본인 인증이 필요합니다.']);
      // if (result) {
      //     this._vm.$navigateTo('authentication');
      // }
      // return false;
    }

    store.commit("setIsProgressing", true);

    try {
      const wishResponse = await this._vm.$axios1.post("/v3/product/wish", {
        userId: state.userId,
        productId: state.productId,
      });
      if (wishResponse.data.responseCode == 2000) {
        await store.dispatch("initState", {
          productId: state.productId,
          userId: state.userId,
        });
        // store.commit('toggleProductLike');
        const isLike = state.product.wishProductYn == "Y";
        const _this = this;
        const modalName = "like_notice_modal";
        const compProps = {
          title: isLike ? "찜 하였습니다." : "찜에서 삭제하였습니다.",
          msgArr: [isLike ? "마이페이지 > 찜 리스트<br>에서 확인하세요." : "마이페이지 > 찜 리스트<br>에서 삭제되었습니다."], // html 코드 적용 가능
          closeModal: function () {
            _this._vm.$modal.hide(modalName);
          },
        };
        // 찜 등록/취소 안내 모달 출력
        store.dispatch(
          "showModal",
          {
            name: modalName,
            compProps: compProps,
            modalProps: null,
            seconds: 3,
          },
          { root: true },
        );
      } else {
        logError(wishResponse.data, "단골 정보 조회에 실패하였습니다.");
      }
    } catch (e) {
      console.error(e);
    }
    store.commit("setIsProgressing", false);
  },
  moveToStore: function (store) {
    if (state.product.brandId) {
      router.push({ name: "놀장 메인" });
    } else {
      store.commit("moveToStore");
    }
  },
  navigateToProductOption: async function (store) {
    if (!state.isLoading) {
      return;
    }

    try {
      // 상품 정보 조회
      const productUrl = "/v3/market/delivery/product/" + state.productId + "/user/" + (state.userId ? state.userId : "null");
      const productResponse = await this._vm.$axios1.get(productUrl);
      if (productResponse.data.responseCode == 2000) {
        const product = productResponse.data.result;
        if (product.notationalInformationList) {
          product.notationalInformationList = product.notationalInformationList.filter((item) => {
            return item.title || item.content;
          });
        }
        // store.commit("updateState", {
        //   type: "product",
        //   updateData: productResponse.data.result,
        // });
      } else {
        logError(productResponse.data, "상품 정보 조회에 실패하였습니다.");
      }
    } catch (e) {
      console.error(e);
    }

    const userCart = JSON.parse(localStorage.getItem("userCart"));

    // var cartCompareCount = 0;
    var targetMarket = null;
    var marketId = state.product.marketId;
    const cartLength = userCart?.length;
    if (cartLength > 0) {
      for (var i = 0; i < cartLength; i++) {
        if (userCart[i].marketId == marketId) {
          targetMarket = userCart[i];
        }
      }
    }

    var errorCode = 0; // 0 일때 정상 프로세스 진행

    if (targetMarket) {
      const productInfo = state.product; // 상품 정보
      const deliveryMethod = productInfo.deliveryMethod; // 배송 타입
      const storeId = productInfo.storeId; // 상점 아이디

      if (deliveryMethod == "PKG") {
        if (targetMarket.products) {
          const products = targetMarket.products;
          for (const product of products) {
            if (product.deliveryMethod == "FD") {
              if (product.storeId != storeId) {
                errorCode = 1; // 묶음 상품을 장바구니에 담을때 음식배달 상품이 존재하고 다른 상점 상품일때
                break;
              }
            }
            // if (product.deliveryMethod == "TB") {
            //   errorCode = 4;
            //   break;
            // }
          }
        }
      } else if (deliveryMethod == "TB") {
        // 택배
        if (targetMarket.products) {
          const products = targetMarket.products;
          for (const product of products) {
            if (product.deliveryMethod == "FD" || product.deliveryMethod == "FDPKG") {
              errorCode = 1; // 음식배달이 존재할때
              break;
            }
            // 같은 상점일 때
            if (product.storeId != storeId) {
              errorCode = 2;
              break;
            }
          }
        }
      } else if (deliveryMethod == "FD" || deliveryMethod == "FDPKG") {
        // 음식
        if (targetMarket.products) {
          const products = targetMarket.products;

          var fdProductCount = 0;
          if (deliveryMethod == "FDPKG") {
            for (const item of products) {
              if (item.deliveryMethod == "FD") {
                fdProductCount++;
              }
            }
          }

          for (const product of products) {
            if (product.storeId != storeId) {
              if (product.deliveryMethod == "PKG") {
                if (deliveryMethod == "FDPKG") {
                  if (fdProductCount > 0) {
                    errorCode = 3;
                  }
                } else {
                  errorCode = 3;
                }
              } else {
                errorCode = 5;
              }
              break;
            }
            if (product.deliveryMethod == "TB") {
              errorCode = 4;
              break;
            }
          }
        }
      }
    }

    if (state.product.productSaleYn == "N") {
      errorCode = 6;
    } else if (state.product.openStoreYn == "N") {
      errorCode = 7;
    } else if (state.product.productDeleteYn == "Y") {
      errorCode = 8;
    }

    var compareMsgs = null;

    if (errorCode == 1) {
      compareMsgs = [
        "<strong style='display: block; margin: 0 0 10px;'>장바구니에 음식 상품이 담겨 있습니다.</strong>",
        "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
      ];
    } else if (errorCode == 2) {
      compareMsgs = [
        "<strong style='display: block; margin: 0 0 10px;'>택배 상품은 같은 상점의 상품만 <br>담을 수 있습니다.</strong>",
        "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
      ];
    } else if (errorCode == 3) {
      compareMsgs = [
        "<strong style='display: block; margin: 0 0 10px;'>장바구니에 묶음 상품이 담겨 있습니다.</strong>",
        "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
      ];
    } else if (errorCode == 4) {
      compareMsgs = [
        "<strong style='display: block; margin: 0 0 10px;'>장바구니에 택배 상품이 담겨 있습니다.</strong>",
        "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
      ];
    } else if (errorCode == 5) {
      compareMsgs = [
        "<strong style='display: block; margin: 0 0 10px;'>음식 상품은 같은 상점의 상품만 <br>담을 수 있습니다.</strong>",
        "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
      ];
    } else if (errorCode == 6) {
      compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>상품을 구매하실 수 없습니다.</strong>"];
    } else if (errorCode == 7) {
      if (new Date().getHours() < 12) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>영업 준비 중인 상점의 상품입니다.<br/>장바구니에 담을수 없습니다.</strong>",
        ];
      } else {
        compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>영업 종료된 상점의 상품입니다.<br/>장바구니에 담을수 없습니다.</strong>"];
      }
    } else if (errorCode == 8) {
      compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>유효하지 않은 상품입니다.</strong>"];
    }
    if (errorCode > 0) {
      const _this = this;
      const callback = (isBoolean) => {
        _this._vm.$modal.hideAll();
        if (isBoolean) {
          router.push({ name: "장바구니", params: { reload: true } });
        }
      };

      const compProps = {
        title: "장바구니 담기",
        type: "order_notice",
        marketId: String(marketId),
        msgArr: compareMsgs, // html 코드 적용 가능
        btnArr: [
          {
            txt: "닫기",
            type: "type4 normal",
            btnCallback: () => callback(false),
          },
        ],
        marketOrderInfo: {
          enableYn: "N",
        },
        closeModal: function () {
          _this._vm.$modal.hide("order_notice");
        },
      };
      this.dispatch("showModal", { name: "order_notice", compProps: compProps, modalProps: null }, { root: true });

      return;
    }

    // 장바구니에 음식 상품이 담겨 있습니다.
    // 장바구니에 묶음 상품이 담겨 있습니다.
    // 음식 상품은 같은 상점의 상품만 담을 수 있습니다.
    // 택배 상품은 같은 상점의 상품만 담을 수 있습니다.
    // 장바구니에 택배 상품이 담겨 있습니다.

    // if (state.userId != "B6545D8E-ECE0-4044-9D52-C05496D4D410") {
    // }
    // TODO :: 현재 담으려는 상품의 구분 (BD, TB, QUICK)과 장바구니 내의 상품의 구분이 동일한지 체크
    // TODO :: 동일하지 않을 시 Alert 표시
    store.commit("navigateToProductOption", store.rootState.appVersion);
  },
  navigateToMall: function () {
    const _this = this;

    const compProps = {
      title: "안내",
      type: "alert",
      msgArr: ["온라인 몰로 이동하시겠습니까?"], // html 코드 적용 가능
      btnArr: [
        {
          txt: "닫기",
          type: "type1",
          btnCallback: () => {
            _this._vm.$modal.hideAll();
          },
        },
        {
          txt: "확인",
          type: "type4 else1",
          btnCallback: () => {
            _this._vm.$modal.hideAll();
            let currentOS = "else";
            let mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

            if (mobile) {
              let userAgent = navigator.userAgent.toLowerCase();
              if (userAgent.search("android") > -1)
                currentOS = "android";
              else if ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1))
                currentOS = "ios";
              else
                currentOS = "else";
            }

            // Android
            if(currentOS === "android"){
              window.open("intent://mall.noljang.co.kr/home#Intent;scheme=http;package=com.android.chrome;end");
            // IOS
            } else if(currentOS === "ios"){
              window.open("googlechrome:////mall.noljang.co.kr/home");
            }
          },
        },
      ],
      closeModal: function () {
        _this._vm.$modal.hideAll();
      },
    };

    this.dispatch(
      "showModal",
      {
        name: "navigate_mall",
        compProps: compProps,
        modalProps: {
          width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
          height: "auto",
        },
      },
      { root: true },
    );
  }
};

const getters = {};

/**
 * API 정상 호출 실패 시 에러 로그
 * @param {object} response API 호출 후 받은 응답 데이터
 * @param {string} message 임의로 같이 표시할 메시지
 */
const logError = (response, message) => {
  console.error(
    "Response Code:: " + response.responseCode + "\n" + "Error Message:: " + response.errorObject.errorMessage + "\n" + "Message:: " + message,
  );
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
