<template>
  <div class="product_info_wrap">
    <div class="panel_btn_wrap">
      <PanelBtn type="call" bottomText="전화걸기" @click="goCall" />
    </div>
    <!--    단골 맺기 버튼 -->
    <div class="toggle_store_regular" @click="$emit('clickRegular')">
      <div class="toggle_store_regular_img_wrap" :class="{ active: isRegularStore }" />
      <span class="regular_txt" :class="{ active: !isRegularStore }">
        {{ isRegularStore ? "단골끊기" : "단골맺기" }}
      </span>
      <span class="regular_count">{{ $formatPrice(formatRegularCount) }}</span>
    </div>
    <div class="product_info_top">
      <div class="market_name">{{ marketName }}</div>
      <div class="product_name">{{ name }}</div>
      <div class="chips_wrap">
        <div class="chip" v-for="paymentType in paymentTypes" v-bind:key="paymentType.paymentName">
          {{ paymentType.paymentName }}
        </div>
      </div>
      <div class="origin_price" v-if="discountPrice">
        {{ $formatPrice(price) }}
      </div>
      <div class="product_price">
        {{ formattedPrice }}
        <span class="price_txt">원</span>
      </div>
    </div>
    <div class="product_info_bottom">
      <div class="product_info_lbl_wrap">
        <div class="product_info_lbl">원산지</div>
        <div class="product_info_val">
          {{ originName }}
        </div>
      </div>
      <div class="product_info_lbl_wrap">
        <div class="product_info_lbl">용<span class="spacing"></span>량</div>
        <div class="product_info_val">
          {{ unitString }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PanelBtn from "@/components/PanelBtn";
export default {
  name: "ProductInfo",
  components: { PanelBtn },
  props: {
    name: String,
    marketName: String,
    originName: String,
    paymentTypes: Array,
    price: Number,
    discountPrice: Number,
    unitNumber: Number,
    unitName: String,
    businessCall: String,
    isRegularStore: Boolean,
    favoriteCount: Number,
  },
  methods: {
    goCall: function () {
      this.$callNative("showCall", { phoneNumber: this.businessCall });
    },
  },
  computed: {
    formattedPrice: function () {
      const price = this.discountPrice ? this.discountPrice : this.price;
      return this.$formatPrice(price);
    },
    unitString: function () {
      return this.unitNumber + this.unitName;
    },
    formatRegularCount: function () {
      return this.favoriteCount < 1000 ? this.favoriteCount : "999+";
    },
  },
};
</script>

<style lang="scss" scoped>
.product_info_wrap {
  background: $nj_background_gray;
  border-radius: 20px 20px 0px 0px;
  padding: 50px 20px 20px 20px;
  position: relative;
}

.toggle_store_regular {
  background-image: url("../../../assets/images/shape1.png");
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  padding-top: 15px;
  text-align: center;

  .toggle_store_regular_img_wrap {
    display: inline-block;
    width: 23px;
    height: 21px;
    margin-bottom: 5px;
    background-image: url("~image/icon_store.png");
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      background-image: url("~image/icon_store_active.png");
    }
  }

  span {
    display: block;
    font-size: 12px;
    letter-spacing: -0.72px;

    &.regular_txt {
      color: $nj_black;

      &.active {
        color: $nj_red;
      }
    }

    &.regular_count {
      color: #aaaaaa;
    }
  }
}

/* 상품 정보 상단 영역 (시장, 상품명, 가격 등) */
.product_info_top {
  padding-bottom: 30px;
  margin-top: 15px;
  text-align: center;
  border-bottom: 1px solid $nj_divider_gray;

  .origin_price {
    @include font(22, 33, -1.32);
    color: $nj_text_main_gray;
    text-decoration: line-through;
  }
}

.market_name {
  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -1.2px;
  color: $nj_text_main_gray;
}

.product_name {
  font: normal normal normal 26px/38px Noto Sans CJK KR Bold;
  letter-spacing: -1.56px;
  color: $nj_black;
}

.chips_wrap {
  margin-top: 20px;
  margin-bottom: 15px;
}

.chip {
  height: 23px;
  display: inline-block;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  padding: 0px 10px;
  font: normal normal normal 12px/23px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: $nj_text_main_gray;
  margin-right: 2px;
  text-align: center;
}

.product_price {
  //font: normal normal normal 34px/50px Noto Sans CJK KR Bold;
  @include font(34, 34, -2.04, bold);
  letter-spacing: -2.04px;
  color: $nj_black;
}

.product_price .price_txt {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -1.08px;
  color: $nj_text_main_gray;
}

/* 상품 정보 하단 영역 (원산지, 용량 등) */
.product_info_bottom {
  padding-top: 20px;
}

.product_info_lbl_wrap > * {
  display: inline-block;
}

.product_info_lbl_wrap:first-child {
  margin-bottom: 10px;
}

.product_info_lbl {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -1.08px;
  color: $nj_text_main_gray;
  margin-right: 20px;
}

.product_info_lbl .spacing {
  width: 16px;
  display: inline-block;
}

.product_info_val {
  font: normal normal normal 18px/27px Noto Sans CJK KR;
  letter-spacing: -1.08px;
  color: $nj_black;
}

.panel_btn_wrap {
  position: absolute;
  top: -25px;
  left: 20px;
}
</style>
