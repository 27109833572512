<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <div v-else>
      <DeliverySearchAddrItem :shipToDoro="shipToDoro" :shipToJibun="shipToJibun" :isDetail="true"></DeliverySearchAddrItem>
      <div class="detail_addr_wrap">
        <div class="input_wrapper">
          <input v-bind:value="shipToDetail" v-on:input="changeDetailAddr" class="" placeholder="상세주소를 입력하세요. ex)건물명, 층수" />
        </div>
        <div class="input_wrapper">
          <input v-bind:value="shipToName" v-on:input="changeAddrAlias" class="" placeholder="주소의 별칭을 정해주세요. ex)집, 회사" />
        </div>
      </div>
      <div class="btn_complete" v-on:click="doSave">
        <span>완료</span>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import DeliverySearchAddrItem from "./DeliverySearchAddrItem";
import { mapActions, mapState } from "vuex";

export default {
  name: "DeliveryAddrDetail",
  props: {
    shipToDoro: String,
    shipToJibun: String,
    showHideLayer: Function,
  },
  data: function () {
    return {
      shipToDetail: "",
      shipToName: "",
    };
  },
  components: {
    LoadingIndicator,
    DeliverySearchAddrItem,
  },
  methods: {
    ...mapActions("deliveryAddr", ["saveCurrentAddress"]),
    changeDetailAddr: function (e) {
      this.shipToDetail = e.target.value;
    },
    changeAddrAlias: function (e) {
      this.shipToName = e.target.value;
    },
    doSave: function () {
      this.saveCurrentAddress({
        shipToDoro: this.shipToDoro,
        shipToJibun: this.shipToJibun,
        shipToDetail: this.shipToDetail,
        shipToName: this.shipToName,
      });
    },
  },
  computed: {
    ...mapState("deliveryAddr", ["isProgressing"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  letter-spacing: -0.96px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
}

.detail_addr_wrap {
  margin-top: 20px;
}

.input_wrapper > input {
  width: 100%;
  background: transparent;
  padding: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid $nj_divider_gray;
  color: $nj_black;
}

.input_wrapper > input::placeholder {
  letter-spacing: -0.96px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  color: $nj_placeholder_gray;
}

.btn_complete {
  width: 100%;
  height: 60px;
  background: $nj_red;
  border-radius: 20px;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.btn_complete > span {
  color: white;
  font: normal normal normal 20px/29px Noto Sans CJK KR;
  letter-spacing: -1.2px;
  line-height: 60px;
}
</style>
