<template>
  <div v-show="displayLayer" class="layer_wrap">
    <a href="javascript:void(0)" class="layer_blank" @click="closeLayer">레이어 팝업 닫기</a>

    <div class="layer type2" v-if="displayType == 'result'">
      <div class="layer_contents">
        <slot name="content" />
      </div>
    </div>
    <div class="layer" v-else-if="displayType != 'center'">
      <div class="layer_contents">
        <slot name="content" />
      </div>
    </div>
    <div class="layer type1" v-else-if="displayType == 'center'">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Layer",
  props: {
    displayLayer: Boolean,
    closeLayer: Function,
    displayType: String,
  },
};
</script>

<style lang="scss" scoped>
.layer_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  .layer_blank {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    text-indent: -9999px;
  }
  .layer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    @include border-radius(20px 20px 0 0);
    &.type1 {
      top: 50%;
      left: 50%;
      bottom: auto;
      width: 100%;
      max-width: 340px;
      transform: translate(-50%, -50%);
      background-color: transparent;
      //@include border-radius(20px);
      text-align: center;
      .layer_contents {
        background-color: $white;
        @include border-radius(20px);
      }
    }
    &.type2 {
      bottom: 20px;
      left: 20px;
      right: 20px;
      width: auto;
      @include border-radius(20px);
      .layer_txt {
        margin: 10px 0 0;
        @include font(20, 29, -1.2);
        color: $nj_red;
        text-align: center;
      }
    }
  }
  .layer_contents {
    padding: 20px 20px 30px;
  }

  .layer_title {
    margin: 10px 0 0;
    @include font(20, 26, -1.2, bold);
    color: $nj_black;
  }
  .layer_txt {
    margin: 20px 0 0;
    @include font(16, 24, -0.96);
    color: $nj_text_sub_gray;
  }
}
</style>
