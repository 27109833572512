<template>
  <div>
    <TopHeader class="top_header" title="개인정보처리방침" @goBack="goBack" :isCartVisible="false" />
    <div class="contents">
      <div class="privacy_info">
        <div class="text-box">
          <h2 class="text-bold text-size-l text-center">개인정보처리방침</h2>
          <div class="text-center">
            ㈜마켓트리(이하 “회사”)는 회사가 운영하는 놀장 서비스(이하 “서비스”)를 이용하는 고객의 개인정보보호를 위해 최선을 다하고 있으며 아래와
            같은 개인정보처리방침을 가지고 있습니다.
          </div>
          <div class="gray-box text-bold">
            <div>1. 목적</div>
            <div>2. 수집하는 개인정보의 항목</div>
            <div>3. 개인정보 수집에 대한 동의</div>
            <div>4. 개인정보의 수집 및 이용목적</div>
            <div>5. 쿠키에 의한 개인정보 수집</div>
            <div>6. 목적 외 사용 및 제3자에 대한 제공 및 공유</div>
            <div>7. 개인정보의 열람, 정정</div>
            <div>8. 개인정보 수집, 이용, 제공에 대한 동의철회(회원탈퇴)</div>
            <div>9. 수집하는 개인정보의 보유 및 이용기간</div>
            <div>10. 개인정보보호를 위한 기술 및 관리적 대책</div>
            <div>11. 링크사이트</div>
            <div>12. 개인정보의 위탁처리</div>
            <div>13. 이용자의 권리와 의무</div>
            <div>14. 의견수렴 및 불만처리</div>
            <div>15. 개인정보관리책임자 및 담당자</div>
            <div>16. 아동의 개인정보보호</div>
            <div>17. 고지의 의무</div>
          </div>
          <br />
          <p class="text-bold text-size-m">1. (목적)</p>
          <div>
            가. “개인정보”란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는
            정보(당해 정보 만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다)를 말합니다.
          </div>
          <br />
          <div>
            나. 회사는 이용자의 개인정보보호를 매우 중요시하며, 『개인정보보호법』, 『정보통신망 이용촉진 및 정보보호에 관한 법률』 상의
            개인정보보호규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자께서 제공하시는
            개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
          </div>
          <br />
          <div>다. 회사는 개인정보처리방침을 “서비스 &gt; 마이페이지”에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.</div>
          <br />
          <div>
            라. 회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고
            개인정보처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된 사항을 이용자가 쉽게 알아볼 수 있도록 하고 있습니다.
          </div>
          <br />
          <p class="text-bold text-size-m">2. (수집하는 개인정보의 항목)</p>
          <div>
            회사는 별도의 회원가입 절차 없이 대부분의 컨텐츠에 자유롭게 접근할 수 있습니다. 그러나 회사가 제공하는 서비스 이용에 따른 대금결제,
            상품배송 및 환불 등에 필요한 이용자의 정보를 추가로 수집하고 있습니다.
          </div>
          <br />
          <div>가. 회원 가입 시 수집하는 개인정보의 범위</div>
          <div>1) 필수항목 : 성명, 휴대전화번호, 주민등록번호 일부 정보(앞자리, 뒷자리 첫째 숫자), 주소</div>
          <br />
          <div>나. 비회원 이용 시 수집하는 개인정보의 범위</div>
          <div>1) 필수항목 : 주소</div>
          <br />
          <div>다. 서비스 이용과정이나 사업처리과정에서의 자동생성 정보</div>
          <div>1) 서비스 이용기록, 접속로그, 쿠키, 접속 IP정보, 결제기록, 이용정지기록, 기기고유번호(디바이스 아이디 또는 IMEI)</div>
          <br />
          <p class="text-bold text-size-m">3. (개인정보 수집에 대한 동의)</p>
          <div>
            이용자는 '제2조(수집하는 개인정보의 항목)' 상 개인정보의 수집에 대해 동의를 거부할 수 있습니다. 다만, 거부하는 경우, 회원서비스 이용을
            위한 회원가입이 불가하거나 회원서비스를 이용하지 못할 수 있습니다. 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 법령에 따라 이와 같은
            동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다.
          </div>
          <br />
          <div>
            가. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 · 기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게
            곤란한 경우
          </div>
          <br />
          <div>나. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우</div>
          <br />
          <div>다. 그 밖에 법률에 특별한 규정이 있는 경우</div>
          <br />
          <p class="text-bold text-size-m">4. (개인정보의 수집 및 이용목적)</p>
          <div>
            가. 회사는 다음과 같은 목적을 위하여 개인정보를 수집하고 있으며 목적이 변경될 경우에는 사전에 이용자의 동의를 구하도록 하겠습니다.
          </div>
          <div>1) 성명, 휴대전화번호, 주민등록번호 일부 정보(앞자리, 뒷자리 첫째 숫자) : 회원제 서비스 이용에 따른 본인 식별 절차에 이용</div>
          <div>
            2) 휴대전화번호 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스/신상품이나 이벤트 정보 안내,
            서비스 이용에 대한 리뷰 작성 권유, 그 밖에 회사가 제공하는 서비스 안내 및 광고성 정보 전송
          </div>
          <div>3) 성명, 휴대전화번호, 주소 : 이용자의 상품 주문에 따른 배달 진행, 택배 배송을 위한 정확한 배송지의 확보 및 본인 식별</div>
          <div>4) 주소 : 선택한 주소를 기준으로 배달 가능한 제휴 전통시장 및 해당 전통시장 내 제휴 상점을 안내</div>
          <div>5) 그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료</div>
          <br />
          <div>
            나. 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록,
            의료정보 등)는 수집하지 않습니다.
          </div>
          <br />
          <br />
          <p class="text-bold text-size-m">5. (쿠키에 의한 개인정보 수집)</p>
          <div>
            가. 쿠키(cookie)는 서비스가 이용자의 브라우저로 전송하는 소량의 정보입니다. 회사는 이용자에 대한 정보를 저장하고 수시로 찾아내는
            ‘쿠키(cookie)’를 사용합니다. 쿠키는 이용자의 기기(휴대전화, 컴퓨터 등)는 식별하지만 이용자를 개인적으로 식별하지는 않습니다. 또한 이용자는
            쿠키에 대한 선택권이 있습니다. 브라우저의 설정에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든
            쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
          </div>
          <br />
          <div>나. 회사의 쿠키(cookie) 운용</div>
          <div>1) 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 마케팅에 활용</div>
          <div>2) 회원들의 습관을 분석하여 서비스 개편 등의 척도</div>
          <br />
          <div>
            다. 쿠키(cookie) 설정 거부방법 : 이용자는 쿠키설치에 대해 거부할 수 있습니다. 단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부
            서비스의 이용이 어려울 수 있습니다. 설정방법 : 각 모바일기기에 맞는 쿠키삭제 진행 혹은 권한부여 삭제를 진행합니다.
          </div>
          <br />
          <p class="text-bold text-size-m">6. (목적 외 사용 및 제3자에 대한 제공 및 공유)</p>
          <div>
            가. 회사는 이용자의 개인정보를 「개인정보의 수집목적 및 이용목적」에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인
            또는 타기업에 제공하지 않습니다. 특히 다음의 경우는 주의를 기울여 개인정보를 이용 및 제공할 것입니다.
          </div>
          <br />
          <div>1) 제휴 관계:</div>
          <div>가) 배달서비스 이용계약의 이행을 위하여 이용자의 개인정보를 제휴사에게 제공 하거나 제휴사와 공유할 수 있습니다.</div>
          <div class="table-responsive">
            <table class="table-privacy">
              <caption></caption>
              <tbody>
                <tr>
                  <th class="text-bold">개인정보를 제공받는 자</th>
                  <td>제휴 전통시장내 제휴 상점, 제휴 배달대행 업체</td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 제공 목적</th>
                  <td>제휴 서비스 제공</td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 제공 항목</th>
                  <td>성명, 주소, 휴대전화번호, 이용상품 정보</td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 보유 및 이용기간</th>
                  <td>구매 서비스 종료 후 1개월</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          나) 정부의 전통시장 육성 정책의 일환으로 진행되는 각 종 전통시장 이벤트나 관계 기관의 통계 집계를 위해 개인정보를 제공하거나 공유할 수
          있습니다.
          <div class="table-responsive">
            <table class="table-privacy">
              <caption></caption>
              <tbody>
                <tr>
                  <th class="text-bold">개인정보를 제공받는 자</th>
                  <td>제휴 전통시장의 상인회 또는 문화관광형시장육성사업단, 상공인시장진흥공단, 중소벤처기업부</td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 제공 목적</th>
                  <td>제휴 전통시장 이벤트 진행 및 전통시장 육성을 위한 통계 집계</td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 제공 항목</th>
                  <td>
                    성명, 휴대전화번호, 이용상품 정보. 단, 성명과 휴대전화번호는 마스킹된 정보로 제공 <br /><span class="text-gray"
                      >· 이벤트 참여날짜 : YYYY.MM.DD</span
                    >
                    <br /><span class="text-gray">· 성명 : 김****</span> <br /><span class="text-gray">· 휴대전화번호 : 010.1234.****</span>
                  </td>
                </tr>
                <tr>
                  <th class="text-bold">개인정보 보유 및 이용기간</th>
                  <td>제공 동의일로부터 개인정보 제공 목적을 달성할 때까지, 제공 목적을 달성한 이 후에는 제공된 개인정보는 즉시 파기함</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div>
            개인정보 보유 및 이용기간 제공 동의일로부터 개인정보 제공 목적을 달성할 때까지, 제공 목적을 달성한 이 후에는 제공된 개인정보는 즉시 파기함
          </div>
          <br />
          <div>
            3) 매각, 인수합병 등: 서비스제공자의 권리와 의무가 완전 승계 이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해 상세하게 고지할 것이며
            이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다.
          </div>
          <br />
          <div>
            나. 고지 및 동의방법은 서비스 초기화면의 배너와 팝업을 통해 최소 10 일 이전부터 고지함과 동시에 푸시알림, 문자 등을 이용하여 1회 이상
            개별적으로 고지합니다.
          </div>
          <br />
          <div>다. 다음은 예외로 합니다.</div>
          <div>1) 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우</div>
          <div>2) 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주 협력사나 연구단체 등에 제공하는 경우</div>
          <div>3) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</div>
          <div>
            4) 그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을 원칙으로
            운영하고 있습니다. 법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있습니다. 본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가
            제공되지 않도록 최대한 노력하겠습니다.
          </div>
          <br />
          <p class="text-bold text-size-m">7. (개인정보의 열람, 정정)</p>
          <div>
            가. 이용자는 언제든지 등록되어 있는 이용자의 개인정보를 열람하거나 정정할 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 “서비스
            &gt; 마이페이지”를 클릭하여 직접 열람 또는 정정하거나, 개인정보관리책임자 및 담당자에게 서면, 전화 또는 E-mail 로 연락하시면 지체 없이
            조치하겠습니다.
          </div>
          <br />
          <div>나. 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.</div>
          <br />
          <div>다. 잘못된 개인정보를 제 3 자에게 이미 제공한 경우에는 정정 처리결과를 제 3 자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.</div>
          <br />
          <div>
            라. 이용자에 따른 권리행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실수 있습니다. 이 경우 위임장을 제출하셔야
            합니다.
          </div>
          <br />
          <div>
            마. 이용자는 개인정보 보호 유관법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인이 개인정보 및 사생활을 침해하여서는 안됩니다.
          </div>
          <br />
          <p class="text-bold text-size-m">8. (개인정보 수집, 이용, 제공에 대한 동의철회[회원탈퇴])</p>
          <div>
            가. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 이용자가 동의하신 내용을 이용자는 언제든지 철회하실 수 있습니다. 동의철회는
            “마이페이지 &gt; 서비스탈퇴”를 클릭하거나 개인정보관리책임자에게 팩스, 우편, 고객센터, 전화 등으로 연락하시면 회원탈퇴 신청 시점으로부터 7
            일 동안 재가입 방지를 위한 개인정보보존 이후 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를
            취한 경우에는 그 사실을 이용자께 지체 없이 통지하도록 하겠습니다.
          </div>
          <br />
          <div>나. 회사는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.</div>
          <br />
          <p class="text-bold text-size-m">9. (수집하는 개인정보의 보유 및 이용기간)</p>
          <div>가. 회사는 회원의 개인정보를 서비스 이용시점부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다.</div>
          <br />
          <div>
            나. 1년 간 회원의 서비스 이용기록이 없는 경우, 『정보통신망 이용촉진 및 정보보호등에 관한 법률』 제29조에 근거하여 회원에게 사전 통지하고
            개인정보를 별도로 분리하여 휴면계정 전환일로부터 3년 간 저장합니다.
          </div>
          <br />
          <div>
            다. 회원 탈퇴 또는 회원 자격 정지 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등으로 경제상의
            이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 등 편법행위 또는 불법행위를 하는 회원을 차단하고자 회사는 회원 탈퇴 후 7 일 간 회원의
            정보를 보관합니다.
          </div>
          <br />
          <div>
            라. 본조 “나” 항 및 “다” 항에서 명시한 회원의 개인정보 보존기간이 경과되면, 회사는 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 외부
            업체를 통하여 분쇄하고, 전자적 파일 형태로 저장된 개인정보는 재사용할 수 없도록 기술적 방법을 사용하여 삭제 처리합니다.
          </div>
          <br />
          <div>
            마. 본조 “가” 항 내지 “라” 항에도 불구하고 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있는
            개인정보의 경우, 회사는 하기 명시된 법령에 따라 해당 개인정보를 보관합니다.
          </div>
          <div>· 서비스 이용 관련 개인정보(로그인기록)보존 근거: 통신비밀보호법 보존 기간 3개월</div>
          <div>· 표시/광고에 관한 기록 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 6개월</div>
          <div>· 계약 또는 청약철회 등에 관한 기록 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 5년</div>
          <div>· 대금결제 및 재화 등의 공급에 관한 기록 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 5년</div>
          <div>· 소비자의 불만 또는 분쟁처리에 관한 기록 보존 근거: 전자상거래 등에서의 소비자보호에 관한 법률 보존 기간 3년</div>
          <div>· 전자금융 거래에 관한 기록 보존 근거: 전자금융거래법 보존 기간 5년</div>
          <div>
            바. 이용자의 동의를 받아 보유하고 있는 거래정보 등을 이용자가 열람을 요구하는 경우 회사는 지체 없이 그 열람 확인을 할 수 있도록
            조치합니다.
          </div>
          <br />
          <br />
          <p class="text-bold text-size-m">10. (개인정보보호를 위한 기술 및 관리적 대책)</p>
          <div>
            가. 기술적 대책: 회사는 의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과
            같은 기술적 대책을 강구하고 있습니다.
          </div>
          <div>
            1) 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의
            보안기능을 통해 보호되고 있습니다.
          </div>
          <div>
            2) 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로
            업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
          </div>
          <div>3) 회사는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.</div>
          <div>4) 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.</div>
          <br />
          <div>나. 관리적 대책</div>
          <div>
            1) 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.
          </div>
          <div>- 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자</div>
          <div>- 고객의 불만 및 이용문의 처리 업무를 수행하는 자</div>
          <div>- 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자</div>
          <div>- 기타 업무상 개인정보의 취급이 불가피한 자</div>
          <div>
            2) 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를
            감사하기 위한 내부절차를 마련하고 있습니다.
          </div>
          <div>
            3) 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을
            명확화하고 있습니다.
          </div>
          <div>
            4) 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를
            보호하기 위해서 자신의 ID와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
          </div>
          <div>
            5) 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 회사는 즉각 이용자께 사실을 알리고
            적절한 대책과 보상을 강구할 것입니다.
          </div>
          <br />
          <p class="text-bold text-size-m">11. (링크사이트)</p>
          <div>
            회사는 이용자께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이
            없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여
            타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기
            바랍니다.
          </div>
          <br />
          <p class="text-bold text-size-m">12. (개인정보의 위탁처리)</p>
          <div>
            가. 회사는 원활하고 향상된 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다.이 경우 회사는 사전에 다음 각 호의 사항 모두를
            이용자에게 미리 알리고 동의를 받습니다. 다음 각 호의 어느 하나의 사항이 변경되는 경우에도 같습니다.
          </div>
          <div>1) 개인정보 처리위탁을 받는 자</div>
          <div>2) 개인정보 처리위탁을 하는 업무의 내용</div>
          <br />
          <div>
            나. 회사는 정보통신서비스의 제공에 관한 계약을 이행하고 이용자 편의 증진 등을 위하여 필요한 경우 개인정보처리방침에 따라 가항 각 호의
            사항을 공개함으로써 고지절차와 동의절차를 거치지 아니하고 개인정보 처리를 타인에게 위탁할 수 있습니다
          </div>
          <br />
          <div>
            다. 회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수
            있도록 필요한 조치를 하고 있습니다. 회사는 위탁 계약 시 수탁자의 개인정보 보호조치 능력을 고려하고, 개인정보의 안전한 관리 및 파기 등
            수탁자의 의무 이행 여부를 주기적으로 확인합니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에
            국한됩니다.
          </div>
          <br />
          <div class="table-responsive">
            <table class="table-privacy">
              <caption></caption>
              <thead>
                <tr>
                  <th class="text-bold">수탁 업체</th>
                  <th class="text-bold">위탁업무 내용</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>나이스 평가정보㈜</td>
                  <td>휴대전화 본인인증</td>
                </tr>
                <tr>
                  <td>비즈플레이㈜</td>
                  <td>결제처리(모바일온누리상품권)</td>
                </tr>
                <tr>
                  <td>NICE페이먼츠㈜</td>
                  <td>결제처리(신용카드)</td>
                </tr>
                <tr>
                  <td>비즈플레이㈜</td>
                  <td>결제처리(서울페이)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p class="text-bold text-size-m">13. (이용자의 권리와 의무)</p>
          <div>
            가. 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해
            발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
          </div>
          <br />
          <br />
          <p class="text-bold text-size-m">14. (의견수렴 및 불만처리)</p>
          <div>가. 당사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.</div>
          <br />
          <div>나. 당사는 이용자와의 원활환 의사소통을 위해 고객센터를 운영하고 있으며 연락처는 다음과 같습니다.</div>
          <div>[고객센터]</div>
          <div>· 전자우편 : noljang@market-tree.com</div>
          <div>· 전화번호 : 02-2039-6742</div>
          <div>· 주 소 : 서울특별시 강남구 테헤란로25길 23 ㈜마켓트리</div>
          <br />
          <div>다. 정확한 상담을 위하여 전화상담을 사용하지 않고 전자우편을 통한 상담을 해드리고 있습니다.</div>
          <br />
          <div>
            라. 전자우편를 이용한 상담은 접수 후 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로
            합니다.
          </div>
          <br />
          <div>
            마. 기타 개인정보에 관한 상담이 필요한 경우에는 회사의 위 전자우편으로 문의하실 수 있으며, 국가기관에 신고나 상담이 필요한 경우에는 아래의
            연락처에 문의하셔서 도움을 받으실 수 있습니다.
          </div>
          <span class="text-bold">[개인정보침해신고센터]</span>
          <div>· 전화 : 118</div>
          <div>· URL : <a target="blank">http://privacy.kisa.or.kr</a></div>
          <span class="text-bold">[정보보호마크인증위원회]</span>
          <div>· 전화 : 02-550-9531~2</div>
          <div>· URL : <a target="blank">http://www.eprivacy.or.kr</a></div>
          <span class="text-bold">[대검찰청 사이버범죄수사단]</span>
          <div>· 전화 : 02-3480-3571</div>
          <div>· URL : <a target="blank">https://cybercid.spo.go.kr</a></div>
          <span class="text-bold">[경찰청 사이버안전국]</span>
          <div>· 전화 : 182</div>
          <div>· URL : <a target="blank">http://cyberbureau.police.go.kr</a></div>
          <br />
          <p class="text-bold text-size-m">15. (개인정보관리책임자 및 담당자)</p>
          회사는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는
          사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
          위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 이용자의
          개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.
          <div>[개인정보관리책임자]</div>
          <div>· 성 명 : 김용대</div>
          <div>· 전자우편 : ydkim@market-tree.com</div>
          <div>· 전화번호 : 02-2039-6742</div>
          <br />
          <p class="text-bold text-size-m">16. (아동의 개인정보보호)</p>
          <div>회사는 만 14세 미만 아동의 개인정보를 보호하기 위하여, 만 14세 미만 아동의 회원가입은 제한합니다.</div>
          <br />
          <br />
          <p class="text-bold text-size-m">17. (고지의 의무)</p>
          <div>
            현 개인정보처리방침은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전(단, 중요한 내용
            변경의 경우 30일 전)부터 서비스의 배너, 팝업 또는 푸시알림을 통해 고지할 것입니다.
          </div>
          <br />
          <div class="gray-box">
            - 버전번호 : v3.0<br />
            - 개정일자 : 2022. 12. 29.<br />
            - 시행일자 : 2024. 06. 13.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapState, mapActions, mapMutations } from "vuex";
import Btn from "@/components/new/Btn";

export default {
  name: "WithdrawService",
  beforeCreate() {
    this.isProgressing = false;

    this.$store.dispatch("privacyInfo/initState", {
      userId: localStorage.getItem("userId"),
    });
  },
  computed: {
    ...mapState({
      userId: (state) => state.privacyInfo.userId,
      versionCode: (state) => state.privacyInfo.versionCode,
      versionName: (state) => state.privacyInfo.versionName,
      versionUrl: (state) => state.privacyInfo.versionUrl,
      versionHtmlTags: (state) => state.privacyInfo.versionHtmlTags,
    }),
  },
  props: {},
  data: function () {
    return {};
  },
  components: {
    Btn,
    TopHeader,
  },
  methods: {
    ...mapMutations("privacyInfo", []),
    ...mapActions("privacyInfo", ["initState", "btnCallback"]),

    goBack: function () {
      this.$router.push({
        name: "놀장 메인",
      });
    },

    onBackPressed: function () {
      // console.log("onBackPressed");
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        // this.$router.back();
        this.$navigateBack();
      }
    },

    goWebViewUrl: function (title, url) {
      // console.log("링크호출");
      this.$navigateTo("open-url", {
        title: title,
        url: url,
      });
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
};
</script>

<style lang="scss" scoped>
.contents {
  background-color: $white;
  overflow: auto;
}
.privacy_info {
  padding: 20px;
}
p {
  margin: 0;
  @include font(16, 26, 0, medium);
  color: $black;
}

.version_btn_wrap {
  position: relative;
  height: 60px;
  border: {
    style: solid;
    color: #f9f9f9;
    width: 1px 0 0;
  }
  margin: 12px;

  a {
    width: 25%;
    position: absolute;
    right: 0;
    top: 22px;
  }
}

// v-html css

.privacy_info::v-deep th,
td {
  padding: 10px;
}
.privacy_info::v-deep .text-center {
  text-align: center;
}
.privacy_info::v-deep .text-bold {
  font-weight: 800;
  margin: 0;
}
.privacy_info::v-deep .text-size-m {
  font-size: 20px;
}
.privacy_info::v-deep .text-size-l {
  font-size: 25px;
}
.privacy_info::v-deep .text-gray {
  color: #777;
}
.privacy_info::v-deep .text-box {
  font-family: "NanumSquare", san-serif;
  transform: skew(-0deg);
  letter-spacing: -0.8px;
  line-height: 170%;
  font-size: 18px;
  padding: 0.5rem;
}
.privacy_info::v-deep .gray-box {
  background: #f1f1f1;
  padding: 25px 20px;
  border-radius: 0.5rem;
}
.privacy_info::v-deep .table-responsive {
  overflow-x: auto;
  white-space: nowrap;
}
.privacy_info::v-deep .table-privacy {
  border-top: 2px solid #000;
  text-align: left;
}
.privacy_info::v-deep .table-privacy th {
  background: #f1f1f1;
}
</style>
