// import router from '@/router';

const getState = () => {
  return {
    title: "보유쿠폰",
    userId: null,
    marketId: null,
    couponId: null,
    totalPrice: null,
    isProgressing: false,
    couponTabItems: [
      {
        dataType: "enable",
        text: "사용 가능",
        isActive: true,
      },
      {
        dataType: "disable",
        text: "사용 불가",
        isActive: false,
      },
    ],
    contentsType: "enable",
    usableCoupons: [],
    unusableCoupons: [],
    selectedCoupon: null,
  };
};

const state = getState();

const mutations = {
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  goBack: function () {
    // router.back();
    this._vm.$navigateBack();
  },
  reset: function (state) {
    Object.assign(state, getState());
  },
  setContentsType: function (state, { dataType }) {
    state.selectedCoupon = null;
    state.couponTabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });

    state.contentsType = dataType;
  },
};

const actions = {
  initState: async function (store, data) {
    store.commit("updateState", {
      type: "marketId",
      updateData: data.marketId,
    });

    store.commit("updateState", {
      type: "couponId",
      updateData: data.couponId,
    });

    store.commit("updateState", {
      type: "userId",
      updateData: data.userId,
    });

    store.commit("updateState", {
      type: "totalPrice",
      updateData: data.totalPrice,
    });

    store.dispatch("setContentsType", { dataType: state.contentsType });
  },
  goBack: function (store) {
    store.commit("reset");
    store.commit("goBack");
  },
  setContentsType: function (store, data) {
    store.commit("setContentsType", data);
    store.dispatch("getCouponList", data.dataType);
  },
  getCouponList: async function (store, type) {
    try {
      if (!state.userId) {
        return false;
      }

      let url = "v3/coupons/members?size=30&userId=" + store.state.userId;
      if (state.marketId) {
        url += "&marketIds=" + store.state.marketId;
      }

      if (type == "enable") {
        url += "&couponEnableType=Y";
        store.commit("updateState", {
          type: "isProgressing",
          updateData: true,
        });
        const usableCouponResult = await this._vm.$axios2.get(url);
        store.commit("updateState", {
          type: "isProgressing",
          updateData: false,
        });
        const usableCoupons = usableCouponResult.data.result.coupons;

        if (state.marketId) {
          usableCoupons.forEach((coupon) => {
            if (coupon.minProductsPrice > store.state.totalPrice) {
              coupon.enableYn = "N";
            } else {
              coupon.enableYn = "Y";
              if (coupon.couponId == store.state.couponId) {
                state.selectedCoupon = coupon;
              }
            }
          });
        }

        store.commit("updateState", {
          updateData: usableCoupons,
          type: "usableCoupons",
        });
      } else if (type == "disable") {
        url += "&couponEnableType=N";
        store.commit("updateState", {
          type: "isProgressing",
          updateData: true,
        });
        const unusableCouponResult = await this._vm.$axios2.get(url);
        store.commit("updateState", {
          type: "isProgressing",
          updateData: false,
        });
        const unusableCoupons = unusableCouponResult.data.result.coupons;

        store.commit("updateState", {
          updateData: unusableCoupons,
          type: "unusableCoupons",
        });
      }
    } catch (e) {
      // this._vm.$alert(e);
    }
  },
  addCoupon: async function (store, couponNumber) {
    return new Promise((resolve, reject) => {
      if (!couponNumber) {
        this._vm.$alert("알림", ["쿠폰 일련번호를 입력해 주세요."]);
        return false;
      }
      try {
        store.commit("updateState", {
          type: "isProgressing",
          updateData: true,
        });
        this._vm.$axios2
          .post("v3/coupons/members/" + state.userId + "/coupon?couponNumber=" + couponNumber)
          .then((addResult) => {
            if (addResult.data.responseCode == 2000) {
              store.dispatch("getCouponList");
              store.commit("updateState", {
                type: "isProgressing",
                updateData: false,
              });
              resolve(true);
            } else {
              const title = addResult.data.errorObject.errorDetailMessage;
              const msg = [addResult.data.errorObject.errorMessage];
              this._vm.$alert(title, msg);
              store.commit("updateState", {
                type: "isProgressing",
                updateData: false,
              });
              resolve(false);
            }
          })
          .catch((e) => {
            this._vm.$alert(e);
            store.commit("updateState", {
              type: "isProgressing",
              updateData: false,
            });
            reject(e);
          });
      } catch (e) {
        this._vm.$alert(e);
        store.commit("updateState", {
          type: "isProgressing",
          updateData: false,
        });
        reject(e);
      }
    });
  },
  selectCoupon: function (store, coupon) {
    if (state.selectedCoupon && state.selectedCoupon.couponId == coupon.couponId) {
      coupon = null;
    } else if (state.totalPrice < coupon.minProductsPrice) {
      const formattedPrice = this._vm.$formatPrice(coupon.minProductsPrice);
      this._vm.$callNative("showToast", {
        message: formattedPrice + "원 이상 구매시 사용 가능합니다.",
      });
      return false;
    }

    store.commit("updateState", {
      type: "selectedCoupon",
      updateData: coupon,
    });
  },
  applyCoupon: async function (store) {
    const coupon = state.selectedCoupon;
    if (coupon) {
      const returnData = {
        couponId: coupon.couponId,
        couponPrice: coupon.discount,
        couponName: coupon.couponName,
        minProductsPrice: coupon.minProductsPrice,
        couponUseYn: "Y",
        selectedCoupon: true,
      };
      this.dispatch("paymentNew/useCoupon", returnData);
    } else {
      this.dispatch("paymentNew/useCoupon", { couponUseYn: "N" });
    }

    /*let path = "";
    if (coupon) {
      path = "applyCoupon";
      const returnData = {
        couponId: coupon.couponId,
        couponPrice: coupon.discount,
      };

      if (await this.dispatch("checkPaymentWebMode")) {
        this.dispatch("paymentNew/useCoupon", returnData);
      } else {
        this._vm.$callNative(path, returnData, false);
      }
    } else {
      path = "disableCoupon";
      if (await this.dispatch("checkPaymentWebMode")) {
        this.dispatch("paymentNew/useCoupon", null);
      } else {
        this._vm.$callNative(path, null, false);
      }
    }*/
    store.dispatch("goBack");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
