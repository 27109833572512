<template>
  <div>
    <FAQItem v-for="faq in faqs" v-bind:key="faq.id" :id="faq.id" :title="faq.title" :content="faq.description" />
  </div>
</template>

<script>
import FAQItem from "@/page/FAQ/components/FAQItem";

export default {
  name: "FAQList",
  props: {
    faqs: Array,
  },
  data: function () {
    return {
      isProgressing: false,
      curPage: 0,
    };
  },
  components: {
    FAQItem,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
