<template>
  <div class="product_main">
    <TopHeader
      class="top_header"
      :title="title"
      @goBack="goBack"
      :isHomeVisible="true"
      style="position: fixed; top: 0; width: 100%; z-index: 999"
    /><!-- style="position: fixed; top: 0; width: 100%; z-index: 999;" -->
    <div class="contents" style="padding: 58px 0 0; height: 100%; overflow: unset">
      <LoadingIndicator v-if="isProgressing" />
      <div class="product_wrap">
        <!--      상품 이미지 영역 -->
        <div class="product_img_wrap thumb">
          <template v-if="new Array(product.productImageUrl).length > 1">
            <swiper
              ref="productSwiper"
              :options="swiperOptions"
              :auto-destroy="true"
              :delete-instance-on-destroy="true"
              :cleanup-styles-on-destroy="true"
              @touchEnd="touchEnd"
            >
              <swiper-slide v-for="(productImage, index) in product.productImage" :key="index">
                <img :src="productImage" alt="" style="width: 100%; height: 100%; object-fit: cover" />
              </swiper-slide>
              <div class="swiper-pagination product_img_pagination" slot="pagination"></div>
            </swiper>
            <!--            <img :src="productImage" alt="" style="width: 100%; height: 100%; object-fit:cover;"/>-->
          </template>
          <template v-else>
            <div class="close_store" v-if="product.openStoreYn === 'N'">
              {{ storeClosedMsg }}
            </div>
            <div class="close_store" v-else-if="product.productSaleYn === 'N' && product.productDeleteYn === 'N'">상품을 구매하실 수 없습니다.</div>
            <div class="close_store" v-else-if="product.productDeleteYn === 'Y'">SOLD OUT</div>
            <img :src="product.productImageUrl" />
          </template>
        </div>
        <!--      상품 정보 영역-->
        <div class="product_info_wrap product_info_panel" style="position: relative; min-height: 123px">
          <div class="product_info_main_info" style="min-height: 135px">
            <ul class="delivery_type_list" v-if="product.deliveryMethod">
              <li class="tb" v-if="product.deliveryMethod.includes('TB')">택배</li>
              <li
                class="fc"
                v-if="product.deliveryMethod === 'TB' && (product.tbDeliveryFeeCondition === 'FREE' || product.tbDeliveryFeeText === '무료배송')"
              >
                무료배송
              </li>
              <li
                class="at"
                v-if="
                  product.atYn == 'Y' &&
                  discountType != 'SEOUL' &&
                  discountType != 'NONE' &&
                  sssMarkets.indexOf(Number(product.marketId)) === -1 &&
                  hideAtLabelProducts.indexOf(Number(product.productId)) === -1
                "
                v-text="atLabelText"
              ></li>
            </ul>

            <a href="javascript:void(0)" class="btn_share" @click="share" v-if="appVersion"> 공유하기 </a>

            <span class="product_title" style="line-height: 1.4" v-if="isProductLoaded">
              {{ product.productName }},
              {{ product.weight ? $formatWeight(product.weight) : "" }}
              {{
                product.unitNumber
                  ? product.unitNumber + product.unitName !== $formatWeight(product.weight)
                    ? product.unitNumber + product.unitName
                    : ""
                  : ""
              }}
              <span class="regular_indicator" style="position: unset; vertical-align: middle" v-if="regularInfo.regularYn == 'Y'">단골</span>
            </span>
            <div class="price_wrap">
              <span class="sale_price_wrap" v-if="isProductLoaded && product.discountPrice">
                <span class="discount_percent">{{ $formatDiscountPercent(product.price, product.discountPrice) }}</span>
                <span class="origin_price">{{ $formatPrice(product.price) }}원</span>
              </span>
              <span class="real_price_wrap" v-if="isProductLoaded">
                <span class="price">{{ $formatPrice(product.discountPrice ? product.discountPrice : product.price) }}</span>
                <span class="price_txt">원</span>
              </span>
              <div class="like_wrap icon_like2 active" v-if="product.productLikeCount">
                <span class="like_count">{{ $formatPrice(product.productLikeCount) }}</span>
              </div>
            </div>
            <div class="discount_txt" v-if="product.atYn == 'Y'">결제 시 할인 적용</div>
            <ul class="payment_type_list inline_list type2 icon_card">
              <!-- <li v-for="(paymentType, idx) in product.paymentTypeList" v-bind:key="idx" class="payment_item" v-html="paymentType.paymentName" /> -->
              <li v-for="(paymentType, idx) in product.paymentTypeList" v-bind:key="idx" class="payment_item">
                <!-- <p v-if="paymentType.paymentName === '구로사랑상품권' && product.marketId === 176">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else-if="paymentType.paymentName === '신온누리'"></p>
                <p v-else-if="paymentType.paymentName === '금천G밸리사랑상품권' && product.marketId === 190">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else-if="paymentType.paymentName === '금천G밸리사랑상품권' && product.marketId === 192">
                  {{ "서울사랑상품권" }}
                </p>
                <p v-else>
                  {{ paymentType.paymentName }}
                </p> -->
                <p v-if="paymentType.paymentName === '신온누리'"></p>
                <p v-else>
                  {{ paymentType.paymentName }}
                </p>
              </li>
            </ul>
          </div>
          <!--      상품 배송비 정보 영역-->
          <div class="delivery_info_wrap" v-if="(product.deliveryMethod === 'TB' && product.tbDeliveryFeeText) || product.tbDeliveryAreaExtraFeeText">
            <div class="delivery_info">
              <div class="title">택배배송비</div>
              <ul class="info_list">
                <li class="accent" v-if="product.tbDeliveryFeeText">{{ product.tbDeliveryFeeText }}</li>
                <li v-if="product.tbDeliveryAreaExtraFeeText && tbDeliveryAreaExtraFeeTextJeju">{{ tbDeliveryAreaExtraFeeTextJeju }}</li>
                <li v-if="tbDeliveryAreaExtraFeeTextExtraJeju">{{ tbDeliveryAreaExtraFeeTextExtraJeju }}</li>
                <li v-if="product.tbDeliveryConditionExtraFeeText">{{ product.tbDeliveryConditionExtraFeeText }}</li>
                <li v-if="product.additionalFeeIslandMountainComment">{{ product.additionalFeeIslandMountainComment }}</li>
              </ul>
            </div>
          </div>
          <!-- 상품 정보 영역 -->
          <div class="product_info_list">
            <ul>
              <li>
                <span class="title">원산지</span>
                <span class="content">{{ product.originName }}</span>
              </li>
              <li>
                <span class="title">용량</span>
                <span class="weight content" v-if="isProductLoaded">{{ $formatWeight(product.weight) }}</span>
              </li>
              <li>
                <span class="title">상점</span>
                <span class="content" v-if="isProductLoaded">{{ product.storeName }}·{{ product.marketName }}</span>
              </li>
              <li>
                <span class="title">안내</span>
                <span class="content">상품이미지, 용량은 실제 상품과 차이가 있을 수 있습니다.</span>
              </li>
            </ul>
          </div>
        </div>
        <!--      상품 상세정보/필수표기정보 탭 영역 -->
        <div class="product_info_tab product_info_panel" v-if="directMarkets.filter(market => market.marketId === product.marketId).length === 1">
          <TabList :tabItems="tabItems" @click="setContentsType({ dataType: $event.dataType })" type="product_type1" />
          <div class="tab_content">
            <div class="product_detail_wrap" v-if="contentsType == 'detail'">
              <div v-if="product.descriptionImageUrlYn === 'Y'" style="margin: -20px -16px">
                <img :src="product.descriptionImageUrl" alt="상품 상세 이미지" style="width: 100%" />
              </div>
              <span class="product_desc" v-if="product.descriptionImageUrlYn === 'N'">
                {{ product.description }}
              </span>

              <div class="common_msg_wrap" v-if="!product.brandId">
                <span>상품에 대한 자세한 정보는</span>
                <span>
                  <span class="red">{{ product.storeName }}</span>
                  점주님께 직접 문의하시기 바랍니다.
                </span>
                <span class="tel">
                  {{ storeTel }}
                </span>
                <a v-if="appVersion" href="javascript:void(0)" class="call_btn" @click="goCallStore">
                  <span class="icon_call_red"></span>
                  <span>전화걸기</span>
                </a>
              </div>
            </div>
            <template v-if="contentsType == 'information'">
              <ul class="notational_information_list">
                <li v-for="(item, idx) in product.notationalInformationList" v-bind:key="idx">
                  <span class="info_title">{{ item.title }}</span>
                  <span class="info_content">{{ item.content }}</span>
                </li>
              </ul>
            </template>
          </div>

          <div class="product_add_info" v-if="product.brandId">
            <div v-if="product.storeName !== '산지직송 초록팜마트'">
              <div class="product_add_header">생산자정보</div>
              <table class="product_add_table">
                <colgroup>
                  <col style="width: 100px" />
                  <col />
                </colgroup>
                <tr>
                  <th>대표자명</th>
                  <td>{{ product.representative }}</td>
                </tr>
                <tr>
                  <th>전화번호</th>
                  <td>
                    <a href="javascript: void(0);" class="go_call" @click="$callNative('goCall', { tel: product.tel })">{{
                      $formatTelNumber(product.tel)
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>{{ product.businessAddress }}</td>
                </tr>
              </table>
            </div>

            <div class="product_guide_container" style="background-color: #eee">
              <div class="product_add_header">반품/환불/배송/고객센터</div>
              <div style="padding: 20px 15px">
                <strong class="subject">반품/환불</strong>
                <ul>
                  <li>브랜드몰 판매 과정상 상품에 문제가 있는 경우 교환은 불가하며, 상품 회수 후 반품(환불)만 가능합니다.</li>
                  <li>농산물 특성상 고객님의 단순 변심 또는 주관적 입장(상품 불만족 등)으로 인한 반품(환불)은 불가합니다.</li>
                  <li>주문 취소는 아래 고객센터로 연락주세요. 주문 마감일 이후나 이미 배송 출고된 경우 주문 취소가 불가합니다.</li>
                  <li>
                    <strong
                      >상품에 문제가 있는 경우 해당 부분의 사진을 꼭 남겨 놓으시고 상품 수령 후 24시간 이내에 아래 고객센터로 직접 연락주세요. (상품의
                      전체 수량이 보이는 사진, 문제부분 디테일 사진, 운송장 사진)</strong
                    ><br />
                    사진이 없으시거나, 고객센터와 협의 없이 임의 반송 또는 폐기 시 반품(환불) 처리가 불가합니다.
                  </li>
                  <li>
                    배송 사고의 경우(박스파손/배송지연) 운송장번호가 나온 송장 사진, 상품 사진, 파손된 박스 포장 사진(물품은 수령상태 그대로 보관)을
                    반드시 남겨 놓으시고 아래 고객센터로 직접 연락주세요.
                    <br />
                    고객센터와의 협의 없이 임의 반송 시 반품(환불) 처리가 불가합니다.
                  </li>
                  <li>고객센터 휴무 시 <strong>카카오톡 채널로 접수를</strong> 해주시면 접수일자 기준으로 반품(환불) 처리가 진행됩니다.</li>
                </ul>

                <div class="kakao_channel_guide">
                  <strong class="subject">카카오톡 채널 검색 방법</strong>
                  <ol class="circle_list">
                    <li>카카오톡 친구/채팅앱 > <strong>상단 [돋보기] 클릭</strong></li>
                    <!--                    <li><strong>greenfarmmart</strong> 또는 <strong>초록팜마트</strong>로 검색</li>-->
                    <li><strong>@noljang</strong> 또는 <strong>놀장</strong>으로 검색</li>
                  </ol>
                </div>

                <div class="mt_20"><strong class="subject">배송 안내</strong></div>
                <ul>
                  <li>산지 또는 배송처의 사정에 따라 배송지연이 발생할 수도 있습나다.</li>
                  <li>평균 배송기간 : 3~7일 소요 (공휴일, 주말 제외)</li>
                  <li>
                    고객의 과실로 고객정보(배송지 주소, 연락처 등) 오기나, 부재 시 보관장소에 대한 기재오류로 인해 연락 부재로 발생한 배송사고에
                    대해서는 책임지지 않습니다.
                  </li>
                  <li>제주도 및 도서산간 지역은 4,000원의 추가 배송비가 부과됩니다.</li>
                </ul>

                <div class="mt_20"><strong class="subject">고객센터</strong></div>
                <ul>
                  <li>배송일정 및 상품에 변동 사항이 발생할 경우, 주문 시 입력한 전화번호로 연락드리겠습니다.</li>
                </ul>

                <div class="customer_service_center">
                  <a href="javascript: void(0);" @click="$callNative('goCall', { tel: customerServiceCenterNumber })">
                    <div><span class="title">고객센터</span></div>
                    <div style="margin-top: -10px">
                      <strong class="phonr_number"
                        ><span>02</span><span class="minus" /><span>2039</span><span class="minus" /><span>6742</span></strong
                      >
                    </div>
                    <div style="display: flex; justify-content: center">
                      <div>상담시간</div>
                      <ul style="list-style: none; padding: 0 0 0 5px">
                        <li style="list-style: none">평일 09:30 ~ 18:30 (점심시간 12:30~14:00)</li>
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--      이 상점의 상품 더보기 영역 -->
        <div class="product_others product_info_panel" v-if="otherProducts.length && directMarkets.filter(market => market.marketId === product.marketId).length === 1">
          <div class="contents_title type1">
            <span class="title">
              <span class="red">{{ product.brandId ? product.brandName : product.storeName }}</span>
              상품 더보기
            </span>
            <a href="javascript:void(0)" class="icon_more" title="더보기" @click="moveToStore">더보기</a>
          </div>
          <div class="product_list type1">
            <ProductList :products="otherProducts" :marketId="product.marketId" noneStoreName="true" />
          </div>
        </div>
        <!--      시장 기준 내가 본 상품 리스트 영역-->
        <!--
        <div class="product_recents product_info_panel" style="min-height: 388px" v-if="recentProducts.length">
          <div class="contents_title type1">
            <span class="title">
              내가 본 상품
            </span>
          </div>
          <div class="product_list type1">
            <ProductList :products="recentProducts" :marketId="product.marketId" />
          </div>
        </div>
        -->
      </div>
      <!--      좋아요 및 장바구니 담기 버튼 영역-->
      <div class="bottom_wrap" v-if="directMarkets.filter(market => market.marketId === product.marketId).length === 1">
        <a href="javascript:void(0)" @click="clickLike" class="icon_toggle_like" :class="{ active: product.wishProductYn == 'Y' }" title="좋아요"
          >좋아요</a
        >
        <Btn class="btn_add_cart" type="type4" value="장바구니 담기" @btnCallback="navigateToProductOption">장바구니 담기 </Btn>
      </div>
      <div class="bottom_wrap" v-else>
        <Btn class="btn_add_cart" type="type4" value="장바구니 담기" @btnCallback="navigateToMall" style="width: inherit">상품 자세히보기</Btn>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapActions, mapState } from "vuex";
import ProductList from "@/components/new/ProductList";
import TabList from "@/components/new/TabList";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import Btn from "@/components/new/Btn";

export default {
  name: "Product",
  props: {},
  data: function () {
    return {
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      atLabelText: "농할",
    };
  },
  components: {
    Btn,
    LoadingIndicator,
    TabList,
    ProductList,
    TopHeader,
    Swiper,
    SwiperSlide,
  },
  beforeCreate() {
    let data = {
      productId: this.$route.params.productId,
      userId: localStorage.getItem("userId"),
    };
    this.$store.dispatch("product/initState", data);
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.authResult = this.authResult;
    window.onCartChanged = this.onCartChanged;
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions(["showModal", "appDownloadModal"]),
    ...mapActions("product", [
      "initState",
      "reset",
      "setContentsType",
      "toggleProductLike",
      "moveToStore",
      "navigateToProductOption",
      "navigateToMall",
      "goBack",
      "share",
    ]),

    /**
     * Swiper > 사용자 터치가 종료 되었을때 autoplay 적용
     */
    touchEnd() {
      const _this = this;
      _this.$refs.productSwiper.$swiper.autoplay.stop();
      setTimeout(function () {
        _this.$refs.productSwiper.$swiper.autoplay.start();
      }, 1000);
    },
    /**
     * 뒤로가기 버튼 클릭 시 기능 처리
     */
    onBackPressed() {
      if (this.isModalOpen) {
        this.$store.commit("product/setIsModalOpen", false);
        this.$modal.hideAll();
      } else {
        this.goBack();
      }
    },
    authResult(jsonString) {
      const result = JSON.parse(jsonString);
      localStorage.setItem("userId", result.userId);

      let data = {
        productId: this.$route.params.productId,
        userId: result.userId,
      };
      this.initState(data);
    },
    // 장바구니에 상품 추가 시 호출되는 콜백
    onCartChanged(jsonString) {
      if (typeof jsonString == "string") {
        jsonString = JSON.parse(jsonString);
      }
      const marketCart = jsonString;

      // 현재 장바구니 데이터 조회
      let cart = localStorage.getItem("userCart");
      if (cart) {
        cart = JSON.parse(cart);
      } else {
        cart = [];
      }

      const marketId = marketCart.marketId;

      // 해당 시장 ID로 추가된 장바구니 데이터가 있는지 체크
      const targetCart = cart.find((item) => {
        return item.marketId == marketId;
      });

      // 해당 시장 장바구니 데이터가 있는 경우
      if (targetCart) {
        targetCart.products = marketCart.products;
      } else {
        // 해당 시장 장바구니 데이터가 없는 경우 신규 추가
        cart.push(marketCart);
      }

      // 마켓기준으로 갱신된 상품의 마켓순서를 첫번째로 변경
      const targetMarket = cart.filter((market) => market.marketId == marketId);
      cart.forEach((item) => {
        if (item.marketId != targetMarket[0].marketId) {
          targetMarket.push(item);
        }
      });

      // 장바구니 데이터 재설정
      this.$store.dispatch("rootInitState", { cart: targetMarket });
    },

    goCallStore: function () {
      this.$callNative("goCall", { tel: this.storeTel });
    },

    initParams: function (data) {
      // console.log("initParams product");
      // console.log("initParams product");
      // console.log("initParams product");
      if (typeof data == "string") {
        data = JSON.parse(data);
      }
      if (data.firstInit == "Y") {
        localStorage.removeItem("isNoljangPopup");
      }
      this.$store.dispatch("rootInitState", data);
      this.$store.dispatch("main/initState", data);
    },
    clickLike: function () {
      if (this.appVersion) {
        this.toggleProductLike();
      } else {
        this.appDownloadModal();
      }
    },
  },
  computed: {
    ...mapState({
      title: (state) => state.product.title,
      product: (state) => state.product.product,
      otherProducts: (state) => state.product.otherProducts,
      recentProducts: (state) => state.product.recentProducts,
      isModalOpen: (state) => state.product.isModalOpen,
      isProgressing: (state) => state.product.isProgressing,
      tabItems: (state) => state.product.tabItems,
      contentsType: (state) => state.product.contentsType,
      regularInfo: (state) => state.product.regularInfo,
      swiperOptions: (state) => state.product.swiperOptions,
      descriptionImageUrlYn: (state) => state.product.descriptionImageUrlYn,
      descriptionImageUrl: (state) => state.product.descriptionImageUrl,
      customerServiceCenterNumber: (state) => state.customerServiceCenterNumber,
      sssMarkets: (state) => state.sssMarkets, // 서울시특판 시장 아이디
      hideAtLabelProducts: (state) => state.hideAtLabelProducts, // 농할 할인은 적용되지만 농할 라벨 보이지 않게 처리
      appVersion: (state) => state.appVersion,
      directMarkets: (state) => state.main.directMarkets,
    }),
    isProductLoaded: function () {
      return Object.keys(this.product).length > 0 ? true : false;
    },
    storeTel: function () {
      const infoList = this.product.notationalInformationList;
      return infoList && infoList.length ? infoList[infoList.length - 1].content : "";
    },
    storeClosedMsg: function () {
      return new Date().getHours() < 12 ? "영업준비중" : "영업종료";
    },
    /*
    tbDeliveryFeeText: function () {
      const text = this.product.tbDeliveryFeeText || "무료배송";
      return text === "" ? "무료배송" : text.replace("택배배송 ", "");
    },

    */
    tbDeliveryAreaExtraFeeTextJeju: function () {
      const text = this.product.tbDeliveryAreaExtraFeeText || "";
      if (text.indexOf(" / ")) {
        return text.split(" / ")[0];
      } else {
        return text;
      }
    },
    tbDeliveryAreaExtraFeeTextExtraJeju: function () {
      const text = this.product.tbDeliveryAreaExtraFeeText || "";
      if (text.indexOf(" / ")) {
        return text.split(" / ")[1];
      } else {
        return "";
      }
    },
    // tbDeliveryConditionExtraFeeText: function () {
    //   const text = this.product.tbDeliveryConditionExtraFeeText || "";
    //   return text.replace(" 무료배송", "");
    // },
  },
  created() {
    window.initParams = this.initParams;
  },
};
</script>

<style lang="scss" scoped>
.customer_service_center {
  padding: 55px 20px 20px;
  margin: 20px 0 0;
  background-color: #1f1f1f;
  background-image: url("~svg/headset.svg");
  background-repeat: no-repeat;
  background-position: center 25px;
  background-size: 32px auto;
  border-radius: 20px;
  color: white;
  text-align: center;
  > a {
    color: white !important;
    text-decoration: none;
  }
  .title {
    font-size: 21px;
    font-weight: 100;
    letter-spacing: -1px;
  }
  .phonr_number {
    font-family: gothic_a1_extrabold;
    font-size: 26px;
    font-weight: 900;
    color: $active-color;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .minus {
      width: 10px;
      height: 3px;
      margin: 0 2px;
      background-color: $active-color;
    }
  }
}

.product_guide_container {
  ul {
    margin: 0;
    //list-style-position: inside;
    li {
      position: relative;
      padding: 0 0 5px 12px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 3px;
        width: 3px;
        height: 3px;
        background-color: #555;
        //transform: translateY(-50%);
        //counter-increment: circle 1;
        //content: counter(circle);
        //font-size: 10px;
        //font-weight: bold;
      }
    }
  }
}
.kakao_channel_guide {
  padding: 15px 20px;
  margin: 20px 0 0;
  background-color: #f8f8f8;
  border-radius: 20px;
  //ol {
  //  list-style: decimal;
  //}
  .circle_list {
    margin-top: 5px;
    counter-reset: circle 0;
    list-style-position: inside;

    li {
      position: relative;
      list-style: none;
      padding-left: 18px;
      strong {
        color: $active-color;
      }
    }
    li::before {
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      counter-increment: circle 1;
      content: counter(circle);
      font-size: 10px;
      font-weight: bold;
    }
    li::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      border: 1px solid #333;
      border-radius: 50%;
      width: 1em;
      height: 1em;
    }
  }
}
.subject {
  font-size: 16px;
}

.product_add_header {
  padding: 8px 0;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.product_add_table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;

  th {
    color: #555;
  }
  td {
    padding: 5px 10px;
  }
  th,
  td {
    border: {
      style: solid;
      width: 1px;
      color: #dedede;
    }
  }
}
.contents {
  position: relative;

  .product_wrap {
    padding: 0 0 80px 0;
    overflow-y: auto;

    .product_img_wrap {
      width: 100%;
      height: 100vw;
      max-height: 400px;

      &.thumb {
        img {
          border-width: 0;
          height: 100%;
        }
      }

      .swiper-container {
        position: relative;

        .product_img_pagination {
          position: absolute;
          bottom: 16px;
          right: 16px;
          left: auto;
          width: 50px;
          height: 20px;
          @include border-radius(10px);
          color: $white;
          background-color: rgba(255, 255, 255, 0.47);
        }
      }

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .product_info_panel {
      width: 100%;
      border: {
        width: 0 0 10px 0;
        style: solid;
        color: $background-color-type1;
      }

      &:last-child {
        border: none;
      }
    }

    .product_info_wrap {
      > div {
        padding: 20px 16px;
      }

      > .product_info_main_info {
        &:first-child {
          border: {
            width: 0 0 1px 0;
            style: solid;
            color: $border-type1;
          }
        }

        > span {
          display: block;
        }

        // ProductList.vue 컴포넌트 내에도 해당 UI가 있어서 CSS 변경 시 함께 변경 필요
        .delivery_type_list {
          //display: flex;
          //flex-wrap: wrap;
          margin: 5px 0 0;

          > li {
            //display: flex;
            display: inline-block;
            //align-items: center;
            //justify-content: center;
            margin: 0 2px 0 0;
            padding: 3px 4px 2px;
            background: gray;
            @include border-radius(3px);
            @include font(12, 16);
            color: $white;

            // &:last-child {
            //   margin: 0;
            // }

            &.pkg {
              background-color: #67a3ff;
            }

            &.fd {
              background-color: #ffa033;
            }

            // &.tb {
            //   background-color: white;
            //   border: 1px solid #707070;
            //   color: #707070;
            // }

            // 새롭게 수정된 버전
            &.tb {
              padding: 3px 4px 2px;
              border: {
                style: solid;
                width: 1px;
                color: #707070;
                radius: 3px;
              }
              color: #707070;
              background-color: white;
              @include font(12, 16);
            }

            &.fc {
              background-color: white;
              border: 1px solid #707070;
              color: #707070;
            }

            &.at {
              // margin-left: auto;
              padding: 3px 4px 2px;
              border: {
                style: solid;
                width: 1px;
                color: #ff1d48;
                radius: 3px;
                //offset: -1px;
              }
              color: #ff1d48;
              background-color: $white;
              @include font(12, 16);
            }
          }
        }

        .product_title {
          position: relative;
          margin: 6px 0 0;
          @include font(18, 20, normal, medium);

          .regular_indicator {
            left: auto;
            right: 0px;
          }
        }

        .price_wrap {
          position: relative;
          margin-top: 14px;

          > span {
            &.sale_price_wrap {
              display: block;
              //height: 12px;
              line-height: 12px;
              margin-bottom: 5px;

              > span {
                display: inline-block;
                height: 12px;
                line-height: 12px;
                //vertical-align: top;

                &.discount_percent {
                  font-family: gothic_a1_bold;
                  font-size: 13px;
                  margin-right: 4px;
                  color: $nj_red;
                }

                &.origin_price {
                  font-family: gothic_a1_regular;
                  font-size: 12px;
                  color: $gray-light;
                  text-decoration: line-through;
                }
              }
            }

            &.real_price_wrap {
              // 최종 가격
              display: block;
              font-family: "gothic_a1_extrabold";
              font-size: 22px;
              line-height: 22px;
              letter-spacing: normal;

              > span {
                display: inline-block;
                vertical-align: top;
              }

              .price {
                font-family: gothic_a1_extrabold;
                font-size: 22px;
                line-height: 22px;
              }

              .price_txt {
                //padding-top: 4px;
                @include font(19, 22, normal);
              }
            }
          }

          .like_wrap {
            position: absolute;
            right: 0px;
            bottom: 4px;
            height: 13px;

            > span {
              display: inline-block;
              height: 13px;
              vertical-align: top;
            }

            &.icon_like,
            &.icon_like2 {
              background-position: 0 0;
            }

            .like_count {
              font-family: gothic_a1_regular;
              font-size: 13px;
              line-height: 16px;
              letter-spacing: normal;
            }
          }
        }

        .payment_type_list {
          margin-top: 15px;
        }
      }
      .delivery_info_wrap {
        border: {
          width: 0 0 1px 0;
          style: solid;
          color: $border-type1;
        }
        .delivery_info {
          position: relative;
          padding: 15px 15px 15px 90px;
          background-color: $background-color-type5;
          border-radius: 4px;
          .title {
            position: absolute;
            top: 15px;
            left: 15px;
            @include font(14, 16, 0, medium);
            color: $gray-ultra-dark;
          }
          .info_list {
            li {
              margin: 0 0 3px;
              @include font(13, 16, 0, normal);
              color: $gray;
              &:first-child {
                margin: 0 0 4px;
                @include font(14, 16, 0, medium);
                color: $active-color;
              }
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
      > .product_info_list {
        //background-color: $background-color-type5;
        ul {
          display: flex;
          flex-direction: column;
          li {
            display: flex;
            //position: relative;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0px;
            }

            > span {
              @include font(14, 18);

              &.title {
                min-width: 50px;
                //color: $gray;
                //font-size: 12px;
                //position: absolute;
                //top: 0;
                //left: 0;
              }

              &.content {
                //padding: 0 0 0 50px;
                color: $black-light;
                font-size: 14px;
                &.weight {
                  font-family: gothic_a1_regular;
                }
              }
            }
          }
        }
      }
    }

    .product_info_tab::v-deep {
      padding-top: 20px;

      > ul {
        padding: 0 16px;
        border: {
          width: 1px 0 1px 0;
          style: solid;
          color: #dcdcdc;
        }

        > li {
          > a:not(.active) {
            color: $gray;
            font-weight: 600;
          }
        }
      }

      .tab_content {
        padding: 20px 16px;
        min-height: 300px;

        .product_detail_wrap {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .product_desc {
            min-height: 150px;
            @include font(13, 22, normal, bold);
            white-space: pre-line;
          }

          .common_msg_wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 20px 0 0;
            padding: 20px 0 0;
            border: {
              width: 1px 0 0;
              style: solid;
              color: $border-type2;
            }

            > span {
              @include font(13, 22);
            }

            .red {
              @include font(13, 22, normal, bold);
              color: $active-color;
            }

            .tel {
              margin: 15px 0 0;
              min-height: 22px;
            }

            .call_btn {
              display: flex;
              align-items: center;
              margin: 10px 0 0;
              padding: 5px;
              border: {
                width: 1px;
                style: solid;
                color: $border-type2;
              }
              @include border-radius(5px);

              > span {
                @include font(13, 23);
                color: $black-light;

                &.icon_call_red {
                  width: 23px;
                  height: 23px;
                }
              }
            }
          }
        }

        .notational_information_list {
          > li {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0px;
            }

            .info_title,
            .info_content {
              display: block;
            }

            .info_title {
              //width: 100px;
              @include font(13, 18, normal, bold);
              color: $gray-middle-dark;
            }

            .info_content {
              margin: 6px 0 0 10px;
              @include font(13, 18);
              color: $gray;
            }
          }
        }
      }
    }

    .product_others {
      .contents_title {
        > .title {
          display: block;
          width: calc(100% - 25px);
          > .red {
            color: $active-color;
          }
        }
      }
    }

    //.product_recents {
    //  .market_name_wrap {
    //    @include font(18, 18, 0);
    //    color: $gray-normal;
    //
    //    .separator {
    //      padding: 0 2px;
    //    }
    //  }
    //}
  }

  .bottom_wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 13px 16px;
    background-color: $white;
    border: {
      width: 1px 0 0 0;
      style: solid;
      color: $border-type1;
    }

    > .icon_toggle_like {
      text-indent: -9999px;
    }

    > .btn_add_cart {
      float: right;
      width: calc(100% - 62px);
      @include border-radius(10px);
      text-align: center;
      @include font(16, 54, normal, medium);
    }
  }
}

.ios {
  .like_wrap {
    height: 14px;
  }
  .product_info_main_info {
    .delivery_type_list {
      .at {
        padding: 1px 4px 1px;
      }
    }
  }
}

.go_call {
  color: #333 !important;
  text-decoration: underline !important;
}
.btn_share {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  background-image: url("~image/version_3_5/share.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  text-indent: -9999px;
}

.discount_txt {
  margin: 10px 0 0;
  @include font(14, 18);
  color: #333;
}
</style>
