<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" :isCartVisible="false" style="position: fixed; top: 0; width: 100%; z-index: 999" />
    <div class="contents" style="padding: 58px 0 0; height: 100%; overflow: unset">
      <LoadingIndicator v-if="isProgressing" />
      <!-- 택배 상품이 고려되지 않아 아래 코드 삭제 처리  -->
      <!--      <div class="order_notice_wrap">
        <span>
          상품관련 교환 및 환불 문의는<br/>
          <span class="red">배달 완료 후 3시간 이내에 가능</span>합니다.
        </span>
      </div>-->
      <div class="order_group_list">
        <ul>
          <li v-for="(orderGroup, idx) in orderGroups" :key="idx">
            <span class="order_group_title">{{ $moment(orderGroup.orderDate).format("YYYY.M.D (dd)") }}</span>
            <ul class="order_list">
              <li class="order_item" v-for="order in orderGroup.orders" :key="order.orderId">
                <div class="order_item_top">
                  <span class="order_status" v-if="order.paymentStatus"
                    >{{
                      order.paymentStatus == "PC" || order.paymentStatus == "C"
                        ? order.paymentStatus == "PC"
                          ? "부분 환불"
                          : "전체 환불"
                        : order.orderStatus
                    }}
                  </span>
                  <span class="order_status" v-else>{{ order.orderStatus }}</span
                  ><!-- 운영 정상 반영후 삭제 처리 -->

                  <a class="order_detail_btn" @click="moveToOrderDetail(order.orderId)">주문 상세보기</a>
                </div>
                <span class="order_title">
                  {{ order.orderTitle }}
                </span>
                <span class="order_payment_wrap">
                  <span class="payment">{{ $formatPrice(order.payment) }}</span>
                  <span class="payment_txt">원</span>
                </span>
                <span class="order_market_name">{{ order.marketName }}</span>
                <span class="order_id" v-html="'주문번호&nbsp;&nbsp;' + order.orderId.slice(-4)" />
                <span class="order_date_time" v-html="'주문일시&nbsp;&nbsp;' + $moment(order.orderDateTime).format('YYYY.MM.DD dddd a hh:mm')" />
                <!--                <span class="order_cancel_notice" v-if="order.orderStatus == '결제 완료'">-->
                <!--                  주문 취소는 결제 후 5분 내에 할 수 있습니다.-->
                <!--                </span>-->
                <span class="order_btn_wrap" v-if="order.orderType && order.orderType != 'TB'">
                  <Btn :type="order.hasReview == 'X' ? 'type7' : 'type2'" @btnCallback="moveToReview(order)">
                    <template v-if="order.hasReview == 'Y'"> 리뷰보기 </template>
                    <template v-else-if="order.hasReview == 'N'">
                      리뷰쓰기
                      <span class="left_review_time">{{ order.leftReviewTime + " 남음" }}</span>
                    </template>
                    <template v-else-if="order.hasReview == 'X'"> 리뷰쓰기 기간만료 </template>
                  </Btn>
                  <Btn
                    :value="order.hasInquiry == 'Y' ? '주문문의보기' : order.hasInquiry == 'N' ? '주문문의' : '주문문의 기간만료'"
                    :type="order.hasInquiry == 'X' ? 'type7' : 'type4'"
                    @btnCallback="moveToInquiry(order)"
                  />
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapGetters, mapState } from "vuex";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import Btn from "@/components/new/Btn";

export default {
  name: "OrderHistory",
  props: {},
  data: function () {
    return {};
  },
  components: {
    Btn,
    LoadingIndicator,
    TopHeader,
  },
  beforeCreate() {
    this.$store.dispatch("orderHistory/initState");
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.onBackPressed = this.goBack;
  },
  beforeDestroy() {
    // 별도 Params 없이 로컬스토리지 내의 userId 만을 이용하여 동작하는 화면이기 때문에, 화면 종료 시 항상 데이터 초기화해도 이슈 X
    // Params 를 넘겨받는 화면의 경우 화면 종료 시점에 데이터를 초기화하면
    // 뒤로가기 등으로 해당 화면에 재접속 시 화면 구성에 필요한 필수 파라미터 값이 없어 동작하지 않을 수도 있음
    // this.$store.dispatch('orderHistory/reset');
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    goBack() {
      this.$store.dispatch("orderHistory/reset");
      this.$navigateBack();
    },
    moveToOrderDetail(orderId) {
      this.$router.push({ name: "주문상세", params: { orderId: orderId } });
    },
    moveToReview(order) {
      if (order) {
        const hasReview = order.hasReview;
        const orderId = order.orderId;
        if (hasReview == "Y") {
          this.$router.push({
            name: "리뷰쓰기",
            params: {
              orderId: orderId,
              pageMode: "view",
            },
          });
        } else if (hasReview == "N") {
          if (this.isReviewAvailable(order)) {
            this.$router.push({
              name: "리뷰쓰기",
              params: {
                orderId: orderId,
                pageMode: "write",
              },
            });
          } else {
            this.$showToast("리뷰쓰기는 주문일로부터 3일 동안만 가능합니다.");
            this.$store.dispatch("orderHistory/toggleReview", order);
          }
        } else if (hasReview == "X") {
          this.$showToast("리뷰쓰기는 주문일로부터 3일 동안만 가능합니다.");
          return false;
        }
      }
    },
    moveToInquiry(order) {
      const hasInquiry = order.hasInquiry;
      if (hasInquiry == "Y") {
        this.$router.push({
          name: "상품문의보기",
          params: {
            orderId: order.orderId,
            marketId: order.marketId,
            pageMode: "view",
          },
        });
      } else if (hasInquiry == "N") {
        // if (this.isInquiryAvailable(order)) {
        this.$router.push({
          name: "상품문의쓰기",
          params: {
            orderId: order.orderId,
            marketId: order.marketId,
            pageMode: "write",
          },
        });
        // }
        // else {
        //   this.$showToast('상품관련 교환 및 환불 문의는 배달 완료 후 3시간 이내에만 가능합니다.');
        //   this.$store.dispatch('orderHistory/toggleInquiry', order);
        // }
      } else if (hasInquiry == "X") {
        this.$showToast("상품문의는 배달완료 후 3시간 이내에 가능합니다.");
        return false;
      }
    },
    // 주문시간으로부터 3일 이내인 경우 true
    isReviewAvailable(order) {
      return this.$moment(order.orderDateTime).add(this.reviewLimitDay, "d").isAfter(this.$moment());
    },
    handleScroll() {
      if (!this.isProgressing) {
        const eventTarget = document.querySelector("html");
        if (eventTarget.scrollTop + eventTarget.offsetHeight > eventTarget.scrollHeight - 150) {
          if (this.totalPage - 1 > this.currentPage) {
            this.$store.dispatch("orderHistory/getOrderList", this.currentPage + 1);
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      title: (state) => state.orderHistory.title,
      isProgressing: (state) => state.orderHistory.isProgressing,
      currentPage: (state) => state.orderHistory.currentPage,
      totalPage: (state) => state.orderHistory.totalPage,
      reviewLimitDay: (state) => state.orderHistory.reviewLimitDay,
      inquiryLimitHour: (state) => state.orderHistory.inquiryLimitHour,
    }),
    ...mapGetters({
      orderGroups: "orderHistory/orderGroups",
    }),
  },
};
</script>

<style lang="scss" scoped>
.contents {
  .order_notice_wrap {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $background-color-type1;

    span {
      @include font(13, 16, normal, bold);

      &.red {
        color: $active-color;
      }
    }
  }

  .order_group_list {
    .order_group_title {
      display: flex;
      padding: 20px 16px;
      @include font(14, normal, -0.56, bold);
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }
    }
  }

  .order_list {
    padding: 0 0 10px 0;
    background-color: $background-color-type1;

    .order_item {
      display: flex;
      flex-direction: column;
      padding: 20px 16px;
      background-color: $white;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }

      .order_item_top {
        display: flex;

        > span,
        > a {
          @include font(12, 12, normal, bold);

          &.order_status {
            flex: 1;
            color: $active-color;
          }

          &.order_detail_btn {
            display: inline-block;
            padding: 0 0 1px;
            border-bottom: 1px solid #aaa;
            color: $gray;
            text-align: right;
            // text-decoration: underline !important;
          }
        }
      }

      .order_title {
        margin: 12px 0 0 0;
        @include font(14, 16, normal, bold);
      }

      .order_payment_wrap {
        display: flex;
        align-items: center;
        margin: 9px 0 0 0;

        > .payment {
          font-family: gothic_a1_extrabold;
          font-size: 16px;
          line-height: 16px;
        }

        > .payment_txt {
          @include font(15, 15);
        }
      }

      .order_market_name,
      .order_id,
      .order_date_time {
        @include font(12, normal, -0.48);
        color: $gray-light;
      }

      .order_market_name {
        margin: 14px 0 0 0;
      }

      .order_id,
      .order_date_time {
        margin: 10px 0 0 0;
      }

      .order_cancel_notice {
        display: block;
        margin: 12px 0 0 0;
        padding: 12px 0;
        @include font(12);
        @include border-radius(18px);
        color: $gray-light;
        background-color: $background-color-type1;
        text-align: center;
      }

      .order_btn_wrap {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc(50% - 5px);
          height: 38px;
          @include border-radius(15px);
          @include font(14);

          &:only-child {
            width: 100%;
          }

          .left_review_time {
            margin: 0 0 0 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
