<template>
  <div class="wrap">
    <TopHeader @goBack="goBack" :title="title" theme="nj_red" :isButton="false" />
    <div class="contents_wrap">
      <template v-if="markets.length > 0">
        <ul class="contents_list">
          <li v-for="(market, index) in markets" :key="market.marketId" :style="'z-index: ' + (markets.length - index)">
            <MarketProductList
              :customerMarginTop="customerMarginTop"
              :marketName="market.marketName"
              :marketId="market.marketId"
              :products="market.products"
              :expand="market.expand"
              @add="updateQuantity"
              @remove="updateQuantity"
              @expand="expandMarket"
              @delete="deleteProduct"
              @payment="paymentMarket"
            />
          </li>
        </ul>
      </template>
      <div class="no_product_msg" v-else>장바구니에 담긴 상품이 없습니다.</div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import MarketProductList from "../../components/cart/MarketProductList";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "CartShip",
  props: {
    params: Object,
  },
  data() {
    return {
      title: "장바구니",
    };
  },
  components: {
    TopHeader,
    MarketProductList,
  },
  methods: {
    ...mapMutations("cartShip", [
      "expandMarket", // 상품 영역 확장 및 축소
    ]),
    ...mapActions("cartShip", [
      "goBack",
      "deleteProduct", // 상품 삭제
      "updateQuantity", // 상품 증가, 감소
      // 'addProductQnt',  // 상품 추가
      // 'delProductQnt',  // 상품 빼기
      "paymentMarket", // 결제하기
    ]),
    onBackPressed: function () {},
    initParams: function (data = null) {
      if (data) {
        var sendData = JSON.parse(data);
        this.$store.dispatch("cartShip/initState", {
          cart: sendData.cart,
        });
      }
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.initParams = this.initParams;
  },
  beforeCreate: function () {
    if (this.$route.params.cart) {
      // dummy data 적용 ::: 개발모드
      // console.log(this.$route.params.cart);
      this.$store.dispatch("cartShip/initState", {
        // userId: this.$route.query.userId? this.$route.query.userId : 'efe7f717-fdae-4ab1-a08f-d7c6e8803c9c',
        cart: this.$route.params.cart,
      });
    }
  },
  computed: {
    ...mapState({
      markets: (state) => state.cartShip.markets,
      customerMarginTop: (state) => state.cartShip.customerMarginTop,
    }),
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}

.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  padding: {
    top: 0px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
  overflow: auto;
  .contents_list {
    padding: 0 0 80px;
    > li {
      position: relative;
      background-color: $nj_background_gray;
      overflow: hidden;
      &:first-child {
        padding-top: 0;
        border-width: 0;
      }
    }
  }
}
.no_product_msg {
  padding-top: 100px;
  @include font(16, 22, -0.96, medium);
  text-align: center;
  color: $nj_text_sub_gray;
}
</style>
