const getState = () => {
  return {
    userId: localStorage.getItem("userId"),
    versionCode: 0,
    versionName: "",
    versionUrl: "",
    versionHtmlTags: "",
    versionList: [],
  };
};

const state = getState();

const mutations = {
  initState: function (state, data) {
    state.userId = data.userId;

    state["versionCode"] = data.versionCode;
    state["versionName"] = data.versionName;
    state["versionUrl"] = data.versionUrl;
    state["versionHtmlTags"] = data.versionHtmlTags;
    state["versionList"] = data.versionList;
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },

  updateState: function (state, data) {
    if (data.type === "zipCode") {
    }
    if (data.append) {
      // 데이터를 추가할때 적용
      if (state[data.type]) {
        state[data.type] = state[data.type].concat(data.updateData);
      } else {
        state[data.type] = data.updateData;
      }
    } else {
      state[data.type] = data.updateData;
    }
    if (data.callback) {
      data.callback();
    }
  },
};

const actions = {
  showHideLayer: function (store, data) {
    if (!data) {
      state.displayLayer = !state.displayLayer;
    } else if (data.btnId && data.btnId == "WITHDRAW") {
      store.dispatch("withdrawal");
    } else {
      state.displayLayer = !state.displayLayer;
    }
  },
  initState: async function (store, data) {
    state.userId = data.userId;

    state.versionCode = data.versionCode ? data.versionCode : null;
    state.versionName = data.versionName;
    state.versionUrl = data.versionUrl;
    state.versionHtmlTags = data.versionHtmlTags;
    state.versionList = data.versionList;

    store.commit("updateState", { type: "versionCode", updateData: state.versionCode });
  },
  btnCallback: function (store, btnData) {
    const { name, data } = btnData;
    if (name === "SELECT_VERSION") {
      store.dispatch("setModalBottom");
    } else if (data) {
      if (data.name === "SAVE") {
      }
    }
  },
  setModalBottom: function (store) {
    const selectCallback = async (data) => {
      if (data) {
        const { versionCode, versionName } = state.versionList.find((prop) => prop.versionCode === data);
        store.commit("updateState", { type: "versionCode", updateData: String(versionCode) }, { root: true });
        store.commit("updateState", { type: "versionName", updateData: versionName }, { root: true });

        // 클릭됨에 따라 내용 변경
        const privacyNewUrl = "/v3/noljang/privacy/versions/info?code=" + versionCode;
        const response = await this._vm.$axios1.get(privacyNewUrl, { headers: { userId: state.userId } });
        const result = response.data.result;

        if (response.data.responseCode === 2000) {
          store.commit("updateState", { type: "versionHtmlTags", updateData: result.versionHtmlTags });
        }
      }
      this.dispatch("setModalBottom", {
        showHide: false,
      });
    };
    store.dispatch(
      "setModalBottom",
      {
        showHide: true,
        type: "SELECT",
        callback: selectCallback,
        activeId: String(state.versionCode),
        options: state.versionList,
      },
      { root: true },
    );
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
