import router from "@/router";

const getState = () => {
  return {
    userId: localStorage.getItem("userId"),
    memberName: null,
    phoneNumber: null,
    memberAddress: null,
    // userAddrtess: JSON.parse(localStorage.getItem("userAddress")),
    isProgressing: false,
    marketInfo: {
      marketId: null,
      marketName: null,
    },

    marketOrderType: null, // marketOrderType === 'TG' 이면 onSiteYn 값이 없더라도 'Y' 값으로 적용 한다.
    onSiteYn: null,
    deliveryType: null,
    deliveryTbType: null, // 택배 배송 상세타입 : PREDELIVERY = 선결제, CASH_PAYMENT = 착불, MIXED_PAYMENT = 선결제 + 착불
    deliveryApplyType: null,
    orders: [],
    radioCheckValue: null, // 결제수단
    radioOptions: null, // 결제옵션
    // [
    // { code: "1", text: "온누리모바일상품권", active: true, guideTxt: "기존의 구매하신 온누리 상품권으로 결제가 가능합니다." },
    // { code: "2", text: "서울사랑상품권", active: false, guideTxt: "기존의 구매하신 상품권으로 결제가 가능합니다." },
    // { code: "3", text: "놀장페이", active: false, guideTxt: "카드/계좌로 간편하게 결제하실 수 있습니다." },
    // ],
    radioTypeGuideTxt: null,

    recevieTypeActiveText: "직접수령(부재시 문 앞)",
    recevieTypeCheckValue: "DIRECT",
    recevieTypeRadioOptions: [
      {
        code: "DIRECT",
        text: "직접수령(부재시 문 앞)",
        active: true,
      },
      {
        code: "DOOR",
        text: "문 앞",
        active: false,
      },
      {
        code: "BELL",
        text: "초인종 누르고 문 앞",
        active: false,
      },
      {
        code: "PHONE",
        text: "도착하면 전화",
        active: false,
      },
      {
        code: "OFFICE",
        text: "경비실",
        active: false,
      },
    ],

    coupons: [],
    useCoupon: null,
    couponUseYn: "Y",
    totalProductPrice: 0, // 총 상품금액 : 할인 받기전 금액
    shippingFee: 0, // 배달비
    deliveryDiscount: 0, // 배달비 할인
    cashDeliveryTB: 0, // 택배선결제시 착불 금액
    eventDeliveryDiscountId: null, // 배달비 할인 아이디
    deliveryPromotionId: null, // 배달비 할인 아이디 - 이전 프로세스
    eventDiscount: 0, // 이벤트 할인
    eventPaymentDiscountId: null, // 이벤트 할인 아이디
    totalPaymentPrice: 0, // 총 결제금액
    totalPaymentPriceExceptPoint: 0, // 포인트를 제외한 결제금액
    userPoint: 0, // 사용자 보유 포인트
    usePoint: 0, // 사용자가 사용하려고 입력한 포인트에서 남은 포인트
    pointPrice: 0, // 포인트

    eventDeliveryDiscountUseYn: null, // 배달비 할인 사용 여부
    eventDeliveryDiscountConditionYn: null, // 배송비 할인 가이드 텍스트 사용여부
    eventDeliveryDiscountConditionMessage: null, // 배송비 할인 가이드 텍스트

    eventPaymentDiscountUseYn: null, // 이벤트 할인 사용 여부
    eventPaymentDiscountMessage: null, // 이벤트 할인 가이드 텍스트

    issueCoupon: null, // 쿠폰 발행
    deliveryRequest: null, // 배달 기사님에게 요청
    atEventId: null, // 농할 이벤트 할인 아이디
    windowInnerWidth: null,
    // atUseBoolean: false,

    discountAtEventContent: null,
    expectedArrivalTime: "2022-11-25",

    // 포장 할인가
    // packagingTotalDiscountPrice: 0,// TODO 사용안함 - 삭제처리
    tgSaleAmount: 0,
    distType: null,

    useDeliveryDesiredDate: false, // 배송희망일 사용여부
  };
};

const state = getState();

const mutations = {
  moveToCalendar: function () {
    // console.log("moveToAddress");
    router.push({ name: "상품도착 희망일 선택", params: { startDt: "2022-11-05", endDt: "2022-12-10" } });
  },
  moveToAddress: function () {
    // console.log("moveToAddress");
    router.push({ name: "배송지설정", params: { userId: state.userId, isFromPayment: true } });
  },
  moveToPoint: function () {
    // console.log("moveToPoint");
    if (this._vm.$isProd()) {
      // 운영
      this._vm.$navigateTo("open-url", {
        title: "포인트 내역",
        url: "http://noljang.co.kr/deployment/V3/member/point-history/view.html?userId=" + state.userId,
      });
    } else {
      // 개발
      this._vm.$navigateTo("open-url", {
        title: "포인트 내역",
        url: "http://noljang.com/test/V3/member/point-history/view.html?userId=" + state.userId,
      });
    }
  },
  moveToAgreePrivate: function () {
    // console.log("moveToAgreePrivate");
    this._vm.$navigateTo("open-url", {
      title: "개인정보 제공 동의",
      url: "https://noljang.co.kr/deployment/member/agreePrivate.html",
    });
  },
  moveToGiftCertificateGuide: function () {
    // console.log("moveToGiftCertificateGuide");
    this._vm.$navigateTo("open-url", {
      title: "모바일 온누리상품권 구입방법",
      url: "https://s3.ap-northeast-2.amazonaws.com/noljang-ops/event/detail_20200713_105407.html?userId=" + state.userId,
    });
  },
  moveToNoljangPayGuide: function () {
    // console.log("moveToNoljangPayGuide");
    this._vm.$navigateTo("open-url", {
      title: "놀장페이 사용법",
      url: "https://s3.ap-northeast-2.amazonaws.com/noljang-ops/event/detail_20211025_112834.html?userId=" + state.userId,
    });
  },
  moveToCoupon: function (state) {
    router.push({
      name: "보유쿠폰",
      params: {
        userId: state.userId,
        marketId: state.marketInfo.marketId,
        // totalPrice: state.totalPaymentPrice,
        totalPrice: state.totalProductPrice,
      },
    });
  },
  changeRadio: function (state, value) {
    state.radioCheckValue = value;
    localStorage.setItem("paymentType", value);

    state.radioOptions.forEach((radio) => {
      radio.active = false;
      if (radio.code == value) {
        radio.active = true;
        state.radioTypeGuideTxt = radio.guideTxt;
      }
    });
    // console.log(state.radioOptions);
  },
  updateState: function (state, data) {
    if (data.append) {
      // 데이터를 추가할때 적용
      if (state[data.type]) {
        state[data.type] = state[data.type].concat(data.updateData);
      } else {
        state[data.type] = data.updateData;
      }
    } else {
      state[data.type] = data.updateData;
    }
    if (data.callback) {
      data.callback();
    }
  },

  goBack: function () {
    this.commit("paymentNew/reset");
    this.dispatch("setOrderProducts", null);
    localStorage.removeItem("setProductRequest");
    localStorage.removeItem("paymentType");

    // router.push({ name: "장바구니" });
    this._vm.$navigateBack();
  },
  reset: function (state) {
    // console.log("reset");
    Object.assign(state, getState());
  },
};

const actions = {
  initPaymentSetting: function (store) {
    // console.log("initPaymentSetting");
    const recevieTypeRadioOptions = [
      {
        code: "DIRECT",
        text: "직접수령(부재시 문 앞)",
        active: true,
      },
      {
        code: "DOOR",
        text: "문 앞",
        active: false,
      },
      {
        code: "BELL",
        text: "초인종 누르고 문 앞",
        active: false,
      },
      {
        code: "PHONE",
        text: "도착하면 전화",
        active: false,
      },
      {
        code: "OFFICE",
        text: "경비실",
        active: false,
      },
    ];

    localStorage.removeItem("paymentType");
    localStorage.removeItem("setProductRequest");

    store.commit("updateState", { type: "recevieTypeActiveText", updateData: "직접수령(부재시 문 앞)" });
    store.commit("updateState", { type: "recevieTypeCheckValue", updateData: "DIRECT" });
    store.commit("updateState", { type: "recevieTypeRadioOptions", updateData: recevieTypeRadioOptions });
    store.commit("updateState", { type: "pointPrice", updateData: 0 });
    store.commit("updateState", { type: "deliveryRequest", updateData: null });
  },
  setRecevieType: function (store) {
    // const callback = (data) => {
    //   _this._vm.$modal.hideAll();
    //   console.log(data);
    // };

    const _this = this;
    const modalName = "recevie_type_modal";
    const compProps = {
      title: "수령방법",
      radioCheckValue: state.recevieTypeCheckValue,
      radioOptions: state.recevieTypeRadioOptions,
      type: "radio",
      callback: function (event) {
        // console.log(event);
        // console.log(event.target.value);

        const recevieTypeCheckValue = event.target.value;
        store.commit("updateState", { type: "recevieTypeCheckValue", updateData: recevieTypeCheckValue });

        // recevieTypeActiveText
        state.recevieTypeRadioOptions.forEach((radio) => {
          radio.active = false;
          if (radio.code == recevieTypeCheckValue) {
            radio.active = true;
            // state.recevieTypeActiveText = radio.text;
            store.commit("updateState", { type: "recevieTypeActiveText", updateData: radio.text });
          }
        });

        _this._vm.$modal.hide(modalName);
      },
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
  },
  setAlert: function (store, data) {
    // const callback = (isConfirm) => {
    //   _this._vm.$modal.hideAll();
    //   if (isConfirm) {
    //     console.log("결제하기");
    //   }
    // };

    const _this = this;
    const modalName = "alert_modal";
    const compProps = {
      title: data.title,
      msgArr: data.msg, // html 코드 적용 가능
      btnArr: data.btnArr,
      type: data.type ? data.type : "alert",
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
  },
  focusProductRequest: function (store, event) {
    const nextElementSibling = event.currentTarget.nextElementSibling;
    if (nextElementSibling) {
      if (nextElementSibling.classList.contains("speech_bubble_wrap")) {
        nextElementSibling.style.display = "none";
      }
    }
  },
  inputProductRequest: async function (store, event) {
    // console.log("inputProductRequest");
    // console.log(event.currentTarget.dataset.productId);
    // console.log(event.currentTarget.value);
    // console.log(state.orders);
    await state.orders.forEach((product) => {
      if (product.productId == event.currentTarget.dataset.productId) {
        product.orderRequest = event.currentTarget.value;
      }
    });
    // console.log(state.orders);
    localStorage.setItem("setProductRequest", JSON.stringify(state.orders));
  },
  inputDeliveryRequest: function (store, event) {
    // console.log("inputDeliveryRequest");
    // console.log(event.currentTarget.value);
    store.commit("updateState", { type: "deliveryRequest", updateData: event.currentTarget.value });
  },
  paymentOrder: async function (store, paymentType = null) {
    if (state.marketInfo.marketId === 168 && !state.expectedArrivalTime) {
      document.getElementById("expectedArrivalTime").scrollIntoView({ behavior: "smooth" });
      store.dispatch("setMsg", { msgText: "상품도착 희망일을 선택해 주세요." }, { root: true });
      return;
    }

    var errorMsg = null;
    if (state.marketOrderType != "TB") {
      // 1. 주소를 로컬 스토리지에서 따온다.
      const userAddress = JSON.parse(localStorage.getItem("userAddress"));
      // 2. 전역 스토어 orders를 순회하여 productId가 담긴 배열을 만든다.
      const productIds = state.orders.map((product) => product.productId);

      // 3. 아래의 try문은 특정 상점이 현재 장사를 하고있는지를 체크하는 것으로 보임.
      try {
        // validationUrl : 마켓id + 좌표를 이용해 뭔가를 만드는 듯?
        const validationUrl = `v3/marketId/${state.marketInfo.marketId}/latitude/${userAddress.latitude}/longitude/${
          userAddress.longitude
        }/validation?productIds=${productIds.join()}&tgYn=${state.marketOrderType === "TG" ? "Y" : "N"}`;
        const validation = await this._vm.$axios2.get(validationUrl);
        if (validation.data.responseCode == 2000) {
          if (validation.data.result?.orderValidationInfo.marketStatus.includes("정상 영업")) {
          } else {
            const orderValidationInfo = validation.data.result?.orderValidationInfo;
            errorMsg = `<p style="margin: 0 0 10px; font-weight: bold;">${
              validation.data.result?.buttonText === "주문시간이 종료되었습니다." ||
              validation.data.result?.buttonText === "유효하지 않은 상품이 존재합니다."
                ? validation.data.result?.buttonText
                : orderValidationInfo?.marketStatus
            }</p>
                <div style="display: ${orderValidationInfo?.orderTime ? "flex" : "none"}; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">주문시간</strong>
                    <span>${orderValidationInfo?.orderTime.replace(/\n/gi, "<br/>")}</span>
                </div>
            `;
            /*errorMsg = `<p style="margin: 0 0 10px; font-weight: bold;">${orderValidationInfo?.marketStatus}</p>
                <div style="display: ${orderValidationInfo?.orderTime? 'flex' : 'none'}; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">주문시간</strong>
                    <span>${orderValidationInfo?.orderTime.replace(/\n/gi, '<br/>')}</span>
                </div>
                <div style="display: ${orderValidationInfo?.deliveryFee? 'flex' : 'none'}; margin: 0 0 10px; font-weight: normal; text-align: left;">
                    <strong style="width: 70px;">배달비</strong>
                    <span>${orderValidationInfo?.deliveryFee.replace(/\n/gi, '<br/>')}</span>
                </div>
                <div style="display: ${orderValidationInfo?.tgInfo? 'block' : 'none'}; font-weight: normal; text-align: left;">
                    <span>${orderValidationInfo?.tgInfo.replace(/\n/gi, '<br/>')}</span>
                </div>
            `;*/
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    // 4. 뭐하는지 잘 모르겠음. 모달창을 만드는 것 같음.
    if (errorMsg != null) {
      this.dispatch("paymentNew/setAlert", {
        title: "결제하기",
        msg: [errorMsg],
        btnArr: [],
      });
      return;
    }

    // 5. 결제수단 확인
    if (!paymentType) {
      paymentType = "ONR";
      if (state.radioCheckValue == "1" || state.radioCheckValue == "11") {
        paymentType = "ONR"; // 온누리, 수산물
      } else if (state.radioCheckValue == "2") {
        paymentType = "ZB"; // (구)서울사랑상품권 ---> 제로페이 상품권
      } else if (state.radioCheckValue == "3") {
        paymentType = "KB"; // 국민은행
      } else if (state.radioCheckValue == "4") {
        paymentType = "NICE"; // 나이스페이먼츠
      } else if (state.radioCheckValue == "5") {
        paymentType = "SP"; // (신)서울사랑상품권 ---> 서울페이+(서울배달+) 상품권
      } else if (state.radioCheckValue == "12") {
        paymentType = "CH"; // 지역사랑상품권
      } else if (state.radioCheckValue == "15") {
        paymentType = "BIZ_ONR"; // (신)모바일 온누리상품권
      }
    }

    // console.log("paymentType : ", paymentType);
    // console.log("state.radioCheckValue : ", state.radioCheckValue);
    // paymentType = "NJP" ---> 놀장 포인트로만 결제할때

    var getOptionData = function (productIndex) {
      var optionGroups = [];
      const productOptionGroups = state.orders[productIndex];
      if (productOptionGroups.optionGroups) {
        productOptionGroups.optionGroups.map((optGroup) => {
          optionGroups.push({
            optionGroupId: optGroup.optionGroupId,
            optionItems: optGroup.items.map((item) => {
              return { optionItemId: item.optionItemId, optionPrice: item.optionPrice, optionSellerPrice: item.optionItemSellerPrice };
            }),
          });
        });
        return optionGroups;
      } else {
        return null;
      }
    };

    // 6. orders를 순회하여 상품과 상품 인덱스를 가져다가 products 배열에 push
    var products = [];
    state.orders.map((product, productIndex) => {
      const productObj = {
        // optionPrice: product.totalOptionPrice,
        // totalPrice: product.totalPrice,
        marketId: state.marketInfo.marketId,
        productId: product.productId,
        storeId: product.storeId,
        unitPrice: product.discountPrice ? product.discountPrice : product.price,
        etcDiscount: 0, // 3000 ---> Native에서 적용되고 있지 않음
        quantity: product.quantity,
        optionGroups: getOptionData(productIndex),
        etcEventId: null, // 9 ---> Native에서 적용되고 있지 않음
        // price: null, // 500 ---> Native에서 적용되고 있지 않음
        orderRequest: product.orderRequest ? product.orderRequest : null,
        // sellerPrice: product.sellerPrice,
        // eventDiscount: null, // 2000 ---> 사용여부 API에서 확인 중
      };
      products.push(productObj);
    });

    const orderType = state.marketOrderType ? state.marketOrderType : state.deliveryType;

    // 7. 전송할 데이터를 만듦
    var sendData = {
      orderType: orderType,
      userId: state.userId,
      marketId: state.marketInfo.marketId,

      totalPayment: {
        paymentType: paymentType,
        deliveryFee: state.shippingFee,
        deliveryDiscount: state.shippingFee > 0 ? state.deliveryDiscount : 0,
        couponDiscount: state.useCoupon ? state.useCoupon.couponPrice : 0,
        point: state.pointPrice,
        payment: state.totalPaymentPrice,
        eventDiscount: state.eventDiscount,
        cashDeliveryTB: state.cashDeliveryTB,
        deliveryFeeMethodTB: state.deliveryTbType,
        deliveryApplyType: state.deliveryApplyType,
        tgDc: state.tgSaleAmount,
      },

      agreeUsePrivacy: "Y",
      delivery: {
        jibunAddress: state.memberAddress.shipToJibun,
        roadAddress: state.memberAddress.shipToRoad,
        detailAddress: state.memberAddress.shipToDetail,
        latitude: Number(state.memberAddress.latitude),
        longitude: Number(state.memberAddress.longitude),
        receiveMethod: state.recevieTypeActiveText,
        deliveryRequest: state.deliveryRequest ? state.deliveryRequest : "",
      },
      products: products,
      deliveryPromotionId: state.deliveryPromotionId ? String(state.deliveryPromotionId) : null,
      couponId: state.useCoupon ? String(state.useCoupon.couponId) : null,
      eventDeliveryDiscountId: state.eventDeliveryDiscountId ? String(state.eventDeliveryDiscountId) : null,
      eventPaymentDiscountId: state.eventPaymentDiscountId ? String(state.eventPaymentDiscountId) : null,
      atEventId: Number(state.atEventId),
      distType: orderType === "TG" ? null : state.distType,
      expectedArrivalTime: state.marketInfo.marketId === 168 ? `${state.expectedArrivalTime} 09:00:00` : null,
    };

    if (paymentType === "CH") {
      const token = await this.dispatch("chackToken");

      if (token) {
        sendData.Authentication = token;
      } else {
        console.log("착 토큰 에러");
        return;
      }
    }

    debugger;

    // 백엔드로 통신 보냄.
    const paymentOrderUrl = "/v3/orders";
    try {
      const paymentOrderResult = await this._vm.$axios2.post(paymentOrderUrl, sendData);
      // console.log(paymentOrderResult);
      if (paymentOrderResult.data.responseCode == 2000) {
        // console.log(paymentOrderResult.data.result.paymentUrl);
        this._vm.$navigateTo("open-url", {
          title: "결제진행",
          url: paymentOrderResult.data.result.paymentUrl + "&marketId=" + state.marketInfo.marketId + "&isCompletePayment=true",
        });

        // location.href = paymentOrderResult.data.result.paymentUrl;
      } else {
        // console.log(paymentOrderResult.data.errorObject.errorMessage);
        this.dispatch("paymentNew/setAlert", {
          title: "결제하기",
          msg: ["<p>" + paymentOrderResult.data.errorObject.errorMessage + "</p>"],
          btnArr: [],
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
  payment: async function () {
    if (state.isProgressing == true) {
      return;
    }

    if (state.useDeliveryDesiredDate) {
      if (!state.deliveryRequest) {
        this._vm.$showToast("배송요청사항에 배송희망일을 적어 주세요");
        document.querySelector("#deliveryRequest").focus();
        return;
      }
    }

    // console.log(state.orders);
    var productIds = [];
    state.orders.forEach((product) => {
      productIds.push(product.productId);
    });
    // console.log(productIds.join());

    state.isProgressing = true;

    setTimeout(() => {
      state.isProgressing = false;
    }, 1000);

    var paymentBoolean = false;
    const paymentValidationUrl =
      "/v3/orders/validation?userId=" +
      state.userId +
      "&productIds=" +
      productIds.join() +
      "&orderType=" +
      (state.marketOrderType ? state.marketOrderType : state.deliveryType);
    // console.log(paymentValidationUrl);
    try {
      const paymentValidationResult = await this._vm.$axios2.get(paymentValidationUrl);
      // console.log(paymentValidationResult);
      if (paymentValidationResult.data.responseCode == 2000) {
        // console.log(paymentValidationResult);
        paymentBoolean = true;
      } else {
        // console.log(paymentValidationResult.data.errorObject.errorMessage);
        this.dispatch("paymentNew/setAlert", {
          title: "결제하기",
          msg: ["<p>" + paymentValidationResult.data.errorObject.errorMessage + "</p>"],
          btnArr: [],
        });
      }
    } catch (e) {
      console.error(e);
    }

    if (paymentBoolean) {
      if (state.totalPaymentPrice == 0) {
        var _this = this;
        const callback = (isConfirm) => {
          _this._vm.$modal.hideAll();
          if (isConfirm) {
            // console.log("결제하기");
            _this.dispatch("paymentNew/paymentOrder", "NJP"); // 포인트로만 결제할때
          }
        };

        this.dispatch("paymentNew/setAlert", {
          title: "결제하기",
          msg: ["<p>총 결제금액을 포인트로만 결제하시겠습니까?</p>"],
          callback: callback,
          btnArr: [
            {
              txt: "닫기",
              btnCallback: () => callback(false),
            },
            {
              txt: "결제하기",
              type: "type1 inverse",
              btnCallback: () => callback(true),
            },
          ],
        });
      } else {
        this.dispatch("paymentNew/paymentOrder");
      }
    }
  },
  changeRadio: function (store, event) {
    store.commit("changeRadio", event.currentTarget.value);
  },
  // 사용가능한 쿠폰확인
  getUserCoupon: async function (store, marketId) {
    if (marketId) {
      const couponsUrl = "/v3/coupons/members?couponEnableType=Y&marketIds=" + marketId + "&page=0&size=100&userId=" + state.userId;
      try {
        const couponResult = await this._vm.$axios2.get(couponsUrl);
        if (couponResult.data.responseCode == 2000) {
          const result = couponResult.data.result;
          // console.log("사용가능한 쿠폰확인 ::: ");
          // console.log(result);

          store.commit("updateState", { type: "coupons", updateData: result.coupons });

          // console.log(result.coupons);
          // console.log(state.couponUseYn);

          if (state.couponUseYn === "Y") {
            if (!state?.useCoupon?.selectedCoupon) {
              if (result.coupons.length > 0) {
                var activeCoupon = null;
                result.coupons.forEach((coupon) => {
                  if (state.totalProductPrice >= coupon.minProductsPrice) {
                    if (!activeCoupon) {
                      activeCoupon = coupon;
                    } else {
                      if (activeCoupon.discount < coupon.discount) {
                        activeCoupon = coupon;
                      }
                    }
                  }
                });
                // console.log("activeCoupon -------------------------------------");
                // console.log(activeCoupon);

                if (activeCoupon) {
                  store.commit("updateState", {
                    type: "useCoupon",
                    updateData: {
                      couponId: activeCoupon.couponId,
                      couponPrice: activeCoupon.discount,
                      minProductsPrice: activeCoupon.minProductsPrice,
                      couponName: activeCoupon.couponName,
                      couponUseYn: "Y",
                    },
                    callback: () => {
                      store.dispatch("setTotalPaymentPrice");
                    },
                  });
                }
              }
            }

            /*
            if (result.coupons.length === 1) {
              const coupon = result.coupons[0];
              //console.log(state.totalProductPrice);
              //console.log(coupon.minProductsPrice);
              if (state.totalProductPrice >= coupon.minProductsPrice) {
                store.commit("updateState", {
                  type: "useCoupon",
                  updateData: {
                    couponId: coupon.couponId,
                    couponPrice: coupon.discount,
                    minProductsPrice: coupon.minProductsPrice,
                    couponName: coupon.couponName,
                    couponUseYn: "Y",
                  },
                  callback: () => {
                    this.dispatch("paymentNew/setTotalPaymentPrice");
                  },
                });
              }
            }
            */
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  // 결제수단 확인
  checkPaymentType: async function (store, data) {
    if (data) {
      const productLength = data.length;

      var compareArr = [];
      data.forEach((product) => {
        product.paymentTypes.forEach((paymentType) => {
          compareArr.push(paymentType.paymentCode);
        });
      });
      // console.log("compareArr");
      // console.log(compareArr);

      const result = compareArr.reduce((accu, curr) => {
        accu.set(curr, (accu.get(curr) || 0) + 1);
        return accu;
      }, new Map());

      var paymentTypeObj = {
        ONR: null,
        SEA: null,
        ZB: null,
        SP: null, // 서울페이 플러스
        BIZ_ONR: null,
      };
      for (let [key, value] of result.entries()) {
        paymentTypeObj[key] = value;
      }

      let radioOptions = [];

      radioOptions.push({ code: "4", text: "카드결제/계좌이체", active: false, guideTxt: "카드 및 계좌이체로 간편하게 결제하실 수 있습니다." });

      if (paymentTypeObj.BIZ_ONR == productLength) {
        radioOptions.push({
          code: "15",
          text: "온누리페이 모바일 온누리상품권", // (신)모바일 온누리상품권
          active: false,
          guideTxt: "온누리상품권으로 결제가 가능합니다.",
        });
      }
      if (paymentTypeObj.ONR == productLength) {
        // radioOptions.push({
        //   code: "1",
        //   text: "(구)모바일 온누리상품권",
        //   active: false,
        //   guideTxt:
        //     "카드충전식과 현재 판매중인 모바일 온누리상품권은 사용하실 수 없으며, 2023년 4월 이전에 구매한 (구)온누리상품권만 사용 가능합니다.",
        // });
        // if (paymentTypeObj.SEA == productLength) {
        //   radioOptions[1] = {
        //     code: "11",
        //     text: "온누리&수산물 모바일 상품권",
        //     active: false,
        //     guideTxt: "구매하신 온누리 및 수산물 상품권으로 결제가 가능합니다.",
        //   };
        // }
        // radioOptions[1] = {
        //   code: "11",
        //   text: "온누리&수산물 모바일 상품권",
        //   active: false,
        //   guideTxt: "구매하신 온누리 및 수산물 상품권으로 결제가 가능합니다.",
        // };
        // if (paymentTypeObj.SEA == productLength) {
        //   radioOptions.push({
        //     code: "11",
        //     text: "온누리&수산물 모바일 상품권",
        //     active: false,
        //     guideTxt: "구매하신 온누리 및 수산물 상품권으로 결제가 가능합니다.",
        //   });
        // }
      }
      if (paymentTypeObj.SEA == productLength) {
        radioOptions.push({
          code: "11",
          text: "온누리&수산물 모바일 상품권",
          active: false,
          guideTxt: "구매하신 온누리 및 수산물 상품권으로 결제가 가능합니다.",
        });
      } // 원복시켜야함
      if (paymentTypeObj.ZB == productLength) {
        radioOptions.push({
          code: "5",
          text: "서울페이+(서울배달+) 상품권",
          active: false,
          guideTxt: "기존의 구매하신 상품권으로 결제가 가능합니다.",
        });
        radioOptions.push({
          code: "2",
          text: "제로페이 모바일상품권",
          active: false,
          guideTxt:
            "카드충전식과 현재 판매중인 모바일 온누리상품권은 사용하실 수 없으며, 2023년 4월 이전에 구매한 (구)온누리상품권만 사용 가능합니다.",
        });
      }
      // 논산화지만 지역사랑상품권 결제타입 추가
      else if (data[0].marketId === 45) {
        radioOptions.push({ code: "12", text: "지역사랑상품권", active: false, guideTxt: "구매하신 지역사랑상품권으로 결제가 가능합니다." });
      }

      // radioOptions[0].active = true;

      var paymentType = radioOptions[0].code;
      if (localStorage.getItem("paymentType")) {
        paymentType = localStorage.getItem("paymentType");
      }

      var guideTxt = null;
      radioOptions.forEach((option) => {
        if (option.code == paymentType) {
          option.active = true;
          guideTxt = option.guideTxt;
        }
      });

      const sendData = {
        type: "radioOptions",
        updateData: radioOptions,
      };

      store.commit("updateState", {
        type: "radioCheckValue",
        updateData: paymentType,
        callback: function () {
          store.commit("updateState", { type: "radioTypeGuideTxt", updateData: guideTxt });
          store.commit("updateState", sendData);
        },
      });
    }

    // radioTypeGuideTxt
  },
  // 시장정보 저장
  setMarketInfo: function (store, data) {
    const marketData = {
      type: "marketInfo",
      updateData: {
        marketId: data.marketId ? data.marketId : JSON.parse(localStorage.getItem("paymentInfo")).marketId,
        marketName: data.marketName ? data.marketName : JSON.parse(localStorage.getItem("paymentInfo")).marketName,
      },
    };
    // console.log(marketData);
    store.commit("updateState", marketData);
  },
  // 결제상품
  setOrders: function (store, products) {
    var productsData = products ? products : JSON.parse(localStorage.getItem("paymentInfo")).products;
    if (localStorage.getItem("setProductRequest")) {
      const productRequests = JSON.parse(localStorage.getItem("setProductRequest"));
      productsData.forEach((product, index) => {
        product.productRequests[index] = productRequests[index].productRequest;
      });
    }

    const sendData = {
      type: "orders",
      updateData: productsData,
    };
    store.commit("updateState", sendData);
  },
  // 배송비, 할인(이벤트, 배송비)
  getShippingFee: async function (store, data) {
    // console.log(data.marketId);
    // console.log(data.totalPrice);
    // console.log(data.products);
    // console.log(state.userId);

    // if (!data.products) {
    //   console.log(localStorage.getItem("paymentData"));
    //   data = JSON.parse(localStorage.getItem("paymentData"));
    // }

    var deliveryFeeProducts = [];
    // const products = data.products;
    data.products.forEach((product) => {
      var options = [];
      if (product.options) {
        product.options.forEach((opt) => {
          opt.options.forEach((op) => {
            options.push(op.optionId);
          });
        });
      }

      deliveryFeeProducts.push({
        compoundKey: product.compoundKey,
        productId: product.productId,
        marketId: product.marketId,
        storeId: product.storeId,
        quantity: product.quantity,
        orderType: product.deliveryMethod == "TB" ? product.deliveryMethod : "BD",
        price: product.price,
        sellerPrice: product.sellerPrice,
        optionItems: options.length ? options.join() : null,
      });
    });

    var shippingFee = null;
    const userAddress = await JSON.parse(localStorage.getItem("userAddress"));
    try {
      const res = await this._vm.$axios2.post("/v3/delivery/fee", {
        userId: localStorage.getItem("userId"),
        lotNumberAddress: userAddress.lotNumberAddress,
        roadNameAddress: userAddress.roadNameAddress,
        products: deliveryFeeProducts,
        zipCode: store.rootState.zipCode,
        lat: userAddress.latitude,
        lng: userAddress.longitude,
      });

      if (res.data.responseCode == 2000) {
        var deliveryFeeSum = res.data.result.deliveryFeeSum;
        const deliveryFees = res.data.result.deliveryFees;
        // console.log(deliveryFeeSum);
        if (state.deliveryType === "TB") {
          var cashDeliveryCount = 0;
          var cashDeliveryTbPrice = 0;
          var deliveryApplyType = null;
          var bundleIds = [];

          deliveryFeeSum = null;

          deliveryFees.forEach((deliveryMarket) => {
            if (deliveryMarket.deliveryFeeMethod === "CASH_DELIVERY") {
              cashDeliveryCount++;
            }
            deliveryApplyType = deliveryMarket.deliveryApplyType;
            if (!deliveryMarket.tbDeliveryPrePaymentFeeBundleId) {
              if (deliveryMarket.deliveryFeeMethod === "PREPAYMENT") {
                deliveryFeeSum = (deliveryFeeSum === null ? 0 : deliveryFeeSum) + deliveryMarket.deliveryFee;
              } else if (deliveryMarket.deliveryFeeMethod === "CASH_DELIVERY") {
                if (deliveryMarket.deliveryFee !== null) {
                  cashDeliveryTbPrice = cashDeliveryTbPrice + deliveryMarket.deliveryFee;
                  // deliveryApplyType = deliveryMarket.deliveryApplyType;
                } else {
                  cashDeliveryTbPrice = null;
                }
              }
            } else {
              if (bundleIds.indexOf(deliveryMarket.tbDeliveryPrePaymentFeeBundleId) === -1) {
                bundleIds.push(deliveryMarket.tbDeliveryPrePaymentFeeBundleId);
                if (deliveryMarket.deliveryFeeMethod === "PREPAYMENT") {
                  const bundleProducts = deliveryFees.filter(
                    (item) => item.tbDeliveryPrePaymentFeeBundleId === deliveryMarket.tbDeliveryPrePaymentFeeBundleId,
                  );
                  var bundleProductDeliveryFee = 0; // 묶은상품 배송비 확인
                  bundleProducts.forEach((item) => {
                    if (bundleProductDeliveryFee < item.deliveryFee) {
                      bundleProductDeliveryFee = item.deliveryFee;
                    }
                  });
                  // deliveryFeeSum = deliveryFeeSum + deliveryMarket.deliveryFee;
                  deliveryFeeSum = (deliveryFeeSum === null ? 0 : deliveryFeeSum) + bundleProductDeliveryFee;
                } else if (deliveryMarket.deliveryFeeMethod === "CASH_DELIVERY") {
                  if (deliveryMarket.deliveryFee !== null) {
                    cashDeliveryTbPrice = cashDeliveryTbPrice + deliveryMarket.deliveryFee;
                    // deliveryApplyType = deliveryMarket.deliveryApplyType;
                  } else {
                    cashDeliveryTbPrice = null;
                  }
                }
              }
            }
          });

          // console.log("cashDeliveryTB ::: " + cashDeliveryTbPrice);
          // console.log("cashDeliveryCount ::: " + cashDeliveryCount);
          // console.log(deliveryFees.length);
          // console.log(typeof cashDeliveryCount);
          // console.log(typeof deliveryFees.length);
          // console.log(cashDeliveryCount === 0);
          // console.log(Number(deliveryFees.length) === cashDeliveryCount);
          // console.log(Number(deliveryFees.length) > cashDeliveryCount);
          // console.log(deliveryFees.length);

          store.commit("updateState", { type: "deliveryApplyType", updateData: deliveryApplyType });
          store.commit("updateState", { type: "cashDeliveryTB", updateData: cashDeliveryTbPrice });

          var deliveryTbType = null;
          if (cashDeliveryCount === 0) {
            deliveryTbType = "PREPAYMENT";
          } else if (deliveryFees.length === cashDeliveryCount) {
            console.log(cashDeliveryCount);
            deliveryTbType = "CASH_DELIVERY";
          } else if (deliveryFees.length > cashDeliveryCount) {
            deliveryTbType = "MIXED_PAYMENT";
          }
          //console.log("deliveryTbType ::: " + deliveryTbType);
          //console.log("deliveryFeeSum ::: " + deliveryFeeSum);
          store.commit("updateState", { type: "deliveryTbType", updateData: deliveryTbType });
        } else {
          // 방문수령 예외처리
          if (deliveryFees[0].marketId === 63) {
            store.commit("updateState", { type: "onSiteYn", updateData: "Y" });
          } else {
            store.commit("updateState", { type: "onSiteYn", updateData: deliveryFees[0].onSiteYn });
          }
        }

        if (state.marketOrderType === "TG") {
          store.commit("updateState", { type: "onSiteYn", updateData: "Y" });

          const tgSaleAmount = res.data.result.tgSaleAmount;
          if (tgSaleAmount && tgSaleAmount > 0) {
            store.commit("updateState", { type: "tgSaleAmount", updateData: tgSaleAmount });
          }
        }

        // distType

        store.commit("updateState", { type: "distType", updateData: deliveryFees[0].orderInfo.distType });

        // console.log("shippingFee ::::: " + deliveryFeeSum);
        shippingFee = state.marketOrderType === "TG" ? 0 : deliveryFeeSum;
        store.commit("updateState", {
          type: "shippingFee",
          updateData: deliveryFees[0].marketId === 63 || deliveryFees[0].onSiteYn === "Y" || state.marketOrderType === "TG" ? 0 : deliveryFeeSum, // 방문수령 예외처리
        });
      } else {
        console.log(res.data);
      }
    } catch (e) {
      console.error(e);
    }

    // 배달비 확인 - Legacy
    /*const shippingFeeUrl = "/api2/delivery/shippingFee?marketId=" + data.marketId + "&productsPrice=" + data.totalPrice + "&userId=" + state.userId;
    var shippingFee = 0;
    try {
      const shippingFeeResult = await this._vm.$axiosLegacy.get(shippingFeeUrl);
      if (shippingFeeResult.data.responseCode == 2000) {
        const result = shippingFeeResult;
        console.log("shippingFeeResult");
        console.log(result.data.shippingFee);
        shippingFee = result.data.shippingFee;
        store.commit("updateState", { type: "shippingFee", updateData: shippingFee });
      }
    } catch (e) {
      console.log(e);
    }*/

    /////////////////////////////////////////////////////////
    // 배달비, 배달비 할인 재정의
    var products = [];
    data.products.forEach((product) => {
      products.push({
        price: product.price,
        productId: product.productId,
        quantity: product.quantity,
      });
    });

    // console.log(":priceDATA", data);
    // if(products.options.length > 0) {
    //   console.log("***product.options",product.options.optionItemSellerPrice);
    // }
    // let userCart = JSON.parse(localStorage.getItem("userCart") || '[]');
    // let optionPrice= [];
    // let fullPrice = [];
    //
    // //옵션가격 얻기
    // userCart.forEach((item,index) => {
    //   if(item.products.options) {
    //     optionPrice =  optionPrice[index];
    //     console.log("item.products.options.options.optionPrice", item.products.options.options.optionPrice);
    //   } else{
    //     optionPrice.push(null);
    //   }
    // });
    // //정가가격
    // products.forEach((price,index) =>{
    //   fullPrice =  fullPrice[index];
    // });
    //
    // const maxLength = Math.max(fullPrice.length, optionPrice.length);
    // let formatPrices = [];
    //
    // for (let i = 0; i < maxLength; i++) {
    //   const formattedFullPrice = fullPrice[i] ?? 'null';
    //   const formattedOptionPrice = optionPrice[i] ?? 'null';
    //   formatPrices.push(`(${formattedFullPrice},${formattedOptionPrice})`);
    //   console.log("formatPrices", formatPrices);
    // }

    // products.map((item) => {
    //   item.price = `(${item.price},${item.productsPrice} - ${item.price})`
    //   console.log("itemprice***", item.price);
    // });

    const postData = {
      userId: localStorage.getItem("userId"),
      marketId: data.marketId,
      productsPrice: data.totalPrice,
      deliveryFee: shippingFee,
      products: products,
      // second: formatPrices,
    };

    const discountInfoUrl = "/v3/events/discounts/markets/members/products-price";
    // console.log(discountInfoUrl);
    var eventDeliveryDiscountUseYn = null;

    try {
      const discountInfoResult = await this._vm.$axios2.post(discountInfoUrl, postData);
      if (discountInfoResult.data.responseCode == 2000) {
        const result = discountInfoResult.data.result;

        if (state?.marketOrderType !== "TG") {
          if (result.eventDeliveryDiscountUseYn == "Y") {
            // 배달비 할인을 사용할때
            eventDeliveryDiscountUseYn = "Y";
            store.commit("updateState", { type: "eventDeliveryDiscountUseYn", updateData: result.eventDeliveryDiscountUseYn }); // 배달비 할인 사용 여부
            store.commit("updateState", { type: "shippingFee", updateData: result.eventDeliveryAmount }); // 배달비
            store.commit("updateState", { type: "deliveryDiscount", updateData: result.eventDeliveryDiscountAmount }); // 배달비 할인
            store.commit("updateState", { type: "eventDeliveryDiscountId", updateData: result.eventDeliveryDiscountId }); // 배달비 할인 아이디
          }

          // 배달비 할인 가이드 텍스트
          if (result.eventDeliveryDiscountConditionYn == "Y") {
            // eventDeliveryDiscountUseYn = "Y";
            store.commit("updateState", {
              type: "eventDeliveryDiscountConditionYn",
              updateData: result.eventDeliveryDiscountConditionYn,
            });
            if (result.eventDeliveryDiscountConditionMessage) {
              store.commit("updateState", {
                type: "eventDeliveryDiscountConditionMessage",
                updateData: result.eventDeliveryDiscountConditionMessage,
              });
            }
          }
        }

        if (result.eventPaymentDiscountUseYn == "Y") {
          store.commit("updateState", { type: "eventPaymentDiscountUseYn", updateData: result.eventPaymentDiscountUseYn }); // 이벤트 할인 사용 여부
          store.commit("updateState", { type: "eventDiscount", updateData: result.eventPaymentDiscountAmount }); // 이벤트 할인
          store.commit("updateState", { type: "eventPaymentDiscountId", updateData: result.eventPaymentDiscountId }); // 이벤트 할인 아이디

          if (result.eventPaymentDiscountMessage) {
            let message = result.eventPaymentDiscountMessage;

            store.commit("updateState", {
              type: "eventPaymentDiscountMessage",
              updateData: message,
            });
          }
          store.commit("updateState", { type: "discountAtEventContent", updateData: result.discountAtEventContent }); // AT이벤트
        }
        store.commit("updateState", { type: "atEventId", updateData: result.atEventId }); // 농할 이벤트 할인 아이디
      }
    } catch (e) {
      console.error(e);
    }

    // console.log("eventDeliveryDiscountUseYn");
    // console.log("eventDeliveryDiscountUseYn");
    // console.log("eventDeliveryDiscountUseYn");
    // console.log("eventDeliveryDiscountUseYn ::: " + eventDeliveryDiscountUseYn);
    // console.log(state.shippingFee);
    // console.log(state.deliveryDiscount);\

    var useLegacyDiscount =
      state.marketOrderType !== "TG" && state.onSiteYn !== "Y" && (!eventDeliveryDiscountUseYn || eventDeliveryDiscountUseYn === "N");

    if (useLegacyDiscount) {
      // 상품가격 15% 할증 된 시장에 배달비 할인 쿠폰이 적용되면 예외처리 해야함
      // 방학동도깨비(32), 명일전통시장(25), 수유시장(22) 결제하기 시 할인금액 레거시 API 실행 여부 조건 처리
      if (state.marketInfo.marketId === 32 && state.marketInfo.marketId === 25 && state.marketInfo.marketId === 22) {
        useLegacyDiscount = state.totalProductPrice >= 10000 && state.totalProductPrice < 30000;
      }
    }

    // 방문수령 예외처리
    // if (
    //     state.marketOrderType !== "TG" &&
    //     state.onSiteYn !== "Y" &&
    //     (!eventDeliveryDiscountUseYn || eventDeliveryDiscountUseYn === "N")
    // ) {
    if (useLegacyDiscount) {
      // 레거시의 배송비 할인을 확인한다.

      var showDeliveryMsgBoolean = false;

      const discountPromotion = "/api2/delivery/discountPromotion?marketId=" + data.marketId + "&userId=" + localStorage.getItem("userId");
      try {
        const discountPromotionResult = await this._vm.$axiosLegacy.get(discountPromotion);
        if (discountPromotionResult.data.responseCode == 2000) {
          const result = discountPromotionResult;
          if (result.data.canUseYn == "Y") {
            if (result.data.minOrderPay <= data.totalPrice) {
              store.commit("updateState", { type: "eventDeliveryDiscountUseYn", updateData: result.data.canUseYn }); // 배달비 할인 사용 여부
              store.commit("updateState", { type: "deliveryDiscount", updateData: result.data.discount ?? null });
              // store.commit("updateState", { type: "deliveryPromotionId", updateData: result.data.deliveryPromotionId });
              store.commit("updateState", { type: "deliveryPromotionId", updateData: result.data.promotionId });

              if (state.shippingFee - result.data.discount == 0) {
                showDeliveryMsgBoolean = true;
              }
            } else {
              console.log("배송비 할인 없음 ::: 1 ");
            }
          } else {
            console.log("배송비 할인 없음 ::: 2 ");
          }
        }
      } catch (e) {
        console.error(e);
      }

      if (showDeliveryMsgBoolean) {
        const showDeliveryMsgUrl = "";

        // if (배달비 – 프로모션 할인 금액 == 0)
      }
    }

    // 총 결제금액
    this.dispatch("paymentNew/setTotalPaymentPrice");
  },
  // 사용자 사용 가능한 포인트 확인
  getUserPoint: async function (store) {
    const userPointUrl = "/v3/points/members/" + state.userId + "/markets/" + state.marketInfo.marketId;
    // const userPointUrl = "/v3/points/members/" + state.userId + "/?marketId=" + state.marketInfo.marketId;
    try {
      const userPointResult = await this._vm.$axios2.get(userPointUrl);
      if (userPointResult.data.responseCode == 2000) {
        const result = userPointResult.data.result;
        // console.log(result);
        store.commit("updateState", { type: "userPoint", updateData: result.availableTotalPoint });
      }
    } catch (e) {
      console.error(e);
    }
  },
  changePoint: async function (store, event) {
    const regex = /[^0-9]/g;
    var value = Number(event.currentTarget.value.replace(regex, ""));

    // const value = Number(event.currentTarget.value.replace(",", "").replace("-", ""));

    event.currentTarget.value = value;

    if (value > state.userPoint) {
      // if (state.totalPaymentPriceExceptPoint <= state.userPoint) {
      if (state.totalPaymentPriceExceptPoint <= state.userPoint) {
        value = state.totalPaymentPriceExceptPoint;
        this._vm.$showToast("상품 금액보다 포인트 금액은 클 수 없습니다.");
      } else {
        if (state.userPoint < value) {
          value = state.userPoint;
          this._vm.$showToast("보유한 포인트 금액보다 클 수 없습니다.");
        }
      }
      // const eventCurrentTarget = event.currentTarget;
      event.currentTarget.value = Number("-" + value);

      const _this = this;
      store.commit("updateState", {
        type: "usePoint",
        updateData: value,
        callback: () => {
          _this.dispatch("paymentNew/setTotalPaymentPrice");
          // eventCurrentTarget.value = "-" + _this._vm.$formatPrice(value);
        },
      });

      store.commit("updateState", {
        type: "pointPrice",
        updateData: value,
        callback: () => {
          _this.dispatch("paymentNew/setTotalPaymentPrice");
          // eventCurrentTarget.value = "-" + _this._vm.$formatPrice(value);
        },
      });

      // event.currentTarget.value = "-" + _this._vm.$formatPrice(value);
      return;

      // this._vm.$showToast("보유한 포인트 금액보다 클수 없습니다.");
    }

    if (value > state.totalPaymentPriceExceptPoint) {
      this._vm.$showToast("상품 금액보다 포인트 금액은 클 수 없습니다.");
      // store.commit("updateState", {
      //   type: "pointPrice",
      //   updateData: state.totalPaymentPriceExceptPoint,
      //   // callback: function () {
      //   //   _this.dispatch("paymentNew/setTotalPaymentPrice");
      //   // },
      // });

      value = state.totalPaymentPriceExceptPoint;
      store.commit("updateState", { type: "pointPrice", updateData: state.totalPaymentPriceExceptPoint });
      // event.currentTarget.value = this._vm.$formatPrice(value);
      event.currentTarget.value = Number("-" + value);
      if (event.currentTarget.value.indexOf("-") === -1) {
        event.currentTarget.value = "-" + event.currentTarget.value;
      }
    } else if (value > state.userPoint) {
      // this._vm.$showToast("보유한 포인트 금액보다 클수 없습니다.");
      // store.commit("updateState", { type: "pointPrice", updateData: 0 });

      const _this = this;
      store.commit("updateState", {
        type: "usePoint",
        updateData: state.userPoint,
        callback: () => {
          _this.dispatch("paymentNew/setTotalPaymentPrice");
        },
      });

      store.commit("updateState", {
        type: "pointPrice",
        updateData: state.userPoint,
        callback: () => {
          _this.dispatch("paymentNew/setTotalPaymentPrice");
        },
      });
      this._vm.$showToast("보유한 포인트 금액보다 클 수 없습니다.");
      return;
    } else {
      if (state.userPoint - state.usePoint < 0) {
        return;
      }
      if (event.currentTarget.value.indexOf("-") === -1) {
        event.currentTarget.value = "-" + event.currentTarget.value;
      }
      store.commit("updateState", { type: "pointPrice", updateData: value });
    }
    store.commit("updateState", { type: "usePoint", updateData: value });

    // 총 결제금액
    this.dispatch("paymentNew/setTotalPaymentPrice");
  },
  useCoupon: function (store, data) {
    const _this = this;
    // console.log("useCoupon ::: ");
    // console.log(data);

    if (data?.couponUseYn) {
      if (data.couponUseYn === "N") {
        store.commit("updateState", { type: "couponUseYn", updateData: "N" });
        store.commit("updateState", {
          type: "useCoupon",
          updateData: null,
          callback: () => {
            _this.dispatch("paymentNew/setTotalPaymentPrice");
          },
        });
      } else if (data.couponUseYn === "Y") {
        store.commit("updateState", {
          type: "useCoupon",
          updateData: data,
          callback: () => {
            _this.dispatch("paymentNew/setTotalPaymentPrice");
          },
        });
      }
    } else {
      store.commit("updateState", {
        type: "useCoupon",
        updateData: data,
        callback: () => {
          _this.dispatch("paymentNew/setTotalPaymentPrice");
        },
      });
    }

    setTimeout(() => {
      _this.dispatch("paymentNew/setTotalPaymentPrice");
    }, 500);
  },
  // 총 결제 금액 계산
  setTotalPaymentPrice: function (store) {
    // console.log("setTotalPaymentPrice");
    if (!state.totalProductPrice) {
      console.log("총 상품금액이 없습니다.");
    }
    // console.log(state.totalProductPrice);
    // console.log(state.eventDiscount);
    // console.log(state.shippingFee);
    // console.log(state.deliveryDiscount);

    var totalPaymentPriceExceptPoint = // 포인트 금액을 제외한 금액
      state.totalProductPrice -
      state.eventDiscount +
      state.shippingFee -
      (state.shippingFee > 0 ? state.deliveryDiscount : 0) -
      (state.useCoupon ? state.useCoupon.couponPrice : 0) -
      state.tgSaleAmount;
    // (state.useCoupon ? state.useCoupon.couponPrice : 0) - state.packagingTotalDiscountPrice;

    const totalPaymentPrice = totalPaymentPriceExceptPoint - state.pointPrice; // 포인트 포함 금액
    // console.log("totalPaymentPrice ::::: " + totalPaymentPrice);

    store.commit("updateState", { type: "totalPaymentPriceExceptPoint", updateData: totalPaymentPriceExceptPoint });
    store.commit("updateState", { type: "totalPaymentPrice", updateData: totalPaymentPrice });
  },
  // 배송타입 확인
  setDeliveryType: function (store, products) {
    var deliveryType = "BD";
    products.forEach((product) => {
      if (product.deliveryMethod == "TB") {
        deliveryType = "TB";
      }
    });
    store.commit("updateState", { type: "deliveryType", updateData: deliveryType });
  },
  // 쿠폰 발행
  issuedCoupon: async function (store, data) {
    // console.log("issuedCoupon ::: ");
    // console.log(data);
    const userId = localStorage.getItem("userId");
    if (userId) {
      const issuedCouponUrl = "/v3/coupons/members/" + userId + "/coupon?couponNumber=" + data.data.couponNumber + "&userId=" + userId;
      console.log(issuedCouponUrl);
      try {
        const issuedCouponResult = await this._vm.$axios2.post(issuedCouponUrl);
        if (issuedCouponResult.data.responseCode == 2000) {
          const result = issuedCouponResult.data.result;
          // console.log("쿠폰 발급 후 갱신 프로세스");

          this._vm.$showToast(state.issueCoupon.couponName + "이 발급되었습니다.");
          store.dispatch("getUserCoupon", state.marketInfo.marketId);
          store.commit("updateState", { type: "issueCoupon", updateData: null });

          // 발급 가능한 또 다른 쿠폰이 있는지 확인
          const validationCouponUrl = "/v3/coupons/members/" + state.userId + "/coupon/market/" + state.marketInfo.marketId + "/validation";
          try {
            const validationCouponResult = await this._vm.$axios2.get(validationCouponUrl);
            if (validationCouponResult.data.responseCode == 2000) {
              const result = validationCouponResult.data.result;
              if (result.issueYn == "Y") {
                store.commit("updateState", { type: "issueCoupon", updateData: result });
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  getCoupon: async function (store) {
    const _this = this;
    const recevieCouponUrl = "/v3/coupons/issue/all?marketId=" + state.marketInfo.marketId + "&userId=" + state.userId;
    try {
      const recevieCouponResult = await _this._vm.$axios2.post(recevieCouponUrl);
      if (recevieCouponResult.data.responseCode === 2000) {
        const result = recevieCouponResult.data.result;

        // console.log(result);
        // console.log(result.message);

        store.commit("updateState", { type: "issueCoupon", updateData: null });
        store.dispatch("getUserCoupon", state.marketInfo.marketId);

        this._vm.$showToast(result.message);
      }
    } catch (e) {
      console.error(e);
    }
  },
  // 쿠폰받기, 쿠폰사용 레이어 노출
  getUseCoupon: function (store, type = null) {
    // console.log(store);

    if (state?.useCoupon?.selectedCoupon) {
      // 사용자가 선택한 쿠폰이 있을때는 재진입하는 것으로 간주해 레이어를 노출하지 않는다.
      return false;
    }

    if (!type) {
      type = "recevie";
    }

    const _this = this;

    const callback = async function (boolean) {
      if (type === "recevie") {
        if (boolean) {
          store.dispatch("getCoupon"); // 쿠폰 받기
        }
      }
      // else if (type === "use") {
      // 쿠폰 사용하기
      // if (boolean) {
      // const useCouponUrl = "/v3/coupons/issue/all?marketId=" + state.marketInfo.marketId + "&userId=" + state.userId;
      // try {
      //   const useCouponResult = await _this._vm.$axios2.post(useCouponUrl);
      //   console.log(useCouponResult);
      //   if (useCouponResult.data.responseCode === 2000) {
      //     const result = useCouponResult.data.result;
      //     console.log(result);
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
      // }
      // }
      _this._vm.$modal.hide(modalName);
    };

    const modalName = "recevie_coupon_modal";
    const compProps = {
      title: type == "recevie" ? "쿠폰 받기" : "쿠폰 사용하기",
      type: "confirm",
      msgArr:
        type == "recevie" ? ["새로운 쿠폰이 있습니다.", "쿠폰 받기를 하시겠습니까?"] : ["사용 가능한 쿠폰이 있습니다.", "쿠폰을 사용하시겠습니까?"],
      btnArr: [
        {
          txt: "아니오",
          type: "type1 normal",
          btnCallback: () => callback(false),
        },
        {
          txt: type == "recevie" ? "쿠폰 받기" : "쿠폰사용",
          type: "type1 inverse",
          btnCallback: () => callback(true),
        },
      ],
      closeModal: function () {
        _this._vm.$modal.hide(modalName);
      },
    };
    this.dispatch("showModal", { name: modalName, compProps: compProps, modalProps: null }, { root: true });
  },
  // 발급 가능 쿠폰 확인
  validationCoupon: async function (store) {
    const validationCouponUrl = "/v3/coupons/members/" + state.userId + "/coupon/market/" + state.marketInfo.marketId + "/validation";
    try {
      const validationCouponResult = await this._vm.$axios2.get(validationCouponUrl);
      if (validationCouponResult.data.responseCode == 2000) {
        // console.log(validationCouponResult);
        const result = validationCouponResult.data.result;
        if (result.issueYn == "Y") {
          store.commit("updateState", { type: "issueCoupon", updateData: result });
          store.dispatch("getUseCoupon"); // 쿠폰 받기 레이어 팝업 호출
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  getMemberInfo: async function (store) {
    const memberInfoUrl = "/v3/user/member/information/" + state.userId + "/personal?maskYn=N";
    try {
      const memberInfoResult = await this._vm.$axios1.get(memberInfoUrl);
      if (memberInfoResult.data.responseCode == 2000) {
        const result = memberInfoResult.data.result;
        // console.log(result);
        store.commit("updateState", { type: "memberName", updateData: result.memberName });
        store.commit("updateState", { type: "phoneNumber", updateData: result.phoneNumber });
      }
    } catch (e) {
      console.log(e);
    }

    /*const memberAddressUrl = "/v3/user/member/address/" + state.userId + "/ship-to";
    try {
      const memberAddressResult = await this._vm.$axios1.get(memberAddressUrl);
      if (memberAddressResult.data.responseCode == 2000) {
        const result = memberAddressResult.data.result;
        console.log(result);
        store.commit("updateState", { type: "memberAddress", updateData: result });
      }
    } catch (e) {
      console.log(e);
    }*/
  },
  getMemberAddress: async function (store) {
    const memberAddressUrl = "/v3/user/member/address/" + localStorage.getItem("userId") + "/ship-to";
    // console.log(memberAddressUrl);
    try {
      const memberAddressResult = await this._vm.$axios1.get(memberAddressUrl);
      // console.log(memberAddressResult);
      if (memberAddressResult.data.responseCode == 2000) {
        const result = memberAddressResult.data.result;
        // console.log(result);
        store.commit("updateState", { type: "memberAddress", updateData: result });
      }
    } catch (e) {
      console.error(e);
    }
  },
  initState: async function (store, data) {
    state.isProgressing = false;

    store.commit("updateState", { type: "marketOrderType", updateData: data.marketOrderType ? data.marketOrderType : null });

    store.commit("updateState", { type: "userId", updateData: localStorage.getItem("userId") });

    store.commit("updateState", { type: "eventDiscount", updateData: 0 }); // 이벤트 할인 초기화
    store.commit("updateState", { type: "tgSaleAmount", updateData: 0 }); // 포장할인가 초기화

    store.commit("updateState", { type: "coupons", updateData: [] }); // 쿠폰 초기화
    store.commit("updateState", { type: "useCoupon", updateData: null }); // 쿠폰 초기화

    store.commit("updateState", { type: "shippingFee", updateData: 0 }); // 배달비 초기화
    store.commit("updateState", { type: "deliveryDiscount", updateData: 0 }); // 배달비 할인 초기화

    store.commit("updateState", { type: "eventDeliveryDiscountUseYn", updateData: null }); // 배달비 할인 초기화
    store.commit("updateState", { type: "eventDeliveryDiscountConditionYn", updateData: null });
    store.commit("updateState", { type: "eventDeliveryDiscountConditionMessage", updateData: null });

    var cartProducts = JSON.parse(JSON.stringify(data.products));

    // 배송희망일 사용여부

    const useDeliveryDesiredDateProducts = store.rootState.useDeliveryDesiredDateProducts;
    let useDeliveryDesiredDate = false;
    if (useDeliveryDesiredDateProducts.length) {
      cartProducts.forEach((product) => {
        if (!useDeliveryDesiredDate) {
          useDeliveryDesiredDateProducts.forEach((productId) => {
            if (product.compoundKey.indexOf(String(productId)) > -1) {
              useDeliveryDesiredDate = true;
            }
          });
        }
      });
    }
    store.commit("updateState", { type: "useDeliveryDesiredDate", updateData: useDeliveryDesiredDate });

    /*
    var packagingTotalDiscountPrice = 0;// 포장 주문 할인 금액
    // TODO : 포장주문 일때 포장할인 적용 : totalPrice
    if (data.marketOrderType === 'TG') {// 포장주문
      console.log(cartProducts);
      cartProducts.forEach(product => {
        if (product.price > product.sellerPrice) {
          packagingTotalDiscountPrice += product.packagingDiscountPrice;
        }
      });
      if (packagingTotalDiscountPrice) {
        store.commit("updateState", { type: "packagingTotalDiscountPrice", updateData: packagingTotalDiscountPrice });
      }
    }
    */

    // 총 상품금액
    store.commit("updateState", { type: "totalProductPrice", updateData: data.totalPrice });
    // store.commit("updateState", { type: "totalProductPrice", updateData: data.marketOrderType === 'TG'? tgTotalPrice : data.totalPrice });

    // 회원 정보 확인
    this.dispatch("paymentNew/getMemberInfo");

    // 회원 주소 확인
    this.dispatch("paymentNew/getMemberAddress");

    // 배송타입 확인
    this.dispatch("paymentNew/setDeliveryType", cartProducts);

    // vue 전역으로 저장한다.
    this.dispatch("setOrderProducts", cartProducts);

    // 배송비 및 할인정보 확인
    this.dispatch("paymentNew/getShippingFee", {
      marketId: data.marketId,
      orderType: data.orderType,
      totalPrice: data.totalPrice,
      products: cartProducts,
    });

    // 결제 상품 목록
    this.dispatch("paymentNew/setOrders", cartProducts);

    // 시장정보 저장
    this.dispatch("paymentNew/setMarketInfo", { marketId: data.marketId, marketName: data.marketName });

    // 결제수단 확인
    this.dispatch("paymentNew/checkPaymentType", cartProducts);

    // 발급 가능 쿠폰 확인
    this.dispatch("paymentNew/validationCoupon");

    // 사용가능 쿠폰
    this.dispatch("paymentNew/getUserCoupon", data.marketId);

    // 사용가능 포인트
    this.dispatch("paymentNew/getUserPoint");

    if (data.products) {
      localStorage.setItem("paymentInfo", JSON.stringify(data));
      // var atYnCount = 0;
      // data.products.forEach(product => {
      //   if (product.atYn === 'Y') {
      //     atYnCount++;
      //   }
      // });
      // if (atYnCount) {
      //   store.commit("updateState", { type: "atUseBoolean", updateData: true });
      // }
    }

    store.commit("updateState", { type: "windowInnerWidth", updateData: window.innerWidth });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
