<template>
  <div class="wrap">
    <TopHeader title="장바구니" theme="nj_white" :isButton="true" @goBack="goBack" />
    <div class="content">
      <template v-if="products && products.length > 0">
        <AddressInfo />
        <ProductList :marketId="marketId" :products="products"></ProductList>
        <DeliveryInfo @select="setReceiveWay" @input="setDeliveryRequest"></DeliveryInfo>
        <div class="total_price_wrap">
          <div class="price_wrap total_wrap">
            <div class="title">총 상품금액</div>
            <div class="price">{{ $formatPrice(totalPrice) }}원</div>
          </div>
        </div>
        <div class="btn_wrap">
          <div class="btn_purchase" @click="goPayment">결제하기</div>
        </div>
      </template>
      <div class="no_product_msg" v-else>장바구니에 담긴 상품이 없습니다.</div>
    </div>
    <Layer :displayLayer="displayLayer" displayType="center" :closeLayer="hideLayer">
      <template v-slot:content>
        <div class="layer_contents">
          <div class="layer_title">상품 삭제</div>
          <p class="layer_txt">해당 상품을 삭제하시겠습니까 ?</p>
        </div>
        <ul class="half_list type1 mt_10">
          <li>
            <Btn type="type1 none_border" value="취소" @btnCallback="hideLayer" />
          </li>
          <li>
            <Btn type="type1 reverse" btnId="DEL_PRODUCT" value="삭제하기" @btnCallback="deleteProduct" />
          </li>
        </ul>
      </template>
    </Layer>

    <Alert
      :displayLayer="displayAlertLayer"
      :texts="[alertText]"
      :textClass="[]"
      :btns="[
        {
          type: null,
          btnId: '',
          btnData: {},
          value: '확인',
        },
      ]"
      :showHideLayer="showHideAlertLayer"
    />
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import AddressInfo from "./components/AddressInfo";
import ProductList from "./components/ProductList";
import DeliveryInfo from "./components/DeliveryInfo";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import Btn from "@/components/Btn";
import Layer from "@/components/Layer";
import Alert from "@/components/Alert";

export default {
  name: "CartMarket",
  data: function () {
    return {
      userId: this.$route.query.userId,
      shipToDoro: this.$route.query.shipToDoro,
      shipToDetail: this.$route.query.shipToDetail,
    };
  },
  components: {
    Alert,
    Layer,
    Btn,
    DeliveryInfo,
    ProductList,
    AddressInfo,
    TopHeader,
  },
  methods: {
    ...mapMutations("cartMarket", ["showHideAlertLayer"]),
    ...mapActions("cartMarket", ["setReceiveWay", "setDeliveryRequest", "deleteProduct", "hideLayer", "goBack"]),
    initParams: function (data = null) {
      if (data) {
        data = JSON.parse(data);
        const cartData = this.$parseCart(data.cart)[0];

        this.$store.dispatch("cartMarket/getProducts", cartData);
      }
    },
    // iOS의 경우, 해당 정보를 QueryString으로 넘겨받을 수 없어, 해당 함수를 호출하여 전달 받음
    moveToPayment: function () {
      return JSON.stringify(this.moveToPaymentData);
    },
    initDeliveryAddress: function () {
      if (this.userId) {
        this.$store.dispatch("cartMarket/getDeliveryAddress", this.userId);
      } else if (this.shipToDoro && this.shipToDetail) {
        const data = {
          shipToDoro: this.shipToDoro,
          shipToDetail: this.shipToDetail,
        };
        this.$store.dispatch("cartMarket/setDeliveryAddress", data);
      }
    },
    goPayment: function () {
      if (this.saleProduct.length != this.products.length) {
        this.showHideAlertLayer("판매 종료된 상품이 있습니다. <br>해당 상품을 삭제해주세요.");
        return false;
      }

      if (!this.receiveWay) {
        this.showHideAlertLayer("수령 방법은 필수 선택 항목입니다.");
        return false;
      }

      this.$store.dispatch("cartMarket/goPayment", { isCallback: false });
    },
  },
  beforeCreate: function () {},
  created() {
    this.initDeliveryAddress();
  },
  mounted() {
    window.initParams = this.initParams;
    window.moveToPayment = this.moveToPayment;

    // const dummy = '{"cart":[{"marketId":22,"products":[{"compoundKey":"42500!1@1#4@7","quantity":3},{"compoundKey":"42500!1@2#4@8","quantity":1},{"compoundKey":"42500!1@1#3@5,6#4@7","quantity":1},{"compoundKey":"42500!1@1#3@5#4@7","quantity":1},{"compoundKey":"42500!1@1#3@6#4@7","quantity":1}]}]}';
    // this.initParams(dummy);z
  },
  computed: {
    ...mapState({
      products: (state) => state.cartMarket.products,
      marketId: (state) => state.cartMarket.marketId,
      deliveryRequest: (state) => state.cartMarket.deliveryRequest,
      receiveWay: (state) => state.cartMarket.receiveWay,
      displayLayer: (state) => state.cartMarket.displayLayer,
      displayAlertLayer: (state) => state.cartMarket.displayAlertLayer,
      alertText: (state) => state.cartMarket.alertText,
    }),
    ...mapGetters({
      saleProduct: "cartMarket/saleProduct",
      moveToPaymentData: "cartMarket/moveToPaymentData",
    }),
    totalPrice: function () {
      let total = 0;

      this.saleProduct.forEach((product) => {
        const price = product.realDiscountPrice ? product.realDiscountPrice : product.realPrice;
        total += price * product.quantity;
      });

      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;

  .content {
    height: calc(100% - 44px);
    padding: 0 20px;
    background: $nj_background_gray;
    overflow: auto;

    .no_product_msg {
      padding-top: 100px;
      @include font(16, 22, -0.96, medium);
      text-align: center;
      color: $nj_text_main_gray;
    }

    .total_price_wrap {
      padding-top: 20px;

      .price_wrap {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
        vertical-align: top;
        @include font(18, 27, -1.08);

        .title {
          float: left;
          color: $nj_text_main_gray;
        }

        .price {
          float: right;
          color: $nj_black;
        }
      }

      .price_wrap.total_wrap {
        padding-top: 15px;
        margin-bottom: 0px;

        .title {
          padding-top: 20px;
          @include font(20, normal, -1.2);
          color: $nj_red;
        }

        .price {
          @include font(34, 50, -2.04, bold);
        }
      }
    }

    .btn_wrap {
      height: 60px;
      padding: 30px 0;
      box-sizing: content-box;

      .btn_purchase {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: $nj_red;
        @include font(20, 60, -1.2);
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .layer_title {
    margin: 10px 0 0;
    @include font(20, 26, -1.2, bold);
    color: $nj_black;
  }
  .layer_txt {
    margin: 20px 0 0;
    @include font(16, 24, -0.96);
    color: $nj_text_sub_gray;
  }
}
</style>
