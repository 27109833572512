const state = {
  userId: "",
  title: "단골가게",
  serviceOffStoreList: [],
  serviceOnStoreList: [],
  displayLayer: false,
  removeRegularInfo: {},
};

const mutations = {
  goLink: function (state, data) {
    if (data.type == "MARKET") {
      this._vm.$callNative("moveToMarket", {
        marketId: data.marketId,
      });
    } else if (data.type == "STORE") {
      this._vm.$callNative("moveToStore", {
        marketId: data.marketId,
        storeId: data.storeId,
      });
    }
    // else if (data.type == 'HANG_UP') {
    //     console.log('------------------------------------');
    //     console.log(state);
    //     if (confirm('해당 상점에서 단골끊기를 하시겠습니까?')) {
    //         this._vm.$callNative('hangUpFavoriteStore', {
    //             marketId: data.marketId,
    //             storeId: data.storeId
    //         });
    //     }
    // }
  },
  initState: function (state, data) {
    state["userId"] = data.initData.userId;
    state["serviceOffStoreList"] = data.initData.serviceOffStoreList;
    state["serviceOnStoreList"] = data.initData.serviceOnStoreList;
  },
  goBack: function (state, vueContainer) {
    // this.commit('cartShip/testFunc');// 테스트 코드
    vueContainer.$closeView();
  },
};

const actions = {
  showHideLayer: function (store, data) {
    if (!data) {
      state.displayLayer = !state.displayLayer;
    } else if (data.btnId && data.btnId == "REGULAR_Y") {
      // store.dispatch('withdrawal');
      // console.log(state);
      // console.log(data);
      // console.log('REGULAR_Y');

      const { serviceOnOffType, deleteId, marketId, storeId } = state.removeRegularInfo;

      const axiosUrl = "/v3/user/store/market/" + marketId + "/store/" + storeId + "/regular/" + state.userId;
      this._vm.$axios1
        .put(axiosUrl, { regularYn: "N" })
        .then((response) => {
          if (response.data.responseCode == 2000) {
            var deleteIndex = state["service" + serviceOnOffType + "StoreList"].findIndex((list) => {
              return list.storeId == deleteId && list.marketId == marketId;
            });
            state["service" + serviceOnOffType + "StoreList"].splice(deleteIndex, 1);
            state.removeRegularInfo = {};
            state.displayLayer = false;
          } else {
            alert(response.data.errorObject.errorMessage);
          }
          this._vm.isProgressing = false;
        })
        .catch(() => {
          this._vm.isProgressing = false;
        });
    } else {
      state.displayLayer = !state.displayLayer;
    }
  },
  goLink: function (store, data) {
    if (data.type != "HANG_UP") {
      store.commit("goLink", data);
    } else {
      state.displayLayer = true;

      state.removeRegularInfo["serviceOnOffType"] = data.event.currentTarget.closest("li").dataset.serviceOnOff;
      state.removeRegularInfo["deleteId"] = Number(data.event.currentTarget.closest("li").dataset.listId);
      state.removeRegularInfo["marketId"] = data.marketId;
      state.removeRegularInfo["storeId"] = data.storeId;

      /*
            if (confirm('해당 상점에서 단골끊기를 하시겠습니까?')) {

                const axiosUrl = '/v3/user/store/market/' + data.marketId + '/store/' + data.storeId +'/regular/' + state.userId;
                this._vm.$axios1.put(axiosUrl, {'regularYn': 'N'})
                    .then((response) => {
                        if (response.data.responseCode == 2000) {
                            var deleteIndex = state['service' + serviceOnOffType + 'StoreList'].map((list, index) => {
                                if (list.storeId == deleteId) {
                                    return index;
                                }
                            });
                            state['service' + serviceOnOffType + 'StoreList'].splice(deleteIndex, 1);
                        }
                        else {
                            alert(response.data.errorObject.errorMessage);
                        }
                        this._vm.isProgressing = false;
                    })
                    .catch(() => {
                        this._vm.isProgressing = false;
                    });
            }
            */
    }
  },
  initState: async function (store, data) {
    // console.log(data);

    this._vm.isProgressing = true;
    const initData = {
      userId: data.userId,
      serviceOffStoreList: null,
      serviceOnStoreList: null,
    };
    // console.log(initData);

    const axiosUrl = "/v3/user/store/userId/" + initData.userId + "/stores/regular";
    await this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        // console.log(response);
        if (response.data.responseCode == 2000) {
          initData.serviceOffStoreList = response.data.result.serviceOffStoreList;
          initData.serviceOnStoreList = response.data.result.serviceOnStoreList;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
    store.commit("initState", {
      initData: initData,
    });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {
  title(state) {
    return state.title;
  },
  markets(state) {
    return state.markets;
  },
  // count(state, getters){
  //     return Math.pow(state.count, getters.weight);
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
