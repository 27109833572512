<template>
  <div>
    <div class="modal_wrap" :class="[resultType ? 'result_type' : null, type]">
      <h1 v-if="title" :class="{ title_type1: type === 'order_notice' }">
        {{ title }}
      </h1>
      <template v-if="type === 'alert' || type === 'confirm'">
        <p v-for="(msg, index) in msgArr" :key="index" class="msg" v-html="msg" />
        <div v-if="btnArr">
          <!-- btnArr 정의되지 않으면 버튼을 생성하지 않음 -->
          <div v-if="btnArr.length" class="btn_area" :class="btnAlign">
            <!-- 요소 있음 -->
            <Btn v-for="(btn, index) in btnArr" :type="btn.type" :key="index" :value="btn.txt" :data="btn.data" @btnCallback="btn.btnCallback" />
          </div>
          <div v-else class="btn_area center">
            <!-- 정의는 되었지만 요소가 없을때 확인 버튼만 적용 -->
            <Btn value="확인" @btnCallback="closeModal ? closeModal() : ''" />
          </div>
        </div>
      </template>
      <template v-else-if="type == 'text_list'">
        <ul class="text_list">
          <li v-for="(item, idx) in items" :key="idx">
            <strong class="title">
              {{ item.title }}
              <span v-if="item.appVersion">
                <a href="javascript:void(0)" class="round_box" @click="goCall(item.text)" v-if="item.type == 'call'">전화걸기</a>
                <a href="javascript:void(0)" class="round_box" @click="copyText(item.text)" v-else-if="item.type == 'copy'">복사</a>
              </span>
            </strong>
            <span class="text" v-if="item.type == 'call' && item.appVersion">
              <a href="javascript:void(0)" @click="goCall(item.text)">{{ item.text }}</a>
            </span>
            <span class="text" v-else-if="item.type == 'copy' && item.appVersion">
              <a href="javascript:void(0)" @click="copyText(item.text)">{{ item.text }}</a>
            </span>
            <span class="text" v-else>{{ item.text }}</span>
          </li>
        </ul>
        <div class="explain_txt" v-html="explainTxt" v-if="explainTxt" />
      </template>

      <template v-else-if="type == 'order_notice'">
        <div style="margin: 32px 0">
          <div v-if="msgArr">
            <p v-for="(msg, index) in msgArr" :key="index" class="msg" v-html="msg" />
          </div>
          <div v-else>
            <div class="txt1">시간제 배달구역입니다.</div>
            <div class="txt2" v-if="marketOrderInfo.enableYn == 'Y'">시간제 배달 구역은 {{ marketOrderInfo.deliveryTime }}에 배달이 출발합니다.</div>
            <div class="txt2" v-if="marketOrderInfo.enableYn == 'N'">
              죄송합니다. <br />현재 주문 가능 시간대가 아닙니다.<br />주문 가능 시간대에 다시 주문해주시기 바랍니다.
            </div>
            <div class="txt3">시간제 배달구역 주문 가능 시간</div>
            <div class="txt4">{{ marketOrderInfo.orderStart }} ~ {{ marketOrderInfo.orderEnd }}</div>

            <div class="input_label mt_30" v-if="marketOrderInfo.enableYn == 'Y'">
              <input type="checkbox" id="closeTimeNotice" :data-market-id="marketId" @change="changeCheckbox" />
              <label class="ml_5" for="closeTimeNotice">오늘 하루 보지 않기</label>
            </div>
          </div>

          <div v-if="btnArr">
            <!-- btnArr 정의되지 않으면 버튼을 생성하지 않음 -->
            <div v-if="btnArr.length" class="btn_area" :class="btnAlign">
              <!-- 요소 있음 -->
              <Btn v-for="(btn, index) in btnArr" :type="btn.type" :key="index" :value="btn.txt" :data="btn.data" @btnCallback="btn.btnCallback" />
            </div>
            <div v-else class="btn_area center">
              <!-- 정의는 되었지만 요소가 없을때 확인 버튼만 적용 -->
              <Btn value="확인" @btnCallback="closeModal ? closeModal() : ''" />
            </div>
          </div>
          <Btn
            v-else
            type="type4 else1"
            :value="marketOrderInfo.enableYn == 'Y' ? '주문하기' : '확인'"
            @btnCallback="closeModal ? (marketOrderInfo.enableYn == 'Y' ? closeModal({ marketId: marketId }) : closeModal()) : ''"
          />
        </div>
      </template>

      <template v-else-if="type == 'radio'">
        <RadioList type="type1" :options="radioOptions" :checkValue="radioCheckValue" :changeRadio="callback" />
      </template>

      <template v-else-if="type == 'select'">
        <ul>
          <li class="select_item" v-for="(item, idx) in items" :key="idx">
            <a href="javascript:void(0)" @click="closeModal(item)">
              <!--              INFO:: icon_check 클래스도 사용 가능 -->
              <!--              <span class="icon_check" :class="{active : item.type == selectedItem.type}"/>-->
              <span class="icon_radio" :class="{ active: item.type == selectedItem.type }" />
              <span class="item_text">{{ item.text }}</span>
            </a>
          </li>
        </ul>
      </template>

      <template v-else-if="type == 'image'">
        <div v-if="items.length == 1">
          <a
            href="javascript:void(0)"
            :data-title="items[0].title"
            :data-notice-id="items[0].noticeId"
            :data-detail-url="items[0].detailUrl"
            @click="clickBanner"
          >
            <img :src="items[0].popupImage" :alt="items[0].title" loading="lazy" />
          </a>
          <div class="image_banner_bottom">
            <div class="area_left">
              <input type="checkbox" id="closeTodayPopup" :data-market-id="marketId" @change="closeModal" />
              <label for="closeTodayPopup">오늘 하루 보지 않기</label>
            </div>
            <div class="area_right">
              <a href="javascript:void(0)" class="close_x" @click="closeModal">닫기</a>
            </div>
          </div>
        </div>
        <div v-else class="images">
          <div @click="clickBanner">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="(banner, index) in items" :key="'banner_' + index">
                <img
                  :src="banner.popupImage"
                  :alt="banner.title"
                  :data-title="banner.title"
                  :data-detail-url="banner.detailUrl"
                  :data-notice-id="banner.noticeId"
                  @load="onLoad(index)"
                  loading="lazy"
                />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="image_banner_bottom">
            <div class="area_left">
              <input type="checkbox" id="closeTodayPopup" :data-market-id="marketId" @change="closeModal" /><!-- id="closeTodayPopupImages" -->
              <label for="closeTodayPopup">오늘 하루 보지 않기</label>
            </div>
            <div class="area_right">
              <a href="javascript:void(0)" class="close_x" @click="closeModal">닫기</a>
            </div>
          </div>
        </div>
      </template>
      <a href="javascript:void(0)" v-if="!resultType && type != 'image'" class="close_x" @click="closeModal ? closeModal() : ''">레이어 팝업 닫기</a>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Btn from "@/components/new/Btn";
import RadioList from "@/components/list/RadioList";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "Modal",
  props: {
    title: String,
    closeModal: Function,
    msgArr: Array,
    btnArr: Array,
    btnAlign: {
      type: String,
      default: "center",
    },
    resultType: Boolean,
    type: {
      type: String,
      default: "alert",
    },
    items: Array,
    selectedItem: Object,
    marketId: String,
    explainTxt: String,

    marketOrderInfo: Object,
    changeCheckbox: Function,
    // btnCallback: Function,

    radioOptions: Array,
    radioCheckValue: String,
    callback: Function,
    onCartChanged: Function,
    // selectRadioOption: Function,
  },
  components: {
    Btn,
    Swiper,
    SwiperSlide,
    RadioList,
  },
  methods: {
    onLoad: function (idx) {
      if (idx === this.items.length - 1) {
        setTimeout(() => {
          const imagesEl = document.querySelector(".images");
          imagesEl.style.setProperty("top", "50%");
          imagesEl.style.setProperty("opacity", "1");
        }, 500);
      }
    },
    goCall: function (tel) {
      this.$callNative("goCall", { tel: tel });
    },
    copyText: function (text) {
      const _this = this;
      this.$copyText(text).then(function () {
        _this.$callNative("showToast", { message: "복사 하였습니다." });
      });
    },
    clickBanner: async function () {
      let currentOS = "else";
      let mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

      if (mobile) {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.search("android") > -1)
          currentOS = "android";
        else if ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1))
          currentOS = "ios";
        else
          currentOS = "else";
      }

      // Android
      if(currentOS === "android"){
        window.open("intent://mall.noljang.co.kr/home#Intent;scheme=http;package=com.android.chrome;end");
        // IOS
      } else if(currentOS === "ios"){
        window.open("googlechrome:////mall.noljang.co.kr/home");
      }
      this.closeModal();

      // const dataset = event.target.dataset.detailUrl ? event.target.dataset : event.currentTarget.dataset;
      // // console.log(dataset);
      //
      // const noticeId = event.target.dataset.noticeId ? event.target.dataset.noticeId : event.currentTarget.dataset.noticeId;
      // if (noticeId === "123456") {
      //   const cart = {
      //     marketId: 45,
      //     products: [
      //       {
      //         compoundKey: "33907",
      //         deliveryMethod: "PKG",
      //         productName: "육계 10호",
      //         quantity: 1,
      //         storeId: 28,
      //         unitName: "g/1마리",
      //         unitNumber: "1",
      //       },
      //     ],
      //   };
      //
      //   this.$callNative("setCartCompoundKey", { cartData: cart });
      //   this.onCartChanged(cart);
      //   await router.push({ name: "장바구니", params: { reload: true } });
      //   this.closeModal();
      // } else if (noticeId) {
      //   const uniqueEventUrl = "v3/noljang/main/banners/" + noticeId + "/click";
      //   const response = await this.$axios2.get(uniqueEventUrl, { headers: { userId: localStorage.getItem("userId") } });
      //   const result = response.data.result;
      //   if (response.data.responseCode === 2000) {
      //     if (result.bannerClickType === "EVENT_DETAIL") {
      //       if (result.noticeDetailDto.noticeDetailUrl) {
      //         if (event.target.dataset.detailUrl) {
      //           await router.push({
      //             name: "이벤트상세",
      //             params: {
      //               title: result.noticeDetailDto.noticeTitle,
      //               url: result.noticeDetailDto.noticeDetailUrl,
      //             },
      //           });
      //         }
      //       }
      //     } else if (result.bannerClickType === "PRODUCT_DETAIL") {
      //       const productId = result.productDetailDto.productId;
      //       await router.push({ name: "상품 메인", params: { productId: productId } });
      //     } else if (result.bannerClickType === "STORE_MAIN") {
      //       const marketId = result.storeMainDto.marketId;
      //       const storeId = result.storeMainDto.storeId;
      //       await router.push({ name: "상점 메인", params: { marketId: marketId, storeId: storeId } });
      //     } else if (result.bannerClickType === "BRAND_MALL_DETAIL") {
      //     } else if (result.bannerClickType === "MARKET_MAIN") {
      //       const marketId = result.marketBannerDto.marketId;
      //       const marketName = result.marketBannerDto.marketName;
      //       await router.push({ name: "시장 메인", params: { marketId: marketId, marketName: marketName } });
      //     }
      //     this.closeModal();
      //   }
      // }
    },
    swiper() {
      return this.$refs.mySwiper.$swiper; // 배너
    },
  },
  data: function () {
    return {
      swiperOptions: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        autoplay: {
          delay: 3000,
        },
        slidesPerView: "auto",
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 1,
      },
      widthInnerWidthPx: window.innerWidth - 30 + "px",
      productId: null,
    };
  },
  mounted: function () {
    const imagesEl = document.querySelector(".images");
    const width = (screen.width > 500 ? 500 : screen.width) - 30;
    imagesEl?.style.setProperty("width", width + "px");
    imagesEl?.style.setProperty("max-width", "400px");
  },
};
</script>

<style lang="scss" scoped>
.swiper-pagination {
  bottom: 45px !important;
}

.swiper-slide {
  width: 100%;
}

#modals-container {
  &.resultType {
    .vm--overlay {
      display: none;
    }
  }
}

.modal_wrap {
  position: relative;
  padding: 30px 20px 20px;
  border: {
    width: 4px 0 0;
    style: solid;
    color: $active-color;
  }
  text-align: center;

  &.order_notice {
    padding: 0 20px;
    h1 {
      height: 53px;
      padding: 15px 0 0 0;
      margin: 0;
      //background-color: #f00;
      border: {
        width: 0 0 1px;
        style: solid;
        color: $border-type1;
      }
      @include font(18, 24, normal, medium);
      text-align: left;
    }
    .close_x {
      top: 17px;
      right: 20px;
    }

    .txt1 {
      @include font(18, 24, normal, medium);
    }
    .txt2 {
      margin: 15px 0 0;
      @include font(15, 22, normal, medium);
      color: $gray;
    }
    .txt3 {
      margin: 15px 0 0;
      @include font(14, 20, normal);
    }
    .txt4 {
      margin: 3px 0 0;
      @include font(18, 24, normal, bold);
      color: $active_color;
    }
  }

  &.result_type {
    padding: 20px;
  }
  &.image {
    padding: 0;
    border-width: 0;
    .image_banner_bottom {
      padding: 10px;
      overflow: hidden;
      background-color: $white;
      .area_left {
        float: left;
      }
      .area_right {
        float: right;
      }
      input,
      label {
        vertical-align: middle;
      }
      label {
        display: inline-block;
        padding: 2px 0 0 3px;
      }
      .close_x {
        display: inline-block;
        position: unset;
        width: auto;
        height: auto;
        padding: 2px 0 0 15px;
        background-image: url("~svg/icon_close.svg");
        background-repeat: no-repeat;
        background-position: 0 7px;
        background-size: auto 12px;
        color: $gray;
        text-indent: unset;
        //opacity: 0.5;
      }
    }
  }
  h1 {
    margin-bottom: 20px;
    @include font(17, 20, normal, medium);
  }
  .msg {
    @include font(16, 22, normal);
    color: #707070;

    .txt1 {
      @include font(18, 24, normal, medium);
    }
    .txt2 {
      margin: 15px 0 0;
      @include font(15, 22, normal, medium);

      color: $gray;
    }
    .txt3 {
      margin: 15px 0 0;
      @include font(14, 20, normal);
    }
    .txt4 {
      margin: 3px 0 0;
      @include font(18, 24, normal, bold);
      color: $active_color;
    }
  }
  .btn_area {
    margin: 20px 0 0;
    text-align: center;
    &.center {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > a {
        margin: 0;
      }
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
    > a::v-deep {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 7px);
      margin: 0 10px 0 0;
      @include border-radius(15px);

      &:last-child {
        margin: 0;
      }

      &:only-child {
        width: 100%;
      }

      &.type1 {
        height: 39px;
        border: {
          color: $active-background-color;
        }
        color: $active-background-color !important;

        &.inverse {
          color: $white !important;
        }
      }
    }
  }

  .close_x {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    height: 14px;
    background-image: url("~svg/icon_close.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    text-indent: -99999px;
    //opacity: 0.5;
  }

  &.text_list {
    padding: 15px 20px 30px 20px;

    .close_x {
      top: 15px;
      right: 20px;
      line-height: 20px;
    }

    h1 {
      text-align: left;
      margin: 0;
      padding: 0 0 10px 0;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type1;
      }
    }

    ul {
      text-align: left;
      li {
        margin: 20px 0 0;
      }
    }
    .title {
      display: block;
      @include font(14, 26, 0, bold);
      color: $black;
    }
    .text {
      display: block;
      @include font(16, 22, 0);
      color: $gray;
    }
  }

  &.select {
    padding: 26px 20px 20px 20px;
    .close_x {
      top: 26px;
      right: 20px;
      line-height: 20px;
    }

    h1 {
      text-align: left;
      margin: 0;
      padding: 0 0 10px 0;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type1;
      }
    }

    ul {
      padding: 20px 0 0 0;
      .select_item {
        text-align: left;

        &:not(:first-child) {
          margin: 20px 0 0 0;
        }

        > a {
          display: flex;
          align-items: center;
          //align-items: center;
          width: 100%;
          color: $select-item-txt-color;

          > .icon_check,
          .icon_radio {
            align-self: flex-start;
            margin: 0 10px 0 0;
          }

          > .item_text {
            max-width: calc(100% - 40px);
            @include font(16);
            line-height: 30px;
          }
        }
      }
    }
  }
}

.text_list {
  a {
    color: $gray !important;
    //text-decoration: underline !important;
  }
}

.round_box {
  display: inline-block;
  padding: 3px 10px 2px;
  margin: 0 0 0 5px;
  background-color: $background-color-type6;
  @include border-radius(10px);
  @include font(12, 16, 0);
  font-weight: 100;
  color: $gray;
  vertical-align: middle;
}

.explain_txt {
  padding: 10px 0 0;
  margin: 20px 0 0;
  border: {
    width: 1px 0 0 0;
    style: solid;
    color: $border-type1;
  }
}

.input_label {
  input[type="checkbox"],
  label {
    vertical-align: middle;
  }
}

.images {
  position: fixed;
  top: 100vh;
  transform: translateY(-50%);
  transition: 0.35s ease;
  opacity: 0;
}
</style>
