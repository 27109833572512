const getState = () => {
  return {
    title: "",
    pageMode: "", // write : 리뷰쓰기, view : 리뷰보기
    userId: "",
    orderId: "",
    orderList: [],
    isProgressing: true,
  };
};

const state = getState();

const mutations = {
  registReview: function (state, data) {},
  toggleLike: function (state, data) {
    // console.log(data);
    const orderList = state.orderList.map((order) => {
      if (order.productId === data.productId) {
        // console.log(data.hasActive);
        if (!data.hasActive) {
          if (data.type == "like") {
            order.likeYn = "Y";
            order.dislikeYn = "N";
          } else if (data.type == "dislike") {
            order.likeYn = "N";
            order.dislikeYn = "Y";
          }
        } else {
          order.likeYn = "N";
          order.dislikeYn = "N";
        }
      }
      return order;
    });
    state.orderList = orderList;
  },
  toggleReview: function (state, data) {
    const target = data.event.currentTarget.closest("li").querySelector("div.review");
    target.classList.toggle("active");
    data.event.currentTarget.classList.toggle("inactive");

    if (target.classList.contains("active")) {
      data.event.currentTarget.text = "리뷰취소";
    } else {
      data.event.currentTarget.text = "리뷰쓰기";

      const reviewId = data.event.currentTarget.dataset.reviewId;
      const orderList = state.orderList.map((order) => {
        if (order.id == reviewId) {
          order.content = "";
        }
        return order;
      });
      state.orderList = orderList;
      data.event.currentTarget.closest("li").querySelector(".contenteditable").innerHTML = "";
    }
  },
  moveToApp: function (state, data) {
    // this._vm.$callNative('moveToOrder', {orderId: data.orderId});
  },
  initState: function (state, data) {
    var reviews = data.initData.reviews;
    if (state.pageMode == "view") {
      reviews = [];
      data.initData.reviews.forEach((review) => {
        if (review.content || review.dislikeYn || review.likeYn) {
          reviews.push(review);
        }
      });
    }
    state["orderList"] = reviews;
    // state['orderList'] = data.initData.reviews.map(review => {// 강제로 보이지 않게 처리 - 테스트용
    //     review.content = '';
    //     return review;
    // });
  },
  goBack: function () {
    this._vm.$navigateBack();
    // this._vm.$closeView();
  },
  reset: function (state) {
    Object.assign(state, getState());
  },
};

const actions = {
  registReview: async function (store, data) {
    var validationBoolean = false;
    const orderId = state.orderId;
    await this._vm.$axios2
      .get("/v3/orders/members/" + state.userId + "/" + orderId)
      .then((response) => {
        state.isProgressing = false;
        if (response.data.responseCode == 2000) {
          if (response.data.result.hasReview == "N") {
            validationBoolean = true;
          }
        } else {
          alert(response.data.errorObject.errorMessage);
        }
      })
      .catch(() => {
        state.isProgressing = false;
      });

    if (!validationBoolean) {
      this._vm.$callNative("showToast", {
        message: "리뷰쓰기는 주문일로부터 3일 동안만 가능합니다.",
      });
      return;
    }

    const input = document.querySelectorAll(".contenteditable");
    const inputLength = input.length;
    for (var i = 0; i < inputLength; i++) {
      state.orderList.map((order) => {
        if (order.productId == input[i].dataset.productId) {
          order.content = input[i].innerText;
        }
        return order;
      });
    }
    var likeDislikeCount = 0;
    const sendData = {
      orderId: orderId,
      // orderId: state.orderId? state.orderId : localStorage.getItem('userId'),
      userId: state.userId,
      reviews: state.orderList.map((order) => {
        if (order.likeYn || order.dislikeYn) {
          likeDislikeCount++;
        }

        const data = {
          productId: order.productId,
          content: order.content,
          likeYn: order.likeYn,
          dislikeYn: order.dislikeYn,
        };
        return data;
      }),
    };

    if (likeDislikeCount == 0) {
      this._vm.$callNative("showToast", {
        message: "좋아요/안좋아요를 선택해 주세요.",
      });
      return;
    }

    const reviews = [];
    sendData.reviews.forEach((review) => {
      if (review.dislikeYn || review.likeYn) {
        reviews.push(review);
      }
    });
    sendData.reviews = reviews;

    state.isProgressing = true;
    const axiosUrl = "/v3/reviews";
    this._vm.$axios2
      .post(axiosUrl, sendData)
      .then((response) => {
        state.isProgressing = false;
        if (response.data.responseCode == 2000) {
          store.commit("goBack");
        } else {
          alert(response.data.errorObject.errorMessage);
        }
      })
      .catch(() => {
        state.isProgressing = false;
      });
  },
  toggleLike: function (store, data) {
    store.commit("toggleLike", {
      productId: data.productId,
      type: data.event.currentTarget.dataset.type,
      hasActive: data.event.currentTarget.querySelector("span").classList.contains("active"),
      event: data.event,
    });
  },
  initState: function (store, data) {
    if (data.pageMode == "write") {
      state.pageMode = "write";
      state.title = "리뷰쓰기";
    } else if (data.pageMode == "view") {
      state.pageMode = "view";
      state.title = "리뷰보기";
    } else {
      state.pageMode = "write";
      state.title = "리뷰쓰기";
    }

    state.userId = data.userId;
    state.orderId = data.orderId;
    const axiosUrl = "/v3/reviews/" + state.orderId;
    state.isProgressing = true;
    this._vm.$axios2
      .get(axiosUrl)
      .then((response) => {
        state.isProgressing = false;
        if (response.data.responseCode == 2000) {
          store.commit("initState", {
            initData: response.data.result,
          });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
      })
      .catch(() => {
        state.isProgressing = false;
      });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
