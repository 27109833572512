<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" />
    <div class="content" @scroll="handleScroll">
      <ul class="date_order_list" v-if="orderList.length">
        <li v-for="(orders, index) in orderList" :key="orders.orderDate + '_' + index">
          <div class="title_date">
            {{ $moment(orders.orderDate).format("YYYY.MM.DD (dd)") }}
          </div>
          <OrderHistoryList :orders="orders.orders" @moveToApp="moveToApp" />
        </li>
      </ul>
      <div v-else class="empty_list">주문 내역이 없습니다.</div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import OrderHistoryList from "@/components/list/OrderHistoryList";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "OrderHistory",
  components: {
    OrderHistoryList,
    TopHeader,
  },
  computed: {
    ...mapState({
      title: (state) => state.orderHistory.title,
      orderList: (state) => state.orderHistory.orderList,
    }),
  },
  methods: {
    ...mapMutations("orderHistory", ["moveToApp"]),
    ...mapActions("orderHistory", [
      "handleScroll",
      // 'goBack'
    ]),
    goBack: function () {
      this.$closeView();
    },
  },
  beforeCreate: function () {
    this.$store.dispatch("orderHistory/initState", {
      userId: this.$route.query.userId,
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 44px);
  padding: 0 20px 20px;
  background: $nj_background_gray;
  overflow-y: auto;
}
.title_date {
  @include font(18, 27, -1.08);
  color: $nj_black;
}
.date_order_list > li {
  padding: 20px 0;
  border-style: solid;
  border-color: $nj_divider_gray;
  border-width: 0 0 1px;
  &:last-child {
    border-width: 0;
  }
}
.empty_list {
  padding: 50px 0 0;
  @include font(15, 22, -1.2);
  text-align: center;
  color: $nj_placeholder_gray;
}
</style>
