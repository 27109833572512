<template>
  <div class="info_wrap">
    <!--    Tab Header -->
    <div class="tab">
      <div v-for="tab in tabList" v-bind:key="tab.id" :class="{ active: tab.id == currentTab.id }" class="tab_item" @click="onTabClick(tab.id)">
        {{ tab.label }}
      </div>
    </div>
    <!--    Tab Content -->
    <div class="tab_content">
      <keep-alive>
        <component :is="currentTab.component" :description="description" :notationalInformations="notationalInformations"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ProductDetail from "./ProductDetail";
import ProductRequiredInfo from "./ProductRequiredInfo";

export default {
  name: "ProductDetailTab",
  props: {
    description: String,
    notationalInformations: Array,
  },
  data: function () {
    return {
      currentTabId: 1,
      tabList: [
        { id: 1, label: "상세정보", component: ProductDetail },
        { id: 2, label: "필수표기정보", component: ProductRequiredInfo },
      ],
    };
  },
  components: {
    ProductDetail,
    ProductRequiredInfo,
  },
  methods: {
    onTabClick: function (tabId) {
      this.currentTabId = tabId;
    },
  },
  computed: {
    currentTab() {
      return this.tabList.find((el) => el.id === this.currentTabId) || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.info_wrap {
  background: #ffffff;
}

.tab {
  padding: 0 20px;
}

.tab_item {
  width: 50%;
  height: 45px;
  display: inline-block;
  font: normal normal normal 14px Noto Sans CJK KR;
  letter-spacing: -0.84px;
  color: $nj_text_main_gray;
  line-height: 45px;
  text-align: center;
  border-bottom: 1px solid $nj_divider_gray;
}

.tab_item.active {
  font-family: Noto Sans CJK KR Bold;
  color: $nj_black;
  border-bottom: 4px solid $nj_black;
}

.tab_content {
  //overflow-y: scroll;
  min-height: 250px;
}
</style>
