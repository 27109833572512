<template>
  <div class="relative">
    <div class="no_product_wrap" v-if="isDisabled">
      <div class="no_product_msg">
        해당 상품은 판매종료되었습니다.
        <div class="mt_10">
          <a href="javascript:void(0);" class="delete_product" @click="$emit('delete', { marketId, compoundKey })"> 상품 삭제하기 </a>
        </div>
      </div>
    </div>

    <div class="product_wrap" :class="[isInfo ? 'view_info' : isHistory ? 'view_history' : '']">
      <div class="product_top">
        <div class="name_repeat" v-if="isDefault" @click="$emit('storeClick')">
          {{ storeName }}
        </div>
        <a
          href="javascript:void(0);"
          class="product_delete"
          :data-product-id="productId"
          v-if="viewMode != 'info'"
          @click="$emit('delete', { marketId, compoundKey })"
          >상품삭제</a
        >
        <div class="product_detail">
          <div>
            <div class="thumb_wrap" @click="$emit('productClick')">
              <img :src="productImageUrl" class="thumb no_img" alt="" />
            </div>

            <div class="name_repeat mt_5 mb_5" v-if="viewMode == 'info'">{{ marketName }} ({{ storeName }})</div>
            <div class="product_name">
              {{ name }}
              <span v-if="unitName" class="unit">{{ unitNumber + unitName }}</span>
            </div>
            <div v-if="originName" class="origin_name">원산지 : {{ originName }}</div>

            <ul class="chip_list mt_6">
              <div class="name_repeat mt_5 mb_5" v-if="isInfo || isHistory">
                {{ marketName }}
                <template v-if="isInfo">({{ storeName }})</template>
              </div>
            </ul>

            <ul class="chip_list mt_2">
              <li class="chip" v-for="(paymentType, idx) in paymentTypes" :key="idx">
                {{ paymentType }}
              </li>
            </ul>

            <div class="quantity_wrap" v-if="viewMode != 'info'">
              <a href="javascript:void(0)" class="quantity_btn btn_minus" @click="$emit('remove', { compoundKey, marketId, $event })"></a>
              <div class="quantity_txt">{{ quantity }}</div>
              <a href="javascript:void(0)" class="quantity_btn btn_plus" @click="$emit('add', { compoundKey, marketId, $event })"></a>
            </div>

            <ul class="opt_list" v-if="optionGroups">
              <li v-for="optionGroup in optionGroups" :key="optionGroup.optionGroupId">
                <div v-for="option in optionGroup.options" :key="option.optionId">
                  {{ option.optionName }}

                  <span class="ml_5">{{
                    Number(option.optionPrice) > 0 ? "+" + $formatPrice(Number(option.optionPrice)) : $formatPrice(Number(option.optionPrice))
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="product_bottom" v-if="!isHistory">
        <div v-if="discountPrice" class="discount">
          <div class="right">
            <div class="price_wrap original_name">
              <span class="price">{{ $formatPrice(price * quantity) }}</span>
            </div>
          </div>
        </div>

        <div class="left clear">상품 금액</div>
        <div class="right">
          <div class="price_wrap">
            <span class="price">{{ $formatPrice(totalProductPrice) }}</span>
            <span class="price_txt">원</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="requestInput" class="mt_20 mb_20">
      <NJInput placeholder="해당상품관련 요청사항을 적어주세요." @input="$emit('input', $event)"></NJInput>
    </div>
  </div>
</template>

<script>
import NJInput from "../NJInput";

export default {
  name: "ProductList",
  props: {
    viewMode: {
      type: String,
      default: "default",
    },
    compoundKey: String,
    marketName: String,

    marketId: Number,
    productId: Number,
    name: String,
    storeName: String,
    quantity: Number,
    price: Number,
    discountPrice: Number,
    saleYn: {
      type: String,
      default: "Y",
    },
    paymentTypes: Array,
    productImageUrl: String,
    requestInput: Boolean,
    unitName: String,
    unitNumber: Number,
    originName: String,
    optionGroups: Array,
    totalOptionPrice: Number,
  },
  data: function () {
    return {
      // isVisible: true
    };
  },
  components: {
    NJInput,
  },
  computed: {
    isDefault: function () {
      return this.viewMode == "default";
    },
    isInfo: function () {
      return this.viewMode == "info";
    },
    isHistory: function () {
      return this.viewMode == "history";
    },
    isDisabled: function () {
      return this.saleYn == "Y" ? false : true;
    },
    totalProductPrice: function () {
      var returnValue = this.discountPrice ? this.discountPrice * this.quantity : this.price * this.quantity;
      if (this.totalOptionPrice) {
        returnValue = this.discountPrice
          ? (this.discountPrice + this.totalOptionPrice) * this.quantity
          : (this.price + this.totalOptionPrice) * this.quantity;
      }
      return returnValue;
    },
    // finalPrice: function () {
    //   let optionPrice = 0;
    //
    //   if (this.optionGroups) {
    //     this.optionGroups.forEach(optionGroup => {
    //       optionGroup.options.forEach(option => {
    //         optionPrice += option.optionPrice;
    //       });
    //     });
    //   }
    //
    //   const price = (this.discountPrice ? this.discountPrice : this.price) + optionPrice;
    //
    //   return price;
    // }
  },
};
</script>

<style lang="scss" scoped>
.product_wrap {
  position: relative;
  min-height: 204px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 0px 1px 0px #00000029;
  //&:first-child {
  //  margin: 0;
  //}

  &.view_info {
    // 결제완료 페이지 적용
    min-height: 178px;
    .product_top {
      padding: 20px;
    }
    .product_detail {
      margin: {
        top: 0;
      }
    }
  }

  &.view_history {
    // 주문 내역 페이지 적용
    min-height: 120px;
    .product_top {
      padding: 20px;
    }
    .product_detail {
      margin: {
        top: 0;
      }
    }
  }
}
.no_product_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include border-radius(20px);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 1;

  .no_product_msg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    @include font(16, 22, -0.96, medium);
    color: $white;
  }
}

.product_top {
  padding: 17px 20px 20px;
}
.name_repeat {
  font-size: 16px;
  color: $nj_text_main_gray;
}
.product_delete {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 14px;
  height: 14px;
  background-image: url("../../assets/images/Xbtn.png");
  background-repeat: no-repeat;
  background-position: 0;
  text-indent: -9999px;
}
.product_detail {
  position: relative;
  min-height: 80px;
  padding: {
    left: 110px;
    top: 2px;
  }
  margin: {
    top: 10px;
  }
}
.thumb_wrap {
  position: absolute;
  top: 0;
  left: 0;
  .thumb {
    width: 100px;
    height: 80px;
    @include border-radius(20px);
    object-fit: cover;
    &.no_img {
      max-height: 80px;
      background-size: 28px;
      color: #000;
    }
  }
}
.product_name {
  line-height: 1.4;
  font-family: "Noto Sans CJK KR Medium";
  font-size: 16px;
  letter-spacing: -0.96px;
  color: $nj_black;
  @include text_ellipsis(2, 44px);
}
.chip_list {
  li {
    display: inline-block;
    height: 16px;
    padding: 0px 10px;
    margin-right: 2px;
    border: 1px solid #e3e3e3;
    @include border-radius(20px);
    @include font(10, 14, -0.6);
    color: $nj_text_main_gray;
    text-align: center;
  }
}

.quantity_wrap {
  margin-top: 10px;
  height: 30px;

  & > * {
    display: inline-block;
    vertical-align: top;
  }

  .quantity_btn {
    width: 36px;
    height: 100%;
    font-size: 28px;
    line-height: 28px;
    text-align: center;

    &.btn_minus {
      background: url("../../assets/images/icon_minus.png");
    }

    &.btn_plus {
      background: url("../../assets/images/icon_plus.png");
    }
  }

  .quantity_txt {
    height: 100%;
    padding: 0 10px;
    @include font(22, 27, -1.32, medium);
    color: #262626;
    //font-size: 22px;
    //line-height: 30px;
  }
}

.product_bottom {
  padding: 10px 20px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  border: {
    style: solid;
    color: #e9e9e9;
    width: 1px 0 0;
  }
  .discount {
    .left {
      color: $nj_text_main_gray;
    }
    .right {
      .price_wrap {
        &.original_name {
          height: 22px;
          .price {
            margin-right: 13px;
            @include font(18, 27, -1.08);
            color: $nj_text_main_gray;
          }
          //.price_txt {
          //  @include font(18, 27, -1.08);
          //  color: $nj_text_main_gray
          //}
        }
        .price {
          color: $nj_text_main_gray;
          text-decoration: line-through;
        }

        .price_txt {
          color: $nj_text_main_gray;
          text-decoration: line-through;
        }
      }
    }
  }
  .left {
    float: left;
    @include font(16, 36, -0.96);
    color: $nj_red;
  }
  .right {
    float: right;

    .price_wrap {
      height: 36px;

      & > * {
        display: inline-block;
      }

      .price {
        @include font(24, 36, -1.44);
        color: $nj_black;
      }
      .price_txt {
        margin-left: 2px;
        @include font(14, 24, -0.84);
        color: $nj_text_main_gray;
      }
    }
  }
}
.unit {
  margin-left: 10px;
  @include font(16, 24, -0.96);
  color: $nj_text_sub_gray;
}
.origin_name {
  @include font(12, 18, -0.72);
  color: $nj_text_main_gray;
}
.opt_list {
  margin-top: 15px;
  > li {
    @include font(12, 18, -0.72);
    color: $nj_text_main_gray;
  }
}

.delete_product {
  @include font(16, 24, -0.96);
  color: $nj_red !important;
}
</style>
