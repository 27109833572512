const state = {
  title: "배송지 설정",
  userId: null,
  shipToDoro: "",
  shipToDetail: "",
  shipToName: "",
  currentAddress: {},
  addressList: [],
  addressSearchList: [],
  selectedSearchAddress: {}, // 검색된 주소 중 현재 선택되어 있는 주소

  currentPage: 1, // 현재 페이지
  pageInformation: {}, // 페이지 정보
  keyword: "", // 검색 키워드
  latestSearchInfo: null, // 최근 검색 정보

  isProgressing: false,
  displayAlertLayer: false,
  displayDeleteAlertLayer: false,

  currentShipToId: null,
};

const mutations = {
  moveToApp: function (state, data) {
    if (data.type == "shipTo") {
      this._vm.$callNative("shipToObjectToApp", data.sendData);
    }
  },
  initState: function (state, data) {
    Object.keys(data).forEach((item) => {
      state[item] = data[item];
    });
  },
  setSearchAddressInfo: function (state, data) {
    if (data) {
      state.addressSearchList = [...state.addressSearchList, ...data.addressList];
      state.pageInformation = data.pageInformation;
    } else {
      state.addressSearchList = [];
      state.pageInformation = {};
    }
  },
  setIsProgressing: function (state, isProgressing) {
    state.isProgressing = isProgressing;
  },
  setCurrentShipToId: function (state, shipToId) {
    state.currentShipToId = shipToId;
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },
  setKeyword: function (state, keyword) {
    state.keyword = keyword;
  },
  setLatestSearchInfo: function (state, { page, keyword }) {
    state.latestSearchInfo = { page, keyword };
  },
  setCurrentPage: function (state, page) {
    state.currentPage = page;
  },
  selectSearchAddress: function (state, data) {
    state.selectedSearchAddress = data;
  },
  showHideAlertLayer: function (state) {
    state.displayAlertLayer = !state.displayAlertLayer;
  },
  showHideDeleteAlertLayer: function (state) {
    state.displayDeleteAlertLayer = !state.displayDeleteAlertLayer;
  },
};

const actions = {
  // Process
  initState: async function (store, data) {
    const userId = data.userId;
    const shipToDoro = data.shipToDoro;
    const shipToDetail = data.shipToDetail;
    const shipToName = data.shipToName;
    let currentAddress = {};
    let addressList = [];

    try {
      if (userId) {
        store.commit("setIsProgressing", true);
        const shipToResponse = await this._vm.$axios1.get("/v3/user/member/address/" + userId + "/ship-to-list");
        store.commit("setIsProgressing", false);

        currentAddress = shipToResponse.data.result.userShipTo;
        addressList = shipToResponse.data.result.shipToList;
      }

      if (shipToDoro && shipToDetail) {
        currentAddress = {
          shipToFull: shipToDoro + " " + shipToDetail,
          shipToName: shipToName,
        };
      }
    } catch (e) {
      console.error(e);
    }

    store.commit("initState", {
      userId: userId,
      shipToDoro: shipToDoro,
      shipToDetail: shipToDetail,
      shipToName: shipToName,
      currentAddress: currentAddress,
      addressList: addressList,
    });
  },
  handleScroll(store, event) {
    if (!state.isProgressing) {
      const eventTarget = event.currentTarget;
      if (eventTarget.scrollTop + eventTarget.offsetHeight > eventTarget.scrollHeight - 50) {
        if (state.pageInformation.totalPage > state.currentPage) {
          store.dispatch("setCurrentPage", state.currentPage + 1);
        }
      }
    }
  },
  searchAddressList: async function (store) {
    if (!state.keyword.trim()) {
      store.commit("setSearchAddressInfo", null);
      return false;
    }

    try {
      if (state.latestSearchInfo) {
        const page = state.latestSearchInfo.page;
        const keyword = state.latestSearchInfo.keyword;

        // 신규 키워드 검색 / 최초 검색 / 검색 버튼을 통한 검색의 경우 기존 검색 내용 초기화
        if (state.keyword != keyword || state.currentPage == 1) {
          store.commit("setSearchAddressInfo", null);
        }
        // 같은 키워드의 같은 페이지 조회인 경우 프로세스 호출 X
        else if (state.keyword == keyword && state.currentPage == page) {
          return false;
        }
      }

      store.commit("setIsProgressing", true);
      const searchRespone = await this._vm.$axios1.get("/v3/user/address/search/" + state.keyword + "/20/" + state.currentPage);
      store.commit("setSearchAddressInfo", searchRespone ? searchRespone.data.result : null);
      store.commit("setLatestSearchInfo", {
        page: state.currentPage,
        keyword: state.keyword,
      });
      store.commit("setIsProgressing", false);
    } catch (e) {
      console.error(e);
    }
  },
  updateCurrentShipTo: async function (store, shipToId) {
    try {
      store.commit("setIsProgressing", true);
      const upResponse = await this._vm.$axios1.patch("/v3/user/member/address/" + state.userId + "/ship-to", {
        shipToId: shipToId,
      });

      if (upResponse.data.responseCode == 2000) {
        const address = state.addressList.find((item) => {
          return item.shipToId == shipToId;
        });

        store.commit("moveToApp", {
          type: "shipTo",
          sendData: address,
        });
      }
    } catch (e) {
      console.error(e);
    }

    store.commit("setIsProgressing", false);
  },
  saveCurrentAddress: async function (store, { shipToDoro, shipToJibun, shipToDetail, shipToName }) {
    if (!shipToDetail.trim()) {
      store.commit("showHideAlertLayer");
      return false;
    }

    try {
      store.commit("setIsProgressing", true);

      let addressData = {
        shipToDoro: shipToDoro,
        shipToJibun: shipToJibun,
      };

      const coordResponse = await this._vm.$axios1.post("/v3/user/address/ship-to/location/converter/", addressData);
      if (coordResponse.data.responseCode == 2000) {
        Object.assign(addressData, {
          shipToDetail: shipToDetail,
          shipToName: shipToName,
          latitude: coordResponse.data.result.latitude,
          longitude: coordResponse.data.result.longitude,
        });
      }

      if (state.userId) {
        // 현재 주소로 설정
        await this._vm.$axios1.put("/v3/user/member/address/" + state.userId + "/ship-to", {
          shipToDoro,
          shipToJibun,
          shipToDetail,
          shipToName,
        });
      }

      store.commit("moveToApp", {
        type: "shipTo",
        sendData: addressData,
      });
    } catch (e) {
      console.error(e);
    }
    store.commit("setIsProgressing", false);
  },
  deleteAddress: async function (store, shipToId) {
    store.commit("setIsProgressing", true);

    try {
      const deleteResponse = await this._vm.$axios1.delete("/v3/user/member/address/" + state.userId + "/ship-to", {
        data: {
          shipToId: shipToId,
        },
      });

      if (deleteResponse.data.responseCode == 2000) {
        store.commit("setCurrentShipToId", null);
        store.dispatch("initState", {
          userId: state.userId,
          shipToDoro: state.shipToDoro,
          shipToDetail: state.shipToDetail,
          shipToName: state.shipToName,
        });
      }
    } catch (e) {
      console.error(e);
    }

    store.commit("setIsProgressing", false);
  },

  // Local
  setKeyword: function (store, keyword) {
    store.commit("setKeyword", keyword);
  },
  setCurrentPage: function (store, page) {
    // 현재 페이지 설정
    store.commit("setCurrentPage", page);
    // 데이터 재 조회
    store.dispatch("searchAddressList");
  },
  selectSearchAddress: function (store, data) {
    store.commit("selectSearchAddress", data);
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
  showHideDeleteAlertLayer: async function (store, data) {
    if (state.displayDeleteAlertLayer) {
      if (data.btnId == "DELETE") {
        store.dispatch("deleteAddress", state.currentShipToId);
      }
    } else {
      store.commit("setCurrentShipToId", data.shipToId ? data.shipToId : null);
    }
    store.commit("showHideDeleteAlertLayer", state.currentShipToId);
  },
};

const getters = {
  isAddressRequired: (state) => {
    return !state.userId && Object.keys(state.currentAddress).length == 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
