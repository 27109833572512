<template>
  <!--  상점별 상품 목록-->
  <div class="nj_input_wrap">
    <input :placeholder="placeholder" @input="$emit('input', $event.target.value)" :value="value" />
  </div>
</template>

<script>
export default {
  name: "NJInput",
  props: {
    placeholder: String,
    value: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {},
};
</script>

<style lang="scss">
.nj_input_wrap {
  width: 100%;
  height: 55px;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px 20px;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
}

.nj_input_wrap input,
.nj_input_wrap select,
.nj_input_wrap select > option {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;

  font: normal normal normal 16px/22px Noto Sans CJK KR;
  letter-spacing: -0.96px;
  color: $nj_black;
}

.nj_input_wrap input::placeholder,
.nj_input_wrap select.default {
  font: normal normal normal 16px/22px Noto Sans CJK KR;
  letter-spacing: -0.96px;
  color: $nj_placeholder_gray;
}

.nj_input_wrap select {
  background: transparent;
}
</style>
