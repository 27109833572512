<template>
  <div>
    <naver-maps :width="width" :height="height" :mapOptions="mapOptions" :initLayers="initLayers" @load="onLoad">
      <naver-info-window class="info-window" @load="onWindowLoad" :isOpen="info" :marker="marker">
        <div class="info-window-container">
          <h1>{{ hello }}</h1>
        </div>
      </naver-info-window>
      <naver-marker :lat="37.478683" :lng="126.87877397242556" @click="onMarkerClicked" @load="onMarkerLoaded" data-type="center"
        ><div style="background-color: #000; color: #fff; border-radius: 20px">놀장</div></naver-marker
      >
      <naver-marker :lat="37.478683" :lng="126.877" @click="onMarkerClicked" @load="onMarkerLoaded" data-type="crew"
        ><img src="@/assets/images/icon_like_active.svg" /><br />크루</naver-marker
      >
      <!--      <naver-circle :lat="37" :lng="127" :radius="88600"/>-->
      <!--      <naver-rectangle :south="36" :north="38" :west="126" :east="128"/>-->
      <!--      <naver-ellipse :bounds="{south:36,north:38,west:126,east:128}"/>-->
      <!--      <naver-polygon :paths="[[{lat:37,lng:127},{lat:38,lng:127},{lat:38,lng:129},{lat:37,lng:128}]]"/>-->
      <!--      <naver-polyline :path="[{lat:37,lng:127},{lat:38,lng:129}]"/>-->
      <!--      <naver-ground-overlay-->
      <!--          :url="'//logoproject.naver.com/img/img_about.gif'"-->
      <!--          :bounds="{south:36.7,north:36.9,west:126.5,east:127.5}"/>-->
    </naver-maps>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      info: false,
      marker: null,
      count: 1,
      map: null,
      isCTT: false,
      mapOptions: {
        lat: 37.478683,
        lng: 126.87877397242556,
        zoom: 15,
        zoomControl: true,
        zoomControlOptions: { position: "TOP_RIGHT" },
        mapTypeControl: true,
        setIcon: "ImageIcon",

        // icon: {
        //   url: '@/assets/images/icon_like_active.svg',
        //   size: new naver.maps.Size(50, 52),
        //   origin: new naver.maps.Point(0, 0),
        //   anchor: new naver.maps.Point(25, 26)
        // }
      },
      // useStyleMap: true,
      initLayers: ["BACKGROUND", "BACKGROUND_DETAIL", "POI_KOREAN", "TRANSIT", "ENGLISH", "CHINESE", "JAPANESE"],
    };
  },
  computed: {
    hello() {
      return `Hello, World! × ${this.count}`;
    },
  },
  methods: {
    onLoad(vue) {
      this.map = vue;
    },
    onWindowLoad(that) {},
    onMarkerClicked(event) {
      this.info = !this.info;
    },
    onMarkerLoaded(vue) {
      this.marker = vue.marker;
    },
  },
  mounted() {
    setInterval(() => this.count++, 1000);
  },
};
</script>
<style scoped>
.info-window-container {
  padding: 10px;
  width: 300px;
  height: 100px;
}
</style>
