<!-- 결제 시 나오는 화면 -->
<template>
  <div>
    <TopHeader
      class="top_header"
      style="position: fixed; top: 0; width: 100%; z-index: 999"
      title="결제하기"
      :isCartVisible="false"
      @goBack="goBack"
    />
    <div class="contents">
      <div class="header_txt">주문해 주셔서 감사합니다.</div>
      <div class="contents_area" v-if="orders">
        <div class="payment_header">
          <div class="title">주문상품</div>
          <div class="absolute_right">
            {{ marketInfo.marketName }}
            <span class="accent_txt">{{ orders.length }}</span
            ><span class="normal_txt">건</span>
          </div>
        </div>

        <div class="list_wrap">
          <div class="list">
            <ProductList
              v-for="(product, idx) in orders"
              :key="idx"
              :keyIndex="'key_' + idx"
              :compoundKey="product.compoundKey ? product.compoundKey : null"
              :storeName="product.storeName"
              :productId="product.productId"
              :productImageUrl="product.imgUrl"
              :name="product.productName"
              :quantity="product.quantity"
              :price="product.price"
              :totalPrice="product.totalPrice"
              :discountPrice="product.discountPrice"
              :requestInput="true"
              :unitName="product.unitName"
              :unitNumber="product.unitNumber"
              :weight="product.weight"
              :originName="product.originName"
              :optionGroups="product.optionGroups ? (product.optionGroups.length > 0 ? product.optionGroups : null) : null"
              :totalOptionPrice="product.totalOptionPrice ? product.totalOptionPrice : null"
              :paymentTypes="product.paymentTypes"
              :placeholder="
                onSiteYn === 'Y' ? '점주님에게 상품관련 요청사항을 적어주세요. (도착예정시간)' : '점주님에게 상품관련 요청사항을 적어주세요.'
              "
              :orderRequest="product.orderRequest ? product.orderRequest : ''"
              :inputProductRequest="inputProductRequest"
              :focusProductRequest="focusProductRequest"
              :deliveryMethod="product.deliveryMethod"
              :marketId="product.marketId"
              viewMode="payment"
            />
          </div>
        </div>

        <div class="contents_unit" v-if="issueCoupon">
          <div class="payment_header">
            <div class="title">할인쿠폰</div>
          </div>
          <div style="padding: 15px 15px 30px; background-color: #fff">
            <Btn
              :value="issueCoupon.couponName + ' 받기'"
              type="type4 normal else2"
              :data="{ couponNumber: issueCoupon.couponNumber }"
              @btnCallback="issuedCoupon"
            />
            <p class="center mt_10">총 상품금액 {{ $formatPrice(issueCoupon.minOrderPay) }}원 이상 사용가능합니다.</p>
          </div>
        </div>

        <div class="contents_unit">
          <div class="payment_header">
            <div class="title">적용쿠폰</div>
            <div class="absolute_right type1" v-if="coupons.length > 0">
              <Btn :value="`보유쿠폰${coupons.length > 1 ? ' (' + coupons.length + '개)' : ''}`" type="type6" @btnCallback="moveToCoupon" />
            </div>
          </div>
          <div class="coupon" style="background-color: #fff">
            <div class="coupon_item_wrap" v-if="useCoupon">
              <!--
              <div>
                <span class="coupon_price">- {{ $formatPrice(useCoupon.couponPrice) }}</span>
                <span class="ml_3">원</span>
                <Btn value="변경하기" type="type1 accent ml_10" :btnId="String(useCoupon.couponId)" @btnCallback="moveToCoupon" />
              </div>
              -->

              <div class="coupon_item enable">
                <div class="coupon_info">
                  <span class="coupon_price_wrap">
                    <span class="coupon_price">{{ $formatPrice(useCoupon.couponPrice) }}</span>
                    <span class="coupon_price_txt">원</span>
                  </span>
                  <span class="coupon_name">{{ useCoupon.couponName }}</span>
                  <span class="coupon_notice_detail">{{ $formatPrice(useCoupon.minProductsPrice) }}원 이상 구매 시 사용 가능합니다.</span>
                </div>
                <div class="coupon_is_usable">
                  <span class="coupon_icon" />
                  <span>COUPON</span>
                  <span>사용</span>
                </div>
              </div>
            </div>
            <div v-else style="padding: 30px 0px 40px; text-align: center">사용가능 쿠폰 {{ coupons.length }}개가 있습니다.</div>
          </div>
        </div>

        <!-- eventPaymentDiscountUseYn은 aT 이벤트 외에 다른 이벤트 할인도 포함되어 있음 -->
        <div class="at_banner_wrap" v-if="discountAtEventContent" style="display: none">
          <div class="at_banner_area">
            <div class="left_area">
              <div class="area">
                <div class="title">
                  {{ discountType === "SEOUL" ? "서울시특판" : "농할" }} 이벤트
                  <div class="accent">최대 40% 할인</div>
                </div>
                <div class="gray">{{ discountAtEventContent.atEventDate }}</div>
                <!--                <div class="gray">2월 14일 ~ 예산소진시</div>-->
              </div>
            </div>
            <div class="right_area">
              <div class="txt1">사용가능금액</div>
              <div class="txt2">{{ $formatPrice(discountAtEventContent.atEventLimitPrice) }}<span>원</span></div>
              <div class="txt3">{{ discountAtEventContent.atEventLimitText }}</div>
            </div>
          </div>

          <!--          <div class="at_banner">
            <div class="title"><strong>농할 최대 40% 할인 이벤트</strong></div>
            <div>국산 농할 상품 결제 시, 1인 1주 1만원 한도</div>
            <ul class="at_banner_list">
              <li>
                사용가능금액
                <div class="absolute_right"><strong class="price">10,000</strong>원</div>
              </li>
              <li>
                사용가능기간
                <div class="absolute_right">
                  2월 14일 ~ 예산소진 시
                  <span class="else_txt">1인 1주 1만원 한도</span>
                </div>
              </li>
            </ul>
          </div>-->
        </div>

        <div class="contents_unit">
          <div class="payment_header">
            <div class="title">결제수단</div>
          </div>
          <div class="radio_list_wrap" v-if="radioOptions">
            <div class="relative">
              <RadioList type="type1" :options="radioOptions" :checkValue="radioCheckValue" :changeRadio="changeRadio" />
              <!--              <div class="noljang_pay_guide" v-if="radioCheckValue === '4'">
                <div class="guide_bubble">
                  <span v-if="windowInnerWidth > 379">더욱 편리하게~ </span>
                  놀장페이가 새로워졌어요.
                </div>
              </div>-->

              <!--              <div style="position: absolute; bottom: 0; right: 0;">버블 텍스트</div>-->
            </div>
            <div class="guide_txt">
              <span class="icon_card" v-if="radioCheckValue == '3' || radioCheckValue == '4'"></span>
              <span v-html="radioTypeGuideTxt"></span>
            </div>
            <div class="pay_guide_link" style="display: none"><Btn value="놀장페이 사용법" type="type6" @btnCallback="moveToNoljangPayGuide" /></div>
          </div>
        </div>

        <!-- 2023-0516 : 온누리 상품권 구매 방법 변경으로 주석처리 -->
        <!--
        <div class="contents_unit">
          <div class="payment_header">
            <div class="title">온누리 모바일 상품권 구매 방법</div>
            <div class="mt_5">온누리 모바일 상품권 구매 방법</div>
            <Btn type="type4 normal arrow_right" value="온누리모바일상품권 구매 방법 안내" @btnCallback="moveToGiftCertificateGuide" />
            <div class="guide_txt mt_20">상품 관련 교환 및 환불 문의는 배달 완료 후 3시간 이내에 가능합니다.</div>
          </div>
        </div>
        -->

        <div class="payment_detail_wrap contents_unit">
          <ul class="payment_detail_list">
            <li>
              <span class="title"> 총 상품금액 </span>
              <span class="content">
                <span class="price">{{ $formatPrice(totalProductPrice) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>

            <li class="none_flex" v-if="eventPaymentDiscountUseYn == 'Y' && Number(eventDiscount) > 0">
              <div class="relative">
                <div class="title">이벤트 할인</div>
                <div class="content type1 type_absolute">
                  <span class="price"><span v-if="Number(eventDiscount) > 0">-</span> {{ $formatPrice(eventDiscount) }}</span>
                  <span class="price_txt">원</span>
                </div>
              </div>
              <div class="guide mt_10" v-if="eventPaymentDiscountMessage">{{ eventPaymentDiscountMessage }}</div>
            </li>
            <!-- 바로배달 -->
            <li v-if="deliveryType !== 'TB'">
              <span class="title"> 배송비 </span>
              <span class="content">
                <span class="price">{{ shippingFee === deliveryDiscount && shippingFee !== 0 ? "무료배송" : $formatPrice(shippingFee) }}</span>
                <span class="price_txt" v-if="shippingFee !== deliveryDiscount || onSiteYn === 'Y'">원</span>
              </span>
            </li>
            <li
              class="deferred_payment_delivery"
              :class="{ deferred_payment_delivery: deliveryTbType === 'CASH_DELIVERY' || deliveryTbType === 'MIXED_PAYMENT' ? true : false }"
              v-else-if="deliveryType === 'TB'"
            >
              <div class="deferred_payment_delivery_info">
                <span class="title"> 택배비 </span>
                <span class="content" id="paymentPrice">
                  <strong class="deferred_payment" v-if="deliveryTbType === 'CASH_DELIVERY'">착불</strong>
                  <span class="price" v-if="deliveryTbType === 'PREPAYMENT'">
                    {{ shippingFee === deliveryDiscount || shippingFee === 0 ? "무료배송" : $formatPrice(shippingFee) }}
                  </span>
                  <span class="price" v-else-if="deliveryTbType === 'CASH_DELIVERY' && cashDeliveryTB !== null">
                    {{ cashDeliveryTB === deliveryDiscount || cashDeliveryTB === 0 ? "무료배송" : $formatPrice(cashDeliveryTB) }}
                  </span>
                  <span class="price" v-else-if="deliveryTbType === 'MIXED_PAYMENT'">
                    {{ $formatPrice(shippingFee) }}
                  </span>

                  <span class="price_txt">{{ displayWon() }}</span>

                  <!--                  <span class="price_txt" v-else-if="shippingFee !== deliveryDiscount && deliveryTbType !== 'CASH_DELIVERY' && shippingFee !== 0"
                    >원</span
                  >
                  <span class="price_txt" v-else-if="cashDeliveryTB !== deliveryDiscount && deliveryTbType === 'CASH_DELIVERY' && shippingFee !== 0"
                    >원</span
                  >
                  <span class="price_txt" v-else-if="deliveryTbType === 'MIXED_PAYMENT'">원</span>-->
                </span>
              </div>
              <div style="display: block" class="deferred_payment_info" v-if="deliveryTbType !== 'PREPAYMENT'">
                <span v-if="deliveryTbType === 'MIXED_PAYMENT'">착불배송비 제외</span>
                <span v-else-if="deliveryTbType === 'CASH_PAYMENT'">착불수수료 제외</span>
              </div>
            </li>
            <li
              class="none_flex"
              v-if="
                (eventDeliveryDiscountUseYn == 'Y' || eventDeliveryDiscountConditionYn == 'Y') &&
                shippingFee != deliveryDiscount &&
                deliveryType != 'TB' &&
                onSiteYn !== 'Y'
              "
            >
              <!-- && Number(deliveryDiscount) > 0 -->
              <div class="relative" v-if="shippingFee > 0 && Number(deliveryDiscount) > 0">
                <div class="title">배송비 할인</div>
                <div class="content type1 type_absolute">
                  <span class="price"><span v-if="Number(deliveryDiscount) > 0">-</span> {{ $formatPrice(deliveryDiscount) }}</span>
                  <span class="price_txt">원</span>
                </div>
              </div>
              <div class="guide mt_5" v-if="eventDeliveryDiscountConditionYn == 'Y' && eventDeliveryDiscountConditionMessage">
                {{ eventDeliveryDiscountConditionMessage }}
              </div>
            </li>

            <li v-if="useCoupon">
              <span class="title"> 쿠폰 할인 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(useCoupon.couponPrice) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>

            <li v-if="deliveryType != 'TB' && onSiteYn && onSiteYn === 'Y' && tgSaleAmount && tgSaleAmount > 0">
              <span class="title"> 포장주문</span>
              <span class="content">
                <span class="price">- {{ $formatPrice(tgSaleAmount) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>

            <li class="none_flex">
              <!-- style="opacity: 0.4" -->
              <div class="relative">
                <span class="title"> 포인트 사용</span>
                <Btn value="상세" type="type8 ml_10" @btnCallback="moveToPoint" />
                <div class="content type1 type_absolute">
                  <!-- @click="stopPointMessage" -->
                  <!-- stopPointMessage : 포인트 시스템 점검으로 인해 포인트를 사용할 수 없습니다. -->
                  <span class="price">
                    <input
                      type="number"
                      :value="pointPrice > 0 ? '-' + pointPrice : pointPrice"
                      @input="changePoint($event)"
                    /><!-- disabled="true" -->
                  </span>
                  <span class="price_txt ml_3">원</span>
                </div>
                <div class="guide mt_10">사용가능 {{ $formatPrice(usePoint >= 0 ? userPoint - usePoint : userPoint) }}원</div>
              </div>
            </li>

            <li class="active">
              <span class="title"> 결제금액 </span>
              <span class="content">
                <span class="price">{{ $formatPrice(totalPaymentPrice) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
          </ul>
        </div>

        <div class="contents_unit" v-if="!onSiteYn || onSiteYn === 'N'">
          <!-- 방문수령 예외처리 -->
          <div class="payment_address_wrap">
            <span class="header"> 배송지 </span>
            <div class="address_wrap">
              <span>{{ memberName }}</span>
              <span>{{ phoneNumber }}</span>
              <span class="address" v-if="memberAddress">{{ memberAddress.shipToRoad }}</span>

              <div v-if="deliveryType == 'TB'">
                <div class="absolute_right type1"><Btn type="type6" value="배송지 변경" @btnCallback="moveToAddress" /></div>
              </div>
            </div>
          </div>
        </div>

        <div class="contents_unit">
          <div class="payment_address_wrap">
            <span class="header"> {{ !onSiteYn || onSiteYn === "N" ? "수령방법" : "포장주문 안내" }} </span>
            <div class="address_wrap">
              <Btn
                type="type4 normal arrow_right arrow_down"
                :value="recevieTypeActiveText"
                @btnCallback="setRecevieType"
                v-if="!onSiteYn || onSiteYn === 'N'"
              />
              <!-- 방문수령 예외처리 -->
              <div class="mt_20" v-else>
                <p>
                  포장 주문은 <strong>주문 당일 수령을 원칙</strong>으로 합니다. 당일 미수령으로 인해 발생한 상품 문제에 대해서는 점주분이 책임지지
                  않습니다.
                </p>
              </div>
              <span></span>
            </div>
          </div>
        </div>

        <!-- 방문수령 예외처리 -->
        <div class="contents_unit" v-if="!onSiteYn || onSiteYn === 'N'">
          <div class="payment_address_wrap">
            <span class="header"> {{ useDeliveryDesiredDate ? "배송요청사항" : "배달 기사님에게 요청" }} </span>
            <p class="mt_10" v-if="useDeliveryDesiredDate">배송희망일은 11월 6일 ~ 12월 22일 사이 택일 (일요일 제외)</p>
            <div class="address_wrap">
              <input
                id="deliveryRequest"
                class="request_msg_wrap"
                :placeholder="useDeliveryDesiredDate ? '배송희망일을 적어 주세요.' : '배송관련 요청사항을 적어주세요.'"
                :value="deliveryRequest"
                @input="inputDeliveryRequest($event)"
              />
            </div>
            <div v-if="useDeliveryDesiredDate">
              <p style="padding: 0 0 0 18px; margin: 15px 0 0; text-indent: -18px">
                ※ 주문하시는 분과 받으시는 분이 다를 경우 배송요청사항에 배송희망일과 함께 <strong>받으시는 분의 성함과 연락처</strong>를 적어 주세요.
                <span class="block mt_10" style="padding: 0 0 0 18px">(예시) 11월 6일 / 홍길동 / 010-0000-0000</span>
              </p>
            </div>
          </div>
        </div>

        <div class="contents_unit">
          <div class="payment_guide_container">
            <div class="payment_guide">
              <strong class="title">반품/환불/배송 안내</strong>
              <ul class="payment_guide_list">
                <li>
                  냉장/냉동/신선제품은 제품 특성상
                  <strong>고객님의 단순 변심 또는 주관적 입장(상품 불만족 등)으로 인한 반품(환불)은 불가</strong>합니다.
                </li>
                <li>배달상품은 주문과 동시에 픽업이 시작되므로 주문취소가 불가하며, 택배상품은 배송 출고된 경우 취소가 불가합니다.</li>
                <li>
                  상품에 문제가 있을 경우 문제 부분의 사진을 꼭 남기시고, 배달/포장 상품의 반품/환불은
                  <strong>배달완료 또는 상품수령 후 3시간 이내</strong>, 택배상품은 <strong>상품 수령 후 24시간 이내</strong>에
                  고객센터(02-2039-6742)로 연락주세요.<br />
                  (문제 부분 사진이 없거나, 임의 반송 또는 폐기 시 반품/환불 처리가 불가합니다.)
                </li>
                <li>
                  고객의 과실로 고객정보(배송지주소, 연락처 등) 오기나 부재 시 보관장소에 대한 기재 오류로 인한 배송사고에 대해서는 책임지지 않습니다.
                </li>
              </ul>
            </div>

            <p class="mt_20">위 주문 내용을 확인하였으며, 회원 본인은 개인정보 이용 및 제공, 결제에 동의합니다.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute_bottom">
      <Btn type="type5" value="결제하기" @btnCallback="checkWeekendPayment" />
    </div>
    <div class="modal_area" :class="{ show_modal: showModal }" @click="(e) => closeModal(e)">
      <div class="modal_wrap" @click="() => false">
        <div class="modal_header">주말 고객센터 문의</div>
        <div class="modal_content">
          주말 주문에 대한 고객센터는 별도로 운영하고 있습니다.<br />
          고객센터: 010.2462.8960
        </div>
        <div class="modal_button">
          <button class="close" @click="(e) => closeModal(e)">취소</button>
          <button @click="modalClick">확인</button>
        </div>
      </div>
    </div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";
import { mapActions, mapState, mapMutations } from "vuex";
import ProductList from "@/components/new/cart/ProductList";
import Btn from "@/components/new/Btn";
import RadioList from "@/components/list/RadioList";

export default {
  name: "Payment",
  props: {},
  data: function () {
    return {
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      showModal: false,
      //     marketId: null,
      //     notices: null,
      //     isProgressing: false,
    };
  },
  components: {
    TopHeader,
    LoadingIndicator,
    ProductList,
    Btn,
    RadioList,
  },
  computed: {
    ...mapState({
      userId: (state) => state.paymentNew.userId,
      memberName: (state) => state.paymentNew.memberName,
      phoneNumber: (state) => state.paymentNew.phoneNumber,
      memberAddress: (state) => state.paymentNew.memberAddress,
      isProgressing: (state) => state.paymentNew.isProgressing,
      marketInfo: (state) => state.paymentNew.marketInfo,
      orders: (state) => state.paymentNew.orders,
      radioOptions: (state) => state.paymentNew.radioOptions,
      radioCheckValue: (state) => state.paymentNew.radioCheckValue,
      radioTypeGuideTxt: (state) => state.paymentNew.radioTypeGuideTxt,

      onSiteYn: (state) => state.paymentNew.onSiteYn, // 방문수령

      deliveryType: (state) => state.paymentNew.deliveryType, // 배송타입

      coupons: (state) => state.paymentNew.coupons,
      useCoupon: (state) => state.paymentNew.useCoupon,

      totalProductPrice: (state) => state.paymentNew.totalProductPrice,

      eventPaymentDiscountUseYn: (state) => state.paymentNew.eventPaymentDiscountUseYn, // 이벤트 할인
      eventPaymentDiscountMessage: (state) => state.paymentNew.eventPaymentDiscountMessage, // 이벤트 할인 가이드 텍스트

      eventDeliveryDiscountUseYn: (state) => state.paymentNew.eventDeliveryDiscountUseYn, // 배송비 할인
      eventDeliveryDiscountConditionYn: (state) => state.paymentNew.eventDeliveryDiscountConditionYn, // 배송비 할인 가이드 텍스트 사용여부
      eventDeliveryDiscountConditionMessage: (state) => state.paymentNew.eventDeliveryDiscountConditionMessage, // 배송비 할인 가이드 텍스트

      shippingFee: (state) => state.paymentNew.shippingFee,
      cashDeliveryTB: (state) => state.paymentNew.cashDeliveryTB,
      deliveryDiscount: (state) => state.paymentNew.deliveryDiscount,
      eventDiscount: (state) => state.paymentNew.eventDiscount,

      deliveryTbType: (state) => state.paymentNew.deliveryTbType,

      totalPaymentPrice: (state) => state.paymentNew.totalPaymentPrice,
      userPoint: (state) => state.paymentNew.userPoint,
      usePoint: (state) => state.paymentNew.usePoint,
      pointPrice: (state) => state.paymentNew.pointPrice,
      windowInnerWidth: (state) => state.paymentNew.windowInnerWidth,
      // atUseBoolean: (state) => state.paymentNew.atUseBoolean,

      recevieTypeActiveText: (state) => state.paymentNew.recevieTypeActiveText,

      issueCoupon: (state) => state.paymentNew.issueCoupon, // 발행쿠폰
      deliveryRequest: (state) => state.paymentNew.deliveryRequest, // 배달 기사님에게 요청

      paymentWebMode: (state) => state.paymentWebMode,

      discountAtEventContent: (state) => state.paymentNew.discountAtEventContent,

      tgSaleAmount: (state) => state.paymentNew.tgSaleAmount, // 포장 할인가

      useDeliveryDesiredDate: (state) => state.paymentNew.useDeliveryDesiredDate, // 배송희망일 사용 여부
    }),
  },
  methods: {
    stopPointMessage: function () {
      this.$showToast("포인트 시스템 점검으로 인해 포인트를 사용할 수 없습니다.");
    },
    displayWon: function () {
      const deliveryPrice = document.querySelector("#paymentPrice .price");
      // console.log("displayWon ::: ");
      // console.log("displayWon ::: ");
      // console.log("displayWon ::: ");

      if (this.deliveryTbType === "CASH_DELIVERY" && this.cashDeliveryTB === null) {
        // console.log("displayWon ::: 1 ");
        return "";
      }
      // console.log("displayWon ::: 000000 ");
      if (deliveryPrice) {
        // console.log(this.deliveryTbType);
        // console.log(this.cashDeliveryTB);
        // console.log(deliveryPrice);
        // console.log(deliveryPrice.innerHTML.indexOf("무료배송"));

        if (deliveryPrice.innerHTML.indexOf("무료배송") === -1) {
          // console.log("displayWon ::: 2 ");
          return "원";
        }
      }
    },
    ...mapActions(["deleteCartByMarketId"]),
    ...mapMutations("paymentNew", [
      "moveToCoupon",
      "moveToNoljangPayGuide",
      "moveToGiftCertificateGuide",
      "moveToAgreePrivate",
      "moveToPoint",
      "moveToAddress",
    ]),
    ...mapActions("paymentNew", [
      "goBack",
      "changeRadio",
      "payment",
      "changePoint",
      "setRecevieType",
      "issuedCoupon",
      "inputDeliveryRequest",
      "inputProductRequest",
      "focusProductRequest",
    ]),
    /*clickBanner: function (event) {
      console.log(event.currentTarget.dataset);
      if (event.currentTarget.dataset.detailUrl) {
        const eventDataset = event.currentTarget.dataset;
        this.$navigateTo("open-url", {
          title: eventDataset.title,
          url: eventDataset.detailUrl,
        });
      }
    },
    getEventList: function (marketId) {
      console.log(marketId);
      this.isProgressing = true;
      const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;
      this.$axios2
        .get("/v3/notices/member-notices/markets/" + marketId + "?page=0&size=30&userId=" + userId)
        .then((response) => {
          if (response.data.responseCode == 2000) {
            console.log(response.data.result.notices);
            this.notices = response.data.result.notices;
            this.isProgressing = false;
          } else {
            this.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
    },*/
    onBackPressed: function () {
      // console.log("onBackPressed");
      // window.history.back();
      // this.$router.back();
      this.goBack();
    },
    checkWeekendPayment: function () {
      // 논산화지중앙 시장은 주말 결제 시 주말고객센터 문의 팝업을 띄움
      const today = new Date().getDay();
      if (this.marketInfo.marketId === 45 && (today === 0 || today === 6)) {
        this.showModal = true;
      } else {
        this.payment();
      }
    },
    modalClick: function () {
      this.showModal = false;
      this.payment();
    },
    closeModal: function (e) {
      if (e.target.classList.contains("modal_area") || e.target.classList.contains("close")) {
        this.showModal = false;
      }
    },
  },
  filters: {
    replaceContent: function (value) {
      return value ? value.replace("\n", "<br/>") : null;
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.deleteCartByMarketId = this.deleteCartByMarketId;
  },
  beforeCreate() {
    // console.log(this.$route.params);
    var initData = this.$route.params;
    if (initData.marketId) {
      localStorage.setItem("paymentData", JSON.stringify(this.$route.params));
      this.$store.dispatch("paymentNew/initPaymentSetting");
      // this.$store.commit("paymentNew/updateState", { type: "pointPrice", updateData: 0 });
      // this.$store.dispatch("paymentNew/initRecevieType");
    } else {
      initData = JSON.parse(localStorage.getItem("paymentData"));
    }
    // console.log(initData);
    this.$store.dispatch("paymentNew/initState", initData);
  },
  created() {
    // console.log(this.$route.params);
    // var marketId = this.$route.params.marketId;
    // if (!marketId) {
    //   marketId = "1";
    // }
    // this.getEventList(marketId);
  },
};
</script>

<style lang="scss" scoped>
.coupon_item_wrap {
  padding: 5px 0 20px;
}
.coupon_item {
  width: 100%;

  &:first-child {
    margin: 0;
  }
  //height: 130px;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  .coupon_info {
    width: calc(100% - 80px);
    height: 100%;
    padding: 20px;
    background-color: $background-color-type2;
    border-radius: 15px 0 0 15px;
    border: {
      width: 1px 0 1px 1px;
      style: solid;
      color: $border-type2;
    }

    > span {
      display: block;

      &.coupon_price_wrap {
        display: flex;
        align-items: center;
        color: black;

        > .coupon_price {
          @include font(22, 22, normal, bold);
          font-family: gothic_a1_extrabold;
        }

        > .coupon_price_txt {
          @include font(19, 19);
        }
      }

      &.coupon_name {
        margin-top: 6px;
        color: black;
        @include font(16, 22, normal, bold);
        word-break: break-all;
      }

      &.coupon_notice_msg {
        margin-top: 14px;
        @include font(13, 17, -0.52);
        color: $gray;
      }

      &.coupon_notice_detail {
        margin-top: 9px;
        @include font(12, 16, -0.48);
        color: $gray-light;
      }
    }
  }

  .coupon_is_usable {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 0 0 20px;
    width: 80px;
    height: 100%;
    border-radius: 0 15px 15px 0;
    color: white;

    > span {
      display: block;
      @include font(12, 13.5, normal, bold);

      &:last-child {
        margin-top: 4px;
      }
    }

    .coupon_icon {
      display: block;
      width: 26px;
      height: 14px;
      margin: 0 0 7px 0;
      background: {
        image: url("~svg/icon_coupon.svg");
        repeat: no-repeat;
      }
    }
  }

  &.active {
    .coupon_info {
      border: {
        color: #ff1c48;
      }
    }
  }

  &.enable {
    .coupon_is_usable {
      background-color: $nj_red;
    }
  }

  &.disable {
    .coupon_info {
      span {
        color: $gray-light !important;
      }
    }
    .coupon_is_usable {
      background-color: $background-color-type3;
    }
  }
}

.noljang_pay_guide {
  position: absolute;
  bottom: 7px;
  left: 95px;
  z-index: 99;
  .guide_bubble {
    position: relative;
    height: 30px;
    padding: 4px 13px 2px 13px;
    background-color: $active-background-color;
    border-radius: 20px;
    @include font(13, 18, 0);
    color: #fff;
  }
  .guide_bubble:after {
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $active-background-color;
    content: "";
    position: absolute;
    top: 11px;
    left: -3px;
    transform: rotate(45deg);
  }
}

.at_banner_wrap {
  position: relative;
  padding: 10px 0 0;
  background-color: #f1f1f1;
  .at_banner_area {
    padding: 15px 15px 15px 44%;
    background-color: #565656;
    //@include font(18px, 21, -0.72);
    .left_area {
      position: absolute;
      top: 39px;
      left: calc(45% - 115px);
      .area {
        .title {
          line-height: 1;
          font-size: 17px !important;
          letter-spacing: -0.72px;
        }
        //font-weight: 600;
        color: white;
        .gray {
          color: #b1b1b1;
          font-size: 12px !important;
          letter-spacing: -0.7px;
        }
        .accent {
          margin: -3px 0 0;
          color: #ff771d;
        }
      }
    }

    .right_area {
      //width: 176px;
      max-width: 176px;
      height: 87px;
      padding: 7px 0 0;
      //background-color: #ff771d;
      background-image: url("~svg/at_banner_bg_01.svg");
      background-repeat: no-repeat;
      background-size: cover;
      //background-position: ;
      text-align: center;
      .txt1 {
        font-size: 10px;
        font-weight: bold;
        color: #552c10;
      }
      .txt2 {
        line-height: 1;
        font-size: 22px;
        font-weight: bold;
        font-family: "gothic_a1_extrabold";
        color: white;

        > span {
          font-family: "NanumSquareR";
          font-size: 15px;
          font-weight: 100;
        }
      }
      .txt3 {
        line-height: 1.3;
        margin: 0 3px;
        padding: 3px 0 0;
        font-size: 9px;
        border: {
          style: dashed;
          width: 1px 0 0;
          color: #565656;
        }
        color: white;
        letter-spacing: -0.9px;
      }
    }
  }
  @media (min-width: 320px) {
    .at_banner_area {
      padding: 15px 15px 15px 40%;
      .left_area {
        left: calc(40% - 115px);
        .area {
          .title {
            font-size: 18px !important;
          }
        }
      }
      .right_area {
        padding: 10px 0 0;
        .txt2 {
          margin: 3px 0 0;
        }
        .txt3 {
          padding: 4px 0 0;
          margin: 5px 3px 0;
          border-width: 2px 0 0;
        }
      }
    }
  }
  @media (min-width: 380px) {
    .at_banner_area {
      padding: 15px 15px 15px 45%;
      .left_area {
        left: calc(45% - 130px);
      }
      .right_area {
        .txt3 {
          padding: 4px 0 0;
        }
      }
    }
  }

  .at_banner {
    padding: 20px 15px 25px;
    background-color: #845ba8;
    font-size: 14px;
    color: #fff;
    .title {
      line-height: 1.3px;
      font-size: 18px;
    }
    .at_banner_list {
      margin: 15px 0 0;
      font-size: 14px;
      .price {
        font-size: 16px;
        letter-spacing: 1px;
      }
      li {
        position: relative;
        min-height: 37px;
        .absolute_right {
          text-align: right;
        }
      }
      .else_txt {
        display: block;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 600;
        color: #2b1440;
      }
    }
  }
}

/*.coupon_price {
  display: inline-block;
  width: 170px;
  height: 35px;
  padding: 5px 10px 0;
  border: {
    style: solid;
    width: 1px;
    color: #dedede;
    radius: 5px;
  }
  @include font(16, 22, normal, bold);
  text-align: right;
  vertical-align: middle;
}*/
.contents_unit {
  padding: 10px 0 0;
  background-color: #f1f1f1;
}

.absolute_bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 75px;
  padding: 10px 15px;
  border-style: solid;
  border-color: $gray-light;
  border-width: 1px 0 0;
  background-color: #fff;
  z-index: 99;
}
.contents {
  padding-top: 58px;
}
.contents_area {
  padding: 0 0 75px;
}

.payment_guide_container {
  position: relative;
  padding: 20px 16px;
  background-color: #fff;
  .title {
    font: {
      size: 15px;
    }
  }
  .payment_guide {
    padding: 15px;
    background-color: #f5f5f5;
  }
  .payment_guide_list {
    margin-top: 10px;
    > li {
      position: relative;
      padding: 0 0 0 10px;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 11px;
        left: 0px;
        width: 2px;
        height: 2px;
        background-color: #333;
      }
    }
  }
  p {
    font: {
      size: 14px;
      weight: bold;
    }
  }
}

.payment_address_wrap {
  position: relative;
  padding: 20px 16px;
  background-color: #fff;

  .header {
    @include font(16, 18, -0.64, bold);
  }

  .absolute_right {
    &.type1 {
      top: 17px;
      right: 15px;
    }
  }
  .address_wrap {
    &.txt_bold {
      margin: 10px 0 0;
      font-weight: bold;
    }
    > span {
      display: block;
      margin-top: 10px;
      @include font(14, 14, normal, bold);

      &.address {
        @include font(14, 20, normal, bold);
      }

      &:first-child {
        margin-top: 20px;
      }
    }

    .delivery_info_wrap {
      display: flex;
      flex-direction: column;
      margin: 20px 0 0;

      > span {
        @include font(14, 18, normal, bold);

        &.title {
          color: $gray-normal;
        }

        &.receive_method {
          margin: 5px 0 0;
        }
      }
    }
  }
}
.request_msg_wrap {
  width: 100%;
  margin: 10px 0 0;
  padding: 12px 20px;
  border: {
    width: 1px;
    style: solid;
    color: $border-type2;
  }
  @include border-radius(10px);
  @include font(14, 18);
  //color: $gray-normal;
  resize: none;

  &:focus {
    outline: none;
  }
}

.payment_detail_wrap {
  .payment_detail_list {
    padding: 0 16px;
    border: {
      width: 1px 0;
      style: solid;
      color: $border-type2;
    }
    //background-color: $background-color-type2;
    background-color: #f9f9f9;
    align-items: center;

    > li.deferred_payment_delivery {
      margin-bottom: 10px;
      > .deferred_payment_info {
        position: relative;
        top: -15px;
        text-align: end;
        @include font(12, 12, 0);
        //@include font(12, 12, 0, medium);
        color: $active-color;
      }
    }
    > li:not(.deferred_payment_delivery),
    .deferred_payment_delivery_info {
      display: flex;
      height: 16px;
      margin: 0 0 20px 0;
      align-items: center;

      &.none_flex {
        display: block;
        height: auto;
      }

      &:first-child:not(.deferred_payment_delivery_info) {
        height: 46px;
        padding: 30px 0 0 0;
      }

      &.active {
        height: 62px;
        padding: 20px 0;
        margin: 0;
        border: {
          width: 1px 0 0 0;
          style: solid;
          color: $border-type2;
        }

        .title {
          @include font(16, normal, -0.64, bold);
          color: $active-color;
        }

        .content {
          height: 22px;
          .price {
            height: 22px;
            font-family: gothic_a1_bold;
            font-size: 22px;
          }

          .price_txt {
            @include font(19);
            position: relative;
            top: -2px;
          }
        }
      }

      span {
        height: 16px;
      }

      .title {
        @include font(14, normal, normal, bold);
        &.type1 {
          padding: 7px 0 0;
        }
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.type1 {
          display: block;
          position: relative;
          height: 63px;
          padding: 0 0 30px;
          //background-color: #f00;
          //.title {
          //  height: 60px;
          //}

          //.use_point { // 삭제예정
          //  position: absolute;
          //  bottom: 0;
          //  right: 0;
          //}

          input {
            width: 120px;
            height: 33px;
            padding: 0 10px;
            border: {
              style: solid;
              width: 1px;
              color: #dcdcdc;
              radius: 10px;
            }
            @include font(15, normal, normal, bold);
            text-align: right;
          }
        }
        &.type_absolute {
          position: absolute;
          top: -6px;
          right: 0;
        }

        .price {
          font-family: gothic_a1_bold;
          font-size: 16px;
          line-height: 1;
          &.accent {
            color: $active-color;
          }
        }

        .price_txt {
          @include font(15);
        }

        .deferred_payment {
          display: inline-block;
          padding: 1px 7px 0px;
          margin: -2px 15px 0 0;
          background-color: $active-color;
          @include border-radius(6px);
          @include font(13, 20);
          color: white;
        }
      }
    }
  }
}
.guide {
  @include font(14, 20, -0.3);
  color: $active-color;
  text-align: right;
}
.guide_area {
  .active_color {
    color: $nj_red;
  }
}
.guide_txt {
  min-height: 30px;
  padding: 7px 7px 5px;
  margin: 20px 0 0;
  background-color: #eee;
  border-radius: 15px;
  @include font(12, 18, -0.3);
  color: #777;
  text-align: center;
  .icon_card {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url("~svg/icon_card.svg");
    background-position: center;
    vertical-align: middle;
  }
}

.radio_list_wrap {
  position: relative;
  padding: 10px 15px 20px;
  background-color: #fff;

  .pay_guide_link {
    position: absolute;
    bottom: 80px;
    right: 15px;
  }
}
.coupon {
  padding: 0 15px;
  @include font(13, 20, -0.3);
  //text-align: center;
}
.payment_header {
  position: relative;
  padding: 15px;
  background-color: #fff;
  border: {
    //width: 0 0 1px 0;
    width: 0;
    style: solid;
    color: $border-type2;
  }
  .title {
    @include font(16, 20, -0.3, medium);
  }
  .absolute_right {
    top: 13px;
    right: 15px;
    font-weight: bold;
    &.type1 {
      top: 10px;
    }
    .accent_txt {
      color: $active-color;
      font-weight: 600;
    }
    .normal_txt {
      font-weight: normal;
    }
  }
}
.header_txt {
  height: 61px;
  padding: 20px 0 0;
  background-color: #f1f1f1;
  //border: {
  //  width: 0 0 1px 0;
  //  style: solid;
  //  color: $border-type2;
  //}
  @include font(13, 18, -0.5, medium);
  color: #333;
  text-align: center;
}

.list_wrap {
  background-color: #fff;
  .list::v-deep {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border: {
      width: 0 0 1px 0;
      style: solid;
      color: $border-type2;
    }

    &:last-child {
      border: none;
    }

    .cart_product_container:last-child {
      .product_wrap {
        border: none;
      }
    }
  }
}

.modal_area {
  position: fixed;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  top: 5%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: 0.2s ease-in-out;

  &.show_modal {
    visibility: visible;
    top: 0;
    opacity: 1;
  }

  .modal_wrap {
    padding: 20px 15px;
    border-top: 5px solid $nj_red;
    border-radius: 5px;
    background-color: white;
    text-align: center;

    .modal_header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .modal_content {
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .modal_button button {
      padding: 5px 20px;
      border: none;
      border-radius: 10px;
      color: white;
      background-color: $nj_red;

      &.close {
        background-color: #bcbcbc;
        margin-right: 10px;
      }
    }
  }
}
</style>
