const state = {
  marketId: null,
  products: [],
  receiveWay: "",
  deliveryRequest: "",
  deliveryAddr: {},
  displayLayer: false,
  displayAlertLayer: false,
  alertText: "판매 종료된 상품이 있습니다. <br>해당 상품을 삭제해주세요.",
  curProductCompoundKey: null, // 현재 삭제하려는 상품
};

const mutations = {
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  showHideAlertLayer: function (state, msg) {
    if (msg) {
      state.alertText = msg;
    }
    state.displayAlertLayer = !state.displayAlertLayer;
  },
  deleteProduct: function (state) {
    const compoundKey = state.curProductCompoundKey;
    state.products = state.products.filter((product) => product.compoundKey != compoundKey);
    this._vm.$callNative("deleteProduct", {
      compoundKey: compoundKey,
      marketId: state.marketId,
    });
  },
  addProductQnt: function (state, compoundKey) {
    const product = state.products.find((product) => product.compoundKey == compoundKey);
    product.quantity++;
    this._vm.$callNative("updateQuantity", {
      compoundKey: product.compoundKey,
      marketId: state.marketId,
      quantity: product.quantity,
    });
  },
  delProductQnt: function (state, compoundKey) {
    const product = state.products.find((product) => product.compoundKey == compoundKey);
    if (product.quantity > 1) {
      product.quantity--;
      this._vm.$callNative("updateQuantity", {
        compoundKey: product.compoundKey,
        marketId: state.marketId,
        quantity: product.quantity,
      });
    }
  },
  setProductRequest: function (state, { compoundKey, input }) {
    const product = state.products.find((product) => product.compoundKey == compoundKey);
    product.productRequest = input;
  },
  goPayment: function (state, { getters }) {
    let data = null;
    if (this._vm.$isAndroid()) {
      data = getters.moveToPaymentData;
    }
    this._vm.$callNative("moveToPayment", data);
  },
  goLink: function (state, data) {
    if (data.type == "STORE") {
      this._vm.$callNative("moveToStore", {
        marketId: data.marketId,
        storeId: data.storeId,
      });
    } else if (data.type == "PRODUCT") {
      this._vm.$callNative("moveToProduct", {
        productId: data.productId,
      });
    }
  },
  goBack: function () {
    this._vm.$closeView();
  },
};

const actions = {
  getProducts: async function (store, cartData) {
    try {
      let productIds = [];
      cartData.products.forEach((product) => {
        product.productId = parseInt(product.productId);
        productIds.push(product.productId);
      });

      const productResult = await this._vm.$axiosLegacy.get("product/products/information?productIds=" + productIds.join());
      const products = productResult.data.result.products;

      store.commit("updateState", {
        updateData: cartData.marketId,
        type: "marketId",
      });

      cartData.products.forEach((product, index) => {
        // 상품 정보 매핑
        for (let i = 0; i < products.length; i++) {
          const data = JSON.parse(JSON.stringify(products[i]));
          if (product.productId == data.productId) {
            product.marketName = data.marketName;
            product.storeName = data.storeName;
            product.storeId = data.storeId;
            product.name = data.productName;
            product.price = data.price;
            product.discountPrice = data.discountPrice;
            product.paymentTypes = data.paymentTypeList;
            product.productImageUrl = data.imgUrl;
            product.originName = data.originName;
            product.unitNumber = data.unitNumber;
            product.unitName = data.unitName;
            product.optionGroups = data.optionGroups;
            product.saleYn = data.saleYn;
          }
        }

        if (product.option) {
          product.optionGroups = convertProduct(product).optionGroups;
          cartData.products[index] = product;
        }

        if (product.optionGroups) {
          product.realPrice = product.price;
          product.realDiscountPrice = product.discountPrice ? product.discountPrice : null;

          product.optionGroups.forEach((optionGroup) => {
            optionGroup.options.forEach((option) => {
              product.realPrice += option.optionPrice;
              if (product.realDiscountPrice) {
                product.realDiscountPrice += option.optionPrice;
              }
            });
          });
        }
      });

      await store.commit("updateState", {
        updateData: cartData.products,
        type: "products",
      });
    } catch (e) {
      alert(e);
    }
  },
  getDeliveryAddress: async function (store, userId) {
    try {
      const deliveryAddrResult = await this._vm.$axios1.get("/v3/user/member/address/" + userId + "/ship-to");
      const deliveryAddr = deliveryAddrResult.data.result;
      let delvAddr = {
        shipToDoro: deliveryAddr.shipToRoad ? deliveryAddr.shipToRoad : deliveryAddr.shipToJibun,
        shipToDetail: deliveryAddr.shipToDetail,
      };

      store.commit("updateState", {
        updateData: delvAddr,
        type: "deliveryAddr",
      });
    } catch (e) {
      alert(e);
    }
  },
  setDeliveryAddress: async function (store, deliveryAddr) {
    store.commit("updateState", {
      updateData: deliveryAddr,
      type: "deliveryAddr",
    });
  },
  deleteProduct: function (store) {
    store.commit("deleteProduct");
    store.dispatch("hideLayer");
  },
  addProductQnt: function (store, compoundKey) {
    store.commit("addProductQnt", compoundKey);
  },
  delProductQnt: function (store, compoundKey) {
    store.commit("delProductQnt", compoundKey);
  },
  setProductRequest: function (store, { compoundKey, input }) {
    store.commit("setProductRequest", { compoundKey, input });
  },
  setReceiveWay: function (store, receiveWay) {
    store.commit("updateState", {
      updateData: receiveWay,
      type: "receiveWay",
    });
  },
  setDeliveryRequest: function (store, deliveryRequest) {
    store.commit("updateState", {
      updateData: deliveryRequest,
      type: "deliveryRequest",
    });
  },
  goPayment: function ({ commit, getters }) {
    commit("goPayment", { getters });
  },
  goLink: function (store, data) {
    store.commit("goLink", data);
  },
  showLayer: function (store, compoundKey) {
    store.commit("updateState", {
      type: "curProductCompoundKey",
      updateData: compoundKey,
    });
    store.commit("updateState", {
      type: "displayLayer",
      updateData: true,
    });
  },
  hideLayer: function (store) {
    store.commit("updateState", {
      type: "curProductCompoundKey",
      updateData: null,
    });
    store.commit("updateState", {
      type: "displayLayer",
      updateData: false,
    });
  },
  goBack: function (store) {
    store.commit("goBack");
  },
};

const getters = {
  productList: (state) => {
    return state.products;
  },
  saleProduct: function () {
    return state.products.filter((product) => {
      return product.saleYn == "Y";
    });
  },
  moveToPaymentData: function () {
    let returnData = {};

    returnData = {
      marketId: state.marketId,
      receiveWay: state.receiveWay,
      deliveryRequest: state.deliveryRequest,
      productRequests: [],
    };

    // iOS의 경우, 해당 정보를 QueryString으로 넘겨받을 수 없어, 해당 함수를 호출하여 전달 받음 (cartMarket.vue 참고)
    state.products.forEach((item) => {
      returnData.productRequests.push({
        compoundKey: item.compoundKey,
        productRequest: item.productRequest ? item.productRequest : "",
      });
    });

    return returnData;
  },
};

const convertProduct = (product) => {
  // 상품 옵션 매핑
  const optionGroupIdxArr = [];

  // 옵션그룹 ID 리스트로 변환
  product.option.forEach((optionGroup, index) => {
    if (optionGroup) {
      optionGroupIdxArr.push(index);
    }
  });

  // 옵션그룹 ID에 포함되지 않는 옵션 그룹 필터링
  let optionGroups = filterOptionGroup(product, optionGroupIdxArr);
  optionGroups.forEach((optionGroup, index) => {
    const options = convertOption(optionGroup, product.option[optionGroup.optionGroupId]);
    // console.log(options);
    optionGroups[index].options = options;
  });
  product.optionGroups = optionGroups;
  return product;
};

const filterOptionGroup = (product, optionGroupIdxArr) => {
  return product.optionGroups.filter((optionGroup) => {
    if (optionGroupIdxArr.includes(optionGroup.optionGroupId)) {
      return optionGroup;
    }
  });
};

const convertOption = (optionGroup, optionIdArr) => {
  let options = [];
  optionGroup.optionItems.forEach((option) => {
    if (optionIdArr.includes(option.optionItemId)) {
      options.push({
        optionId: option.optionItemId,
        optionName: option.name,
        optionPrice: option.optionPrice,
      });
    }
  });

  return options;
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
