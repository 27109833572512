var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TopHeader',{attrs:{"title":"보유쿠폰","theme":"nj_white","isButton":true},on:{"goBack":_vm.goBack}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"coupon_input_wrap"},[_c('NJInput',{attrs:{"placeholder":"쿠폰 일련번호를 입력해주세요.","value":_vm.couponNumber},on:{"input":function($event){return _vm.setCouponNumber($event)}}}),_c('span',{staticClass:"add_btn",on:{"click":function($event){return _vm.addCoupon({ userId: _vm.userId, couponNumber: _vm.couponNumber })}}},[_vm._v("등록")])],1),_c('CouponList',{attrs:{"coupons":_vm.coupons,"isSelectable":_vm.isSelectable}})],1),_c('Alert',{attrs:{"displayLayer":_vm.displayAlertLayer,"texts":['쿠폰 일련번호를 입력해주세요.'],"textClass":[],"btns":[
      {
        type: null,
        btnId: '',
        btnData: {},
        value: '확인',
      },
    ],"showHideLayer":_vm.showHideAlertLayer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }