<template>
  <div class="msg">
    <p v-html="text" v-if="!type" />
    <p v-else-if="type === 'LINK'">
      <a href="javascript:void(0)" @click="callback">
        <span v-html="text" />
        <span class="add_text" v-if="addText"
          ><span>{{ addText }}</span></span
        >
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Msg",
  props: {
    type: String,
    text: String,
    addText: String,
    callback: Function, // 사용안함 - 2022-05-23
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.msg {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  z-index: 100000;
  p {
    display: inline-block;
    line-height: 1.6;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.5);
    @include border-radius(20px);
    font-size: 14px;
    color: white;
    text-align: center;
  }
  .add_text {
    display: block;
    margin: 5px 0 0;
    > span {
      display: inline-block;
      border: {
        style: solid;
        width: 0 0 1px;
        color: white;
      }
    }
  }
}
</style>
