<template>
  <div class="wrap">
    <LoadingIndicator v-if="isProgressing" />
    <!--    Top Header 영역 -->
    <TopHeader @goBack="goBack" :title="title" theme="nj_gray"></TopHeader>
    <StoreProductPanel />
  </div>
</template>

<script>
import StoreProductPanel from "./components/StoreProductPanel";
import TopHeader from "../../components/TopHeader";
import { mapActions, mapGetters, mapState } from "vuex";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "DeliveryStore",
  props: {},
  data: function () {
    return {};
  },
  components: {
    LoadingIndicator,
    StoreProductPanel,
    TopHeader,
  },
  methods: {
    ...mapActions("deliveryStore", ["goBack", "initState", "setStoreDetailShow"]),
  },
  beforeCreate: function () {
    this.$store.dispatch("deliveryStore/initState", {
      marketId: this.$route.query.marketId,
      storeId: this.$route.query.storeId,
      userId: this.$route.query.userId,
    });
  },
  computed: {
    ...mapState({
      isStoreDetailShow: (state) => state.deliveryStore.isStoreDetailShow,
      isProgressing: (state) => state.deliveryStore.isProgressing,
    }),
    ...mapGetters({
      title: "deliveryStore/title",
    }),
  },
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background: #e2e2e2;
}
</style>
