// import axios from "axios";

const state = {
  userId: null,
  customerMarginTop: "",
  markets: [],
};

const mutations = {
  initState: function (state, data) {
    state["markets"] = data.updateData.markets;
  },
  goBack: function (state, vueContainer) {
    // this.commit('cartShip/testFunc');// 테스트 코드
    vueContainer.$closeView();
  },
  deleteProduct: function (state, { marketId, compoundKey }) {
    const market = state.markets.find((market) => market.marketId == marketId);
    const deleteProduct = market.products.find((product) => {
      if (product.compoundKey == compoundKey) {
        return product;
      }
    });

    const sendData = {
      compoundKey: deleteProduct.compoundKey,
      marketId: market.marketId,
    };

    this._vm.$callNative("deleteProduct", sendData);

    const product = market.products.filter((product) => product.compoundKey != deleteProduct.compoundKey);
    if (product.length > 0) {
      market.products = product;
    } else {
      const newMarket = state.markets.filter((market) => market.marketId != marketId);
      state.markets = newMarket;
    }
  },
  expandMarket: async function (state, event) {
    var targetId = event.currentTarget.dataset.marketId;

    var markets = await state.markets.map((market) => {
      if (Number(targetId) == market.marketId) {
        if (market.expand) {
          state.customerMarginTop = "-" + String(event.currentTarget.closest(".wrap").offsetHeight - 58) + "px";
          market.expand = false;
        } else {
          market.expand = true;
        }
      }
      return market;
    });

    state.markets = markets;

    // console.log(state.markets);
    // await state.markets.map(market => {
    //     if (Number(targetId) == market.marketId) {
    //         if (market.expand) {
    //             state.customerMarginTop = '-' + String(event.currentTarget.closest('.wrap').offsetHeight - 58 ) + 'px';
    //         }
    //         market.expand = !market.expand;
    //         console.log(market);
    //     }
    // });
    // 아래소스는 오차가 있음
    // state.customerMarginTop = await ('-' + String(event.currentTarget.closest('.wrap').offsetHeight - 58 ) + 'px');

    // const market = state.markets.find(market => market.marketId == targetId);
    // market.expand = !market.expand;
    // console.log('market.expand ::: ' + market.expand);
  },
  updateQuantity: function (state, { compoundKey, marketId, $event }) {
    const market = state.markets.find((market) => market.marketId == marketId);
    const product = market.products.find((product) => product.compoundKey == compoundKey);
    if ($event.currentTarget.classList.contains("btn_minus")) {
      // 감소
      product.quantity > 1 ? product.quantity-- : null;
    } else {
      // 증가
      product.quantity++;
    }
    const sendData = {
      marketId: market.marketId,
      compoundKey: product.compoundKey,
      quantity: product.quantity,
    };
    this._vm.$callNative("updateQuantity", sendData);
  },
  paymentMarket: function (state, marketId) {
    // 결제하기
    // const market = state.markets.find(market => market.marketId == marketId);
    // console.log('paymentMarket :::::::::::::::::::::::');
    // console.log(market);
    // const paymentProduct = market.products.map(product => {
    //     return product.compoundKey;
    // });

    this._vm.$callNative("moveToMarketCart", { marketId: marketId });
  },
};

const actions = {
  initState: async function (store, data) {
    var cartData = data.cart;

    // '2352!0@1,2,3,4#1@1,2#1@1,2#1@1,2#1@1,2'.split('!')[0]
    // var marketArr = [];

    var productIds = "";
    cartData.map((market) => {
      market.products.map((product) => {
        product.productId = Number(product.compoundKey.split("!")[0]);
        if (productIds == "") {
          productIds = product.productId;
        } else {
          productIds = productIds + "," + product.productId;
        }
      });
    });
    // console.log('productIds');
    // console.log(productIds);

    const axiosUrl = "http://13.209.246.38:8080/product/products/information?productIds=" + productIds;
    await this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        if (response.data.responseCode == 2000) {
          var products = response.data.result.products;
          var productLength = products.length;
          // console.log('----------------------------------------');
          // console.log(products);

          // var marketCount = 0;
          cartData.map(async (market) => {
            market.products.map((product) => {
              for (var i = 0; i < productLength; i++) {
                if (product.productId == products[i].productId) {
                  // console.log('products ::::::::::::::::::::::::::::::::::::::::');
                  // console.log(products[i]);

                  market.marketName = products[i].marketName;
                  market.expand = true;

                  product.storeName = products[i].storeName;
                  product.storeId = products[i].storeId;
                  product.name = products[i].productName;
                  product.price = products[i].price;
                  product.discountPrice = products[i].discountPrice;
                  product.paymentTypes = products[i].paymentTypeList;
                  product.imgUrl = products[i].imgUrl;
                  product.originName = products[i].originName;
                  product.unitNumber = products[i].unitNumber;
                  product.unitName = products[i].unitName;

                  // 상품 옵션 정의
                  if (products[i].optionGroups.length > 0) {
                    const productOptionGroups = products[i].optionGroups;

                    var optionGroups = [];
                    var optGops = product.compoundKey.split("!")[1].split("#");
                    // console.log(optGops);

                    optGops.map(async (item) => {
                      var opt = item.split("@");
                      await optionGroups.push({
                        optionGroupId: Number(opt[0]), // 그룹 아이디와 분리
                        options: opt[1].split(",").map((optId) => {
                          return {
                            optionId: Number(optId),
                          };
                        }),
                      });
                    });
                    // console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
                    // console.log(optionGroups);
                    // console.log(productOptionGroups);
                    var totalOptionPrice = 0;
                    var optionGroupLength = productOptionGroups.length;
                    optionGroups.map(async (item) => {
                      for (var k = 0; k < optionGroupLength; k++) {
                        if (item.optionGroupId == productOptionGroups[k].optionGroupId) {
                          // console.log('item.optionGroupId :::::::::::::::::::::::::::::::::::::');
                          // console.log(item.optionGroupId);
                          // console.log(productOptionGroups[k].optionGroupId);
                          await item.options.map((option) => {
                            productOptionGroups[k].optionItems.map((optItem) => {
                              // console.log('optItem ~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
                              // console.log(option);
                              // console.log(optItem);
                              if (option.optionId == optItem.optionItemId) {
                                option.optionName = optItem.name;
                                option.optionPrice = optItem.optionPrice;

                                totalOptionPrice = totalOptionPrice + optItem.optionPrice;
                              }
                            });
                          });
                        }
                      }
                    });
                    // console.log(optionGroups);
                    product.optionGroups = optionGroups;
                    product.totalOptionPrice = totalOptionPrice;
                  }
                }
              }
            });
          });

          this._vm.isProgressing = false;

          // console.log("cartData :::::::::::::::::::::::: ");
          // console.log(cartData);
          store.commit("initState", {
            updateData: {
              markets: cartData,
            },
          });
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });

    // const updateData = {
    //     markets: [
    //         {
    //             marketId: 1,
    //             marketName: '시장 A111',
    //             expand: true,
    //             products: [
    //                 {
    //                     compoundKey: '1',
    //                     productId: 1,
    //                     name: '돼지고기',
    //                     quantity: 10,
    //                     storeName: 'A 상점',
    //                     price: 37900,
    //                     discountPrice: 9100,
    //                     paymentTypes: ['온누리', '제로배달', '제로배달'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     isDisabled: false,
    //                     requestInput: false,
    //                     optionGroups: [
    //                         {
    //                             optionGroupId: 0,
    //                             optionGroupName: '기본',
    //                             requiredYn: 'Y',
    //                             selectCount: 0,
    //                             options: [
    //                                 {
    //                                     optionId: 0,
    //                                     optionName: '양념추가',
    //                                     optionPrice: -3000
    //                                 }
    //                             ],
    //
    //                         },
    //                         {
    //                             optionGroupId: 1,
    //                             optionGroupName: '음료',
    //                             requiredYn: 'Y',
    //                             selectCount: 0,
    //                             options: [
    //                                 {
    //                                     optionId: 1,
    //                                     optionName: '벌꿀칼집',
    //                                     optionPrice: 2000
    //                                 }
    //                             ],
    //                         }
    //                     ],
    //                 },
    //                 {
    //                     compoundKey: '2',
    //                     productId: 2,
    //                     name: '돼지고기 2',
    //                     quantity: 13,
    //                     storeName: 'B 상점',
    //                     price: 37900,
    //                     paymentTypes: ['온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: false,
    //                     requestInput: false,
    //                     options: []
    //                 },
    //             ],
    //         },
    //         {
    //             marketId: 10,
    //             marketName: '시장 B',
    //             expand: true,
    //             products: [
    //                 {
    //                     compoundKey: '3',
    //                     productId: 11,
    //                     name: '돼지고기 돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기',
    //                     quantity: 5,
    //                     storeName: 'A 상점',
    //                     price: 27000,
    //                     discountPrice: 9100,
    //                     paymentTypes: ['온누리', '온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: false,
    //                     requestInput: false
    //                 },
    //             ],
    //         },
    //         {
    //             marketId: 20,
    //             marketName: '시장 C',
    //             expand: true,
    //             products: [
    //                 {
    //                     compoundKey: '4',
    //                     productId: 21,
    //                     name: '돼지고기 돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기돼지고기',
    //                     quantity: 5,
    //                     storeName: 'A 상점',
    //                     price: 27000,
    //                     paymentTypes: ['온누리', '온누리'],
    //                     imgUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_38207.png',
    //                     originName: '국내산',
    //                     unitName: 'g',
    //                     unitNumber: 100,
    //                     isDisabled: true,
    //                     requestInput: true
    //                 },
    //             ],
    //         }
    //     ]
    // };
    //
    // store.commit('initState', {
    //     updateData: {
    //         markets: updateData.markets
    //     }
    // });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
  updateQuantity: function (store, { compoundKey, marketId, $event }) {
    store.commit("updateQuantity", { compoundKey, marketId, $event });
  },
  deleteProduct: function (store, data) {
    store.commit("deleteProduct", data);
  },
  // addProductQnt: function (store, {compoundKey, marketId, $event}) {
  //     store.commit('addProductQnt', {compoundKey, marketId, $event});
  // },
  // // delProductQnt: function (store, {compoundKey, marketId, event}) {
  // delProductQnt: function (store, {compoundKey, marketId, $event}) {
  //     console.log($event);
  //     store.commit('delProductQnt', {compoundKey, marketId, $event});
  // },
  paymentMarket: function (store, marketId) {
    store.commit("paymentMarket", marketId);
  },
};

const getters = {
  title(state) {
    return state.title;
  },
  markets(state) {
    return state.markets;
  },
  // count(state, getters){
  //     return Math.pow(state.count, getters.weight);
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
