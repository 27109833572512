<template>
  <div class="container">
    <div class="header">
      <a href="javascript:void(0)" @click="goBack">
        <img class="icon_x" src="~svg/icon_x.svg" alt="뒤로가기" />
      </a>
      <span>상품도착 희망일 선택</span>
    </div>
    <ul class="calendar_wrap">
      <li class="calendar" v-for="calendar in calenderList" :key="`${calendar.year}-${calendar.month}`">
        <div class="calendar_title">{{ calendar.year }}년 {{ calendar.month }}월</div>
        <ul class="day_list_wrap">
          <li class="day" :class="{ sunday: day === '일', saturday: day === '토' }" v-for="day in dayList" :key="day">
            {{ day }}
          </li>
        </ul>
        <ul>
          <li v-for="(week, weekIndex) in dateList(calendar.year, calendar.month)" :key="`week_${weekIndex + 1}`">
            <ul class="date_list_wrap">
              <li class="date" v-for="(date, dateIndex) in week" :key="dateIndex">
                <a
                  href="javascript:void(0)"
                  :class="{
                    sunday: getDay(calendar.year, calendar.month, date) === 0,
                    holiday: isHoliday(calendar.year, calendar.month, date),
                    saturday: getDay(calendar.year, calendar.month, date) === 6,
                    disabled: isDisabled(calendar.year, calendar.month, date),
                    today: isToday(calendar.year, calendar.month, date),
                    available: !isDisabled(calendar.year, calendar.month, date) && ![0, 6].includes(getDay(calendar.year, calendar.month, date)),
                    active: isActive(calendar.year, calendar.month, date),
                  }"
                  @click="calendarCallback(calendar.year, calendar.month, date)"
                  >{{ getDateFormat(date) }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "Calendar",
  props: {},
  data: function () {
    return {
      holidayList: [], // ["2022-11-03"]
      dayList: ["일", "월", "화", "수", "목", "금", "토"],
      calenderList: [
        {
          year: "2022",
          month: "11",
        },
        {
          year: "2022",
          month: "12",
        },
      ],
      startDt: "1970-01-01", // default
      endDt: "2050-12-31", // default
    };
  },
  computed: {
    ...mapState({
      expectedArrivalTime: (state) => state.paymentNew.expectedArrivalTime,
    }),
    dateList: function () {
      return (year, month) => {
        return this.getDateList(year, month);
      };
    },
  },
  methods: {
    ...mapMutations("paymentNew", ["updateState"]),
    goBack: function () {
      this.$router.go(-1);
    },
    getDateList: function (year, month) {
      const firstDate = 1;
      const firstWeek = 1;
      const firstDay = this.getDay(year, month, firstDate); // 일~토 : 0~6
      const lastDate = this.getMonthLastDate(year, month);
      const lastWeek = this.getWeek(firstDay, lastDate);
      const dateList = [];
      for (let week = firstWeek; week <= lastWeek; week++) {
        dateList.push(new Array(7).fill(null));
      }
      for (let date = firstDate; date <= lastDate; date++) {
        const week = this.getWeek(firstDay, date);
        const day = this.getDay(year, month, date);
        dateList[week - 1][day] = String(date).padStart(2, "0");
      }
      return dateList;
    },
    getDay: function (year, month, date) {
      return new Date(`${year}-${month}-${date}`).getDay();
    },
    getMonthLastDate: function (year, month) {
      return new Date(new Date(new Date(`${year}-${month}-01`).setMonth(month)).setDate(0)).getDate();
    },
    getWeek: function (firstDay, date) {
      return Math.trunc((firstDay + date - 1) / 7) + 1;
    },
    isHoliday: function (year, month, date) {
      // 음력 공휴일 때문에 year 포함 했습니다.
      return (
        this.holidayList.includes(`${year}-${month}-${date}`) ||
        this.holidayList.includes(`${year}-${month}-${this.getDateFormat(date)}`) ||
        this.holidayList.includes(`${year}-${this.getDateFormat(month)}-${date}`) ||
        this.holidayList.includes(`${year}-${this.getDateFormat(month)}-${this.getDateFormat(date)}`)
      );
    },
    isDisabled: function (year, month, date) {
      return this.isPast(year, month, date) || !this.isAvailableDate(year, month, date);
    },
    isActive: function (year, month, date) {
      return this.expectedArrivalTime === `${year}-${month}-${date}`;
    },
    isPast: function (year, month, date) {
      const today = new Date(new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`).setHours(9));
      return today.getTime() >= new Date(`${year}-${month}-${date}`).getTime();
    },
    isToday: function (year, month, date) {
      const today = new Date(new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`).setHours(9));
      return today.getTime() === new Date(`${year}-${month}-${date}`).getTime();
    },
    isAvailableDate: function (year, month, date) {
      const startDt = new Date(this.startDt);
      const endDt = new Date(this.endDt);
      const dt = new Date(`${year}-${month}-${date}`);
      return startDt.getTime() <= dt.getTime() && dt.getTime() <= endDt.getTime();
    },
    getDateFormat: function (date) {
      return date === null ? date : date.replace(/^0/, "");
    },
    calendarCallback: function (year, month, date) {
      if (!this.isDisabled(year, month, date)) {
        if (this.isHoliday(year, month, date) || [0, 6].includes(this.getDay(year, month, date))) {
          this.$store.dispatch("setMsg", { msgText: "주말과 공휴일은 선택할 수 없습니다." });
        } else {
          this.updateState({ type: "expectedArrivalTime", updateData: `${year}-${month}-${date}` });
          this.goBack();
        }
      }
    },
  },
  created: function () {
    const { startDt, endDt } = this.$route.params;
    if (startDt) {
      this.startDt = startDt;
    }
    if (endDt) {
      this.endDt = endDt;
    }
    window.onBackPressed = this.goBack;
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: white;
  min-height: 100vh;
  padding-bottom: 30px;

  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 58px;
    background-color: white;
    @include font(20, normal, normal, medium);
    border: {
      style: solid;
      width: 0 0 1px;
      color: $border-type1;
    }
    a {
      position: absolute;
      left: 10px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .calendar_wrap {
    padding: 0 15px;

    .calendar {
      margin-top: 15px;

      .calendar_title {
        color: black;
        font-size: 17px;
        text-align: center;
        font-weight: bold;
      }

      .day_list_wrap,
      .date_list_wrap {
        display: flex;
        align-items: center;
        height: 50px;

        .day,
        .date {
          flex: 1 0 0;
          text-align: center;
          font-weight: bold;

          &.sunday {
            color: $active-color;
          }

          &.saturday {
            color: dodgerblue;
          }

          > a {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 3px 0;
            color: black;

            &.saturday {
              color: dodgerblue;
            }

            &.sunday,
            &.holiday {
              color: $active-color;
            }

            &.disabled {
              opacity: 0.3;
              font-weight: normal;
            }
            &.available {
              background-color: #e7f3ff;
            }

            &.today {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: calc(50% - 15px);
                width: 30px;
                height: 30px;
                border: {
                  style: solid;
                  width: 1px;
                  color: gray;
                  radius: 100%;
                }
              }
              &::after {
                content: "오늘";
                position: absolute;
                bottom: -18px;
                left: calc(50% - 10px);
                font-weight: bold;
                font-size: 12px;
              }
            }

            &.active {
              position: relative;
              background-color: $active-color;
              color: white;
              //&::before {
              //  content: "";
              //  position: absolute;
              //  top: -3px;
              //  left: calc(50% - 15px);
              //  width: 30px;
              //  height: 30px;
              //  background-color: blue;
              //  border-radius: 100%;
              //  opacity: 0.5;
              //}
            }
          }
        }
      }
    }
  }
}
</style>
