import Vue from "vue";
import Router from "vue-router";

import Home from "../page/Home/Home.vue";
import DeliveryAddr from "../page/DeliveryAddr/DeliveryAddr.vue";
import Notice from "../page/Notice/Notice.vue";
import MyPagePrev from "../page/MyPage/MyPage.vue";
import NotFound from "../page/NotFound/NotFound.vue";
import DeliveryProduct from "../page/DeliveryProduct/DeliveryProduct";
import DeliveryStore from "../page/DeliveryStore/DeliveryStore";
import CartMarket from "../page/CartMarket/CartMarket";
import CouponPrev from "@/page/Coupon/Coupon";
import FAQ from "@/page/FAQ/FAQ";
import CartShip from "../page/CartShip/CartShip";
import CategoryProduct from "../page/CategoryProduct/CategoryProduct";
import OrderHistoryPrev from "@/page/OrderHistory/OrderHistory";
import OrderDetailPrev from "@/page/OrderDetail/OrderDetail";
import Market from "../page/Market/Market";
import FavoriteStore from "../page/FavoriteStore/FavoriteStore";
import ReviewPrev from "../page/Review";
import Withdraw from "../page/Etc/Withdraw";
import SettingPrev from "@/page/Etc/Setting";
import ProductInquiryPrev from "@/page/ProductInquiry/ProductInquiry";
import ProductInquiryWritePrev from "@/page/ProductInquiry/ProductInquiryWrite";

import Works from "../page/Works.vue"; // 작업 목록
import Main from "@/page/Main"; // 메인
import MarketMain from "@/page/Market"; // 시장 메인
import StoreMain from "@/page/Store"; // 상점 메인
import Cart from "@/page/Cart"; // 장바구니
import OrderHistory from "@/page/OrderHistory"; // 주문내역
import OrderDetail from "@/page/OrderDetail"; // 주문상세
import ProductMain from "@/page/Product"; // 상품 메인
import Address from "@/page/Address"; // 배송지설정
import Coupon from "@/page/Coupon"; // 보유쿠폰
import paymentNew from "@/page/PaymentNew"; // 결제하기
import CompletePayment from "@/page/CompletePayment"; // 결제완료
import MarketList from "@/page/MarketList"; // 전체시장보기
import FavoritesStore from "@/page/FavoritesStore"; // 단골상점
import Event from "@/page/Event"; // 이벤트
import EventDetail from "@/page/EventDetail"; // 이벤트
import Search from "@/page/Search"; // 검색(시장,상점)
import MyPage from "@/page/MyPage"; // 마이페이지
import Like from "@/page/Like"; // 찜 리스트
import ServiceCenter from "@/page/ServiceCenter"; // 고객센터
import PrivacyInfo from "@/page/PrivacyInfo"; // 개인정보처리방침
import TermsConditions from "@/page/TermsConditions"; // 이용약관
import WithdrawService from "@/page/WithdrawService"; // 서비스탈퇴
import Setting from "@/page/Setting"; // 설정
import StorageSpace from "@/page/StorageSpace"; // 저장공간 관리
import AllMenu from "@/page/AllMenu"; // 전체메뉴
import Review from "@/page/Review"; // 리뷰
import RecommendMember from "@/page/RecommendMember"; // 추천인 이벤트
import ProductInquiry from "@/page/ProductInquiry"; // 상품문의보기, 상품문의쓰기
import Calendar from "@/page/Calendar"; // 상품문의보기, 상품문의쓰기
import ProductOptions from "@/page/ProductOptions"; // 상품옵션

import Dummy from "@/page/Dummy";

import TestTemplate from "../page/TestTemplate/TestTemplate";
import ReportingList from "@/page/Reporting/ReportingList.vue";
import ReportWrite from "../page/Reporting/ReportWrite.vue";
import SpecialOffers from "@/page/SpecialOffers/SpecialOffers.vue";

// 템플릿으로 사용

Vue.use(Router); //플러그인 등록
export default new Router({
  // mode: location.origin === process.env.VUE_APP_BASE_URL ? 'hash' : 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "놀장 메인",
      component: Main,
    },
    {
      path: "/product-options",
      name: "상품옵션",
      component: ProductOptions,
      params: {
        productId: "3884",
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
      },
    },

    //
    {
      // path: "/market-main/:marketId/:marketName",
      path: "/market-main",
      name: "시장 메인",
      component: MarketMain,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
        marketId: "2",
        marketName: "광명전통시장 TEST",
      },
    },
    {
      path: "/store-main",
      name: "상점 메인",
      component: StoreMain,
      params: {
        marketId: "2",
        regularYn: "N",
        storeId: "68",
        storeName: "황금씨앗",
      },
    },
    {
      path: "/cart",
      name: "장바구니",
      component: Cart,
      params: {
        reload: true,
      },
    },
    {
      path: "/order-history",
      name: "주문내역",
      component: OrderHistory,
      props: (route) => ({ userId: route.query.userId }),
    },
    {
      path: "/order-detail",
      name: "주문상세",
      component: OrderDetail,
      props: (route) => ({
        userId: route.query.userId,
        orderId: route.query.orderId,
      }),
    },
    {
      path: "/product-main/:productId",
      name: "상품 메인",
      component: ProductMain,
      params: {
        productId: "3885",
      },
    },
    {
      path: "/address",
      name: "배송지설정",
      component: Address,
    },
    {
      path: "/search",
      name: "검색",
      component: Search,
    },
    {
      path: "/coupon",
      name: "보유쿠폰",
      component: Coupon,
    },
    {
      path: "/paymentNew",
      name: "결제하기",
      component: paymentNew,
    },
    {
      path: "/complete-payment",
      name: "결제완료",
      component: CompletePayment,
      query: {
        orderId: "OID2022033112CB627AB99C44F49E853FB1C8EC8C7D",
      },
    },
    {
      path: "/event",
      name: "이벤트",
      component: Event,
    },
    {
      path: "/event-detail",
      name: "이벤트상세",
      component: EventDetail,
    },
    {
      path: "/my-page",
      name: "마이페이지",
      component: MyPage,
    },
    {
      path: "/market-list",
      name: "전체시장 보기",
      component: MarketList,
    },
    {
      path: "/favorites-store",
      name: "단골상점",
      component: FavoritesStore,
    },
    {
      path: "/like",
      name: "찜 리스트",
      component: Like,
    },
    {
      path: "/service-center",
      name: "고객센터",
      component: ServiceCenter,
    },
    {
      path: "/privacy-info",
      name: "개인정보처리방침",
      component: PrivacyInfo,
    },
    {
      path: "/terms-conditions",
      name: "이용약관",
      component: TermsConditions,
    },
    {
      path: "/withdraw-service",
      name: "서비스탈퇴",
      component: WithdrawService,
    },
    {
      path: "/setting",
      name: "설정",
      component: Setting,
    },
    {
      path: "/storage-space",
      name: "저장공간관리",
      component: StorageSpace,
    },
    {
      path: "/AllMenu",
      name: "전체메뉴",
      component: AllMenu,
    },
    {
      path: "/review",
      name: "리뷰보기",
      component: Review,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
        orderId: "OID2021100308108DE61C454B2C8A1681B13DD1F769", // 리뷰 세개
        // orderId: 'OID2021100303F8571306A549848F16A0599FC75E5D',// 리뷰 하나
        pageMode: "view",
      },
    },
    {
      path: "/review",
      name: "리뷰쓰기",
      component: Review,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
        orderId: "OID202110058D18EF7EF6A14BFEBBCDA56EED37FA5B",
        pageMode: "write",
      },
    },
    {
      path: "/view-product-inquiry",
      name: "상품문의보기",
      component: ProductInquiry,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
        orderId: "OID20211008C5811E41E3F34A9BA168C612176BDA3B",
        pageMode: "view",
      },
    },

    {
      path: "/view-product-inquiry",
      name: "상품문의쓰기",
      component: ProductInquiry,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
        orderId: "OID20211008941B5C779814453A9B84A3ECBD4B9211",
        // orderId: 'OID20211005F2EDDF025B7A450B86AA70426F89DC3C',
        pageMode: "write",
        marketId: 2,
      },
    },
    {
      path: "/recommend-member",
      name: "추천인 이벤트",
      component: RecommendMember,
      params: {
        userId: "97e5a909-3ceb-4dd3-874a-c41f83bb9dbd",
      },
    },

    // {
    //     path: '/write-product-inquiry',
    //     name: '상품문의',
    //     component: ProductInquiryWrite,
    //     query: {
    //         userId: '97e5a909-3ceb-4dd3-874a-c41f83bb9dbd',
    //         // orderId: 'OID2021072168B5A81A91094DC5BC681686DF681AD1',
    //         orderId: 'OID20210720877654E9A7B44D899875A7B2821F6DA2',
    //         pageMode: 'write'
    //     },
    // },
    {
      path: "/works",
      name: "작업 목록",
      component: Works,
    },

    {
      path: "/jmDummy",
      name: "테스트 화면 (서정민)",
      component: Dummy,
    },

    // 기존 작업 화면 (이 위에 신규 라우트를 추가해야 합니다.)
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/delivery-address",
      name: "배송지 설정",
      component: DeliveryAddr,
    },
    {
      path: "/notice",
      name: "알림화면",
      component: Notice,
    },
    {
      path: "/my-page-prev",
      name: "마이페이지-prev",
      component: MyPagePrev,
    },
    {
      path: "/delivery-product",
      name: "상품정보",
      component: DeliveryProduct,
    },
    {
      path: "/delivery-store",
      name: "상점정보",
      component: DeliveryStore,
    },
    {
      path: "/cart-ship",
      name: "장바구니(배송지)",
      component: CartShip,
      porps: true,
    },
    // {
    //   path: "/payment",
    //   name: "결제 완료",
    //   component: Payment,
    // },
    {
      path: "/cart-market",
      name: "장바구니",
      component: CartMarket,
    },
    {
      path: "/category-product",
      name: "카테고리 상품목록",
      component: CategoryProduct,
    },
    {
      path: "/market",
      name: "전체시장 리스트",
      component: Market,
    },
    {
      path: "/favorite-store",
      name: "단골가게",
      component: FavoriteStore,
    },
    {
      path: "/review-prev",
      name: "리뷰쓰기-prev",
      component: ReviewPrev,
    },
    {
      path: "/test-template",
      name: "템플릿",
      component: TestTemplate,
    },
    {
      path: "/withdraw",
      name: "서비스 탈퇴",
      component: Withdraw,
    },
    {
      path: "/setting-prev",
      name: "설정-prev",
      component: SettingPrev,
    },
    {
      path: "/view-product-inquiry-prev",
      name: "상품문의보기-prev",
      component: ProductInquiryPrev,
    },
    {
      path: "/write-product-inquiry-prev",
      name: "상품문의-prev",
      component: ProductInquiryWritePrev,
    },
    {
      path: "/coupon-prev",
      name: "보유 쿠폰",
      component: CouponPrev,
    },
    {
      path: "/FAQ",
      name: "고객 센터",
      component: FAQ,
    },
    {
      path: "/report",
      name: "report",
      component: ReportingList,
    },
    {
      path: "/reportwrite",
      name: "reportwrite",
      component: ReportWrite,
    },
      //타임 딜
    {
      path: "/special-offers",
      name: "specialOffers",
      component: SpecialOffers,
    },
    {
      path: "/order-history-prev",
      name: "주문 내역",
      component: OrderHistoryPrev,
      props: (route) => ({ userId: route.query.userId }),
    },
    {
      path: "/order-detail-prev",
      name: "주문 상세",
      component: OrderDetailPrev,
      props: (route) => ({
        userId: route.query.userId,
        orderId: route.query.orderId,
      }),
    },
    {
      path: "/calendar",
      name: "상품도착 희망일 선택",
      component: Calendar,
    },
    {
      path: "*",
      component: NotFound,
    },
  ],
});
