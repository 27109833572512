<template>
  <div class="modal_bottom_wrap">
    <a href="javascript: void(0)" class="modal_background" @click="callback(null)" title="레이어 닫기">레이어 닫기</a>
    <transition name="select">
      <div class="modal_bottom">
        <div class="bar"><span></span></div>
        <div class="title" v-if="title">{{title? title : ""}}</div>
        <div class="select_contents" v-if="type === 'SELECT'">
          <ul>
            <li :class="{active: activeId === Object.values(option)[0]}" v-for="option in options">
              <a href="javascript: void(0)" @click="callback(Object.values(option)[0])">{{Object.values(option)[1]}}</a>
            </li>
          </ul>
        </div>
        <div class="select_contents type1" v-else-if="type === 'CHECKBOX' && checkboxInfo">
          <CheckboxList type="type2" :checkboxInfo="checkboxInfo" :callback="callback" />
        </div>
        <Btn :type="!title ? 'icon_close_bottom_modal' : 'icon_close_bottom_modal is_title'" @btnCallback="callback(null)" />
      </div>
    </transition>
  </div>
</template>

<script>

import Btn from "@/components/new/Btn";
import CheckboxList from "@/components/new/CheckboxList";

export default {
  name: "ModalBottom",
  props: {
    type: String,
    name: String,// checkbox
    title: String,
    activeId: String,
    options: Array,
    callback: Function,
  },
  data() {
    return {
      checkboxInfo: null,
      checkboxOptions: this.options,
    };
  },
  created() {
    this.checkboxInfo = {
      type: "type3",
      name: this.name,
      options: this.options,
    };
    document.querySelector("html").classList.add("overflow_clip");
  },
  mounted() {
    this.type === 'SELECT' && document.querySelector('.select_contents').querySelector('.active')?.scrollIntoView({behavior: "auto"})
  },
  destroyed() {
    document.querySelector("html").classList.remove("overflow_clip");
  },
  methods: {
    // changeCheckbox: function (data) {
    //   console.log("changeCheckbox ::: ");
    //   console.log(data);
    //   const targetCheckbox = this.checkboxInfo.options.filter(checkbox => checkbox.id === data.optionCode)[0];
    //   targetCheckbox.checked = data.checked;
    //   targetCheckbox.active = data.checked;
    //
    //   console.log(targetCheckbox);
    //   console.log(this.checkboxInfo);
    //
    //   // if (this.callback) {
    //   //   this.callback(this.checkboxOptions);
    //   // }
    //   // options
    // },
  },
  components: {
    Btn,
    CheckboxList,
  }
};
</script>

<style lang="scss" scoped>

.modal_bottom_wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100000;
  .modal_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    text-indent: -9999px;
  }
  .modal_bottom {
    position: absolute;
    bottom: -500px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 0 0;
    background-color: #fff;
    border: {
      radius: 5px 5px 0 0;
    }
    animation-name: show_bottom_modal;
    animation-duration: 0.35s;
    //animation-iteration-count: 1;
    animation-fill-mode: forwards;
    //animation-duration: leaner;

    &.active {
      animation-name: show_bottom_modal;
    }

    .bar {
      text-align: center;
      span {
        display: inline-block;
        width: 50px;
        height: 5px;
        background-color: #EEE;
        border-radius: 3px;
      }
    }
    .title {
      min-height: 32px;
      padding: 7px 0 12px;
      border: {
        style: solid;
        width: 0 0 1px;
        color: #f1f1f1;
      }
      font: {
        size: 15px;
        weight: bold;
      }
      color: #555;
      text-align: center;
    }
    .select_contents {
      max-height: 230px;
      line-height: 1.7;
      padding: 15px 0;
      text-align: center;
      overflow: auto;

      &.type1 {
        padding: 15px;
        text-align: left;
      }
      > ul {
        display: inline-block;
      }
      li {
        padding: 3px 0;
        a {
          font: {
            size: 15px;
          }
          color: #999;
        }
        &.active {
          a {
            font-weight: bold;
            color: #555;
            //color: $font-color-gray-dark;
          }
        }
      }
    }
  }
}

@-webkit-keyframes show_bottom_modal {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 0;
  }
}

</style>
