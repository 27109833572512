<template>
  <div class="wrap">
    <TopHeader :title="title" theme="nj_red" @goBack="goBack" />
    <div class="contents_wrap">
      <div class="contents_area">
        <ul class="setting_list">
          <li>
            <strong class="title">내 정보</strong>
            <ul class="detail_list">
              <li>
                <a href="javascript:void(0);" data-type="서비스 탈퇴" @click="moveToApp"
                  ><Icon type="icon_arrow_left_normal" value="서비스 탈퇴"
                /></a>
              </li>
            </ul>
          </li>
          <li>
            <strong class="title">서비스 설정</strong>
            <ul class="detail_list">
              <li>
                푸쉬 알림
                <div class="absolute_right">
                  <Btn :type="pushYn == 'Y' ? 'slide_check active' : 'slide_check'" btnId="PUSH" value="푸쉬 알림 선택/해제" @btnCallback="setPush" />
                </div>
              </li>
            </ul>
          </li>
          <li>
            <strong class="title">약관 및 정책</strong>
            <ul class="detail_list">
              <li>
                <a href="javascript:void(0);" data-type="놀장 이용약관" @click="moveToApp"
                  ><Icon type="icon_arrow_left_normal" value="놀장 이용약관"
                /></a>
              </li>
              <li>
                <a href="javascript:void(0);" data-type="개인정보취급방침" @click="moveToApp"
                  ><Icon type="icon_arrow_left_normal" value="개인정보취급방침"
                /></a>
              </li>
            </ul>
          </li>
          <li>
            <strong class="title">버전 정보</strong>
            <ul class="detail_list">
              <li>
                현재 버전
                <span class="absolute_right type1">Ver {{ thisVer }}</span>
              </li>
              <!--              <li>최신 버전 <span class="absolute_right type1">Ver 2.0.20</span></li>-->
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import Btn from "@/components/Btn";
import Icon from "@/components/new/Icon";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "Setting",
  computed: {
    ...mapState({
      title: (state) => state.setting.title,
      userId: (state) => state.setting.userId,
      pushYn: (state) => state.setting.pushYn,
      thisVer: (state) => state.setting.thisVer,
    }),
  },
  beforeCreate: function () {
    this.$store.dispatch("setting/initState", {
      userId: this.$route.query.userId,
      thisVer: this.$route.query.thisVer,
    });
  },
  components: {
    TopHeader,
    Icon,
    Btn,
  },
  methods: {
    ...mapMutations("setting", ["moveToApp"]),
    ...mapActions("setting", ["goBack", "setPush"]),
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}
.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.contents_area {
  padding: {
    //top: 20px;
    //left: 20px;
    //right: 20px;
    bottom: 20px;
  }
}
.setting_list {
  > li {
    border-style: solid;
    border-color: $nj_divider_gray;
    border-width: 1px 0 0;
    &:first-child {
      border-width: 0;
    }
    .title {
      display: block;
      height: 41px;
      border-style: solid;
      border-color: $nj_divider_gray;
      border-width: 0 0 1px;
      padding: 0 20px;
      @include font(14, 41, -0.84, bold);
      color: $nj_red;
    }
  }
  .detail_list {
    padding: 0 20px;
    > li {
      position: relative;
      height: 67px;
      @include font(18, 67, -1.08);
      color: $nj_black;
      border-style: solid;
      border-color: $nj_divider_gray;
      border-width: 0 0 1px;
      &:last-child {
        border-width: 0;
      }
      a {
        color: $nj_black;
      }
    }
  }
}
.absolute_right {
  position: absolute;
  top: 18px;
  right: 0;
  &.type1 {
    top: 20px;
    @include font(12, 22, -0.48);
    color: $nj_text_main_gray;
  }
}
</style>
