<template>
  <div class="wrap">
    <div class="description_wrap" v-if="description">
      {{ description }}
    </div>
    <div class="no_description_wrap" v-else>상세정보가 없습니다.</div>
  </div>
</template>

<script>
export default {
  name: "ProductDetail",
  props: {
    description: String,
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.description_wrap {
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.96px;
  color: $nj_text_main_gray;
}
.no_description_wrap {
  text-align: center;
  padding-top: 40px;
  letter-spacing: -0.96px;
  color: $nj_text_main_gray;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
}
</style>
