<template>
  <div class="contentsXXXXXXXXXX" style="background-color: #fff">
    <div class="contents_header">
      <div class="my_info">
        <div class="user_setting_wrap">
          <a href="javascript:void(0)" title="설정" class="icon_mypage_setting_white" @click="goSetting" v-if="userId">설정</a>
          <a href="javascript:void(0)" title="전체메뉴 닫기" class="icon_close_white" @click="closeFunc">전체메뉴 닫기</a>
        </div>
        <div v-if="userId">
          <div class="user_name">{{ userInfo.memberName }}</div>
          <div class="user_phone">{{ userInfo.phoneNumber }}</div>
        </div>
        <div v-else>
          <a href="javascript:void(0)" title="설정" @click="goAuthentication">
            <div class="user_name">놀장</div>
            <div class="user_pass">회원가입하기</div>
          </a>
        </div>
      </div>
    </div>

    <div class="info_card">
      <a class="user_point" v-if="getPoint" @click="moveToPoint">
        포인트 <span class="ml_5">{{ $formatPrice(point) }} P</span>
      </a>
      <div class="qr_pay" @click="goQrPay">QR결제</div>
    </div>

    <AllMenuList :userId="userId" :closeFunc="closeFunc" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AllMenuList from "@/components/new/AllMenuList";

export default {
  name: "AllMenu",
  props: {
    userId: String,
    showAllmenu: Boolean,
    closeFunc: Function,
  },
  data: function () {
    return {
      // userId: localStorage.getItem('userId'),
      userInfo: {},
      point: null,
    };
  },
  components: {
    AllMenuList,
  },
  methods: {
    ...mapActions(["appDownloadModal"]),
    moveToPoint: function () {
      if (this.appVersion) {
        if (this.$store.state.userId != null) {
          // 운영
          if (this.$isProd()) {
            this.$navigateTo("open-url", {
              title: "포인트 내역",
              url: "http://noljang.co.kr/deployment/V3/member/point-history/view.html?userId=" + this.$store.state.userId,
            });
          }
          // 개발
          else {
            this.$navigateTo("open-url", {
              title: "포인트 내역",
              url: "http://noljang.com/test/V3/member/point-history/view.html?userId=" + this.$store.state.userId,
            });
          }
        } else {
          const _this = this;
          const modalName = "authentication";
          const compProps = {
            title: "휴대전화 본인인증",
            msgArr: ["휴대전화 본인 인증이 필요합니다."],
            btnArr: [
              {
                type: "type1 inverse",
                txt: "본인 인증 하기",
                btnId: "",
                btnData: {},
                btnCallback: function () {
                  _this.$modal.hideAll();
                  _this.$navigateTo("authentication");
                },
              },
            ],

            closeModal: function () {
              _this.$modal.hideAll();
            },
          };

          this.$store.dispatch(
            "showModal",
            {
              name: modalName,
              compProps: compProps,
              modalProps: {
                width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
                height: "auto",
              },
            },
            { root: true },
          );
        }
      } else {
        this.appDownloadModal();
      }
    },
    goBack: function () {
      // console.log("goBack ::::: ");
      // this.$router.back();
      // this.$navigateBack();
      if (this.showAllmenu) {
        this.closeFunc();
      }
    },

    onBackPressed: function () {
      // console.log("onBackPressed");
      // window.history.back();
      // this.$router.back();
      // this.$navigateBack();
      this.goBack();
    },

    goSetting: function () {
      this.closeFunc();
      this.$router.push({ name: "설정" });
    },

    goAuthentication: function () {
      if (this.appVersion) {
        this.$navigateTo("authentication");
      } else {
        this.appDownloadModal();
      }
    },

    getUserInfo: async function () {
      // console.log("userId ::: " + this.userId);
      if (!this.userId) {
        return false;
      }
      // console.log("userResponse");
      // console.log("userResponse");
      const userResponse = await this.$axios1.get("/v3/user/member/information/" + this.userId + "/personal");
      if (userResponse.data.responseCode == 2000) {
        // console.log(userResponse);
        this.userInfo = userResponse.data.result;
      }
    },

    getPoint: function () {
      this.$axiosLegacy
        .get("/api2/user/member/point?userId=" + (this.$store.state.userId ?? localStorage.getItem("userId")))
        .then((response) => {
          if (response.data.responseCode == 2000) {
            this.isProgressing = false;
            // console.log(response);
            this.point = response.data.point;
          } else {
            this.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
    },

    goQrPay: function () {
      if (this.appVersion) {
        if (!this.userId) {
          // console.log(this.userId);
          // console.log("this.userId == null");

          const _this = this;
          const modalName = "authentication";
          const compProps = {
            title: "휴대전화 본인인증",
            msgArr: ["휴대전화 본인 인증이 필요합니다."],
            btnArr: [
              {
                type: "type1 inverse",
                txt: "본인 인증 하기",
                btnId: "",
                btnData: {},
                btnCallback: function () {
                  _this.$modal.hideAll();
                  _this.$navigateTo("authentication");
                },
              },
            ],

            closeModal: function () {
              this.$modal.hideAll();
            },
          };

          this.$store.dispatch(
            "showModal",
            {
              name: modalName,
              compProps: compProps,
              modalProps: {
                width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
                height: "auto",
              },
            },
            { root: true },
          );

          return;
        } else {
          // console.log(this.userId);
          // console.log("this.userId");
          this.$navigateTo("qr-reader");
        }
      } else {
        this.appDownloadModal();
      }
    },
    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });

      this.userId = userId;

      this.getUserInfo();
      this.getPoint();
    },
  },

  mounted() {
    // window.onBackPressed = this.onBackPressed;
    window.authResult = this.authResult;
  },

  created() {
    if (this.userId) {
      this.getUserInfo();
      this.getPoint();
    }
  },
  watch: {
    userId: function () {
      this.getUserInfo();
      this.getPoint();
    },
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.contents {
  //width: 85%;
  height: 100%;
}

.contents_header {
  width: 100%;
  //padding-top: 30px;
  padding: 18px 0 12px;
  background-color: $active_background-color;

  .my_info {
    position: relative;
    height: 91px;
    padding: 0 20px;

    .user_setting_wrap {
      position: absolute;
      right: 16px;

      a {
        display: inline-block;
        width: 22px;
        height: 22px;
        vertical-align: middle;
        text-indent: -9999px;

        &.icon_mypage_setting_white {
          background-image: url("~svg/icon_mypage_setting_white.svg");
          background-repeat: no-repeat;
          background-position: center;
        }

        &.icon_close_white {
          margin: 0 0 0 20px;
          background-image: url("~svg/icon_close_white.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .user_name {
      @include font(20, 24, 0, bold);
      width: 70%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $white;
    }

    .user_phone {
      margin: 10px 0 0;
      @include font(16, 20, 0);
      color: $white;
    }

    .user_pass {
      display: inline-block;
      padding: 5px 20px 0 0;
      background-image: url("~svg/icon_more_05.svg");
      background-repeat: no-repeat;
      background-position: right 12px;
      @include font(16, 24, -0.96, medium);
      color: $white;
    }
  }
}

.info_card {
  //position: absolute;
  display: block;
  //top: 95px;
  //left: 20px;
  //width: calc(100% - 40px);
  height: 60px;
  padding: 19px 10px;
  margin: -30px 16px 0;
  border-radius: 6px;
  background-color: $white;
  box-shadow: 0px 1px 2px 1px #dcdcdc;
  z-index: 100;

  .user_point {
    float: left;
    display: inline-block;
    padding: 0 15px 0 0;
    @include font(13, 24, -0.5, thin);
    color: $black;
    background-image: url("~svg/icon_more_04.svg");
    background-repeat: no-repeat;
    background-position: right 6px;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:active {
      color: $black;
    }

    span {
      @include font(14, 24, 0, bold);
    }
  }
  .qr_pay {
    float: right;
    display: inline-block;
    padding: 0 15px 0 0;
    @include font(14, 24, 0, thin);
    color: $black;
    background-image: url("~svg/icon_more_04.svg");
    background-repeat: no-repeat;
    background-position: right 6px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
