<template>
  <ul class="product_list mt_8">
    <li v-for="product in products" :key="product.productId">
      <div class="order_product_item">
        <a
          href="javascript:void(0);"
          class="product_title"
          :data-event-type="'moveToProduct'"
          :data-product-id="product.productId"
          @click="$emit('moveToApp', $event)"
        >
          <div>
            <span class="name"
              >{{ product.productName }}
              {{ product.unitNumber + product.unitName }}
              <span class="weight" v-html="product.weight" />
            </span>
          </div>
          <div class="origin_name">원산지: {{ product.originName }}</div>
        </a>

        <ul class="opt_list" v-if="product.optionGroups">
          <li v-for="optionGroup in product.optionGroups" :key="optionGroup.optionGroupId">
            <div v-for="option in optionGroup.options" :key="option.optionId">
              {{ option.optionName }}

              <span class="ml_5">{{
                Number(option.optionPrice) > 0 ? "+" + $formatPrice(Number(option.optionPrice)) : $formatPrice(Number(option.optionPrice))
              }}</span>
            </div>
          </li>
        </ul>
        <div class="price mt_12">{{ $formatPrice(Number(product.payment)) }}원</div>
      </div>

      <div v-if="product.orderRequest" class="order_product_item order_request">
        {{ product.orderRequest }}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "OrderProductList",
  props: {
    products: Array,
  },
};
</script>

<style lang="scss" scoped>
.order_product_item {
  padding: 20px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
  margin-bottom: 10px;

  &.order_request {
    padding: 16px 20px;
    @include font(16, 22, -0.96);
    color: $nj_text_main_gray;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    @include font(18, 27, -1.08, medium);
    color: $nj_black;
  }
  .origin_name {
    @include font(12, 18, -0.72);
    color: $nj_text_main_gray;
  }

  .weight {
    margin-left: 8px;
    @include font(16, 24, -0.96);
    color: $nj_text_sub_gray;
  }

  .product_title {
    display: block;
    padding: 0 15px 0 0;
    background-image: url("~image/icon_arrow_left_normal.svg");
    background-repeat: no-repeat;
    background-position: right center;
  }

  .price {
    @include font(16, 22, -0.96);
    color: $nj_red;
  }
}

.product_list {
  > li {
    padding: 0 0 20px;
    &:last-child {
      padding: 0;
    }
  }
}

.opt_list {
  margin-top: 9px;
  > li {
    @include font(12, 18, -0.72);
    color: $nj_text_main_gray;
  }
}
</style>
