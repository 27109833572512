var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_wrap"},[_c('TopHeader',{attrs:{"title":_vm.title,"isButton":_vm.currentComp == 'DeliveryAddrList' && _vm.isAddressRequired ? false : true},on:{"goBack":_vm.goBack},scopedSlots:_vm._u([{key:"btn_back_icon",fn:function(){return [(_vm.currentComp == 'DeliveryAddrList')?_c('img',{attrs:{"src":require("@/assets/images/Xbtn.png")}}):_c('img',{attrs:{"src":require("@/assets/images/btn_back.png")}})]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('keep-alive',[(_vm.currentComp == 'DeliveryAddrList')?_c(_vm.currentComp,{tag:"component"}):_vm._e()],1),(_vm.currentComp == 'DeliveryAddrDetail')?_c(_vm.currentComp,{tag:"component",attrs:{"shipToDoro":_vm.selectedSearchAddress.shipToDoro,"shipToJibun":_vm.selectedSearchAddress.shipToJibun,"showHideLayer":_vm.showHideAlertLayer}}):_vm._e()],1),_c('Alert',{attrs:{"displayLayer":_vm.displayAlertLayer,"texts":['상세 주소는 필수 입력 항목입니다.'],"textClass":[],"btns":[
      {
        type: null,
        btnId: '',
        btnData: {},
        value: '확인',
      },
    ],"showHideLayer":_vm.showHideAlertLayer}}),_c('Alert',{attrs:{"displayLayer":_vm.displayDeleteAlertLayer,"texts":['해당 주소를 삭제하시겠습니까?'],"textClass":[],"btns":[
      {
        type: null,
        btnId: '',
        btnData: {},
        value: '취소 ',
      },
      {
        type: null,
        btnId: 'DELETE',
        btnData: {},
        value: '삭제',
      },
    ],"showHideLayer":_vm.showHideDeleteAlertLayer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }