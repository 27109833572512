<template>
  <ul class="select_list">
    <li v-for="list in selectList" :key="list.id">
      <Btn type="type3" :btnId="list.id" :title="list.title" :value="list.value" @btnCallback="selectCallback" />
    </li>
  </ul>
</template>

<script>
import Btn from "../Btn";

export default {
  name: "SelectList",
  props: {
    selectList: Array,
    selectCallback: Function,
  },
  components: {
    Btn,
  },
};
</script>

<style lang="scss" scoped>
.select_list {
  li {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
