<template>
  <div class="product_item" @click="onProductClick">
    <div class="product_img_wrap">
      <img :src="productImageUrl" />
    </div>
    <div class="product_name">
      {{ productName }}
    </div>
    <div class="product_price">
      {{ formattedPrice }}
      <span class="price_txt">원</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    id: Number,
    productName: String,
    price: Number,
    productImageUrl: String,
  },
  methods: {
    onProductClick: function () {
      this.$callNative("moveToProduct", {
        productId: this.id,
      });
    },
  },
  computed: {
    formattedPrice: function () {
      return this.price.toLocaleString("ko-KR");
    },
  },
};
</script>

<style lang="scss" scoped>
.product_item {
  display: inline-block;
  margin-right: 10px;
  width: 150px;

  &:last-child {
    margin-right: 0px;
  }

  .product_img_wrap {
    width: 100%;
    height: 116px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
      box-shadow: 0px 1px 0px $nj_shadow_gray;
    }
  }

  .product_name {
    //@include text_ellipsis();
    @include font(16, 22, -0.96, medium);
    color: $nj_black;

    overflow: hidden;
    text-overflow: ellipsis;
    //-webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  .product_price {
    @include font(18, 28, -1.08, bold);
    color: $nj_black;

    .price_txt {
      margin-left: -3px;
      @include font(12, 24, -0.72);
      color: $nj_text_main_gray;
    }
  }
}
</style>
