<template>
  <ul class="favorite_like_list">
    <li>
      <!-- 좋아요 -->
      <!--      <Icon v-if="store" type="icon_store_red_small" :value="$formatPrice(store)" />-->
      <Icon v-if="store" type="icon_store_small" :value="$formatPrice(store)" />
    </li>
    <li>
      <!-- 좋아요 -->
      <Icon v-if="like" type="icon_heart_small" :value="$formatPrice(like)" />
    </li>
  </ul>
</template>

<script>
import Icon from "../new/Icon";
export default {
  name: "FavoriteLike",
  props: {
    store: Number,
    like: Number,
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.favorite_like_list {
  > li {
    display: inline-block;
    &:nth-child(n + 2) {
      margin-left: 10px;
    }
  }
}
</style>
