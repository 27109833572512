<template>
  <!--  메뉴 아이템 영역입니다. (주문내역, 보유쿠폰, QR 결제 등) -->
  <a class="menu_item" href="javascript:void(0)" @click="goLink">
    <span class="title">{{ title }}</span>
    <span class="menu_badge_wrap" v-if="badgeUrl">
      <span class="menu_badge">
        {{ formattedCount }}
      </span>
    </span>
    <span class="menu_btn">
      <a href="javascript:void(0)"></a>
    </span>
  </a>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    title: String,
    badgeUrl: String,
    link: String,
    detailUrl: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      badge: 0,
    };
  },
  methods: {
    goLink: function () {
      let param = null;

      if (this.link == "showTerms") {
        param = {
          title: this.title,
          detailUrl: this.detailUrl,
        };
      }
      this.$callNative(this.link, param);
    },
    getBadgeCount: async function () {
      if (this.badgeUrl && this.$route.query.userId) {
        const response = await this.$axiosLegacy.get(this.badgeUrl);

        this.badge = response.data.couponInfos.length;
      }
    },
  },
  created() {
    this.getBadgeCount();
  },
  computed: {
    formattedCount: function () {
      return this.badge > 99 ? "99+" : this.badge;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_item {
  display: block;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid $nj_divider_gray;

  &:first-child {
    border-top: 1px solid $nj_divider_gray;
  }

  & > span {
    display: inline-block;
    vertical-align: top;
  }

  .title {
    @include font(18, 28, -1.08);
    color: $nj_black;
  }

  .menu_badge_wrap {
    height: 28px;
    margin-left: 10px;

    .menu_badge {
      width: 100%;
      padding: 1px 6px;
      border-radius: 20px 20px 20px 0;
      background: #ffcd33;
      @include font(10, 28, -0.6, bold);
      color: $nj_black;
    }
  }

  .menu_btn {
    float: right;
    vertical-align: top;
    width: 8px;
    height: 28px;

    a {
      display: inline-block;
      width: 8px;
      height: 28px;
      transform: rotate(180deg);
      background-image: url("../../../assets/images/btn_back.png");
      background-size: unset;
      background-repeat: no-repeat;
      background-position: left center;
      vertical-align: middle;
    }
  }
}
</style>
