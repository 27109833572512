<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" :isCartVisible="false" style="position: fixed; top: 0; width: 100%; z-index: 999" />
    <div class="contents" style="padding: 58px 0 0; height: 100%; overflow: unset">
      <LoadingIndicator v-if="isProgressing" />

      <div class="order_refund_wrap" v-if="order.cancelReason">
        <span class="order_refund_msg">{{ order.cancelReason }}</span>
      </div>
      <!-- 아래코드 주석처리 후 위 코드로 적용 : 2021-11-11 -->
      <!--      <div class="order_refund_wrap" v-if="isCanceledOrder">
        <span v-if="true" class="order_refund_msg_title">{{ order.orderRejectYn === "Y" ? "접수거부사유" : "주문환불사유" }}</span>
        <span class="order_refund_msg">{{ order.cancelReason }}</span>
      </div>-->

      <div class="order_info_wrap">
        <span class="order_title_wrap">
          <span class="order_title" v-if="order.productList">
            {{ order.orderTitle }}
            <!--            <template v-if="order.productList.length == 1">-->
            <!--            {{order.orderTitle}}-->
            <!--            </template>-->
            <!--            <template v-else-if="order.productList.length > 1">-->
            <!--            {{order.productList[0].productName}} 외 <span class="red">{{order.productList.length - 1}}</span>건-->
            <!--            </template>-->
          </span>

          <span class="order_status" v-if="totalPayment.paymentStatus">
            {{
              totalPayment.paymentStatus == "PC" || totalPayment.paymentStatus == "C"
                ? totalPayment.paymentStatus == "PC"
                  ? "부분 환불"
                  : "전체 환불"
                : order.orderStatus
            }}

            <!-- 정상작동하지 않아 아래코드 주석처리 후 원복함 : 2021-11-11 -->
            <!--
            <template v-if="isRefundedOrder"> 1 {{ totalPayment.paymentStatus === "PC" ? "부분 환불" : "전체 환불" }} </template>
            <template v-else> 2 {{ order.orderStatus }} </template>
            -->
          </span>
          <span class="order_status" v-else>{{ order.orderStatus }}</span
          ><!-- 운영 정상 반영후 삭제 처리 -->
        </span>
        <a class="market_name_wrap" @click="moveToMarket">
          <span class="market_name">{{ order.marketName }}</span>
          <span class="icon_more_arrow" />
        </a>
        <div class="order_detail_wrap">
          <span class="order_id"> 주문번호 {{ order.orderId ? order.orderId.slice(-4) : "" }} </span>
          <span class="order_datetime">
            주문일시
            {{ $moment(order.orderDateTime).format("YYYY.MM.DD dddd a hh:mm") }}
          </span>
          <div class="invoice_number" v-if="order.orderType == 'TB' && order.invoiceNo">
            송장번호 {{ order.invoiceNo }} {{ order.deliveryCo }}
            <div class="invoice_wrap" v-if="order.orderType == 'TB' && order.invoiceNo">
              <Btn class="invoice copy" type="type3 invoice" value="번호복사" :data="{ invoiceNo: order.invoiceNo }" @btnCallback="copyInvoice" />
              <Btn class="invoice tracking" type="type3 invoice" value="배송조회" @btnCallback="moveToTracking" />
            </div>
          </div>
        </div>
      </div>
      <div class="store_list_wrap">
        <div class="store_list" v-for="store in order.stores" :key="store.storeId">
          <!--          <a class="store_name_wrap" @click="moveToStore(store)">-->
          <!--            <span class="store_name">{{ store.storeName }}</span>-->
          <!--            <span class="icon_more_arrow"/>-->
          <!--          </a>-->
          <ProductList
            v-for="(product, idx) in store.products"
            :key="idx"
            :compoundKey="product.compoundKey ? product.compoundKey : null"
            :storeName="store.storeName"
            :productId="product.productId"
            :productImageUrl="product.productThumbnailUrl"
            :name="product.productName"
            :quantity="product.quantity"
            :price="product.unitPrice"
            :discountPrice="product.discountPrice"
            :requestInput="true"
            :orderRequest="product.orderRequest"
            :unitName="product.unitName"
            :unitNumber="product.unitNumber"
            :weight="product.weight"
            :originName="product.originName"
            :optionGroups="product.optionGroups ? (product.optionGroups.length > 0 ? product.optionGroups : null) : null"
            viewMode="info"
          />
        </div>
      </div>
      <div class="payment_detail_wrap">
        <ul class="payment_detail_list">
          <li>
            <span class="title"> 총 상품금액 </span>
            <span class="content">
              <span class="price">{{ $formatPrice(totalPayment.productsPrice) }}</span>
              <span class="price_txt">원</span>
            </span>
          </li>

          <!-- *************** 연말 이벤트 예외 처리 *************** -->
          <template v-if="new Date(order.orderDateTime) > new Date('2021-12-18') && new Date(order.orderDateTime) < new Date('2021-12-23')">
            <li style="position: relative; padding: 10px 0 30px">
              <span class="title"> 총 할인 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.totalDiscount) }}</span>
                <span class="price_txt">원</span>
              </span>

              <div style="position: absolute; bottom: 0; right: 0; text-align: right; color: #f00">
                <div style="line-height: 1.3; font-size: 12px">20,000원 이상 주문 시 20% 할인, 1인당 1일 1회</div>
                <!--<div style="line-height: 1.3">(사용가능 0원)</div>-->
              </div>
            </li>

            <li style="position: relative; padding: 10px 0 30px" v-if="order.marketId !== 188">
              <span class="title"> 배송비 </span>
              <span class="content">
                <template v-if="order.orderType === 'BD'">
                  <span class="price">{{ totalPayment.deliveryFee === totalPayment.deliveryDiscount ? "무료배송" : totalPayment.deliveryFee }}</span>
                  <span class="price_txt" v-if="totalPayment.deliveryFee !== totalPayment.deliveryDiscount">원</span>
                </template>
                <template v-else-if="totalPayment.deliveryFee === 0">
                  <span class="price">택배비는 착불입니다.</span>
                </template>
                <template v-else>
                  <span class="price">{{ totalPayment.deliveryFee === totalPayment.deliveryDiscount ? "무료배송" : totalPayment.deliveryFee }}</span>
                  <span class="price_txt" v-if="totalPayment.deliveryFee !== totalPayment.deliveryDiscount">원</span>
                </template>
              </span>
              <div style="position: absolute; bottom: 0; right: 0; text-align: right; color: #f00">
                <div style="line-height: 1.3; font-size: 12px">1만원 이상 주문 시 무료 배송</div>
              </div>
            </li>
          </template>
          <!-- end: *************** 연말 이벤트 예외 처리 *************** -->

          <template v-else>
            <li :class="{ delivery_tb: order.orderType === 'TB' && order.deliveryFeeMethod !== 'PREPAYMENT' }">
              <span class="title"> 배송비 </span>
              <div class="content">
                <template v-if="order.orderType === 'BD'">
                  <span class="price">{{
                    order.onSiteYn === "Y"
                      ? 0
                      : totalPayment.deliveryFee === totalPayment.deliveryDiscount
                      ? "무료배송"
                      : $formatPrice(totalPayment.deliveryFee)
                  }}</span>
                  <span class="price_txt" v-if="totalPayment.deliveryFee !== totalPayment.deliveryDiscount || order.onSiteYn === 'Y'">원</span>
                </template>
                <template v-else-if="order.orderType === 'TB' && order.deliveryFeeMethod">
                  <div :class="{ content_flex: order.deliveryFeeMethod !== 'PREPAYMENT' }">
                    <span class="cash_delivery" v-if="order.deliveryFeeMethod && order.deliveryFeeMethod === 'CASH_DELIVERY'">착불</span>
                    <span class="price">{{
                      deliveryFee !== null && typeof getTbDeliveryFee() === "string" ? getTbDeliveryFee() : $formatPrice(getTbDeliveryFee())
                    }}</span>
                    <span class="price_txt" v-if="deliveryFee && deliveryFee !== '무료배송'">원</span>
                  </div>
                  <div class="deferred_payment_info" v-if="order.deliveryFeeMethod !== 'PREPAYMENT'">
                    <span v-if="order.deliveryFeeMethod === 'MIXED_PAYMENT'">착불배송비 제외</span>
                    <span v-else-if="order.deliveryFeeMethod === 'CASH_PAYMENT'">착불수수료 제외</span>
                  </div>
                </template>
                <template v-else-if="order.orderType === 'TB' && !order.deliveryFeeMethod">
                  <div :class="{ content_flex: true }">
                    <span class="price">택배비는 착불입니다.</span>
                  </div>
                </template>
              </div>
            </li>
            <li v-if="totalPayment.deliveryDiscount && totalPayment.deliveryFee != totalPayment.deliveryDiscount && order.onSiteYn !== 'Y'">
              <span class="title"> 배송비 할인 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.deliveryDiscount) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
            <li v-if="totalPayment.totalDiscount">
              <span class="title"> 이벤트 할인 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.totalDiscount) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
            <li v-if="totalPayment.totalDiscountPackagingPrice">
              <span class="title"> 포장주문 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.totalDiscountPackagingPrice) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
          </template>

          <li v-if="totalPayment.point">
            <span class="title"> 포인트 </span>
            <span class="content">
              <span class="price">- {{ $formatPrice(totalPayment.point) }}</span>
              <span class="price_txt">원</span>
            </span>
          </li>
          <li class="active">
            <span class="title"> 결제금액 </span>
            <span class="content">
              <span class="price">{{ $formatPrice(totalPayment.payment) }}</span>
              <span class="price_txt">원</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="payment_address_wrap">
        <div class="delivery_info_wrap" v-if="order.nicePaymentTrResponse && order.nicePaymentTrResponse.payMethod === 'VBANK'">
          <div class="relative">
            <span class="header">입금 계좌</span>
          </div>
          <div class="receive_method">계좌번호: {{ order.nicePaymentTrResponse.vbankName }} {{ order.nicePaymentTrResponse.vbankNum }}</div>
          <div class="receive_method" style="margin-bottom: 15px">
            입금기한: {{ formatDate(order.nicePaymentTrResponse.vbankExpDate) }} {{ formatTime(order.nicePaymentTrResponse.vbankExpTime) }}
          </div>
        </div>
        <span class="header"> {{ order.onSiteYn === "Y" ? "수령방법" : "배송지" }} </span>
        <div class="address_wrap" v-if="order.onSiteYn !== 'Y'">
          <span>{{ delivery.receiverName }}</span>
          <span>{{ delivery.receiverTel }}</span>
          <span class="address">{{ delivery.roadAddressFull }}</span>
          <div class="delivery_info_wrap">
            <span class="title">수령 방법</span>
            <span class="receive_method">{{ delivery.receiveMethod }}</span>
            <div class="request_msg_wrap" v-if="delivery.deliveryRequest">
              {{ delivery.deliveryRequest }}
            </div>
          </div>
        </div>
        <!-- 방문수령 예외처리 -->
        <div class="address_wrap" v-else>
          <div class="delivery_info_wrap type1">
            <div class="receive_method">
              <p>
                먼저 점주님에게 전화하여 놀장 <strong>방문포장 상품이 준비되었는지 확인 후</strong>, 방문하셔서
                <strong>주문번호 4자리를</strong> 알려주세요.
              </p>
            </div>
            <div class="request_msg_wrap" v-if="delivery.deliveryRequest">
              {{ delivery.deliveryRequest }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_btn_wrap" v-if="order.orderType && order.orderType != 'TB'">
        <div class="btn_wrap">
          <span class="title">
            <template v-if="order.hasReview == 'Y'"> 리뷰작성을 완료하였습니다. </template>
            <template v-else-if="order.hasReview == 'N'">
              리뷰작성 가능 기간이
              <span class="red">{{ order.leftReviewTime }}</span> 남았습니다.
            </template>
            <template v-else> 리뷰작성 가능 기간이 지났습니다. </template>
          </span>
          <Btn
            :value="order.hasReview == 'Y' ? '리뷰보기' : order.hasReview == 'N' ? '리뷰쓰기' : '리뷰쓰기 기간만료'"
            :type="order.hasReview == 'X' ? 'type7' : 'type2'"
            @btnCallback="moveToReview"
          />
        </div>
        <div class="btn_wrap">
          <span class="title">
            주문관련 교환 및 환불 문의는<br />
            <span class="red">{{ order.onSiteYn === "Y" ? "수령" : "배달" }} 완료 후 3시간 이내에 가능</span>합니다.
          </span>
          <Btn
            :value="order.hasInquiry == 'Y' ? '주문문의보기' : order.hasInquiry == 'N' ? '주문문의' : '주문문의 기간만료'"
            :type="order.hasInquiry == 'X' ? 'type7' : 'type4'"
            @btnCallback="moveToInquiry"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapGetters, mapState } from "vuex";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import ProductList from "@/components/new/cart/ProductList";
import Btn from "@/components/new/Btn";

export default {
  name: "OrderDetail",
  props: {},
  data: function () {
    return {
      deliveryFee: String,
    };
  },
  components: {
    Btn,
    ProductList,
    LoadingIndicator,
    TopHeader,
  },
  beforeCreate() {
    const orderId = this.$route.params.orderId;
    this.$store.dispatch("orderDetail/initState", orderId);
  },
  mounted() {
    window.onBackPressed = this.goBack;
  },
  methods: {
    getTbDeliveryFee() {
      // console.log(this.order.deliveryFeeMethod !== "CASH_DELIVERY");
      // console.log(this.order.orderType);
      if (this.order.orderType === "TB") {
        var deliveryFee = this.order.tbDeliveryFee;
        // console.log(this.order.deliveryFeeMethod);
        if (this.order.deliveryFeeMethod === "CASH_DELIVERY") {
          deliveryFee = this.order.tbCashDeliveryFee;
        }
        // console.log(deliveryFee);
        if (this.totalPayment.deliveryDiscount == deliveryFee || deliveryFee === 0 || !deliveryFee) {
          deliveryFee = "무료배송";
        }

        this.deliveryFee = deliveryFee;
        return deliveryFee;
      }
    },
    goBack() {
      this.$store.dispatch("orderDetail/reset");
      this.$navigateBack();
    },
    moveToReview() {
      const hasReview = this.order.hasReview;
      if (hasReview == "Y") {
        this.$router.push({
          name: "리뷰쓰기",
          params: {
            orderId: this.order.orderId,
            pageMode: "view",
          },
        });
      } else if (hasReview == "N") {
        if (this.isReviewAvailable()) {
          this.$router.push({
            name: "리뷰쓰기",
            params: {
              orderId: this.order.orderId,
              pageMode: "write",
            },
          });
        } else {
          this.$showToast("리뷰쓰기는 주문일로부터 3일 동안만 가능합니다.");
          this.$store.dispatch("orderDetail/toggleReview");
        }
      } else if (hasReview == "X") {
        return false;
      }
    },
    moveToInquiry() {
      const hasInquiry = this.order.hasInquiry;
      if (hasInquiry == "Y") {
        // if (this.isInquiryAvailable()) {
        this.$router.push({
          name: "상품문의보기",
          params: {
            orderId: this.order.orderId,
            marketId: this.order.marketId,
            pageMode: "view",
          },
        });
        // }
        // else {
        //   this.$showToast('상품문의는 배달완료 후 3시간 이내에 가능합니다.');
        // }
      } else if (hasInquiry == "N") {
        this.$router.push({
          name: "상품문의쓰기",
          params: {
            orderId: this.order.orderId,
            marketId: this.order.marketId,
            pageMode: "write",
          },
        });
      } else if (hasInquiry == "X") {
        return false;
      }
    },
    moveToMarket() {
      this.$router.push({
        name: "시장 메인",
        params: {
          marketId: this.order.marketId,
          marketName: this.order.marketName,
        },
      });
    },
    moveToStore(store) {
      this.$router.push({
        name: "상점 메인",
        params: {
          marketId: this.order.marketId,
          marketName: this.order.marketName,
          storeId: store.storeId,
          storeName: store.storeName,
        },
      });
    },
    // 주문시간으로부터 3일 이내인 경우 true
    isReviewAvailable() {
      return this.$moment(this.order.orderDateTime).add(3, "d").isAfter(this.$moment());
    },
    copyInvoice(event) {
      // console.log(event);
      var _this = this;
      this.$copyText(this.order.invoiceNo).then(function () {
        // console.log("order.invoiceNo ::::" + _this.order.invoiceNo);
        _this.$callNative("showToast", { message: "송장번호를 복사하였습니다." });
      });
    },
    moveToTracking() {
      this.$navigateTo("open-url", {
        title: "배송조회",
        url: "https://m.search.naver.com/search.naver?sm=mtp_sug.top&where=m&query=%EB%B0%B0%EC%86%A1%EC%A1%B0%ED%9A%8C&acq=%EB%B0%B0%EC%86%A1&acr=1&qdt=0",
      });
    },
    formatDate(date) {
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6);

      return `${year}년 ${month}월 ${day}일`;
    },
    formatTime(time) {
      const hour = time.slice(0, 2);
      const minute = time.slice(2, 4);
      const second = time.slice(4);

      return `${hour}:${minute}:${second}까지`;
    },
  },
  computed: {
    ...mapState({
      title: (state) => state.orderDetail.title,
      isProgressing: (state) => state.orderDetail.isProgressing,
      order: (state) => state.orderDetail.order,
    }),
    ...mapGetters({
      delivery: "orderDetail/delivery",
      totalPayment: "orderDetail/totalPayment",
    }),
    isRefundedOrder: function () {
      // 주문상태가 '부분환불' 또는 '환불' 인 경우 true 반환, 그렇지 않은 경우 false 반환
      return this.paymentStatus === "C" || this.paymentStatus === "PC";
    },
    isCanceledOrder: function () {
      // 주문상태가 '부분환불' 또는 '환불' 이면서 환불/거부 사유가 있는 경우 true 반환, 그렇지 않은 경우 false 반환
      const cancelReason = this.order.cancelReason;
      return this.isRefundedOrder && cancelReason;
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  .deferred_payment_info {
    display: block;
    @include font(12, 12, 0);
    //@include font(12, 12, 0, medium);
    color: $active-color;
    text-align: right;
  }
  .cash_delivery {
    display: inline-block;
    height: auto !important;
    padding: 1px 7px 0px;
    margin: -4px 5px 0;
    background-color: $active-color;
    @include border-radius(6px);
    @include font(13, 20);
    color: white;
    vertical-align: middle;
  }

  .order_refund_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    //height: 60px;
    background-color: #f3f3f3;

    .order_refund_msg_title {
      color: #fc2368;
      @include font(16, 24, normal, bold);
    }

    .order_refund_msg {
      padding: 10px 0;
      margin: 2px 0 0;
      @include font(14, 24, normal, bold);
      color: $active-color;
      word-break: break-word;
    }
  }

  .order_info_wrap {
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    border: {
      width: 0 0 1px 0;
      style: solid;
      color: $border-type2;
    }

    .order_title_wrap {
      display: flex;
      //align-items: center;

      .order_title {
        flex: 1;
        margin: 0 10px 0 0;
        @include font(16, 16, normal, bold);

        .red {
          @include font(16, 16, normal, bold);
          color: $active-color;
        }
      }

      .order_status {
        @include font(14, 16, normal, bold);
        color: $active-color;
      }
    }

    .market_name_wrap {
      display: flex;
      //align-items: center;
      align-items: flex-start;
      margin: 12px 0 0 0;
      color: $gray;

      .market_name {
        margin: 0 10px 0 0;
        @include font(13, 13, normal, bold);
      }
    }

    .order_detail_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 33px;
      margin: 12px 0 10px 0;

      > span,
      .invoice_number {
        @include font(12, 16, -0.48);
        color: $gray-light;
      }

      .invoice_number {
        position: relative;
        padding: 0 130px 0 0;
        word-break: break-all;
      }
    }

    @media (max-width: 280px) {
      .order_detail_wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 33px;
        margin: 12px 0 25px 0;
      }
    }

    .invoice_wrap {
      position: absolute;
      top: 0;
      right: 0;
      .invoice {
        float: left;
        width: 60px;
        &.tracking {
          margin: 0 0 0 5px;
        }
      }
    }

    //@media (max-width: 280px) {
    //  position: absolute;
    //  top: 180px;
    //  right: 10px;
    //  .invoice {
    //    //position: absolute;
    //    //top: 160px;
    //    float: left;
    //    width: 60px;
    //    &.tracking {
    //      margin: 0 0 0 5px;
    //    }
    //  }
    //}
  }

  .store_list_wrap {
    .store_list::v-deep {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }

      &:last-child {
        border: none;
      }

      //.store_name_wrap {
      //  display: flex;
      //  align-items: flex-start;
      //  padding: 20px 0 0 0;
      //
      //  > .store_name {
      //    @include font(13, 13, normal, bold);
      //    color: $gray;
      //  }
      //
      //  > .icon_more_arrow {
      //    margin: 0 0 0 10px;
      //  }
      //}

      .cart_product_container:last-child {
        .product_wrap {
          border: none;
        }
      }
    }
  }

  .payment_detail_wrap {
    .payment_detail_list {
      padding: 0 16px;
      border: {
        width: 1px 0;
        style: solid;
        color: $border-type2;
      }
      background-color: $background-color-type2;
      align-items: center;

      > li {
        display: flex;
        height: 16px;
        margin: 0 0 20px 0;
        align-items: center;

        &.delivery_tb {
          height: auto;
          align-items: flex-start;
          .title {
            padding: 5px 0 0;
          }
          .content {
            flex-wrap: wrap;
          }
          .content_flex {
            flex: 1 0 100%;
            text-align: right;
          }
        }

        &:first-child {
          height: 46px;
          padding: 30px 0 0 0;
        }

        &.active {
          height: 62px;
          padding: 20px 0;
          margin: 0;
          border: {
            width: 1px 0 0 0;
            style: solid;
            color: $border-type2;
          }

          .title {
            @include font(16, normal, -0.64, bold);
            color: $active-color;
          }

          .content {
            height: 22px;

            .price {
              height: 22px;
              font-family: gothic_a1_bold;
              font-size: 22px;
            }

            .price_txt {
              @include font(19);
            }
          }
        }

        span {
          height: 16px;
        }

        .title {
          @include font(14, normal, normal, bold);
        }

        .content {
          flex: 1;
          display: flex;
          align-items: baseline;
          justify-content: flex-end;

          .price {
            font-family: gothic_a1_bold;
            font-size: 16px;
            line-height: 1;
          }

          .price_txt {
            @include font(15);
          }
        }
      }
    }
  }

  .payment_address_wrap {
    padding: 20px 16px;

    .header {
      @include font(16, 18, -0.64, bold);
    }

    .address_wrap {
      > span {
        display: block;
        margin-top: 10px;
        @include font(14, 14, normal, bold);

        &.address {
          @include font(14, 20, normal, bold);
        }
      }

      .delivery_info_wrap {
        display: flex;
        flex-direction: column;
        margin: 20px 0 0;
        &.type1 {
          margin: 10px 0 0;
        }

        > span {
          @include font(14, 18, normal, bold);

          &.title {
            color: $gray-normal;
          }

          &.receive_method {
            margin: 5px 0 0;
          }
        }

        .request_msg_wrap {
          width: 100%;
          margin: 10px 0 0;
          padding: 14px 20px;
          border: {
            width: 1px;
            style: solid;
            color: $border-type2;
          }
          @include border-radius(15px);
          @include font(14, 18);
          //color: $gray-normal;
          resize: none;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .bottom_btn_wrap {
    display: flex;
    flex-direction: column;
    padding: 10px 0 0 0;
    background-color: $background-color-type1;

    .btn_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      background-color: $white;

      &:first-child {
        border: {
          width: 0 0 1px 0;
          style: solid;
          color: $border-type2;
        }
      }

      .title {
        text-align: center;
        @include font(16, 22, -0.64);
        color: $gray;

        > .red {
          @include font(16, 22, -0.64);
          color: $active-color;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        @include font(16);

        &.type4 {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
