<template>
  <div class="notice_item">
    <div class="notice_main" @click="toggle">
      <div class="notice_title">{{ title }}</div>
      <div class="notice_date">
        {{ $moment(registerDateTime).format("YYYY.MM.DD") }}
      </div>
    </div>
    <transition name="slide">
      <div class="notice_detail" v-if="isToggle">
        <div class="title">{{ title }}</div>
        <div class="content">{{ content }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "NoticeItem",
  props: {
    id: Number,
    title: String,
    registerDateTime: String,
    content: String,
  },
  data: function () {
    return {
      isToggle: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    toggle: function () {
      this.isToggle = !this.isToggle;
    },
  },
  created: function () {},
};
</script>

<style lang="scss" scoped>
.notice_item {
  padding-top: 20px;
}

.notice_item > .notice_main {
  padding-bottom: 20px;
  border-bottom: 1px solid $nj_divider_gray;
}

.notice_item > .notice_main > * {
  display: inline-block;
}

.notice_item .notice_title {
  font: normal normal normal 18px/22px Noto Sans CJK KR Medium;
  letter-spacing: -1.08px;
  width: calc(100% - 80px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notice_item .notice_date {
  float: right;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
  color: $nj_text_main_gray;
}

.notice_item .notice_detail {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $nj_divider_gray;
  letter-spacing: -0.84px;
}

.notice_item .notice_detail .title {
  font: normal normal normal 16px/20px Noto Sans CJK KR Medium;
  margin-bottom: 15px;
}

.notice_item .notice_detail .content {
  font: normal normal normal 14px/20px Noto Sans CJK KR;
  color: $nj_text_main_gray;
}
</style>
