import router from "@/router";
const state = {
  touch: {
    sx: null,
    sy: null,
    st: null,
    ex: null,
    ey: null,
    et: null,
    touchSensitive: 5,
    touchDirection: null,
    isIgnoreTouchX: null,
    reloadProcess: false,
  },
  previousRoutePath: null,
  userId: null,
  categoryId: 0,
  specialOffer: "",
  latitude: null,
  longitude: null,
  isReloading: false,
  isInitStateProgressing: false,
  isProgressing: true,
  showAllmenu: false,
  allSpecialOfferItems: [{}],
  contentsType: "home", // direct: 단골시장, home: 농산, livestock: 축산, brand: 친환경, sea: 수산, n-mart: N마트, event: 이벤트
  slideActiveIndex: 1,
  topMenuItems: [
    {
      dataType: "direct",
      text: "단골시장",
      isActive: false,
    },

    {
      dataType: "home",
      text: "농산",
      isActive: true,
    },
    {
      dataType: "livestock",
      text: "축산",
      isActive: false,
    },
    {
      // 240529 브랜드몰 -> 친환경
      dataType: "brand",
      text: "친환경",
      isActive: false,
    },
    {
      dataType: "sea",
      text: "수산",
      isActive: false,
    },
    // 240613 n-mark 추가
    {
      dataType: "n-mart",
      text: "N마트",
      isActive: false,
    },
    {
      dataType: "event",
      text: "이벤트",
      isActive: false,
    },
    //  기존에 탭이었으나 제거된 것들.
    // {
    //   dataType: "specialOffer",
    //   text: "특가",
    //   isActive: false,
    // },
    // 240624 산지직송 제거
    // {
    //   dataType: "immediateDelivery",
    //   text: "산지직송",
    //   isActive: false,
    // },
  ],
  eventContentsType: "event",
  eventTabItems: [
    {
      dataType: "event",
      text: "이벤트",
      isActive: true,
    },
    {
      dataType: "notice",
      text: "공지사항",
      isActive: false,
    },
  ],
  topBanner: null,
  topBannerHeight: null,
  banners: [],
  directMarkets: [
    // 바로배달
  ],
  deliveryMarketsLocal: [
    // 전국배송 지역별
  ],
  deliveryMarkets: [
    // 전국배송 시장별
  ],
  // 전국배송 상품별
  deliveryMarketsProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  deliveryFdStoreList: [
    // 음식배달(1:1배달)
  ],
  favoritesStore: [
    // 단골 상점
  ],
  recentBuyProducts: [
    // 최근 구매했어요
  ],
  eventList: {},
  backKeyBoolean: false,
  deliveryMarketsType: "products",
  deliveryMarketsLocalTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },
  deliveryMarketsProductsLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },
  deliveryMarketsProductsMediumTab: {
    tabItems: [],
    tabActiveIndex: 0,
    isMediumTabVisible: false,
  },

  mainTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  mainTabLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },

  seaTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  seaTabLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },

  // 산지직송 immediateDelivery
  immediateDeliveryTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },

  // 축산
  livestockTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  livestockTabLargeTab: {
    tabItems: [
      {
        categoryId: "0",
        categoryName: "전체",
        categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
      },
      {
        categoryId: "30",
        categoryName: "돼지고기",
        categoryImg: null,
      },
      {
        categoryId: "31",
        categoryName: "소고기",
        categoryImg: null,
      },
      {
        categoryId: "99",
        categoryName: "기타",
        categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
      },
    ],
    tabActiveIndex: 0,
  },

  // 마트 상품 상태
  nMartTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  // 마트 대분류 상태
  nMartTabLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },

  specialTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },

  specialTabLargeTab: {
    tabItems: [
      {
        dataType: "all",
        categoryId: "0",
        categoryName: "전체",
        isActive: false,
        tabActiveIndex: 0,
        categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
      },
      {
        dataType: "tuesday",
        categoryId: "1",
        categoryName: "화요일",
        isActive: false,
        tabActiveIndex: 0,
        categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
      },
      {
        dataType: "thursday",
        categoryId: "2",
        categoryName: "목요일",
        isActive: false,
        tabActiveIndex: 0,
        categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
      },
    ],
    tabActiveIndex: 0,
  },

  brandTabProducts: {
    list: [],
    currentPage: 0,
    isLastPage: false,
  },
  brandTabLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },
};

const mutations = {
  setTouch: function (state, { event, type }) {
    const touchEvent = event.changedTouches ? event.changedTouches[0] : event;
    if (type === "start") {
      state.touch.sx = touchEvent.clientX;
      state.touch.sy = touchEvent.clientY;
      state.touch.st = Date.now();
    } else {
      state.touch.ex = touchEvent.clientX;
      state.touch.ey = touchEvent.clientY;
      state.touch.et = Date.now();
    }
  },
  setTabBarOptions: function (state, dataType) {
    const index = state.topMenuItems.findIndex((item) => {
      return item.dataType === dataType;
    });

    state.topMenuItems.forEach((tabItem) => {
      if (dataType === tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });

    const tabWrapEl = document.querySelectorAll(".tab-wrap")[0];
    let left = 15;
    for (let i = 0; i < index; i++) {
      const tabEl = tabWrapEl.children[0].children[i];
      left += tabEl.clientWidth;
    }
    let scrollLeft = 15;
    for (let i = 0; i <= index; i++) {
      scrollLeft +=
        i === index ? tabWrapEl.children[0].children[i].clientWidth / 2 - window.innerWidth / 2 : tabWrapEl.children[0].children[i].clientWidth;
    }
    scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
    tabWrapEl.scrollTo({ behavior: "smooth", left: scrollLeft });
  },
  setPreviousRoutePath: function (state, previousRoutePath) {
    state.previousRoutePath = previousRoutePath;
  },
  closeAllMenu: function (state) {
    state.showAllmenu = false;
  },
  setAllmenu: function (state, event) {
    if (event) {
      if (event.target) {
        if (event.target.classList.contains("icon_close_white")) {
          state.showAllmenu = false;
        } else if (event.target.classList.contains("all_menu_container")) {
          state.showAllmenu = false;
        } else if (event.target.classList.contains("item")) {
          state.showAllmenu = false;
        } else if (event.target.classList.contains("all_menu_bg")) {
          state.showAllmenu = false;
        }
        // user_point
        // qr_pay
        // icon_mypage_setting_white
        return;
      }
    }
    // setAllMenu
    state.showAllmenu = !state.showAllmenu;
  },
  updateState: function (state, data) {
    state[data.type] = data.updateData;
    // console.log('updateState :::::::::::: ');
    // console.log(state);
  },
  setRecentBuyProducts: function (state, data) {
    state["recentBuyProducts"] = data;
  },
  setFavoritesStore: function (state, data) {
    state["favoritesStore"] = data;
  },
  setBanners: function (state, data) {
    state["banners"] = data;
    // state['eventList'] = data;
  },
  setDeliveryMarket: function (state, data) {
    var deliveryMarkets = [];
    if (data.length > 0) {
      data.forEach((market) => {
        deliveryMarkets.push({
          marketId: market.marketId,
          marketName: market.marketNm,
          marketFullyAddress: market.marketFullAddress,
          recentBuyProducts: market.productList,
        });
      });
    }
    state["deliveryMarkets"] = deliveryMarkets;
  },
  setDirectMarket: function (state, data) {
    // 바로배달 데이터 정의
    var directMarkets = [];
    if (data.length > 0) {
      data.forEach((market) => {
        var categoryTabItems = [];
        var contentsType = "";
        if (market.hotSaleProductList.length > 0) {
          categoryTabItems.push({
            dataType: "hotSaleProductList",
            text: "핫세일",
            isActive: false,
          });
        }
        if (market.newMerchandiseProductList.length > 0) {
          categoryTabItems.push({
            dataType: "newMerchandiseProductList",
            text: "새로 들어왔어요",
            isActive: false,
          });
        }
        if (market.marketEventList.length > 0) {
          categoryTabItems.push({
            dataType: "marketEventList",
            text: "이벤트",
            isActive: false,
          });
        }
        if (categoryTabItems.length > 0) {
          categoryTabItems[0].isActive = true;
          contentsType = categoryTabItems[0].dataType;
        }

        directMarkets.push({
          marketId: market.marketId,
          marketName: market.marketName,
          marketFullyAddress: market.marketFullyAddress,
          marketIntro: market.marketIntro,
          distance: market.distance,
          contentsType: contentsType,
          categoryTabItems: categoryTabItems,
          hotSaleProductList: market.hotSaleProductList,
          newMerchandiseProductList: market.newMerchandiseProductList,
          marketEventList: market.marketEventList.reverse().filter((item, index) => index < 3),
          mapServiceYn: market.mapServiceYn,
          recentBuyProducts: market.recentBuyProducts,
        });
      });
    }

    state["directMarkets"] = directMarkets;
  },
  // 이벤트
  changeEventTabItem: function (state, data) {
    state.eventList = null;

    const dataType = data.dataType;
    state.eventTabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });
    state.eventContentsType = dataType;

    this.dispatch("main/getEventList", dataType);
  },
  moveToNativeSearch: function () {
    this._vm.$navigateTo("main-search");
  },
  moveToCart: function () {
    router.push({ name: "장바구니", params: { reload: true } });
  },
  moveToAddress: function (state) {
    router.push({ name: "배송지설정", params: { userId: state.userId } });
  },
  moveToFavoritesStore: function (state) {
    router.push({ name: "단골상점", params: { userId: state.userId } });
  },
  moveToEvent: function (state, data) {
    const nodeEnv = process.env.NODE_ENV;
    if (nodeEnv === "production" && data.noticeId === "2596") {
      router.push({ name: "상품 메인", params: { productId: 93899 } });
      return;
    }
    if (nodeEnv !== "production" && data.noticeId === "2590") {
      router.push({ name: "상품 메인", params: { productId: 93377 } });
      return;
    }
    this._vm.$navigateTo("open-url", {
      title: data.title,
      url: data.detailUrl,
    });
  },
  moveToMarketMap: function (state, data) {
    if (data.currentTarget) {
      if (data.currentTarget.classList.contains("none_underline")) {
        this._vm.$showToast("지도 준비중입니다.");
      } else {
        this._vm.$navigateTo("market-map", { marketId: data.marketId });
      }
    } else {
      if (data.mapServiceYn == "N") {
        this._vm.$showToast("지도 준비중입니다.");
      } else {
        this._vm.$navigateTo("market-map", { marketId: data.marketId });
      }
    }
    // this._vm.$callNative('market-map', data);
  },
  moveToMarket: function (state, event) {
    // router.push('/market-main');// path
    router.push({
      name: "시장 메인",
      params: {
        marketId: event.currentTarget.dataset.marketId,
        marketName: event.currentTarget.dataset.marketName,
      },
    });
  },
  clickCategory: function (state, data) {
    // 바로배달에서 탭 클릭
    const marketId = data.data.marketId;
    state.directMarkets.forEach((market) => {
      if (market.marketId === marketId) {
        market.contentsType = data.dataType;
        market.categoryTabItems.forEach((tabItem) => {
          tabItem.isActive = false;
        });
      }
    });
    data.isActive = true;
  },
  setSlideActiveIndex: function (state, index) {
    state["slideActiveIndex"] = index;
  },
  updateContentsType: function (state, data) {
    // 상단 탭 메뉴 클릭
    state["contentsType"] = data.dataType;
  },
  setContentsType: async function (state, data) {
    // 상단 탭 메뉴 클릭
    const dataType = data.dataType;

    if (data.dataType === "specialOffer") {
      state.contentsType = dataType;
      //this.$store.commit("setContentsType", data);
      //await this.$store.dispatch("getEventList");
      //await this.dispatch("main/allSpecialOffers");
      await this.dispatch("main/setSpecialOffers");
      //await router.push({name:'specialOffers'})
      // await this.dispatch("main/setSpecialOffers");
      //await this.dispatch("main/setSpecialOffers");
    }
    if (state.contentsType !== dataType) {
      this.commit("main/initHtmlScrollTop");
      state.contentsType = dataType;
      this.commit("main/setTabBarOptions", dataType);
    }

    state.topMenuItems.forEach((tabItem) => {
      tabItem.isActive = dataType === tabItem.dataType;
    });

    if (dataType === "home") {
      if (data.buttonType === "back") {
        return;
      }
      this.dispatch("main/setMainTabType");
    }

    if (dataType === "sea") {
      this.dispatch("main/setSeaTabType");
    }

    if (dataType === "brand") {
      this.dispatch("main/setBrandTabType");
    }

    if (dataType === "specialOffer") {
      this.dispatch("main/setSpecialOffers");
    }

    // 탭 클릭시 데이터 호출 n-mart
    if (dataType === "n-mart") {
      this.dispatch("main/setNMartTabType");
    }

    // 산지직송 immediateDeliveryTabProducts
    if (dataType === "immediateDelivery") {
      this.dispatch("main/setImmediateDeliveryTabType");
    }

    // 탭 클릭시 데이터 호출 livestock
    if (dataType === "livestock") {
      this.dispatch("main/setLivestockTabType");
    }
  },

  initHtmlScrollTop: function (state) {
    // const html = document.querySelector("html");
    // const htmlScrollTop = html.scrollTop;
    // if (state.topBannerHeight < htmlScrollTop) {
    //   html.scrollTop = state.topBannerHeight;
    // }
  },
  initState: function (state, data) {
    // state['markets'] = data.updateData.markets;
  },
  backKey: function (state) {
    if (state.contentsType == "home") {
      if (!state.backKeyBoolean) {
        state.backKeyBoolean = true;
        this._vm.$callNative("showToast", {
          message: '"뒤로" 버튼을 한 번 더 누르시면 종료됩니다.',
        });
        setTimeout(function () {
          state.backKeyBoolean = false;
        }, 1200);
      } else {
        // this._vm.$callNative('showToast', {message: '종료!!!'});
        this._vm.$callNative("exit", { mustFileClear: false });
        // native 종료 프로세스 호출 //////////////////////////////////////////////////////////////////////////
      }
    }
  },
  reset: function (state) {
    state.contentsType = "home";
    state.topMenuItems.forEach((tabItem) => {
      if (state.contentsType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });
    state.directMarkets.forEach((market) => {
      market.categoryTabItems.forEach((tabItem, index) => {
        if (index == 0) {
          tabItem.isActive = true;
          market.contentsType = tabItem.dataType;
        } else {
          tabItem.isActive = false;
        }
      });
    });

    state = {
      userId: null,
      isProgressing: false,
      contentsType: "home", // sea : 수산시장, home : 농축산시장, direct : 전통시장, brand : 브랜드몰, event : 이벤트
      slideActiveIndex: 0,
      topMenuItems: [
        {
          dataType: "direct",
          text: "단골시장",
          isActive: false,
        },
        {
          dataType: "home",
          text: "농산",
          isActive: true,
        },
        {
          // 240529 브랜드몰 -> 친환경
          dataType: "brand",
          text: "친환경",
          isActive: false,
        },
        {
          dataType: "sea",
          text: "수산",
          isActive: false,
        },
        {
          dataType: "specialOffer",
          text: "특가",
          isActive: false,
        },
        {
          dataType: "event",
          text: "이벤트·정보",
          isActive: false,
        },
      ],
      eventContentsType: "event",
      eventTabItems: [
        {
          dataType: "event",
          text: "이벤트",
          isActive: true,
        },
        {
          dataType: "notice",
          text: "공지사항",
          isActive: false,
        },
        // {
        //   dataType: "how",
        //   text: "사용법",
        //   isActive: false,
        // },
      ],
      banners: [],
      directMarkets: [
        // 바로배달
      ],
      deliveryMarkets: [
        // 전국배송
      ],
      favoritesStore: [
        // 단골 상점
      ],
      recentBuyProducts: [
        // 최근 구매했어요
      ],
      eventList: {},
    };
  },

  showPopup: function (state, data) {
    if (data.length == 0) {
      console.log("시장에 등록된 배너가 없음 :::");
      return;
    }

    localStorage.setItem("isNoljangPopup", true);

    const _this = this;
    const modalName = "showPopup";
    const compProps = {
      type: "image",
      items: data,
      // marketId: state.marketId,
      closeModal: function () {
        // X 버튼 클릭시
        const checkedMarket = document.querySelector("#closeTodayPopup:checked");
        if (checkedMarket) {
          const date = new Date();
          localStorage.setItem("noljangPopup", date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());
        }
        // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
        _this._vm.$modal.hideAll();
      },
      onCartChanged: (cart) => {
        _this.commit("productOptions/onCartChanged", cart);
      },
    };

    const width = (screen.width > 500 ? 500 : screen.width) - 30;
    const left = ((screen.width > 500 ? 500 : screen.width) - width - 30) / 2;

    this.dispatch(
      "showModal",
      {
        name: modalName,
        compProps: compProps,
        modalProps: {
          width: width,
          height: "auto",
          maxWidth: 400,
          adaptive: true,
          style: {
            left: left + "px",
          },
        },
      },
      { root: true },
    );
  },
  updateZipCode: function (state, zipCode) {
    console.log("updateZipCode");

    // this.commit()
    this.commit("updateState", { type: "zipCode", updateData: zipCode });
  },
};

const actions = {
  onBackPressedEvent: function (store, goBack) {
    if (state.contentsType === "brand") {
      if (state.deliveryMarketsProductsLargeTab.tabActiveIndex === 0) {
        goBack();
      } else {
        if (state.deliveryMarketsProductsMediumTab.isMediumTabVisible) {
          store.dispatch("clickDeliveryMarketsProductsMediumTabBackArrow");
        } else {
          store.dispatch("switchDeliveryMarketsProductsLargeTabActiveIndex", 0);
        }
      }
    } else {
      goBack();
    }
  },
  setDeliveryMarketsTabFixed: function () {
    const htmlScrollTop = document.querySelector("html").scrollTop;
    const bannerWrapEl = document.querySelector(".banner_wrap");
    const bannerWrapElHeight = bannerWrapEl.clientHeight;
    const mainContentTitleEl = document.querySelector(".main_contents_title");
    const mainContentTitleElHeight = mainContentTitleEl?.clientHeight;
    const haeNamEventStrapBannerEl = document.querySelector("#haeNamEventStrapBanner");
    const haeNamEventStrapBannerElHeight = haeNamEventStrapBannerEl?.clientHeight;
    const deliveryMarketsWrapEl = document.querySelector(".deliveryMarkets_wrap");
    const fixedScrollTop = state.topBannerHeight + bannerWrapElHeight + mainContentTitleElHeight + haeNamEventStrapBannerElHeight;
    if (state.contentsType === "brand") {
      if (htmlScrollTop >= fixedScrollTop) {
        deliveryMarketsWrapEl?.classList.add("fixed");
        if (state.deliveryMarketsType === "products") {
          deliveryMarketsWrapEl?.style.setProperty("padding-top", 92 + "px");
        } else if (state.deliveryMarketsType === "markets") {
          deliveryMarketsWrapEl?.style.setProperty("padding-top", 42 + "px");
        }
      } else {
        deliveryMarketsWrapEl?.classList.remove("fixed");
        deliveryMarketsWrapEl?.style.setProperty("padding-top", 0 + "px");
      }
    } else {
      deliveryMarketsWrapEl?.classList.remove("fixed");
    }
  },
  setDeliveryMarketsTabScrollTop: function () {
    const html = document.querySelector("html");
    const bannerWrapEl = document.querySelector(".banner_wrap");
    const bannerWrapElHeight = bannerWrapEl.clientHeight;
    const mainContentTitleEl = document.querySelector(".main_contents_title");
    const mainContentTitleElHeight = mainContentTitleEl?.clientHeight;
    const haeNamEventStrapBannerEl = document.querySelector("#haeNamEventStrapBanner");
    const haeNamEventStrapBannerElHeight = haeNamEventStrapBannerEl?.clientHeight;
    const deliveryMarketsWrapEl = document.querySelector(".deliveryMarkets_wrap");
    const isFixedClassExist = deliveryMarketsWrapEl.classList.contains("fixed");
    if (isFixedClassExist) {
      const scrollTop = state.topBannerHeight + bannerWrapElHeight + mainContentTitleElHeight + haeNamEventStrapBannerElHeight;
      html.scrollTo({ behavior: "auto", top: scrollTop + 1 });
    }
  },
  mainWrapTouchStart: function (store, event) {
    const html = document.querySelector("html");
    const htmlScrollTop = html.scrollTop;
    const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
    state.touch = {
      sx: null,
      sy: null,
      st: null,
      ex: null,
      ey: null,
      et: null,
      touchSensitive: 5,
      touchDirection: null,
      isIgnoreTouchX: null,
      reloadProcess: false,
    };
    const isTargetContainsAcceptTouchX = event.target.closest(".accept_touch_x");
    const isTargetContainsIgnoreTouchX = event.target.closest(".ignore_touch_x");
    state.touch.isIgnoreTouchX = !isTargetContainsAcceptTouchX && isTargetContainsIgnoreTouchX?.clientWidth === window.innerWidth;
    if (!state.isReloading) {
      html.style.setProperty("overflow-y", "scroll");
      reloadScrollWrapEl.scrollTo({ top: 120 });
      reloadScrollWrapEl.style.setProperty("display", "none");
      // 첫 터치 시작점 위치가 htmlScrollTop === 0 일 때만 새로고침 기능 사용가능
      state.touch.reloadProcess = htmlScrollTop === 0 && true;
    }
    store.commit("setTouch", { event: event, type: "start" });
  },
  mainWrapTouchMove: function (store, event) {
    store.commit("setTouch", { event: event, type: "move" });
    const dx = state.touch.sx - state.touch.ex;
    const dy = state.touch.ey - state.touch.sy;
    const vMove = Math.abs(dx) / Math.abs(dy) < 0.577; // 수직움직임 여부 tan30deg = 0.577...
    const hMove = Math.abs(dy) / Math.abs(dx) < 0.577; // 수평움직임 여부
    // 사용자의 화면 컨트롤 수직, 수평 여부
    if (state.touch.touchDirection === null && (Math.abs(dx) > state.touch.touchSensitive || Math.abs(dy) > state.touch.touchSensitive)) {
      if (vMove) {
        state.touch.touchDirection = "vertical";
      }
      if (hMove) {
        state.touch.touchDirection = "horizon";
      }
    }
    // 사용자가 화면을 수직 컨트롤 할 때
    if (state.touch.touchDirection === "vertical" && !state.isInitStateProgressing && state.touch.reloadProcess) {
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      const reloadIconEl = document.querySelector(".reload_icon");
      const html = document.querySelector("html");
      if (dy >= 0) {
        html.style.overflowY === "scroll" && html.style.setProperty("overflow-y", "hidden");
        reloadScrollWrapEl.style.display === "none" && reloadScrollWrapEl.style.setProperty("display", "inline-block");
        if (dy <= 118) {
          reloadScrollWrapEl.scrollTo({ top: 120 - dy });
          reloadIconEl.style.setProperty("transform", `rotate(${dy * 3}deg)`);
          if (dy <= 80) {
            (reloadIconEl.style.opacity === "1" || reloadIconEl.style.opacity === "") && reloadIconEl.style.setProperty("opacity", 0.3);
          } else {
            reloadIconEl.style.opacity === "0.3" && reloadIconEl.style.setProperty("opacity", 1);
          }
        } else {
          reloadScrollWrapEl.scrollTop !== 0 && reloadScrollWrapEl.scrollTo({ top: 2 });
        }
      } else {
        reloadScrollWrapEl.scrollTop !== 120 && reloadScrollWrapEl.scrollTo({ top: 120 });
        html.style.overflowY === "hidden" && html.style.setProperty("overflow-y", "scroll");
        reloadScrollWrapEl.style.display === "inline-block" && reloadScrollWrapEl.style.setProperty("display", "none");
        state.touch.reloadProcess = false;
      }
    }
  },
  mainWrapTouchEnd: function (store, event) {
    store.commit("setTouch", { event: event, type: "move" });
    // 사용자가 화면을 수직 컨트롤 할 때
    if (state.touch.touchDirection === "vertical" && !state.isInitStateProgressing && state.touch.reloadProcess) {
      const html = document.querySelector("html");
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      html.style.setProperty("overflow-y", "scroll");
      const dy = state.touch.ey - state.touch.sy;
      const isReloading = dy > 80;
      if (isReloading) {
        state.previousRoutePath = "/";
        reloadScrollWrapEl.scrollTo({ top: 40, behavior: "smooth" });
        const isReload = true;
        store.dispatch("initState", { isReload });
      } else {
        reloadScrollWrapEl.scrollTo({ top: 120, behavior: "smooth" });
      }
    }
    // 사용자가 화면을 수평 컨트롤 할 때
    if (state.touch.touchDirection === "horizon" && !state.isInitStateProgressing && !state.touch.isIgnoreTouchX) {
      const dx = state.touch.ex - state.touch.sx;
      const dt = state.touch.et - state.touch.st;
      if (dt < 300 && Math.abs(dx) > 30) {
        const dx = state.touch.sx - state.touch.ex;
        const xSign = Math.sign(dx); // x 부호
        const activePageIndex = state.topMenuItems.findIndex((item) => {
          return item.dataType === state.contentsType;
        });
        let switchPageIndex = activePageIndex + xSign;
        const minPageIndex = 0;
        const maxPageIndex = state.topMenuItems.length - 1;
        switchPageIndex = switchPageIndex < minPageIndex ? maxPageIndex : switchPageIndex > maxPageIndex ? minPageIndex : switchPageIndex;
        const switchPageDataType = state.topMenuItems[switchPageIndex].dataType;
        activePageIndex !== switchPageIndex && store.dispatch("setContentsType", { dataType: switchPageDataType });
      }
    }
    state.touch = {
      sx: null,
      sy: null,
      st: null,
      ex: null,
      ey: null,
      et: null,
      touchSensitive: 5,
      touchDirection: null,
      isIgnoreTouchX: null,
      reloadProcess: false,
    };

    state.isProgressing = false;
  },
  initState: async function (store, data) {
    const startTime = new Date().getTime();

    state.isProgressing = true;
    state.isReloading = data.isReload && true;
    state.isInitStateProgressing = false;

    let userAddress = JSON.parse(localStorage.getItem("userAddress"));
    state.latitude = userAddress ? userAddress.latitude : null;
    state.longitude = userAddress ? userAddress.longitude : null;

    const date = new Date();
    const noljangPopupDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    const apiList = [
      // 메인 탑 배너 불러오기
      store.dispatch("setTopBanner"),
      // 메인_홈(탭)_단골상점 상품 리스트 불러오기
      state.userId ? store.dispatch("setFavoritesStore") : undefined,
      // 메인_홈(탭)_최근에 구매했어요 상품 리스트 불러오기
      state.userId ? store.dispatch("setRecentBuyProducts") : undefined,
    ];

    if (userAddress !== null) {
      apiList.push(store.dispatch("setDirectMarkets"));
    }

    // 배열 속 비동기 작업이 동시에 진행 된다. 모든 axios 작업이 끝난 후 아래코드 동기적으로 실행
    await Promise.all(apiList);

    if (data.tabType) {
      switch (data.tabType) {
        case "수산":
          await store.commit("setContentsType", { dataType: "sea" });
          break;
        case "농산":
          await store.commit("setContentsType", { dataType: "home" });
          break;
      }
    }

    // else if (state.mainTabProducts.list.length === 0) {
    //   await this.dispatch("main/setMainTabType");
    // }

    // 팝업관련 로컬스토레이지 세팅
    if (
      (!store.rootState.appVersion && localStorage.getItem("noljangPopup") !== noljangPopupDate) ||
      (localStorage.getItem("noljangPopup") !== noljangPopupDate && !localStorage.getItem("isNoljangPopup"))
    ) {
      localStorage.removeItem("noljangPopup");

      // 팝업 배너 불러오기
      await store.dispatch("setPopup");
    }

    if (data.firstInit === "Y") {
      await state["directMarkets"].forEach((market) => {
        localStorage.removeItem("isMarketPopup_" + market.marketId);
      });
      await state["deliveryMarkets"].forEach((market) => {
        localStorage.removeItem("isMarketPopup_" + market.marketId);
      });
    }

    // 새로고침 작업 이후 작업
    if (data.isReload) {
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      //reloadScrollWrapEl.scrollTo({ top: 120 });
      reloadScrollWrapEl.style.setProperty("display", "none");
    }

    state.isProgressing = false;
    state.isInitStateProgressing = false;
    state.isReloading = data.isReload && false;

    const endTime = new Date().getTime();
    const diffTime = ((endTime - startTime) / 1000).toFixed(2);
    // console.log(`초기세팅이 ${diffTime}초 걸렸습니다.`);
  },
  // 메인 탑 배너 불러오기
  setTopBanner: async function (store) {
    try {
      const bannerUrl = "/v3/notices/member-notices/all-markets/banners?size=30&userId=" + state.userId;
      const response = await this._vm.$axios2.get(bannerUrl);
      if (response.data.responseCode == 2000) {
        const result = response.data.result;
        if (result.billboardBanners) {
          const updateData = {
            type: "banners",
            updateData: result.billboardBanners,
          };
          store.commit("updateState", updateData);
        }
        if (result.homeTopBannerImg) {
          const topBannerData = {
            type: "topBanner",
            updateData: {
              bannerImage: result.homeTopBannerImg,
              detailUrl: result.detailUrl,
              noticeId: result.noticeId,
              title: result.title,
            },
          };
          store.commit("updateState", topBannerData);
        } else {
          state.topBannerHeight = null;
        }
      } else {
        console.log(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 메인_홈(탭)_단골상점 상품 리스트 불러오기
  setFavoritesStore: async function (store) {
    try {
      // const regularUrl = '/v3/user/store/userId/' + state.userId + '/stores/regular/sort/NEW';
      const regularUrl = "/v3/user/store/userId/" + state.userId + "/stores/regular/sort/RANDOM";
      const response = await this._vm.$axios1.get(regularUrl);
      if (response.data.responseCode == 2000) {
        const favoritesStore = response.data.result.serviceStoreList.reverse();
        if (favoritesStore.length > 0) {
          favoritesStore.map((store) => {
            store.regularYn = "Y";
            return store;
          });
        }
        const updateData = {
          type: "favoritesStore",
          updateData: favoritesStore,
        };
        store.commit("updateState", updateData);
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 메인_홈(탭)_최근에 구매했어요 상품 리스트 불러오기
  setRecentBuyProducts: async function (store) {
    try {
      const orderProductUrl = "/v3/market/delivery/product/product/order/" + state.userId;
      const response = await this._vm.$axios1.get(orderProductUrl);
      if (response.data.responseCode == 2000) {
        const updateData = {
          type: "recentBuyProducts",
          updateData: response.data.result.recentOrderProductList,
        };
        store.commit("updateState", updateData);
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 메인_바로배달(탭) 상품 리스트 불러오기
  setDirectMarkets: async function (store) {
    try {
      const directUrl = "/v3/market/user/" + state.latitude + "/" + state.longitude + "/" + state.userId + "/markets/bd";
      const response = await this._vm.$axios1.get(directUrl);
      if (response.data.responseCode == 2000) {
        let marketList = response.data.result.marketList;
        if (state.userId) {
          marketList = await Promise.all(
            marketList.map(async (marketData) => {
              const marketId = marketData.marketId;
              const recentBuyProductsUrl = `/v3/market/delivery/product/product/markets/${marketId}/order/${state.userId}`;
              let tempMarketData;
              const recentBuyProducts = await this._vm.$axios1
                .get(recentBuyProductsUrl)
                .then((response) => {
                  if (response.data.responseCode === 2000) {
                    return response.data.result.recentOrderProductList;
                  } else {
                    alert(response.data.errorObject.errorMessage);
                  }
                })
                .catch(() => {
                  return false;
                });
              tempMarketData = { ...marketData, recentBuyProducts };
              return tempMarketData;
            }),
          );
          marketList.sort((a, b) => {
            const isRecentBuyProductsExistA = a.recentBuyProducts.length > 0;
            const isRecentBuyProductsExistB = b.recentBuyProducts.length > 0;
            return isRecentBuyProductsExistB - isRecentBuyProductsExistA;
          });
        }

        store.commit("setDirectMarket", marketList);
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 팝업 배너 불러오기
  setPopup: async function (store) {
    try {
      const bannerImageUrl = "/v3/notices/member-notices/all-markets/popups?size=30&userId=" + state.userId;
      const response = await this._vm.$axios2.get(bannerImageUrl);
      if (response.data.responseCode == 2000) {
        store.commit("showPopup", response.data.result.notices);
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },

  // 메인 농축산시장 상점 선택
  setMainTabType: function (store) {
    state.mainTabProducts.currentPage = 0;
    state.mainTabProducts.isLastPage = false;
    store.dispatch("setMainTabLargeTab");
    store.dispatch("getMainTabProducts");
  },
  // 화목한 특가
  setSpecialOffers: function (store, categoryId) {
    state.mainTabProducts.currentPage = 0;
    state.mainTabProducts.isLastPage = false;
    store.dispatch("setSpecialTabLargeTab");
    categoryId = state.categoryId;
    store.dispatch("getAllSpecialOffers", categoryId);
  },
  getAllSpecialOffers: async function (index, categoryId) {
    state.isProgressing = true;
    try {
      const page = state.mainTabProducts.currentPage;
      //const productUrl = `/v3/special/event/product-list?marketId=188&page=${page}&size=1000&storeId=1`;
      //const cateId = state.specialTabLargeTab.tabItems[index].categoryId;
      let response = null;
      if (categoryId === 0) {
        //all
        const productUrl = `/v3/special/event/product-list?marketId=188&page=${page}&size=1000`;
        state.specialOffer = "";
        response = await this._vm.$axios1.post(productUrl);
      } else if (categoryId === 1) {
        const productUrl = `/v3/special/event/product-list?marketId=188&page=${page}&size=1000&storeId=1`;
        state.specialOffer = "tuesday";
        response = await this._vm.$axios1.post(productUrl);
      } else {
        const productUrl = `/v3/special/event/product-list?marketId=188&page=${page}&size=1000&storeId=2`;
        state.specialOffer = "thursday";
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.specialTabProducts.isLastPage = true;
        }
        if (state.specialOffer === "tuesday") {
          state.specialTabProducts.list = tabProducts.map((item) => {
            if (item.storeId === 1) {
              item.sheduled = true;
            }
          });
          state.specialTabProducts.list = tabProducts;
        } else if (state.specialOffer === "thursday") {
          state.specialTabProducts.list = tabProducts.map((item) => {
            if (item.storeId === 1) {
              item.sheduled = false;
            }
          });
          state.specialTabProducts.list = tabProducts;
        } else {
          state.specialTabProducts.list = tabProducts;
        }
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 농축산시장 상품 불러오기
  getMainTabProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.mainTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=176&page=${page}&size=1000&storeId=1`;
      //const index = state.mainTabLargeTab.tabActiveIndex;
      //const cateId = state.mainTabLargeTab.tabItems[index].categoryId;
      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.mainTabProducts.isLastPage = true;
        }

        state.mainTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 농축산시장 상품 리스트 추가페이지 불러오기
  addMainTabProducts: async function () {
    state.isProgressing = true;

    try {
      const index = state.mainTabLargeTab.tabActiveIndex;
      const cateId = state.mainTabLargeTab.tabItems[index].categoryId;
      const page = ++state.mainTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=176&page=${page}&size=60&storeId=1`;

      let response = null;
      if (cateId !== "0") {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.mainTabProducts.isLastPage = true;
        }

        state.mainTabProducts.list = state.mainTabProducts.list.concat(tabProducts);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 농축산시장 대분류 불러오기
  setMainTabLargeTab: async function () {
    // 과거 api 호출 방식
    // try {
    //   const axiosUrl = `/v3/special/event/main-cate/list?marketId=176&storeId=1`;
    //   const response = await this._vm.$axios1.get(axiosUrl);
    //   if (response.data.responseCode === 2000) {
    //     const result = response.data.result;
    //     result.unshift({
    //       cateCd1: "0",
    //       cateNm: "전체",
    //       cateImgUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
    //     });
    //     const resultData = result
    //       .map(({ cateCd1, cateNm, cateImgUrl }) => {
    //         return {
    //           categoryId: cateCd1,
    //           categoryName: cateNm,
    //           categoryImg: cateImgUrl,
    //         };
    //       })
    //       .filter((cate) => cate.categoryName !== "기타");
    //     // resultData.push({
    //     //   categoryId: "99",
    //     //   categoryName: "기타",
    //     //   categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
    //     // });
    //     state.mainTabLargeTab.tabItems = resultData;
    //     state.mainTabLargeTab.tabActiveIndex = 0;
    //   } else {
    //     console.log(response.data.errorObject.errorMessage);
    //   }
    // } catch (err) {
    //   return false;
    // }

    // 하드코딩 방식
    const hardcodedAgricultureLargeTab = [
      {
        categoryId: "0",
        categoryName: "전체",
        categoryImageUrl:
          "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
      },
      {
        categoryId: "4",
        categoryName: "농산물/청과",
        categoryImageUrl:
          "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%82%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A1%E1%86%AB%E1%84%86%E1%85%AE%E1%86%AF.png",
      },
      {
        categoryId: "41",
        categoryName: "쌀/잡곡",
        categoryImageUrl:
          "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8A%E1%85%A1%E1%86%AF%E1%84%8C%E1%85%A1%E1%86%B8%E1%84%80%E1%85%A9%E1%86%A8.png",
      },
      {
        categoryId: "200",
        categoryName: "산지직송",
        categoryImageUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/main_cate_200.png",
      },
      {
        categoryId: "12",
        categoryName: "건강식품",
        categoryImageUrl:
          "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%A5%E1%86%AB%E1%84%80%E1%85%A1%E1%86%BC.png",
      },
    ];

    const parsedData = hardcodedAgricultureLargeTab.map((item) => {
      return {
        categoryId: item.categoryId,
        categoryName: item.categoryName,
        categoryImg: item.categoryImageUrl,
      };
    });

    state.mainTabLargeTab.tabItems = parsedData;
    state.mainTabLargeTab.tabActiveIndex = 0;
  },

  // 메인 농축산시장 대분류 선택
  switchMainTabLargeTabActiveIndex: function (store, index) {
    state.mainTabProducts.totalPages = 0;
    state.mainTabProducts.currentPage = 0;
    state.mainTabProducts.isLastPage = false;

    if (index === 0) {
      store.dispatch("getMainTabProducts");
    } else {
      const categoryId = state.mainTabLargeTab.tabItems[index].categoryId;
      store.dispatch("getMainTabProducts", categoryId);
    }

    state.mainTabLargeTab.tabActiveIndex = index;
  },

  specialTabLargeTabActiveIndex: function (store, index) {
    state.specialTabProducts.totalPages = 0;
    state.specialTabProducts.currentPage = 0;
    state.specialTabProducts.isLastPage = false;

    if (index === 0) {
      const categoryId = state.specialTabLargeTab.tabItems[index].categoryId;
      state.categoryId = index;
      store.dispatch("getAllSpecialOffers", index, categoryId);
    } else {
      const categoryId = state.specialTabLargeTab.tabItems[index].categoryId;
      state.categoryId = index;
      store.dispatch("getAllSpecialOffers", index, categoryId);
    }
    state.specialTabLargeTab.tabActiveIndex = index;
  },

  // 메인 수산시장 상점 선택
  setSeaTabType: async function (store) {
    state.seaTabProducts.currentPage = 0;
    state.seaTabProducts.isLastPage = false;
    await store.dispatch("setSeaTabLargeTab");
    await store.dispatch("getSeaTabProducts");
  },
  // 메인 수산시장 상품 불러오기
  getSeaTabProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.seaTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=177&page=${page}&size=300&storeId=1`;

      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.seaTabProducts.isLastPage = true;
        }

        state.seaTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },

  setImmediateDeliveryTabType: async function (store) {
    state.immediateDeliveryTabProducts.currentPage = 0;
    state.immediateDeliveryTabProducts.isLastPage = false;
    // await store.dispatch("setNMartProducts");
    await store.dispatch("getImmediateDeliveryProducts");
  },

  getImmediateDeliveryProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.immediateDeliveryTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=191&size=300`;

      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.immediateDeliveryTabProducts.isLastPage = true;
        }

        console.log("here", tabProducts);
        state.immediateDeliveryTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },

  // 축산 상품 리스트 불러오기
  setLivestockTabType: async function (store) {
    state.livestockTabProducts.currentPage = 0;
    state.livestockTabProducts.isLastPage = false;
    // await store.dispatch("setLivestockLargeTab");
    await store.dispatch("getLivestockProducts");
  },

  getLivestockProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.livestockTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=192&size=300`; // TODO : 축산시장 marketId 수정

      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.livestockTabProducts.isLastPage = true;
        }

        state.livestockTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },

  switchLivestockTabLargeTabActiveIndex: function (store, index) {
    state.livestockTabProducts.totalPages = 0;
    state.livestockTabProducts.currentPage = 0;
    state.livestockTabProducts.isLastPage = false;

    if (index === 0) {
      store.dispatch("getLivestockProducts");
    } else {
      const categoryId = state.livestockTabLargeTab.tabItems[index].categoryId;
      store.dispatch("getLivestockProducts", categoryId);
    }

    state.livestockTabLargeTab.tabActiveIndex = index;
  },

  // nMart 상품 리스트 추가페이지 불러오기
  setNMartTabType: async function (store) {
    state.nMartTabProducts.currentPage = 0;
    state.nMartTabProducts.isLastPage = false;
    await store.dispatch("setNMartTabLargeTab");
    await store.dispatch("getNMartProducts");
  },

  // nMart 대분류 불러오기
  setNMartTabLargeTab: async function () {
    try {
      const axiosUrl = `/v3/market/delivery/category/market/190/categories/sale`;
      const response = await this._vm.$axios1.get(axiosUrl);

      if (response.data.responseCode === 2000) {
        const result = response.data.result.categoryList;

        const resultData = result.map((items) => {
          const { categoryId, categoryName, categoryImageUrl } = items;
          return {
            categoryId: categoryId,
            categoryName: categoryName,
            categoryImg: categoryImageUrl,
          };
        });

        state.nMartTabLargeTab.tabItems = resultData;
        state.nMartTabLargeTab.tabActiveIndex = 0;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },

  switchNMartTabLargeTabActiveIndex: function (store, index) {
    state.nMartTabProducts.totalPages = 0;
    state.nMartTabProducts.currentPage = 0;
    state.nMartTabProducts.isLastPage = false;

    if (index === 0) {
      store.dispatch("getNMartProducts");
    } else {
      const categoryId = state.nMartTabLargeTab.tabItems[index].categoryId;
      store.dispatch("getNMartProducts", categoryId);
    }

    state.nMartTabLargeTab.tabActiveIndex = index;
  },

  // nMart 상품 불러오기
  getNMartProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.nMartTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=190&size=300`;

      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.nMartTabProducts.isLastPage = true;
        }

        state.nMartTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },

  // 메인 수산시장 상품 리스트 추가페이지 불러오기
  addSeaTabProducts: async function () {
    state.isProgressing = true;

    try {
      const index = state.seaTabLargeTab.tabActiveIndex;
      const cateId = state.seaTabLargeTab.tabItems[index].categoryId;
      const page = ++state.seaTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?marketId=177&page=${page}&size=30&storeId=1`;

      let response = null;
      if (cateId !== "0") {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
        });
      } else {
        response = await this._vm.$axios1.post(productUrl);
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.seaTabProducts.isLastPage = true;
        }

        state.seaTabProducts.list = state.seaTabProducts.list.concat(tabProducts);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 수산시장 대분류 불러오기
  setSeaTabLargeTab: async function () {
    try {
      const axiosUrl = `/v3/special/event/main-cate/list?marketId=177&storeId=1`;
      const response = await this._vm.$axios1.get(axiosUrl);

      if (response.data.responseCode === 2000) {
        // const result = response.data.result;
        // result.unshift({
        //   cateCd1: "0",
        //   cateNm: "전체",
        //   cateImgUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
        // });
        // const resultData = result
        //   .map(({ cateCd1, cateNm, cateImgUrl }) => {
        //     return {
        //       categoryId: cateCd1,
        //       categoryName: cateNm,
        //       categoryImg: cateImgUrl,
        //     };
        //   })
        //   .filter((cate) => cate.categoryName !== "기타");
        // resultData.push({
        //   categoryId: "99",
        //   categoryName: "기타",
        //   categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        // });

        const hardcodedSeaLargeTab = [
          {
            categoryId: "0",
            categoryName: "전체",
            categoryImageUrl:
              "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
          },
          {
            categoryId: "5",
            categoryName: "수산대전상품권",
            categoryImageUrl:
              "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%89%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB.png",
          },
          {
            categoryId: "8",
            categoryName: "건어물",
            categoryImageUrl:
              "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%A5%E1%86%AB%E1%84%8B%E1%85%A5%E1%84%86%E1%85%AE%E1%86%AF.png",
          },
          {
            categoryId: "51",
            categoryName: "수산",
            categoryImageUrl:
              "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%89%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB.png",
          },
          {
            categoryId: "99",
            categoryName: "기타",
            categoryImageUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
          },
        ];

        const parsedData = hardcodedSeaLargeTab.map((item) => {
          return {
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            categoryImg: item.categoryImageUrl,
          };
        });

        state.seaTabLargeTab.tabItems = parsedData;
        state.seaTabLargeTab.tabActiveIndex = 0;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },

  // 메인 수산시장 대분류 불러오기
  setSpecialTabLargeTab: async function () {
    try {
      const axiosUrl = `/v3/special/event/main-cate/list?marketId=177&storeId=1`;
      const response = await this._vm.$axios1.get(axiosUrl);

      if (response.data.responseCode === 2000) {
        const result = response.data.result;
        result.unshift({
          cateCd1: "0",
          cateNm: "전체",
          cateImgUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
        });

        const resultData = result
          .map(({ cateCd1, cateNm, cateImgUrl }) => {
            return {
              categoryId: cateCd1,
              categoryName: cateNm,
              categoryImg: cateImgUrl,
            };
          })
          .filter((cate) => cate.categoryName !== "기타");

        resultData.push({
          categoryId: "99",
          categoryName: "기타",
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        });

        state.mainTabLargeTab.tabItems = resultData;
        state.mainTabLargeTab.tabActiveIndex = 0;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 메인 수산시장 대분류 선택
  switchSeaTabLargeTabActiveIndex: function (store, index) {
    state.seaTabProducts.totalPages = 0;
    state.seaTabProducts.currentPage = 0;
    state.seaTabProducts.isLastPage = false;

    if (index === 0) {
      store.dispatch("getSeaTabProducts");
    } else {
      const categoryId = state.seaTabLargeTab.tabItems[index].categoryId;
      store.dispatch("getSeaTabProducts", categoryId);
    }

    state.seaTabLargeTab.tabActiveIndex = index;
  },

  // 메인 브랜드몰(친환경) 상점 선택
  setBrandTabType: function (store) {
    state.brandTabProducts.currentPage = 0;
    state.brandTabProducts.isLastPage = false;
    store.dispatch("setBrandTabLargeTab");
    store.dispatch("getBrandTabProducts");
  },
  // 메인 브랜드몰(친환경) 상품 불러오기
  getBrandTabProducts: async function (store, cateId) {
    state.isProgressing = true;

    try {
      const page = state.brandTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?page=${page}&size=300`;

      let response = null;
      if (cateId) {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
          storeId: 1,
        });
      } else {
        response = await this._vm.$axios1.post(productUrl, {
          storeId: 1,
        });
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.brandTabProducts.isLastPage = true;
        }

        state.brandTabProducts.list = tabProducts;
      } else {
        console.log(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 브랜드몰(친환경) 상품 다음 페이지 불러오기
  addBrandTabProducts: async function () {
    state.isProgressing = true;

    try {
      const index = state.brandTabLargeTab.tabActiveIndex;
      const cateId = state.brandTabLargeTab.tabItems[index].categoryId;
      const page = ++state.brandTabProducts.currentPage;
      const productUrl = `/v3/special/event/product-list?page=${page}&size=60`;

      let response = null;
      if (cateId !== "0") {
        response = await this._vm.$axios1.post(productUrl, {
          cateCd1: cateId.toString(),
          storeId: 1,
        });
      } else {
        response = await this._vm.$axios1.post(productUrl, {
          storeId: 1,
        });
      }

      if (response.data.responseCode === 2000) {
        const tabProducts = response.data.result.response.content.map((product) => {
          return {
            productId: product.productId,
            productName: product.productNm,
            productThumbnailUrl: product.thumbImgUrl,
            marketId: product.marketId,
            storeId: product.storeId,
            atYn: product.atYn,
            price: product.price,
            deliveryMethod: product.productType,
            originName: product.originNm,
            regDateTime: product.regDateTime,
            paymentTypeList: product.paymentTypeList,
            paymentTypeCodeList: product.paymentTypeCodeList,
            businessAddress: product.address,
            deliveryFeeMethod: product.deliveryFeeMethod,
            deliveryFeeCondition: product.deliveryFeeCondition,
          };
        });

        if (response.data.result.response.totalPages - 1 === page) {
          state.brandTabProducts.isLastPage = true;
        }

        state.brandTabProducts.list = state.brandTabProducts.list.concat(tabProducts);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      state.isProgressing = false;
      return false;
    }

    state.isProgressing = false;
  },
  // 메인 브랜드몰(친환경) 대분류 불러오기
  setBrandTabLargeTab: async function () {
    try {
      const axiosUrl = `/v3/special/event/brand/main-cate/list`;
      const response = await this._vm.$axios1.get(axiosUrl);

      if (response.data.responseCode === 2000) {
        const result = response.data.result;
        result.unshift({
          cateCd1: "0",
          cateNm: "전체",
          cateImgUrl: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8E%E1%85%A6.png",
        });

        const resultData = result
          .map(({ cateCd1, cateNm, cateImgUrl }) => {
            return {
              categoryId: cateCd1,
              categoryName: cateNm,
              categoryImg: cateImgUrl,
            };
          })
          .filter((cate) => cate.categoryName !== "기타");

        resultData.push({
          categoryId: "99",
          categoryName: "기타",
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        });

        state.brandTabLargeTab.tabItems = resultData;
        state.brandTabLargeTab.tabActiveIndex = 0;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 메인 브랜드몰(친환경) 대분류 선택
  switchBrandTabLargeTabActiveIndex: function (store, index) {
    state.brandTabProducts.totalPages = 0;
    state.brandTabProducts.currentPage = 0;
    state.brandTabProducts.isLastPage = false;

    if (index === 0) {
      store.dispatch("getBrandTabProducts");
    } else {
      const categoryId = state.brandTabLargeTab.tabItems[index].categoryId;
      store.dispatch("getBrandTabProducts", categoryId);
    }

    state.brandTabLargeTab.tabActiveIndex = index;
  },

  scrollEvent: function (store) {
    // mainWrap fixed 관련
    if (state.topBannerHeight) {
      const htmlScrollTop = document.querySelector("html").scrollTop;
      const mainWrapEl = document.querySelector("#mainWrap");
      if (htmlScrollTop >= state.topBannerHeight - 1) {
        mainWrapEl?.classList.add("fixed");
      } else {
        mainWrapEl?.classList.remove("fixed");
      }
    }
    // deliveryMarketsTab fixed 관련
    store.dispatch("setDeliveryMarketsTabFixed");
    // 메인_전국배송(탭)_전체상품 상품 리스트 추가페이지 불러오기 관련
    if (!state.isProgressing) {
      if (state.contentsType === "sea" && state.seaTabProducts.isLastPage === false) {
        const html = document.querySelector("html");
        const isScrollBottom = html.scrollTop + html.offsetHeight > html.scrollHeight - 150;
        if (isScrollBottom) {
          store.dispatch("addSeaTabProducts");
        }
      } else if (state.contentsType === "home" && state.mainTabProducts.isLastPage === false) {
        const html = document.querySelector("html");
        const isScrollBottom = html.scrollTop + html.offsetHeight > html.scrollHeight - 150;
        if (isScrollBottom) {
          store.dispatch("addMainTabProducts");
        }
      } else if (state.contentsType === "brand" && state.deliveryMarketsType === "products") {
        if (!state.deliveryMarketsProducts.isLastPage) {
          const html = document.querySelector("html");
          const isScrollBottom = html.scrollTop + html.offsetHeight > html.scrollHeight - 150;
          if (isScrollBottom) {
            store.dispatch("addDeliveryMarketsProducts");
          }
        }
      }
    }
  },
  loadTopBanner: function (store) {
    const topBanner = document.querySelector("#topBanner");
    if (topBanner) {
      store.commit("updateState", { type: "topBannerHeight", updateData: topBanner.offsetHeight });
      store.dispatch("scrollEvent");
    }
  },
  setProgressing: function (store, boolean) {
    if (!store.rootState.userId || !localStorage.getItem("userId")) {
      state.isProgressing = boolean;
    }
  },
  clickExplain: function (store, event) {
    // 공지사항 목록 클릭시
    event.currentTarget.closest("li").querySelector(".explain_txt").classList.toggle("active");
  },
  clickBanner: async function (store, event) {
    // console.log(event.currentTarget.dataset.title);
    // console.log(event.currentTarget.dataset.link);
    if (event.target.dataset.detailUrl) {
      this.commit("marketMain/navigateTo", {
        path: "event-detail",
        params: {
          url: event.target.dataset.detailUrl,
          title: event.target.dataset.title,
        },
      });
    }
  },
  // TODO : noticeId 감지하여 탭 이동해야함
  clickEvent: async function (store, event) {
    if (event.target.tagName == "IMG") {
      let currentOS = "else";
      let mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));

      if (mobile) {
        let userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.search("android") > -1)
          currentOS = "android";
        else if ((userAgent.search("iphone") > -1) || (userAgent.search("ipod") > -1) || (userAgent.search("ipad") > -1))
          currentOS = "ios";
        else
          currentOS = "else";
      }

      // Android
      if(currentOS === "android"){
        window.open("intent://mall.noljang.co.kr/home#Intent;scheme=http;package=com.android.chrome;end");
        // IOS
      } else if(currentOS === "ios"){
        window.open("googlechrome:////mall.noljang.co.kr/home");
      }

      // const noticeId = event.target.dataset.noticeId ? event.target.dataset.noticeId : event.currentTarget.dataset.noticeId;
      //
      // // 배너 외부 링크 예외처리
      // if (noticeId === "2901") {
      //   await router.push({
      //     name: "이벤트상세",
      //     params: {
      //       title: "서울시공공배달서비스2.0소개",
      //       url: "https://news.seoul.go.kr/economy/seoul-delivery-plus",
      //     },
      //   });
      // }
      //
      // if (noticeId) {
      //   const uniqueEventUrl = "v3/noljang/main/banners/" + noticeId + "/click";
      //   const response = await this._vm.$axios2.get(uniqueEventUrl, { headers: { userId: state.userId } });
      //   const result = response.data.result;
      //   if (response.data.responseCode === 2000) {
      //     if (result.bannerClickType === "EVENT_DETAIL") {
      //       await router.push({
      //         name: "이벤트상세",
      //         params: {
      //           title: result.noticeDetailDto.noticeTitle,
      //           url: result.noticeDetailDto.noticeDetailUrl,
      //         },
      //       });
      //     } else if (result.bannerClickType === "PRODUCT_DETAIL") {
      //       const productId = result.productDetailDto.productId;
      //       await router.push({
      //         name: "상품 메인",
      //         params: {
      //           productId: productId,
      //         },
      //       });
      //     } else if (result.bannerClickType === "STORE_MAIN") {
      //       const marketId = result.storeMainDto.marketId;
      //       const storeId = result.storeMainDto.storeId;
      //       const storeName = result.storeMainDto.storeName;
      //       await router.push({ name: "상점 메인", params: { marketId: marketId, storeId: storeId, storeName: storeName } });
      //     } else if (result.bannerClickType === "BRAND_MALL_DETAIL") {
      //     } else if (result.bannerClickType === "MARKET_MAIN") {
      //       const marketId = result.marketBannerDto.marketId;
      //       const marketName = result.marketBannerDto.marketName;
      //       await router.push({ name: "시장 메인", params: { marketId: marketId, marketName: marketName } });
      //     }
      //   }
      // }
    }
  },
  clickCategory: function (store, data) {
    // console.log(data);
    store.commit("clickCategory", data);
  },
  setContentsType: async function (store, data) {
    // 상단 탭 메뉴 클릭
    state.isProgressing = true;

    if (data.dataType === "event") {
      store.commit("setContentsType", data);
      // console.log(1)
      store.dispatch("getEventList");
    } else {
      if (data.dataType === "specialOffer") {
        //store.commit("special :: setContentsType", data);
        state.contentsType = data.dataType;
        await this.dispatch("main/setSpecialOffers");
        store.dispatch("getEventList");
        this.dispatch("main/allSpecialOffers");
        //await router.push({name:'specialOffers'})
      }
      if (state.contentsType === "event") {
        // 아래 dataType = 'event' 와는 다름 ::: 주의
        const dataType = "event";
        state.eventTabItems.forEach((tabItem) => {
          if (dataType === tabItem.dataType) {
            tabItem.isActive = true;
          } else {
            tabItem.isActive = false;
          }
        });
        state.eventContentsType = dataType;
      }

      store.commit("setContentsType", data);
    }
    state.isProgressing = false;
  },
  changeEventTabItem: function (store, data) {
    store.commit("changeEventTabItem", data);
  },
  getEventList: async function (store, eventType) {
    if (!eventType) {
      eventType = store.getters.eventContentsType;
    }

    const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;

    var axiosUrl = "/v3/notices/member-notices/all-markets/banners?size=30&userId=" + userId;
    if (eventType == "notice") {
      axiosUrl = "/v3/notices/member-notices/all-markets/articles?size=30";
    } else if (eventType == "how") {
      axiosUrl = "/v3/notices/member-notices/manuals?size=30&userId=" + userId;
    }

    this._vm.$axios2
      .get(axiosUrl) // 베너 목록
      .then((response) => {
        if (response.data.responseCode == 2000) {
          state.banners = response.data.result.billboardBanners;
          const updateData = {
            type: "eventList",
            updateData: eventType == "notice" ? response.data.result.notices : response.data.result.billboardBanners,
          };
          store.commit("updateState", updateData);
          // store.commit('setBanners', response.data.result.notices);
          // store.commit('updateContentsType', {dataType: 'event'});
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  },

  allSpecialOffers: function () {
    const axiosUrl = "/v3/special/event/product-list?marketId=188&page=0&size=300&storeId=1";
    this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        state.allSpecialOfferItems = response.data;
        state.specialTabProducts = response.data;
        let subTabArray = [
          {
            categoryId: "1",
            categoryName: "전체",
            isActive: false,
            categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
          },
          {
            categoryId: "2",
            categoryName: "화요일",
            isActive: false,
            categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
          },
          {
            categoryId: "3",
            categoryName: "목요일",
            isActive: false,
            categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
          },
        ];
        state.specialTabLargeTab.tabItems = subTabArray;
        state.specialTabLargeTab.tabActiveIndex = 0;
      })
      .catch(() => {
        console.error("에러가 발생했습니다.");
      });
  },
  goBack: function (store) {
    if (state.showAllmenu) {
      store.commit("setAllmenu");
      return;
    }
    // contentsType
    // this.commit('cartShip/testFunc');// 테스트 코드
    // this._vm.$closeView();
    if (state.contentsType !== "home") {
      store.dispatch("setContentsType", { dataType: "home", buttonType: "back" });
    } else {
      store.commit("backKey");
    }
  },
};

const getters = {
  eventContentsType(state) {
    return state.eventContentsType;
  },
  // markets(state){
  //     return state.markets;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
