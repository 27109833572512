import router from "@/router";

const state = {
  contentsType: "select",
  userId: null,
  isProgressing: false,
  // postCode: null,

  // Native Flag
  isFromPayment: false, // 네이티브에서 이 화면을 호출한 경우 true

  // select
  shippingAddress: {}, // 현재 설정되어 있는 주소
  addresses: [], // 기존에 등록해놓은 주소

  // setting
  searchAddresses: null, // 검색된 주소 데이터
  searchPageInfo: {}, // 검색된 주소의 페이징 데이터
  currentPage: 1, // 현재 검색 중인 페이지
  latestKeyword: null, // 가장 마지막으로 검색한 검색 키워드

  // detail
  addressType: null, // 집 추가 / 회사 추가 클릭 시 해당 구분값
  currentSearchAddress: {}, // 현재 선택되어 있는 검색된 주소
};

const mutations = {
  initState: function (state, data) {
    state.userId = data.userId;

    if (data.isFromPayment) {
      state.isFromPayment = data.isFromPayment;
    }

    if (data.addresses) {
      state.addresses = data.addresses;
    }

    if (Object.keys(data.shippingAddress).length) {
      let currentAddress = {
        shipToDoro: data.shippingAddress.roadNameAddress,
        shipToJibun: data.shippingAddress.lotNumberAddress,
        shipToDetail: data.shippingAddress.detailAddress,
        latitude: data.shippingAddress.latitude,
        longitude: data.shippingAddress.longitude,
        shipToName: data.shippingAddress.alias,
        isActive: true,
      };

      state.shippingAddress = data.shippingAddress;
      state.addresses = [...new Array(currentAddress), ...state.addresses];
    }
  },
  goBack: function (state, { commit }) {
    commit("reset");
    this._vm.$navigateBack();
  },
  reset: function (state) {
    if (state.contentsType == "select") {
      state.shippingAddress = {};
      state.addresses = [];
    }
    state.addressType = null;
    state.currentPage = 1;
    state.searchAddresses = null;
    state.searchPageInfo = {};
    state.latestKeyword = null;
    state.currentSearchAddress = {};
  },
  setShippingAddress: function (state, shippingAddress) {
    state.shippingAddress = shippingAddress;
  },
  setIsProgressing: function (state, isProgressing) {
    state.isProgressing = isProgressing;
  },
  setContentsType: function (state, type) {
    state.contentsType = type;
  },
  setCurrentSearchAddress: function (state, currentSearchAddress) {
    state.currentSearchAddress = currentSearchAddress;
  },
  setSearchAddressInfo: function (state, searchAddresses) {
    let addressList = null;

    // 초기화 여부 (넘겨받은 주소 리스트와 페이지 정보가 비어있는 경우)
    const isInit = !searchAddresses.addressList && !Object.keys(searchAddresses.pageInformation).length;
    // 검색 키워드 변경 여부 (초기화)
    const isKeywordChanged = state.latestKeyword != searchAddresses.latestKeyword;

    if (isInit || isKeywordChanged) {
      state.currentPage = 1;
      addressList = searchAddresses.addressList;
    } else {
      // 1페이지 신규 조회
      if (state.currentPage == 1) {
        addressList = searchAddresses.addressList;
      }
      // 페이지 추가 조회
      else {
        addressList = [...state.searchAddresses, ...searchAddresses.addressList];
      }
    }
    state.searchAddresses = addressList;
    state.searchPageInfo = searchAddresses.pageInformation;
    state.latestKeyword = searchAddresses.latestKeyword;
    // state.postCode = searchAddresses.postCode;
  },
  setAddressType: function (state, addressType) {
    state.addressType = addressType;
  },
};

const actions = {
  initState: async function (store, { isFromPayment }) {
    const userId = localStorage.getItem("userId");
    const shippingAddress = localStorage.getItem("userAddress") ? JSON.parse(localStorage.getItem("userAddress")) : {};

    let initData = {
      userId: userId,
      shippingAddress: shippingAddress,
      isFromPayment: isFromPayment ? isFromPayment : false,
    };

    store.commit("setIsProgressing", true);
    try {
      if (userId) {
        // 유저가 기존에 등록한 주소 조회
        const shipToListResponse = await this._vm.$axios1.get("/v3/user/member/address/" + userId + "/ship-to-list");
        if (shipToListResponse.data.responseCode == 2000) {
          initData["addresses"] = shipToListResponse.data.result.shipToList;
        } else {
          logError(shipToListResponse.data, "사용자 보유 주소 조회에 실패하였습니다.");
        }

        // 현재 로컬에 저장된 주소가 없는 경우
        if (!Object.keys(shippingAddress).length) {
          // 유저의 현재 주소 조회
          const shipToResponse = await this._vm.$axios1.get("/v3/user/member/address/" + userId + "/ship-to");
          if (shipToResponse.data.responseCode == 2000) {
            const result = shipToResponse.data.result;
            result.shipToDoro = result.shipToRoad;

            // 조회된 해당 주소를 현재 주소로 가공하여 주소 리스트 제일 앞에 추가
            result.isActive = true;
            const data = {
              roadNameAddress: result.shipToRoad,
              lotNumberAddress: result.shipToJibun,
              detailAddress: result.shipToDetail,
              latitude: result.latitude,
              longitude: result.longitude,
              alias: result.shipToName,
            };
            initData["shippingAddress"] = data;
          } else {
            logError(shipToResponse.data, "사용자 설정 주소 조회에 실패하였습니다.");
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    store.commit("setIsProgressing", false);

    store.commit("initState", initData);
  },
  goBack: function (store) {
    if (state.contentsType == "detail") {
      store.commit("setCurrentSearchAddress", {});
      store.dispatch("setContentsType", { type: "setting" });
    } else if (state.contentsType == "setting") {
      store.commit("reset");
      store.dispatch("setContentsType", { type: "select" });
    } else if (state.contentsType == "select") {
      store.commit("goBack", { commit: store.commit });
    }
  },
  setContentsType: function (store, { type, data }) {
    // 상단 탭 메뉴 클릭
    if (type == "detail") {
      store.commit("setCurrentSearchAddress", data);
    }

    if (type == "setting") {
      if (data == "home" || data == "company") {
        store.commit("setAddressType", data);
      }
    }

    store.commit("setContentsType", type);
  },
  doSearch: async function (store, keyword) {
    let addressList = null;
    let pageInformation = {};
    let currentPage = state.latestKeyword == keyword ? state.currentPage : 1;
    if (keyword) {
      // 검색어가 있으나 두 자 미만일 시
      if (keyword.length < 2) {
        alert("검색어는 두 자 이상이어야 조회가 가능합니다.");
        return;
      } else {
        try {
          store.commit("setIsProgressing", true);
          const searchResponse = await this._vm.$axios1.get("/v3/user/address/search/" + keyword + "/20/" + currentPage);
          if (searchResponse.data.responseCode == 2000) {
            addressList = searchResponse.data.result.addressList;
            pageInformation = searchResponse.data.result.pageInformation;
          } else {
            addressList = [];
            logError(searchResponse.data, "주소 검색에 실패하였습니다.");
          }
          store.commit("setIsProgressing", false);
        } catch (e) {
          console.error(e);
        }
      }
    }
    store.commit("setSearchAddressInfo", {
      addressList,
      pageInformation,
      latestKeyword: keyword,
    });
  },
  handleScroll(store, event) {
    if (!state.isProgressing && state.searchPageInfo.totalPage > state.currentPage) {
      const eventTarget = event.currentTarget;
      if (eventTarget.scrollTop + eventTarget.offsetHeight > eventTarget.scrollHeight - 50) {
        state.currentPage++;
        store.dispatch("doSearch", state.latestKeyword);
      }
    }
  },
  setCurrentAddress: async function (store, { shipToId, addressDetail, addressAlias, onInvalid }) {
    let addressData = {
      shipToDoro: state.currentSearchAddress.addressDoro,
      shipToJibun: state.currentSearchAddress.addressJibun,
      zipCode: state.currentSearchAddress.postCode,
      shipToDetail: addressDetail,
      shipToName: addressAlias ? addressAlias : "",
    };

    const cfTitle = state.addresses.length ? "배송지 변경" : "배송지 추가";
    const cfContent = state.addresses.length ? "해당 배송지로 변경하시겠습니까?" : "해당 배송지를 추가하시겠습니까?";

    // 1. 리스트 화면 & 기존 등록해놓은 주소로 변경
    if (state.contentsType == "select") {
      // 1-1. 배달지 변경 Confirm
      if (!(await getConfirm(this, cfTitle, cfContent))) {
        return false;
      }

      // 1-2. 서버에 사용자 현재 주소 변경
      try {
        store.commit("setIsProgressing", true);
        const addressResponse = await this._vm.$axios1.patch("/v3/user/member/address/" + state.userId + "/ship-to", { shipToId: shipToId });
        store.commit("setIsProgressing", false);
        if (addressResponse.data.responseCode != 2000) {
          logError(addressResponse.data, "사용자 설정 주소 변경에 실패하였습니다.");
          return false;
        }
      } catch (e) {
        store.commit("setIsProgressing", false);
        console.error(e);
        return false;
      }
    }
    // 2. 상세 화면 & 신규 주소로 추가 및 변경
    else if (state.contentsType == "detail") {
      // 2-1. 상세 주소 유효성 체크
      if (!addressDetail.trim()) {
        this._vm.$callNative("showToast", {
          message: "상세주소를 입력해주세요",
        });
        // 2021.09.16 - 배송지 추가 시, 상세정보를 입력하지 않은 경우 상세 정보 input에 focus 처리
        // 상세주소 Input에 focus() 처리하도록 콜백 호출
        onInvalid();
        return false;
      }

      // 2-2. 추가 및 변경 컨펌 획득
      if (!(await getConfirm(this, cfTitle, cfContent))) {
        return false;
      }

      // 2-3. 서버에 주소 추가 및 현재 주소로 설정
      try {
        if (state.userId) {
          // 사용자 보유 주소 추가 및 설정 주소 변경
          const putData = addressData;
          store.commit("setIsProgressing", true);
          const addressResponse = await this._vm.$axios1.put("/v3/user/member/address/" + state.userId + "/ship-to", putData);
          store.commit("setIsProgressing", false);
          if (addressResponse.data.responseCode != 2000) {
            logError(addressResponse.data, "사용자 설정 주소 추가 및 변경에 실패하였습니다.");
            return false;
          }
        }
      } catch (e) {
        store.commit("setIsProgressing", false);
        console.error(e);
        return false;
      }
    }

    // 3. 네이티브에 전달할 주소 데이터 가공
    try {
      let userAddress = {};

      // 3-1. 회원인 경우 회원 현재 주소 재조회
      if (state.userId) {
        const shipToResponse = await this._vm.$axios1.get("/v3/user/member/address/" + state.userId + "/ship-to");
        const address = shipToResponse.data.result;
        userAddress = {
          roadNameAddress: address.shipToRoad,
          lotNumberAddress: address.shipToJibun,
          detailAddress: address.shipToDetail,
          latitude: address.latitude,
          longitude: address.longitude,
          alias: address.shipToName,
        };
      }
      // 3-2. 비회원인 경우 주소정보 + 사용자가 입력한 상세주소, 별칭 + 좌표 데이터 취합
      else {
        // 주소 -> 좌표 변환
        const postData = {
          shipToDoro: addressData.shipToDoro,
          shipToJibun: addressData.shipToJibun,
        };
        store.commit("setIsProgressing", true);
        const locationResponse = await this._vm.$axios1.post("/v3/user/address/ship-to/location/converter", postData);
        store.commit("setIsProgressing", false);
        if (locationResponse.data.responseCode == 2000) {
          userAddress = {
            roadNameAddress: addressData.shipToDoro,
            lotNumberAddress: addressData.shipToJibun,
            detailAddress: addressData.shipToDetail,
            alias: addressData.shipToName,
          };
          userAddress.latitude = locationResponse.data.result.latitude;
          userAddress.longitude = locationResponse.data.result.longitude;
        } else {
          logError(locationResponse.data, "주소 -> 좌표 변환에 실패하였습니다.");
          return false;
        }
      }

      // 네이티브에 주소 정보 전달
      this._vm.$callNative("setShippingAddress", userAddress, false);
      store.commit("setShippingAddress", userAddress);
      store.dispatch("setContentsType", { type: "select" });
      store.dispatch("goBack");
    } catch (e) {
      console.error(e);
    }

    localStorage.setItem("userAddress", JSON.stringify(addressData));
  },
  delAddress: async function (store, shipToId) {
    // 2021.09.16 - 요청에 따라 '배달지' -> '배송지' 문구 변경
    const cfTitle = "배송지 삭제";
    const cfContent = "해당 배송지를 삭제하시겠습니까?";

    // 배송지 변경 Confirm
    if (!(await getConfirm(this, cfTitle, cfContent))) {
      return false;
    }

    try {
      const deleteResponse = await this._vm.$axios1.delete("/v3/user/member/address/" + state.userId + "/ship-to", {
        data: {
          shipToId: shipToId,
        },
      });

      if (deleteResponse.data.responseCode == 2000) {
        store.dispatch("initState", { isFromPayment: false });
      } else {
        logError(deleteResponse.data, "사용자 보유 배송지 삭제에 실패하였습니다.");
      }
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {
  homeAddresses: (state) => {
    return state.addresses.filter((address) => address.shipToName == "집");
  },
  companyAddresses: (state) => {
    return state.addresses.filter((address) => address.shipToName == "회사");
  },
  generalAddresses: (state) => {
    return state.addresses.filter((address) => address.shipToName != "집" && address.shipToName != "회사");
  },
};

/**
 * 배송지 변경 / 삭제 확인 모달 출력
 * @param {Object} this 객체
 */
const getConfirm = (_this, title, msg) => {
  return new Promise((resolve) => {
    const modalName = "confirm_change_address";
    const callback = (isConfirm) => {
      _this._vm.$modal.hideAll();
      resolve(isConfirm);
    };
    const compProps = {
      title: title,
      msgArr: [msg], // html 코드 적용 가능
      btnArr: [
        {
          txt: "닫기",
          btnCallback: () => callback(false),
        },
        {
          txt: "확인",
          type: "type1 inverse",
          btnCallback: () => callback(true),
        },
      ],
      closeModal: function () {
        callback(false);
      },
    };

    _this.dispatch(
      "showModal",
      {
        name: modalName,
        compProps: compProps,
        modalProps: {
          width: "90%",
          maxWidth: 400,
          height: "auto",
          adaptive: true,
        },
        // seconds: 3
      },
      { root: true },
    );
  });
};

/**
 * API 정상 호출 실패 시 에러 로그
 * @param {object} response API 호출 후 받은 응답 데이터
 * @param {string} message 임의로 같이 표시할 메시지
 */
const logError = (response, message) => {
  if (!response) {
    return false;
  }

  console.error(
    "Response Code:: " + response.responseCode + "\n" + "Error Message:: " + response.errorObject.errorMessage + "\n" + "Message:: " + message,
  );
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
