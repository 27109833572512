<template>
  <div class="wrap">
    <TopHeader :title="title" theme="nj_white" @goBack="goBack">
      <!--      <div class="top_absolute" slot="absolute_right">-->
      <!--        <a href="javascript:void(0)" class="btn_search" @click="callSearch">검색</a>-->
      <!--        <a href="javascript:void(0)" class="btn_store ml_17"  @click="callFavorite">장바구니</a>-->
      <!--      </div>-->
    </TopHeader>

    <div class="product_category_wrap">
      <!-- ref="productCategory" -->
      <ul class="product_category" :style="{ width: categoryWidth }" ref="ul">
        <!-- v-if="categorys.length > 0"  -->
        <li v-for="category in categorys" :key="category.categoryId" :data-code="category.categoryId">
          <a href="javascript:void(0)" :class="{ active: category.active }" @click="clickCategory">{{ category.categoryName }}</a>
        </li>
      </ul>
      <!--      <div v-else>카테고리가 존재하지 않습니다.</div>-->
    </div>

    <div class="contents_wrap" ref="contentsWrap" :style="!categorys ? 'border-width: 0' : null">
      <div>
        <ul class="category_list" v-if="storeList.length > 0">
          <li v-for="(store, index) in storeList" :key="store.storeId" :style="'z-index: ' + (storeList.length - index)">
            <div class="relative">
              <a
                href="javascript:void(0)"
                class="list_show_hide"
                :class="{ active: store.expand }"
                :data-store-id="store.storeId"
                @click="listShowHide"
              >
                <span class="store_name">{{ store.storeName }}</span>
                <span class="market_name">{{ store.marketName }}</span>
              </a>
              <div class="list_info">
                <Icon
                  v-if="store.favoriteCount"
                  type="icon_store_small"
                  :value="$formatPrice(typeof store.favoriteCount == 'number' ? store.favoriteCount : Number(store.favoriteCount))"
                />
                <!--<Icon type="icon_heart_small ml_10" :value="$formatPrice(store.likeCount)" />-->
                <Btn
                  type="type2 ml_10"
                  btnId="VIEWMORE"
                  value="더보기"
                  :link="{ marketId: store.marketId, storeId: store.storeId }"
                  @btnCallback="clickMore"
                />
              </div>
            </div>

            <transition name="fade">
              <div class="product_list_wrap" :class="{ active: true }" :style="cssProps" v-show="store.expand">
                <ul class="product_list mt_10">
                  <li
                    v-for="product in store.productList"
                    :key="product.id"
                    :style="
                      product.moreContents
                        ? 'min-height: 170px'
                        : product.paymentTypes
                        ? product.paymentTypes.length
                          ? 'min-height: 306px'
                          : 'min-height: 249px'
                        : 'min-height: 249px'
                    "
                  >
                    <!--                    product.moreContents? 'min-height: 170px' : '',-->
                    <!--                    product.paymentTypes? product.paymentTypes.length? product.paymentTypes : false : false-->
                    <ProductList
                      :product="product"
                      :viewCartIcon="store.viewCartIcon"
                      :moreUrl="{
                        marketId: store.marketId,
                        storeId: store.storeId,
                      }"
                      @moreCallback="clickMore"
                    />
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>
        <div v-else class="empty_txt">해당 카테고리 상품이 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import Btn from "../../components/Btn";
import Icon from "../../components/new/Icon";
import ProductList from "../../components/list/ProductList";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "CategoryProduct",
  props: {
    params: Object,
  },
  components: {
    TopHeader,
    Btn,
    Icon,
    ProductList,
  },
  computed: {
    ...mapState({
      title: (state) => state.categoryProduct.title,
      isLoading: (state) => state.categoryProduct.isLoading,
      changeTitle: (state) => state.categoryProduct.changeTitle,
      categorys: (state) => state.categoryProduct.categorys,
      categoryWidth: (state) => state.categoryProduct.categoryWidth,
      storeList: (state) => state.categoryProduct.storeList,
      customerMarginTop: (state) => state.categoryProduct.customerMarginTop,
      // refProductCategory: state => state.categoryProduct.refProductCategory,
    }),
    cssProps() {
      return {
        "--customer-margin-top": this.customerMarginTop,
      };
    },
  },
  beforeCreate: function () {
    //var categoryProductData = this.$route.params.categoryProduct;
    var categoryProductData = this.$route.query;

    this.$store.dispatch("categoryProduct/initState", {
      marketId: categoryProductData.marketId,
      categoryId: categoryProductData.categoryId,

      latitude: categoryProductData.latitude,
      longitude: categoryProductData.longitude,

      // pageType: 'special',// 일반 = null, 핫세일 = hot, 오늘 들어 왔어요 = today, 특산품 = special
      pageType: categoryProductData.pageType ? categoryProductData.pageType : null, // 일반 = null, 핫세일 = hot, 오늘 들어 왔어요 = today, 특산품 = special
    });
  },
  mounted: function () {
    if (this.$route.params.categoryProduct) {
      // dummy data 적용 ::: 개발모드
      // console.log(this.$route.params);
      // console.log('==============================');
      // this.initState({
      //   marketId: this.$route.params.categoryProduct.marketId,
      //   categoryId: this.$route.params.categoryProduct.categoryId,
      //   pageType: null,// 일반 = null, 핫세일 = hot, 오늘 들어 왔어요 = today, 특산품 = special
      // });
      // this.setTitle('오늘 들어 왔어요');// setTitle을 실행하면 카테고리 메뉴를 클릭해도 타이틀이 변경되지 않는다.
    }
  },
  methods: {
    ...mapMutations("categoryProduct", ["listShowHide", "setCategoryWidth", "setTitle", "setIsLoading", "pageDestroy"]),
    ...mapActions("categoryProduct", ["goBack", "initState", "clickCategory", "callFavorite", "callSearch", "clickMore"]),
    initParams: function (data = null) {
      if (data) {
        var sendData = JSON.parse(data);

        this.initState({
          marketId: sendData.categoryData.marketId,
          categoryId: sendData.categoryData.categoryId,
        });
      }
    },
  },
  beforeDestroy() {
    this.pageDestroy();
  },
  watch: {
    // categorys 데이터에 따라 영역의 가로 사이즈를 변경한다.
    categorys: async function () {
      // if (this.categorys.length > 0) {
      if (this.$refs.ul) {
        // 카테고리 영역에서 벗어난 메뉴가 활성화 될때 스크롤 좌표를 변경한다.
        if (!this.isLoading) {
          const windowWidth = window.innerWidth;
          // console.log(windowWidth);
          const categoryListWrap = this.$refs.ul.closest("div");

          setTimeout(function () {
            const categoryList = categoryListWrap.querySelectorAll("li");
            const categoryListLength = categoryList.length;
            for (var i = 0; i < categoryListLength; i++) {
              if (categoryList[i].querySelector("a").classList.contains("active")) {
                var listWidth = categoryList[i].offsetLeft + categoryList[i].offsetWidth;
                if (windowWidth < listWidth) {
                  categoryListWrap.scrollLeft = listWidth - categoryList[i].offsetWidth - 20;
                }
              }
            }
          }, 1);
          this.setIsLoading();
        }

        // 카테고리 목록에 변경이 있을때 스크롤 전체 가로 사이즈를 재조정 한다.
        var lis = await this.$refs.ul.getElementsByTagName("li");
        var ulWidth = 0;

        for (var i = 0, len = lis.length; i < len; i++) {
          ulWidth = ulWidth + lis[i].clientWidth;
        }
        this.setCategoryWidth(String(ulWidth + 60) + "px");
        this.$refs.contentsWrap.scrollTop = 0;
      }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
// 목록 노출여부 animation
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  margin-top: var(--customer-margin-top);
}

.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}
.contents_wrap {
  position: absolute;
  top: 114px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 20px;
  border-style: solid;
  border-color: $nj_divider_gray;
  border-width: 1px 0 0;
  overflow: auto;
}
.product_list_wrap {
  display: none;
  position: relative;
  &.active {
    display: block;
  }
}
.product_list {
  > li {
    display: inline-block;
    width: 50%;
    min-height: 306px;
    padding: 0 0 18px;
    vertical-align: top;
    &:nth-child(odd) {
      padding-right: 9px;
    }
    &:nth-child(even) {
      padding-left: 9px;
    }
  }
}

.category_list {
  > li {
    position: relative;
    padding: 0 0 10px;
    border-style: solid;
    border-color: $nj_divider_gray;
    border-width: 0 0 1px;
    background-color: $nj_background_gray;
    &:last-child {
      border-width: 0;
    }
    > div:first-child {
      position: relative;
      padding-top: 6px;
      background-color: $nj_background_gray;
      z-index: 999;
    }
  }
  .list_info {
    position: absolute;
    top: 20px;
    right: 0;
  }
}

.list_show_hide {
  display: inline-block;
  height: 60px;
  padding: 8px 36px 0 0;
  //padding: 0 36px 0 0;
  background-image: url("../../assets/images/arrow_bottom.png");
  background-repeat: no-repeat;
  background-position: right center;
  .store_name {
    display: block;
    font: normal normal bold 18px/28px Noto Sans CJK KR;
    letter-spacing: -1.08px;
    color: $nj_black;
  }
  .market_name {
    display: block;
    margin: -3px 0 0;
    font: normal normal normal 14px/20px Noto Sans CJK KR;
    letter-spacing: -0.84px;
    color: $nj_text_main_gray;
  }
  &:hover {
    color: $nj_black;
  }
  &.active {
    background-image: url("../../assets/images/arrow_top.png");
  }
}

.product_category_wrap {
  position: relative;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.product_category {
  width: 1000px;
  height: 70px;
  padding: 20px;
  overflow-y: hidden;
  > li {
    display: inline-block;
    height: 70px;
    a {
      display: inline-block;
      width: 100%;
      height: 30px;
      padding: 0 20px;
      font: normal normal medium 14px/20px Noto Sans CJK KR;
      letter-spacing: -0.84px;
      color: $nj_text_main_gray;
      line-height: 2;
      text-align: center;

      &.active {
        background-color: $nj_red;
        @include border-radius(20px);
        color: white;
        &:hover {
          color: white;
        }
      }
      &:hover {
        color: $nj_text_main_gray;
      }
    }
  }
}

.empty_txt {
  height: 100px;
  padding: 20px 0 0;
  @include font(15, 22, -1.2);
  text-align: center;
  color: $nj_placeholder_gray;
}
</style>
