<template>
  <div class="wrap">
    <div class="wrap_title">상품필수정보</div>
    <div class="wrap_content">
      <div class="info_item" v-for="(item, idx) in notationalInformations" v-bind:key="idx">
        <div class="info_title">·{{ item.title }}</div>
        <div class="info_content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductRequiredInfo",
  props: {
    notationalInformations: Array,
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.wrap {
  padding-top: 20px;
}

.wrap_title {
  font: normal normal normal 14px Noto Sans CJK KR Medium;
  letter-spacing: -0.84px;
  color: #585857;
  width: 100%;
  background: #e6e6e6;
  padding: 5px 20px;
  margin-bottom: 20px;
}

.wrap_content {
  padding: 0 20px;
}

.info_title {
  font: normal normal normal 14px/17px Noto Sans CJK KR Medium;
  letter-spacing: -0.84px;
  color: #1f1f1f;
}

.info_content {
  font-size: 13px;
  letter-spacing: -0.78px;
  color: #5d5d5d;
  margin-bottom: 10px;
  padding-left: 4px;
}

.info_item:last-child .info_content {
  margin-bottom: 0px;
}
</style>
