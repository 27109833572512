<template>
  <div :class="{ active: isToggle }" class="nj_select_wrap">
    <div class="cur_value_wrap" @click="isToggle = !isToggle">
      <div class="placeholder" v-if="!selected">
        {{ placeholder }}
      </div>
      <div class="cur_value" v-else>
        {{ selected }}
      </div>
      <div class="toggle_wrap" :class="{ active: isToggle }">
        <!--        <img src="@/assets/images/btn_back.png"/>-->
      </div>
    </div>
    <template v-if="isToggle">
      <div class="line_wrap">
        <div class="line"></div>
      </div>
      <div class="nj_option_wrap">
        <NJOption v-for="(item, idx) in items" v-bind:key="idx" :value="item" @click="onClick" :selected="selected"></NJOption>
      </div>
    </template>
  </div>
</template>

<script>
import NJOption from "./NJOption";
export default {
  name: "NJSelect",
  props: {
    placeholder: String,
    items: Array,
  },
  data: function () {
    return {
      isToggle: false,
      selected: "",
    };
  },
  components: {
    NJOption,
  },
  methods: {
    onClick: function (value) {
      this.selected = value;
      this.isToggle = false;
      this.$emit("select", value);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.nj_select_wrap {
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  border-radius: 20px;
  padding: 0 20px;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
  position: relative;
}

.nj_select_wrap .cur_value_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 15px 0;
}

.nj_select_wrap .cur_value_wrap > * {
  display: inline-block;
}

.nj_select_wrap .cur_value_wrap > *:first-child {
  width: calc(100% - 24px);
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
}

.nj_select_wrap .placeholder {
  color: $nj_placeholder_gray;
}

.nj_select_wrap .cur_value {
  color: $nj_black;
}

.nj_select_wrap .toggle_wrap {
  width: 24px;
  height: 24px;
  float: right;
  position: absolute;
  right: 0px;
  transform: rotate(-90deg);
  background-image: url("~image/btn_back.png");
  background-repeat: no-repeat;
  background-position: center;

  &.active {
    transform: rotate(90deg);
  }
}

.nj_select_wrap .line_wrap {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 0 20px;
  /*padding-bottom: 5px;*/
}

.nj_select_wrap .line_wrap .line {
  border-top: 1px solid $nj_divider_gray;
}

.nj_select_wrap .nj_option_wrap {
  width: 100%;
  max-height: 25vh;
  overflow: auto;
  position: absolute;
  /*border-top: 1px solid $nj_divider_gray;*/
  left: 0;
  padding: 5px 20px 0 20px;
  background: #ffffff;
  border-radius: 0 0 20px 20px;
}

.nj_select_wrap.active {
  border-radius: 20px 20px 0 0;
  box-shadow: none;
}
</style>
