<template>
  <div class="btn_wrap" @click="$emit('click')">
    <a class="panel_icon" :class="type" href="javascript:void(0)">
      <icon :type="iconType" />
    </a>
    <span class="panel_icon_value" :class="type" v-if="value">{{ value }}</span>
    <span class="panel_icon_bottom_txt" :class="type">{{ bottomText }}</span>
  </div>
</template>

<script>
import Icon from "@/components/new/Icon";
export default {
  name: "PanelBtn",
  components: { Icon },
  props: {
    type: {
      type: String,
      default: "call",
    },
    value: String,
    bottomText: String,
  },
  computed: {
    iconType: function () {
      if (this.type == "call") {
        return "icon_call_white";
      } else if (this.type == "regular") {
        return "icon_store_regular";
      } else if (this.type == "regular_active") {
        return "icon_store_regular_active";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn_wrap {
  text-align: center;

  .panel_icon {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #aaaaaa;
    border-radius: 25px;
  }

  .panel_icon_value {
    position: absolute;
    left: 50%;
    width: 100%;
    color: $nj_black;
    transform: translateX(-50%);

    &.regular,
    &.regular_active {
      @include font(10, 15, -0.2);
      bottom: 5px;
    }

    &.regular {
      color: $nj_black;
    }

    &.regular_active {
      color: $white;
    }
  }

  .panel_icon_bottom_txt {
    position: absolute;
    left: 50%;
    width: 100%;
    @include font(12, 18, -0.24);
    color: $nj_black;
    transform: translateX(-50%);
  }
}
</style>
