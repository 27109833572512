<template>
  <div class="wrap">
    <div>놀장 사용자 앱 리뉴얼 웹뷰 화면 : 2021-0804</div>
    <div class="mt_20">
      <ul>
        <li v-for="(route, idx) in $router.options.routes" v-bind:key="idx">
          <!--          <router-link :to="route.path">{{route.name}} ({{route.path}})</router-link>-->
          <router-link :to="{ name: route.name, params: route.params, query: route.query }">{{ route.name }} ({{ route.path }})</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Works",
  props: {},
  data: function () {
    return {};
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 20px;
}
ul {
  li {
    padding: 5px 0;
  }
}
</style>
