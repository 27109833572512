<template>
  <span :class="type ? type : ''">{{ value }}</span>
</template>

<script>
export default {
  name: "Icon",
  props: {
    type: String,
    value: String,
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.icon_store_small {
  display: inline-block;
  padding: 0 0 0 12px;
  background-image: url("~image/icon_store_small.svg");
  background-repeat: no-repeat;
  background-position: 0;
  font: normal normal normal 10px/15px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #6a6a6a;
  vertical-align: middle;
}
.icon_heart_small {
  display: inline-block;
  padding: 0 0 0 12px;
  background-image: url("~image/icon_heart_small.svg");
  background-repeat: no-repeat;
  background-position: 0 4px;
  font: normal normal normal 10px/15px Noto Sans CJK KR;
  letter-spacing: -0.6px;
  color: #6a6a6a;
  vertical-align: middle;
}
.icon_like {
  display: inline-block;
  //width: 19px;
  height: 18px;
  padding: 0 0 0 23px;
  background-image: url("~image/icon_like.svg");
  background-repeat: no-repeat;
  background-position: 0;
  @include font(12, 18, -0.24);
  color: #777;
  &.active {
    background-image: url("~image/icon_like_active.svg");
    color: $nj_red;
  }
}
.icon_like2 {
  display: inline-block;
  //width: 19px;
  height: 18px;
  padding: 0 0 0 23px;
  background-image: url("~svg/icon_like2.svg");
  background-repeat: no-repeat;
  background-position: 0;
  @include font(12, 18, -0.24);
  color: #777;
  &.active {
    background-image: url("~svg/icon_like2_active.svg");
    color: $nj_red;
  }
}
.icon_dislike2 {
  display: inline-block;
  //width: 19px;
  height: 18px;
  padding: 0 0 0 23px;
  background-image: url("~svg/icon_dislike2.svg");
  background-repeat: no-repeat;
  background-position: 0;
  @include font(12, 18, -0.24);
  color: #777;
  &.active {
    background-image: url("~svg/icon_dislike2_active.svg");
    color: $nj_red;
  }
}
.icon_dislike {
  display: inline-block;
  //width: 19px;
  height: 18px;
  padding: 0 0 0 23px;
  background-image: url("~image/icon_dislike.svg");
  background-repeat: no-repeat;
  background-position: 0;
  @include font(12, 18, -0.24);
  color: #777;
  &.active {
    background-image: url("~image/icon_dislike_active.svg");
    color: $nj_red;
  }
}

.icon_like_red_small {
  display: inline-block;
  padding: 0 0 0 13px;
  background-image: url("~image/icon_like_red_small.svg");
  background-repeat: no-repeat;
  background-position: left center;
  @include font(8, 12, -0.16);
  //font-family: tahoma;
  // font-family: "맑은고딕","Malgun Gothic";
  // font-family: "돋움",Dotum,"돋움체",DotumChe;
  color: $white;
  vertical-align: middle;
  &.type1 {
    padding: 0 0 0 11px;
    background-position: 0;
    color: $nj_text_main_gray;
    letter-spacing: -0.5px;
  }
}
.icon_cart_white_small {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("~image/icon_cart_white_small.svg");
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  text-indent: -9999px;
}
.icon_store_red_small {
  display: inline-block;
  padding: 0 0 0 11px;
  background-image: url("~image/icon_store_red.svg");
  background-repeat: no-repeat;
  background-position: 0;
  background-size: 10px 9px;
  @include font(8, 12, -0.16);
  font-family: tahoma;
  // font-family: "맑은고딕","Malgun Gothic";
  // font-family: "돋움",Dotum,"돋움체",DotumChe;
  color: $nj_text_main_gray;
  vertical-align: middle;
  letter-spacing: -0.5px;
}
.icon_arrow_left_small {
  display: inline-block;
  padding: 0 15px 3px 0;
  background-image: url("~image/icon_arrow_left_small.svg");
  background-repeat: no-repeat;
  background-position: right center;
  @include font(16, 22, -0.96);
  color: $nj_text_sub_gray;
}

.icon_call_white {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-image: url("~image/icon_call.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.icon_store_regular {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-image: url("~image/icon_store_regular.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.icon_store_regular_active {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-image: url("~image/icon_store_regular_active.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.icon_arrow_left_normal {
  display: block;
  padding: 0 15px 0 0;
  background-image: url("~image/icon_arrow_left_normal.svg");
  background-repeat: no-repeat;
  background-position: right center;
  @include font(18, 67, -1.08);
  color: $nj_black;
}
</style>
