<template>
  <div>
    <ReloadingIndicator :isReloading="isReloading" />
    <LoadingIndicator v-if="!isReloading && isProgressing" />
    <!-- 서버 점검 -->
    <!-- <div class="server_fix" v-on:click.prevent.self>
      <p><span style="color: red">서버 점검</span> 중입니다.</p>
      <p>신속히 처리하겠습니다.</p>
    </div> -->
    <div class="main_wrap" id="mainWrap">
      <div class="scroll_top" :class="{ active: showScroll }" @click="scrollTop">
        <img src="../assets/images/scroll.svg" alt="scroll button" loading="lazy" />
      </div>
      <div>
        <div class="top_banner" v-if="topBanner">
          <a href="javascript:void(0);" :data-title="topBanner.title" :data-link="topBanner.detailUrl" @click="clickBanner">
            <img
              style="width: 100%"
              :src="topBanner.bannerImage"
              :alt="topBanner.title"
              :data-title="topBanner.title"
              :data-detail-url="topBanner.detailUrl"
              @load="loadTopBanner"
              id="topBanner"
              loading="lazy"
            />
          </a>
        </div>
        <div class="main_header_wrap">
          <div class="main_header" style="display: flex; justify-content: space-between; align-items: center">
            <h1 class="header_logo" v-if="!appVersion">놀장</h1>
            <div class="main_address" v-else>
              <a href="javascript:void(0);" @click="moveToAddress">
                <span class="address" v-if="userAddress">
                  {{ userAddress.alias ? userAddress.alias : roadName(userAddress.roadNameAddress) }}
                </span>
                <span class="address" v-else>주소 지정</span>
              </a>
            </div>
            <div class="header_search" @click="moveToNativeSearch" v-if="appVersion">
              <div class="input_wrap">
                <div class="placeholder">상품 검색</div>
                <div class="input_btn">
                  <a href="javascript:void(0)" class="icon_clear_input" @click="clear" title="내용전체삭제" v-if="keyword.length">내용전체삭제</a>
                  <a href="javascript:void(0)" class="icon_input_search" @click="moveToNativeSearch" title="검색하기">검색하기</a>
                </div>
                <!--                <input-->
                <!--                  v-model="keyword"-->
                <!--                  placeholder="찾고 싶은 상품을 검색해주세요."-->
                <!--                  @submit="moveToNativeSearch"-->
                <!--                  disabled-->
                <!--                />-->
              </div>
              <div class="reporting_logo" style="" @click="clickBottom('report')">
                <img src="../assets/images/reporting_logo.svg" alt="신고센터" width="25" height="25" />
                <div>가격신고</div>
              </div>
            </div>
          </div>
          <div>
            <Tab :tabItems="topMenuItems" @click="setContentsType({ dataType: $event.dataType })" :isPreviousData="true" tabType="type1" />
          </div>
        </div>

        <div class="main_contents" :class="{ bg_white: contentsType !== 'home', web_contents: !appVersion }">
          <!-- scroll 원복처리 -->

          <div class="banner_wrap ignore_touch_x" :class="contentsType === 'event' ? 'absolute_hide' : null" @click="clickEvent">
            <swiper
              ref="mySwiper"
              :options="swiperOptions"
              :auto-destroy="true"
              :delete-instance-on-destroy="true"
              :cleanup-styles-on-destroy="true"
              @touchEnd="touchEnd"
              @slideChange="onSlideChange"
            >
              <swiper-slide v-for="(banner, index) in banners" :key="'banner_' + index">
                <img
                  :src="banner.bannerImage"
                  :alt="banner.title"
                  :data-title="banner.title"
                  :data-detail-url="banner.detailUrl"
                  :data-notice-id="banner.noticeId"
                  style="width: 100%; height: 100%; object-fit: contain"
                  loading="lazy"
                />
              </swiper-slide>
            </swiper>
            <span v-if="banners.length > 1" class="absolute_right"> {{ !slideActiveIndex ? "1" : slideActiveIndex }}/{{ banners.length }} </span>
          </div>

          <div class="tab_wrap">
            <!-- 수산시장 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'sea'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <DoubleTab
                    :largeTabItems="seaTabLargeTab.tabItems"
                    :largeTabActiveIndex="seaTabLargeTab.tabActiveIndex"
                    @clickLargeTab="switchSeaTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  />
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList :products="seaTabProducts.list" v-if="seaTabProducts.list && seaTabProducts.list.length" :timeSale="false" />
                  </div>
                </div>
              </div>
            </div>

            <!-- 화목한 특가 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'specialOffer'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <!-- <DoubleTab
                    :largeTabItems="specialTabLargeTab.tabItems"
                    :largeTabActiveIndex="specialTabLargeTab.tabActiveIndex"
                    :categoryId="this.$store.state.main.categoryId"
                    @clickLargeTab="specialTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  /> -->
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList :products="specialTabProducts.list" :timeSale="true" :categoryId="this.$store.state.main.categoryId" />
                  </div>
                </div>
              </div>
            </div>
            <!-- e: 수산시장 -->

            <!-- 농축산시장 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'home'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <DoubleTab
                    :largeTabItems="mainTabLargeTab.tabItems"
                    :largeTabActiveIndex="mainTabLargeTab.tabActiveIndex"
                    @clickLargeTab="switchMainTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  />
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList :products="mainTabProducts.list" :timeSale="false" v-if="mainTabProducts.list && mainTabProducts.list.length" />
                  </div>
                </div>
              </div>
            </div>
            <!-- e: 농축산시장 -->

            <!-- 축산 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'livestock'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <DoubleTab
                    :largeTabItems="livestockTabLargeTab.tabItems"
                    :largeTabActiveIndex="livestockTabLargeTab.tabActiveIndex"
                    @clickLargeTab="switchLivestockTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  />
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList
                      :products="livestockTabProducts.list"
                      :timeSale="false"
                      v-if="livestockTabProducts.list && livestockTabProducts.list.length"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 축산 -->

            <!-- N마트 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'n-mart'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <DoubleTab
                    :largeTabItems="nMartTabLargeTab.tabItems"
                    :largeTabActiveIndex="nMartTabLargeTab.tabActiveIndex"
                    @clickLargeTab="switchNMartTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  />
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList :products="nMartTabProducts.list" :timeSale="false" v-if="nMartTabProducts.list && nMartTabProducts.list.length" />
                  </div>
                </div>
              </div>
            </div>
            <!-- N마트 -->

            <!-- 산지직송 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'immediateDelivery'">
              <div class="deliveryMarkets_wrap">
                <div>
                  <!-- <DoubleTab
                    :largeTabItems="mainTabLargeTab.tabItems"
                    :largeTabActiveIndex="mainTabLargeTab.tabActiveIndex"
                    @clickLargeTab="switchMainTabLargeTabActiveIndex"
                    :mediumTabItems="[]"
                    tabType="type5"
                    large-tab-wrap-background-color="white"
                  /> -->
                  <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
                    <ProductList
                      :products="immediateDeliveryTabProducts.list"
                      :timeSale="false"
                      v-if="immediateDeliveryTabProducts.list && immediateDeliveryTabProducts.list.length"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 산지직송 -->

            <!-- 전통시장 -> 단골시장 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'direct'">
              <!-- 전통시장 -->
              <div>
                <div class="main_contents_title type1">
                  <div class="title icon_direct">단골시장</div>
                  <p class="txt"><span class="unit_line"></span> 포장 / 배송이 가능합니다.</p>
                </div>
                <MarketInfoList
                  :moveToMarket="moveToMarket"
                  :moveToMarketMap="moveToMarketMap"
                  :disableSearch="true"
                  :directMarkets="directMarkets"
                  v-if="directMarkets && directMarkets.length > 0"
                />
                <EmptyDirectMarket v-else />
              </div>
              <!-- e: 바로배달 -->

              <!-- 음식배달 -->
              <div v-if="deliveryFdStoreList && deliveryFdStoreList.length > 0">
                <div class="main_contents_title type1">
                  <div class="title icon_food_delivery">음식배달</div>
                  <p class="txt"><span class="unit_line"></span> 시장 음식을 배달합니다.</p>
                </div>

                <MarketItem
                  :markets="deliveryFdStoreList"
                  :moveToMarket="moveToMarket"
                  :moveToMarketMap="moveToMarketMap"
                  quickMarket="Y"
                  v-if="deliveryFdStoreList && deliveryFdStoreList.length > 0"
                />
                <!--              <EmptyDirectMarket v-else />-->
              </div>
              <!-- e: 음식배달 -->

              <!-- 단골상점 -->
              <!-- <div class="main_contents_unit" :class="{ type1: favoritesStore && favoritesStore.length > 0 }" v-if="userId" style="padding-top: 0">
                <div class="contents_title" style="height: auto">
                  <div class="title">단골상점</div>
                  <a href="javascript:void(0);" class="icon_more" v-if="favoritesStore && favoritesStore.length > 0" @click="moveToFavoritesStore"
                    >더보기</a
                  >
                </div>
                <div class="store_area" v-if="favoritesStore && favoritesStore.length > 0">
                  <div class="store_list type1 horizontal_list">
                    <StoreList :stores="favoritesStore" :regularBoolean="true" />
                  </div>
                </div>
                <div v-else>
                  <div class="empty_favorites_store">
                    <strong class="accent_txt"><span>고객님,</span> <br />아직 단골맺기를 하신 상점이 없네요.</strong>
                    <p class="txt">
                      자주 이용하는 상점은 "단골맺기"를 해보세요. 단골맺기한 상점의 상품에는 자동으로 "단골" 스티커가 부착되어 편리하게 이용하실 수
                      있어요.
                    </p>
                  </div>
                </div>
              </div> -->
              <!-- e: 단골상점 -->

              <!-- 최근에 구매했어요 -->
              <!-- <div class="main_contents_unit" v-if="userId">
                <div class="contents_title type1">
                  <div class="title">최근에 구매했어요.</div>
                </div>
                <div class="product_list type1" v-if="recentBuyProducts && recentBuyProducts.length > 0">
                  <ProductList :products="recentBuyProducts" :timeSale="false" />
                </div>
                <div v-else>
                  <div class="empty_recent_buy">
                    <strong class="accent_txt">고객님, 아직 구매하신 상품이 없네요.</strong>
                    <p class="txt">
                      시장에서 당일 준비된 값 싸고, 신선한 상품을 구매해보세요. 주문 후 2시간 이내에 고객님의 문 앞까지 배달됩니다.<br />
                      주문시간 : 오전 10시 ~ 오후 5시
                    </p>
                  </div>
                </div>
              </div> -->
              <!-- e: 최근에 구매했어요 -->
            </div>
            <!-- e: 전통시장 -->

            <!-- 전국배송 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'brand'">
              <div class="deliveryMarkets_wrap">
                <DoubleTab
                  :largeTabItems="brandTabLargeTab.tabItems"
                  :largeTabActiveIndex="brandTabLargeTab.tabActiveIndex"
                  @clickLargeTab="switchBrandTabLargeTabActiveIndex"
                  :mediumTabItems="[]"
                  tabType="type5"
                  large-tab-wrap-background-color="white"
                />
                <div
                  class="absolute_contents type2 delivery_market_products_wrap"
                  style="position: relative; top: unset; left: unset; right: unset; bottom: unset; overflow: unset"
                >
                  <div class="product_list vertical accept_touch_x">
                    <ProductList :products="brandTabProducts.list" :timeSale="false" v-if="brandTabProducts.list && brandTabProducts.list.length" />
                  </div>
                </div>
              </div>
            </div>
            <!-- e: 전국배송 -->

            <!-- 이벤트 -->
            <div class="tab_contents" style="overflow-y: hidden" v-if="contentsType === 'event'">
              <div>
                <TabList :tabItems="eventTabItems" type="type2" @click="changeEventTabItem({ dataType: $event.dataType })" />
                <div v-if="eventList">
                  <div v-if="eventContentsType == 'event' || eventContentsType == 'how'">
                    <BannerList :bannerList="eventList" :clickEvent="clickEvent" v-if="eventList.length > 0" />
                    <!-- <a href="https://pc.noljang.co.kr/">피시 놀장</a> -->
                  </div>
                  <div v-else-if="eventContentsType == 'notice'">
                    <ul class="event_txt_list">
                      <li v-for="notice in eventList" :key="notice.noticeId">
                        <a href="javascript:void(0)" class="title" :data-notice-id="notice.noticeId" @click="clickExplain">
                          {{ notice.title }}
                          <span class="date">{{ $moment(notice.registerDateTime).format("YYYY.MM.DD") }}</span>
                        </a>
                        <div class="explain_txt">{{ notice.content }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- e: 이벤트 -->

            <div class="copyright">
              <span>
                (주)마켓트리
                <span v-if="!appVersion">
                  |
                  <a href="https://market-tree.com" target="_blank" style="text-decoration: underline !important"> 회사소개 </a>
                </span> </span
              ><br />
              대표자 : 이광명<br />
              사업자등록번호 : 836-86-00441<br />
              통신판매업 : 2019-서울금천-1900<br />
              주소 : 서울특별시 강남구 테헤란로25길 23 3층<br />
              고객센터 : 02-2039-6742 | 팩스 : 02-6953-6239<br />
              고객지원: 평일 09:30 - 18:30 (점심시간 12:30~14:00)<br />
              전화상담가능시간: 평일 10:00 - 15:00 (점심시간 12:30~13:30)<br />
              E-MAIL : noljang@market-tree.com<br />
              ⓒCOPYRIGHT 2016 - ALL RIGHTS RESERVED BY market Tree.INC<br /><br />

              (주)마켓트리는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 상품거래정보 및 거래와 관련하여 놀장에 등록된 판매자의 고의 또는
              과실로 소비자에게 발생하는 손해는 (주)마켓트리가 책임을 지지 않습니다. 상품 및 거래에 관하여 보다 정확한 정보는 해당 판매자에게 직접
              확인하여 주시기 바랍니다.
            </div>
          </div>
        </div>

        <Bottom :clickBottom="clickBottom" />
      </div>

      <transition name="slide-fade">
        <div class="all_menu_container active" v-if="showAllmenu">
          <div class="all_menu_wrap">
            <AllMenu :closeFunc="closeAllMenu" :userId="userId" :showAllmenu="showAllmenu" />
          </div>
          <div class="all_menu_bg" @click="setAllmenu"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import SearchInput from "@/components/new/SearchInput";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import ReloadingIndicator from "@/components/new/ReloadingIndicator";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import TabList from "@/components/new/TabList";
import ProductList from "@/components/new/ProductList";
import StoreList from "@/components/new/StoreList";

import MarketItem from "@/components/new/main/MarketItem";
import EmptyDirectMarket from "@/components/new/main/EmptyDirectMarket";
import MarketInfoList from "@/components/new/MarketInfoList";

import BannerList from "@/components/new/BannerList";

// import Modal from '@/components/new/Modal';
import Bottom from "@/components/new/Bottom";
import AllMenu from "@/page/AllMenu";
import DoubleTab from "@/components/new/DoubleTab";
import Tab from "@/components/new/Tab";
import router from "@/router";
import app from "@/App.vue";

export default {
  name: "Main",
  data: function () {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
        },
        slidesPerView: "auto",
        touchRatio: 0.2,
        loop: true,
      },
      specialArray: [0, 1, 2],
      nodeEnv: process.env.NODE_ENV,
      showScroll: false,
      lastScroll: 0,
      keyword: "",
    };
  },
  methods: {
    ...mapActions(["updateState"]),
    ...mapMutations("main", [
      "setSlideActiveIndex", // 슬라이딩 활성화 인덱스
      "moveToMarket", // 시장 메인으로 이동 - 더보기 클릭시
      "moveToMarketMap", // 시장 지도
      "moveToFavoritesStore",
      "moveToAddress",
      "moveToNativeSearch",
      "moveToCart",
      "reset", // 페이지 이동시 초기화
      "setAllmenu",
      "closeAllMenu",
      "setPreviousRoutePath",
      "initHtmlScrollTop",
      "setTabBarOptions",
    ]),
    ...mapActions("main", [
      "initState",
      "goBack",
      "setContentsType", // 상단 탭 클릭
      "clickCategory",
      "changeEventTabItem",
      "clickEvent", // 이벤트 클릭 목록
      "clickBanner", // 클릭 배너
      "clickExplain", // 공지사항
      "loadTopBanner",
      "scrollEvent",
      "onBackPressedEvent", // 안드로이드 백 버튼 클릭 시 이벤트
      "mainWrapTouchStart",
      "mainWrapTouchMove",
      "mainWrapTouchEnd",
      "switchMainTabLargeTabActiveIndex",
      "switchLivestockTabLargeTabActiveIndex",
      "switchNMartTabLargeTabActiveIndex",
      "switchSeaTabLargeTabActiveIndex",
      "switchBrandTabLargeTabActiveIndex",
      "specialTabLargeTabActiveIndex",
    ]),
    clickBottom(type) {
      if (type == "allmenu") {
        this.setAllmenu();
      } else if (type == "search") {
        this.$router.push({ name: "전체시장 보기" });
      } else if (type == "sea") {
        this.setContentsType({ dataType: "sea" });
        this.initHtmlScrollTop();
      } else if (type == "cart") {
        router.push({ name: "장바구니", params: { reload: true } });
      } else if (type === "report") {
        router.push("report");
      }
    },
    touchEnd() {
      // 슬라이딩 배너에서 사용자 터치가 종료 되었을때 autoplay 적용
      const _this = this;
      _this.swiper?.autoplay?.stop();
      setTimeout(function () {
        _this.swiper?.autoplay?.start();
      }, 1000);
    },
    onSlideChange() {
      // 현재 슬라이드 index 설정
      this.setSlideActiveIndex((this.$refs.mySwiper.$swiper.activeIndex % this.banners.length) + 1);
    },
    initParams: function (data) {
      if (typeof data == "string") {
        data = JSON.parse(data);
      }

      // userId 값이 있을 경우 로컬 userId 값을 초기화
      if (data?.userId) {
        let userId = data.userId;

        if (userId.indexOf("?userId=") > -1) {
          userId = userId.substring(0, userId.indexOf("?userId="));
          data.userId = userId;
          localStorage.setItem("userId", userId);
        }
      }

      // firstInit 값이 Y일 경우 메인 페이지 팝업 안 띄움
      if (data.firstInit === "Y") {
        localStorage.removeItem("isNoljangPopup");
        this.setContentsType({ dataType: "home" });
      }

      if (this.$route.params.tabType) {
        data.tabType = this.$route.params.tabType;
      }

      this.$store.dispatch("rootInitState", data);
      this.$store.dispatch("main/initState", data);

      // if (this.contentsType && this.contentsType !== 'sea') {
      //   setTimeout(() => {
      //     this.setTabBarOptions(this.contentsType);
      //   }, 1);
      // }
    },
    onBackPressed() {
      if (document.querySelector("#modals-container .vm--container")) {
        this.$modal.hideAll();
      } else {
        this.onBackPressedEvent(() => {
          this.goBack();
        });
      }
    },
    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    watchScrollY() {
      const scrollY = window.scrollY;
      if (scrollY === 0 || scrollY > this.lastScroll) {
        this.showScroll = false;
      } else if (scrollY < this.lastScroll) {
        this.showScroll = true;
      }

      this.lastScroll = scrollY;
      this.scrollEvent();
    },
    clear() {
      this.keyword = "";
    },
    roadName(address) {
      const index = [...address].findIndex((e) => e === "(");
      return address.slice(index + 1, address.length - 1);
    },
  },
  beforeCreate: function () {
    // userId가 url 쿼리에 있을 경우 로컬 userId를 초기화
    const userId = this.$route.query.userId;
    if (userId) {
      this.$store.dispatch("initUserId", { userId });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setPreviousRoutePath(from.path);
      next();
    });
  },
  components: {
    AllMenu,
    TabList,
    Swiper,
    SwiperSlide,
    ReloadingIndicator,
    LoadingIndicator,
    ProductList,
    MarketItem,
    MarketInfoList,
    Bottom,
    StoreList,
    EmptyDirectMarket,
    BannerList,
    DoubleTab,
    Tab,
    SearchInput,
  },
  computed: {
    app() {
      return app;
    },
    ...mapState({
      categoryId: (state) => state.main.categoryId,
      specialOffer: (state) => state.main.specialOffer,
      isReloading: (state) => state.main.isReloading,
      isProgressing: (state) => state.main.isProgressing, // loading
      contentsType: (state) => state.main.contentsType, // 상단탭 활성화 변수
      topBanner: (state) => state.main.topBanner, // 상단 배너
      banners: (state) => state.main.banners, // 슬라이딩 배너
      slideActiveIndex: (state) => state.main.slideActiveIndex, // 슬라이딩 배너 활성화 index
      directMarkets: (state) => state.main.directMarkets, // 바로배달
      deliveryMarkets: (state) => state.main.deliveryMarkets, // 전국배송 시장별
      deliveryMarketsProducts: (state) => state.main.deliveryMarketsProducts.list, // 전국배송 상품별

      deliveryFdStoreList: (state) => state.main.deliveryFdStoreList, // 음식배달

      recentBuyProducts: (state) => state.main.recentBuyProducts, // 최근 구매
      favoritesStore: (state) => state.main.favoritesStore, // 단골상점
      topMenuItems: (state) => state.main.topMenuItems, // 상단메뉴

      eventContentsType: (state) => state.main.eventContentsType, // 이벤트/정보 페이지에서 활성화탭(event, notice, how)
      eventTabItems: (state) => state.main.eventTabItems, // 이벤트 서브 탭 : 이벤트-event, 공지사항-notice, 사용법-how
      eventList: (state) => state.main.eventList, //

      mainTabProducts: (state) => state.main.mainTabProducts,
      mainTabLargeTab: (state) => state.main.mainTabLargeTab,

      specialTabLargeTab: (state) => state.main.specialTabLargeTab,
      specialTabProducts: (state) => state.main.specialTabProducts,

      seaTabProducts: (state) => state.main.seaTabProducts,
      seaTabLargeTab: (state) => state.main.seaTabLargeTab,

      brandTabProducts: (state) => state.main.brandTabProducts,
      brandTabLargeTab: (state) => state.main.brandTabLargeTab,

      nMartTabProducts: (state) => state.main.nMartTabProducts,
      nMartTabLargeTab: (state) => state.main.nMartTabLargeTab,

      livestockTabProducts: (state) => state.main.livestockTabProducts,
      livestockTabLargeTab: (state) => state.main.livestockTabLargeTab,

      immediateDeliveryTabProducts: (state) => state.main.immediateDeliveryTabProducts,

      showAllmenu: (state) => state.main.showAllmenu, // 전체메뉴 노출여부
      deliveryMarketsType: (state) => state.main.deliveryMarketsType, // 상단탭 전체상품 or 전체시장 선택
      deliveryMarketsLocalTab: (state) => state.main.deliveryMarketsLocalTab, // 전국배송 지역별 분류 탭
      deliveryMarketsLocal: (state) => state.main.deliveryMarketsLocal, // 전국배송 지역별 시장
      deliveryMarketsProductsLargeTab: (state) => state.main.deliveryMarketsProductsLargeTab, // 전체상품 대분류 탭 리스트
      deliveryMarketsProductsMediumTab: (state) => state.main.deliveryMarketsProductsMediumTab, // 전체상품 중분류 탭 리스트
      topBannerHeight: (state) => state.main.topBannerHeight, // 전체상품 중분류 탭 리스트

      /////////////////////////////////////////////////////////////////////////////
      // 전역 store
      userId: (state) => state.userId, // 사용자 아이디
      userAddress: (state) => state.userAddress,
      userCartTotalCount: (state) => state.userCartTotalCount, // 장바구니 카운터
      appVersion: (state) => state.appVersion, // 장바구니 카운터
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper; // 배너
    },
    isDevMode() {
      return this.$route.query.devMode;
    },
  },
  created() {
    window.initParams = this.initParams;
    window.onBackPressed = this.onBackPressed;
    window.authResult = this.authResult;

    window.removeEventListener("scroll", this.watchScrollY);
    window.removeEventListener("resize", this.loadTopBanner);
    window.addEventListener("scroll", this.watchScrollY);
    window.addEventListener("resize", this.loadTopBanner);
  },
  beforeMount() {},
  mounted() {
    this.swiper.slideTo(0, 0, false); // 배너

    setTimeout(() => {
      if (this.appVersion === null) {
        this.initParams(this.$route.params);
      }
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchScrollY);
    window.removeEventListener("resize", this.loadTopBanner);
  },
  updated() {
    this.scrollEvent();
  },
  watch: {
    banners(val) {
      if (val.length) {
        this.swiper.autoplay.start();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header_search {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 15px;

  .input_wrap {
    background-color: white;
    border-radius: 50px;
    width: 120px;
    position: relative;
    height: 100%;

    .placeholder {
      padding: 11px 15px 9px;
      line-height: 1;
      color: $placeholder-color;
    }

    input {
      width: 100%;
      padding: 7px 65px 7px 15px;
      line-height: 1;
      border: none;
      border-radius: 50px;
      font-size: 15px;
    }
  }

  .input_btn {
    display: flex;
    align-items: center;
    justify-content: right;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0;

    .icon_input_search {
      display: block;
      background-image: url("~svg/search_orange.svg");
      background-size: contain;
      width: 20px;
      height: 20px;
    }

    .icon_clear_input {
      width: 15px;
      height: 15px;
      background-image: url("~svg/icon_clear_input.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.reporting_logo {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
  }

  div {
    color: yellow;
    font-size: 10px;
    font-weight: bold;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  // transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transition: all 0.3s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 1;
}

.all_menu_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 98;

  &.active {
    display: block;
  }
}
.all_menu_wrap {
  position: relative;
  height: 100%;
  width: 280px;
  background-color: #fff;
  z-index: 2;
}

.all_menu_bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
//@keyframes animation {
//  0% {left:-100%;}
//  100% {left:0; width: 70%;}
//}

@media (max-width: 280px) {
  .all_menu_wrap {
    width: 100%;
  }
}

//@media (min-width: 330px) {
//  .all_menu_wrap {
//    margin-right: 50px;
//  }
//  .all_menu_container {
//    .icon_close_white {
//      display: inline-block;
//    }
//  }
//}
//
//@media (min-width: 400px) {
//  .all_menu_wrap {
//    margin-right: 80px;
//  }
//}
//@media (min-width: 500px) {
//  .all_menu_wrap {
//    margin-right: 180px;
//  }
//}
//@media (min-width: 600px) {
//  .all_menu_wrap {
//    margin-right: 280px;
//  }
//}
//@media (min-width: 700px) {
//  .all_menu_wrap {
//    margin-right: 380px;
//  }
//}

.server_fix {
  flex-direction: column;
  pointer-events: none;
  background-color: white;
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main_wrap {
  &.fixed {
    .main_header_wrap {
      position: fixed;
      width: 100%;
      top: 0;
    }

    .main_contents {
      background-color: #f6f6f6;
      padding-top: 100px;
      //padding: 146px 0 0;
    }

    .web_contents {
      padding-top: 100px;
    }
  }

  .scroll_top {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 7%;
    right: 15px;
    width: 70px;
    height: 70px;
    z-index: 999;
    transition: 0.5s ease-out;

    &.active {
      visibility: visible;
      opacity: 1;
      bottom: 90px;
    }

    img {
      position: absolute;
      width: 70px;
      height: 70px;
      cursor: pointer;
      object-fit: contain;
    }
  }
}
.top_banner {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #ddd;
}

.main_header_wrap {
  position: relative;
  background-color: $white;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  z-index: 97;

  &.fixed {
    position: fixed;
    width: 100%;
  }

  .main_header {
    position: relative;
    height: 58px;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    background-color: #ff563f;

    .header_logo {
      display: inline-block;
      width: 110px;
      height: 37px;
      margin: 10px 0 0 19px;
      background-repeat: no-repeat;
      background-position: 0;
      background-size: contain;
      text-indent: -9999px;
      background-image: url("~image/noljang_white.svg");
    }

    .absolute_right {
      top: 18px;
      right: 25px;
    }
  }

  .main_address {
    display: inline-block;
    position: relative;
    height: 100%;
    max-width: 40%;
    background-image: url("~svg/marker.svg");
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px 20px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;
      width: 10px;
      height: 10px;
      background-image: url("~svg/arrow_down.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    > a {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      margin-left: 35px;
      @include font(14, 20, -0.64, 400);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address {
      position: relative;
      color: white;
      font-weight: bold;
    }
  }
}

:root {
  --md-theme-default-primary: $gray;
  --md-theme-default-accent: $gray;
  --md-theme-default-theme: $gray;
}

.md-theme-default .main_address a:not(.md-button):hover {
  color: $gray !important;
  color: var(--md-theme-default-primary-on-background, $gray) !important;
  //color: $gray;
  //&:hover, &:active {
  //  color: $gray !important;
  //}
}

.main_contents {
  position: relative;
  top: unset;
  bottom: unset;
  overflow-y: unset;
  /*
  position: absolute;
  top: 146px;
  bottom: 80px;
  width: 100%;
  background-color: $background-color-type1;
  overflow-x: hidden;
  overflow-y: auto;
  */
  &.bg_white {
    background-color: $white;
  }
}
.main_contents_unit {
  margin: 10px 0 0;
  background-color: $white;
  &.type1 {
    padding: 0 0 15px;
  }
}
.main_contents_title {
  position: relative;
  padding: 0 0 0 15px;
  background-color: $white;

  &.type1 {
    min-height: 54px;
    padding: 17px 16px 10px 120px;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    .title {
      position: absolute;
      top: 15px;
      left: 15px;
      @include font(17, 24, 0.4, bold);
      &.icon_direct {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_N_mart.svg");
        background-repeat: no-repeat;
        background-position: 4px 0;
      }
      &.icon_anywhere {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_anywhere.svg");
        background-repeat: no-repeat;
        background-position: 2px 2px;
      }
      &.icon_food_delivery {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_food_delivery.svg");
        background-repeat: no-repeat;
        background-position: 2px 0;
      }
    }
    .txt {
      position: relative;
      padding: 0 0 0 20px;
      @include font(14, 20, 0);
      color: $black;
      //> span {
      //  margin: 0 7px;
      //}
    }
    .unit_line {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 7px;
      width: 1px;
      height: 13px;
      background-color: $black;
    }
  }
  .add_type1 {
  }
  .absolute_right {
    right: 15px;
  }
}

.empty_favorites_store {
  padding: 28px 15px 40px 100px;
  background-image: url("~svg/icon_empty_favorites_store.svg");
  background-repeat: no-repeat;
  background-position: 20px 26px;
}
.empty_recent_buy {
  padding: 28px 15px 40px 100px;
  background-image: url("~svg/icon_empty_recent_buy.svg");
  background-repeat: no-repeat;
  background-position: 20px 26px;
}
.empty_favorites_store,
.empty_recent_buy {
  .accent_txt {
    @include font(16, 22, 0);
    color: $black;
    span {
      font-size: 18px;
      //@include font(18, 24, 0);
    }
  }
  .txt {
    margin: 11px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
}

.swiper-container,
.swiper-slide {
  //max-height: 347px;
  background-color: $white;
  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.banner_wrap {
  position: relative;
  &.absolute_hide {
    position: absolute;
    top: -99999px;
  }
  //max-height: 347px;
  .absolute_right {
    position: absolute;
    top: auto;
    bottom: 20px;
    right: 15px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.5);
    @include border-radius(15px);
    @include font(13, 24, -0.2);
    color: $white;
    z-index: 95;
    &:hover {
      color: $white;
    }
  }
  img {
    width: 100%;
    height: 100%;
    //max-height: 347px;
    object-fit: cover;
  }
}
.tab_wrap {
  background-color: $white;

  .event_tab {
    padding-top: 20px;
  }
}
.copyright {
  padding: 17px 17px 100px 17px;
  @include font(12, 18, -0.48);
  color: $gray-light;
  background-color: #f4f4f4;
}
.banner_list {
  li {
    padding: 0px 0px 10px 0px;
    text-align: center;
    &:last-child {
      padding: 0px;
    }
  }
}
.explain_txt {
  display: none;
  padding: 0 0 18px;
  @include font(14, 24, 0);
  color: $gray-normal;
  white-space: pre-line;
  &.active {
    display: block;
  }
}

.event_txt_list {
  padding: 5px 16px 0;
  > li {
    position: relative;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    &:last-child {
      border-width: 0;
    }
    .title {
      display: block;
      padding: 18px 80px 10px 0;
      @include font(15, 20, 0.37, medium);
      color: $black;
      &:hover,
      &:active {
        color: $black;
      }
    }
    .date {
      position: absolute;
      top: 18px;
      right: 0;
      @include font(14, 20, 0.37);
      font-weight: normal;
      color: $gray-normal;
    }
  }

  .md-theme-default a:not(.md-button) {
    color: #000 !important;
  }
}
#marketsList {
  margin-top: 200px;
}

.deliveryMarkets_wrap {
  &.fixed {
    .deliveryMarkets_tab_wrap {
      position: fixed;
      top: 147px;
      z-index: 999;
      width: 100vw;
    }
  }
  .deliveryMarkets_tab_wrap {
    margin-bottom: 1px;
    .deliveryMarkets_type_tab_list {
      display: flex;
      height: 42px;
      .deliveryMarkets_type_tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        transition: background-color 0.15s;
        @include font(16, 16, 0, normal);
        background-color: $background-color-type1;
        color: $placeholder-color;

        &.blue {
          background-color: #0070d8;
          color: white;
          font-size: 0;
          background-size: 70px;
          background-repeat: no-repeat;
          background-position: center;

          &:first-child {
            background-image: url("~image/sea-m-2.svg");
          }

          &:last-child {
            background-size: 55px;
            background-image: url("~image/sea-b-2.svg");
          }
        }

        &.blue.active {
          background-color: white;
          color: #0070d8;
          font-size: 0;
          &:first-child {
            background-image: url("~image/sea-m-1.svg");
          }

          &:last-child {
            background-image: url("~image/sea-b-1.svg");
          }
        }

        &.green {
          background-color: green;
          color: white;
        }

        &.green.active {
          background-color: white;
          color: green;
        }

        &.active {
          @include font(16, 16, 0, bold);
          background-color: $white;
          color: $active-color;
          &.markets {
            border: {
              style: solid;
              width: 1px;
              color: transparent;
              left-color: $border-type1;
            }
          }
        }
      }
    }
  }
}

.delivery_market_products_wrap {
  min-height: calc(100vh - 318px);
}
</style>
