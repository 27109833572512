import router from "@/router";

const state = {
  title: "상품옵션",
  productId: null,
  userId: null,
  // options: [],
  product: false,
  isProgressing: false,
  quantity: 1,
  totalPrice: null,
  totalWeight: null,
  extraChargeInfo: null,
  isRequiredCheckbox: false,
};

const mutations = {
  initState: function (state, data) {},
  updateState: function (state, { type, updateData }) {
    state[type] = updateData;
  },
  goBack: function () {
    this._vm.$navigateBack();
  },
  setIsProgressing: function (state, isProgressing) {
    state.isProgressing = isProgressing;
  },

  // 장바구니에 상품 추가 시 호출되는 콜백
  onCartChanged(state, data) {
    if (typeof data == "string") {
      data = JSON.parse(data);
    }
    const marketCart = data;

    // 현재 장바구니 데이터 조회
    let cart = localStorage.getItem("userCart");
    if (cart) {
      cart = JSON.parse(cart);
    } else {
      cart = [];
    }

    const marketId = marketCart.marketId;

    // 해당 시장 ID로 추가된 장바구니 데이터가 있는지 체크
    const targetCart = cart.find((item) => {
      return item.marketId == marketId;
    });

    // 해당 시장 장바구니 데이터가 있는 경우
    if (targetCart) {
      const addProduct = marketCart.products[0];
      let updateCompoundKey = addProduct.compoundKey;
      if (targetCart.products.length > 0) {
        const targetProducts = targetCart.products.filter((product) => product.compoundKey === updateCompoundKey);
        if (targetProducts.length) {
          targetCart.products.forEach((product) => {
            if (product.compoundKey === updateCompoundKey) {
              product.quantity = product.quantity + addProduct.quantity;
            }
          });
        } else {
          targetCart.products.unshift(marketCart.products[0]);
          // targetCart.products.push(marketCart.products[0]);
        }
      }
    } else {
      // 해당 시장 장바구니 데이터가 없는 경우 신규 추가
      cart.push(marketCart);
    }

    // 마켓기준으로 갱신된 상품의 마켓순서를 첫번째로 변경
    const targetMarket = cart.filter((market) => market.marketId == marketId);
    cart.forEach((item) => {
      if (item.marketId != targetMarket[0].marketId) {
        targetMarket.push(item);
      }
    });

    // 장바구니 데이터 재설정
    this.dispatch("rootInitState", { cart: targetMarket });
  },

  setDeviceCartData: function (state) {
    const product = state.product;
    let compoundKey = `${product.productId}`;

    let isActiveOption = false;

    if (product?.options.length > 0) {
      product.options.forEach((option, index) => {
        const activeOptionItems = option.checkboxInfo.options.filter((opt) => opt.active);
        if (activeOptionItems.length) {
          // compoundKey = compoundKey + (index === 0 ? '!' : '#') + option.checkboxInfo.name + '@' + activeOptionItems.map(item => item.code).join();
          isActiveOption = true;
          compoundKey =
            compoundKey +
            (compoundKey.indexOf("!") == -1 ? "!" : "#") +
            option.checkboxInfo.name +
            "@" +
            activeOptionItems.map((item) => item.code).join();
        }
      });
    }
    if (state.isRequiredCheckbox) {
      if (!isActiveOption) {
        this._vm.$showToast("옵션 선택은 필수 입니다.");
        return;
      }
    }

    const data = {
      marketId: product.marketId,
      products: [
        {
          compoundKey: compoundKey,
          quantity: state.quantity,
          productName: product.productName,
          unitNumber: product.unitNumber,
          unitName: product.unitName,
          storeId: product.storeId,
          deliveryMethod: product.deliveryMethod,
        },
      ],
    };
    // console.log(data);

    let isShowModal = false;

    const extraChargeInfo = state.extraChargeInfo;
    if (extraChargeInfo && extraChargeInfo?.basicUnit) {
      if (extraChargeInfo.chargeCd === "WT") {
        if (state.totalWeight > extraChargeInfo.basicUnit) {
          isShowModal = true;
        }
      }
      if (extraChargeInfo.chargeCd === "BOX" && state.product.boxPkgYn === "Y") {
        if (state.quantity > extraChargeInfo.basicUnit) {
          isShowModal = true;
        }
      }
    }

    if (isShowModal) {
      const _this = this;
      const modalName = "addCartModal";

      const msg =
        extraChargeInfo.chargeCd === "WT"
          ? `
                <div>
                    <p class="mt_10 center">
                        기본배송비 무게(${this._vm.$formatWeight(extraChargeInfo.basicUnit)}) <br />
                        ${_this._vm.$formatWeight(state.totalWeight - extraChargeInfo.basicUnit)} 초과, 
                        배송비 ${_this._vm.$formatPrice(
                          extraChargeInfo.conditionCharge * Math.floor(state.totalWeight / extraChargeInfo.basicUnit),
                        )}원 추가
                    </p>
                </div>
              `
          : `
                <div>
                    <p class="mt_10 center">
                        기본배송비 박스 수 (${extraChargeInfo.basicUnit} 박스) <br />
                        박스 ${state.quantity - extraChargeInfo.basicUnit} 초과, 
                        배송비 ${_this._vm.$formatPrice(extraChargeInfo.conditionCharge * (state.quantity - extraChargeInfo.basicUnit))}원 추가
                    </p>
                </div>
              `;

      const compProps = {
        title: "장바구니 담기",
        msgArr: [`<p class="center"><strong>추가 배송비가 발생합니다.</strong></p>`, msg], // html 코드 적용 가능
        btnArr: [
          {
            txt: "확인",
            type: "type1 inverse",
            // data: { id: "ok" },
            btnCallback: function () {
              _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
              console.log(state);

              _this._vm.$callNative("setCartCompoundKey", { cartData: data });
              _this.commit("productOptions/onCartChanged", data);
              _this._vm.$navigateBack();
              _this._vm.$showToast("장바구니에 상품을 담았습니다.");
            },
          },
        ],
        closeModal: function () {
          // X 버튼 클릭시
          // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
          _this._vm.$modal.hideAll();
        },
      };
      //
      this.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
          // seconds: 3
        },
        { root: true },
      );
    } else {
      console.log("setCartCompoundKey");
      const newData = {
        marketId: data.marketId,
        products: data.products.map((a) => {
          return {
            compoundKey: a.compoundKey,
            deliveryMethod: a.deliveryMethod,
            productName: a.productName.replace(RegExp(/\, /g), ",").replaceAll("[", "(").replaceAll("]", ")"),
            quantity: a.quantity,
            storeId: a.storeId,
            unitName: a.unitName,
            unitNumber: a.unitNumber.toString(),
          };
        }),
      };

      console.log(newData);
      this._vm.$callNative("setCartCompoundKey", { cartData: newData });
      this.commit("productOptions/onCartChanged", data);
      this._vm.$navigateBack();
      this._vm.$showToast("장바구니에 상품을 담았습니다.");
    }
  },

  reset: function (state) {
    // state.productId = null;
  },
};

const actions = {
  initState: async function (store, data) {
    if (!data?.productId) {
      return false;
    }
    store.commit("setIsProgressing", true);
    try {
      // 상품 정보 조회
      const optionsUrl = `v3/market/delivery/product/${data.productId}/option/information`;
      const optionsResponse = await this._vm.$axios1.get(optionsUrl);
      if (optionsResponse.data.responseCode == 2000) {
        state.product = optionsResponse.data.result;
        state.product.options = state.product?.options?.length && state.product?.options.filter((option) => option?.deleteYn === "N");

        state.totalPrice = state.product.discountPrice ?? state.product?.price;
        state.quantity = state.product.productId === 102068 ? 2 : 1; // 초록팜마트 고춧가루 최소 주문개수 예외처리 TODO 추후 원복하거나 삭제
        state.totalWeight = state.product?.weight;

        let isRequiredCheckbox = false;

        if (state.product?.options) {
          state.product.options.forEach((option) => {
            option.checkboxInfo = {
              type: "type5",
              name: option.optionGroupId,
              selectCount: option.selectCount,
              options: option.items.map((item, index) => {
                if (option.requiredYn === "Y" && index === 0) {
                  state.totalPrice = state.totalPrice + item.optionItemPrice;
                  state.totalWeight = state.totalWeight + item.optionWeight;
                }

                if (item.optionWeight === 13531353) {
                  // 프로세스 예외처리 13531353
                  item.optionWeight = state.product?.weight;
                  isRequiredCheckbox = true;
                }
                return {
                  code: `${item.optionItemId}`,
                  // text: String(item.optionItemName) + ' ' + String(item.optionWeight && item.optionWeight > 0 ? this._vm.$formatWeight(item.optionWeight) : ''),
                  text:
                    String(item.optionItemName) +
                    " " +
                    String(item.optionWeight && item.optionWeight != 0 ? this._vm.$formatWeight(item.optionWeight) : ""),
                  add: `<strong>${this._vm.$formatPrice(item.optionItemPrice)}</strong>원`,
                  value: item.optionItemPrice,
                  weight: item.optionWeight === 13531353 ? state.product?.weight : item.optionWeight, // 프로세스 예외처리 13531353
                  active: option.requiredYn === "Y" && index === 0 ? true : false,
                };
              }),
            };
          });
        }

        state.isRequiredCheckbox = isRequiredCheckbox;
        // debugger;
        if (isRequiredCheckbox) {
          // state.product.weight = 0;
          state.totalWeight = 0;
        }

        store.dispatch("setTotalPrice");
      } else {
        // logError(optionsResponse.data, "상품 옵션 조회에 실패하였습니다.");
      }
    } catch (e) {
      console.log(e);
    }
    store.commit("setIsProgressing", false);
    store.dispatch("getDeliveryExtraCharge");
  },

  getDeliveryExtraCharge: async function (store) {
    try {
      // 추가 배송비 조회
      const deliveryExtraChargeUrl = `v3/market/delivery/product/extra/charge/${state.product.marketId}/information?marketId=${state.product.marketId}`;
      const response = await this._vm.$axios1.get(deliveryExtraChargeUrl);
      if (response.data.responseCode == 2000) {
        state.extraChargeInfo = response.data.result[0];
      } else {
        // logError(optionsResponse.data, "상품 옵션 조회에 실패하였습니다.");
      }
    } catch (e) {
      console.log(e);
    }
  },
  reset: function (store) {
    store.commit("reset");
  },
  goBack: function (store) {
    store.commit("goBack");
  },
  setOptions: function (store, data) {
    store.dispatch("setTotalPrice");
  },

  setTotalPrice: function () {
    let optionsPrice = 0;
    let optionsWeight = 0;
    if (state.product.options) {
      state.product.options?.forEach((option) => {
        // console.log(option);

        option.checkboxInfo.options.forEach((item) => {
          // console.log(item.active);
          if (item.active) {
            optionsPrice = optionsPrice + item.value;
            optionsWeight = optionsWeight + item.weight;
          }
        });
        // if (option.checkboxInfo.options.filter(item => item.active)[0]?.value) {
        //   optionsPrice = optionsPrice + option.checkboxInfo.options.filter(item => item.active)[0]?.value;
        //   optionsWeight = optionsWeight + option.checkboxInfo.options.filter(item => item.active)[0]?.weight;
        // }
      });
    }

    // console.log(state.totalWeight);
    state.totalPrice = (state.product.price + optionsPrice) * state.quantity;
    state.totalWeight = (state.isRequiredCheckbox ? optionsWeight : state.product.weight + optionsWeight) * state.quantity;

    // return 30000;
  },
  setQuantity: function (store, event) {
    if (event.currentTarget.classList.contains("btn_minus")) {
      // 감소
      if (state.quantity > (state.product.productId === 102068 ? 2 : 1)) {
        // 초록팜마트 고춧가루 최소 주문개수 예외처리 TODO 추후 원복하거나 삭제
        state.quantity--;
      }
    } else {
      // 증가
      state.quantity++;
    }

    store.dispatch("setTotalPrice");
    // console.log('state.product.totalPrice ::: ' + state.totalPrice);
    // state.product.totalPrice = state.product.totalPrice * state.quantity;
  },
  navigateToProductOption: async function (store) {
    store.commit("setDeviceCartData");
  },
};

const getters = {};

/**
 * API 정상 호출 실패 시 에러 로그
 * @param {object} response API 호출 후 받은 응답 데이터
 * @param {string} message 임의로 같이 표시할 메시지
 */
const logError = (response, message) => {
  console.error(
    "Response Code:: " + response.responseCode + "\n" + "Error Message:: " + response.errorObject.errorMessage + "\n" + "Message:: " + message,
  );
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
