<template>
  <div>
    <div class="carousel_container">
      <div v-if="totalPage > 1">
        <Carousel :per-page="1" :mouse-drag="false" :paginationEnabled="false" :loop="true" @page-change="onPageChange">
          <Slide class="carousel_page" v-for="(imageUrl, idx) in images" v-bind:key="idx">
            <img :src="imageUrl" @click="isDetailShow = true" />
          </Slide>
        </Carousel>
        <div class="carousel_pager">
          <span class="cur_page">{{ curPage }}</span>
          /
          <span class="total_page">{{ totalPage }}</span>
        </div>
      </div>
      <template v-else>
        <img :src="images && images[0]" @click="isDetailShow = true" />
      </template>
    </div>
    <div class="image_detail_popup" v-if="isDetailShow" @click="isDetailShow = false">
      <img :src="images && images[curPage - 1]" />
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "ProductImgCarousel",
  props: {
    images: Array,
  },
  data: function () {
    return {
      curPage: 1,
      isDetailShow: false,
    };
  },
  components: {
    Carousel,
    Slide,
  },
  computed: {
    totalPage: function () {
      return this.images ? this.images.length : 0;
    },
  },
  methods: {
    onPageChange: function (curPage) {
      this.curPage = curPage + 1;
    },
    onBackPressed: function () {
      if (this.isDetailShow) {
        this.isDetailShow = false;
      } else {
        this.$closeView();
      }
    },
  },
  watch: {
    isDetailShow: function (isShow) {
      const viewportMeta = document.getElementsByName("viewport")[0];
      if (isShow) {
        if (this.$isAndroid()) {
          // 줌 가능 처리
          viewportMeta.content = "width=device-width, minimum-scale=1.0, initial-scale=1.0";
        } else {
          // 줌 불가능 처리
          viewportMeta.content = "width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0";
        }
        document.documentElement.style.overflow = "hidden";
      } else {
        // 줌 불가능 처리
        viewportMeta.content = "width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0";

        document.documentElement.style.overflow = "auto";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel_container {
  width: 100%;
  height: 100vw;
  text-align: center;
  position: relative;
}

.carousel_pager {
  width: 39px;
  height: 21px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font: normal normal normal 12px/21px Noto Sans CJK KR;
  letter-spacing: -0.72px;
  color: #8e8e8e;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  border-radius: 10px;
}

.carousel_pager .cur_page {
  color: #ffffff;
}

.carousel_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image_detail_popup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1;
  overflow: hidden;
}

.image_detail_popup > img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
