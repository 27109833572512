var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tabWrap",staticClass:"tab-wrap ignore_touch_x",class:{ vertical_scroll: !_vm.appVersion }},[_c('ul',{staticClass:"tab_list",class:_vm.addClass,attrs:{"data-id":_vm.dataId}},_vm._l((_vm.tabItems),function(tabItem,idx){return _c('li',{key:idx,staticClass:"tab",class:{
        active: _vm.activeTabItem ? _vm.activeTabItem === tabItem.dataType : _vm.isPreviousData ? tabItem.isActive : idx === _vm.tabActiveIndex,
        sea_tab: tabItem.text === '수산',
        plan_tab: tabItem.text === '농산',
        brand_tab: tabItem.text === '친환경',
        main_tab: _vm.tabType === 'type1',
      }},[_c('a',{class:{
          active: _vm.activeTabItem ? _vm.activeTabItem === tabItem.dataType : _vm.isPreviousData ? tabItem.isActive : idx === _vm.tabActiveIndex,
          special_tab: tabItem.text === '단골시장' || tabItem.text === '수산',
          traditional_tab: tabItem.text === '단골시장',
          sea_tab: tabItem.text === '수산',
          specialOffer_tab: tabItem.text === '특가',
          plan_tab: tabItem.text === '농산',
          at_tab: tabItem.text === '농산' || tabItem.text === '단골시장',
          brand_tab: tabItem.text === '친환경',
          sea_tab_align: tabItem.text === '수산' || tabItem.text === '특가' || tabItem.text === '친환경' || tabItem.text === '이벤트',
        },attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.isPreviousData ? _vm.onTabClick(tabItem, $event) : _vm.onClickTab(idx)}}},[_vm._v(" "+_vm._s(_vm.isPreviousData ? tabItem.text : tabItem.sidoName)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }