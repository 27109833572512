<template>
  <div>
    <LoadingIndicator v-if="isProgressing" />
    <div>
      <TopHeader class="top_header" title="주문문의" @goBack="goBack" :isCartVisible="false" />
      <div class="contents" :class="{ type3: pageMode == 'write' ? true : false }">
        <div class="guide_txt">
          <p class="txt1">
            놀장은 통신판매중개자로 통신판매의 당사자가 아닙니다. 상품상태에 대한 이상, 사진, 설명과 다른 상품 등에 대한 문의는 앱문의가 아닌 점주님께
            직접 문의해 주셔야 됩니다.
          </p>
          <p class="txt2">
            점주님 연락처는 상품 선택 후 나오는 상품 정보 하단에서 확인 가능합니다.(구매하신 상품이 검색되지 않을 경우 점포명으로 검색 후 상품 선택
            부탁드립니다.)
          </p>
        </div>
        <div class="result_area" v-if="pageMode == 'view'">
          <!-- && loadComplete == false" -->
          <!--          <div class="text"-->
          <!--               :class="{normal_txt : !inquiryStatus || inquiryStatus == '1'}"-->
          <!--               v-html="!inquiryStatus || inquiryStatus == '1'? '접수된 문의는 담당자 확인 후 처리 중으로, <br>시간이 소요되는 점 양해 부탁드립니다.': inquiryStatus == 'D'? '처리완료' : '접수된 문의는 담당자 확인 후 처리 중으로, <br>시간이 소요되는 점 양해 부탁드립니다.'"-->
          <!--          />-->

          <div class="text">
            {{ !inquiryStatus || inquiryStatus == "1" ? "처리중입니다..." : inquiryStatus == "D" ? "처리완료" : "처리중입니다..." }}
          </div>
          <div class="msg_text" v-if="resultMsg">{{ resultMsg }}</div>
        </div>

        <div class="contents_area">
          <div class="order_title_txt">주문한 상품 목록</div>
          <div class="inquiry_list_wrap" ref="inquiryList" id="inquiryList">
            <InquiryList
              :inquiryList="inquiryList"
              :pageMode="pageMode"
              @inquiryIncreaseDecrease="inquiryIncreaseDecrease"
              @onChangeFileUpload="onChangeFileUpload"
              @onChangeCameraUpload="onChangeCameraUpload"
            />
          </div>
        </div>
      </div>

      <div class="btn_wrap" v-if="pageMode == 'write'">
        <Btn type="type5" value="상품문의 요청하기" @btnCallback="registInquiry" :data="{ ref: this.$refs.inquiryList }" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/new/LoadingIndicator";
import TopHeader from "@/components/new/TopHeader";
import InquiryList from "@/components/list/InquiryList";
import Btn from "@/components/new/Btn";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ProductInquiry",
  components: {
    TopHeader,
    InquiryList,
    Btn,
    LoadingIndicator,
  },
  computed: {
    ...mapState({
      isProgressing: (state) => state.productInquiry.isProgressing,
      title: (state) => state.productInquiry.title,
      pageMode: (state) => state.productInquiry.pageMode,
      loadComplete: (state) => state.productInquiry.loadComplete,
      inquiryId: (state) => state.productInquiry.inquiryId,
      inquiryList: (state) => state.productInquiry.inquiryList,
      inquiryStatus: (state) => state.productInquiry.inquiryStatus,
      resultMsg: (state) => state.productInquiry.resultMsg,
    }),
  },
  methods: {
    ...mapMutations("productInquiry", ["inquiryIncreaseDecrease", "showHideGuideLayer", "reset"]),
    ...mapActions("productInquiry", ["goBack", "registInquiry", "onChangeFileUpload", "onChangeCameraUpload"]),
  },
  beforeDestroy() {
    this.reset();
  },
  beforeCreate: function () {
    // console.log(this.$route.params);

    if (this.$route.params.pageMode == "view") {
      this.$store.dispatch(
        "productInquiry/initState",
        this.$route.params,
        // {
        //   orderId: this.$route.params.orderId,
        //   userId: localStorage.getItem('userId'),
        //   pageMode: 'view',
        //
        // }
      );
    } else if (this.$route.params.pageMode == "write") {
      this.$store.dispatch(
        "productInquiry/initState",
        this.$route.params,
        // {
        //   orderId: this.$route.params.orderId,
        //   userId: localStorage.getItem('userId'),
        //   pageMode: 'write',
        // }
      );
    }

    // this.$store.dispatch(
    //     'productInquiry/initState',
    //     {
    //
    //       orderId: this.$route.query.orderId? this.$route.query.orderId : 'OID20210720877654E9A7B44D899875A7B2821F6DA2',
    //       inquiryId: this.$route.query.orderId? this.$route.query.orderId : 'OID20210720877654E9A7B44D899875A7B2821F6DA2',
    //       userId: localStorage.getItem('userId'),
    //       pageMode: this.$route.query.pageMode? this.$route.query.pageMode : 'write',
    //
    //       // inquiryId: this.$route.query.orderId? this.$route.query.orderId : 'OID20210720877654E9A7B44D899875A7B2821F6DA2',
    //       // userId: localStorage.getItem('userId'),
    //       // pageMode: this.$route.query.pageMode? this.$route.query.pageMode : 'write',
    //     }
    // );
  },
};
</script>

<style lang="scss" scoped>
.btn_wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: block;

  padding: 10px 16px;
  background-color: $white;
  border: {
    style: solid;
    color: $border-type1;
    width: 1px 0 0;
  }
}

//.contents {
//  height: calc(100vh - 44px);
//  padding: 0 0 20px;
//  background: $nj_background_gray;
//  overflow-y: auto;
//}
.contents_area {
  //padding: 0 16px;
  //border: {
  //  style: solid;
  //  color: $border-type1;
  //  width: 0 0 1px;
  //}
}
.order_title_txt {
  padding: 20px 16px 17px;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  @include font(16, 18, -0.5, bold);
  color: $black;
}
.inquiry_list_wrap {
  position: relative;
  padding: 0 16px 20px;
  margin-bottom: 75px;
}
.guide_txt {
  padding: 20px;
  background-color: $background-color-type1;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  .txt1 {
    margin: 0;
    @include font(14, 22, -0.5, bold);
    color: $black;
  }
  .txt2 {
    margin: 12px 0 0;
    @include font(13, 20, -0.5);
    color: $nj_text_main_gray;
  }
}
.result_area {
  padding: 20px;
  background-color: white;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  text-align: center;
  .text {
    @include font(16, 24, -0.5, bold);
    color: $active-color;
    &.normal_txt {
      font-size: 14px;
    }
  }
  .msg_text {
    @include font(14, 20, -0.28);
    color: $nj_red;
  }
}
</style>
