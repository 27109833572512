<template>
  <div>
    <TopHeader :title="title" :isCartVisible="false" @goBack="goBack" v-if="false" />
    <div class="contents">
      <div class="payment_wrap">
        <div class="header">
          <span class="title">주문상품</span>
          <span class="count_wrap">
            <span class="market_name">{{ marketName }}</span>
            <span class="count">{{ products.length }}</span>
            <span class="count_txt">건</span>
          </span>
        </div>
        <div class="payment_product_list">
          <ul>
            <li v-for="product in products" :key="product.id">
              <ProductList
                :compoundKey="product.compoundKey ? product.compoundKey : null"
                :productId="product.productId"
                :storeName="product.storeName"
                :name="product.productName"
                :paymentTypes="product.paymentTypes"
                :quantity="product.quantity"
                :price="product.payment"
                :discountPrice="product.discountPrice"
                :productImageUrl="product.productThumbnailUrl"
                :requestInput="product.orderRequest ? true : false"
                :unitPrice="product.unitPrice"
                :orderRequest="product.orderRequest"
                :unitName="product.unitName"
                :unitNumber="product.saleUnit"
                :weight="product.weight"
                :originName="product.originName"
                :optionGroups="product.optionGroups ? (product.optionGroups.length > 0 ? product.optionGroups : null) : null"
                :totalOptionPrice="product.totalOptionPrice ? product.totalOptionPrice : null"
                :isLink="false"
                viewMode="info"
              />
            </li>
          </ul>
        </div>
        <div class="payment_detail_wrap">
          <ul class="payment_detail_list">
            <li>
              <span class="title"> 총 상품금액 </span>
              <span class="content">
                <span class="price">{{ $formatPrice(totalPayment.productsPrice) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>

            <!-- *************** 연말 이벤트 예외 처리 *************** -->
            <template v-if="new Date() > new Date('2021-12-16') && new Date() < new Date('2021-12-23')">
              <li style="position: relative; padding: 10px 0 30px">
                <span class="title"> 총 할인 </span>
                <span class="content">
                  <span class="price">- {{ $formatPrice(totalPayment.totalDiscount) }}</span>
                  <span class="price_txt">원</span>
                </span>

                <div style="position: absolute; bottom: 0; right: 0; text-align: right; color: #f00">
                  <div style="line-height: 1.3; font-size: 12px">20,000원 이상 주문 시 20% 할인, 1인당 1일 1회</div>
                  <!--<div style="line-height: 1.3">(사용가능 0원)</div>-->
                </div>
              </li>
              <li style="position: relative; padding: 10px 0 30px">
                <span class="title"> 배송비 </span>

                <span class="content">
                  <template v-if="orderType == 'BD'">
                    <span class="price">{{ totalPayment.deliveryFee == totalPayment.deliveryDiscount ? "무료배송" : totalPayment.deliveryFee }}</span>
                    <span class="price_txt" v-if="totalPayment.deliveryFee != totalPayment.deliveryDiscount">원</span>
                  </template>
                  <template v-else-if="totalPayment.deliveryFee == 0">
                    <span class="price">택배비는 착불입니다.</span>
                  </template>
                  <template v-else>
                    <span class="price">{{ totalPayment.deliveryFee == totalPayment.deliveryDiscount ? "무료배송" : totalPayment.deliveryFee }}</span>
                    <span class="price_txt" v-if="totalPayment.deliveryFee != totalPayment.deliveryDiscount">원</span>
                  </template>
                </span>
                <div style="position: absolute; bottom: 0; right: 0; text-align: right; color: #f00">
                  <div style="line-height: 1.3; font-size: 12px">1만원 이상 주문 시 무료 배송</div>
                </div>
              </li>
            </template>
            <!-- end: *************** 연말 이벤트 예외 처리 *************** -->

            <template v-else>
              <li :class="{ delivery_tb: order.orderType === 'TB' && order.deliveryFeeMethod !== 'PREPAYMENT' }">
                <span class="title"> 배송비 </span>
                <div class="content">
                  <template v-if="order.orderType === 'BD'">
                    <!-- 방문수령 예외처리 -->
                    <span class="price">{{
                      onSiteYn === "Y"
                        ? 0
                        : totalPayment.deliveryFee === totalPayment.deliveryDiscount || totalPayment.deliveryFee === 0
                        ? "무료배송"
                        : $formatPrice(totalPayment.deliveryFee)
                    }}</span>
                    <span class="price_txt" v-if="totalPayment.deliveryFee !== totalPayment.deliveryDiscount || onSiteYn === 'Y'">원</span>
                  </template>
                  <template v-else-if="order.orderType === 'TB' && order.deliveryFeeMethod">
                    <div :class="{ content_flex: order.deliveryFeeMethod !== 'PREPAYMENT' }">
                      <span class="cash_delivery" v-if="order.deliveryFeeMethod && order.deliveryFeeMethod === 'CASH_DELIVERY'">착불</span>
                      <span class="price">{{
                        deliveryFee !== null && typeof getTbDeliveryFee() === "string" ? getTbDeliveryFee() : $formatPrice(getTbDeliveryFee())
                      }}</span>
                      <span class="price_txt" v-if="onSiteYn === 'Y' || (deliveryFee && deliveryFee !== '무료배송')">원</span>
                    </div>

                    <div class="deferred_payment_info" v-if="order.deliveryFeeMethod !== 'PREPAYMENT'">
                      <span v-if="order.deliveryFeeMethod === 'MIXED_PAYMENT'">착불배송비 제외</span>
                      <span v-else-if="order.deliveryFeeMethod === 'CASH_PAYMENT'">착불수수료 제외</span>
                    </div>
                  </template>
                  <template v-else-if="order.orderType === 'TB' && !order.deliveryFeeMethod">
                    <div :class="{ content_flex: true }">
                      <span class="price">택배비는 착불입니다.</span>
                    </div>
                  </template>
                  <!--<span class="price">{{
                    totalPayment.deliveryFee == totalPayment.deliveryDiscount
                      ? "무료배송"
                      : totalPayment.deliveryFee == 0
                      ? "택배비는 착불입니다."
                      : $formatPrice(totalPayment.deliveryFee)
                  }}</span>
                  <span class="price_txt" v-if="totalPayment.deliveryFee != totalPayment.deliveryDiscount">원</span>-->
                </div>
              </li>
              <!-- 방문수령 예외처리 -->
              <li v-if="totalPayment.deliveryDiscount && totalPayment.deliveryFee != totalPayment.deliveryDiscount && onSiteYn !== 'Y'">
                <span class="title"> 배송비 할인 </span>
                <span class="content">
                  <span class="price">- {{ $formatPrice(totalPayment.deliveryDiscount) }}</span>
                  <span class="price_txt">원</span>
                </span>
              </li>
              <li v-if="totalPayment.totalDiscount">
                <span class="title"> 이벤트 할인 </span>
                <span class="content">
                  <span class="price">- {{ $formatPrice(totalPayment.totalDiscount) }}</span>
                  <span class="price_txt">원</span>
                </span>
              </li>
              <li v-if="totalPayment.totalDiscountPackagingPrice">
                <span class="title"> 포장주문 </span>
                <span class="content">
                  <span class="price">- {{ $formatPrice(totalPayment.totalDiscountPackagingPrice) }}</span>
                  <span class="price_txt">원</span>
                </span>
              </li>
            </template>

            <!--<li>
              <span class="title"> 배송비 </span>
              <span class="content">
                <span class="price">{{ $formatPrice(totalPayment.deliveryFee) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
            <li v-if="totalPayment.deliveryDiscount">
              <span class="title"> 배송비 할인 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.deliveryDiscount) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>-->

            <li v-if="totalPayment.point">
              <span class="title"> 포인트 </span>
              <span class="content">
                <span class="price">- {{ $formatPrice(totalPayment.point) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
            <li class="active">
              <span class="title"> 결제금액 </span>
              <span class="content">
                <span class="price">{{ $formatPrice(totalPayment.payment) }}</span>
                <span class="price_txt">원</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="payment_address_wrap">
          <div class="delivery_info_wrap" v-if="order.nicePaymentTrResponse && order.nicePaymentTrResponse.payMethod === 'VBANK'">
            <div class="relative">
              <span class="header">입금 계좌</span>
            </div>
            <div class="receive_method">계좌번호: {{ order.nicePaymentTrResponse.vbankName }} {{ order.nicePaymentTrResponse.vbankNum }}</div>
            <div class="receive_method" style="margin-bottom: 15px">
              입금기한: {{ formatDate(order.nicePaymentTrResponse.vbankExpDate) }} {{ formatTime(order.nicePaymentTrResponse.vbankExpTime) }}
            </div>
          </div>
          <div class="relative">
            <span class="header"> {{ onSiteYn === "Y" ? "수령방법" : "배송지" }} </span>
          </div>
          <div class="address_wrap" v-if="onSiteYn !== 'Y'">
            <span>{{ delivery.receiverName }}</span>
            <span>{{ delivery.receiverTel }}</span>
            <span class="address">{{ delivery.roadAddressFull }}</span>
            <div class="delivery_info_wrap">
              <span class="title">수령 방법</span>
              <span class="receive_method">{{ delivery.receiveMethod }}</span>
              <div class="request_msg_wrap" v-if="delivery.deliveryRequest">
                {{ delivery.deliveryRequest }}
              </div>
            </div>
          </div>
          <!-- 방문수령 예외처리 -->
          <div class="address_wrap" v-else>
            <div class="delivery_info_wrap">
              <div class="receive_method">
                <p>
                  먼저 점주님에게 전화하여 놀장 <strong>방문포장 상품이 준비되었는지 확인 후</strong>, 방문하셔서
                  <strong>주문번호 4자리를</strong> 알려주세요.
                </p>
              </div>
              <div class="request_msg_wrap" v-if="delivery.deliveryRequest">
                {{ delivery.deliveryRequest }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="payment_btn_wrap">
        <div class="notice_msg" v-if="orderType != 'TB' && onSiteYn !== 'Y'">
          {{ delivery.deliveryNotice }}
        </div>
        <div class="notice_msg" v-else-if="onSiteYn === 'Y'">늦지않게 방문해 주세요.</div>
        <div class="btn_wrap">
          <Btn value="시장 메인으로" type="type2" @btnCallback="moveToMarket" />
          <Btn value="주문상세보기" type="type4" @btnCallback="moveToOrderDetail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapActions, mapState } from "vuex";
import Btn from "@/components/new/Btn";
import ProductList from "@/components/new/cart/ProductList";
import { loadScript } from "vue-plugin-load-script";

export default {
  name: "CompletePayment",
  props: {},
  data: function () {
    return {
      deliveryFee: String,
    };
  },
  components: {
    ProductList,
    Btn,
    TopHeader,
  },
  beforeCreate: function () {
    /*this.$store.dispatch("completePayment/initState", {
      userId: localStorage.getItem("userId") ? localStorage.getItem("userId") : "B6545D8E-ECE0-4044-9D52-C05496D4D410",
      orderId: this.$route.query.orderId ? this.$route.query.orderId : "OID20211108DEF004C29A5F45B9A23A6310E77BC004",
    });*/

    this.$store.dispatch("completePayment/initState", {
      userId: localStorage.getItem("userId"),
      orderId: this.$route.query.orderId,
    });
  },
  async mounted() {
    window.onBackPressed = this.goBack;

    if (this.$route.query.setlId && this.$route.query.reqSetlDttm) {
      const token = await this.chackToken();
      const form = JSON.stringify({
        setlAuthlId: this.$route.query.setlId,
        reqSetlDttm: this.$route.query.reqSetlDttm,
      });
      const data = await this.encryptChack(form);

      const paymentReqResult = await this.$axios.post(
        "https://lopy-api.komscochak.com/opapi/v2/setl/aprv",
        { encData: data },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      if (paymentReqResult.data.head.resultCode !== "200") {
        console.error("ERROR: /opapi/v2/setl/aprv");
        return;
      }

      // 놀장 결제승인 요청
      await this.$axios2.post("/v3/payment/chak/approval?orderId=" + this.$route.query.orderId);

      // 착 결제승인 요청
      const decryped = await this.decryptChack(paymentReqResult.data.body.encData);
      // console.log(decryped);

      await this.$axios2
        .post("/v3/payment/chak/approval/response", {
          orderId: this.$route.query.orderId,
          resultCode: "200",
          resultMsg: "성공",
          mercId: "MRC00000000000153285",
          setlAmt: decryped.setlAmt,
          setlTxAmt: null,
          setlTxFrAmt: null,
          grmnAmt: null,
          waetBal: null,
          setlId: decryped.setlId,
          mercNm: "논산화지중앙시장",
          csvcIssDmanYn: "Y",
          icdcDvcd: "00",
          icdcNo: this.delivery.receiverTel,
          setlDttm: this.$route.query.reqSetlDttm,
          reqDttm: this.$route.query.reqSetlDttm,
        })
        .then((res) => {
          if (res.status === 200) {
            // 결제 취소 요청
            // this.$axios2.post('/v3/payment/chak/cancel?orderId=' + this.$route.query.orderId);
          }
        });
    }
  },
  methods: {
    ...mapActions(["chackToken", "encryptChack", "decryptChack"]),
    getTbDeliveryFee() {
      // console.log(this.order.deliveryFeeMethod !== "CASH_DELIVERY");
      if (this.order.orderType === "TB") {
        var deliveryFee = this.order.tbDeliveryFee;
        if (this.order.deliveryFeeMethod === "CASH_DELIVERY") {
          deliveryFee = this.order.tbCashDeliveryFee;
        }
        if (this.totalPayment.deliveryDiscount == deliveryFee || deliveryFee === 0 || !deliveryFee) {
          deliveryFee = "무료배송";
        }
        this.deliveryFee = deliveryFee;
        return deliveryFee;
      }
    },
    /*changeAddress: function () {
      console.log("changeAddress ::: ");
      console.log("changeAddress ::: ");
      console.log("changeAddress ::: ");

      this.$router.push({
        name: "배송지설정",
        params: { userId: localStorage.getItem("userId") ? localStorage.getItem("userId") : "B6545D8E-ECE0-4044-9D52-C05496D4D410" },
      });
    },*/
    moveToMarket: function () {
      this.$callNative("moveToMarket", { marketId: this.marketId, marketName: this.marketName }, false);
    },
    moveToOrderDetail: function () {
      this.$callNative("moveToOrderDetail", { orderId: this.orderId }, false);
    },
    formatDate(date) {
      const year = date.slice(0, 4);
      const month = date.slice(4, 6);
      const day = date.slice(6);

      return `${year}년 ${month}월 ${day}일`;
    },
    formatTime(time) {
      const hour = time.slice(0, 2);
      const minute = time.slice(2, 4);
      const second = time.slice(4);

      return `${hour}:${minute}:${second}까지`;
    },
    ...mapActions("completePayment", ["goBack"]),
  },
  computed: {
    ...mapState({
      title: (state) => state.completePayment.title,
      orderId: (state) => state.completePayment.orderId,
      orderType: (state) => state.completePayment.orderType,
      marketId: (state) => state.completePayment.marketId,
      marketName: (state) => state.completePayment.marketName,
      products: (state) => state.completePayment.products,
      delivery: (state) => state.completePayment.delivery,
      totalPayment: (state) => state.completePayment.totalPayment,
      order: (state) => state.completePayment.order,
      onSiteYn: (state) => state.completePayment.onSiteYn,
    }),
  },
  watch: {
    order(val) {
      // console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .deferred_payment_info {
    display: block;
    @include font(12, 12, 0);
    //@include font(12, 12, 0, medium);
    color: $active-color;
    text-align: right;
  }

  .cash_delivery {
    display: inline-block;
    height: auto !important;
    padding: 1px 7px 0px;
    margin: -4px 5px 0;
    background-color: $active-color;
    @include border-radius(6px);
    @include font(13, 20);
    color: white;
    vertical-align: middle;
  }

  .payment_wrap {
    //height: calc(100vh - 160px);
    overflow-y: scroll;

    > div:not(.header):not(.payment_address_wrap) {
      border: {
        width: 0 0 10px 0;
        style: solid;
        color: $background-color-type1;
      }
    }

    > .header {
      width: 100%;
      height: 58px;
      padding: 0 16px;
      border: {
        width: 0 0 1px 0;
        style: solid;
        color: $border-type2;
      }
      display: flex;
      align-items: center;

      > span {
        &.title {
          @include font(16, normal, -0.64, bold);
        }

        &.count_wrap {
          display: flex;
          justify-content: right;
          flex: 1;

          > span {
            @include font(14, normal, -0.32);
          }

          > .market_name {
            margin: 0 4px 0 0;
            font-weight: bold;
          }

          > .count {
            font-weight: bold;
            color: $nj_red;
          }
        }
      }
    }

    .payment_product_list::v-deep {
      .cart_product_container {
        padding: 0 16px;

        .product_wrap:last-child {
          border: none;
        }
      }
    }

    .payment_detail_wrap {
      .payment_detail_list {
        padding: 0 16px;
        background-color: $background-color-type2;
        align-items: center;

        > li {
          display: flex;
          height: 16px;
          margin: 0 0 20px 0;
          align-items: center;

          &.delivery_tb {
            height: auto;
            align-items: flex-start;
            .title {
              padding: 5px 0 0;
            }
            .content {
              flex-wrap: wrap;
            }
            .content_flex {
              flex: 1 0 100%;
              text-align: right;
            }
          }

          &:first-child {
            height: 46px;
            padding: 30px 0 0 0;
          }

          &.active {
            height: 62px;
            padding: 20px 0;
            margin: 0;
            border: {
              width: 1px 0 0 0;
              style: solid;
              color: $border-type2;
            }

            .title {
              @include font(16, normal, -0.64, bold);
              color: $active-color;
            }

            .content {
              height: 22px;

              .price {
                height: 22px;
                font-family: gothic_a1_bold;
                font-size: 22px;
              }

              .price_txt {
                @include font(19);
              }
            }
          }

          span {
            height: 16px;
          }

          .title {
            @include font(14, normal, normal, bold);
          }

          .content {
            flex: 1;
            display: flex;
            align-items: baseline;
            justify-content: flex-end;

            .price {
              font-family: gothic_a1_bold;
              font-size: 16px;
              line-height: 1;
            }

            .price_txt {
              @include font(15);
            }
          }
        }
      }
    }

    .payment_address_wrap {
      padding: 20px 16px;

      .header {
        @include font(16, 18, -0.64, bold);
      }

      .address_wrap {
        > span {
          display: block;
          margin-top: 10px;
          @include font(14, 14, normal, bold);

          &.address {
            @include font(14, 20, normal, bold);
          }
        }

        .delivery_info_wrap {
          display: flex;
          flex-direction: column;

          > span {
            @include font(14, 18, normal, bold);

            &.title {
              color: $gray-normal;
            }

            &.receive_method {
              margin: 5px 0 0;
            }
          }

          .request_msg_wrap {
            width: 100%;
            margin: 10px 0 0;
            padding: 14px 20px;
            border: {
              width: 1px;
              style: solid;
              color: $border-type2;
            }
            @include border-radius(15px);
            @include font(14, 18, normal, bold);
            //color: $gray-normal;
            resize: none;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  .payment_btn_wrap {
    border: {
      width: 10px 0 0 0;
      style: solid;
      color: $background-color-type1;
    }
    padding: 20px 16px;

    .notice_msg {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 36px;
      margin: 0 0 20px 0;
      color: $gray-light;
      background-color: $background-color-type1;
      @include font(12);
      @include border-radius(18px);
    }

    .btn_wrap {
      > a {
        width: calc(50% - 6px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include font(16, normal, normal, bold);

        &:first-child {
          margin: 0 6px 0 0;
        }

        &:last-child {
          margin: 0 0 0 6px;
        }
      }
    }
  }
}
</style>
