<template>
  <svg class="setting_btn" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs></defs>
    <g transform="translate(0.5 0.5)">
      <path
        class="a"
        d="M753.625,213.611h-2.544a6.447,6.447,0,0,0-.548-1.324l1.8-1.8a.733.733,0,0,0,0-1.036l-1.513-1.513a.733.733,0,0,0-1.037,0l-1.8,1.8a6.477,6.477,0,0,0-1.324-.548v-2.544a.733.733,0,0,0-.734-.733h-2.138a.733.733,0,0,0-.733.733v2.544a6.449,6.449,0,0,0-1.323.548l-1.8-1.8a.733.733,0,0,0-1.036,0l-1.512,1.512a.733.733,0,0,0,0,1.037l1.8,1.8a6.467,6.467,0,0,0-.548,1.324H736.09a.732.732,0,0,0-.732.733v2.139a.733.733,0,0,0,.734.733h2.543a6.467,6.467,0,0,0,.548,1.324l-1.8,1.8a.732.732,0,0,0,0,1.036l1.512,1.513a.731.731,0,0,0,1.036,0l1.8-1.8a6.444,6.444,0,0,0,1.323.547v2.544a.733.733,0,0,0,.733.733h2.138a.733.733,0,0,0,.734-.733v-2.544a6.455,6.455,0,0,0,1.324-.547l1.8,1.8a.733.733,0,0,0,1.037,0l1.512-1.513a.733.733,0,0,0,0-1.036l-1.8-1.8a6.447,6.447,0,0,0,.548-1.324h2.544a.733.733,0,0,0,.732-.733v-2.139A.733.733,0,0,0,753.625,213.611Z"
        transform="translate(-735.357 -205.913)"
      />
      <path class="a" d="M758.931,227.455a2.031,2.031,0,1,1-2.031-2.031A2.031,2.031,0,0,1,758.931,227.455Z" transform="translate(-747.4 -217.955)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SettingButton",
};
</script>

<style lang="scss" scoped>
.setting_btn {
  .a {
    fill: none;
    stroke: #484848;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
</style>
