<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" />
    <div class="contents">
      <div class="guide_txt">
        <p class="txt1">
          놀장은 통신판매중개자로 통신판매의 당사자가 아닙니다. 상품상태에 대한 이상, 사진, 설명과 다른 상품 등에 대한 문의는 앱문의가 아닌 점주님께
          직접 문의해 주셔야 됩니다.
        </p>
        <p class="txt2">
          점주님 연락처는 상품 선택 후 나오는 상품 정보 하단에서 확인 가능합니다.(구매하신 상품이 검색되지 않을 경우 점포명으로 검색 후 상품 선택
          부탁드립니다.)
        </p>
      </div>
      <div class="result_area">
        <div class="text">
          {{ !inquiryStatus || inquiryStatus == "1" ? "처리중입니다." : inquiryStatus == "D" ? "처리완료" : "처리중입니다." }}
        </div>
        <div class="msg_text" v-if="resultMsg">{{ resultMsg }}</div>
      </div>

      <div class="contents_area">
        <div class="contents_title">주문한 상품 목록</div>
        <div class="mt_12">
          <InquiryList :inquiryList="inquiryList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import InquiryList from "@/components/list/InquiryList";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ProductInquiry",
  components: {
    TopHeader,
    InquiryList,
  },
  computed: {
    ...mapState({
      title: (state) => state.productInquiry.title,
      inquiryId: (state) => state.productInquiry.inquiryId,
      inquiryList: (state) => state.productInquiry.inquiryList,
      inquiryStatus: (state) => state.productInquiry.inquiryStatus,
      resultMsg: (state) => state.productInquiry.resultMsg,
    }),
  },
  methods: {
    ...mapMutations("productInquiry", [
      // 'moveToApp',
    ]),
    ...mapActions("productInquiry", ["goBack"]),
  },
  beforeCreate: function () {
    // console.log(typeof this.$route.query.inquiryId);
    this.$store.dispatch("productInquiry/initState", {
      inquiryId: this.$route.query.orderId,
    });
  },
};
</script>

<style lang="scss" scoped>
.contents {
  height: calc(100vh - 44px);
  padding: 0 0 20px;
  background: $nj_background_gray;
  overflow-y: auto;
}
.contents_area {
  padding: 23px 20px 20px;
}
.contents_title {
  @include font(18, 22, -1.08);
  color: $nj_black;
}
.guide_txt {
  padding: 20px;
  background-color: $nj_button_gray;

  .txt1 {
    margin: 0;
    @include font(16, 24, -0.96);
    color: $nj_black;
  }
  .txt2 {
    margin: 12px 0 0;
    @include font(14, 20, -0.28);
    color: $nj_text_main_gray;
  }
}
.result_area {
  padding: 20px;
  background-color: white;
  text-align: center;
  .text {
    @include font(16, 24, -0.96);
    color: $nj_black;
  }
  .msg_text {
    @include font(14, 20, -0.28);
    color: $nj_red;
  }
}
</style>
