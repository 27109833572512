<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <TopHeader
      class="top_header"
      :title="title"
      :isCartVisible="false"
      @goBack="goBack"
      style="position: fixed; top: 0px; width: 100%; z-index: 999"
    />
    <div class="contents" style="padding: 58px 0px 0px; height: 100vh">
      <div class="cart_guide_wrap">
        <p class="cart_guide">시장별로 <strong class="accent">따로 주문</strong> 부탁드립니다.</p>
      </div>
      <div class="at_event_info" v-if="atRemainPoint && discountType != 'SEOUL' && discountType != 'NONE'">
        <div class="title">
          농할 사용가능 금액
          <span class="at_price">{{ $formatPrice(atRemainPoint) }}</span>
          <span class="won_txt">원</span>
        </div>
        <div class="at_guide_txt">
          <span class="at_event" v-text="atEventLabel"></span>
          <span class="at_txt">{{ atEventText }}</span>
        </div>
        <p class="guide_txt" v-if="discountType != 'SEOUL' && discountType != 'NONE'">※ 농할 할인은 결제 시 적용됩니다.</p>
      </div>
      <div class="top_banner" v-if="strapBanner">
        <a href="javascript:void(0);" @click="clickBanner">
          <img :src="strapBanner.bannerImage" style="width: 100%" :data-title="strapBanner.title" :data-detail-url="strapBanner.detailUrl" />
        </a>
      </div>
      <template v-if="markets.length > 0">
        <ul class="contents_list">
          <li v-for="(market, index) in markets" :key="market.marketId" :style="'z-index: ' + (markets.length - index)">
            <MarketProductList
              :userId="userId ? userId : null"
              :customerMarginTop="customerMarginTop"
              :marketName="market.marketName"
              :deliveryFee="market.totalDeliveryFee"
              :market="market"
              :marketId="market.marketId"
              :products="market.products"
              :totalPrice="market.totalPrice"
              @add="updateQuantity"
              @remove="updateQuantity"
              @delete="deleteProduct"
              @payment="paymentMarket"
              @orderTab="orderTab"
            />
          </li>
        </ul>
      </template>
      <div class="no_product_msg" v-if="markets.length === 0">장바구니에 담긴 상품이 없습니다.</div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import MarketProductList from "@/components/new/cart/MarketProductList";

import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "Cart",
  props: {
    params: JSON.parse(localStorage.getItem("userCart")),
  },
  data() {
    return {
      title: "장바구니",
      // isProgressing: false,
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      atEventLabel: "농할 이벤트",
      // memberId: this.userId,
      // markets: JSON.parse(localStorage.getItem('userCart')),
    };
  },
  components: {
    TopHeader,
    MarketProductList,
    LoadingIndicator,
  },
  methods: {
    ...mapMutations("cart", ["clickBanner"]),
    ...mapActions("cart", [
      "goBack",
      "deleteProduct", // 상품 삭제
      "updateQuantity", // 상품 증가, 감소
      // 'addProductQnt',  // 상품 추가
      // 'delProductQnt',  // 상품 빼기
      "paymentMarket", // 결제하기
      "orderTab",
    ]),
    onBackPressed() {
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        this.goBack();
      }
    },
    authResult(jsonString) {
      // console.log('authResult');
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });

      this.$store.dispatch("cart/initState", {
        cart: JSON.parse(localStorage.getItem("userCart")),
        reload: true,
      });
    },
    checkCartIntegrity(jsonString) {
      // console.log("checkCartIntegrity");

      const marketId = JSON.parse(jsonString).marketId;
      // { marketId: 22 }

      // console.log(marketId);

      // const cart = JSON.parse(jsonString);
      // console.log(cart);
      // this.markets

      if (marketId) {
        const cart = this.markets.filter((marketCart) => {
          return marketCart.marketId != marketId;
        });

        this.$store.dispatch("rootInitState", { cart });
        this.$store.dispatch("cart/initState", { cart });
      }

      // let cart = localStorage.getItem("userCart");
      // cart = cart ? JSON.parse(cart) : [];
      // cart = cart.filter((marketCart) => {
      //   return marketCart.marketId != marketId;
      // });

      // this.$store.dispatch("rootInitState", { cart });
      // this.$store.dispatch("cart/initState", { cart, reload: true });
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.authResult = this.authResult;
    window.checkCartIntegrity = this.checkCartIntegrity;
    // console.log("mounted ------------------------------ Cart mounted");
    // console.log(this.$route.params);
  },
  beforeCreate: function () {
    if (this.$route.params?.reload) {
      this.$store.dispatch("cart/initState", {
        cart: JSON.parse(localStorage.getItem("userCart")),
        reload: true,
      });
    }

    // if (this.$route.params.cart) {// dummy data 적용 ::: 개발모드
    // console.log(this.$route.params.cart);
    // this.$store.dispatch("cart/initState", {
    //   cart: JSON.parse(localStorage.getItem("userCart")),
    //   reload: this.$route.params?.reload ? this.$route.params.reload : false,
    // });
    // }
  },
  computed: {
    ...mapState({
      userId: (state) => state.cart.userId,
      markets: (state) => state.cart.markets,
      customerMarginTop: (state) => state.cart.customerMarginTop,
      strapBanner: (state) => state.cart.strapBanner,
      atRemainPoint: (state) => state.cart.atRemainPoint,
      atEventText: (state) => state.cart.atEventText,
      isProgressing: (state) => state.cart.isProgressing,

      userCartTotalCount: (state) => state.userCartTotalCount,
    }),
  },
  created() {
    if (this.discountType === "SEOUL") {
      this.atEventLabel = "서울시특판 이벤트";
    }
  },
};
</script>

<style lang="scss" scoped>
.at_event_info {
  padding: 10px 16px 10px;
  border: {
    style: solid;
    color: #dcdcdc; // #dcdcdc;
    width: 0 0 1px;
  }
  color: #272727;
  .title {
    padding: 0 0 5px;
    @include font(16, 18, -0.64, bold);
  }
  .at_price {
    display: inline-block;
    margin: 0 0 0 5px;
    font-family: gothic_a1_bold;
    font-size: 17px;
    color: $active-color;
    vertical-align: middle;
  }
  .won_txt {
    margin: 0 0 0 3px;
    font-size: 13px;
    font-weight: normal;
  }
  .at_guide_txt {
    padding: 10px;
    background-color: #f5f8ff;
    .at_event {
      display: inline-block;
      //width: 100px;
      height: 25px;
      padding: 2px 8px 0;
      border: {
        style: solid;
        width: 1px;
        color: $active-color;
        radius: 13px;
      }
      font-size: 12px;
      color: $active-color;
      vertical-align: top;
    }
    .at_txt {
      display: inline-block;
      width: calc(100% - 100px);
      line-height: 18px;
      padding: 4px 0 0;
      margin: 0 0 0 5px;
      font-size: 13px;
      vertical-align: top;
    }
  }
  .guide_txt {
    margin: 7px 0 0;
    @include font(12, 16, -0.64, normal);
    color: $active-color;
  }
}

.cart_guide_wrap {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //padding: 13px 0px;
  height: 47px;
  padding: 8px 0 0;
  background-color: $background-color-type7;
  background-size: 22px 30px;
  font-size: 16px;
  text-align: center;
  //> .alert_icon {
  //  background-image: url("~svg/icon_alert.svg");
  //  background-repeat: no-repeat;
  //  width: 22px;
  //  height: 30.5px;
  //}
  .cart_guide {
    display: inline-block;
    min-height: 32px;
    padding: 10px 0 0 30px;
    background-image: url("~svg/icon_alert.svg");
    background-repeat: no-repeat;

    @include font(16, 18, -0.64, normal);
    .accent {
      color: $text-color-type1;
      @include font(16, 18, -0.64, bold);
    }
  }
}
.cart_guide {
  text-align: center;
}

.contents {
  .contents_list {
    //padding: 0 0 80px;
    > li {
      position: relative;
      margin: 10px 0 0;
      background-color: $white;

      &:first-child {
        margin: 0;
      }
    }
  }
}
.no_product_msg {
  padding-top: 100px;
  @include font(16, 22, -0.96, medium);
  text-align: center;
  color: $nj_text_sub_gray;
}
</style>
