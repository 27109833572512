<template>
  <div class="page_wrap">404 NOT FOUND</div>
</template>

<script>
export default {
  name: "DeliveryAddr",
  props: {},
  components: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="./NotFound.scss"></style>
