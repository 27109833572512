<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" />
    <div class="contents">
      <LoadingIndicator v-if="isProgressing" />
      <div class="search_wrap">
        <SearchInput placeholder="상품명을 입력하세요." type="type1" :value="keyword ? keyword : ''" @input="setKeyword" @search="search" />
        <div class="filter_wrap">
          <!--          History :: 기획 수정 요청으로 해당 영역 제거-->
          <!--          <span class="result_count_wrap">-->
          <!--            <span>결과</span>-->
          <!--            <span class="result_count">{{ products.length }}</span>-->
          <!--            <span>개</span>-->
          <!--          </span>-->
          <div class="filter_list horizontal_list">
            <ul>
              <li v-for="(filter, idx) in filters" :key="idx">
                <a class="filter_item icon_arrow_down" href="javascript:void(0)" @click="showFilter(filter)">
                  {{ Object.keys(filter.selectedItem).length ? filter.selectedItem.text : filter.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="product_list type1 vertical">
        <template v-if="products.length">
          <ProductList :products="products" :marketId="marketId" />
        </template>
        <div v-else class="no_products_msg">검색된 상품이 없습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapActions, mapState } from "vuex";
import ProductList from "@/components/new/ProductList";
import SearchInput from "@/components/new/SearchInput";
import LoadingIndicator from "@/components/LoadingIndicator";
export default {
  name: "Search",
  props: {},
  data: function () {
    return {};
  },
  components: {
    LoadingIndicator,
    SearchInput,
    ProductList,
    TopHeader,
  },
  beforeCreate() {
    // 실 운영 코드
    const params = {
      title: this.$route.query.title,
      marketId: this.$route.query.marketId,
      storeId: this.$route.query.storeId,
      productType: this.$route.query.productType,
      keyword: this.$route.query.keyword,
      serviceQuickYn: this.$route.query.serviceQuickYn,
      brandId: this.$route.query.brandId,
    };

    // console.log(params);

    this.$store.dispatch("search/initState", params);
  },
  mounted() {
    window.onBackPressed = this.goBack;
  },
  methods: {
    ...mapActions("search", ["goBack", "search", "showFilter", "setKeyword"]),
  },
  computed: {
    ...mapState({
      title: (state) => state.search.title,
      marketId: (state) => state.search.marketId,
      isProgressing: (state) => state.search.isProgressing,
      keyword: (state) => state.search.keyword,
      products: (state) => state.search.products,
      filters: (state) => state.search.filters,
    }),
  },
};
</script>

<style lang="scss" scoped>
.contents {
  .search_wrap {
    padding: 13px 16px 10px 16px;
    border: {
      width: 0 0 1px 0;
      style: solid;
      color: $border-type1;
    }

    .filter_wrap {
      .result_count_wrap {
        height: 30px;

        > span {
          display: inline-block;
          height: 30px;
          @include font(16, 30);

          &.result_count {
            margin: 0 0 0 3px;
            @include font(16, 30, normal, bold);
            color: $nj_red;
          }
        }
      }
    }

    @media (min-width: 370px) {
      .filter_wrap {
        height: 30px;
        margin-top: 20px;

        > span,
        div {
          display: inline-block;
          //vertical-align: top;
        }

        .filter_list {
          float: right;

          li {
            margin: 0 0 0 10px;

            &:first-child {
              margin: 0;
            }

            .filter_item {
              display: inline-block;
              padding: 7px 10px;
              margin: 0;
              height: 30px;
              border: {
                width: 1px;
                style: solid;
                color: $btn-type2-border;
              }
              @include border-radius(20px);
              @include font(14, 16);
              color: $black;
            }

            .icon_arrow_down {
              padding-right: 30px;
              background: {
                position: right 10px center;
              }
            }
          }
        }
      }
    }

    @media (max-width: 369px) {
      .filter_wrap {
        margin-top: 20px;

        > span,
        div {
          display: block;
        }

        .filter_list {
          ul {
            white-space: normal;

            li {
              margin: 5px 10px 0 0;
              &:last-child {
                a {
                  margin: 0;
                }
              }

              .filter_item {
                display: inline-block;
                padding: 7px 10px;
                height: 30px;
                border: {
                  width: 1px;
                  style: solid;
                  color: $btn-type2-border;
                }
                @include border-radius(20px);
                @include font(14, 16);
                color: $black;
              }

              .icon_arrow_down {
                padding-right: 30px;
                background: {
                  position: right 10px center;
                }
              }
            }
          }
        }
      }
    }
  }

  .product_list {
    margin: 0px;
    padding: 10px 0 0 0;
    overflow-y: scroll;

    @media (min-width: 450px) {
      height: calc(100% - 114px);
    }

    @media (max-width: 449px) {
      height: calc(100% - 149px);
    }

    .no_products_msg {
      width: 100%;
      padding-top: 25px;
      @include font(16, normal, normal, medium);
      text-align: center;
    }
  }
}
</style>
