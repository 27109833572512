<template>
  <div class="product_main" style="height: 100vh">
    <TopHeader
      class="top_header"
      :title="title"
      @goBack="goBack"
      :isHomeVisible="true"
      style="position: fixed; top: 0; width: 100%; z-index: 999"
    />
    <div class="contents" style="padding: 58px 0 80px; height: 100%; ">
      <LoadingIndicator v-if="isProgressing" />
      <ul class="contents_area">

        <li class="flex">
          <img :src="product.productThumbnailUrl" alt="product.productName" style="width: 70px; height: 70px; border: #f1f1f1 1px solid;" />
          <div style="flex: 1; padding: 0 0 0 15px; font-size: 14px;">
            <div>
              <span style="color: #FF563F;">{{ product.storeName }}</span> ・ {{ product.marketName }}
            </div>
            <div>
              <strong style="font-size: 16px;">
                {{ product.productName }}, {{ $formatWeight(product.weight) }} {{ product.unitNumber }} {{ product.unitName }}
              </strong>
            </div>

            <div style="text-align: right;">
              <span v-if="product.boxPkgYn === 'Y'">{{ quantity }} 박스 포장 / </span> {{ totalWeight? $formatWeight(totalWeight) : $formatWeight(product.weight) }}
            </div>
          </div>
        </li>
        <li class="flex" style="align-items: baseline;">
          <div style="flex: 1;"><strong>주문금액</strong></div>
          <div style="flex: 1; text-align: right;"><strong  style="font-family: gothic_a1_bold; font-size: 18px;">{{ $formatPrice(totalPrice) }}</strong>원</div>
        </li>

        <!--
        <li class="flex quantity_container">
          <strong style="flex: 1;">주문수량</strong>
          <div class="quantity_wrap">
            <a href="javascript:void(0)" class="quantity_btn btn_minus" @click="setQuantity" />
            <div class="quantity_txt">{{ quantity }}</div>
            <a href="javascript:void(0)" class="quantity_btn btn_plus" @click="setQuantity" />
          </div>
        </li>
        -->

        <!-- 초록팜마트 고춧가루 최소 주문개수 예외처리 TODO 추후 원복하거나 위 코드와 대체 -->
        <li>
          <div class="flex quantity_container" style="display: flex; align-items: center;">
            <strong style="flex: 1;">주문수량</strong>
            <div class="quantity_wrap">
              <a href="javascript:void(0)" class="quantity_btn btn_minus" @click="setQuantity" />
              <div class="quantity_txt">{{ quantity }}</div>
              <a href="javascript:void(0)" class="quantity_btn btn_plus" @click="setQuantity" />
            </div>
          </div>
          <p
              style="padding: 10px 15px 8px; margin: 10px 0 0; background-color: #f4f4f4; border-radius: 10px; text-align: center;"
              v-if="product.productId === 102068"
          >
            최소 <strong style="color: #f00;">2</strong>개 부터 주문 가능합니다.
          </p>
        </li>

        <li v-if="product.options">
          <ul style="width: 100%;">
            <li v-for="(option, index) in product.options" :key="option.optionGroupId">
              <div class="mt_10">
                <strong>{{ option.optionGroupName }}</strong>
                <span style="color: #777;">
                  (<span v-if="option.requiredYn === 'N'">최대 </span>{{ option.selectCount }}개<span v-if="option.requiredYn === 'Y'"> 선택</span>)
                  <span v-if="option.requiredYn === 'Y' || isRequiredCheckbox" style="color: #ff1d48;">필수</span>
                </span>
                <span style="float: right; font-size: 13px;">
                  추가요금
                </span>
              </div>

              <CheckboxList
                  :inputType="option.requiredYn === 'N'? 'checkbox' : 'radio'"
                  type="type5"
                  class="mt_15 mb_15" :checkboxInfo="option.checkboxInfo"
                  :callback="setOptions"
              />
              <hr v-if="index < product.options.length - 1" style="height: 1px; margin: 20px 0; border-style: none; background-color: #f1f1f1;" />

            </li>
          </ul>
        </li>

        <li class="mt_10">
          <div style="display: flex;">
            <strong>상품무게</strong>
            <strong style="flex: 1; text-align: right;">{{ totalWeight? $formatWeight(totalWeight) : $formatWeight(product.weight) }}</strong>
          </div>
          <div v-if="product.extraChargeInfo" style="padding: 5px 10px; margin: 10px 0 0; background-color: #f1f1f1; border-radius: 10px;">
            <strong style="color: #555;">배송비 추가 조건</strong>
            <div class="mt_5" v-html="product.extraChargeInfo.replace('\n', '<br />')" />
            <!--
            <div class="mt_5" v-if="extraChargeInfo.chargeCd === 'WT'">
              기본배송비 무게({{$formatWeight(extraChargeInfo.basicUnit)}}),
              이후 {{$formatWeight(extraChargeInfo.conditionUnit)}} 초과 시 마다 배송비 {{$formatPrice(extraChargeInfo.conditionCharge)}}원 추가
            </div>
            -->
          </div>
        </li>

      </ul>

      <!-- 버튼 영역 -->
      <div class="bottom_wrap">
        <Btn class="btn_add_cart" type="type4" value="장바구니 담기" @btnCallback="clickCart">장바구니 담기 </Btn>
        <span style="position: absolute; top: 29px; right: 25px; color: white;">{{ $formatPrice(totalPrice) }} 원</span>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import { mapActions, mapState } from "vuex";
import LoadingIndicator from "@/components/new/LoadingIndicator";
import Btn from "@/components/new/Btn";
import CheckboxList from "@/components/new/CheckboxList.vue";

export default {
  name: "ProductOptions",
  props: {},
  data: function () {
    return {
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
    };
  },
  components: {
    CheckboxList,
    Btn,
    LoadingIndicator,
    TopHeader,
  },
  beforeCreate() {
    let data = {
      productId: this.$route.params.productId,
      userId: localStorage.getItem("userId"),
    };
    this.$store.dispatch("productOptions/initState", data);
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions(["showModal", "appDownloadModal"]),
    ...mapActions("productOptions", [
      "initState",
      "reset",
      "goBack",
      'navigateToProductOption',
      'setOptions',
      'setQuantity',

    ]),
    /**
     * 뒤로가기 버튼 클릭 시 기능 처리
     */
    onBackPressed() {
      this.goBack();
    },
    clickCart() {
      if (this.appVersion) {
        this.navigateToProductOption();
      } else {
        this.appDownloadModal();
      }
    },
    disableRefresh(event) {
      // 새로고침 방지
      if (
        event.key === "F5" ||
        event.key === "F11" ||
        ((event.ctrlKey || event.metaKey) && event.keyCode === 82)
      ) {
        event.preventDefault();
      }
    },
  },
  computed: {
    ...mapState({
      title: (state) => state.productOptions.title,
      isProgressing: (state) => state.productOptions.isProgressing,
      product: (state) => state.productOptions.product,
      quantity: (state) => state.productOptions.quantity,
      totalPrice: (state) => state.productOptions.totalPrice,
      totalWeight: (state) => state.productOptions.totalWeight,
      extraChargeInfo: (state) => state.productOptions.extraChargeInfo,
      isRequiredCheckbox: (state) => state.productOptions.isRequiredCheckbox,
      appVersion: (state) => state.appVersion,
    }),
  },
  beforeMount() {
    window.addEventListener('keydown', this.disableRefresh);
  },
  destroyed() {
    window.removeEventListener('keydown', this.disableRefresh);
  },
};
</script>

<style lang="scss" scoped>

.contents {
  position: relative;

  .contents_area {
    padding: 15px;
    > li {
      &.flex {
        display: flex;
      }
      padding: 10px 0;
      border: {
        style: solid;
        color: #f1f1f1;
        width: 0 0 1px 0;
      }
      &:first-child {
        padding: 0 0 10px;
        border-width: 0;
      }
      &:last-child {
        border-width: 0;
      }

      &.quantity_container {
        align-items: center;

      }
    }
  }

  .bottom_wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 13px 16px;
    background-color: $white;
    border: {
      width: 1px 0 0 0;
      style: solid;
      color: $border-type1;
    }

    > .icon_toggle_like {
      text-indent: -9999px;
    }

    > .btn_add_cart {
      //float: right;
      //width: calc(100% - 62px);
      @include border-radius(10px);
      text-align: center;
      @include font(16, 54, normal, medium);
    }
  }
}

.quantity_wrap {
  margin: 5px 0;
  //margin-top: 20px;
  height: 40px;

  & > * {
    display: inline-block;
    vertical-align: top;
  }

  .quantity_btn {
    width: 46px;
    height: 40px;
    font-size: 28px;
    line-height: 28px;
    text-align: center;

    &.btn_minus {
      background-image: url("~svg/btn_minus.svg");
    }

    &.btn_plus {
      background-image: url("~svg/btn_plus.svg");
    }
  }

  .quantity_txt {
    height: 100%;
    padding: 6px 10px;
    @include font(22, 27, -1.32, medium);
    color: #262626;
    //font-size: 22px;
    //line-height: 30px;
  }
}
</style>
