<template>
  <ul class="list mt_10" :class="pageMode == 'view' ? 'view_list' : ''">
    <li v-for="list in orderList" :key="pageMode == 'view' ? list.id : list.productId">
      <div class="item">
        <div class="store_name">{{ list.storeName }}</div>
        <div class="list_title">
          {{ list.productName }}
          <span v-if="list.weight">{{ $formatPrice(list.weight) + "g" }} </span>
          <span v-if="list.unitNumber">, {{ list.unitNumber }}{{ list.unitName }}</span>
        </div>

        <a
          href="javascript:void(0);"
          class="list_right"
          :data-review-id="pageMode == 'view' ? list.id : list.productId"
          @click="
            $emit('toggleReview', {
              reviewId: pageMode == 'view' ? list.id : list.productId,
              event: $event,
            })
          "
          v-if="pageMode == 'write'"
          >리뷰쓰기</a
        >
        <div v-else-if="(pageMode == 'view' && list.likeYn == 'Y') || list.dislikeYn == 'Y'" class="list_right">
          <Icon type="icon_like2 active" value="" v-if="list.likeYn == 'Y'" />
          <Icon type="icon_dislike2" value="" v-if="list.dislikeYn == 'Y'" />
        </div>

        <div class="order_quantity_price">주문수량 : {{ $formatPrice(list.quantity) }}</div>
        <!--        <div class="order_quantity_price">주문수량 : {{ $formatPrice(list.quantity) }} / {{ $formatPrice(list.payment) }} 원</div>-->
      </div>

      <div class="item review" :class="{ none_border: pageMode == 'view' && !list.content }">
        <div
          class="contenteditable"
          :style="{
            display: pageMode == 'view' && list.content ? 'block' : null,
          }"
          :contenteditable="pageMode == 'write' ? true : false"
          :placeholder="pageMode == 'write' ? '점주님에게 상품 리뷰를 전달할 수 있습니다.' : ''"
          :data-review-id="list.id"
          :data-product-id="list.productId"
        >
          {{ pageMode == "view" ? list.content : "" }}
        </div>
      </div>

      <div class="like_area" v-if="pageMode == 'write'">
        <span class="like_txt">이 상품이 마음에 드시나요?</span>
        <div class="absolute_right">
          <a href="javascript:void(0);" data-type="dislike" @click="$emit('toggleLike', { productId: list.productId, event: $event })">
            <Icon :type="list.dislikeYn == 'Y' ? 'icon_dislike2 active' : 'icon_dislike2'" value="안좋아요" />
          </a>

          <a href="javascript:void(0);" data-type="like" @click="$emit('toggleLike', { productId: list.productId, event: $event })">
            <Icon :type="list.likeYn == 'Y' ? 'icon_like2 active' : 'icon_like2'" value="좋아요" />
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import Icon from "@/components/new/Icon";

export default {
  name: "ReviewList",
  props: {
    pageMode: String,
    orderList: Array,
    toggleReview: Function,
    toggleLike: Function,
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
* {
  -webkit-user-select: none;
  user-select: none;
}

input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.store_name {
  color: $active-color;
}

.list_title {
  width: calc(100% - 85px);
  @include font(16, 22, -0.5, medium);
  color: $nj_black;
  //@include text_ellipsis();
}
.list_right {
  position: absolute;
  top: 13px;
  right: 16px;
  min-width: 80px;
  padding: 3px 0 2px;
  border: {
    style: solid;
    color: $active-color;
    width: 1px;
    radius: 5px;
  }
  text-align: center;
  @include font(14, 22, -0.72, bold);
  color: $active-color !important;
  &.inactive {
    color: $gray !important;
    border-color: $gray;
  }
}
.order_quantity_price {
  margin: 3px 0 0;
  @include font(13, 20, -0.5);
  color: $gray-light;
}
.list {
  &.view_list {
    .contenteditable {
      min-height: auto;
      padding: 15px 0 20px;
      background-color: $background-color-type2;
      border-width: 0;
      color: $gray-middle-dark;
    }
    .list_right {
      top: 13px;
      min-width: auto;
      border-width: 0;
    }
  }
  > li {
    padding: 0;
    margin: 20px 0;
    background-color: $background-color-type2;
    border: {
      style: solid;
      width: 1px;
      color: #dcdcdc;
    }
    border-radius: 5px;

    //border-style: solid;
    //border-width: 0 0 1px;
    //border-color: $nj_divider_gray;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      // padding: 0;
      // border-width: 0;
      .like_area {
        border-width: 0;
      }
    }
  }
  .item {
    display: block;
    position: relative;
    padding: 14px 16px 15px;
    // background-color: $white;
    //@include border-radius(20px);
    //box-shadow: 0px 1px 0px $nj_shadow_gray;
    // margin-bottom: 10px;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  .review {
    //display: none;
    padding: 0;
    margin: 0 16px;
    border: {
      style: solid;
      color: $border-type2;
      width: 1px 0 0;
    }

    &.active {
      border-width: 0;
      .contenteditable {
        display: block;
      }
    }
    &.none_border {
      border-width: 0;
    }
  }

  .contenteditable:empty:before {
    content: attr(placeholder);
    display: block;
    color: $gray;
  }
  .contenteditable {
    display: none;
    width: 100%;
    min-height: 82px;
    height: auto;
    padding: 10px;
    background-color: $white;
    border: {
      style: solid;
      width: 1px;
      color: $border-type2;
      radius: 5px;
    }
    @include font(14, 20, 0);
    color: $gray;
    outline: none;
    //white-space: nowrap;
    overflow: hidden;
  }
  .contenteditable br {
    display: none;
  }
  .contenteditable * {
    display: inline;
    white-space: nowrap;
  }

  //[contenteditable=true]:empty:before{
  //  content: attr(placeholder);
  //  display: block;
  //  color: $nj_placeholder_gray;
  //}
  //div[contenteditable=true] {
  //  display: block;
  //  width: 100%;
  //  min-height: 110px;
  //  height: auto;
  //  border-width: 0;
  //  @include font(16, 22, -0.96);
  //  color: $nj_text_main_gray;
  //  outline: none;
  //  //white-space: nowrap;
  //  overflow: hidden;
  //}
  //[contenteditable=true] br {
  //  display: none;
  //}
  //[contenteditable=true] * {
  //  display:inline;
  //  white-space:nowrap;
  //}
}
.absolute_right {
  position: absolute;
  top: 13px;
  right: 20px;
}

.like_area {
  position: relative;
  padding: 13px 16px;
  //border-style: solid;
  //border-width: 0 0 1px;
  //border-color: $nj_divider_gray;
  .absolute_right {
    position: relative;
    top: unset;
    right: unset;
    //text-align: right;
    > a:nth-child(2) {
      margin: 0 0 0 10px;
    }
  }
}

@media (min-width: 370px) {
  .like_area {
    .absolute_right {
      position: absolute;
      top: 13px;
      right: 20px;
    }
  }
}

.like_txt {
  @include font(14, 18, -0.75);
  color: $black;
}
</style>
