<template>
  <div>
    <TopHeader class="top_header" :title="$route.params.title" :isCartVisible="false" @goBack="goBack" />
    <div class="contents">
      <iframe :src="$route.params.url" class="event_detail" id="iframe" />
    </div>
  </div>
</template>
<script>
import TopHeader from "@/components/new/TopHeader";
import router from "@/router";

export default {
  methods: {
    goBack: function () {
      this.$navigateBack();
    },
    disableRefresh(event) {
      // 새로고침 방지
      if (event.key === "F5" || event.key === "F11" || ((event.ctrlKey || event.metaKey) && event.keyCode === 82)) {
        event.preventDefault();
      }
    },
  },
  components: {
    TopHeader,
  },
  mounted() {
    window.onBackPressed = this.goBack;

    const _this = this;
    window.addEventListener("blur", function () {
      window.setTimeout(function () {
        if (document.activeElement == document.querySelector("#iframe")) {
          const title = _this.$route.params.title;
          let event = "";

          if (title.includes("녹축산시장")) {
            event = "녹축산시장";
          } else if (title.includes("수산")) {
            event = "수산";
          } else {
            return;
          }

          _this.$router.push({ name: "놀장 메인", params: { tabType: event } });
        }
      }, 0);
    });
  },
  beforeMount() {
    window.addEventListener("keydown", this.disableRefresh);
  },
  destroyed() {
    window.removeEventListener("keydown", this.disableRefresh);
  },
};
</script>
<style lang="scss" scoped>
.event_detail {
  width: 100%;
  height: calc(100vh - 58px);
  border: none;
}
</style>
