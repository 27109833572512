import Vue from "vue";
import Vuex from "vuex";

import cartMarket from "./cartMarket";
import cartShip from "./cartShip"; // 장바구니 배송지
import categoryProduct from "./categoryProduct"; // 카테고리 상품 목록
// import coupon from './coupon';
import market from "./market"; // 전체시장 리스트
// import payment from "./payment"; // 결제완료
import favoriteStore from "./favoriteStore"; // 단골가게
// import orderDetail from './orderDetail';// 주문상세
// import orderHistory from './orderHistory';// 주문목록
import deliveryStore from "./deliveryStore"; // 설정
import withdraw from "./withdraw"; // 회원탈퇴
// import productInquiry from './productInquiry';// 상품문의보기
import productInquiryWrite from "./productInquiryWrite"; // 상품문의쓰기
import deliveryAddr from "./deliveryAddr"; // 배송지 설정

import setting from "./setting"; // 설정

import testTemplate from "./testTemplate"; // 템플릿으로 사용

import main from "@/store/new/main"; // 메인
import product from "@/store/new/product"; // 상품 메인
import address from "@/store/new/address"; // 배송지 설정
import marketMain from "@/store/new/market"; // 시장 메인
import search from "@/store/new/search"; // 시장 메인
import store from "@/store/new/store"; // 검색
import cart from "@/store/new/cart"; // 장바구니
import coupon from "@/store/new/coupon"; // 쿠폰 리스트
import paymentNew from "@/store/new/paymentNew"; // 결제하기
import completePayment from "@/store/new/completePayment"; // 결제 완료
import orderHistory from "@/store/new/orderHistory"; // 주문내역
import orderDetail from "@/store/new/orderDetail"; // 주문상세
import like from "@/store/new/like"; // 찜리스트
import productInquiry from "@/store/new/productInquiry"; // 상품문의
import review from "@/store/new/review"; // 리뷰쓰기
import recommendMember from "@/store/new/recommendMember"; // 추천인 이벤트
import marketList from "@/store/new/marketList"; // 전체시장 리스트
import calendar from "@/store/new/calendar"; // 전체시장 리스트
import privacyInfo from "@/store/new/privacyInfo"; // 개인정보처리방침
import termsConditions from "@/store/new/termsConditions"; // 이용약관
import productOptions from "@/store/new/productOptions"; // 상품옵션

import Modal from "@/components/new/Modal";
import router from "@/router"; // 공통 모달 컴포넌트

// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: null,
    userAddress: null,
    userCart: null,
    userCartTotalCount: null,
    useCoupon: null,
    orderProducts: null,
    paymentWebMode: true,
    zipCode: null,

    msgType: null,
    msgText: null,
    msgAddText: null, // 사용안함
    msgCallback: null, // 사용안함

    modalBottom: {
      showHide: true,
      type: null,
      activeId: null,
      options: [],
      callback: null,
    },

    appVersion: null, // 앱버전이 있으면 Flutter 적용 버전으로 간주한다.

    appYn: true,

    customerServiceCenterNumber: "0220396742",

    sssMarkets: [], // 서울시특판 시장 아이디 // TODO : 이벤트 종료 시장 수동으로 아이디 삭제 후 배포 진행
    // sssMarkets: [17, 59, 149, 16, 67],// 서울시특판 시장 : 17 목사랑시장, 59 면곡시장, 149 장미원골목시장, 16 신창시장, 67 장위전통시장
    // sssMarkets: [67, 103, 159],// 서울시특판 시장 : 67 장위전통시장, 103 인왕시장, 159 마포농수산물시장

    // 배송희망일 적용 상품 아이디
    useDeliveryDesiredDateProducts: [101992, 102855], // , 102548, 102856
    // 농할 이벤트는 적용되었지만 '농할' 라벨 표시하지 않는 상품 아이디
    hideAtLabelProducts: [], //101992, 102855
  },
  mutations: {
    setMsg: function (state, data) {
      state.msgType = data.type;
      state.msgText = data.msgText;
      state.msgAddText = data.addText;
      state.msgCallback = data.callback;
    },
    hideMsg: function (state) {
      state.msgType = null;
      state.msgText = null;
      state.msgAddText = null;
      state.msgCallback = null;
    },
    rootInitState: function (state, data) {
      // console.log("***root init state appversion", data);
      const { shippingAddress, cart, version } = data;

      let userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.indexOf("edge") > -1) {
        this.state.appYn = false;
      } else if (userAgent.indexOf("whale") > -1) {
        this.state.appYn = false;
      } else if (userAgent.indexOf("chrome") > -1) {
        // console.log("appYn", this.state.appYn);
        this.state.appYn = false;
      } else if (userAgent.indexOf("firefox") > -1) {
        this.state.appYn = false;
      }

      // 로컬 앱 버전 데이터 초기화
      if (version) {
        state.appVersion = version;
      }

      // 로컬 장바구니 데이터 초기화
      if (cart) {
        let userCartTotalCount = 0;

        cart.forEach((item) => {
          userCartTotalCount += item.products.length;

          // 앱 업데이트전 사용자 장바구니 데이터 대응
          if (item.marketId === 23 || item.marketId === 95) {
            item.products.forEach((product) => {
              if (product.deliveryMethod === "UNKNOWN" || !product.deliveryMethod) {
                product.deliveryMethod = "TB";
              }
            });
          } else {
            item.products.forEach((product) => {
              if (product.deliveryMethod === "UNKNOWN" || !product.deliveryMethod) {
                product.deliveryMethod = "BD";
              }
            });
          }
        });

        state["userCart"] = cart;
        state["userCartTotalCount"] = userCartTotalCount;
        localStorage.setItem("userCart", JSON.stringify(cart));
        localStorage.setItem("userCartTotalCount", JSON.stringify(userCartTotalCount));
      } else {
        state["userCartTotalCount"] = 0;
        localStorage.setItem("userCartTotalCount", JSON.stringify(0));
      }

      // 로컬 배송지 데이터 초기화
      if (shippingAddress) {
        if (!state.userId) {
          shippingAddress.roadNameAddress = shippingAddress.roadNameAddress.substring(shippingAddress.roadNameAddress.indexOf(" ") + 1);
        }
        state["userAddress"] = shippingAddress;
        localStorage.setItem("userAddress", JSON.stringify(shippingAddress));

        if (data.callback) {
          data.callback();
        }
      }
    },
    initUserId: function (state, data) {
      // console.log("initUserId ::::::: ");
      // console.log(data);
      if (data?.userId) {
        var userId = data.userId;
        // console.log(userId);
        if (userId.indexOf("?userId=") > -1) {
          userId = userId.substring(0, userId.indexOf("?userId="));
          data.userId = userId;
          // console.log("data.userId USER_ID 재조정");
          // console.log(userId);
        }
      }
      state["userId"] = data.userId;
      localStorage.setItem("userId", data.userId);
    },
    useCoupon: function (state, data) {
      // console.log("useCoupon");
      // console.log(data);
      state.useCoupon = data;
      // console.log(state.useCoupon);
    },
    setOrderProducts: function (state, data) {
      // console.log("setOrderProducts");
      // console.log("setOrderProducts");
      // console.log("setOrderProducts");
      // console.log(data);
      state.orderProducts = data;
      // console.log(state.orderProducts);
    },
    updateState: function (state, data) {
      if (data.type === "zipCode") {
        // console.log("updateState");
        // console.log(data);
      }
      if (data.append) {
        // 데이터를 추가할때 적용
        if (state[data.type]) {
          state[data.type] = state[data.type].concat(data.updateData);
        } else {
          state[data.type] = data.updateData;
        }
      } else {
        state[data.type] = data.updateData;
      }
      if (data.callback) {
        data.callback();
      }
    },

    updateBottom: function (state, { data, commonModule = false } = {}) {
      const targetState = commonModule ? state : state[state.currentPage];
      if (data?.append) {
        if (targetState[data.type] && targetState[data.type].length > 0) {
          targetState[data.type] = [...targetState[data.type], ...data.updateData];
        } else {
          targetState[data.type] = data.updateData;
        }
      } else {
        targetState[data?.type] = data?.updateData;
      }
      if (data?.callback) {
        data.callback();
      }
    },
  },
  actions: {
    setMsg: function (store, data) {
      const _this = this;
      _this.commit("setMsg", data);
      setTimeout(
        function () {
          _this.commit("hideMsg");
        },
        !data.second ? data.msgText.length * 200 : data.second,
      );
    },
    hideMsg: function () {
      this.commit("hideMsg");
    },
    // 결제하기 웹뷰 안정화 작업 위한 검증 소스
    checkPaymentWebMode: function (store) {
      // console.log("checkPaymentWebMode");
      // console.log(store);

      var returnBoolean = this._vm.paymentWebMode;

      if (!returnBoolean) {
        var now = new Date();
        var webModeDate = new Date("2022-03-07");

        if (now >= webModeDate) {
          returnBoolean = true;
        }
        // else {
        //   const userId = localStorage.getItem("userId");
        //   if (
        //     userId == "B6545D8E-ECE0-4044-9D52-C05496D4D410" || // 신효원
        //     userId == "59045cee-9c39-486f-9bf2-6214d38b0d95" // 장재호
        //   ) {
        //     returnBoolean = true;
        //   }
        // }
      }
      store.commit("updateState", {
        type: "paymentWebMode",
        updateData: returnBoolean,
      });

      return returnBoolean;
    },
    deleteCartByMarketId: function (store, data) {
      if (typeof data == "string") {
        data = JSON.parse(data);
      }

      const cartData = JSON.parse(localStorage.getItem("userCart"));

      const userCart = cartData.filter((cart) => cart.marketId != data.marketId);
      this.commit("rootInitState", { cart: userCart });
    },
    setOrderProducts: function (store, data) {
      this.commit("setOrderProducts", data);
    },
    useCoupon: function (store, data) {
      this.commit("useCoupon", data);
    },
    initUserId: function (store, data) {
      this.commit("initUserId", data);
    },
    rootInitState: function (store, data) {
      this.commit("rootInitState", data);
    },
    navigateToProductOption: async function (store, data) {
      try {
        // 상품 정보 조회
        const productUrl = "/v3/market/delivery/product/" + data.productId + "/user/" + (this.state.userId ? this.state.userId : "null");
        const productResponse = await this._vm.$axios1.get(productUrl);
        if (productResponse.data.responseCode == 2000) {
          const product = productResponse.data.result;
          if (product.notationalInformationList) {
            product.notationalInformationList = product.notationalInformationList.filter((item) => {
              return item.title || item.content;
            });
          }
          data.product = productResponse.data.result;
        } else {
          const logError = (response, message) => {
            console.error(
              "Response Code:: " +
                response.responseCode +
                "\n" +
                "Error Message:: " +
                response.errorObject.errorMessage +
                "\n" +
                "Message:: " +
                message,
            );
          };
          logError(productResponse.data, "상품 정보 조회에 실패하였습니다.");
        }
      } catch (e) {
        console.error(e);
      }

      const userCart = JSON.parse(localStorage.getItem("userCart"));

      // var cartCompareCount = 0;
      var targetMarket = null;
      var marketId = data.product.marketId;
      const cartLength = userCart.length;
      if (cartLength > 0) {
        for (var i = 0; i < cartLength; i++) {
          if (userCart[i].marketId == marketId) {
            targetMarket = userCart[i];
          }
        }
      }

      var errorCode = 0; // 0 일때 정상 프로세스 진행

      if (targetMarket) {
        const productInfo = data.product; // 상품 정보
        const deliveryMethod = productInfo.deliveryMethod; // 배송 타입
        const storeId = productInfo.storeId; // 상점 아이디

        if (deliveryMethod == "PKG") {
          if (targetMarket.products) {
            const products = targetMarket.products;
            for (const product of products) {
              if (product.deliveryMethod == "FD") {
                if (product.storeId != storeId) {
                  errorCode = 1; // 묶음 상품을 장바구니에 담을때 음식배달 상품이 존재하고 다른 상점 상품일때
                  break;
                }
              }
              // if (product.deliveryMethod == "TB") {
              //   errorCode = 4;
              //   break;
              // }
            }
          }
        } else if (deliveryMethod == "TB") {
          // 택배
          if (targetMarket.products) {
            const products = targetMarket.products;
            for (const product of products) {
              if (product.deliveryMethod == "FD" || product.deliveryMethod == "FDPKG") {
                errorCode = 1; // 음식배달이 존재할때
                break;
              }
              if (product.storeId != storeId) {
                errorCode = 2;
                break;
              }
            }
          }
        } else if (deliveryMethod == "FD" || deliveryMethod == "FDPKG") {
          // 음식
          if (targetMarket.products) {
            const products = targetMarket.products;

            var fdProductCount = 0;
            if (deliveryMethod == "FDPKG") {
              for (const item of products) {
                if (item.deliveryMethod == "FD") {
                  fdProductCount++;
                }
              }
            }

            for (const product of products) {
              if (product.storeId != storeId) {
                if (product.deliveryMethod == "PKG") {
                  if (deliveryMethod == "FDPKG") {
                    if (fdProductCount > 0) {
                      errorCode = 3;
                    }
                  } else {
                    errorCode = 3;
                  }
                } else {
                  errorCode = 5;
                }
                break;
              }
              if (product.deliveryMethod == "TB") {
                errorCode = 4;
                break;
              }
            }
          }
        }
      }

      if (data.product.productSaleYn == "N") {
        errorCode = 6;
      } else if (data.product.openStoreYn == "N") {
        errorCode = 7;
      } else if (data.product.productDeleteYn == "Y") {
        errorCode = 8;
      }

      var compareMsgs = null;

      if (errorCode == 1) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>장바구니에 음식 상품이 담겨 있습니다.</strong>",
          "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
        ];
      } else if (errorCode == 2) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>택배 상품은 같은 상점의 상품만 <br>담을 수 있습니다.</strong>",
          "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
        ];
      } else if (errorCode == 3) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>장바구니에 묶음 상품이 담겨 있습니다.</strong>",
          "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
        ];
      } else if (errorCode == 4) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>장바구니에 택배 상품이 담겨 있습니다.</strong>",
          "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
        ];
      } else if (errorCode == 5) {
        compareMsgs = [
          "<strong style='display: block; margin: 0 0 10px;'>음식 상품은 같은 상점의 상품만 <br>담을 수 있습니다.</strong>",
          "장바구니의 상품을 모두 주문하시거나, 비운 후 <br>선택하신 상품을 담을 수 있습니다.",
        ];
      } else if (errorCode == 6) {
        compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>상품을 구매하실 수 없습니다.</strong>"];
      } else if (errorCode == 7) {
        if (new Date().getHours() < 12) {
          compareMsgs = [
            "<strong style='display: block; margin: 0 0 10px;'>영업 준비 중인 상점의 상품입니다.<br/>장바구니에 담을수 없습니다.</strong>",
          ];
        } else {
          compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>영업 종료된 상점의 상품입니다.<br/>장바구니에 담을수 없습니다.</strong>"];
        }
      } else if (errorCode == 8) {
        compareMsgs = ["<strong style='display: block; margin: 0 0 10px;'>유효하지 않은 상품입니다.</strong>"];
      }

      if (errorCode > 0) {
        const _this = this;
        const callback = (isBoolean) => {
          _this._vm.$modal.hideAll();
          if (isBoolean) {
            router.push({ name: "장바구니", params: { reload: true } });
          }
        };

        const compProps = {
          title: "장바구니 담기",
          type: "order_notice",
          marketId: String(marketId),
          msgArr: compareMsgs, // html 코드 적용 가능
          btnArr: [
            {
              txt: "닫기",
              type: "type4 normal",
              btnCallback: () => callback(false),
            },
          ],
          marketOrderInfo: {
            enableYn: "N",
          },
          closeModal: function () {
            _this._vm.$modal.hide("order_notice");
          },
        };
        this.dispatch("showModal", { name: "order_notice", compProps: compProps, modalProps: null }, { root: true });

        return;
      }

      // 장바구니에 음식 상품이 담겨 있습니다.
      // 장바구니에 묶음 상품이 담겨 있습니다.
      // 음식 상품은 같은 상점의 상품만 담을 수 있습니다.
      // 택배 상품은 같은 상점의 상품만 담을 수 있습니다.
      // 장바구니에 택배 상품이 담겨 있습니다.

      // if (state.userId != "B6545D8E-ECE0-4044-9D52-C05496D4D410") {
      // }
      // TODO :: 현재 담으려는 상품의 구분 (BD, TB, QUICK)과 장바구니 내의 상품의 구분이 동일한지 체크
      // TODO :: 동일하지 않을 시 Alert 표시
      const passData = {
        productId: data.productId,
      };

      this._vm.$navigateTo("product-option", passData);
    },
    showModal: function (store, { name, compProps, modalProps, modalEvents, seconds }) {
      const modal = this._vm.$modal;

      if (!name) {
        name = "modal_" + Date.parse(new Date());
      }
      if (!compProps) {
        compProps = {
          msgArr: ["Modal"], // html 코드 적용 가능
        };
      }
      if (!modalProps) {
        modalProps = {
          name: name,
          width: "90%",
          maxWidth: 400,
          height: "auto",
          adaptive: true, // true로 설정이 되어야 maxWidth 적용된다.
        };
      }

      if (!modalEvents) {
        modalEvents = {
          // 'before-open': event => {
          // },
          opened: () => {
            // 전체메뉴 보다 index 높게 처리
            const modalsContainer = document.querySelector("#modals-container");
            modalsContainer.style.position = "relative";
            modalsContainer.style.zIndex = 99999;

            if (seconds) {
              setTimeout(function () {
                modal.hide(name);
              }, seconds * 1000);
            }
          },
          "before-close": (event) => {
            if (compProps.type == "image") {
              const checkedMarket = document.querySelector("#closeTodayPopup:checked");
              if (checkedMarket) {
                localStorage.setItem(
                  "checkedMarket_" + checkedMarket.dataset.marketId,
                  new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
                );
              }
              // event.cancel();
            }
          },
          // 'closed': () => {
          // },
        };
      }
      modal.show(Modal, compProps, modalProps, modalEvents);
    },

    setModalBottom: function (store, data) {
      this.commit("updateBottom", { data: { type: "modalBottom", updateData: data }, commonModule: true });
    },
    appDownloadModal: function () {
      this.dispatch("showModal", {
        name: "downloadApp",
        compProps: {
          title: "앱 다운로드",
          msgArr: ["모바일 앱에서 사용가능한 기능입니다."],
          btnArr: [
            {
              type: "type1 inverse",
              txt: "다운로드",
              btnId: "",
              btnData: {},
              btnCallback: function () {
                const agent = navigator.userAgent.toLowerCase();
                let url = "";

                // AOS
                if (agent.indexOf("android") > -1) {
                  //this.state.appYn = true;
                  url = "https://play.google.com/store/apps/details?id=kr.wiju.noljang";
                }
                // iOS
                else {
                  //this.state.appYn = true;
                  url = "https://apps.apple.com/kr/app/%EB%86%80%EB%9F%AC%EC%99%80%EC%9A%94-%EC%8B%9C%EC%9E%A5/id1169889915";
                }

                window.open(url, "_blank");
              },
            },
          ],
          closeModal: function () {
            this.$modal.hideAll();
          },
        },
      });
    },
    async chackToken() {
      const res = await this._vm.$axios.get("https://lopy-api.komscochak.com/opapi/v2/auth/token", {
        headers: {
          Authorization: "Basic " + btoa("1f8ccac0e6224a72ad636aa5abb8c09a:241531390498268765042098211120781423770594672317"),
        },
      });

      if (res.data.head.resultCode === "200") {
        const decryped = await this.dispatch("decryptChack", res.data.body.encData);
        return decryped.tokenType + " " + decryped.token;
      } else {
        console.log("ERROR::: opapi/v2/auth/token");
      }
    },
    decryptChack(state, data) {
      const decrypted = CryptoJS.SEED.decrypt(data, CryptoJS.enc.Utf8.parse("opapiDevKey00001"), {
        iv: CryptoJS.enc.Utf8.parse("localpay.madp.iv"),
      }).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decrypted);
    },
    encryptChack(state, data) {
      return CryptoJS.SEED.encrypt(data, CryptoJS.enc.Utf8.parse("opapiDevKey00001"), { iv: CryptoJS.enc.Utf8.parse("localpay.madp.iv") }).toString();
    },
  },
  getters: {
    userId: (state) => {
      return state.userId;
    },
    paymentWebMode: (state) => {
      return state.paymentWebMode;
    },
  },
  modules: {
    cartShip,
    categoryProduct,
    // coupon,
    market,
    cartMarket,
    favoriteStore,
    // payment,
    // orderHistory,
    // setting,
    deliveryStore,
    // withdraw,
    // productInquiry,
    // productInquiryWrite,
    deliveryAddr,

    testTemplate, // 템플릿으로 사용

    main,
    product,
    address,
    marketMain,
    search,
    store,
    coupon,
    paymentNew,
    completePayment,
    cart,
    orderHistory,
    orderDetail,
    like,
    productInquiry,
    productInquiryWrite,
    review,
    recommendMember,
    marketList,
    calendar,
    withdraw,
    setting,
    privacyInfo,
    termsConditions,
    productOptions,
  },
});
