<template>
  <div class="double_tab_wrap ignore_touch_x" :class="isHideArrow ? 'reverse' : null">
    <div class="largeTab_wrap" :class="{ vertical_scroll: !appVersion }" v-if="!isHideArrow && largeTabItems.length > 1">
      <ul class="largeTab_list" :class="{ img_tab: imgTab }" v-show="mediumTabItems.length === 0 || !showMedium">
        <li v-for="(tabItem, idx) in largeTabItems" :key="idx" class="largeTab">
          <a href="javascript:void(0);" :class="{ active: idx === largeTabActiveIndex }" @click="onClickLargeTab(idx)">
            <img :src="tabItem.categoryImg" :alt="tabItem.categoryName" v-if="imgTab" class="cate_img" loading="lazy" />
            {{ tabItem.categoryName }}
          </a>
        </li>
      </ul>
      <div class="largeTab_bar" />
    </div>
    <div
      class="mediumTab_container"
      v-show="!imgTab && tabType !== 'type5' && showMedium"
      :style="isHideArrow ? 'padding-left: 0 !important;' : null"
    >
      <div class="back_arrow" :style="isHideArrow ? 'display: none;' : null">
        <a href="javascript:void(0);" title="이전메뉴" @click="onClickBackArrow">이전메뉴</a>
      </div>
      <div class="mediumTab_wrap" :style="isHideArrow ? 'margin-left: 0 !important; padding-left: 15px !important;' : null">
        <ul class="mediumTab_list">
          <li v-for="(tabItem, idx) in mediumTabItems" :key="idx" class="mediumTab">
            <a href="javascript:void(0);" :class="{ active: idx === mediumTabActiveIndex }" @click="onClickMediumTab(idx)">
              <img :src="tabItem.categoryImg" :alt="tabItem.categoryName" v-if="imgTab" class="cate_img" loading="lazy" />
              {{ tabItem.subCategoryName }}
            </a>
          </li>
        </ul>
        <div class="mediumTab_bar" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "DoubleTab",
  props: {
    // 필수 props 입니다.
    largeTabItems: Array,
    imgTab: Boolean,
    largeTabActiveIndex: {
      type: Number,
      default: 0,
    },
    mediumTabItems: Array,
    mediumTabActiveIndex: {
      type: Number,
      default: 0,
    },
    isMediumTabVisible: {
      type: Boolean,
      default: false,
    },
    isHideArrow: {
      type: Boolean,
      default: false,
    },
    // 옵션 props 입니다.
    tabType: {
      type: String,
      default: "type1", // ["type1", "type2", "type3", "type4"]
    },
    tabScrollType: {
      type: String,
      default: "center", // ["center","normal"]
    },
    // 예외 case 일 경우 특정 css 적용 가능
    largeTabWrapHeight: Number,
    largeTabWrapPaddingX: Number,
    largeTabWrapBackgroundColor: String,
    largeTabWrapBorderBottomStyle: String, // ['solid','none']
    largeTabInterval: Number,
    largeTabPaddingX: Number,
    activeLargeTabTextColor: String,
    activeLargeTabBackgroundColor: String,
    activeLargeTabFontWeight: [Number, String], // ['normal','bold', 700 등등...]
    isActiveLargeTabHasBackground: Boolean,
    isLargeTabBarExist: Boolean,
    largeTabBarBackgroundColor: String,
    largeTabFontSize: Number,
    largeTabLineHeight: Number,
    largeTabLetterSpacing: Number,
    largeTabFontWeight: [Number, String],
    largeTabBorderColor: String,

    mediumTabWrapHeight: Number,
    mediumTabWrapPaddingX: Number,
    mediumTabWrapBackgroundColor: String,
    mediumTabWrapBorderBottomStyle: String, // ['solid','none']
    mediumTabInterval: Number,
    mediumTabPaddingX: Number,
    activeMediumTabTextColor: String,
    activeMediumTabBackgroundColor: String,
    activeMediumTabFontWeight: [Number, String], // ['normal','bold', 700 등등...]
    isActiveMediumTabHasBackground: Boolean,
    isMediumTabBarExist: Boolean,
    mediumTabBarBackgroundColor: String,
    mediumTabFontSize: Number,
    mediumTabLineHeight: Number,
    mediumTabLetterSpacing: Number,
    mediumTabFontWeight: [Number, String],
    mediumTabBorderColor: String,
  },
  data: function () {
    return {
      showMedium: false,
      loading: false,
      type1: {
        largeTabWrapHeight: 42,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 0,
        largeTabTextColor: "black",
        largeTabPaddingX: 15,
        isActiveLargeTabHasBackground: false,
        activeLargeTabTextColor: "#FF563F",
        activeLargeTabBackgroundColor: "white",
        activeLargeTabFontWeight: 600,
        isLargeTabBarExist: true,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 17,
        largeTabLineHeight: 20,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: 600,
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 42,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 0,
        mediumTabPaddingX: 15,
        isActiveMediumTabHasBackground: false,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: 600,
        isMediumTabBarExist: true,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 17,
        mediumTabLineHeight: 20,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: 600,
        mediumTabBorderColor: "white",
      },
      type2: {
        largeTabWrapHeight: 50,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "#F9F9F9",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "normal",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 23,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 50,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "#F9F9F9",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 23,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
      type3: {
        largeTabWrapHeight: 44,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "none",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "normal",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 24,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 44,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "none",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "white",
        activeMediumTabBackgroundColor: "#FF563F",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 24,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "white",
      },
      type4: {
        largeTabWrapHeight: 43,
        largeTabWrapPaddingX: 16,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "none",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: false,
        activeLargeTabTextColor: "#FF563F",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "bold",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 24,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "#FF563F",

        mediumTabWrapHeight: 43,
        mediumTabWrapPaddingX: 16,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "none",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: false,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "#FF563F",
        activeMediumTabFontWeight: "bold",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 24,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
      type5: {
        largeTabWrapHeight: 50,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "#F9F9F9",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 5,
        largeTabPaddingX: 10,
        largeTabTextColor: "#707070",
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "bold",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 14,
        largeTabLineHeight: 23,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 50,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "#F9F9F9",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 23,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
    };
  },
  methods: {
    onClickLargeTab(switchLargeTabIndex) {
      this.showMedium = switchLargeTabIndex !== 0;
      this.$emit("clickLargeTab", switchLargeTabIndex);
    },
    changeLargeTabBarWidth(switchLargeTabIndex) {
      const isLargeTabBarExist = this.isLargeTabBarExist || this[this.tabType].isLargeTabBarExist;
      if (isLargeTabBarExist) {
        const largeTabEl = document.getElementsByClassName("largeTab")[switchLargeTabIndex];
        const largeTabBarEl = document.querySelector(".largeTab_bar");
        const isLastIndex = this.largeTabItems.length - 1 === switchLargeTabIndex;
        largeTabBarEl.style.width = isLastIndex
          ? largeTabEl.clientWidth - (this.largeTabWrapPaddingX || this[this.tabType].largeTabWrapPaddingX) + "px"
          : largeTabEl.clientWidth + "px";
      }
    },
    changeLargeTabBarLeft(switchLargeTabIndex) {
      const isLargeTabBarExist = this.isLargeTabBarExist || this[this.tabType].isLargeTabBarExist;
      if (isLargeTabBarExist) {
        let left = this.largeTabWrapPaddingX || this[this.tabType].largeTabWrapPaddingX;
        for (let i = 0; i < switchLargeTabIndex; i++) {
          const largeTabEl = document.getElementsByClassName("largeTab")[i];
          left += largeTabEl.clientWidth + (this.largeTabInterval || this[this.tabType].largeTabInterval);
        }
        const largeTabBarEl = document.querySelector(".largeTab_bar");
        largeTabBarEl.style.left = left + "px";
      }
    },
    changeLargeTabWrapScrollLeft(switchLargeTabIndex, behavior) {
      if (this.tabScrollType === "center") {
        let scrollLeft = this.largeTabWrapPaddingX || this[this.tabType].largeTabWrapPaddingX;
        for (let i = 0; i <= switchLargeTabIndex; i++) {
          const isLastIndex = i === switchLargeTabIndex;
          const largeTabEl = document.getElementsByClassName("largeTab")[i];
          scrollLeft += isLastIndex
            ? largeTabEl?.clientWidth / 2 - window.innerWidth / 2
            : largeTabEl?.clientWidth + (this.largeTabInterval || this[this.tabType].largeTabInterval);
        }
        scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
        const largeTabWrapEL = document.querySelector(".largeTab_wrap");
        largeTabWrapEL?.scrollTo({ behavior: behavior, left: scrollLeft });
      } else if (this.tabScrollType === "normal") {
        const largeTabEl = document.getElementsByClassName("largeTab")[switchLargeTabIndex];
        largeTabEl?.scrollIntoView({ behavior: behavior, block: "nearest" });
      }
    },

    onClickMediumTab(switchMediumTabIndex) {
      if (!this.loading) {
        this.$emit("clickMediumTab", switchMediumTabIndex);
      }
    },
    changeMediumTabBarWidth(switchMediumTabIndex) {
      const isMediumTabBarExist = this.isMediumTabBarExist || this[this.tabType].isMediumTabBarExist;
      if (isMediumTabBarExist) {
        const mediumTabEl = document.getElementsByClassName("mediumTab")[switchMediumTabIndex];
        const mediumTabBarEl = document.querySelector(".mediumTab_bar");
        const isLastIndex = this.mediumTabItems.length - 1 === switchMediumTabIndex;
        mediumTabBarEl.style.width = isLastIndex
          ? mediumTabEl.clientWidth - (this.mediumTabWrapPaddingX || this[this.tabType].mediumTabWrapPaddingX) + "px"
          : mediumTabEl.clientWidth + "px";
      }
    },
    changeMediumTabBarLeft(switchMediumTabIndex) {
      const isMediumTabBarExist = this.isMediumTabBarExist || this[this.tabType].isMediumTabBarExist;
      if (isMediumTabBarExist) {
        let left = 0;
        for (let i = 0; i < switchMediumTabIndex; i++) {
          const mediumTabEl = document.getElementsByClassName("mediumTab")[i];
          left += mediumTabEl.clientWidth + (this.mediumTabInterval || this[this.tabType].mediumTabInterval);
        }
        const mediumTabBarEl = document.querySelector(".mediumTab_bar");
        mediumTabBarEl.style.left = left + "px";
      }
    },
    changeMediumTabWrapScrollLeft(switchMediumTabIndex, behavior) {
      if (this.tabScrollType === "center") {
        let scrollLeft = 0;
        const backArrowEl = document.querySelector(".back_arrow");
        for (let i = 0; i <= switchMediumTabIndex; i++) {
          const isLastIndex = i === switchMediumTabIndex;
          const mediumTabEl = document.getElementsByClassName("mediumTab")[i];
          if (!mediumTabEl) {
            break;
          }
          scrollLeft += isLastIndex
            ? mediumTabEl.clientWidth / 2 -
              window.innerWidth / 2 +
              (this.mediumTabWrapPaddingX || this[this.tabType].mediumTabWrapPaddingX) * 2 +
              backArrowEl.clientWidth
            : mediumTabEl.clientWidth + (this.mediumTabInterval || this[this.tabType].mediumTabInterval);
        }
        scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
        const mediumTabWrapEL = document.querySelector(".mediumTab_wrap");
        mediumTabWrapEL?.scrollTo({ behavior: behavior, left: scrollLeft });
      } else if (this.tabScrollType === "normal") {
        const mediumTabEl = document.getElementsByClassName("mediumTab")[switchMediumTabIndex];
        mediumTabEl?.scrollIntoView({ behavior: behavior, block: "nearest" });
      }
    },
    onClickBackArrow() {
      this.showMedium = false;
      if (!this.loading && !this.isHideArrow) {
        this.$emit("clickBackArrow");
      }
      this.loading = true;
    },
  },
  mounted() {
    const mediumTabContainerEl = document.querySelector(".mediumTab_container");
    if (this.isMediumTabVisible) {
      mediumTabContainerEl.style.setProperty("opacity", 1);
      mediumTabContainerEl.style.setProperty("z-index", 1);
    } else {
      mediumTabContainerEl.style.setProperty("opacity", 0);
      mediumTabContainerEl.style.setProperty("z-index", -1);
    }
    const tabWrapEL = document.querySelector(".double_tab_wrap");

    if (!this.imgTab) {
      const largeTabWrapHeight = this.largeTabWrapHeight || this[this.tabType].largeTabWrapHeight;
      const largeTabWrapPaddingX = this.largeTabWrapPaddingX || this[this.tabType].largeTabWrapPaddingX;
      const largeTabWrapBackgroundColor = this.largeTabWrapBackgroundColor || this[this.tabType].largeTabWrapBackgroundColor;
      const largeTabWrapBorderBottomStyle = this.largeTabWrapBorderBottomStyle || this[this.tabType].largeTabWrapBorderBottomStyle;
      const largeTabInterval = this.largeTabInterval || this[this.tabType].largeTabInterval;
      const largeTabPaddingX = this.largeTabPaddingX || this[this.tabType].largeTabPaddingX;
      const activeLargeTabTextColor = this.activeLargeTabTextColor || this[this.tabType].activeLargeTabTextColor;
      const isActiveLargeTabHasBackground = this.isActiveLargeTabHasBackground || this[this.tabType].isActiveLargeTabHasBackground;
      const activeLargeTabBackgroundColor = this.activeLargeTabBackgroundColor || this[this.tabType].activeLargeTabBackgroundColor;
      const activeLargeTabFontWeight = this.activeLargeTabFontWeight || this[this.tabType].activeLargeTabFontWeight;
      const isLargeTabBarExist = this.isLargeTabBarExist || this[this.tabType].isLargeTabBarExist;
      const largeTabBarBackgroundColor = this.largeTabBarBackgroundColor || this[this.tabType].largeTabBarBackgroundColor;
      const largeTabFontSize = this.largeTabFontSize || this[this.tabType].largeTabFontSize;
      const largeTabLineHeight = this.largeTabLineHeight || this[this.tabType].largeTabLineHeight;
      const largeTabLetterSpacing = this.largeTabLetterSpacing || this[this.tabType].largeTabLetterSpacing;
      const largeTabFontWeight = this.largeTabFontWeight || this[this.tabType].largeTabFontWeight;
      const largeTabBorderColor = this.largeTabBorderColor || this[this.tabType].largeTabBorderColor;
      const largeTabTextColor = this.largeTabTextColor || this[this.tabType].largeTabTextColor;

      tabWrapEL.style.setProperty("--largeTabWrapHeight", largeTabWrapHeight + "px");
      tabWrapEL.style.setProperty("--largeTabWrapPaddingX", largeTabWrapPaddingX + "px");
      tabWrapEL.style.setProperty("--largeTabWrapBackgroundColor", largeTabWrapBackgroundColor);
      tabWrapEL.style.setProperty("--largeTabWrapBorderBottomStyle", largeTabWrapBorderBottomStyle);
      tabWrapEL.style.setProperty("--largeTabInterval", largeTabInterval + "px");
      tabWrapEL.style.setProperty("--largeTabPaddingX", largeTabPaddingX + "px");
      tabWrapEL.style.setProperty("--activeLargeTabTextColor", activeLargeTabTextColor);
      tabWrapEL.style.setProperty("--activeLargeTabBackgroundColor", isActiveLargeTabHasBackground ? activeLargeTabBackgroundColor : "white");
      tabWrapEL.style.setProperty("--activeLargeTabFontWeight", activeLargeTabFontWeight);
      tabWrapEL.style.setProperty("--largeTabBarDisplay", isLargeTabBarExist ? "inline-block" : "none");
      tabWrapEL.style.setProperty("--largeTabBarBackgroundColor", largeTabBarBackgroundColor);
      tabWrapEL.style.setProperty("--largeTabFontSize", largeTabFontSize + "px");
      tabWrapEL.style.setProperty("--largeTabLineHeight", largeTabLineHeight + "px");
      tabWrapEL.style.setProperty("--largeTabLetterSpacing", largeTabLetterSpacing + "px");
      tabWrapEL.style.setProperty("--largeTabFontWeight", largeTabFontWeight);
      tabWrapEL.style.setProperty("--largeTabBorderColor", largeTabBorderColor);
      tabWrapEL.style.setProperty("--largeTabTextColor", largeTabTextColor);

      this.changeLargeTabBarWidth(this.largeTabActiveIndex);
      this.changeLargeTabBarLeft(this.largeTabActiveIndex);
      this.changeLargeTabWrapScrollLeft(this.largeTabActiveIndex, "auto");
    }

    const mediumTabWrapHeight = this.largeTabWrapHeight || this.mediumTabWrapHeight || this[this.tabType].mediumTabWrapHeight;
    const mediumTabWrapPaddingX = this.largeTabWrapPaddingX || this.mediumTabWrapPaddingX || this[this.tabType].mediumTabWrapPaddingX;
    const mediumTabWrapBackgroundColor =
      this.largeTabWrapBackgroundColor || this.mediumTabWrapBackgroundColor || this[this.tabType].mediumTabWrapBackgroundColor;
    const mediumTabWrapBorderBottomStyle =
      this.largeTabWrapBorderBottomStyle || this.mediumTabWrapBorderBottomStyle || this[this.tabType].mediumTabWrapBorderBottomStyle;
    const mediumTabInterval = this.largeTabInterval || this.mediumTabInterval || this[this.tabType].mediumTabInterval;
    const mediumTabPaddingX = this.largeTabPaddingX || this.mediumTabPaddingX || this[this.tabType].mediumTabPaddingX;
    const activeMediumTabTextColor = this.activeLargeTabTextColor || this.activeMediumTabTextColor || this[this.tabType].activeMediumTabTextColor;
    const isActiveMediumTabHasBackground =
      this.isActiveLargeTabHasBackground || this.isActiveMediumTabHasBackground || this[this.tabType].isActiveMediumTabHasBackground;
    const activeMediumTabBackgroundColor =
      this.activeLargeTabBackgroundColor || this.activeMediumTabBackgroundColor || this[this.tabType].activeMediumTabBackgroundColor;
    const activeMediumTabFontWeight = this.activeLargeTabFontWeight || this.activeMediumTabFontWeight || this[this.tabType].activeMediumTabFontWeight;
    const isMediumTabBarExist = this.isLargeTabBarExist || this.isMediumTabBarExist || this[this.tabType].isMediumTabBarExist;
    const mediumTabBarBackgroundColor =
      this.largeTabBarBackgroundColor || this.mediumTabBarBackgroundColor || this[this.tabType].mediumTabBarBackgroundColor;
    const mediumTabFontSize = this.largeTabFontSize || this.mediumTabFontSize || this[this.tabType].mediumTabFontSize;
    const mediumTabLineHeight = this.largeTabLineHeight || this.mediumTabLineHeight || this[this.tabType].mediumTabLineHeight;
    const mediumTabLetterSpacing = this.largeTabLetterSpacing || this.mediumTabLetterSpacing || this[this.tabType].mediumTabLetterSpacing;
    const mediumTabFontWeight = this.largeTabFontWeight || this.mediumTabFontWeight || this[this.tabType].mediumTabFontWeight;
    const mediumTabBorderColor = this.largeTabBorderColor || this.mediumTabBorderColor || this[this.tabType].mediumTabBorderColor;

    tabWrapEL.style.setProperty("--mediumTabWrapHeight", mediumTabWrapHeight + "px");
    tabWrapEL.style.setProperty("--mediumTabWrapPaddingX", mediumTabWrapPaddingX + "px");
    tabWrapEL.style.setProperty("--mediumTabWrapBackgroundColor", mediumTabWrapBackgroundColor);
    tabWrapEL.style.setProperty("--mediumTabWrapBorderBottomStyle", mediumTabWrapBorderBottomStyle);
    tabWrapEL.style.setProperty("--mediumTabInterval", mediumTabInterval + "px");
    tabWrapEL.style.setProperty("--mediumTabPaddingX", mediumTabPaddingX + "px");
    tabWrapEL.style.setProperty("--activeMediumTabTextColor", activeMediumTabTextColor);
    tabWrapEL.style.setProperty("--activeMediumTabBackgroundColor", isActiveMediumTabHasBackground ? activeMediumTabBackgroundColor : "white");
    tabWrapEL.style.setProperty("--activeMediumTabFontWeight", activeMediumTabFontWeight);
    tabWrapEL.style.setProperty("--mediumTabBarDisplay", isMediumTabBarExist ? "inline-block" : "none");
    tabWrapEL.style.setProperty("--mediumTabBarBackgroundColor", mediumTabBarBackgroundColor);
    tabWrapEL.style.setProperty("--mediumTabFontSize", mediumTabFontSize + "px");
    tabWrapEL.style.setProperty("--mediumTabLineHeight", mediumTabLineHeight + "px");
    tabWrapEL.style.setProperty("--mediumTabLetterSpacing", mediumTabLetterSpacing + "px");
    tabWrapEL.style.setProperty("--mediumTabFontWeight", mediumTabFontWeight);
    tabWrapEL.style.setProperty("--mediumTabBorderColor", mediumTabBorderColor);

    this.changeMediumTabBarWidth(this.mediumTabActiveIndex);
    this.changeMediumTabBarLeft(this.mediumTabActiveIndex);
    this.changeMediumTabWrapScrollLeft(this.mediumTabActiveIndex, "auto");

    mediumTabContainerEl.style.setProperty("transition", "0.3s");
  },
  watch: {
    largeTabActiveIndex: function () {
      if (this.imgTab) {
        return;
      }

      this.changeLargeTabBarWidth(this.largeTabActiveIndex);
      this.changeLargeTabBarLeft(this.largeTabActiveIndex);
      const mediumTabContainerEl = document.querySelector(".mediumTab_container");
      if (this.mediumTabActiveIndex === 0 && mediumTabContainerEl.style.opacity === "1") {
        this.changeLargeTabWrapScrollLeft(this.largeTabActiveIndex, "auto");
      } else {
        this.changeLargeTabWrapScrollLeft(this.largeTabActiveIndex, "smooth");
      }
    },
    mediumTabActiveIndex: function () {
      if (this.imgTab) {
        return;
      }

      this.changeMediumTabBarWidth(this.mediumTabActiveIndex);
      this.changeMediumTabBarLeft(this.mediumTabActiveIndex);
      this.changeMediumTabWrapScrollLeft(this.mediumTabActiveIndex, "smooth");
    },
    mediumTabItems: function () {
      if (this.imgTab) {
        return;
      }

      setTimeout(() => {
        this.changeMediumTabBarWidth(this.mediumTabActiveIndex);
        this.changeMediumTabBarLeft(this.mediumTabActiveIndex);
        this.changeMediumTabWrapScrollLeft(this.mediumTabActiveIndex, "auto");
      }, 1);
    },
    isMediumTabVisible: function () {
      const mediumTabContainerEl = document.querySelector(".mediumTab_container");
      if (this.isMediumTabVisible) {
        mediumTabContainerEl.style.setProperty("opacity", 1);
        mediumTabContainerEl.style.setProperty("z-index", 1);
      } else {
        mediumTabContainerEl.style.setProperty("opacity", 0);
        mediumTabContainerEl.style.setProperty("z-index", -1);
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.double_tab_wrap {
  position: relative;
  .largeTab_wrap {
    position: relative;
    overflow-x: auto;
    padding-left: var(--largeTabWrapPaddingX);
    background-color: var(--largeTabWrapBackgroundColor);
    border: {
      style: var(--largeTabWrapBorderBottomStyle);
      color: $border-type1;
      width: 0 0 1px;
    }
    .largeTab_list {
      display: flex;
      height: var(--largeTabWrapHeight);
      .largeTab {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        margin-right: var(--largeTabInterval);
        a {
          height: 24px;
          padding: 0 var(--largeTabPaddingX);
          color: var(--largeTabTextColor);
          border-radius: 12px;
          // 240614 글자 크기 수정
          // font-size: var(--largeTabFontSize);
          font-size: 13px;
          line-height: var(--largeTabLineHeight);
          letter-spacing: var(--largeTabLetterSpacing);
          font-weight: var(--largeTabFontWeight);
          border: {
            style: solid;
            color: transparent;
            width: 1px;
          }
          background-color: white;
          &.active {
            color: var(--activeLargeTabTextColor);
            background-color: var(--activeLargeTabBackgroundColor);
            font-weight: var(--activeLargeTabFontWeight);
            border: {
              style: solid;
              color: var(--largeTabBorderColor);
              width: 1px;
            }
          }

          &.event:not(.active) {
            position: relative;
            color: #ff1d48;
            font-weight: bold;
          }
        }
        &:last-child {
          a {
            margin-right: var(--largeTabWrapPaddingX);
          }
        }
      }
    }

    .img_tab {
      border-bottom: 10px solid #f8f8f8;
      padding: 10px 10px 0;

      .largeTab {
        justify-content: center;
        padding: 0 10px;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          width: 100%;
          height: 70px;
          text-align: center;
          font-size: 13px;
          color: #707070;
          border: none;

          &.active {
            border: none;
            font-weight: bold;
            color: $active-color;
          }

          .cate_img {
            height: 30px;
          }
        }
      }
    }

    .largeTab_bar {
      display: var(--largeTabBarDisplay);
      position: absolute;
      top: calc(var(--largeTabWrapHeight) - 2px);
      height: 2px;
      background-color: var(--largeTabBarBackgroundColor);
      transition: left 0.2s, width 0.1s;
    }
  }
  .mediumTab_container {
    display: flex;
    z-index: -1;
    width: 100%;
    align-items: center;
    padding-left: var(--mediumTabWrapPaddingX);
    background-color: var(--mediumTabWrapBackgroundColor);
    border: {
      style: var(--mediumTabWrapBorderBottomStyle);
      color: $border-type1;
      width: 0 0 1px;
    }
    .back_arrow {
      flex: 0 0 auto;
      height: 24px;
      a {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: {
          image: url("~svg/back_white.svg");
          repeat: no-repeat;
          position: 7px center;
          size: 7px;
          color: var(--mediumTabBarBackgroundColor);
        }
        border-radius: 100%;
        white-space: nowrap;
        overflow: hidden;
        color: transparent;
      }
    }
    .mediumTab_wrap {
      position: relative;
      overflow-x: scroll;
      margin-left: var(--mediumTabWrapPaddingX);
      .mediumTab_list {
        display: flex;
        height: var(--mediumTabWrapHeight);
        .mediumTab {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
          margin-right: var(--mediumTabInterval);
          a {
            height: 24px;
            padding: 0 var(--mediumTabPaddingX);
            color: $black;
            border-radius: 12px;
            font-size: var(--mediumTabFontSize);
            line-height: var(--mediumTabLineHeight);
            letter-spacing: var(--mediumTabLetterSpacing);
            font-weight: var(--mediumTabFontWeight);
            border: {
              style: solid;
              color: transparent;
              width: 1px;
            }
            background-color: white;
            &.active {
              color: var(--activeMediumTabTextColor);
              background-color: var(--activeMediumTabBackgroundColor);
              font-weight: var(--activeMediumTabFontWeight);
              border: {
                style: solid;
                color: var(--mediumTabBorderColor);
                width: 1px;
              }
            }
          }
          &:last-child {
            a {
              margin-right: var(--mediumTabWrapPaddingX);
            }
          }
        }
      }
      .mediumTab_bar {
        display: var(--mediumTabBarDisplay);
        position: absolute;
        top: calc(var(--mediumTabWrapHeight) - 2px);
        height: 2px;
        background-color: var(--mediumTabBarBackgroundColor);
        transition: left 0.2s, width 0.1s;
      }
    }
  }
  &.reverse {
    .mediumTab_container {
      background-color: var(--largeTabWrapBackgroundColor);
      border: {
        style: var(--largeTabWrapBorderBottomStyle);
      }
      .back_arrow {
        a {
          background: {
            color: var(--largeTabBarBackgroundColor);
          }
        }
      }
      .mediumTab_wrap {
        .mediumTab_list {
          .mediumTab {
            a {
              &.active {
                color: var(--activeLargeTabTextColor);
                background-color: var(--activeLargeTabBackgroundColor);
                font-weight: var(--activeLargeTabFontWeight);
                border: {
                  color: var(--activeLargeTabBorderColor);
                }
              }
            }
          }
        }
        //.mediumTab_bar {
        //  background-color: var(--largeTabBarBackgroundColor);
        //}
      }
    }
  }
}
</style>
