import router from "@/router";

const state = {
  touch: {
    sx: null,
    sy: null,
    st: null,
    ex: null,
    ey: null,
    et: null,
    touchSensitive: 5,
    touchDirection: null,
    isIgnoreTouchX: null,
    reloadProcess: false,
  },
  userId: null, // 사용자 아이디
  marketId: null, // 시장 아이디
  marketName: null, // 시장 이름
  marketAddress: null, // 시장 주소
  marketDescription: null, // 시장 소개
  orderType: null, // 시장 배송 타입
  isInitStateProgressing: false,
  isProgressing: true, // 로딩바
  isFirstLoading: false, // 페이지 처음 진입시 로딩 완료 여부
  isReloading: false, // 새로고침 중
  isAxios: false,

  // 스크롤 될때 탭메뉴 고정 여부
  topFixed: false, // 메인탭 고정 여부
  tabFixed: false, // 서브탭 고정 여부

  topBanner: null,
  // topBanner: {
  //   bannerImage: "https://s3.ap-northeast-2.amazonaws.com/noljang-dev/event/banner_20211122_152723.png",
  //   detailUrl: "https://play.google.com/store/apps/details?id=kr.wiju.noljang",
  //   noticeId: 1915,
  //   popupImage: "https://s3.ap-northeast-2.amazonaws.com/noljang-dev/event/popup_20211122_152723.png",
  //   registerDateTime: "2999-11-22T15:27:23",
  //   title: "앱 리뷰이벤트 안내",
  // },
  topBannerHeight: null,
  strapBanner: null,
  // strapBanner: {
  //   bannerImage: "https://s3.ap-northeast-2.amazonaws.com/noljang-ops/event/banner_20211103_094216.jpg",
  //   detailUrl: "https://s3.ap-northeast-2.amazonaws.com/noljang-ops/event/detail_20211103_114134.html?userId=37DC0734-EEB5-4A45-B43B-4583A7BE8A79",
  //   noticeId: 1825,
  //   popupImage: null,
  //   registerDateTime: "2021-11-03T09:42:16",
  //   title: "롯데카드 제휴 안내 ",
  // },

  banners: null, // 배너 목록
  bannerActiveIndex: 1, // 배너 활성화 인덱스
  categoryList: null, // 카테고리

  // 핫세일 탭에서 사용
  hotSaleCategory: null, // 핫세일 카테고리
  hotSaleActiveCategory: null, // 핫세일 활성화 카테고리
  hotSaleCategoryProductList: null, // 핫세일 카테고리의 상품 목록

  // 새로 들어왔어요
  newCategory: null, // 새로 들어왔어요 카테고리
  newActiveCategory: null, // 새로 들어왔어요 활성화 카테고리
  newCategoryProductList: null, // 새로 들어왔어요 카테고리의 상품 목록

  // 음식배달
  quickStoreList: null,
  quickStoreActiveCategory: null, // 음식배달 활성화 카테고리
  quickStoreCategory: null, // 음식배달 카테고리
  quickStoreCategoryStore: null, // 음식배달 카테고리의 상점 목록

  // 전체상품
  keepProductActiveId: false, // 전체 상품 아이디를 유지하고 싶을때 true 적용
  productActiveCategory: null, // 전체상품 활성화 카테고리
  productCategoryProductList: null, // 전체상품 카테고리의 상품 목록

  // 페이징 처리 - 모든 카테고리 전역으로 사용
  currentPage: 0,
  totalPages: null,

  // 전체상점
  stores: null, // 전체상점

  // 단골상점
  regularList: null,

  // 시장 메인탭에서 사용
  hotSaleProductList: null, // 핫세일
  newProductList: null, // 오늘 들어왔어요
  recentProductList: null, // 내가본상품
  serviceStoreList: null, // 단골상점
  products: null, // 전체상품
  storesFilter: null, // 전체상점
  activeTabItem: "main", // 활성화된 탭 dataType 적용
  mainTab: null,
  contents: null,
  tabItems: [
    // 탭메뉴
    {
      dataType: "main",
      text: "메인",
      isActive: true,
    },
    // {
    //   dataType: "hot",
    //   text: "핫세일",
    //   isActive: false,
    // },
    // {
    //   dataType: "new",
    //   text: "새로 들어왔어요",
    //   isActive: false,
    // },
    // {
    //   dataType: "favorite",
    //   text: "단골상점",
    //   isActive: false,
    // },
    // {
    //   dataType: "product",
    //   text: "전체상품",
    //   isActive: false,
    // },
    // {
    //   dataType: "store",
    //   text: "전체상점",
    //   isActive: false,
    // },
  ],
  previousTabItems: [], // 리로딩 직전의 탭메뉴
  recommendEvent: null,
  atEventCategoryList: null, // Array
  atEventActiveCategory: null, // String
  atEventProducts: null, // Array
  atEventCategoryProducts: null, // 탭의 상품 목록
  atEventCurrentPage: 0,

  // 전체 상품 대분류 중분류 탭
  productLargeTab: {
    tabItems: [],
    tabActiveIndex: 0,
  },
  productMediumTab: {
    // tabItems: ["음식점 전체", "죽", "국수"],
    tabItems: [],
    tabActiveIndex: 0,
    isMediumTabVisible: false,
  },
  orderInfo: null,
  marketDeliveryYn: null,
};

const mutations = {
  setTouch: function (state, { event, type }) {
    const touchEvent = event.changedTouches ? event.changedTouches[0] : event;
    if (type === "start") {
      state.touch.sx = touchEvent.clientX;
      state.touch.sy = touchEvent.clientY;
      state.touch.st = Date.now();
    } else {
      state.touch.ex = touchEvent.clientX;
      state.touch.ey = touchEvent.clientY;
      state.touch.et = Date.now();
    }
  },
  setTabBarOptions: function (state, dataType) {
    const index = state.tabItems.findIndex((item) => {
      return item.dataType === dataType;
    });
    const tabWrapEl = document.querySelectorAll(".tab-wrap")[0];
    let scrollLeft = 15;
    for (let i = 0; i <= index; i++) {
      const tabEl = tabWrapEl.children[0].children[i];
      scrollLeft += i === index ? (tabEl?.clientWidth || 0) / 2 - window.innerWidth / 2 : tabEl?.clientWidth || 0;
    }
    scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
    tabWrapEl.scrollTo({ behavior: "smooth", left: scrollLeft });
  },
  search: function (state, keyword) {
    if (!keyword.trim()) {
      this._vm.$callNative("showToast", { message: "검색어를 입력해주세요." });
    } else {
      if (keyword.trim().length < 1) {
        this._vm.$callNative("showToast", {
          message: "검색어를 입력해주세요.",
        });
      } else {
        router.push({
          name: "검색",
          query: {
            marketId: state.marketId,
            title: state.marketName,
            productType: state.orderType,
            keyword: keyword,
            serviceQuickYn: state.activeTabItem == "quick" ? "Y" : "N",
          },
        });
      }
    }
  },
  navigateTo: function (state, data) {
    // console.log(data);

    if (data.path == "event-detail") {
      this._vm.$navigateTo("open-url", {
        title: data.params.title,
        url: data.params.url,
      });
    } else {
      if (data.path) {
        if (data.params) {
          this._vm.$navigateTo(data.path, data.params);
        } else {
          this._vm.$navigateTo(data.path);
        }
      }
    }
  },
  clickTab: function (state, data) {
    // console.log('clickTab :::::::::::::::::::');
    // console.log(data);
    const dataType = data.dataType;
    if (dataType == "favorite") {
      // console.log(state.serviceStoreList);
      // if (!state.serviceStoreList) {
      // if (!state.userId) {
      if (!localStorage.getItem("userId")) {
        const _this = this;
        const modalName = "callAuthentication";
        const compProps = {
          title: "휴대전화 본인인증",
          msgArr: ["휴대전화 본인 인증이 필요합니다."], // html 코드 적용 가능
          btnArr: [
            {
              txt: "본인 인증 하기",
              type: "type1 inverse",
              data: { id: "ok" },
              btnCallback: function () {
                _this._vm.$modal.hideAll(); // 레이어 닫기가 안되어 모든 레이어를 닫기 처리함
                _this.commit("marketMain/navigateTo", {
                  path: "authentication",
                });
              },
            },
          ],
          closeModal: function () {
            // X 버튼 클릭시
            // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
            _this._vm.$modal.hideAll();
          },
        };
        //
        this.dispatch(
          "showModal",
          {
            name: modalName,
            compProps: compProps,
            modalProps: {
              width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
              height: "auto",
            },
            // seconds: 3
          },
          { root: true },
        );

        return;
      }
    }

    state.tabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });

    // 활성화 탭 좌표가 화면에 가려질때 대응
    this.commit("marketMain/changeCategoryPosition", "mainTab");
    if (dataType == "product") {
      this.commit("marketMain/changeCategoryPosition", "contents");
    }
  },
  changeCategoryPosition: function (state, tabType) {
    // 활성화 탭 좌표가 화면에 가려질때 대응
    const windowWidth = window.innerWidth;
    // console.log("changeCategoryPosition ::::: ");
    // console.log(state[tabType]);
    var categoryListWrap = tabType == "contents" ? document.querySelector(".productTab") : state[tabType];

    if (!categoryListWrap) {
      // 방어코드
      const _this = this;
      setTimeout(function () {
        _this.commit("marketMain/changeCategoryPosition", "contents");
        // _this.dispatch("marketMain/handleScroll");
      }, 10);
    } else {
      setTimeout(function () {
        if (categoryListWrap.scrollLeft == 0) {
          // 백버튼으로 진입되었을때 방어코드
          categoryListWrap = tabType == "contents" ? document.querySelector(".productTab") : state[tabType];
        }
        const categoryList = categoryListWrap.querySelectorAll("li");
        const categoryListLength = categoryList.length;

        for (var i = 0; i < categoryListLength; i++) {
          if (categoryList[i].querySelector("a")) {
            // console.log(categoryList[i].querySelector('a'));
            if (categoryList[i].querySelector("a").classList.contains("active")) {
              // console.log(windowWidth);
              // console.log(categoryListWrap.scrollLeft);
              // console.log(categoryList[i].offsetLeft);
              // console.log(categoryList[i].offsetWidth);
              if (windowWidth < categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft) {
                categoryListWrap.scrollLeft =
                  categoryListWrap.scrollLeft +
                  (categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryListWrap.scrollLeft - windowWidth) +
                  16;
              } else if (categoryListWrap.scrollLeft > categoryList[i].offsetLeft + 16) {
                categoryListWrap.scrollLeft = categoryList[i].offsetLeft + categoryList[i].offsetWidth - categoryList[i].offsetWidth - 16;
              }
            }
          }
        }
      }, 10);
    }
  },
  setBannerActiveIndex: function (state, index) {
    // console.log('setSlideActiveIndex :::::::::::::: ' + index);
    state["bannerActiveIndex"] = index;
  },
  updateState: function (state, data) {
    if (data.append) {
      // 데이터를 추가할때 적용
      if (state[data.type]) {
        state[data.type] = state[data.type].concat(data.updateData);
      } else {
        state[data.type] = data.updateData;
      }
    } else {
      state[data.type] = data.updateData;
    }
  },
  initState: function (state, data) {
    // console.log(data);

    state["marketAddress"] = data.marketFullyAddress;
    state["marketDescription"] = data.marketIntro;
    state["orderType"] = data.orderType;
  },
  goBack: function (state) {
    // console.log('goBack ::::: ');
    if (state.activeTabItem == "main") {
      // router.back();
      // this.commit('marketMain/reset');
      this._vm.$navigateBack();
    } else {
      state.isProgressing = false;
      state.activeTabItem = "main";
      this.dispatch("marketMain/clickTab", { dataType: "main" });
      const tabWrapEl = document.querySelectorAll(".tab-wrap")[0];
      const tabEl = tabWrapEl.children[0].children[0];
      const tabBarEl = tabWrapEl.children[1];
      tabBarEl.style.setProperty("left", 15 + "px");
      tabBarEl.style.setProperty("width", tabEl.clientWidth + "px");
      tabWrapEl.scrollTo({ behavior: "smooth", left: 0 });
    }
  },
  reset: function (state) {
    const getInitState = () => {
      return {
        touch: {
          reloadStartY: null,
          reloadProcess: false,
        },
        userId: null,
        marketId: null,
        marketName: null,
        marketAddress: null,
        marketDescription: null,
        orderType: null,
        isProgressing: true,
        isFirstLoading: false,
        isReloading: false,
        isAxios: false,
        topFixed: false,
        tabFixed: false,
        topBanner: null,
        topBannerHeight: null,
        strapBanner: null,
        banners: null,
        bannerActiveIndex: 1,
        categoryList: null,
        hotSaleCategory: null,
        hotSaleActiveCategory: null,
        hotSaleCategoryProductList: null,
        newCategory: null,
        newActiveCategory: null,
        newCategoryProductList: null,
        quickStoreList: null,
        quickStoreActiveCategory: null,
        quickStoreCategory: null,
        quickStoreCategoryStore: null,
        keepProductActiveId: false,
        productActiveCategory: null,
        productCategoryProductList: null,
        currentPage: 0,
        totalPages: null,
        stores: null,
        regularList: null,
        hotSaleProductList: null,
        newProductList: null,
        recentProductList: null,
        serviceStoreList: null,
        products: null,
        storesFilter: null,
        activeTabItem: "main",
        mainTab: null,
        contents: null,
        tabItems: [
          {
            dataType: "main",
            text: "메인",
            isActive: true,
          },
        ],
        previousTabItems: [],
        recommendEvent: null,
        atEventCategoryList: null,
        atEventActiveCategory: null,
        atEventProducts: null,
        atEventCategoryProducts: null,
        atEventCurrentPage: 0,
        productLargeTab: {
          tabItems: [],
          tabActiveIndex: 0,
        },
        productMediumTab: {
          tabItems: [],
          tabActiveIndex: 0,
          isMediumTabVisible: false,
        },
      };
    };
    Object.assign(state, getInitState());
  },
  setRefTab: function (state, data) {
    if (data.mainTab) {
      state["mainTab"] = data.mainTab;
    }
    if (data.contents) {
      state["contents"] = data.contents;
    }
  },

  showPopup: function (state, data) {
    // console.log("showPopup");

    if (data.length == 0) {
      // 시장에 등록된 배너가 없음
      return;
    }

    localStorage.setItem("isMarketPopup_" + state.marketId, true);

    const _this = this;
    const modalName = "showPopup";
    const compProps = {
      type: "image",
      items: data,
      marketId: state.marketId,
      closeModal: function () {
        // X 버튼 클릭시
        const checkedMarket = document.querySelector("#closeTodayPopup:checked");
        if (checkedMarket) {
          localStorage.setItem(
            "checkedMarket_" + checkedMarket.dataset.marketId,
            new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate(),
          );
        }
        // _this._vm.$modal.hide(modalName);// 레이어 닫기가 안되어 아래 코드 모든 레이어를 닫기 처리함
        _this._vm.$modal.hideAll();
      },
    };

    this.dispatch(
      "showModal",
      {
        name: modalName,
        compProps: compProps,
        modalProps: {
          width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
          height: "auto",
        },
        // seconds: 3
      },
      { root: true },
    );
  },
};

const actions = {
  marketWrapTouchStart: function (store, event) {
    const html = document.querySelector("html");
    const htmlScrollTop = html.scrollTop;
    const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
    state.touch = {
      sx: null,
      sy: null,
      st: null,
      ex: null,
      ey: null,
      et: null,
      touchSensitive: 5,
      touchDirection: null,
      isIgnoreTouchX: null,
      reloadProcess: false,
    };
    const isTargetContainsAcceptTouchX = event.target.closest(".accept_touch_x");
    const isTargetContainsIgnoreTouchX = event.target.closest(".ignore_touch_x");
    state.touch.isIgnoreTouchX = !isTargetContainsAcceptTouchX && isTargetContainsIgnoreTouchX?.clientWidth === window.innerWidth;
    if (!state.isReloading) {
      html.style.setProperty("overflow-y", "scroll");
      reloadScrollWrapEl.scrollTo({ top: 120 });
      reloadScrollWrapEl.style.setProperty("display", "none");
      // 첫 터치 시작점 위치가 htmlScrollTop === 0 일 때만 새로고침 기능 사용가능
      state.touch.reloadProcess = htmlScrollTop === 0 && true;
    }
    store.commit("setTouch", { event: event, type: "start" });
  },
  marketWrapTouchMove: function (store, event) {
    store.commit("setTouch", { event: event, type: "move" });
    const dx = state.touch.sx - state.touch.ex;
    const dy = state.touch.ey - state.touch.sy;
    const vMove = Math.abs(dx) / Math.abs(dy) < 0.577; // 수직움직임 여부 tan30deg = 0.577...
    const hMove = Math.abs(dy) / Math.abs(dx) < 0.577; // 수평움직임 여부
    // 사용자의 화면 컨트롤 수직, 수평 여부
    if (state.touch.touchDirection === null && (Math.abs(dx) > state.touch.touchSensitive || Math.abs(dy) > state.touch.touchSensitive)) {
      if (vMove) {
        state.touch.touchDirection = "vertical";
      }
      if (hMove) {
        state.touch.touchDirection = "horizon";
      }
    }
    // 사용자가 화면을 수직 컨트롤 할 때
    if (state.touch.touchDirection === "vertical" && !state.isInitStateProgressing && state.touch.reloadProcess) {
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      const reloadIconEl = document.querySelector(".reload_icon");
      const html = document.querySelector("html");
      if (dy >= 0) {
        html.style.overflowY === "scroll" && html.style.setProperty("overflow-y", "hidden");
        reloadScrollWrapEl.style.display === "none" && reloadScrollWrapEl.style.setProperty("display", "inline-block");
        if (dy <= 118) {
          reloadScrollWrapEl.scrollTo({ top: 120 - dy });
          reloadIconEl.style.setProperty("transform", `rotate(${dy * 3}deg)`);
          if (dy <= 80) {
            (reloadIconEl.style.opacity === "1" || reloadIconEl.style.opacity === "") && reloadIconEl.style.setProperty("opacity", 0.3);
          } else {
            reloadIconEl.style.opacity === "0.3" && reloadIconEl.style.setProperty("opacity", 1);
          }
        } else {
          reloadScrollWrapEl.scrollTop !== 0 && reloadScrollWrapEl.scrollTo({ top: 2 });
        }
      } else {
        reloadScrollWrapEl.scrollTop !== 120 && reloadScrollWrapEl.scrollTo({ top: 120 });
        html.style.overflowY === "hidden" && html.style.setProperty("overflow-y", "scroll");
        reloadScrollWrapEl.style.display === "inline-block" && reloadScrollWrapEl.style.setProperty("display", "none");
        state.touch.reloadProcess = false;
      }
    }
  },
  marketWrapTouchEnd: function (store, event) {
    store.commit("setTouch", { event: event, type: "move" });
    // 사용자가 화면을 수직 컨트롤 할 때
    if (state.touch.touchDirection === "vertical" && !state.isInitStateProgressing && state.touch.reloadProcess) {
      const html = document.querySelector("html");
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      html.style.setProperty("overflow-y", "scroll");
      const dy = state.touch.ey - state.touch.sy;
      const isReloading = dy > 80;
      if (isReloading) {
        reloadScrollWrapEl.scrollTo({ top: 40, behavior: "smooth" });
        const isReload = true;
        store.dispatch("initState", { isReload });
      } else {
        reloadScrollWrapEl.scrollTo({ top: 120, behavior: "smooth" });
      }
    }
    // 사용자가 화면을 수평 컨트롤 할 때
    if (state.touch.touchDirection === "horizon" && !state.isInitStateProgressing && !state.touch.isIgnoreTouchX) {
      const dx = state.touch.ex - state.touch.sx;
      const dt = state.touch.et - state.touch.st;
      if (dt < 300 && Math.abs(dx) > 30) {
        const dx = state.touch.sx - state.touch.ex;
        const xSign = Math.sign(dx); // x 부호
        const activePageIndex = state.tabItems.findIndex((item) => {
          return item.dataType === state.activeTabItem;
        });
        let switchPageIndex = activePageIndex + xSign;
        const minPageIndex = 0;
        const maxPageIndex = state.tabItems.length - 1;
        switchPageIndex = switchPageIndex < minPageIndex ? maxPageIndex : switchPageIndex > maxPageIndex ? minPageIndex : switchPageIndex;
        const switchPageDataType = state.tabItems[switchPageIndex].dataType;
        activePageIndex !== switchPageIndex && store.dispatch("clickTab", { dataType: switchPageDataType });
      }
    }
    state.touch = {
      sx: null,
      sy: null,
      st: null,
      ex: null,
      ey: null,
      et: null,
      touchSensitive: 5,
      touchDirection: null,
      isIgnoreTouchX: null,
      reloadProcess: false,
    };
  },
  onBackPressedEvent: function (store, goBack) {
    if (state.activeTabItem === "product") {
      if (state.productLargeTab.tabActiveIndex === 0) {
        goBack();
      } else {
        if (state.productMediumTab.isMediumTabVisible) {
          store.dispatch("clickProductMediumTabBackArrow");
        } else {
          store.dispatch("switchProductLargeTabActiveIndex", 0);
        }
      }
    } else {
      goBack();
    }
  },
  // 시장_전국상품(탭)_대분류(탭) 불러오기
  setProductLargeTab: async function () {
    try {
      const productLargeTabUrl = `/v3/market/delivery/category/market/categories/sale?marketId=${state.marketId}`;
      const response = await this._vm.$axios1.get(productLargeTabUrl);
      if (response.data.responseCode === 2000) {
        const result = response.data.result.categoryList;
        const resultData = result.map((categoryInfo) => {
          const { categoryId, categoryName } = categoryInfo;
          return {
            categoryId,
            categoryName,
          };
        });
        state.productLargeTab.tabItems = resultData;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  // 내가본상품 불러오기
  setRecentProductList: async function (store) {
    const productRecent = localStorage.getItem("product_recent_" + state.marketId);
    if (productRecent) {
      const productRecentList = JSON.parse(productRecent).map((item) => item.productId);
      // console.log('productRecentList ::: ' + productRecentList.join());
      const productRecentListArr = [];
      JSON.parse(productRecent)
        .reverse()
        .forEach((item, index) => {
          if (index < 5) {
            productRecentListArr.push(item.productId);
          }
        });

      if (productRecentList.length > 0) {
        try {
          const productRecentUrl =
            "/v3/market/delivery/product/products/information?productIds=" + productRecentListArr.join() + "&userId=" + state.userId;
          const response = await this._vm.$axios1.get(productRecentUrl);
          if (response.data.responseCode == 2000) {
            const recentProductList = response.data.result.productDataList;
            const sendData = {
              type: "recentProductList",
              updateData: recentProductList,
              // updateData: recentProductList.reverse(),
            };
            store.commit("updateState", sendData);
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        } catch (err) {
          return false;
        }
      }
    }
  },
  // 배너목록 불러오기
  setBanner: async function (store) {
    try {
      const bannerUrl = "/v3/notices/member-notices/markets/" + state.marketId + "?size=30&userId=" + state.userId; // 시장배너
      const response = await this._vm.$axios2.get(bannerUrl);
      if (response.data.responseCode == 2000) {
        const result = response.data.result;
        if (result.billboardBanners) {
          const sendData = {
            type: "banners",
            updateData: result.billboardBanners,
          };
          store.commit("updateState", sendData);
        }

        if (result.marketTopBannerImg) {
          const topBannerData = {
            type: "topBanner",
            updateData: {
              bannerImage: result.marketTopBannerImg,
              detailUrl: result.marketTopBannerDetailUrl,
              noticeId: result.marketTopBannerNoticeId,
              title: result.marketTopBannerTitle,
            },
          };
          store.commit("updateState", topBannerData);
        } else {
          // 탑배너가 없을때 고정으로 fixed 를 적용한다.
          store.commit("updateState", { type: "topFixed", updateData: true });
        }

        if (result.marketStrapBannerImg) {
          const strapData = {
            type: "strapBanner",
            updateData: {
              bannerImage: result.marketStrapBannerImg,
              detailUrl: result.marketStrapBannerDetailUrl,
              noticeId: result.marketStrapBannerNoticeId,
              title: result.marketStrapBannerTitle,
            },
          };
          store.commit("updateState", strapData);
        }

        if (result.recommendEventResponse) {
          const recommendEventData = {
            type: "recommendEvent",
            updateData: result.recommendEventResponse,
          };
          store.commit("updateState", recommendEventData);
        }
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 시장정보 불러오기
  setMarketInfo: async function (store) {
    try {
      const marketInfoUrl = "/v3/market/user/marketId/" + state.marketId + "/information"; // 시장정보
      const response = await this._vm.$axios1.get(marketInfoUrl);
      if (response.data.responseCode == 2000) {
        store.commit("initState", response.data.result);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
    const userAddress = localStorage.getItem("userAddress");
    const latitude = userAddress ? JSON.parse(userAddress)?.latitude : 0;
    const longitude = userAddress ? JSON.parse(userAddress)?.longitude : 0;

    const marketOpenInfoUrl = `v3/market/user/marketId/${state.marketId}/latitude/${latitude}/longitude/${longitude}/information`;
    try {
      const response = await this._vm.$axios1.get(marketOpenInfoUrl);
      if (response.data.responseCode == 2000) {
        const orderInfo = {
          type: "orderInfo",
          updateData: response.data.result.orderInfo,
        };
        const marketDeliveryYn = {
          type: "marketDeliveryYn",
          updateData: response.data.result.marketDeliveryYn,
        };
        store.commit("updateState", orderInfo);
        store.commit("updateState", marketDeliveryYn);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 시장 카테고리 불러오기
  setMarketCategory: async function (store) {
    try {
      const marketCategoryUrl = "/v3/market/delivery/category/market/" + state.marketId + "/categories/sale"; // 시장 카테고리
      const response = await this._vm.$axios1.get(marketCategoryUrl);
      if (response.data.responseCode == 2000) {
        const categoryList = response.data.result.categoryList.map((item, index) => {
          return {
            dataType: item.categoryId,
            text: item.categoryName,
            isActive: index == 0 ? true : false,
            categoryImageUrl: item.categoryImageUrl,
            isCompleteLoading: false,
          };
        });
        const sendData = {
          type: "categoryList",
          updateData: categoryList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // AT 카테고리 불러오기
  setAtCasetAtCategorytegory: async function (store) {
    const isSssMarket = store.rootState.sssMarkets.indexOf(Number(state.marketId)) > -1 ? true : false;

    if (!isSssMarket && state.marketId != "169") {
      // 서울시특판 시장이 아닐때 농할 카테고리를 조회한다. // TODO 절임 배추 건으로 춘천중앙시장은 농할 이벤트 메뉴 노출하지 않음, 추후 원복
      try {
        const atCategoryUrl = "/v3/market/delivery/category/market/" + state.marketId + "/categories/" + state.orderType.toLowerCase() + "/atEvent";
        const response = await this._vm.$axios1.get(atCategoryUrl);
        // setAtCategory

        if (response.data.responseCode == 2000) {
          if (response.data.result.categoryList.length > 0) {
            const previousActiveIndex =
              state.atEventCategoryList?.findIndex((item) => {
                return item.isActive;
              }) || 0;
            const atEventCategoryList = response.data.result.categoryList.map((item, index) => {
              return {
                dataType: item.categoryId,
                text: item.categoryName,
                isActive: index === previousActiveIndex ? true : false,
                isCompleteLoading: false,
              };
            });

            const sendData = {
              type: "atEventCategoryList",
              updateData: atEventCategoryList,
            };
            store.commit("updateState", sendData);

            const atActiveCategoryId = atEventCategoryList[0].dataType;
            const atEventActiveData = {
              type: "atEventActiveCategory",
              updateData: atActiveCategoryId,
            };
            store.commit("updateState", atEventActiveData);

            const atEventProductsUrl =
              "/v3/market/delivery/product/market/" +
              state.marketId +
              "/category/" +
              atActiveCategoryId +
              "/user/" +
              state.userId +
              "/products/" +
              state.orderType.toLowerCase() +
              "/atEvent?size=20&page=0";

            const responseData = await this._vm.$axios1.get(atEventProductsUrl);
            try {
              if (responseData.data.responseCode == 2000) {
                const atEventProducts = responseData.data.result.productList;

                if (!atEventProducts || atEventProducts.length == 0) {
                  // 목록이 없으면 탭메뉴 삭제
                  state.tabItems = state.tabItems.filter((item) => item.dataType != "at");
                } else if (atEventProducts.length > 0) {
                  if (process.env.VUE_APP_DISCOUNT_TYPE != "NONE") {
                    let text = "농할이벤트";

                    if (process.env.VUE_APP_DISCOUNT_TYPE === "SEOUL") {
                      text = "서울시특판";
                    }

                    state.tabItems.push({
                      dataType: "at",
                      text: text,
                      isActive: false,
                    });
                  }
                }
                if (responseData.data.result.productList.length > 0) {
                  const atEventProductsData = {
                    type: "atEventProducts",
                    updateData: atEventProducts,
                  };
                  store.commit("updateState", atEventProductsData);
                }
              } else {
                alert(responseData.data.errorObject.errorMessage);
                return false;
              }
            } catch (err) {
              return false;
            }
          }
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      } catch (err) {
        return false;
      }
    }
  },
  // 핫세일 불러오기
  setHotSaleProductList: async function (store) {
    try {
      const hotSaleUrl = "/v3/event/hot-sale/market/" + state.marketId + "/categories/0/user/" + state.userId + "/products/bd?size=30&page=0"; // 핫세일
      const response = await this._vm.$axios1.get(hotSaleUrl);
      if (response.data.responseCode == 2000) {
        const hotSaleProductList = response.data.result.hotSaleProductList;
        if (!hotSaleProductList || hotSaleProductList.length == 0) {
          // 목록이 없으면 탭메뉴 삭제
          state.tabItems = state.tabItems.filter((item) => item.dataType != "hot");
        } else if (hotSaleProductList.length > 0) {
          state.tabItems.push({
            dataType: "hot",
            text: "핫세일",
            isActive: false,
          });
        }
        const sendData = {
          type: "hotSaleProductList",
          updateData: hotSaleProductList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 새로 들어왔어요 불러오기
  setNewProductList: async function (store) {
    try {
      const newProductUrl =
        "/v3/event/new-merchandise/market/" + state.marketId + "/categories/0/user/" + state.userId + "/products/bd?size=30&page=0"; // 오늘 들어왔어요
      const response = await this._vm.$axios1.get(newProductUrl);
      if (response.data.responseCode == 2000) {
        const newProductList = response.data.result.newMerchandiseProductList;
        if (!newProductList || newProductList.length == 0) {
          // 목록이 없으면 탭메뉴 삭제
          state.tabItems = state.tabItems.filter((item) => item.dataType != "new");
        } else if (newProductList.length > 0) {
          state.tabItems.push({
            dataType: "new",
            text: "새로 들어왔어요",
            isActive: false,
          });
        }
        const sendData = {
          type: "newProductList",
          updateData: newProductList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 음식배달 불러오기
  setQuickStoreList: async function (store) {
    try {
      const quickStoreUrl = "/v3/market/delivery/store/markets/" + state.marketId + "/stores?userId=" + state.userId; // 음식배달 상점 목록
      const response = await this._vm.$axios1.get(quickStoreUrl);
      if (response.data.responseCode == 2000) {
        const quickStoreList = response.data.result.storeList;
        if (!quickStoreList || quickStoreList.length == 0) {
          // 목록이 없으면 탭메뉴 삭제
          state.tabItems = state.tabItems.filter((item) => item.dataType != "quick");
        } else if (quickStoreList.length > 0) {
          state.tabItems.push({
            dataType: "quick",
            text: "음식배달",
            isActive: false,
          });
        }
        const sendData = {
          type: "quickStoreList",
          updateData: quickStoreList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 시장 기준 음식카테고리 불러오기
  setQuickStoreCategory: async function (store) {
    try {
      const quickStoreCategoryUrl = "/v3/market/delivery/category/market/" + state.marketId + "/categories/fd"; // 시장기준 음식배달 카테고리
      const response = await this._vm.$axios1.get(quickStoreCategoryUrl);
      if (response.data.responseCode == 2000) {
        const previousActiveIndex =
          state.quickStoreCategory?.findIndex((item) => {
            return item.isActive;
          }) || 0;
        const categoryList = response.data.result.categoryList.map((item, index) => {
          return {
            dataType: item.categoryId,
            text: item.categoryName,
            isActive: index === previousActiveIndex ? true : false,
          };
        });
        if (categoryList.length > 0) {
          const sendData = {
            type: "quickStoreCategory",
            updateData: categoryList,
          };
          store.commit("updateState", sendData);
        }
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 단골상점 랜덤 불러오기
  setServiceStoreList: async function (store) {
    // if (userId) {
    try {
      const favoritesStoreUrl = "/v3/user/store/userId/" + state.userId + "/stores/regular/" + state.marketId + "/sort/RANDOM"; // 단골상점 랜덤
      const response = await this._vm.$axios1.get(favoritesStoreUrl);
      if (response.data.responseCode == 2000) {
        const sendData = {
          type: "serviceStoreList",
          updateData: response.data.result.serviceStoreList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 단골상점 최신순 불러오기
  setRegularList: async function (store) {
    // if (state.userId) {
    try {
      const regularUrl = "/v3/user/store/userId/" + state.userId + "/stores/regular/" + state.marketId + "/sort/NEW"; // 단골상점 최신순
      const response = await this._vm.$axios1.get(regularUrl);
      if (response.data.responseCode == 2000) {
        const sendData = {
          type: "regularList",
          updateData: response.data.result.serviceStoreList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 전체상품 불러오기
  setProducts: async function (store) {
    try {
      const productUrl =
        "/v3/market/delivery/product/market/" + state.marketId + "/user/" + state.userId + "/products/" + state.orderType.toLowerCase() + "?size=10";
      const response = await this._vm.$axios1.get(productUrl);
      if (response.data.responseCode == 2000) {
        const productList = response.data.result.productList;
        if (!productList || productList.length == 0) {
          // 목록이 없으면 탭메뉴 삭제
          state.tabItems = state.tabItems.filter((item) => item.dataType != "product");
        } else if (productList.length > 0) {
          state.tabItems.push({
            dataType: "product",
            text: "전체상품",
            isActive: false,
          });
        }
        const sendData = {
          type: "products",
          updateData: productList,
        };
        store.commit("updateState", sendData);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 전체상점 불러오기
  setStores: async function (store) {
    try {
      const storeUrl = "/v3/market/delivery/store/" + state.marketId + "/stores?size=30&userId=" + state.userId; // 전체상점
      const response = await this._vm.$axios1.get(storeUrl);
      if (response.data.responseCode == 2000) {
        // const stores = response.data.result.storeList.filter((store, index) => index < 6);
        const stores = response.data.result.storeList;
        if (!stores || stores.length == 0) {
          // 목록이 없으면 탭메뉴 삭제
          state.tabItems = state.tabItems.filter((item) => item.dataType != "stores");
        } else if (stores.length > 0) {
          state.tabItems.push({
            dataType: "stores",
            text: "전체상점",
            isActive: false,
          });
        }
        const sendData = {
          type: "stores",
          updateData: stores,
        };
        store.commit("updateState", sendData);
        state.isProgressing = false;
      } else {
        state.isProgressing = false;
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  // 팝업 배너 불러오기
  setPopup: async function (store) {
    try {
      const bannerImageUrl = "/v3/notices/member-notices/markets/" + state.marketId + "/popups?size=30&userId=" + state.userId;
      const response = await this._vm.$axios2.get(bannerImageUrl);
      if (response.data.responseCode == 2000) {
        store.commit("showPopup", response.data.result.notices);
      } else {
        alert(response.data.errorObject.errorMessage);
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  switchProductLargeTabActiveIndex: function (store, index) {
    if (typeof index === "number") {
      const categoryId = state.productLargeTab.tabItems[index].categoryId;
      if (index === 0) {
        if (state.productLargeTab.tabActiveIndex !== index) {
          store.dispatch("clickCategoryTab", { categoryId });
        }
      } else {
        if (state.productLargeTab.tabActiveIndex === index) {
          store.dispatch("setProductMediumTab", index);
          state.productMediumTab.isMediumTabVisible = true;
        } else {
          setTimeout(() => {
            store.dispatch("setProductMediumTab", index);
            state.productMediumTab.isMediumTabVisible = true;
            store.dispatch("clickCategoryTab", { categoryId });
          }, 300);
        }
      }
      state.productLargeTab.tabActiveIndex = index;
    } else {
      const categoryId = state.productLargeTab.tabItems[index.index].categoryId;
      if (index.index !== 0) {
        store.dispatch("setProductMediumTab", index.index);
        state.productMediumTab.isMediumTabVisible = true;
        store.dispatch("clickCategoryTab", { categoryId });
      }
      state.productLargeTab.tabActiveIndex = index.index;
    }
  },
  setProductMediumTab: async function (store, index) {
    try {
      const mainCategoryId = state.productLargeTab.tabItems[index].categoryId;
      const productMediumTabUrl = `/v3/market/delivery/category/market/categories/sale?mainCategoryId=${mainCategoryId}&marketId=${state.marketId}`;
      const response = await this._vm.$axios1.get(productMediumTabUrl);
      if (response.data.responseCode === 2000) {
        const title = state.productLargeTab.tabItems[index].categoryName;
        const result = response.data.result.categoryList;

        // 카테고리명 전체, 카테고리명 - 중복 탭 메뉴 예외처리
        if (result.length) {
          if (result.length === 2 && result[0].subCategoryName === "전체") {
            result.pop();
          }
        }

        const resultDate = result.map((categoryInfo) => {
          let { categoryId, subCategoryId, subCategoryName } = categoryInfo;
          subCategoryName = subCategoryName === "전체" ? title + " " + subCategoryName : subCategoryName;
          return {
            categoryId,
            subCategoryId,
            subCategoryName,
          };
        });
        state.productMediumTab.tabItems = resultDate;
      } else {
        alert(response.data.errorObject.errorMessage);
      }
    } catch (err) {
      return false;
    }
  },
  switchProductMediumTabActiveIndex: function (store, index) {
    if (state.productMediumTab.tabActiveIndex !== index) {
      state.productMediumTab.tabActiveIndex = index;
      const categoryId = state.productLargeTab.tabItems[state.productLargeTab.tabActiveIndex].categoryId;
      store.dispatch("clickCategoryTab", { categoryId });
    }
  },
  clickProductMediumTabBackArrow: function (store) {
    const index = state.productLargeTab.tabActiveIndex;
    const categoryId = state.productLargeTab.tabItems[index].categoryId;
    if (state.productMediumTab.tabActiveIndex === 0) {
      state.productLargeTab.tabActiveIndex = 0;
      state.productMediumTab.isMediumTabVisible = false;
      store.dispatch("clickCategoryTab", { categoryId });
    } else {
      state.productMediumTab.tabActiveIndex = 0;
      setTimeout(() => {
        state.productMediumTab.isMediumTabVisible = false;
        store.dispatch("clickCategoryTab", { categoryId });
      }, 300);
    }
  },
  loadTopBanner: function (store) {
    store.commit("updateState", {
      type: "topBannerHeight",
      updateData: document.querySelector("#topBanner").offsetHeight,
    });
  },
  handleScroll: function (store) {
    if (state.isProgressing) {
      return;
    }

    const eventTarget = document.querySelector("html");
    if (state.activeTabItem == "product" || state.activeTabItem == "at") {
      if (!state.isAxios) {
        if (eventTarget.scrollTop + eventTarget.offsetHeight > eventTarget.scrollHeight - 150) {
          state.isAxios = true;
          this.dispatch("marketMain/getProductList", state.activeTabItem);
        }
      }
    }

    // console.log("handleScroll");
    const htmlSelector = document.querySelector("html");
    // console.log(htmlSelector.scrollTop);

    // if (state.topBannerHeight) {
    // console.log("state.topBannerHeight ::: " + state.topBannerHeight);
    if (state.topBannerHeight <= htmlSelector.scrollTop) {
      if (!state.topFixed) {
        store.commit("updateState", { type: "topFixed", updateData: true });
      }

      if (state.topBannerHeight + 55 <= htmlSelector.scrollTop) {
        store.commit("updateState", { type: "tabFixed", updateData: true });
      } else {
        store.commit("updateState", { type: "tabFixed", updateData: false });
      }
    } else {
      store.commit("updateState", { type: "tabFixed", updateData: false });
      if (state.topFixed) {
        store.commit("updateState", { type: "topFixed", updateData: false });
      }
    }
  },
  getProductList: async function (store, productType) {
    // 해당 카테고리의 상품 목록 호출
    const marketId = state.marketId;
    const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;

    state.isProgressing = true;

    var isTotalPage = false;
    if (state.totalPages) {
      if (state.totalPages >= state.currentPage + 1) {
        isTotalPage = true;
      }
    } else {
      isTotalPage = true;
    }

    // if (isTotalPage) {

    if (productType === "hot-sale" || productType === "new-merchandise") {
      const categoryId = productType === "hot-sale" ? state.hotSaleActiveCategory : state.newActiveCategory;
      await this._vm.$axios1
        .get("/v3/event/" + productType + "/market/" + marketId + "/categories/" + categoryId + "/user/" + userId + "/products/bd")
        .then((response) => {
          if (response.data.responseCode == 2000) {
            const sendData = {
              type: productType == "hot-sale" ? "hotSaleCategoryProductList" : "newCategoryProductList",
              updateData: productType == "hot-sale" ? response.data.result.hotSaleProductList : response.data.result.newMerchandiseProductList,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;

            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    } else if (productType === "at") {
      var isAtEventCompleteLoading = false;
      const categoryId2 = productType === "hot-sale" ? state.hotSaleActiveCategory : state.atEventActiveCategory;
      state.atEventCategoryList.forEach((category) => {
        if (category.dataType === categoryId2) {
          isAtEventCompleteLoading = category.isCompleteLoading;
        }
      });

      if (isAtEventCompleteLoading) {
        // 카테고리의 전체 페이지를 확인할수 없어 해당 값으로 분기 처리한다.
        return;
      }

      const categoryId = state.atEventActiveCategory;
      const atEventProductsUrl =
        "/v3/market/delivery/product/market/" +
        marketId +
        "/category/" +
        categoryId +
        "/user/" +
        userId +
        "/products/" +
        state.orderType.toLowerCase() +
        "/atEvent" +
        "?size=20&page=" +
        state.atEventCurrentPage;
      await this._vm.$axios1
        .get(atEventProductsUrl)
        .then((response) => {
          // console.log("state.currentPage ::: " + state.currentPage);
          if (response.data.responseCode == 2000) {
            const atEventProducts = response.data.result.productList;

            if (state.atEventCurrentPage == 0) {
              state.atEventCategoryList.forEach((category) => {
                category.isCompleteLoading = false;
              });
            }

            if (atEventProducts.length == 0) {
              state.atEventCategoryList.forEach((category) => {
                if (category.dataType == categoryId) {
                  category.isCompleteLoading = true;
                }
              });
              return;
            }

            const sendData = {
              // type: "atEventProducts",
              type: "atEventCategoryProducts",
              updateData: atEventProducts,
              append: true,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            state.isAxios = false;

            state.atEventCurrentPage++;

            if (state.atEventCurrentPage == 1) {
              this.dispatch("marketMain/handleScroll");
              if (state.tabFixed) {
                document.querySelector("html").scrollTop = state.topBannerHeight + 56;
              }
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    } else if (productType == "quick") {
      // console.log("quickStoreActiveCategory ::: ");
      const categoryId = state.quickStoreActiveCategory;
      var axiosQuickUrl = "/v3/market/delivery/store/market/" + marketId + "/category/" + categoryId + "/user/" + userId + "/store";
      await this._vm.$axios1
        .get(axiosQuickUrl)
        .then((response) => {
          // console.log("state.currentPage ::: " + state.currentPage);

          if (response.data.responseCode == 2000) {
            const sendData = {
              type: "quickStoreList",
              updateData: response.data.result.storeList,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            state.isAxios = false;

            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }
    //
    else if (productType == "product") {
      const categoryId = state.productActiveCategory;

      var isCompleteLoading = false;
      state.categoryList.forEach((category) => {
        if (category.dataType == categoryId) {
          isCompleteLoading = category.isCompleteLoading;
        }
      });

      if (isCompleteLoading) {
        // 카테고리의 전체 페이지를 확인할수 없어 해당 값으로 분기 처리한다.
        return;
      }
      const largeIndex = state.productLargeTab.tabActiveIndex;
      const mediumIndex = state.productMediumTab.tabActiveIndex;
      const mainCategoryId = state.productLargeTab.tabItems[largeIndex].categoryId;
      const subCategoryId = state.productMediumTab.tabItems[mediumIndex]?.subCategoryId || 0;
      const page = state.currentPage;
      const axiosUrl = `/v3/market/delivery/product/market/${marketId}/categories/${mainCategoryId}/${subCategoryId}/users/${userId}/products?size=30&page=${page}`;
      console.log(axiosUrl);

      await this._vm.$axios1
        .get(axiosUrl)
        .then((response) => {
          if (response.data.responseCode == 2000) {
            if (state.currentPage == 0) {
              state.categoryList.forEach((category) => {
                category.isCompleteLoading = false;
              });
            }

            // console.log("response.data.result.productList.length ::: " + response.data.result.productList.length);
            if (response.data.result.productList.length == 0) {
              state.categoryList.forEach((category) => {
                if (category.dataType == categoryId) {
                  category.isCompleteLoading = true;
                }
              });
              return;
            }

            state.currentPage++;
            state.totalPages = response.data.result.totalPages;

            const sendData = {
              type: "productCategoryProductList",
              updateData: response.data.result.productList,
              append: true,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            state.isAxios = false;
            if (state.currentPage == 1) {
              this.dispatch("marketMain/handleScroll");
              if (state.tabFixed) {
                document.querySelector("html").scrollTop = state.topBannerHeight + 56;
              }
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }

    // }
    // else {
    state.isProgressing = false;
    // }
  },
  clickCategoryTab: async function (store, data) {
    // 서브 카테고리 클릭

    /*if (state.topBannerHeight + 50 <= document.querySelector("html").scrollTop) {
      store.commit("updateState", { type: "tabFixed", updateData: true });
      store.commit("updateState", { type: "topFixed", updateData: true });
      document.querySelector("html").scrollTop = state.topBannerHeight + 56;
    }*/

    // console.log('clickCategoryTab ::: ');
    // console.log('activeTabItem ::: ' + state.activeTabItem);

    state.currentPage = 0;
    state.atEventCurrentPage = 0;

    const activeTabItem = state.activeTabItem;
    const categoryId = data.categoryId;

    var stateCategory = null;
    var stateActiveCategory = null;
    var productListType = null;
    if (activeTabItem == "at") {
      stateCategory = "atEventCategoryList";
      stateActiveCategory = "atEventActiveCategory";
      productListType = "at";
    } else if (activeTabItem == "hot") {
      stateCategory = "hotSaleCategory";
      stateActiveCategory = "hotSaleActiveCategory";
      productListType = "hot-sale";
    } else if (activeTabItem == "new") {
      stateCategory = "newCategory";
      stateActiveCategory = "newActiveCategory";
      productListType = "new-merchandise";
    } else if (activeTabItem == "product") {
      stateCategory = "categoryList";
      stateActiveCategory = "productActiveCategory";
      productListType = "product";
    } else if (activeTabItem == "quick") {
      stateCategory = "quickStoreCategory";
      stateActiveCategory = "quickStoreActiveCategory";
      productListType = "quick"; // 변경될수 있음
    }

    state[stateCategory] = state[stateCategory].map((item) => {
      item.isCompleteLoading = false;
      if (item.dataType == categoryId) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });

    if (activeTabItem == "product") {
      state.productCategoryProductList = null;
      store.commit("changeCategoryPosition", "contents");
    } else if (activeTabItem == "at") {
      state.atEventCategoryProducts = null;
      store.commit("changeCategoryPosition", "contents");
    }

    state[stateActiveCategory] = categoryId;

    await store.dispatch("getProductList", productListType);
  },
  moveToMore: function (store, data) {
    // console.log('moveToMore :::::::::::::::::::');
    // console.log(data);
    this.dispatch("marketMain/clickTab", { dataType: data.type });
  },
  clickTab: async function (store, data) {
    const dataType = data.dataType;
    const marketId = state.marketId;
    if (state.activeTabItem === "at") {
      state.atEventCategoryList = state.atEventCategoryList.map((item, idx) => {
        return {
          ...item,
          isActive: idx === 0 ? true : false,
        };
      });
    } else if (state.activeTabItem === "hot") {
      state.hotSaleCategory = state.hotSaleCategory.map((item, idx) => {
        return {
          ...item,
          isActive: idx === 0 ? true : false,
        };
      });
    } else if (state.activeTabItem === "new") {
      state.newCategory = state.newCategory.map((item, idx) => {
        return {
          ...item,
          isActive: idx === 0 ? true : false,
        };
      });
    }
    state.activeTabItem = dataType;
    !state.isReloading && store.commit("setTabBarOptions", data.dataType);
    const tabWrapEl = document.querySelectorAll(".tab-wrap")[1];
    tabWrapEl?.scrollTo({ behavior: "smooth", left: 0 });
    if (dataType === "main") {
      state.productMediumTab.tabItems = [];
      state.productMediumTab.tabActiveIndex = 0;
      state.productMediumTab.isMediumTabVisible = false;
    }
    // console.log(document.querySelector("html").scrollTop);
    // console.log(state.topBannerHeight);

    // console.log(document.querySelector("#marketMainWrap").classList.contains("fixed_tab"));

    if (dataType == "hot" || dataType == "new") {
      var stateCategory = null;
      var stateActiveCategory = null;
      var productListType = null;

      if (dataType == "hot") {
        stateCategory = "hotSaleCategory";
        stateActiveCategory = "hotSaleActiveCategory";
        productListType = "hot-sale";
      } else if (dataType == "new") {
        stateCategory = "newCategory";
        stateActiveCategory = "newActiveCategory";
        productListType = "new-merchandise";
      }

      // if (!state[stateCategory]) {// 삭제금지
      state.isProgressing = true;
      state.hotSaleCategoryProductList = null;
      state.newCategoryProductList = null;
      await this._vm.$axios1
        .get("/v3/market/delivery/category/market/" + marketId + "/categories/" + productListType) // 카테고리
        .then((response) => {
          if (response.data.responseCode == 2000) {
            // console.log(response.data.result.categoryList);
            const categoryList = response.data.result.categoryList.map((item, index) => {
              return {
                dataType: item.categoryId,
                text: item.categoryName,
                isActive: index == 0 ? true : false,
              };
            });
            state[stateActiveCategory] = categoryList[0].dataType; // 초기 활성화 될 카테고리 아이디 정의
            const sendData = {
              type: stateCategory,
              updateData: categoryList,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            // if (!state[(stateCategory + 'ProductList')]) {// 삭제금지
            this.dispatch("marketMain/getProductList", productListType);
            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
            // }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
      // }
    } else if (dataType == "at") {
      state.isProgressing = true;
      state.atEventCurrentPage = 0;
      state.atEventCategoryProducts = null;

      await this._vm.$axios1
        .get("/v3/market/delivery/category/market/" + marketId + "/categories/" + state.orderType.toLowerCase() + "/atEvent")
        .then((response) => {
          if (response.data.responseCode == 2000) {
            // console.log(response.data.result.categoryList);
            const categoryList = response.data.result.categoryList.map((item, index) => {
              return {
                dataType: item.categoryId,
                text: item.categoryName,
                isActive: index == 0 ? true : false,
              };
            });
            state.atEventActiveCategory = categoryList[0].dataType; // 초기 활성화 될 카테고리 아이디 정의
            const sendData = {
              type: "atEventCategoryList",
              updateData: categoryList,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            this.dispatch("marketMain/getProductList", "at");
            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    } else if (dataType == "product") {
      if (!state.keepProductActiveId || state.isReloading) {
        var activeIndex = 0;
        // 단골상점 목록은 전체상품 카테고리에서는 노출 시키지 않게 처리하기 위한 조건
        if (state.userId && state.regularList && state.regularList.length > 0) {
          activeIndex = 1;
        }
        state["categoryList"] = state["categoryList"].map((item, index) => {
          if (index == activeIndex) {
            item.isActive = true;
          } else {
            item.isActive = false;
          }
          return item;
        });

        state.currentPage = 0;
        state.productCategoryProductList = null;

        state["productActiveCategory"] = state["categoryList"][activeIndex].dataType;
        state.productLargeTab.tabActiveIndex = 0;
        state.productMediumTab.tabActiveIndex = 0;
        state.productMediumTab.isMediumTabVisible = false;
        if (data.categoryId) {
          state["productActiveCategory"] = data.categoryId;
          const index = state.productLargeTab.tabItems.findIndex((item) => {
            return item.categoryId === data.categoryId;
          });
          state.productLargeTab.tabActiveIndex = index;
          store.dispatch("setProductMediumTab", index);
          state.productMediumTab.isMediumTabVisible = true;
          store.dispatch("clickCategoryTab", { categoryId: data.categoryId });
        } else {
          state["productActiveCategory"] = "0";
          this.dispatch("marketMain/getProductList", dataType);
        }
        // state.keepProductActiveId = false;
        this.dispatch("marketMain/handleScroll");
        if (state.tabFixed) {
          document.querySelector("html").scrollTop = state.topBannerHeight + 56;
        }
      } else {
        // state.currentPage = 0;
        // state.productCategoryProductList = null;
        state.keepProductActiveId = false;
      }
    } else if (dataType == "stores" || dataType == "store") {
      const sendData = {
        type: "stores",
        updateData: [],
      };
      store.commit("updateState", sendData);

      // console.log("dataType ::: " + dataType);
      const storeUrl = "/v3/market/delivery/store/" + marketId + "/stores?size=30&userId=" + state.userId;
      state.isProgressing = true;
      await this._vm.$axios1
        .get(storeUrl) // 전체상점
        .then((response) => {
          if (response.data.responseCode == 2000) {
            // const stores = response.data.result.storeList.filter((store, index) => index < 6);
            const stores = response.data.result.storeList;
            const sendData = {
              type: "stores",
              updateData: stores,
            };
            // console.log(stores);
            store.commit("updateState", sendData);
            state.isProgressing = false;
            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            state.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          state.isProgressing = false;
          return false;
        });

      // /v3/market/delivery/store/2/stores?size=30&userId=efe7f717-fdae-4ab1-a08f-d7c6e8803c9c
      // state['categoryList'] = state['categoryList'].map((item, index) => {
      //     if (index == 0) {
      //         item.isActive = true;
      //     }
      //     else {
      //         item.isActive = false;
      //     }
      //     return item;
      // });
      // state['productActiveCategory'] = state['categoryList'][0].dataType;
      // this.dispatch('marketMain/getProductList', dataType);
    } else if (dataType == "favorite") {
      // const favoritesStoreUrl = '/v3/user/store/userId/' + state.userId + '/stores/regular/' + marketId;
      if (!state.userId) {
        state.userId = localStorage.getItem("userId");
      }
      if (!state.userId) {
        // console.log("userId 없음");
        // console.log("userId 없음");
        return;
      }
      const favoritesStoreUrl = "/v3/user/store/userId/" + state.userId + "/stores/regular/" + marketId + "/sort/NEW";

      state.isProgressing = true;
      state.regularList = [];
      await this._vm.$axios1
        .get(favoritesStoreUrl) // 단골상점
        .then((response) => {
          state.isProgressing = false;
          if (response.data.responseCode == 2000) {
            const sendData = {
              type: "regularList",
              updateData: response.data.result.serviceStoreList,
            };
            store.commit("updateState", sendData);

            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          state.isProgressing = false;
          return false;
        });
    } else if (dataType == "quick") {
      // console.log("dataType -------------------- " + dataType);

      state["quickStoreCategory"] = state["quickStoreCategory"].map((item, index) => {
        if (index == 0) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      });

      state.quickStoreActiveCategory = 0;
      state.isProgressing = true;
      state.quickStoreList = null;

      var axiosQuickUrl = "/v3/market/delivery/store/market/" + marketId + "/category/0/user/" + state.userId + "/store";
      await this._vm.$axios1
        .get(axiosQuickUrl)
        .then((response) => {
          state.isProgressing = false;
          if (response.data.responseCode == 2000) {
            const sendData = {
              type: "quickStoreList",
              updateData: response.data.result.storeList,
            };
            store.commit("updateState", sendData);
            state.isProgressing = false;
            state.isAxios = false;

            this.dispatch("marketMain/handleScroll");
            if (state.tabFixed) {
              document.querySelector("html").scrollTop = state.topBannerHeight + 56;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          state.isProgressing = false;
          return false;
        });

      // data.categoryId = 0;
      //
      // this.dispatch("marketMain/clickCategoryTab", {
      //   categoryId: data.categoryId,
      // });
    } else {
      this.dispatch("marketMain/handleScroll");
      if (state.tabFixed) {
        document.querySelector("html").scrollTop = state.topBannerHeight + 56;
      }
    }

    // console.log(data);
    store.commit("clickTab", data);
  },
  clickCategory: function (store, data) {
    // 메인에서 카테고리 클릭시
    if (data.categoryId == "favorite") {
      this.dispatch("marketMain/clickTab", { dataType: "favorite" });
    } else {
      this.dispatch("marketMain/clickTab", {
        dataType: "product",
        categoryId: data.categoryId,
      });
    }
  },
  clickBanner: async function (store, event) {
    if (event.target.tagName == "IMG") {
      // if (event.target.dataset.detailUrl) {
      //     this.commit("marketMain/navigateTo", {
      //         path: "event-detail",
      //         params: {
      //             url: event.target.dataset.detailUrl,
      //             title: event.target.dataset.title,
      //         },
      //     });
      // }
      const noticeId = event.target.dataset.noticeId ? event.target.dataset.noticeId : event.currentTarget.dataset.noticeId;
      if (noticeId) {
        const uniqueEventUrl = "v3/noljang/market-main/banners/" + noticeId + "/click";
        const response = await this._vm.$axios2.get(uniqueEventUrl, { headers: { userId: state.userId } });
        const result = response.data.result;
        if (response.data.responseCode === 2000) {
          if (result.bannerClickType === "EVENT_DETAIL") {
            if (event.target.dataset.detailUrl) {
              this.commit("marketMain/navigateTo", {
                path: "event-detail",
                params: {
                  url: result.noticeDetailDto.noticeDetailUrl,
                  title: result.noticeDetailDto.noticeTitle,
                },
              });
            }
          } else if (result.bannerClickType === "PRODUCT_DETAIL") {
            const productId = result.productDetailDto.productId;
            await router.push({
              name: "상품 메인",
              params: {
                productId: productId,
              },
            });
          } else if (result.bannerClickType === "STORE_MAIN") {
            const marketId = result.storeMainDto.marketId;
            const storeId = result.storeMainDto.storeId;
            const storeName = result.storeMainDto.storeName;
            await router.push({
              name: "상점 메인",
              params: {
                marketId: marketId,
                storeId: storeId,
                storeName: storeName,
              },
            });
          }
        }
      }
    } else if (event.target.tagName == "SPAN") {
      if (event.target.classList.contains("allEvent")) {
        router.push({ name: "이벤트", params: { marketId: state.marketId } });
      }
    }
  },
  initState: async function (store, data) {
    state.isInitStateProgressing = true;
    if (data.isReload) {
      state.previousTabItems = state.tabItems;
      state.isReloading = true;
      state.tabItems = [
        {
          dataType: "main",
          text: "메인",
          isActive: true,
        },
      ];
      state.atEventCategoryProducts = null;
      state.hotSaleCategoryProductList = null;
      state.newCategoryProductList = null;
      state.quickStoreList = null;
      state.regularList = null;
      state.productCategoryProductList = null;
      state.stores = null;
    } else {
      store.commit("reset");
      const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;
      const marketId = data.marketId;
      const marketName = data.marketName;
      store.commit("updateState", { type: "userId", updateData: userId });
      store.commit("updateState", { type: "marketId", updateData: marketId });
      store.commit("updateState", {
        type: "marketName",
        updateData: marketName,
      });
      localStorage.setItem("marketMain", JSON.stringify({ marketId: marketId, marketName: marketName }));
    }

    // 시장정보 불러오기 >>> state.orderType 불러온 후 아래 비동기 작업 동시진행
    await store.dispatch("setMarketInfo");

    const apiList = [
      // 시장_전국상품(탭)_대분류(탭) 불러오기
      store.dispatch("setProductLargeTab"),
      // 시장 기준 음식카테고리 불러오기
      store.dispatch("setQuickStoreCategory"),
      // 내가본상품 불러오기
      store.dispatch("setRecentProductList"),
      // 배너목록 불러오기
      store.dispatch("setBanner"),
      // 시장 카테고리 불러오기
      store.dispatch("setMarketCategory"),
      // AT 카테고리 불러오기
      // TODO : AT 할인 이벤트 진행 시 아래 코드 사용
      store.dispatch("setAtCasetAtCategorytegory"),
      // 핫세일 불러오기
      store.dispatch("setHotSaleProductList"),
      // 새로 들어왔어요 불러오기
      store.dispatch("setNewProductList"),
      // 음식배달 불러오기
      store.dispatch("setQuickStoreList"),
      // 단골상점 랜덤 불러오기
      state.userId && store.dispatch("setServiceStoreList"),
      // 단골상점 최신순 불러오기
      state.userId && store.dispatch("setRegularList"),
      // 전체상품 불러오기
      store.dispatch("setProducts"),
      // 전체상점 불러오기
      store.dispatch("setStores"),
    ];
    // 배열 속 비동기 작업이 동시에 진행 된다. 모든 axios 작업이 끝난 후 아래코드 동기적으로 실행
    await Promise.all(apiList);

    if (state.regularList && state.regularList.length > 0) {
      state.tabItems.push({
        dataType: "favorite",
        text: "단골상점",
        isActive: false,
      });

      state.categoryList.unshift({
        dataType: "favorite",
        text: "단골가게",
        isActive: false,
        categoryImageUrl: "icon_favorite_store",
      });
    }

    // tabItems의 순서를 정해줌
    state.tabItems = state.tabItems.map((item) => {
      if (item.dataType === "main") {
        return {
          ...item,
          order: 0,
        };
      } else if (item.dataType === "at") {
        return {
          ...item,
          order: 1,
        };
      } else if (item.dataType === "hot") {
        return {
          ...item,
          order: 2,
        };
      } else if (item.dataType === "new") {
        return {
          ...item,
          order: 3,
        };
      } else if (item.dataType === "quick") {
        return {
          ...item,
          order: 4,
        };
      } else if (item.dataType === "favorite") {
        return {
          ...item,
          order: 5,
        };
      } else if (item.dataType === "product") {
        return {
          ...item,
          order: 6,
        };
      } else if (item.dataType === "stores") {
        return {
          ...item,
          order: 7,
        };
      }
    });

    // tabItems 순서별로 오름차순
    state.tabItems = state.tabItems.sort((a, b) => {
      return a.order - b.order;
    });

    if (data.isReload) {
      const tabItemsDataType = state.tabItems.map((item) => {
        return item.dataType;
      });
      const previousTabItemsDataType = state.previousTabItems.map((item) => {
        return item.dataType;
      });
      const diffTabItemsDataType = previousTabItemsDataType.filter((dataType) => {
        return !tabItemsDataType.includes(dataType);
      });
      if (JSON.stringify(tabItemsDataType) === JSON.stringify(previousTabItemsDataType)) {
        state.tabItems = state.previousTabItems;
      } else {
        const index = state.previousTabItems.findIndex((item) => {
          return item.dataType === state.activeTabItem;
        });
        const previousActiveTabItemText = state.previousTabItems[index].text;
        const previousActiveTabItemDataType = state.previousTabItems[index].dataType;
        if (diffTabItemsDataType.includes(previousActiveTabItemDataType)) {
          state.activeTabItem = "main";
          this._vm.$showToast(`${previousActiveTabItemText} 상품이 존재하지 않습니다.`);
        }
      }
      const dataType = state.activeTabItem;
      await store.dispatch("clickTab", { dataType });
      document.querySelectorAll(".tab-wrap")[1]?.scrollTo({ left: 0 });
    }

    // 팝업 배너 불러오기
    if (
      localStorage.getItem("checkedMarket_" + state.marketId) !=
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() &&
      !localStorage.getItem("isMarketPopup_" + state.marketId)
    ) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(store.dispatch("setPopup"));
        }, 500);
      });
      localStorage.removeItem("checkedMarket_" + state.marketId);
    }

    // 새로고침 작업 이후 작업
    if (data.isReload) {
      const reloadScrollWrapEl = document.querySelector(".reload_scroll_wrap");
      reloadScrollWrapEl.scrollTo({ top: 120 });
      reloadScrollWrapEl.style.setProperty("display", "none");
    }

    state.isFirstLoading = true;
    state.isProgressing = false;
    state.isInitStateProgressing = false;
    state.isReloading = data.isReload && false;
    setTimeout(() => {
      store.commit("setTabBarOptions", state.activeTabItem);
    }, 1);

    // console.log('initState :::::::::::::: ');
    // console.log(data);
    // const cart = localStorage.getItem('userCart');
    // console.log(typeof cart);
    // const userId = data.userId;

    // if (!userId) {// 인증이 완료되지 않은 사용자는 단골상점 탭메뉴 삭제
    //     state.tabItems = state.tabItems.filter(item => item.dataType != 'favorite');
    // }

    /*
    const recommendUrl = "/v3/events/recommends/" + marketId;
    await this._vm.$axios2
      .get(recommendUrl) // 추천인 이벤트
      .then((response) => {
        if (response.data.responseCode == 2000) {
          console.log(response.data.result);
          const sendData = {
            type: "recommendEvent",
            updateData: response.data.result,
          };
          store.commit("updateState", sendData);
        } else {
          console.log(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
    */

    // console.log("state.quickStoreList ::: ");
    // console.log("state.quickStoreList ::: ");

    // else {
    //     state.categoryList = state.categoryList.filter(item => item.dataType != 'favorite');
    // }

    // 전체상품이 없을때
    // if (!state.stores) {

    // console.log(localStorage.getItem("checkedMarket_" + marketId));
    // console.log(new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate());
    // console.log("*****************************************************************************");
    // console.log("*****************************************************************************");
  },
  setProductRecent: async function (store) {
    // 단골상점
    // console.log(state.marketId);
    // console.log(state.userId);

    if (!state.marketId) {
      state.isProgressing = false;
      return;
    }

    var userId = state.userId;
    if (!userId) {
      userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : null;
    }
    const regularUrl = "/v3/user/store/userId/" + userId + "/stores/regular/" + state.marketId + "/sort/NEW";
    await this._vm.$axios1
      .get(regularUrl) // 단골상점
      .then((response) => {
        if (response.data.responseCode == 2000) {
          // if (response.data.result.serviceStoreList.length == 0) {// 목록이 없으면 탭메뉴 삭제
          //     state.tabItems = state.tabItems.filter(item => item.dataType != 'favorite');
          // }
          const sendData = {
            type: "regularList",
            updateData: response.data.result.serviceStoreList,
          };
          store.commit("updateState", sendData);
          store.commit("updateState", {
            type: "serviceStoreList",
            updateData: response.data.result.serviceStoreList,
          }); // 랜덤 적용해야함
        } else {
          alert(response.data.errorObject.errorMessage);
          return false;
        }
      })
      .catch(() => {
        return false;
      });

    if (state.regularList && state.regularList.length > 0) {
      const isFavorite = (element) => element.dataType == "favorite";
      const favoriteIndex = state.tabItems.findIndex(isFavorite);
      if (favoriteIndex < 0) {
        const findType = (element) => element.dataType == "product";
        state.tabItems.splice(state.tabItems.findIndex(findType), 0, {
          dataType: "favorite",
          text: "단골상점",
          isActive: false,
        });
      }

      const favoriteCategoryIndex = state.categoryList.findIndex(isFavorite);
      if (favoriteCategoryIndex < 0) {
        state.categoryList.unshift({
          dataType: "favorite",
          text: "단골가게",
          isActive: false,
          categoryImageUrl: "icon_favorite_store",
        });
      }

      const compareList = [
        "hotSaleProductList",
        "newProductList",
        "products",
        "stores",
        "hotSaleCategoryProductList",
        "newCategoryProductList",
        "productCategoryProductList",
        "atEventProducts",
        "atEventCategoryProducts",
      ];

      compareList.forEach((listName) => {
        if (state[listName] && state[listName].length > 0) {
          state[listName].forEach((list) => {
            var regularCount = 0;
            state.regularList.forEach((store) => {
              if (store.marketId == list.marketId && store.storeId == list.storeId) {
                regularCount++;
              }
            });
            if (regularCount > 0) {
              list.regularYn = "Y";
            } else {
              list.regularYn = "N";
            }
          });
        }
      });
      setTimeout(() => {
        this.commit("marketMain/setTabBarOptions", state.activeTabItem);
      }, 1);
    } else {
      state.categoryList = state.categoryList.filter((item) => item.dataType != "favorite");

      state.tabItems = state.tabItems.filter((item) => item.dataType != "favorite"); // 상단 단골상점 탭 노출하지 않게 처리
      if (state.atEventProducts) {
        state.atEventProducts.forEach((product) => {
          // AT이벤트 단골노출되지 않게 처리
          product.regularYn = "N";
        });
      }

      state.hotSaleProductList.forEach((product) => {
        // 핫세일 단골노출되지 않게 처리
        product.regularYn = "N";
      });
      state.newProductList.forEach((product) => {
        // 오늘 들어왔어요 단골노출되지 않게 처리
        product.regularYn = "N";
      });
      state.products.forEach((product) => {
        // 전체상품 단골노출되지 않게 처리
        product.regularYn = "N";
      });
      state.stores.forEach((product) => {
        // 전체상점 단골노출되지 않게 처리
        product.regularYn = "N";
      });
      if (state.hotSaleCategoryProductList) {
        state.hotSaleCategoryProductList.forEach((product) => {
          // 핫세일 단골노출되지 않게 처리
          product.regularYn = "N";
        });
      }
      if (state.newCategoryProductList) {
        state.newCategoryProductList.forEach((product) => {
          // 오늘 들어왔어요 단골노출되지 않게 처리
          product.regularYn = "N";
        });
      }
      if (state.productCategoryProductList) {
        state.productCategoryProductList.forEach((product) => {
          // 전체상품 단골노출되지 않게 처리
          product.regularYn = "N";
        });
      }
      if (state.atEventCategoryProducts) {
        state.atEventCategoryProducts.forEach((product) => {
          // aT할인 상품 단골노출되지 않게 처리
          product.regularYn = "N";
        });
      }

      // console.log('------------------------------------------------------------');
      // console.log('state.activeTabItem');
      // console.log('state.activeTabItem');
      // console.log('state.activeTabItem');
      // console.log(state.activeTabItem);
      if (state.activeTabItem == "favorite") {
        this.dispatch("marketMain/clickTab", { dataType: "main" });
      }
    }

    // 내가본상품
    const productRecent = localStorage.getItem("product_recent_" + state.marketId);
    state.recentProductList = null;

    if (productRecent) {
      const productRecentList = JSON.parse(productRecent).map((item) => item.productId);
      // console.log('productRecentList ::: ' + productRecentList.join());
      const productRecentListArr = [];
      JSON.parse(productRecent)
        .reverse()
        .forEach((item, index) => {
          if (index < 5) {
            productRecentListArr.push(item.productId);
          }
        });

      if (productRecentList.length > 0) {
        const productRecentUrl = "/v3/market/delivery/product/products/information?productIds=" + productRecentListArr.join() + "&userId=" + userId;
        await this._vm.$axios1
          .get(productRecentUrl) // 내가본상품
          .then((response) => {
            if (response.data.responseCode == 2000) {
              const recentProductList = response.data.result.productDataList;
              const sendData = {
                type: "recentProductList",
                updateData: recentProductList,
                // updateData: recentProductList.reverse(),
              };
              store.commit("updateState", sendData);
            } else {
              alert(response.data.errorObject.errorMessage);
              return false;
            }
          })
          .catch(() => {
            return false;
          });
      }
    }

    if (state.activeTabItem == "product") {
      state.keepProductActiveId = true;
    }

    // 음식배달이 존재할때
    if (state.quickStoreList && userId) {
      const quickStoreUrl = "/v3/market/delivery/store/markets/" + state.marketId + "/stores?userId=" + userId; // 음식배달 상점 목록
      await this._vm.$axios1
        .get(quickStoreUrl) // 음식배달
        .then((response) => {
          if (response.data.responseCode == 2000) {
            const quickStoreList = response.data.result.storeList;
            if (quickStoreList.length) {
              var newQuickStoreList = [];
              state.quickStoreList.forEach((store) => {
                quickStoreList.forEach((item) => {
                  if (store.storeId == item.storeId) {
                    newQuickStoreList.push(item);
                  }
                });
              });
              state.quickStoreList = newQuickStoreList;
            }
          } else {
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    }

    // this.dispatch("marketMain/handleScroll");
    // if (state.tabFixed) {
    // document.querySelector("html").scrollTop = state.topBannerHeight + 56;
    // }

    if (state.activeTabItem != "main" && state.activeTabItem != "hot" && state.activeTabItem != "new" && state.activeTabItem != "at") {
      //   //  && state.activeTabItem != "at"
      //   // 활성화 탭 좌표가 화면에 가려질때 대응
      store.commit("changeCategoryPosition", "mainTab");
    }

    // state.isProgressing = false;

    // this.dispatch('marketMain/clickTab', {dataType: state.activeTabItem});
  },
  share: function (store) {
    const shareInfo = {
      marketId: String(state.marketId),
      marketImage: "http://noljang.co.kr/noljang_share.png",
      marketName: String(state.marketName),
      marketDesc: String(state.marketDescription),
      marketAddress: String(state.marketAddress),
    };

    this._vm.$callNative("shareAppWithKakao", { shareInfo: JSON.stringify(shareInfo) });
  },
};

const getters = {
  // eventContentsType(state){
  //     return state.eventContentsType;
  // },
  // markets(state){
  //     return state.markets;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
