<template>
  <ul class="checkbox_list" :class="checkboxData.type ? checkboxData.type : ''" :id="checkboxData.name">
    <li
      v-for="option in checkboxData.options"
      :key="option.code"
      :class="{ active: option.active }"
      v-if="!checkboxData.showHide || (checkboxData.showHide && option.show)"
    >
      <input
        :type="inputType"
        :name="checkboxData.name"
        :id="`${checkboxData.name}_${option.code || option.id}`"
        :data-code="option.code || option.id"
        :data-text="option.text || option.name"
        :checked="option.active ? true : false"
        :disabled="disabled"
        @change="changeChecked"
      />
      <!--      @change="callback({name: checkboxData.name, optionCode: option.code || option.id, checked: $event.target.checked})"-->
      <!-- @change="changeChecked" -->
      <label
        :class="{
          checkbox_icon_label: inputType === 'checkbox',
          radio_icon_label: inputType === 'radio',
          disabled: disabled,
        }"
        :for="`${checkboxData.name}_${option.code}`"
      >
        {{ option.text || option.name }}
      </label>
      <label class="checkbox_text_label" :class="{ disabled: disabled }" :for="`${checkboxData.name}_${option.code}`">{{
        option.text || option.name
      }}</label>

      <div class="add" v-if="option.add">
        <label class="checkbox_text_label" :class="{ disabled: disabled }" :for="`${checkboxData.name}_${option.code}`" v-html="option.add" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CheckboxList",
  props: {
    checkboxInfo: Object,
    callback: Function,
    disabled: Boolean,
    inputType: {
      type: String,
      default: "checkbox",
    },
  },
  data() {
    return {
      checkboxData: this.checkboxInfo,
      selectCheckboxCode: [],
    };
  },
  methods: {
    changeChecked: function (event) {
      // console.log(this.checkboxData);
      // console.log(event.currentTarget.dataset.code);
      const currentTarget = event.currentTarget;
      const code = currentTarget.dataset.code;
      const checked = currentTarget.checked;
      this.checkboxData.options.forEach((checkbox) => {
        if (this.inputType === "radio") {
          if (checkbox.code !== code) {
            checkbox.active = false;
          }
        }

        if (checkbox.code === code) {
          checkbox.active = checked;

          if (this.inputType === "checkbox") {
            if (this.checkboxData?.selectCount) {
              // console.log(checkbox);
              // console.log(this.checkboxData.selectCount);
              if (checked) {
                this.selectCheckboxCode.push(checkbox.code);

                if (this.selectCheckboxCode.length > this.checkboxData.selectCount) {
                  this.checkboxData.options.forEach((checkbox) => {
                    if (checkbox.code === this.selectCheckboxCode[0]) {
                      checkbox.active = false;
                    }
                  });
                  this.selectCheckboxCode.shift();
                  this.$store.dispatch("setMsg", { msgText: `${this.checkboxData.selectCount}개까지 선택 가능합니다.` });
                }
                // else {
                //   console.log(this.checkboxData);
                //   console.log(this.selectCheckboxCode);
                //   this.checkboxData.options.forEach(checkbox => {
                //     if (checkbox.code === this.selectCheckboxCode[0]) {
                //       checkbox.active = false;
                //     }
                //   });
                //   this.selectCheckboxCode.shift();
                //   this.selectCheckboxCode.push(checkbox.code);
                // }
              } else {
                this.selectCheckboxCode = this.selectCheckboxCode.filter((code) => code !== checkbox.code);
              }
            }
          }
        }
      });
      if (this.callback) {
        this.callback(this.checkboxData);
      }

      // if (this.callback) {
      //   this.callback({
      //     name: this.checkboxData.name,
      //     optionCode: event.target.dataset.code,
      //     checked: event.target.checked,
      //   });
      // }

      // callback({name: checkboxData.name, optionCode: option.code || option.id, checked: $event.target.checked})
    },
  },
  watch: {
    checkboxInfo: function () {
      // console.log("checkboxInfo watch ::::::::::::: ");
      // console.log("checkboxInfo watch ::::::::::::: ");
      // console.log("checkboxInfo watch ::::::::::::: ");
      // console.log("checkboxInfo watch ::::::::::::: ");
      // console.log("checkboxInfo watch ::::::::::::: ");
      // console.log(this.checkboxInfo.name);
      // console.log(this.checkboxInfo);
      this.checkboxData = this.checkboxInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox_list {
  &.type1 {
    > li {
      display: inline-block;
      margin: 0 15px 0 0;
      &:last-child {
        margin: 0;
      }
      .checkbox_text_label {
        margin: 0 0 0 5px;
      }
    }
  }
  &.type2 {
    display: inline-block;
    padding: 0 0 2px;
    vertical-align: middle;
    > li {
      height: auto;
    }
  }

  &.type3 {
    > li {
      height: auto;
      padding: 3px 0 5px;
    }
  }

  &.type5 {
    > li {
      display: block;
      position: relative;
      width: 100%;
      margin: 0 15px 15px 0;
      &:last-child {
        margin: 0;
      }
      //.checkbox_text_label {
      //  margin: 0 0 0 5px;
      //}

      .checkbox_icon_label {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-image: url("~svg/icon_check.svg");
        background-position: center;
        background-size: 30px;
        &.disabled {
          background-color: #f5f5f5;
          opacity: 0.5;
        }
      }

      &.active {
        .checkbox_icon_label {
          background-image: url("~svg/icon_check_red.svg");
        }
      }

      .add {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.li_border_bottom {
      border: {
        style: solid;
        width: 0 0 1px 0;
        color: #f1f1f1;
      }
    }
  }

  > li {
    position: relative;
    height: 30px;
    overflow: hidden;
    &.active {
      .checkbox_icon_label {
        background-image: url("~svg/icon_check_box.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
      .radio_icon_label {
        background-color: $active-background-color;
      }
    }
  }
  .radio_icon_label {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #dedede;
    border-radius: 15px;
    text-indent: -9999px;
    text-align: center;
    vertical-align: middle;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 11px;
      left: 11px;
      width: 9px;
      height: 9px;
      background-color: white;
      border-radius: 10px;
    }
  }
  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -99999px;
    opacity: 0.5;
  }
  .checkbox_icon_label {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("~svg/icon_check_box_outline.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    text-indent: -9999px;
    vertical-align: middle;
    &.disabled {
      background-color: #f5f5f5;
      opacity: 0.5;
    }
  }
  .checkbox_text_label {
    display: inline-block;
    padding: 2px 0 0;
    margin-left: 10px;
    font-size: 14px;
    color: #555;
    vertical-align: middle;
    cursor: pointer;
    &.disabled {
      color: #ccc;
    }
  }
}
</style>
