<template>
  <div @click="$emit('click')" class="tab_item" :class="[active, 'tab']">
    <span class="badge" v-if="isBadge"></span>
    <span class="tab_label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "NoticeTabItem",
  props: {
    id: Number,
    label: String,
    currentTabId: Number,
    isBadge: Boolean,
  },
  data: function () {
    return {};
  },
  computed: {
    active() {
      return this.currentTabId === this.id ? "active" : false;
    },
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.tab_item {
  float: left;
  width: 33.3%;
  height: 55px;
  text-align: center;
  line-height: 55px;
  color: #aaaaaa;
  font: normal normal normal 18px Noto Sans CJK KR;
  line-height: 55px;
  letter-spacing: -0.96px;
  border-bottom: 1px solid $nj_divider_gray;
  position: relative;
}

.tab_item.active {
  color: #000000;
  border-bottom: 2px solid $nj_red;
  font-family: Noto Sans CJK KR Bold;
}

.tab_item .badge {
  width: 5px;
  height: 5px;
  display: block;
  position: absolute;
  top: 15px;
  right: 25px;
  border-radius: 3px;
  background-color: $nj_red;
}
</style>
