<template>
  <ul class="banner_list">
    <li v-for="banner in bannerList" :key="banner.noticeId" :data-code="banner.noticeId">
      <a
        href="javascript:void(0)"
        :data-notice-id="banner.noticeId"
        :data-title="banner.title"
        :data-detail-url="banner.detailUrl"
        @click="clickEvent"
      >
        <img :src="banner.bannerImage" :alt="banner.title" loading="lazy"/>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "BannerList",
  props: {
    bannerList: Array,
    clickEvent: Function,
  },
  // data: function () {
  //   return {
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.banner_list {
  li {
    padding: 0px 0px 10px 0px;
    text-align: center;
    &:last-child {
      padding: 0px;
    }
  }
}
</style>
