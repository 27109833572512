<template>
  <div class="page_wrap">
    <TopHeader
      class="top_header"
      title="신고센터"
      :isCartVisible="false"
      @goBack="goBack"
      style="position: fixed; top: 0px; width: 100%; z-index: 999"
    />
    <div class="content" style="padding: 58px 0px 0px; height: 100vh">
      <div style="padding: 0 10px">
        <div style="display: flex; justify-content: right">
          <button
            @click="clickBottom('write')"
            style="background-color: lightsalmon; padding: 0px 8px; color: white; height: 25px; border-radius: 7px; margin-bottom: 3px"
          >
            작성
          </button>
        </div>
        <ReportingItem v-bind:reports="reportList" />
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import ReportingItem from "@/page/Reporting/compontents/ReportingItem.vue";
import { mapActions } from "vuex";
export default {
  name: "REPORT",
  props: {},
  data: function () {
    return {
      title: "신고센터",
      reportList: [],
    };
  },
  components: {
    TopHeader,
    ReportingItem,
  },
  methods: {
    ...mapActions("product", ["goBack"]),
    clickBottom(type) {
      if (type === "write") {
        this.$router.push({ name: "reportwrite" });
      }
    },
  },
  computed: {},
  created: function () {
    const userId = localStorage.getItem("userId");
    const axiosUrl = "/v3/report/my-list?userId=" + userId;
    this.$axios1
      .get(axiosUrl)
      .then((response) => {
        this.reportList = response.data;
      })
      .catch(() => {
        console.error("에러가 발생했습니다.");
      });
  },
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page_wrap {
  .content {
    position: relative;
    height: calc(100vh - 44px);
    padding: 20px;
    overflow-x: auto;

    .btn_wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
    }
  }
}

.footer_wrap {
  padding: 20px 0 0;
  text-align: center;

  span {
    display: block;
  }

  .title {
    @include font(16, 24, -0.96, bold);
    color: $nj_black;
  }

  .tel {
    @include font(24, 36, -0.48, bold);
    color: $nj_red;
  }

  .time {
    @include font(16, 24, -0.64);
    color: $nj_text_main_gray;
  }
}
</style>
