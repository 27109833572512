<template>
  <div class="search_input_wrap" :class="type">
    <div class="input_wrap">
      <input
        ref="input"
        type="text"
        :placeholder="placeholder"
        :maxlength="maxlength ? maxlength : 999"
        @input="onInput"
        @keydown="onKeydown"
        @focusin="$emit('focusin')"
        @focusout="$emit('focusout')"
        :value="keyword"
      />
      <div class="absolute_right">
        <a href="javascript:void(0)" class="icon_clear_input" @click="clear" title="내용전체삭제" v-if="keyword">내용전체삭제</a>
        <a href="javascript:void(0)" class="icon_input_search" @click="$emit('search', keyword)" title="검색하기">검색하기</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    placeholder: String,
    type: String,
    tabType: String,
    maxlength: String,
    value: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      keyword: "",
    };
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    },
    onInput(e) {
      this.keyword = e.target.value;
      this.$emit("input", this.keyword);
    },
    onKeydown(e) {
      if (e.keyCode == 13) {
        this.$emit("search", this.keyword);
      }
    },
    clear() {
      this.keyword = "";
      this.$emit("input", this.keyword);
    },
  },
  created() {
    this.keyword = this.value;
  },
  watch: {
    tabType() {
      this.keyword = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.search_input_wrap {
  width: 100%;
  height: 55px;
  padding: 10px 15px 0;
  //border: {
  //  style: solid;
  //  width: 1px 0 0;
  //  color: $border-type1;
  //}

  > .input_wrap,
  a {
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }

  .input_wrap {
    position: relative;
    width: 100%;
    //width: calc(100% - 50px);

    input {
      width: 100%;
      height: 40px;
      padding: 10px 70px 10px 20px;
      border: none;
      border-radius: 20px;
      @include font(15, 30, -0.3);
      background-color: $placeholder-background-color;
      color: $gray-middle-dark;
      &::placeholder {
        color: $placeholder-color;
      }
    }
  }

  .absolute_right {
    top: 10px;
    right: 15px;
  }

  a {
    display: inline-block;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    &.btn_clear {
      width: 23px;
      height: 23px;
      background-image: url("~svg/icon_input_search.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &.icon_clear_input {
      width: 23px;
      height: 23px;
      background-image: url("~svg/icon_clear_input.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    &.icon_input_search {
      width: 23px;
      height: 23px;
      margin: 0 0 0 20px;
      background-image: url("~svg/icon_input_search.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.type1 {
    height: 40px;
    padding: 0px;
    border: none;

    input {
      height: 100%;
    }

    .absolute_right {
      top: 50%;
      right: 15px;
      height: 23px;
      transform: translateY(-50%);
    }
  }

  &.type2 {
    height: 44px;
    padding: 0px;
    border: none;

    input {
      height: 100%;
      background-color: #f5f5f5;
    }

    .absolute_right {
      top: 50%;
      right: 15px;
      height: 23px;
      transform: translateY(-50%);
    }
  }
}
</style>
