<template>
  <ul class="inline_list" :class="type">
    <li v-for="(tabItem, idx) in tabItems" v-bind:key="idx">
      <a href="javascript:void(0);" :class="{ active: tabItem.isActive }" @click="onTabClick(tabItem, $event)" v-html="tabItem.text" />
      <span class="unit_line1" v-if="type == 'type2'"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TabList",
  props: {
    type: {
      type: String,
      default: "default",
      // validator: function (value) {
      //   // 값이 아래 문자열 중 하나여야 합니다.
      //   return ['default', 'type1', 'type2', 'type3', 'type4', 'type5', 'default percent_type1', 'product_type1'].indexOf(value) !== -1
      // }
    },
    tabItems: Array,
    data: Object,
  },
  methods: {
    onTabClick: function (tabItem, event) {
      tabItem["event"] = event;
      if (this.data) {
        tabItem["data"] = this.data;
      }
      this.$emit("click", tabItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.inline_list {
  // Common CSS
  white-space: nowrap;
  > li {
    // Common CSS
    display: inline-block;

    > a {
      display: block;
    }
  }

  &.percent_type1 {
    > li {
      &:first-child {
        width: 10%;
      }
      width: 28%;
      &:last-child {
        width: 34%;
      }
      text-align: center;
      a {
        padding: 0 !important;
      }
    }
    &.quick_list {
      > li {
        &:first-child {
          width: 30px;
        }
        &:last-child {
          width: 110px;
        }
        width: 90px;
      }
    }
  }

  // Default Theme
  &.default {
    > li {
      height: 100%;

      &:last-child {
        margin-right: 15px;
      }

      > a {
        height: 100%;
        line-height: 42px;
        padding: 0 20px;
        color: $black;
        @include font(18, 42, 0, medium);
        &:hover {
          color: $black;
        }

        &.active {
          border: {
            style: solid;
            color: $active-border-color;
            width: 0 0 2px;
          }
          color: $active-color;
        }
      }
    }
  }

  // Type 1 Theme (Product Category Menu)
  &.type1 {
    &.reverse {
      > li {
        > a {
          border: {
            style: solid;
            color: $white;
            width: 1px;
          }
          &.active {
            border: {
              style: solid;
              color: $active-color;
              width: 1px;
            }
            @include font(15, 24, 0, medium);
            //@include font(18, 42, 0, medium);
            //border-color: $active-color !important;

            background-color: white;
            color: $active-color;
          }
        }
      }
    }
    > li {
      padding: 10px 0;
      margin-right: 5px;
      &:last-child {
        margin-right: 15px;
      }

      > a {
        height: 24px;
        padding: 0 15px;
        //line-height: 26px;
        @include font(15, 25, 0);
        @include border-radius(20px);
        color: $black;
        &.active {
          color: $white;
          background-color: $active-background-color;
        }
      }
    }
  }

  &.type2 {
    margin: 0 !important;
    background-color: $background-color-type2;
    text-align: center;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    > li {
      position: relative;
      padding: 10px 0;
      margin: 0;
      &:first-child {
        .unit_line1 {
          display: none;
        }
      }
      > a {
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        @include font(17, 32, 0, medium);
        color: $black;

        &.active {
          color: $active-color;
        }
      }
    }
    &.event_tab {
      > a {
        @include font(16, 32, 0, medium);
      }
    }
    .unit_line1 {
      display: inline-block;
      position: absolute;
      top: 18px;
      left: 0;
      width: 1px;
      height: 14px;
      background-color: $border-type1;
    }
  }

  &.type4 {
    > li {
      padding: 13px 0;
      margin-right: 5px;
      &:last-child {
        margin-right: 15px;
      }

      > a {
        height: 24px;
        padding: 0 15px;
        line-height: 30px;
        @include font(15, 26, 0);
        @include border-radius(20px);
        color: $black;
        &.active {
          color: $white;
          background-color: $background-color-type4;
        }
      }
    }
  }
  &.type5 {
    height: 100%;
    position: relative;
    //border-style: solid;
    //border-color: #dedede;
    //border-width: 1px 0;
    > li {
      position: relative;
      //width: 22%;
      text-align: center;
      z-index: 99;
      //&:nth-child(3) {
      //  width: 34%;
      //}
      a {
        padding: 0 15px !important;
        font-size: 15px !important;
      }
    }
  }

  // 상품 정보 > 상세정보/필수표기정보 탭
  &.product_type1 {
    > li {
      > a {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        color: $black;
        @include font(14, 40, 0);

        &:hover {
          color: $black;
        }

        &.active {
          border: {
            style: solid;
            color: $active-border-color;
            width: 0 0 2px;
          }
          color: $active-color;
          @include font(14, 40, 0, medium);
        }
      }
    }
  }
}

@media (max-width: 449px) {
  .inline_list {
    &.default {
      > li {
        > a {
          @include font(17, 42, 0, medium);
        }
      }
    }
    //&.percent_type2 {
    //  padding: 0 15px;
    //  > li {
    //    //a {
    //    //  font-size: 14px;
    //    //}
    //  }
    //}
  }
}
@media (max-width: 359px) {
  .inline_list {
    &.percent_type2 {
      padding: 0 10px;
      > li {
        a {
          font-size: 13.7px;
        }
      }
    }
  }
}

//@media (min-width:360px) {
//  .inline_list {
//    &.percent_type1 {
//      &.quick_list {
//        > li {
//          &:first-child {
//            width: 8%;
//          }
//
//          &:last-child {
//            width: 29%;
//          }
//
//          width: 21%;
//        }
//      }
//    }
//  }
//}
</style>
