const state = {
  title: "이용약관",
  userId: "",
  displayLayer: false,
};

const mutations = {
  initState: function (state, data) {
    state.userId = data.userId;
  },
  goBack: function (state, vueContainer) {
    vueContainer.$closeView();
  },
};

const actions = {
  showHideLayer: function (store, data) {
    if (!data) {
      state.displayLayer = !state.displayLayer;
    } else if (data.btnId && data.btnId == "WITHDRAW") {
      store.dispatch("withdrawal");
    } else {
      state.displayLayer = !state.displayLayer;
    }
  },
  withdrawal: function () {
    const formdata = new FormData();
    formdata.append("member_id", state.userId);

    const axiosUrl = "/api/setMemberWithdrawal";
    this._vm.$axiosLegacy
      .post(axiosUrl, formdata)
      .then((response) => {
        if (response.data.resultCode == 200) {
          console.log(response.data);
          this._vm.$callNative("withdrawal", { userId: state.userId }, false);
          this._vm.isProgressing = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  initState: function (store, data) {
    store.commit("initState", {
      userId: data.userId,
    });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
