<template>
  <!--  <div class="product_list_wrap">-->
  <ul class="ignore_touch_x" :class="{ vertical_scroll: !appVersion }" ref="productList">
    <!--      <div v-if="timeSale" class="open-date">{{ this.remainingTime }}</div>-->
    <!--      <div v-if="timeSale" class="open-date">4월25일오픈!</div>-->
    <li v-for="(product, idx) in products" :key="product.productId + '_' + Date.now() + '_' + idx">
      <a
        href="javascript:void(0);"
        :data-market-id="marketId ? marketId : product.marketId"
        :data-store-id="product.storeId"
        :data-store-name="product.storeName"
        :data-product="product"
        :data-product-id="product.productId"
        @click="clickEvent"
      >
        <div class="thumb">
          <div class="close_store" v-if="product.deliveryMethod !== 'TB' && product.openStoreYn === 'N'">
            {{ storeClosedMsg }}
          </div>
          <div
            class="close_store"
            v-if="
              product.productId === 105675 ||
              product.productId === 105667 ||
              product.productId === 105535 ||
              product.productId === 105991 ||
              product.productId === 105992 ||
              product.productId === 106097 ||
              product.productId === 106650 ||
              product.productId === 106715 ||
              product.productId === 107238 ||
              product.productId === 107548 ||
              product.productId === 107547 ||
              product.productId === 107549 ||
              product.productId === 107711 ||
              product.productId === 107719 ||
              product.productId === 107737 ||
              product.productId === 107738 ||
              product.productId === 107739 ||
              product.productId === 107788 ||
              product.productId === 107789 ||
              product.productId === 107785 ||
              product.productId === 107642 ||
              product.productId === 107846 ||
              product.productId === 107980 ||
              product.productId === 107981 ||
              product.productId === 108214 ||
              product.productId === 108197 ||
              product.productId === 108198 ||
              product.productId === 108215
            "
          >
            SOLD OUT
          </div>
          <!-- <div class="close_store" v-if="product.productId === 108214 || product.productId === 108215">COMING SOON</div> -->
          <!-- 상품정보표시 -->
          <!-- lazy loading 제거 -->
          <img :src="product.productThumbnailUrl ? product.productThumbnailUrl : product.productImageUrl" />
          <img src="~image/엠블럼.png" alt="수산대전 앰블럼" class="emblem" v-if="checkPaymentCode(product)" loading="lazy" />
          <!-- <img src="~image/nonghal.png" alt="농할 앰블럼" class="nonghal_emblem" v-if="product.atYn === 'Y'" loading="lazy" /> -->
          <div class="recent_order_add_cart" v-if="appVersion && directMarkets.filter(market => market.marketId === product.marketId).length === 1" @touchstart="touchStartCart(idx)" @touchend="touchEndCart(idx)" />
        </div>
        <ul class="delivery_type_list" v-if="product.deliveryMethod">
          <li class="tb" v-if="product.deliveryMethod.includes('TB')">택배</li>
          <li
            class="fc"
            v-if="
              Number(product.marketId) === 168 || (product.deliveryMethod == 'TB' && product.deliveryFeeCondition == 'FREE' && !product.deliveryFee)
            "
          >
            무료배송
          </li>
          <li
            class="at"
            v-if="
              product.atYn === 'Y' &&
              Number(product.marketId) !== 168 &&
              discountType != 'SEOUL' &&
              discountType != 'NONE' &&
              sssMarkets.indexOf(Number(product.marketId)) === -1 &&
              hideAtLabelProducts.indexOf(Number(product.productId)) === -1
            "
          >
            농할
          </li>
          <!-- <li
            class="at"
            v-if="
              product.atYn === 'Y' &&
              Number(product.marketId) !== 168 &&
              discountType != 'SEOUL' &&
              discountType != 'NONE' &&
              sssMarkets.indexOf(Number(product.marketId)) === -1 &&
              hideAtLabelProducts.indexOf(Number(product.productId)) === -1
            "
            v-text="nonghalText"
          ></li> -->
        </ul>

        <div class="store_name" :class="{ add_like: displayLikeBtn }" v-if="!product.storeName">
          {{ product.storeName }}
          <div v-if="displayLikeBtn" class="icon_like remove_like" title="찜 해제">찜 해제</div>
        </div>
        <div>
          <ProductTitle
            :id="product.storeId + '_' + product.productId"
            :name="product.productName"
            :saleUnit="product.unitNumber ? product.unitNumber + product.unitName : ''"
          />
        </div>
        <div class="price">
          {{ $formatPrice(product.price) }}원
          <div class="icon_like2 active" v-if="product.productLikeCount">
            {{ product.productLikeCount }}
          </div>
        </div>

        <!-- 화목한특가에 농할 할인 적용-->
        <div class="discount_txt coupon" v-if="product.marketId === 188 && specialOfferDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(specialOfferDiscountPrice(product)) }}원</strong>
        </div>

        <!-- 친환경에 농할 할인 적용-->
        <!-- <div class="discount_txt coupon" v-if="product.marketId === 187 && specialOfferDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(specialOfferDiscountPrice(product)) }}원</strong>
        </div> -->

        <!-- 축산 할인 적용 -->
        <!-- <div class="discount_txt coupon" v-if="product.marketId === 192 && specialOfferDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(specialOfferDiscountPrice(product)) }}원</strong>
        </div> -->

        <!-- N마트 할인 적용 -->
        <!-- <div class="discount_txt coupon" v-if="product.marketId === 190 && specialOfferDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(specialOfferDiscountPrice(product)) }}원</strong>
        </div> -->

        <!-- 산지직송 할인 적용 -->
        <div class="discount_txt coupon" v-if="product.marketId === 191 && specialOfferDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(specialOfferDiscountPrice(product)) }}원</strong>
        </div>

        <!-- 수산 할인 적용 -->
        <!-- <div class="discount_txt coupon" v-else-if="product.marketId === 177 && blueDiscountPrice(product)">
          할인가 <strong>{{ $formatPrice(blueDiscountPrice(product)) }}원</strong>
        </div> -->

        <div class="discount_txt" v-if="product.atYn === 'Y'">결제 시 할인 적용</div>

        <div v-if="product.paymentTypeList.length">
          <ul class="inline_list type2 icon_card">
            <li v-for="(paymentType, idx) in product.paymentTypeList" v-bind:key="idx" class="payment_item">
              <!-- <p v-if="paymentType.paymentName === '구로사랑상품권' && product.marketId === 176">
                {{ "서울사랑상품권" }}
              </p>
              <p v-else-if="paymentType.paymentName === '신온누리'"></p>
              <p v-else-if="paymentType.paymentName === '금천G밸리사랑상품권' && product.marketId === 187">
                {{ "서울사랑상품권" }}
              </p>
              <p v-else-if="paymentType.paymentName === '금천G밸리사랑상품권' && product.marketId === 190">
                {{ "서울사랑상품권" }}
              </p>
              <p v-else-if="paymentType.paymentName === '금천G밸리사랑상품권' && product.marketId === 192">
                {{ "서울사랑상품권" }}
              </p>
              <p v-else>
                {{ paymentType.paymentName }}
              </p> -->
              <p v-if="paymentType.paymentName === '신온누리'"></p>
              <p v-else>
                {{ paymentType.paymentName }}
              </p>
            </li>
            <!-- <li v-for="(payment, index) in product.paymentTypeList" :key="'payment_' + index">
              {{ payment.paymentName ? payment.paymentName : payment }}
            </li> -->
          </ul>
        </div>
      </a>
    </li>
    <!--타임세일 하드코딩데이터-->
    <li v-if="timeSale && categoryId !== 2 && soldOutData.length">
      <div v-for="(product, idx) in this.soldOutData" :key="product.productId + '_' + Date.now() + '_' + idx">
        <a
          href="javascript:void(0);"
          :data-market-id="marketId ? marketId : product.marketId"
          :data-store-id="product.storeId"
          :data-store-name="product.storeName"
          :data-product="product"
          :data-product-id="product.productId"
        >
          <div class="thumb">
            <div class="close_store" v-if="product.soldOut">SOLD OUT</div>
            <div class="close_store" v-if="timeSale && product.scheduled" style="text-align: center; line-height: 17px">Comming Soon</div>
            <img :src="product.productThumbnailUrl ? product.productThumbnailUrl : product.productImageUrl" loading="lazy" />
            <img src="~image/엠블럼.png" alt="수산대전 앰블럼" class="emblem" v-if="checkPaymentCode(product)" loading="lazy" />
            <div class="recent_order_add_cart" v-if="appVersion && directMarkets.filter(market => market.marketId === product.marketId).length === 1" @touchstart="touchStartCart(idx)" @touchend="touchEndCart(idx)" />
          </div>
          <div>
            <ProductTitle
              :id="product.storeId + '_' + product.productId"
              :name="product.productName"
              :saleUnit="product.unitNumber ? product.unitNumber + product.unitName : ''"
            />
          </div>
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import ProductTitle from "@/components/new/ProductTitle";
import { mapActions, mapState } from "vuex";

export default {
  name: "ProductList",
  props: {
    products: Array,
    marketId: null,
    categoryId: Number,
    timeSale: Boolean,
    specialOffer: String,
    specialArray: Array,
    noneStoreName: String,
    displayLikeBtn: {
      type: Boolean,
      default: false,
    },
    callbackRemoveLike: Function,
    isCartExist: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      sssMarkets: (state) => state.sssMarkets, // 서울시특판 시장 아이디
      hideAtLabelProducts: (state) => state.hideAtLabelProducts, // 농할 할인은 적용되지만 농할 라벨 보이지 않게 처리
      appVersion: (state) => state.appVersion,
      directMarkets: (state) => state.main.directMarkets,
    }),
    storeClosedMsg: function () {
      return new Date().getHours() < 12 ? "영업준비중" : "영업종료";
    },
    isWithinSaleHours() {
      const now = new Date();
      const hours = now.getHours();
      // 9:00 - 24:00 사이인지 확인
      return hours >= 9;
    },
    isSaleDay() {
      const now = new Date();
      const dayOfWeek = now.getDay();
      // 화요일(2)과 목요일(4)인지 확인
      return dayOfWeek === 2 || dayOfWeek === 4;
    },
  },
  methods: {
    ...mapActions(["navigateToProductOption"]),
    getStateStore: function () {
      console.log(new Date().getHours());
      if (new Date().getHours() < 12) {
        return "영업준비중";
      } else {
        return "영업종료";
      }
    },
    updateRemainingTime() {
      const now = new Date();
      const openDateTime = new Date(this.openDate);
      const timeDiff = openDateTime - now;

      if (timeDiff <= 0) {
        this.remainingTime = "오픈했습니다!";
        clearInterval(this.timer);
        return;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      this.remainingTime = `${days}일 ${hours}시간 ${minutes}분 ${seconds}초 남았습니다.`;
    },
    clickEvent: async function (event) {
      // 앱 다운로드 받아야 된다는 모달을 여기에 띄운다.

      const classList = event.target.classList;
      if (classList.contains("icon_like")) {
        if (this.callbackRemoveLike) {
          this.callbackRemoveLike({
            productId: Number(event.currentTarget.dataset.productId),
          });
        }
      } else if (classList.contains("recent_order_add_cart")) {
        if (this.$store.state.appVersion) {
          let productId = event.currentTarget.dataset.productId;
          // 소비자앱 Flutter 적용 시
          this.$router.push({
            name: "상품옵션",
            params: {
              productId: productId,
              userId: this.$store.state.userId,
            },
          });
        } else {
          // 장바구니 클릭 시 장바구니 추가 프로세스
          this.navigateToProductOption({
            product: event.currentTarget.dataset.product,
            productId: event.currentTarget.dataset.productId,
          });
        }
      } else if (classList.contains("store_name")) {
        let marketId = event.currentTarget.dataset.marketId;
        let storeId = event.currentTarget.dataset.storeId;
        if (marketId === "173" && storeId === "1") {
          marketId = "169";
          storeId = "1";
        }

        // 상점 메인으로 이동
        this.$router.push({
          name: "상점 메인",
          params: {
            marketId: marketId,
            storeId: storeId,
            storeName: event.currentTarget.dataset.storeName,
          },
        });
      } else {
        let productId = event.currentTarget.dataset.productId;
        // console.log(productId);
        // if (productId === '101992') {
        //   productId = '102548';// 춘천중앙시장 절임배추 예외처리
        // }
        // else if (productId === '102855') {
        //   productId = '102856';
        // }
        //
        // console.log(productId);
        // debugger;
        // 상품 메인으로 이동
        this.$router.push({
          name: "상품 메인",
          params: {
            productId: productId,
          },
        });
      }
    },
    touchStartCart: function (idx) {
      document.getElementsByClassName("recent_order_add_cart")[idx].classList.add("hover");
    },
    touchEndCart: function (idx) {
      document.getElementsByClassName("recent_order_add_cart")[idx].classList.remove("hover");
    },
    discountPrice: function (product) {
      const discountMax = product.price - 20000;
      const discount30 = +(product.price * 0.7).toFixed(0);
      return discount30 < discountMax ? discountMax : discount30;
    },

    // 농축산시장, 브랜드몰(친환경) 할인가
    greenDiscountPrice: function (product) {
      let discount = 0;
      // if (product.atYn === "Y") discount += +(product.price * 0.3).toFixed(0);
      if (product.atYn === "Y") return this.agrecultureDiscountPrice(product);
      if (product.price >= 50000) discount += 5000;
      else if (product.price >= 40000) discount += 4000;
      else if (product.price >= 30000) discount += 3000;
      if (discount === 0) return null;
      return product.price - discount;
    },

    // 농할 할인
    agrecultureDiscountPrice: function (product) {
      if (product.atYn === "N") return null;
      const 농할할인적용 = 0.7;
      const 최대할인값 = product.price - 20000;
      const 농할할인값 = +(product.price * 농할할인적용).toFixed(0);
      return 농할할인값 < 최대할인값 ? 최대할인값 : 농할할인값;
    },

    // 화목한특가 + 친환경 농할 할인
    specialOfferDiscountPrice: function (product) {
      let 쿠폰할인가 = 0;
      if (product.price >= 50000) 쿠폰할인가 = 5000;
      else if (product.price >= 30000) 쿠폰할인가 = 3000;
      else if (product.price >= 20000) 쿠폰할인가 = 2000;
      if (쿠폰할인가 === 0) return null;
      return product.price - 쿠폰할인가;
    },
    // 수산시장 할인가
    blueDiscountPrice: function (product) {
      let 쿠폰할인가 = 0;
      if (product.price >= 50000) 쿠폰할인가 += 10000;
      else if (product.price >= 30000) 쿠폰할인가 += 6000;
      else if (product.price >= 20000) 쿠폰할인가 += 4000;
      if (쿠폰할인가 === 0) return null;
      return product.price - 쿠폰할인가;
    },
    checkPaymentCode(product) {
      if (!product.paymentTypeList || product.marketId !== 177) {
        return false;
      }
      let check = 0;
      product.paymentTypeList.forEach((code) => {
        if (code.paymentCode === "ONR" || code.paymentCode === "SEA") {
          check++;
        }
      });
      return check === 2;
    },
  },
  data: function () {
    return {
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      nonghalText: "농할",
      currentTime: new Date().toLocaleTimeString(),
      openDate: "2024-04-25T09:00:00", // ISO 8601 날짜 형식
      remainingTime: "", // 남은 시간을 문자열로 저장할 데이터 속성
      soldOutData: [
        // 기존 상품 데이터
        // {
        //   productId: '101',
        //   marketId: '2',
        //   productName: '딸기',
        //   productThumbnailUrl: 'https://s3.ap-northeast-2.amazonaws.com/noljang-ops/product/normalImage/product_106715_thumb.png',
        //   soldOut: true, // 이 상품은 품절되지 않음
        //   // 기타 상품 속성
        // },
        // 추가 상품 데이터...
      ],
    };
  },
  components: {
    ProductTitle,
  },
  watch: {
    products: function () {
      this.$refs.productList.scrollTo({ left: 0 });
    },
  },
  mounted() {
    // console.log("this.state.specialOffer", this.specialOffer);
    // console.log(this.soldOutData);
    this.updateRemainingTime(); // 초기 남은 시간 계산
    this.timer = setInterval(() => {
      this.updateRemainingTime(); // 매초마다 남은 시간 업데이트
    }, 1000);
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 컴포넌트가 파괴될 때 인터벌을 정리
    }
  },
};
</script>

<style lang="scss" scoped>
.open-date {
  display: flex;
  justify-content: center;
  color: orangered;
}
.ignore_touch_x {
  display: inline-block;
  max-width: 100%;
  min-width: calc(100% - 1px);
}
.icon_like.remove_like {
  top: 0;
  width: 25px;
  background-image: url("~svg/icon_like_active.svg");
  background-position: right 3px;
  text-indent: -9999px;
}

// Product.vue 화면 내에도 해당 UI가 있어서 CSS 변경 시 함께 변경 필요
.delivery_type_list {
  //display: flex;
  //flex-wrap: wrap;
  margin: 5px 0 0;

  // 수정
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  > li {
    // display: inline-block;
    // 인라인블록 죽임
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px 0 0;
    padding: 3px 4px 2px;
    background: gray;
    @include border-radius(3px);
    @include font(12, 16);
    color: white;

    &.pkg {
      background-color: #67a3ff;
    }

    &.fd {
      background-color: #ffa033;
    }

    &.tb {
      padding: 3px 4px 2px;
      border: {
        style: solid;
        width: 1px;
        color: #707070;
        radius: 3px;
      }
      color: #707070;
      background-color: white;
      @include font(12, 16);
    }

    &.at {
      padding: 3px 4px 2px;
      border: {
        style: solid;
        width: 1px;
        color: #ff1d48;
        radius: 3px;
      }
      color: #ff1d48;
      background-color: white;
      @include font(12, 16);
    }

    &.fc {
      background-color: white;
      border: 1px solid #707070;
      color: #707070;
    }
  }
}

.ios {
  .delivery_type_list {
    > li {
      &.at {
        padding: 1px 4px 1px;
      }
    }
  }
}

.discount_txt {
  font-size: 13px;
  color: $nj_text_main_gray;

  &.coupon {
    font-weight: bold;
    color: #ff563f;

    strong {
      font-size: 14px;
      font-family: gothic_a1_extrabold;
      vertical-align: middle;
      margin-left: 3px;
    }
  }
}

.emblem {
  position: absolute;
  left: 4%;
  bottom: 4%;
  width: 30% !important;
  max-width: 80px;
  border-width: 0 !important;
}

.nonghal_emblem {
  position: absolute;
  left: 4%;
  bottom: 4%;
  width: 30% !important;
  max-width: 80px;
  border-width: 0 !important;
}

.recent_order_add_cart {
  position: absolute;
  bottom: 4%;
  right: 4%;
  width: 20%;
  height: 20%;
  background-image: url("~image/cart.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.product_list_wrap ul {
  padding: 0 10px 0 10px;
}

.product_list_wrap ul li {
  padding: 0 7px 50px 7px;
}
</style>
