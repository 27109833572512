const getState = () => {
  return {
    marketList: [],
    isProgressing: false,
    tabType: "ALL",
    tabItems: [
      {
        dataType: "ALL",
        text: "전체시장",
        isActive: true,
      },
      {
        dataType: "BD",
        text: "전통시장",
        isActive: false,
      },
      {
        dataType: "TB",
        text: "특화시장",
        isActive: false,
      },
      {
        dataType: "NM",
        text: "주변시장",
        isActive: false,
      },
    ],
  };
};

const state = getState();

const mutations = {
  reset: function (state) {
    Object.assign(state, getState());
  },
  setIsProgressing: function (state, isProgressing) {
    state.isProgressing = isProgressing;
  },
  setMarketList: function (state, marketList) {
    state.marketList = marketList;
  },
  setContentsType: function (state, dataType) {
    state.tabType = dataType;
    state.tabItems.forEach((tabItem) => {
      if (dataType == tabItem.dataType) {
        tabItem.isActive = true;
      } else {
        tabItem.isActive = false;
      }
    });
  },
};

const actions = {
  getMarketList: async function (store) {
    try {
      const userAddress = localStorage.getItem("userAddress");
      const latitude = userAddress ? JSON.parse(userAddress)?.latitude : 0;
      const longitude = userAddress ? JSON.parse(userAddress)?.longitude : 0;
      const marketType = state.tabType;

      store.commit("setIsProgressing", true);
      const marketListResponse = await this._vm.$axios1.get("/v3/market/user/" + latitude + "/" + longitude + "/markets?deliveryType=" + marketType);
      store.commit("setIsProgressing", false);

      if (marketListResponse.data.responseCode == 2000) {
        let marketList = marketListResponse.data.result.marketList;
        marketList = marketList.map((e) => {
          let newList = e;
          newList.key = marketType + newList.marketId.toString();
          return newList;
        });

        store.commit("setMarketList", marketList);
      } else {
        alert(marketListResponse.data.errorObject.errorMessage);
        return false;
      }
    } catch (e) {
      store.commit("setIsProgressing", false);
      return false;
    }
  },
  setContentsType: function (store, dataType) {
    store.commit("setContentsType", dataType);
    store.dispatch("getMarketList", dataType);
  },
  reset: function (store) {
    store.commit("reset");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
