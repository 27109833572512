<template>
  <div class="my_page_list_wrap">
    <ul class="my_page_list">
      <li v-if="userId != null">
        <a href="javascript:void(0)" class="order_history" @click="goOrderHistory"> 주문내역 </a>
      </li>
      <li v-if="userId != null">
        <a href="javascript:void(0)" class="my_coupon" @click="goCoupon"> 보유쿠폰 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="privacy_info" @click="goPrivacyInfo"> 개인정보처리방침 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="terms_conditions" @click="goTermsConditions"> 이용약관 </a>
      </li>
    </ul>
    <span class="item_badge_wrap" v-if="badgeUrl">
      <span class="item_badge">
        <!-- 리스트 옆 쿠폰 개수 표시 -->
        {{ formattedCount }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "MyPageList",

  props: {
    title: String,
    badgeUrl: String,
    link: String,
    // icon: String,
    detailUrl: {
      type: String,
      default: "",
    },
    userId: String,
  },

  data: function () {
    return {
      // userId: localStorage.getItem('userId'),
      badge: 0,
    };
  },
  methods: {
    goLink: function () {
      let param = null;

      if (this.link == "showTerms") {
        param = {
          title: this.title,
          detailUrl: this.detailUrl,
        };
      }
      this.$callNative(this.link, param);
    },

    getBadgeCount: async function () {
      if (this.badgeUrl && this.$route.query.userId) {
        const response = await this.$axiosLegacy.get(this.badgeUrl);

        this.badge = response.data.couponInfos.length;
      }
    },
    goOrderHistory: function () {
      this.$router.push({ name: "주문내역" });
    },
    goWish: function () {
      this.$router.push({ name: "찜 리스트", params: { mypage: true } });
    },
    goCoupon: function () {
      this.$router.push({ name: "보유쿠폰" });
    },
    goCs: function () {
      this.$router.push({ name: "고객센터" });
    },
    goPrivacyInfo: function () {
      this.$router.push({ name: "개인정보처리방침" });
    },
    goTermsConditions: function () {
      this.$router.push({ name: "이용약관" });
    },
  },

  computed: {
    formattedCount: function () {
      return this.badge > 99 ? "99+" : this.badge;
    },
  },
};
</script>

<style lang="scss" scoped>
.my_page_list_wrap {
  margin: 0px 16px;
}

.my_page_list {
  display: block;
  width: 100%;
  @include font(16, 6, 0, medium);
  vertical-align: middle;

  & > span {
    display: inline-block;
    vertical-align: top;
    font-style: $black;
  }

  .title {
    color: #000000;
  }

  .item_badge_wrap {
    height: 20px;
    margin-left: 10px;

    .item_badge {
      width: 100%;
      padding: 1px 6px;
      background: #ffffff;
      border-radius: 11px 11px 11px 11px;
      border-color: #ff1d48;
      @include font(10, 14, 0, thin);
      color: #ff1d48;
    }
  }

  .order_history {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_order_history.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black;
    &:hover {
      color: $black;
    }
  }

  .wish_product {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_like.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black;
    &:hover {
      color: $black;
    }
  }

  .my_coupon {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_coupon.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black !important;
    &:hover {
      color: $black !important;
    }
  }

  .customer_service {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_cs.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black;
    &:hover {
      color: $black;
    }
  }

  .privacy_info {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_privacy.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black;
    &:hover {
      color: $black;
    }
  }

  .terms_conditions {
    display: block;
    width: 100%;
    padding: 20px 30px;
    background-image: url("~svg/icon_new_terms.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    border-bottom: 1px solid #dcdcdc;
    vertical-align: middle;
    color: $black;
    &:hover {
      color: $black;
    }
  }
}
</style>
