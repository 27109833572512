<template>
  <div v-if="products && products.length > 0">
    <div class="market_name">
      <div class="name" :data-market-id="marketId">{{ marketName }}</div>
      <div class="absolute_right">
        주문<span class="accent">{{ products.length }}</span
        ><span class="normal">건</span>
      </div>
    </div>
    <ul class="cart_list">
      <li v-for="(product, index) in products" :key="product.id">
        <!-- :style="product.bundleIndex ? 'background-color: #f9f9f9;' : null" -->
        <!-- 장바구니 -> 마켓프로덕트리스트 -->
        <ProductList
          :userId="userId ? userId : null"
          :compoundKey="product.compoundKey ? product.compoundKey : null"
          :marketId="marketId ? marketId : null"
          :marketName="marketName ? marketName : null"
          :deliveryMethod="product.deliveryMethod"
          :productId="product.productId"
          :deliveryBundleId="product.deliveryBundleId"
          :deliveryFeeMethod="product.deliveryFeeMethod"
          :deliveryFee="product.deliveryFee"
          :bundleDeliveryFee="product.bundleDeliveryFee"
          :bundleCount="product.bundleCount"
          :bundleTotalCount="product.bundleTotalCount"
          :bundleIndex="product.bundleIndex"
          :guideTxt="product.guideTxt"
          :lastList="product.lastList"
          :shippingType="product.shippingType"
          :products="products"
          :productIndex="index"
          :storeName="product.storeName"
          :name="product.name"
          :paymentTypes="product.paymentTypes"
          :quantity="product.quantity"
          :price="product.price"
          :discountPrice="product.discountPrice"
          :productImageUrl="product.imgUrl"
          :disabled="product.disabled"
          :disabledType="product.disabledType"
          :isDisabled="product.isDisabled"
          :requestInput="product.requestInput"
          :weight="product.weight"
          :totalWeight="product.totalWeight"
          :unitName="product.unitName"
          :unitNumber="product.unitNumber"
          :originName="product.originName"
          :optionGroups="product.optionGroups ? (product.optionGroups.length > 0 ? product.optionGroups : null) : null"
          :totalOptionPrice="product.totalOptionPrice ? product.totalOptionPrice : null"
          :marketOpenYn="product.marketOpenYn"
          :storeOpenYn="product.storeOpenYn"
          :productSaleYn="product.productSaleYn"
          :atYn="product.atYn"
          @add="$emit('add', $event)"
          @remove="$emit('remove', $event)"
          @delete="$emit('delete', $event)"
        />
      </li>
    </ul>

    <div class="order_total_info type_bd" v-if="market.orderType !== 'TB' && market.shippingType">
      <ul>
        <li v-if="market.shippingType === 'WEIGHT' || market.shippingType === 'MIX_SHIPPING'">
          <span class="title">배달무게</span>
          <span class="absolute_right">
            <span class="price">{{ $formatWeight(market.productTotalWeight).replace("k", "").replace("g", "") }}</span>
            <span class="ml_3">{{ $formatWeight(market.productTotalWeight).indexOf("kg") === -1 ? "g" : "kg" }}</span>
          </span>
        </li>
        <li v-if="market.shippingType === 'PACKING' || (market.shippingType === 'MIX_SHIPPING' && market.boxQuantity)">
          <span class="title">박스수량</span>
          <span class="absolute_right">
            <span class="price">{{ $formatPrice(market.boxQuantity) }}</span>
          </span>
        </li>
      </ul>
    </div>

    <div class="order_total_info" :class="{ type_tb: market.orderType === 'TB' && products.length > 1 && userId }">
      <ul>
        <li v-if="market.orderType !== 'TB'">
          <span class="title">총 상품금액</span>
          <span class="absolute_right">
            <strong class="price">{{ $formatPrice(totalPrice) }}</strong>
            <span class="won">원</span>
          </span>
        </li>
        <li v-else-if="market.orderType === 'TB' && products.length > 1">
          <span class="title">총 상품금액</span>
          <span class="absolute_right">
            <strong class="price">{{ $formatPrice(totalPrice) }}</strong>
            <span class="won">원</span>
          </span>
        </li>
        <li v-if="market.orderType !== 'TB' && userId && market.orderInfo && market.orderInfo.deliveryFee != '포장주문 시 배달비는 무료입니다.'">
          <div>
            <span class="title">배송비</span>
            <span class="absolute_right">
              <!-- 방문수령 예외처리 -->
              <template v-if="deliveryFee">
                <strong class="price" v-if="market.marketOnSites.defaultType === 'TG'">0</strong>
                <strong class="price" v-else>
                  {{ market.marketOnSites && market.marketOnSites.selectType === "PACKING" ? 0 : $formatPrice(deliveryFee) }}
                </strong>
                <span class="won">원</span>
              </template>
              <template v-else>
                <strong
                  :class="
                    market.onSiteYn !== 'Y' && market.orderInfo && market.orderInfo.deliveryFee != '포장주문 시 배달비는 무료입니다.'
                      ? 'free'
                      : 'price'
                  "
                >
                  {{
                    market.onSiteYn !== "Y" && market.orderInfo && market.orderInfo.deliveryFee != "포장주문 시 배달비는 무료입니다." ? "무료배송" : 0
                  }}
                </strong>
                <span
                  class="won"
                  v-if="
                    (market.onSiteYn && market.onSiteYn === 'Y') ||
                    (market.orderInfo && market.orderInfo.deliveryFee == '포장주문 시 배달비는 무료입니다.')
                  "
                  >원</span
                >
              </template>
            </span>
          </div>
          <div class="guide_txt_wrap" v-if="market.chargeWtInfoText && market.onSiteYn !== 'Y'">{{ market.chargeWtInfoText }}</div>
          <div class="guide_txt_wrap" v-if="market.chargeBoxInfoText">{{ market.chargeBoxInfoText }}</div>
        </li>
        <li v-else-if="market.orderType === 'TB' && products.length > 1 && userId">
          <span class="title">배송비</span>
          <span class="absolute_right">
            <template v-if="market.deliveryTbType === 'PREPAYMENT'">
              <strong class="price">{{ $formatPrice(deliveryFee) }}</strong>
              <span class="won">원</span>
            </template>
            <template v-else-if="market.deliveryTbType === 'CASH_DELIVERY' && market.cashDeliveryTbPrice > 0">
              <strong class="cash_delivery">착불</strong>
              <span class="price">{{ $formatPrice(market.cashDeliveryTbPrice) }}</span>
              <span class="won">원</span>
            </template>
            <template v-else-if="market.deliveryTbType === 'CASH_DELIVERY' && market.cashDeliveryTbPrice === '착불'">
              <strong class="free">착불</strong>
            </template>
            <template v-else-if="market.deliveryTbType === 'MIXED_PAYMENT'">
              <strong class="price">{{ $formatPrice(deliveryFee) }}</strong>
              <span class="won">원</span>
            </template>
            <template v-else-if="!deliveryFee && !market.cashDeliveryTbPrice">
              <strong class="free">무료배송</strong>
            </template>
            <template v-else>
              <strong class="free">무료배송</strong>
            </template>
          </span>
        </li>

        <li>
          <span class="title accent">결제금액</span>
          <span class="absolute_right">
            <!-- 방문수령 예외처리 -->
            <strong class="price" v-if="userId">
              {{
                market.onSiteYn && market.onSiteYn === "Y"
                  ? $formatPrice(totalPrice)
                  : $formatPrice(market.marketId === 45 || market.marketId === 42 || market.marketId === 33 ? totalPrice + deliveryFee : totalPrice)
              }}
            </strong>
            <strong class="price" v-else>{{ $formatPrice(totalPrice) }}</strong>
            <span class="won">원</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="delivery_date_info_wrap">
      <!-- 방문수령 예외처리 -->
      <p
        class="delivery_date_info"
        v-if="(market.orderType === 'BD' && (!isNotWeekend || !checkMarketTime())) || market.marketOnSites.defaultType === 'TG'"
      >
        포장 주문은 주문 당일 직접 방문하여 수령합니다.
      </p>
      <p
        class="delivery_date_info"
        v-else-if="market.marketOnSites.selectType !== 'DELIVERY'"
        v-html="
          market.marketOnSites && market.marketOnSites.selectType === 'PACKING' ? '포장 주문은 주문 당일 직접 방문하여 수령합니다.' : deliveryDateInfo
        "
      />
    </div>
    <div class="btn_wrap">
      <div
        class="mb_20"
        v-if="
          market.orderType !== 'TB' &&
          market.marketOnSites.defaultType === 'BD' &&
          isNotWeekend &&
          !checkMarketTime() &&
          market.marketOnSites.onSiteYn === 'Y'
        "
      >
        <div style="position: relative">
          <Tab
            :tabItems="market.marketOnSites.tabData"
            tabType="type5"
            addClass="border_type1"
            :isPreviousData="true"
            @click="tabClick({ dataType: $event.dataType, marketId: market.marketId })"
          />
          <span class="ml_10" v-if="market.marketOnSites.selectType">
            {{ market.marketOnSites.atYn === "Y" ? "" : market.marketOnSites.selectType === "DELIVERY" ? "로 받을게요" : "으로 직접 가지러 갈게요" }}
          </span>
        </div>
      </div>
      <div v-if="(market.orderType === 'BD' || market.marketOnSites.defaultType === 'TG') && userId">
        <Btn
          :type="market.orderInfo && market.orderInfo.marketStatus === '주문시간이 종료되었습니다.' ? 'inactive font_weight_normal type5' : 'type5'"
          v-if="!market.onSiteYn || market.onSiteYn !== 'Y'"
          :value="
            market.orderInfo && market.orderInfo.marketStatus === '주문시간이 종료되었습니다.'
              ? market.orderInfo.marketStatus
              : market.marketOnSites && market.marketOnSites.selectType === 'PACKING'
              ? '포장 주문하기'
              : '주문하기'
          "
          @btnCallback="$emit('payment', { marketId: marketId, event: $event })"
        />
        <Btn
          :type="market.orderInfo && market.orderInfo.marketStatus === '주문시간이 종료되었습니다.' ? 'inactive font_weight_normal type5' : 'type5'"
          v-else
          :value="market.marketOnSites && market.marketOnSites.selectType === 'PACKING' ? '포장 주문하기' : '주문하기'"
          @btnCallback="$emit('payment', { marketId: marketId, event: $event })"
        />
      </div>
      <div v-else>
        <Btn
          :type="'type5'"
          v-if="!market.onSiteYn || market.onSiteYn !== 'Y'"
          :value="market.marketOnSites && market.marketOnSites.selectType === 'PACKING' ? '포장 주문하기' : '주문하기'"
          @btnCallback="$emit('payment', { marketId: marketId, event: $event })"
        />
        <Btn
          type="type5"
          v-else
          :value="market.marketOnSites && market.marketOnSites.selectType === 'PACKING' ? '포장 주문하기' : '주문하기'"
          @btnCallback="$emit('payment', { marketId: marketId, event: $event })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductList from "@/components/new/cart/ProductList";
import Btn from "@/components/new/Btn";
import Tab from "@/components/new/Tab";
import { mapState } from "vuex";

export default {
  name: "MarketProductList",
  data() {
    return {
      isNotWeekend: new Date().getDay() !== 0 && new Date().getDay() !== 6,
    };
  },
  props: {
    userId: String,
    customerMarginTop: String,
    market: Object,
    marketId: Number,
    marketName: String,
    deliveryFee: Number,
    totalPrice: Number,
    // expand: Boolean,
    products: Array,
  },
  components: {
    ProductList,
    Btn,
    Tab,
  },
  computed: {
    ...mapState({
      isProgressing: (state) => state.cart.isProgressing,
    }),
    getDeliveryType: function () {
      return this.products[0].deliveryMethod === "TB" ? "택배" : "배달";
    },
    deliveryDateInfo: function () {
      if (this.market.orderInfo?.distType === "LONG") {
        // return `<div>${this.market.orderInfo.orderTime}</div>`
        return `<div style="padding: 0 20px; font-size: 14px;">
                  <div style="display: ${this.market.orderInfo.orderTime ? "flex" : "none"}; font-weight: normal; text-align: left;">
                      <strong style="width: 70px;">주문시간</strong>
                      ${this.market.orderInfo.orderTime.replace(/\n/, "<br />")}
                  </div>
                  <div style="display: ${
                    this.market.orderInfo && this.market.orderInfo.deliveryFee ? "flex" : "none"
                  }; margin: 0 0 0px; font-weight: normal; text-align: left;">
                      <strong style="width: 70px;">배달비</strong>
                      ${this.market.orderInfo && this.market.orderInfo.deliveryFee.replace(/\n/, "<br />")}
                  </div>                </div>            `;
      } else if (this.products[0].deliveryMethod === "TB") {
        return "결제 후 2~3일 이내에 배송됩니다. (영업일 기준)";
      }
    },
  },
  methods: {
    tabClick: function (data) {
      this.$emit("orderTab", data);
    },
    setPacking() {
      this.$emit("orderTab", { dataType: "PACKING", marketId: this.marketId });
    },
    checkMarketTime() {
      const date = new Date();
      const hour = date.getHours();

      // 인천계산, 논산, 언양알프스 5시 이후 포장만 가능
      return (this.marketId === 33 || this.marketId === 45 || this.marketId === 42) && hour >= 17;
    },
  },
  mounted() {
    if ((!this.isNotWeekend || this.checkMarketTime()) && this.market.orderType === "BD") {
      this.setPacking();
    }
  },
  watch: {
    isProgressing(val) {
      if ((!this.isNotWeekend || this.checkMarketTime()) && this.market.orderType === "BD" && val === false) {
        this.setPacking();
      }
    },
  },
  created() {
    // console.log('this.market :::::::::::::::::::::::::::::: ');
    // console.log(this.market);
  },
};
</script>

<style lang="scss" scoped>
.cart_list {
  > li {
    padding: 0 16px;
    //padding: 0 16px 10px;

    &:first-child {
      margin: 0;
    }
    //border: {
    //  style: solid;
    //  color: $border-type2;
    //  width: 0 0 1px;
    //}
  }
}

.cash_delivery {
  display: inline-block;
  padding: 1px 7px 0px;
  margin: -3px 5px 0;
  background-color: $active-color;
  @include border-radius(6px);
  @include font(13, 20);
  color: white;
  vertical-align: middle;
}

.market_name {
  position: relative;
  height: 59px;
  padding: 20px 16px 0;
  background-color: $white;
  border: {
    style: solid;
    //color: $border-type1;
    color: $gray-light;
    width: 0 0 1px;
  }

  .name {
    @include font(16, 18, -0.64, bold);
    color: $black;
  }

  .absolute_right {
    top: 16px;
    right: 16px;
    @include font(14, 18, -0.64, bold);
    .accent {
      margin: 0 0 0 3px;
      color: $active-color;
    }
    .normal {
      font-weight: normal;
    }
  }
}

.order_total_info {
  .guide_txt_wrap {
    //min-height: 36px;
    padding: 0 10px 0 20px;
    background-image: url("~svg/icon_reply.svg");
    background-position: 7px 2px;
    background-repeat: no-repeat;
    color: $gray-light;
  }

  &.type_tb {
    padding: 0 0 10px;
    margin: 20px 15px 0;
    background-color: $nj_background_gray;
    @include border-radius(5px);
  }
  &.type_bd {
    > ul {
      padding: 5px 0;
      margin: 0px 15px 0;
      border: {
        style: solid;
        color: $border-type1;
        width: 0 0 1px;
      }
      li {
        padding: 0;
        color: $gray;
        .title {
          padding: 10px 0 0;
        }

        .absolute_right {
          top: 0;
          right: 0;
        }

        .price {
          font-family: "gothic_a1_medium";
        }
      }
    }
  }
  li {
    position: relative;
    padding: 0 16px;
    .title {
      display: inline-block;
      padding: 20px 0 0;
      @include font(14, 18, -0.64, bold);
      //color: $gray-light;
      &.accent {
        font-size: 16px;
        color: $active-color;
      }
    }
    .absolute_right {
      top: 10px;
      right: 16px;
      .price {
        display: inline-block;
        font-size: 18px;
        font-family: "gothic_a1_extrabold";
        vertical-align: middle;
      }
      .won {
        display: inline-block;
        margin-left: 2px;
        @include font(14, 24, -0.64);
        vertical-align: middle;
      }
      .free {
        display: inline-block;
        padding: 5px 0 0 0;
        @include font(16, 20, -0.5);
      }
    }
  }
}

.order_total_price {
  position: relative;
  padding: 0 16px;
  .title {
    display: inline-block;
    padding: 20px 0 0;
    @include font(16, 18, -0.64, bold);
  }
  .absolute_right {
    top: 10px;
    right: 16px;
    .price {
      font-family: "gothic_a1_extrabold";
      font-size: 22px;
    }
    .won {
      @include font(19, 24, -0.64);
    }
  }
  &.type1,
  &.type2 {
    .title {
      @include font(14, 18, -0.64, bold);
      color: $gray-light;
    }
    .absolute_right {
      .price {
        font-size: 18px;
      }
      .won {
        @include font(14, 24, -0.64);
      }
    }
  }
  /*&.type2 {
      .title {
        padding: 10px 0 0;
      }
      .absolute_right {
        top: 0;
      }
    }*/
}

.delivery_date_info_wrap {
  margin-top: 20px;
  padding: 0 16px;
  //line-height: 1.5;
  > .delivery_date_info {
    padding: 12px 10px;
    @include font(14, 20, -0.48);
    color: $gray-light;
    background-color: $background-color-type1;
    text-align: center;
    border-radius: 18px;
  }
}

.btn_wrap {
  padding: 20px 16px;

  .tab-wrap {
    .tab_list {
      .tab {
        &:first-child {
          a {
            border-radius: 10px 0 0 10px !important;
          }
        }
        &:nth-child(2) {
          a {
            border-radius: 0 10px 10px 0 !important;
          }
        }
      }
    }
  }
}
</style>
