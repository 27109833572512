<template>
  <div id="notice_list" class="list_wrap">
    <NoticeItem
      v-for="notice in notices"
      v-bind:key="notice.noticeId"
      :id="notice.noticeId"
      :title="notice.title"
      :content="notice.content"
      :registerDateTime="notice.registerDateTime"
    ></NoticeItem>
  </div>
</template>

<script>
import NoticeItem from "./NoticeItem";

export default {
  name: "NoticeList",
  props: {
    notices: Array,
  },
  data: function () {
    return {
      curPage: 0,
    };
  },
  components: {
    NoticeItem,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/*.list_wrap .notice_item {*/
/*  padding-top: 20px;*/
/*}*/

/*.list_wrap .notice_item > .notice_main {*/
/*  padding-bottom: 20px;*/
/*  border-bottom: 1px solid $nj_divider_gray;*/
/*}*/

/*.list_wrap .notice_item > .notice_main > * {*/
/*  display: inline-block;*/
/*}*/

/*.list_wrap .notice_item .notice_title {*/
/*  font: normal normal normal 18px/22px Noto Sans CJK KR Medium;*/
/*  letter-spacing: -1.08px;*/
/*}*/

/*.list_wrap .notice_item .notice_date {*/
/*  float: right;*/
/*  font: normal normal normal 16px/24px Noto Sans CJK KR;*/
/*  letter-spacing: -0.96px;*/
/*  color: $nj_text_main_gray;*/
/*}*/

/*.list_wrap .notice_item .notice_content {*/
/*  padding-top: 20px;*/
/*  padding-bottom: 20px;*/
/*  border-bottom: 1px solid $nj_divider_gray;*/
/*  display: none;*/
/*}*/
</style>
