<template>
  <ul class="store_list">
    <li v-for="item in list" :key="item.storeId" :data-list-id="item.storeId" :data-service-on-off="serviceOnOff">
      <div class="item">
        <div class="thumb_wrap">
          <a
            href="javascript:void(0)"
            v-if="item.representativeImageUrl"
            @click="
              $emit('goLink', {
                type: 'STORE',
                marketId: item.marketId,
                storeId: item.storeId,
                event: $event,
              })
            "
          >
            <img class="thumb" :src="item.representativeImageUrl" :alt="item.storeName" />
          </a>
          <a
            v-else
            href="javascript:void(0)"
            @click="
              $emit('goLink', {
                type: 'STORE',
                marketId: item.marketId,
                storeId: item.storeId,
                event: $event,
              })
            "
          >
            <div class="empty_store"></div>
          </a>
        </div>
        <div class="relative">
          <div class="market_name">
            <a
              href="javascript:void(0)"
              @click="
                $emit('goLink', {
                  type: 'MARKET',
                  marketId: item.marketId,
                  storeId: item.storeId,
                  event: $event,
                })
              "
            >
              {{ item.marketName }}
            </a>
          </div>
          <div class="store_name">
            <a
              href="javascript:void(0)"
              @click="
                $emit('goLink', {
                  type: 'STORE',
                  marketId: item.marketId,
                  storeId: item.storeId,
                  event: $event,
                })
              "
            >
              {{ item.storeName }}
            </a>
          </div>
          <div class="favorite_like">
            <FavoriteLike :store="item.favoriteCount" :like="item.productLikeCount" />
          </div>
        </div>
        <a
          href="javascript:void(0)"
          class="hang_up"
          @click="
            $emit('goLink', {
              type: 'HANG_UP',
              marketId: item.marketId,
              storeId: item.storeId,
              event: $event,
            })
          "
        >
          단골끊기
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import FavoriteLike from "./FavoriteLike";

export default {
  name: "StoreList",
  props: {
    list: Array,
    serviceOnOff: String,
    // goLink: Function,
  },
  components: {
    FavoriteLike,
  },
};
</script>

<style lang="scss" scoped>
.store_list {
  &.type1 {
    li:last-child {
      .item {
        border-width: 0;
      }
    }
  }

  > li {
    position: relative;
    padding: 0 20px 0 20px;

    .item {
      position: relative;
      min-height: 138px;
      padding: 20px 0 20px 136px;
      border-style: solid;
      border-color: #cecece;
      border-width: 0 0 1px;
    }

    .thumb_wrap {
      position: absolute;
      top: 20px;
      left: 0;
      width: 127px;
      height: 98px;
    }
    .thumb {
      width: 127px;
      height: 98px;
      object-fit: cover;
      @include border-radius(20px);
    }
    .empty_store {
      width: 127px;
      height: 98px;
      background-color: #e8e8e8;
      box-shadow: 0px 1px 8px #00000029;
      background-image: url("../../assets/images/icon_empty_store.svg");
      background-repeat: no-repeat;
      background-position: center;
      @include border-radius(20px);
    }
  }
  .market_name {
    @include font(16, 24, -0.96);
    color: $nj_text_main_gray;
    a {
      color: $nj_text_main_gray;
      &:hover {
        color: $nj_text_main_gray;
      }
    }
  }
  .store_name {
    margin-top: 2px;
    @include font(20, 22, -1.2);
    color: $nj_black;
    a {
      color: $nj_black;
      &:hover {
        color: $nj_black;
      }
    }
  }
  .favorite_like {
    position: absolute;
    top: 77px;
    left: 0;
  }
  .hang_up {
    position: absolute;
    top: 20px;
    right: 0;
    @include font(14, 22, -0.84);
    color: $nj_text_main_gray;
    &:hover {
      color: $nj_text_main_gray;
    }
  }
}
</style>
