<template>
  <div v-if="!product.moreContents">
    <div class="thumb_wrap">
      <a
        href="javascript:void(0)"
        @click="
          $emit('moreCallback', {
            btnId: 'PRODUCT',
            link: product.id ? product.id : product.productId,
          })
        "
      >
        <img :src="product.imageUrl + ''" class="thumb no_img" :alt="product.name" loading="lazy"/>
      </a>
      <ul class="top_right" v-if="product.like || viewCartIcon">
        <li>
          <!-- 좋아요 -->
          <Icon v-if="product.like" type="icon_like_red_small" :value="$formatPrice(product.like)" />
        </li>
        <li>
          <!-- 장바구니 -->
          <a href="javascript:void(0)" @click="$emit('moreCallback', { btnId: 'GO-CART', link: product.id })">
            <Icon v-if="viewCartIcon" type="icon_cart_white_small" :value="'장바구니 담기'" />
          </a>
        </li>
      </ul>
      <div class="origin_indicator" v-if="product.originName">
        <div class="origin_txt">
          {{ product.originName }}
        </div>
      </div>
    </div>
    <div class="mt_10">
      <span class="list_title">{{ product.name }}</span>
      <span class="discount">{{ product.discount ? $formatPrice(product.discount) : "" }}</span>
      <span class="price_area">
        <span class="price">{{ $formatPrice(product.price) }}</span>
        <span class="won">원</span>
        <span class="unit" v-if="product.unitNumber">{{ product.unitNumber + product.unitName }}</span>
      </span>
      <ul class="chip_list type1 mt_10" v-if="product.paymentTypes ? (product.paymentTypes.length ? product.paymentTypes : false) : false">
        <li class="chip" v-for="(paymentType, idx) in product.paymentTypes" :key="idx">
          {{ paymentType }}
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <div class="thumb_wrap type1">
      <a href="javascript:void(0)" class="more_icon" @click="$emit('moreCallback', { btnId: 'VIEWMORE', link: moreUrl })"> 상품더보기 </a>
    </div>
  </div>
</template>

<script>
import Icon from "../new/Icon";

export default {
  name: "ProductList",
  props: {
    product: Object,
    moreUrl: Object,
    viewCartIcon: Boolean,
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.thumb_wrap {
  position: relative;
  height: 144px;
  .top_right {
    position: absolute;
    top: 0;
    right: 0;
    //min-width: 30px;
    min-width: 56px;
    height: 38px;
    line-height: 2.3;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0 20px;

    text-align: center;

    > li {
      display: inline-block;
      &:nth-child(n + 2) {
        margin-left: 5px;
      }
    }
  }

  .origin_indicator {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 56px;
    height: 38px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px 0;
  }

  .origin_indicator .origin_txt {
    padding: 0 5px;
    @include font(14, 38, -0.84);
    color: $white;
    text-align: center;
  }

  &.type1 {
    background-color: $nj_button_gray;
    background-image: url("../../assets/images/img_empty.svg");
    background-repeat: no-repeat;
    background-position: center;
    @include border-radius(20px);
  }
  .more_icon {
    display: block;
    height: 144px;
    padding-top: 93px;
    text-align: center;
    font: normal normal medium 10px/15px Noto Sans CJK KR !important;
    font-size: 10px !important;
    letter-spacing: -0.6px;
    color: #cecece;
    &:hover {
      color: #cecece;
    }
  }
}
.thumb {
  width: 100%;
  height: 144px;
  @include border-radius(20px);
  object-fit: cover;
  color: $nj_text_main_gray;
  &.no_img {
    display: inline-block;
    //width: 221px;
    color: #000;
  }
}

.list_title {
  display: block;
  @include font(16, 22, -0.96, medium);
  color: $nj_black;
  @include text_ellipsis();
}
.discount {
  display: block;
  min-height: 25px;
  @include font(17, 25, -1.02);
  color: $nj_text_main_gray;
  text-decoration: line-through;
}
.price_area {
  display: block;
  position: relative;
  // margin-top: -4px;
  .unit {
    position: absolute;
    top: 3px;
    right: 0;
    @include font(16, 24, -0.96);
    color: $nj_text_sub_gray;
  }
}
.price {
  @include font(24, 28, -1.44, bold);
  color: $nj_black;
}
.won {
  @include font(14, 24, -0.84);
  color: $nj_text_main_gray;
}

.chip_list {
  &:not(.type1) {
    li {
      display: inline-block;
      height: 16px;
      padding: 0px 10px;
      margin-right: 2px;
      border: 1px solid #e3e3e3;
      @include border-radius(20px);
      @include font(10, 14, -0.6);
      color: $nj_text_main_gray;
      text-align: center;
    }
  }
  &.type1 {
    min-height: 47px;
    // padding: 0 0 3px;
    border-style: solid;
    border-color: #b2b2b2;
    border-width: 2px 0;
    > li {
      display: inline-block;
      margin-right: 10px;
      @include font(10, 15, -0.6);
      color: $nj_text_main_gray;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
