var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list",class:{ view_list: _vm.pageMode == 'view' ? true : false }},_vm._l((_vm.inquiryList),function(list,index){return _c('li',{key:list.id ? list.id : list.keyId,attrs:{"id":'inquiry_' + list.keyId}},[_c('div',{staticClass:"store_name"},[_vm._v(_vm._s(list.storeName))]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"list_title"},[_c('span',[_vm._v(_vm._s(list.productName)+", ")]),(list.weight)?_c('span',[_vm._v(_vm._s(_vm.$formatPrice(list.weight) + "g")+" ")]):_vm._e(),(list.unitNumber)?_c('span',[_vm._v(_vm._s(list.unitNumber)+_vm._s(list.unitName))]):_vm._e()]),_c('div',{staticClass:"list_price"},[_vm._v("주문수량 : "+_vm._s(_vm.$formatPrice(list.quantity)))]),(_vm.pageMode != 'write')?_c('div',{staticClass:"inquiry_qty"},[_vm._v("문의개수 : "+_vm._s(_vm.$formatPrice(list.inquiryQty)))]):_c('div',{staticClass:"inquiry_qty plus_minus"},[_c('div',{staticClass:"quantity_wrap"},[_c('a',{staticClass:"quantity_btn btn_minus",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('inquiryIncreaseDecrease', {
                type: 'DECREASE',
                productId: list.keyId,
                inquiryQty: list.inquiryQty,
                event: $event,
              })}}},[_vm._v("감소")]),_c('div',{staticClass:"quantity_txt"},[_vm._v(" "+_vm._s(list.inquiryQty ? list.inquiryQty : 0)+" ")]),_c('a',{staticClass:"quantity_btn btn_plus",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$emit('inquiryIncreaseDecrease', {
                type: 'INCREASE',
                productId: list.keyId,
                inquiryQty: list.inquiryQty,
                event: $event,
              })}}},[_vm._v("증가")])])])]),(list.optionGroups)?_c('ul',{staticClass:"opt_list"},_vm._l((list.optionGroups),function(optionGroup,groupIndex){return _c('li',{key:optionGroup.optionGroupId},_vm._l((optionGroup.optionsItems),function(option,index){return _c('span',{key:option.optionItemId,staticClass:"opt"},[_vm._v(" "+_vm._s(groupIndex != 0 || index != 0 ? "/" : null)+" "+_vm._s(option.optionItemName)+" "),_c('span',[_vm._v("("+_vm._s("+" + _vm.$formatPrice(Number(option.optionPrice)) + "원")+")")])])}),0)}),0):_vm._e(),(_vm.pageMode == 'view')?_c('div',[(list.message)?_c('div',{staticClass:"msg"},[_c('div',{staticClass:"message"},[_vm._v(_vm._s(list.message))])]):_vm._e(),_vm._l((list.imgUrlList),function(img,index){return _c('div',{key:list.inquiryId + '_' + index,staticClass:"img_area"},[_c('img',{attrs:{"src":img,"alt":""}})])})],2):_c('div',[_c('div',{staticClass:"targetContainer"},[_c('div',{staticClass:"item msg",class:{ disabled: list.inquiryQty > 0 ? false : true }},[_c('div',{staticClass:"contenteditable",attrs:{"contenteditable":list.inquiryQty > 0 ? true : false,"placeholder":list.inquiryQty > 0 ? '상품문의 사유를 입력해 주세요.' : '상품문의 개수를 추가해 주세요.',"data-id":list.productId,"data-product-id":list.keyId,"data-order-product-id":list.orderProductId,"data-market-id":list.marketId,"id":'contenteditable_' + list.keyId}})]),_c('div',{staticClass:"input_wrap",class:{ inactive: list.inquiryQty > 0 ? false : true }},[_c('input',{staticClass:"inquiryInput",attrs:{"type":"file","accept":"image/*","id":'fileInput_' + list.keyId,"disabled":list.inquiryQty > 0 ? false : true},on:{"change":function($event){return _vm.$emit('onChangeFileUpload', {
                type: 'FILE',
                orderProductId: list.keyId,
                event: $event,
                index: index,
              })}}}),_c('input',{attrs:{"type":"file","capture":"camera","accept":"image/*","id":'fileInput_camera_' + list.keyId},on:{"change":function($event){return _vm.$emit('onChangeFileUpload', {
                type: 'FILE',
                orderProductId: list.keyId,
                event: $event,
                index: index,
              })}}}),_c('div',{staticClass:"input_title"},[_vm._v("첨부하기")]),_c('div',{staticClass:"absolute_right"},[_c('label',{staticClass:"icon_device_camera",attrs:{"for":'fileInput_camera_' + list.keyId}},[_vm._v("카메라")]),_c('label',{staticClass:"icon_device_image ml_20",attrs:{"for":'fileInput_' + list.keyId}},[_vm._v("파일업로드")])]),_c('div',{staticClass:"mt_15",attrs:{"id":'imgPreview_' + list.keyId}})])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }