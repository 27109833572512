import { render, staticRenderFns } from "./OtherProduct.vue?vue&type=template&id=5cb20dec&scoped=true"
import script from "./OtherProduct.vue?vue&type=script&lang=js"
export * from "./OtherProduct.vue?vue&type=script&lang=js"
import style0 from "./OtherProduct.vue?vue&type=style&index=0&id=5cb20dec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb20dec",
  null
  
)

export default component.exports