<template>
  <div class="empty_market">
    <strong class="accent_txt">고객님 근처의 배달이 가능한 시장이 없어요.</strong>
    <p class="txt">
      아쉽게도 고객님 주소지 근처의 배달이 가능한 <br />시장이 없어요. 놀장이 빠르게 시장을 늘려가고 <br />있으니 조금만 더 기다려주세요. 감사합니다.
    </p>
    <div class="center mt_30">
      <router-link to="/market-list" class="btn_view_all_market">전체 시장 보기</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyDirectMarket",
  props: {},
};
</script>

<style lang="scss" scoped>
.empty_market {
  padding: 38px 20px;
  background-color: $white;
  text-align: center;
  .accent_txt {
    @include font(16, 24, 0);
    color: $black;
    letter-spacing: -0.5px;
  }
  .txt {
    margin: 11px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
  .btn_view_all_market {
    display: inline-block;
    width: 160px;
    height: 36px;
    background-color: $active-background-color;
    @include font(15, 37, 0);
    @include border-radius(20px);
    color: $white;
    &:hover {
      color: $white;
    }
  }
}
</style>
