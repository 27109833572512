import router from "@/router";

const state = {
  title: "주문상세",
  orderId: null,
  isProgressing: false,
  order: {},
  onSiteYn: null,
};

const mutations = {
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  reset: function (state) {
    state.order = {};
  },
  toggleReview: function (state) {
    state.order.hasReview = "X";
  },
  toggleInquiry: function (state) {
    state.order.hasInquiry = "X";
  },
};

const actions = {
  initState: async function (store, orderId) {
    const userId = localStorage.getItem("userId");
    orderId = orderId ? orderId : state.orderId;

    if (!orderId || !userId) {
      return false;
    }

    store.commit("updateState", { type: "orderId", updateData: orderId });

    const url = "v3/orders/members/" + userId + "/" + orderId;
    try {
      store.commit("updateState", { type: "isProgressing", updateData: true });
      const orderHistoryResult = await this._vm.$axios2.get(url);
      store.commit("updateState", { type: "isProgressing", updateData: false });
      if (orderHistoryResult.data.responseCode == 2000) {
        const order = orderHistoryResult.data.result;
        if (order.orderType == "TB") {
          if (order.orderStatus == "배달 중") {
            order.orderStatus = "배송중";
          } else if (order.orderStatus == "배달 완료") {
            order.orderStatus = "배송 완료";
          }
        }

        if (order.onSiteYn === "Y") {
          let totalDiscountPackagingPrice = 0;
          order.productList.forEach((product) => {
            if (product.productPrice > product.sellerPrice) {
              totalDiscountPackagingPrice += product.totalDiscountPackagingPrice; // discountPackagingPrice
            }
          });

          if (totalDiscountPackagingPrice) {
            order["totalPayment"].totalDiscountPackagingPrice = totalDiscountPackagingPrice;
          }
        }

        store.commit("updateState", {
          type: "order",
          updateData: order,
        });
      }
    } catch (e) {
      store.commit("updateState", { type: "isProgressing", updateData: false });
    }
  },
  reset: function (store) {
    store.commit("reset");
  },
  moveToMarket: function () {
    if (state.marketId) {
      router.push({
        name: "시장 메인",
        params: {
          marketId: state.marketId,
          marketName: state.marketName,
        },
      });
    } else {
      router.push({ name: "놀장 메인" });
    }
  },
  toggleReview: function (store) {
    store.commit("toggleReview");
  },
  toggleInquiry: function (store) {
    store.commit("toggleInquiry");
  },
};

const getters = {
  delivery: (state) => {
    if (Object.keys(state.order).length) {
      return state.order.delivery ? state.order.delivery : {};
    } else {
      return {};
    }
  },
  totalPayment: (state) => {
    if (Object.keys(state.order).length) {
      return state.order.totalPayment ? state.order.totalPayment : {};
    } else {
      return {};
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
