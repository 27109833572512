const state = {
  title: "전체시장 리스트",
  deliveryType: "", //
  latitude: null,
  longitude: null,
  marketType: "", // 전통시장 : DIRECT, 특화시장 : ALL, 주변시장 : NORMAL
  marketTypeRadioOption: [
    // BD : 전통시장, TB : 특화시장, NM : 일반
    {
      code: "BD",
      text: "전통시장",
    },
    {
      code: "TB",
      text: "특화시장",
    },
    {
      code: "NM",
      text: "주변시장",
    },
  ],
  deliverySelectType: "ALL", // 전체 : ALL, 배달가능 : Y, 배달불가 : N
  sortType: "DISTANCE", // 거리순 : DISTANCE, 이름 : NAME
  marketList: [],
  displayLayer: false,

  selectList: [
    {
      id: "marketType",
      title: "시장 유형 선택",
      value: "전통시장 시장",
    },
    // 추후 사용할 여지가 있음
    // {
    //     id: 'deliverySelectType',
    //     title: '배달 가능 여부 선택',
    //     value: '전체',
    // },
    // {
    //     id: 'sortType',
    //     title: '정렬 선택',
    //     value: '거리순',
    // },
  ],
  displayGuideLayer: false,
};

const mutations = {
  marketMap: async function (state, data) {
    // console.log('시장 지도 이동   ' + data.event.currentTarget.dataset.marketId);
    this._vm.$callNative("moveToMarketMap", {
      marketId: data.event.currentTarget.dataset.marketId,
    });
  },
  goMarket: function (state, event) {
    var eventData = event.currentTarget.dataset;
    if (eventData.openYn == "Y") {
      this._vm.$callNative("moveToMarket", {
        marketId: eventData.marketId,
        marketName: eventData.marketName,
      });
    } else {
      this.commit("market/showHideGuideLayer");
    }
  },
  showHideGuideLayer: function (state) {
    state.displayGuideLayer = !state.displayGuideLayer;
  },
  showHideRadioPop: function (state) {
    state.displayLayer = !state.displayLayer;
  },
  updateStateType: function (state, data) {
    // BD : 바로배송, TB : 전국배송, NM : 일반
    // console.log(data);
    if (data.type == "marketType") {
      // radio 활성화 선택 기능
      state.marketTypeRadioOption = state.marketTypeRadioOption.map((opt) => {
        opt.active = false;
        if (opt.code == data.code) {
          opt.active = true;
          // state.marketTypeActionString = opt.text;
        }
        return opt;
      });

      // console.log('data.code ::: ------------- ' + data.code);
      state.marketType = data.code;
      // console.log(state.marketType);

      // select 활성화 텍스트 변경
      state.selectList = state.selectList.map((list) => {
        if (list.id == data.type) {
          // console.log(data);
          list.value = data.text;
        }
        return list;
      });

      if (!data.initSetting) {
        this.commit("market/showHideRadioPop");
      }
    }
    state[data.type] = data.value;
  },
  initState: function (state, data) {
    state["marketList"] = data.updateData.marketList;
    this.commit("market/updateStateType", {
      code: data.updateData.selectData.code,
      type: data.updateData.selectData.type,
      text: data.updateData.selectData.text,
      initSetting: true,
    });
  },
  goBack: function (state, vueContainer) {
    // this.commit('cartShip/testFunc');// 테스트 코드
    vueContainer.$closeView();
  },
};

const actions = {
  selectCallback: function (store, data) {
    // console.log(data);
    if (data) {
      if (data.btnId == "marketType") {
        // console.log(data);
        store.commit("showHideRadioPop");
      }
    }
  },
  changeRadio: function (store, event) {
    // console.log(event.currentTarget.value);
    // console.log(event.currentTarget.id);
    // console.log(event.currentTarget.dataset.text);
    // store.commit('updateStateType', {
    //     code: event.currentTarget.value,
    //     type: 'marketType',
    //     text: event.currentTarget.dataset.text,
    // });
    this.dispatch("market/initState", {
      deliveryType: event.currentTarget.value,
    });
  },
  initState: function (store, data) {
    // console.log('initState');
    // console.log(data);

    if (!state.latitude || !state.longitude) {
      state.latitude = data.latitude;
      state.longitude = data.longitude;
    }

    var selectCode = data.deliveryType;
    var selectType = "marketType";
    var selectText = "전통시장";
    if (selectCode == "BD") {
      selectText = "전통시장";
    } else if (selectCode == "TB") {
      selectText = "특화시장";
    } else if (selectCode == "NM") {
      selectText = "주변시장";
    }
    const axiosUrl = "/v3/market/user/" + state.latitude + "/" + state.longitude + "/markets?deliveryType=" + selectCode;
    this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        if (response.data.responseCode == 2000) {
          // console.log(response.data);
          const updateData = {
            selectData: {
              code: selectCode,
              type: selectType,
              text: selectText,
              initSetting: true,
            },
            marketList: response.data.result.marketList,
          };

          store.commit("initState", {
            updateData: updateData,
          });

          state.displayLayer = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
