<template>
  <div>
    <LoadingIndicator v-if="isProgressing" />
    <TopHeader :title="title" @goBack="goBack" />
    <div class="contents">
      <div class="relative">
        <img src="~img/recommend_01.png" />
        <span class="event_date">{{ $moment(eventInfo.startDateTime).format("YYYY.M.D") }} ~ 소진시까지</span>
        <div class="txt_area">
          <p class="txt1">지금 친구에게 놀장을 추천해주세요</p>
          <p class="txt2" v-if="applicationType == 'AFTER_ORDER'">
            <span v-if="rewardPoint == recipientPoint"> 추천받은 친구가 첫 주문을 하였을 시 각 {{ $formatPrice(rewardPoint) }}원을 드립니다 </span>
            <span v-else>
              추천받은 친구가 첫 주문 시 추천인은 {{ $formatPrice(rewardPoint) }}원을 드리고 추천받은 친구는 {{ $formatPrice(recipientPoint) }}원을
              드립니다
            </span>
          </p>
          <p class="txt2" v-else>추천받은 친구가 내 번호 입력 시 {{ $formatPrice(rewardPoint) }}원을 드립니다</p>
        </div>
        <div class="recommend_point mt_30" :class="{ after_order: applicationType == 'AFTER_ORDER' }">
          <div class="unit_container">
            <div class="unit">
              <div class="point">
                포인트<br /><span class="point_number">{{ $formatPrice(rewardPoint) }}P</span>
              </div>
            </div>
            <div class="unit_bottom" v-if="applicationType == 'AFTER_ORDER'">
              <span>신규가입자가 내 전화번호 입력 후 </span><br />
              <strong>첫 주문을 하였을 때</strong>
            </div>
            <div class="unit_bottom" v-else>
              <span>친구추천 후 </span><br />
              <strong>친구가 번호입력 시</strong>
            </div>
          </div>
          <div class="unit_container type_right">
            <div class="unit">
              <div class="point">
                포인트<br /><span class="point_number">{{ $formatPrice(recipientPoint) }}P</span>
              </div>
            </div>
            <div class="unit_bottom">
              <span>놀장가입 후 </span><br />
              <strong v-if="applicationType == 'AFTER_ORDER'">첫 주문을 하였을 때</strong>
              <strong v-else>추천인 전화번호 입력 시</strong>
            </div>
          </div>
        </div>
        <img src="~img/recommend_02.png" />

        <div>
          <div class="auth_container" v-if="userId">
            <!-- 이벤트 적용 가능한 가입자 -->
            <div class="step_1" v-if="eventCode == 2000">
              <p><strong>추천받고 오셨나요?</strong></p>
              <p class="mt_5">추천인 친구 전화번호를 입력 후 <br />포인트를 받으세요!</p>
              <div class="input_area">
                <input type="text" maxlength="11" title="추천인 전화번호 입력" id="inputPhoneNumber" @keyup="changePhoneNumber($event)" />
                <a href="javascript:void(0)" @click="sendPhoneNumber">입력하기</a>
              </div>
            </div>

            <!-- 이벤트 이미 적용된 가입자 -->
            <div class="step_1 icon_checked" v-if="eventCode == 10440">
              <p><strong>이벤트에 참여하였습니다.</strong></p>
              <!--              <p><router-link to="/my-page">마이페이지</router-link>에서 적립된 포인트를 확인하세요.</p>-->
              <p>마이페이지에서 적립된 포인트를 확인하세요.</p>
              <p class="mt_20 bold" v-if="applicationType == 'AFTER_ORDER'">
                이제 나도 친구초대를 할 수 있어요. <br />
                추천받은 친구가 내 번호를 입력 후 첫 주문 시 <span>{{ $formatPrice(rewardPoint) }}원</span>을 드립니다.
              </p>
              <p class="mt_20 bold" v-else>
                이제 나도 친구초대를 할 수 있어요. <br />
                추천받은 친구가 내 번호 입력 시 <span>{{ $formatPrice(rewardPoint) }}원</span>을 드립니다.
              </p>
            </div>

            <!-- 이벤트 시작전 가입자 -->
            <div class="step_1" v-if="eventCode == 10445">
              <p><strong>지금 친구에게 놀장을 추천해주세요.</strong></p>
              <p class="mt_5 mb_5" v-if="applicationType == 'AFTER_ORDER'">추천받은 친구가 내 번호를 입력 후 첫 주문 시!</p>
              <p v-if="applicationType == 'AFTER_ORDER'">
                <span v-if="rewardPoint == recipientPoint">
                  추천인과 친구에게 각 <span>{{ $formatPrice(rewardPoint) }}원</span>을 드립니다.
                </span>
                <span v-else>
                  추천인은 {{ $formatPrice(rewardPoint) }}원을 드리고 추천받은 친구는 {{ $formatPrice(recipientPoint) }}원을 드립니다
                </span>
              </p>
              <p v-else>
                추천받은 친구가 내 번호 입력 시 <span>{{ $formatPrice(rewardPoint) }}원</span>을 드립니다.
              </p>
            </div>
            <div>
              <div class="btn_area">
                <Btn type="type_kakao" value="친구에게 놀장 추천하기" @btnCallback="shareAppWithKakao" />
              </div>
            </div>
          </div>

          <div class="auth_container" v-else>
            <p>이벤트에 참여하시려면 <br />먼저 본인인증이 필요합니다.</p>
            <div class="btn_area">
              <Btn type="type5" value="본인인증하기" @btnCallback="callAuthentication" />
            </div>
          </div>
        </div>

        <div class="guide_container">
          <div class="guide_title">필독! 유의사항</div>
          <div>
            <ul class="guide_txt_list mt_10">
              <li v-if="applicationType == 'AFTER_ORDER'"><span>-</span> 추천인이 받는 포인트는 추천 횟수에 제한없이 적립해 드립니다.</li>
              <li v-if="applicationType == 'AFTER_ORDER'">
                <span>-</span> 추천인 포인트는 추천 받은 분(친구)이 추천인의 전화번호 입력 후 첫 주문을 했을 시 바로 지급 됩니다.
              </li>
              <li v-else><span>-</span> 추천인 포인트는 추천받은분이 추천인의 전화번호 입력 시 바로 지급됩니다.</li>
              <li><span>-</span> 추천인 포인트 수령 후 {{ eventInfo.eventPointUsagePeriodDays }}일 이내 미사용 시 포인트가 소멸됩니다.</li>
              <li><span>-</span> 본 이벤트는 예산 소진 시 종료됩니다.</li>
              <li v-if="eventInfo.recommendEventMarketApplicationType != 'BUCHEON'">
                <span>-</span> 지급된 포인트는 해당 시장에서만 사용 가능합니다.
              </li>
              <li v-if="eventInfo.recommendEventMarketApplicationType != 'BUCHEON'">
                <span>-</span> 시장포인트로 구매한 상품 환불 시, 환불된 포인트는 {{ eventInfo.eventPointUsagePeriodDays }}일뒤 소멸됩니다.
              </li>
              <li><span>-</span> 공지를 확인하지 않아 생기는 불이익은 놀장에서 책임지지 않습니다.</li>
              <li><span>-</span> 본 이벤트 기간 중 문의사항은 고객센터로 문의 바랍니다. (평일 10:00 ~ 18:30)</li>
            </ul>
          </div>
        </div>
        <div class="guide_bottom">
          <ul>
            <li>
              <a href="javascript:void(0)" @click="goCall(customerServiceCenterNumber)">
                <span class="title">고객센터</span>
                <span class="vertical_line">|</span>
                02.2039.6742
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="goKakaoTalk">
                <span class="title">카카오톡</span>
                <span class="vertical_line">|</span>
                @NOLJANG
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/new/LoadingIndicator";
import TopHeader from "@/components/new/TopHeader";
import Btn from "@/components/new/Btn";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "RecommendMember",
  components: {
    TopHeader,
    LoadingIndicator,
    Btn,
  },
  computed: {
    ...mapState({
      isProgressing: (state) => state.recommendMember.isProgressing,
      title: (state) => state.recommendMember.title,
      userId: (state) => state.recommendMember.userId,
      rewardPoint: (state) => state.recommendMember.rewardPoint, // 추천한 나
      recipientPoint: (state) => state.recommendMember.recipientPoint, // 추천받은 선수
      eventCode: (state) => state.recommendMember.eventCode,
      eventInfo: (state) => state.recommendMember.eventInfo,
      applicationType: (state) => state.recommendMember.applicationType,
      customerServiceCenterNumber: (state) => state.customerServiceCenterNumber,
    }),
  },
  methods: {
    ...mapMutations("recommendMember", ["shareAppWithKakao", "callAuthentication", "goCall", "goKakaoTalk", "updateState"]),
    ...mapActions("recommendMember", ["initState", "goBack", "sendPhoneNumber", "changePhoneNumber"]),
    authResult(jsonString) {
      this.test = jsonString;
      const result = JSON.parse(jsonString);
      const userId = result.userId;
      this.$store.dispatch("initUserId", { userId: userId });
      // this.updateState({ type: "userId", updateData: userId });
      this.initState({ userId: userId });
    },
  },
  mounted() {
    window.authResult = this.authResult;
  },
  beforeCreate: function () {
    // console.log(this.$route.params);
    this.$store.dispatch("recommendMember/initState", {
      userId: this.$route.params.userId ? this.$route.params.userId : localStorage.getItem("userId") ? localStorage.getItem("userId") : null,
      params: this.$route.params,
    });
  },
  beforeDestroy() {
    // this.reset();
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.event_date {
  position: absolute;
  top: 20px;
  right: 30px;
  @include font(18, 24, -0.5, bold);
  color: rgb(255, 255, 255);
  z-index: 999;
}

.txt_area {
  margin: 20px 0 0;
  padding: 0 10px;
}

.txt1,
.txt2 {
  line-height: 26px;
  font-family: "gothic_a1_bold";
  font-size: 18px;
  text-align: center;
}
.txt2 {
  color: $active_color;
}
.guide_bottom {
  background-color: #101010;
  li {
    display: inline-block;
    width: 50%;
    padding: 15px 0 15px 10px;
    font-size: 13px;
    a {
      color: #d0d0d0;
    }

    &:nth-child(even) {
      padding: 15px 10px 15px 0;
      text-align: right;
    }
    .title {
      font-size: 12px;
      color: #ffd93f;
    }
    .vertical_line {
      margin: 0 1px 0 3px;
      color: #888;
    }
  }
}
.guide_container {
  padding: 30px 16px;
  background-color: #222;
  @include font(14, 20, -0.5);
  color: #888;
  .guide_title {
    @include font(20, 30, -1.8);
    color: $active-color;
  }
  .guide_txt_list {
    > li {
      line-height: 26px;
      padding: 0 0 5px 10px;
      text-indent: -10px;
      * {
        line-height: 26px;
        color: $active-color;
      }
    }
  }
}

.auth_container {
  padding: 20px 10px 0;
  background-color: #3bc8da;
  font-family: "gothic_a1_semibold";
  font-size: 20px;
  color: #000;
  text-align: center;
  p {
    line-height: 26px;
  }
  .btn_area {
    max-width: 320px;
    padding: 0 10px 40px;
    margin: 25px auto 0;
  }
  .step_1 {
    &.icon_checked {
      padding: 70px 0 0;
    }

    p {
      line-height: 20px;
      font-size: 16px;
      letter-spacing: -0.5px;

      &.bold {
        font-family: "gothic_a1_extrabold";
      }
      strong {
        font-family: "gothic_a1_black";
        font-size: 16px;
      }
      a {
        color: #000;
        text-decoration: underline !important;
      }
    }
    .input_area {
      position: relative;
      max-width: 330px;
      padding: 0 100px 0 15px;
      margin: 20px auto 0;
      a {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 15px;
        min-width: 85px;
        height: 50px;
        padding: 12px 10px 0;
        background-color: $active-color;
        font-size: 16px;
        color: #fff;
      }
    }
    input[type="text"] {
      width: 100%;
      height: 50px;
      padding: 3px 0 0 10px;
      border-color: #e0e0e0;
      border-width: 1px;
      font-size: 18px;
    }
  }
}

.recommend_point {
  padding: 0 5%;
  margin: 30px 0;
  text-align: center;

  .unit_container {
    display: inline-block;
    width: 50%;
    padding: 0 10px 0 0;
    vertical-align: top;
    &.type_right {
      padding: 0 0 30px 10px;
      .unit {
        background-color: #39cdcc;
        background-image: url("~img/recommend_04.png");
      }
    }
    .unit {
      min-height: 70px;
      padding: 55px 0 0;
      background-color: #781bbd;
      background-image: url("~img/recommend_03.png");
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center 20px;
      border-radius: 20px 20px 0 0;
      .point {
        width: 90%;
        max-width: 150px;
        min-height: 75px;
        line-height: 12px;
        padding: 12px 0 0;
        margin: 0 auto;
        background-image: url("~img/recommend_05.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center 0;
        font-family: "gothic_a1_medium";
        font-size: 13px;
        .point_number {
          font-family: "gothic_a1_extrabold";
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.5px;
        }
      }
    }
    .unit_bottom {
      min-height: 50px;
      line-height: 16px;
      padding: 10px;
      background-color: #fff;
      border-style: solid;
      border-color: #e9e9e9;
      border-width: 0 1px 1px 1px;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 0px 12px -1px #aaaaaa8f;
      font-family: "gothic_a1_medium";
      font-size: 12px;
      letter-spacing: -1.5px;
      * {
        line-height: 16px;
      }
    }
  }
  &.after_order {
    .unit_bottom {
      min-height: 70px;
      font-size: 12px;
    }
  }
}

.ios {
  .recommend_point {
    .unit_container {
      .unit {
        .point {
          line-height: 12px;
          padding: 10px 0 0;
          margin: 0 auto;
          font-size: 13px;
          .point_number {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media (min-width: 320px) {
  .auth_container {
    .step_1 {
      p {
        font-size: 16px;
        strong {
          font-size: 18px;
        }
      }
    }
  }

  .recommend_point {
    .unit_container {
      .unit {
        .point {
          padding: 15px 0 0;
          font-size: 13px;
          .point_number {
            font-size: 22px;
            line-height: 34px;
          }
        }
      }
      .unit_bottom {
        font-size: 14px;
        * {
          line-height: 20px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        min-height: 81px;
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 350px) {
  .auth_container {
    font-size: 22px;
    p {
      line-height: 28px;
    }

    .step_1 {
      p {
        font-size: 17px;
        strong {
          font-size: 19px;
        }
      }
    }
  }

  .recommend_point {
    .unit_container {
      .unit {
        padding: 60px 0 10px;
        background-size: 70%;
        .point {
          padding: 15px 0 0;
          font-size: 14px;
          .point_number {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }
      .unit_bottom {
        font-size: 15px;
        * {
          line-height: 20px;
        }
      }
    }
    &.after_order {
      .unit_bottom {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 370px) {
  .guide_bottom {
    li {
      font-size: 14px;
      .title {
        font-size: 13px;
      }
    }
  }

  .guide_container {
    padding: 40px 16px;
    @include font(16, 20, -0.5);
    .guide_title {
      @include font(20, 28, -1.8);
    }
    .guide_txt_list {
      > li {
        line-height: 26px;
        * {
          line-height: 26px;
        }
      }
    }
  }

  .recommend_point {
    .unit_container {
      .unit {
        .point {
          .point_number {
            font-size: 26px;
            line-height: 40px;
          }
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 13px;
      }
    }
  }
}
@media (min-width: 400px) {
  .guide_bottom {
    li {
      font-size: 15px;
      .title {
        font-size: 14px;
      }
    }
  }
  .auth_container {
    font-size: 24px;
    p {
      line-height: 30px;
    }
    .step_1 {
      p {
        font-size: 18px;
        strong {
          font-size: 20px;
        }
      }
    }
  }

  .recommend_point {
    .unit_container {
      .unit {
        background-size: 67%;
        .point {
          .point_number {
            line-height: 40px;
          }
        }
      }
      .unit_bottom {
        font-size: 16px;
        * {
          line-height: 22px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        min-height: 87px;
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 460px) {
  .guide_bottom {
    li {
      font-size: 16px;
      .title {
        font-size: 15px;
      }
    }
  }
  .recommend_point {
    .unit_container {
      .unit {
        padding: 70px 0 20px;
        background-size: 65%;
        .point {
          .point_number {
            line-height: 40px;
          }
        }
      }
      .unit_bottom {
        font-size: 17px;
        * {
          line-height: 22px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 500px) {
  .auth_container {
    font-size: 26px;
    p {
      line-height: 32px;
    }
    .step_1 {
      p {
        font-size: 19px;
        strong {
          font-size: 21px;
        }
      }
    }
  }
  .recommend_point {
    .unit_container {
      .unit {
        background-size: 63%;
        .point {
          .point_number {
            line-height: 40px;
          }
        }
      }
      .unit_bottom {
        font-size: 19px;
        * {
          line-height: 26px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 540px) {
  .recommend_point {
    .unit_container {
      .unit {
        background-size: 60%;
      }
      .unit_bottom {
        font-size: 20px;
        * {
          line-height: 28px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 600px) {
  .guide_container {
    padding: 40px 16px;
    @include font(18, 25, -0.5);
    .guide_title {
      @include font(26, 36, -1.8);
    }
    .guide_txt_list {
      > li {
        line-height: 34px;
        * {
          line-height: 34px;
        }
      }
    }
  }

  .auth_container {
    font-size: 28px;
    p {
      line-height: 35px;
    }
    .step_1 {
      p {
        font-size: 20px;
        strong {
          font-size: 22px;
        }
      }
    }
  }
  .recommend_point {
    .unit_container {
      .unit {
        background-size: 58%;
      }
      .unit_bottom {
        font-size: 22px;
        * {
          line-height: 30px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 660px) {
  .recommend_point {
    .unit_container {
      .unit {
        background-size: 56%;
      }
      .unit_bottom {
        font-size: 24px;
        * {
          line-height: 30px;
        }
      }
    }

    &.after_order {
      .unit_bottom {
        font-size: 14px;
      }
    }
  }
}
</style>
