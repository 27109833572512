<template>
  <div class="page_wrap">
    <TopHeader @goBack="goBack" :title="title" :isButton="currentComp == 'DeliveryAddrList' && isAddressRequired ? false : true">
      <template v-slot:btn_back_icon>
        <img v-if="currentComp == 'DeliveryAddrList'" src="@/assets/images/Xbtn.png" />
        <img v-else src="@/assets/images/btn_back.png" />
      </template>
    </TopHeader>
    <div class="content">
      <keep-alive>
        <!--        리스트 컴포넌트의 경우 리렌더링 X (검색 내용 및 결과 유지 목적) -->
        <component v-if="currentComp == 'DeliveryAddrList'" :is="currentComp"></component>
      </keep-alive>
      <component
        v-if="currentComp == 'DeliveryAddrDetail'"
        :is="currentComp"
        :shipToDoro="selectedSearchAddress.shipToDoro"
        :shipToJibun="selectedSearchAddress.shipToJibun"
        :showHideLayer="showHideAlertLayer"
      ></component>
    </div>

    <Alert
      :displayLayer="displayAlertLayer"
      :texts="['상세 주소는 필수 입력 항목입니다.']"
      :textClass="[]"
      :btns="[
        {
          type: null,
          btnId: '',
          btnData: {},
          value: '확인',
        },
      ]"
      :showHideLayer="showHideAlertLayer"
    />
    <Alert
      :displayLayer="displayDeleteAlertLayer"
      :texts="['해당 주소를 삭제하시겠습니까?']"
      :textClass="[]"
      :btns="[
        {
          type: null,
          btnId: '',
          btnData: {},
          value: '취소 ',
        },
        {
          type: null,
          btnId: 'DELETE',
          btnData: {},
          value: '삭제',
        },
      ]"
      :showHideLayer="showHideDeleteAlertLayer"
    />
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import DeliveryAddrList from "./components/DeliveryAddrList";
import DeliveryAddrDetail from "./components/DeliveryAddrDetail";
import Alert from "@/components/Alert";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "DeliveryAddr",
  props: {},
  data: function () {
    return {};
  },
  components: {
    TopHeader,
    DeliveryAddrList,
    DeliveryAddrDetail,
    Alert,
  },
  methods: {
    ...mapMutations("deliveryAddr", ["showHideAlertLayer"]),
    ...mapActions("deliveryAddr", ["selectSearchAddress", "showHideDeleteAlertLayer"]),
    goBack: function () {
      // 배송지 상세 페이지인 경우, 리스트로 이동
      if (this.currentComp == "DeliveryAddrDetail") {
        this.selectSearchAddress({});
      }
      // 웹뷰 종료
      else if (!this.isAddressRequired) {
        this.$closeView();
      }
    },
    getCurDeliveryAddrIsEmpty: function () {
      return Object.keys(this.selectedSearchAddress).length ? false : true;
    },
    initParams: function (jsonObject) {
      if (!jsonObject) {
        return false;
      }

      const data = JSON.parse(jsonObject);
      this.$store.dispatch("deliveryAddr/initState", {
        userId: data.userId,
        shipToDoro: data.shipToDoro,
        shipToDetail: data.shipToDetail,
        shipToName: data.shipToName,
      });
    },
    onBackPressed: function () {
      this.goBack();
    },
  },
  mounted() {
    window.initParams = this.initParams;
    window.onBackPressed = this.onBackPressed;
  },
  computed: {
    ...mapState("deliveryAddr", ["title", "currentAddress", "selectedSearchAddress", "displayAlertLayer", "displayDeleteAlertLayer"]),
    ...mapGetters({
      isAddressRequired: "deliveryAddr/isAddressRequired",
    }),
    currentComp: function () {
      return this.getCurDeliveryAddrIsEmpty() ? "DeliveryAddrList" : "DeliveryAddrDetail"; // 현재 표시되는 컴포넌트
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped src="./DeliveryAddr.scss"></style>
