<template>
  <!--  내 프로필 정보 영역입니다. (이름, 전화번호) -->
  <!--  userId 없을 경우 본인확인 요청 문구 표시 -->
  <div class="user_info_card">
    <div class="user_setting_wrap" @click="goSetting">
      <a href="javascript:void(0)">
        <SettingButton></SettingButton>
      </a>
    </div>
    <template v-if="userId">
      <div class="user_name">
        {{ userInfo.memberName }}
      </div>
      <div class="user_tel">
        {{ userInfo.phoneNumber }}
      </div>
    </template>
    <template v-else>
      <span class="ident_txt">본인인증이 필요합니다.</span>
      <a class="ident_btn" href="javascript:void(0)" @click="goIdent">본인인증하기 <span class="ident_btn_ico"></span></a>
    </template>
  </div>
</template>

<script>
import SettingButton from "@/components/SettingButton";
export default {
  name: "MyProfile",
  components: { SettingButton },
  props: {},
  data: function () {
    return {
      userId: this.$route.query.userId ? this.$route.query.userId : null,
      userInfo: {},
    };
  },
  methods: {
    goSetting: function () {
      this.$callNative("showSetting");
    },
    goIdent: function () {
      this.$callNative("showIdentifyVerification");
    },
    getUserInfo: async function () {
      if (!this.userId) {
        return false;
      }

      const userResponse = await this.$axios1.get("/v3/user/member/information/" + this.userId + "/personal");
      if (userResponse.data.responseCode == 2000) {
        this.userInfo = userResponse.data.result;
      }
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.user_info_card {
  //position: absolute;
  //top: -60px;
  position: fixed;
  top: 45px;
  width: calc(100% - 40px);
  height: 120px;
  padding: 30px 20px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
  z-index: 100;

  .user_name {
    @include font(20, 29, -1.2, medium);
    color: $nj_black;
  }

  .user_tel {
    margin-top: 7px;
    @include font(16, 24, -0.32);
    color: $nj_text_main_gray;
  }

  .ident_txt {
    display: block;
    @include font(20, 29, -1.2, medium);
    color: $nj_black;
  }

  .ident_btn {
    display: block;
    margin-top: 7px;
    @include font(16, 24, -0.96);
    color: $nj_text_main_gray;

    &:hover {
      color: $nj_text_main_gray;
      text-decoration: none;
    }

    .ident_btn_ico {
      display: inline-block;
      width: 7px;
      height: 24px;
      margin-left: 20px;
      background-image: url("../../../assets/images/arrow_small.png");
      background-size: unset;
      background-repeat: no-repeat;
      background-position: left center;
      vertical-align: top;
    }
  }

  .user_setting_wrap {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
}
</style>
