<template>
  <div class="tab_header">
    <div
      class="tab_item"
      v-for="tabItem in tabList"
      v-bind:key="tabItem.id"
      :class="{ active: isActive(tabItem.id) }"
      @click="$emit('click', tabItem.id)"
      :style="tabWidth"
    >
      {{ tabItem.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreProductTab",
  props: {
    currentTabId: Number,
    tabList: Array,
  },
  data: function () {
    return {};
  },
  components: {},
  methods: {
    isActive: function (id) {
      return this.currentTabId == id;
    },
  },
  computed: {
    isStoreCardShow: function () {
      return this.currentTabId == 1;
    },
    tabWidth: function () {
      return {
        "--tab-width": 100 / this.tabList.length + "%",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_header {
  height: 45px;
}

.tab_item {
  width: var(--tab-width);
  float: left;
  text-align: center;
  @include font(12, 20, -0.84);
  color: $nj_text_main_gray;
  padding-top: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid $nj_divider_gray;
  height: 45px;
}

.tab_item.active {
  //font: normal normal normal 14px/20px Noto Sans CJK KR Bold;
  @include font(12, 20, -0.84, bold);
  border-bottom: 4px solid $nj_black;
  color: $nj_black;
}
</style>
