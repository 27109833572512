<template>
  <ul class="ignore_touch_x" :class="{ vertical_scroll: !appVersion }" ref="storeList">
    <li v-for="store in stores" v-bind:key="store.marketId + '_' + store.storeId">
      <a
        href="javascript:void(0);"
        :data-store-id="store.storeId"
        :data-store-name="store.storeName"
        :data-store-tel="store.storeTel"
        :data-market-id="store.marketId"
        :data-market-name="store.marketName"
        :data-regular-yn="store.regularYn == 'Y' || regularBoolean == true ? 'Y' : 'N'"
        @click="clickEvent"
      >
        <!--      좌측 썸네일 -->
        <div class="thumb">
          <img :src="store.representativeImageUrl" alt="" v-if="store.representativeImageUrl" />
          <span class="regular_indicator" v-if="store.regularYn == 'Y' || regularBoolean == true">단골</span>
        </div>
        <!--      우측 내용-->
        <div class="store_info">
          <span class="store_name" v-html="store.storeName" />
          <span class="market_name" v-if="!marketId && displayMarketName == true" v-html="store.marketName" />
          <span class="store_desc">{{ store.storeIntro }}</span>
          <div class="store_regular_wrap" v-if="store.favoriteCount">
            <div class="regular_icon">
              단골고객
              <span class="regular_count">{{ store.favoriteCount }}</span>
            </div>
          </div>
          <div class="store_status_wrap">
            <span class="store_status active" v-if="store.storeOpenYn == 'Y'"> <span class="on_off">ON</span>영업중 </span>
            <span class="store_status" v-else> 준비중 </span>
            <span v-if="appVersion" class="store_call_btn" :data-store-tel="store.storeTel">전화걸기</span>
          </div>
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StoreList",
  props: {
    stores: Array,
    marketId: Number,
    regularBoolean: Boolean,
    displayMarketName: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    goCall: function (tel) {
      this.$callNative("goCall", { tel: tel });
    },
    clickEvent: function (event) {
      const keyword = "store_call_btn";
      const classList = event.target.classList;
      // console.log(classList);
      // console.log(classList.contains(keyword));
      if (classList.contains(keyword)) {
        // 네이티브 > 전화 걸기 기능 호출
        this.$callNative("goCall", {
          tel: event.currentTarget.dataset.storeTel,
        });
      } else {
        // 상점 메인으로 이동
        this.$router.push({
          name: "상점 메인",
          params: {
            marketId: event.currentTarget.dataset.marketId,
            marketName: event.currentTarget.dataset.marketName,
            storeId: event.currentTarget.dataset.storeId,
            storeName: event.currentTarget.dataset.storeName,
            regularYn: event.currentTarget.dataset.regularYn,
          },
        });
      }
    },
  },
  watch: {
    stores: function () {
      this.$refs.storeList.scrollTo({ left: 0 });
    },
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.ignore_touch_x {
  display: inline-block;
  max-width: 100%;
  min-width: calc(100% - 1px);
}
</style>
