<template>
  <div class="wrap" v-if="productId">
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <div v-else class="relative">
      <!--    찜 토글 영역-->
      <div class="toggle_like active" v-if="formatLikeCount">
        <div class="like_count">{{ formatLikeCount }}</div>
      </div>

      <!--      <div class="toggle_like" :class="{active: isLikeStore}" >-->
      <!--        <img src="@/assets/images/S_ico_01_active.png" v-if="isLikeStore"/>-->
      <!--        <img src="@/assets/images/S_ico_01.png" v-else>-->
      <!--        <div>{{formatLikeCount}}</div>-->
      <!--      </div>-->

      <!--    상단 이미지 캐러셀 영역-->
      <ProductImgCarousel :images="product.productImageList" ref="carousel"></ProductImgCarousel>

      <!--    상품 정보 영역-->
      <ProductInfo
        @clickRegular="toggleLayer"
        :name="product.productName"
        :marketName="product.marketName"
        :price="product.price"
        :discountPrice="product.discountPrice"
        :originName="product.originName"
        :paymentTypes="product.paymentTypeList"
        :unitNumber="product.unitNumber"
        :unitName="product.unitName"
        :isRegularStore="isRegularStore"
        :favoriteCount="product.favoriteCount"
        :businessCall="businessInfo.businessCall"
      />

      <!--    이 가게의 다른 상품 영역-->
      <OtherProduct :marketId="product.marketId" :storeId="product.storeId" :storeProducts="storeProducts"></OtherProduct>

      <!--    상세 정보 및 필수표기정보 영역-->
      <ProductDetailTab :description="product.description" :notationalInformations="product.notationalInformationList"></ProductDetailTab>
      <!--    하단 장바구니 담기 버튼 영역-->
      <div class="btn_wrap">
        <div class="btn_add_cart" @click="moveToProductOption">장바구니 담기</div>
      </div>
    </div>
    <Layer :displayLayer="displayLayer" displayType="center" :closeLayer="toggleLayer">
      <template v-slot:content>
        <div class="layer_contents">
          <div class="layer_title">
            {{ regularInfo.regularYn == "Y" ? "단골끊기" : "단골맺기" }}
          </div>
          <p class="layer_txt">
            해당 상점에 단골을
            {{ regularInfo.regularYn == "Y" ? "끊으시겠습니까" : "맺으시겠습니까" }}
            ?
          </p>
        </div>
        <ul class="half_list type1 mt_10">
          <li>
            <Btn type="type1 none_border" value="취소" @btnCallback="toggleLayer" />
          </li>
          <li>
            <Btn type="type1 reverse" btnId="REGULAR" :value="regularInfo.regularYn == 'Y' ? '단골끊기' : '단골맺기'" @btnCallback="toggleRegular" />
          </li>
        </ul>
      </template>
    </Layer>
  </div>
</template>

<script>
import ProductImgCarousel from "./components/ProductImgCarousel";
import ProductInfo from "./components/ProductInfo";
import OtherProduct from "./components/OtherProduct";
import ProductDetailTab from "./components/ProductDetailTab";
import LoadingIndicator from "../../components/LoadingIndicator";
import Layer from "@/components/Layer";
import Btn from "@/components/Btn";

export default {
  name: "DeliveryProduct",
  props: {},
  data: function () {
    return {
      productId: parseInt(this.$route.query.productId) > 0 ? this.$route.query.productId : null,
      userId: this.$route.query.userId ? this.$route.query.userId : null,
      isProgressing: false,
      displayLayer: false,
      product: {},
      storeProducts: [],
      likeInfo: {},
      regularInfo: {},
      storeInfo: {},
      businessInfo: {},
    };
  },
  components: {
    Btn,
    Layer,
    LoadingIndicator,
    ProductImgCarousel,
    ProductInfo,
    OtherProduct,
    ProductDetailTab,
  },
  methods: {
    toggleLayer: function () {
      if (!this.userId) {
        this.$callNative("showIdentifyVerification");
        return false;
      }
      this.displayLayer = !this.displayLayer;
    },
    getProductAllData: async function () {
      if (!this.productId) {
        return false;
      }
      this.isProgressing = true;

      await this.getProduct();
      await this.getOtherProduct();
      await this.getProductLike();
      await this.getRegularInfo();
      await this.getBusinessInfo();

      this.isProgressing = false;
    },
    getProduct: async function () {
      try {
        const productResponse = await this.$axios1.get("/v3/market/delivery/product/" + this.productId);
        if (productResponse.data.responseCode == 2000) {
          this.product = productResponse.data.result;

          if (this.product.productImage) {
            this.product.productImageList = [];
            this.product.productImageList.push(this.product.productImage);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    getOtherProduct: async function () {
      try {
        const storeProductResponse = await this.$axios1.get("/v3/market/delivery/product/" + this.productId + "/store/products/bd");
        if (storeProductResponse.data.responseCode == 2000) {
          this.storeProducts = storeProductResponse.data.result.storeProductList;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getProductLike: async function () {
      try {
        const params = {
          userId: this.userId,
        };

        const likeResponse = await this.$axios1.get("/v3/market/delivery/product/" + this.productId + "/like-count", { params });
        if (likeResponse.data.responseCode == 2000) {
          this.likeInfo = likeResponse.data.result;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getRegularInfo: async function () {
      if (!this.userId) {
        return false;
      }
      try {
        const params = {
          userId: this.userId,
        };

        const regularResponse = await this.$axios1.get("/v3/market/delivery/store/product/" + this.productId + "/regular", { params });
        if (regularResponse.data.responseCode == 2000) {
          this.regularInfo = regularResponse.data.result;
        }
      } catch (error) {
        console.error(error);
      }
    },
    getBusinessInfo: async function () {
      try {
        const businessInfo = await this.$axios1.get(
          "v3/market/delivery/store/" + this.product.marketId + "/" + this.product.storeId + "/business/information",
        );
        if (businessInfo.data.responseCode == 2000) {
          this.businessInfo = businessInfo.data.result;
        }
      } catch (error) {
        console.error(error);
      }
    },
    toggleRegular: async function () {
      if (!this.userId) {
        this.$callNative("showIdentifyVerification");
        return false;
      }
      try {
        const toggleResponse = await this.$axios1.put("/v3/user/store/product/" + this.productId + "/regular/" + this.userId, {
          regularYn: this.isRegularStore ? "N" : "Y",
        });
        if (toggleResponse.data.responseCode == 2000) {
          await this.getProduct();
          await this.getRegularInfo();
          this.toggleLayer();
        }
      } catch (error) {
        console.error(error);
      }
    },
    moveToProductOption: function () {
      this.$callNative("moveToProductOption", { productId: this.productId });
    },
    onBackPressed: function () {
      this.$refs.carousel.onBackPressed();
    },
    passMarketId: function () {
      const data = { marketId: this.product.marketId };
      // if (this.$isAndroid()) {
      //   return JSON.stringify(data);
      // }
      // else if (this.$isIos()) {
      // }
      this.$callNative("passMarketId", data);
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
    window.passMarketId = this.passMarketId;
  },
  created() {
    this.getProductAllData();
  },
  computed: {
    isRegularStore: function () {
      return this.regularInfo && this.regularInfo.regularYn == "Y" ? true : false;
    },
    isLikeStore: function () {
      return this.likeInfo && this.likeInfo.likeYn == "Y" ? true : false;
    },
    formatLikeCount: function () {
      return this.likeInfo.likeCount < 1000 ? this.likeInfo.likeCount : "999+";
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped src="./DeliveryProduct.scss"></style>
<style lang="scss" scoped>
.btn_wrap {
  width: 100%;
  height: 100px;
  background: #ffffff;
  padding: 20px;
}

.btn_wrap .btn_add_cart {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: $nj_red;
  color: #ffffff;
  text-align: center;
  font: normal normal normal 20px/60px Noto Sans CJK KR;
  letter-spacing: -1.2px;
}

.popup {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1;
  overflow: hidden;
}
</style>
