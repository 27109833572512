import router from "@/router";
import Vue from "vue";

const state = {
  title: "주문내역",
  isProgressing: false,
  reviewLimitDay: 3,
  inquiryLimitHour: 3,
  orders: [],
  currentPage: 0,
  totalPage: 0,
  pageSize: 30,
};

const mutations = {
  updateState: function (state, data) {
    state[data.type] = data.updateData;
  },
  reset: function (state) {
    state.orders = [];
    state.currentPage = 0;
    state.totalPage = 0;
  },
  toggleReview: function (state, order) {
    state.orders.forEach((orderGroup) => {
      orderGroup.orders.forEach((item) => {
        if (order.orderId == item.orderId) {
          order.hasReview = "X";
        }
      });
    });
  },
  toggleInquiry: function (state, order) {
    state.orders.forEach((orderGroup) => {
      orderGroup.orders.forEach((item) => {
        if (order.orderId == item.orderId) {
          order.hasInquiry = "X";
        }
      });
    });
  },
};

const actions = {
  initState: async function (store) {
    const pages = Array.from(Array(state.currentPage + 1).keys());
    // await store.commit('updateState', {type: 'isProgressing', updateData: true});
    for await (let page of pages) {
      await store.dispatch("getOrderList", page);
    }
    // await store.commit('updateState', {type: 'isProgressing', updateData: false});
  },
  getOrderList: async function (store, page) {
    const userId = localStorage.getItem("userId");
    try {
      store.commit("updateState", { type: "isProgressing", updateData: true });

      const url = "v3/orders/members/" + userId + "?size=" + state.pageSize + "&page=" + page;
      const orderHistoryResult = await this._vm.$axios2.get(url);

      store.commit("updateState", { type: "isProgressing", updateData: false });

      if (orderHistoryResult.data.responseCode == 2000) {
        if (orderHistoryResult.data.result.orderList) {
          const orderList = orderHistoryResult.data.result.orderList;
          orderList.forEach((order) => {
            if (order.orderType == "TB") {
              if (order.orderStatus == "배달 중") {
                order.orderStatus = "배송중";
              } else if (order.orderStatus == "배달 완료") {
                order.orderStatus = "배송 완료";
              }
            }
          });
          store.commit("updateState", {
            type: "orders",
            updateData: [...(page == 0 ? [] : state.orders), ...orderList],
          });
        }
        store.commit("updateState", {
          type: "currentPage",
          updateData: page,
        });
        store.commit("updateState", {
          type: "totalPage",
          updateData: orderHistoryResult.data.result.totalPages,
        });
      }
    } catch (e) {
      store.commit("updateState", { type: "isProgressing", updateData: false });
      console.error(e);
    }
  },
  reset: function (store) {
    store.commit("reset");
  },
  moveToMarket: function () {
    if (state.marketId) {
      router.push({
        name: "시장 메인",
        params: {
          marketId: state.marketId,
          marketName: state.marketName,
        },
      });
    } else {
      router.push({ name: "놀장 메인" });
    }
  },
  moveToOrderDetail: function () {},
  toggleReview: function (store, order) {
    store.commit("toggleReview", order);
  },
  toggleInquiry: function (store, order) {
    store.commit("toggleInquiry", order);
  },
};

const getters = {
  orderGroups: (state) => {
    if (!state.orders) {
      return [];
    }

    const orderGroup = state.orders.reduce((orderGroup, order) => {
      const date = Vue.moment(order.orderDateTime, "YYYY.MM.DD");
      if (!orderGroup[date]) {
        orderGroup[date] = [];
      }
      orderGroup[date].push(order);
      return orderGroup;
    }, {});

    return Object.keys(orderGroup).map((date) => {
      return {
        orderDate: date,
        orders: orderGroup[date],
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
