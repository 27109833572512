<template>
  <div>
    <TopHeader :title="title" @goBack="goBack" />
    <div class="content" ref="contentsWrap">
      <div class="order_info">
        <span class="title">{{ orderTitle }}</span>
        <span class="status" v-html="orderStatus" />
        <a href="javascript:void(0);" class="mt_13" :data-event-type="'moveToMarket'" :data-market-id="marketId" @click="moveToApp">
          <Icon type="icon_arrow_left_small" :value="marketName" />
        </a>
        <div class="order_detail_wrap">
          <span class="order_id">주문번호: {{ orderId.slice(-4, orderId.length) }}</span>
          <span class="order_datetime">주문일시: {{ $moment(orderDateTime).format("YYYY.MM.DD a HH:mm") }}</span>
        </div>
      </div>

      <ul class="store_list">
        <li v-for="store in stores" :key="store.storeId">
          <div class="store_name">
            <a
              href="javascript:void(0);"
              :data-event-type="'moveToStore'"
              :data-market-id="marketId"
              :data-store-id="store.storeId"
              @click="moveToApp"
            >
              <Icon type="icon_arrow_left_small" :value="store.storeName" />
            </a>
          </div>

          <OrderProductList :products="store.products" @moveToApp="moveToApp" />
        </li>
      </ul>

      <div class="purchase_info">
        <span class="title">결제</span>
        <ul class="float_list">
          <li>
            <span class="subject">총 상품금액</span>
            <span class="text">{{ $formatPrice(totalPayment.productsPrice) }} 원</span>
          </li>
          <li v-if="totalPayment.totalDiscount">
            <span class="subject">총 할인</span>
            <span class="text">-{{ $formatPrice(totalPayment.totalDiscount) }} 원</span>
          </li>
          <li>
            <span class="subject">배송비</span>
            <span class="text">{{ $formatPrice(totalPayment.deliveryFee) }} 원</span>
          </li>
          <li v-if="totalPayment.deliveryDiscount">
            <span class="subject">배송비 할인</span>
            <span class="text">-{{ $formatPrice(totalPayment.deliveryDiscount) }} 원</span>
          </li>
          <li v-if="totalPayment.point">
            <span class="subject">놀장포인트</span>
            <span class="text">-{{ $formatPrice(totalPayment.point) }} 원</span>
          </li>

          <li class="type1">
            <span class="subject">총 결제 금액</span>
            <span class="text">{{ $formatPrice(totalPayment.payment) }} <span>원</span></span>
          </li>
        </ul>
      </div>

      <div class="delv_addr_info">
        <span class="title">배송지</span>
        <div class="delv_addr_txt">
          <span>{{ delivery.roadAddressFull }}</span>
        </div>
      </div>

      <div class="review_wrap">
        <div class="review_msg">
          <div v-if="hasReview == 'N'">
            리뷰작성 가능 기간이
            <span class="review_limit_days">{{ leftReviewTime }}</span>
            남았습니다.
          </div>
          <div v-else-if="hasReview == 'Y'">리뷰작성을 완료 하였습니다.</div>
          <div v-else-if="hasReview == 'X'">리뷰작성 가능 시간이 지났습니다.</div>
        </div>

        <Btn
          :btnId="'REVIEW'"
          :value="hasReview == 'N' ? '리뷰쓰기' : hasReview == 'Y' ? '리뷰보기' : '리뷰쓰기불가'"
          :type="hasReview == 'N' ? 'black write' : hasReview == 'Y' ? 'black view' : 'inactive'"
          @btnCallback="moveToApp"
        />
      </div>
      <div class="inquiry_wrap">
        <span class="inquiry_msg">
          주문관련 교환 및 환불 문의는
          <span class="block"><span class="inquiry_limit">배달 완료 후 3시간 이내에 가능</span>합니다.</span>
        </span>
        <Btn
          :btnId="'INQUIRY'"
          :value="hasInquiry == 'N' ? '상품문의' : hasInquiry == 'Y' ? '상품문의보기' : '상품문의불가'"
          :type="hasInquiry == 'N' ? 'type1 reverse write' : hasInquiry == 'Y' ? 'type1 reverse view' : 'inactive'"
          @btnCallback="moveToApp"
        />
      </div>
    </div>

    <Alert
      :displayLayer="displayGuideLayer"
      :texts="[guideTxt]"
      :textClass="[]"
      :btns="[
        {
          value: '확인',
        },
      ]"
      :showHideLayer="showHideGuideLayer"
    />
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader";
import Btn from "@/components/Btn";
import OrderProductList from "@/page/OrderDetail/Component/OrderProductList";
import Icon from "@/components/new/Icon";
import Alert from "@/components/Alert";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "OrderDetail",
  // props: {
  // },
  // data: function () {
  //   return {
  //   }
  // },
  components: {
    OrderProductList,
    Btn,
    TopHeader,
    Icon,
    Alert,
  },
  beforeCreate: function () {
    this.$store.dispatch("orderDetail/initState", {
      userId: this.$route.query.userId,
      orderId: this.$route.query.orderId,
    });
  },
  beforeDestroy: function () {
    this.$refs.contentsWrap.scrollTop = 0;
  },
  methods: {
    ...mapMutations("orderDetail", [
      "moveToApp", // 리뷰 작성, 상품 문의
      "showHideGuideLayer",
    ]),
    ...mapActions("orderDetail", ["goBack"]),
  },
  computed: {
    ...mapState({
      title: (state) => state.orderDetail.title,
      orderId: (state) => state.orderDetail.orderId,
      orderType: (state) => state.orderDetail.orderType,
      orderTitle: (state) => state.orderDetail.orderTitle,
      marketId: (state) => state.orderDetail.marketId,
      marketName: (state) => state.orderDetail.marketName,
      orderDateTime: (state) => state.orderDetail.orderDateTime,
      orderStatus: (state) => state.orderDetail.orderStatus,
      hasReview: (state) => state.orderDetail.hasReview,
      hasInquiry: (state) => state.orderDetail.hasInquiry,
      leftReviewTime: (state) => state.orderDetail.leftReviewTime,
      totalPayment: (state) => state.orderDetail.totalPayment,
      delivery: (state) => state.orderDetail.delivery,
      stores: (state) => state.orderDetail.stores,
      displayGuideLayer: (state) => state.orderDetail.displayGuideLayer,
      guideTxt: (state) => state.orderDetail.guideTxt,
    }),
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 44px);
  padding: 23px 20px 20px;
  background: $nj_background_gray;
  overflow-y: auto;

  & > * {
    border-bottom: 1px solid $nj_divider_gray;
  }

  & > *:last-child {
    border: none;
  }

  .order_info {
    position: relative;
    padding-top: 0;
    padding-bottom: 30px;

    & > * {
      display: block;
    }

    .status {
      position: absolute;
      top: 0;
      right: 0;
      @include font(12, 22, -0.72, bold);
      color: $nj_red;
    }

    .title {
      @include font(18, 22, -1.08, medium);
      color: $nj_black;
    }

    .product_simple,
    .market_name,
    .store_name {
      @include font(16, 24, -0.96);
      color: $nj_text_sub_gray;
    }

    .market_name {
      margin-top: 10px;
    }

    .order_detail_wrap {
      margin-top: 8px;
      @include font(12, 18, -0.72);
      color: $nj_placeholder_gray;

      & > span {
        display: block;
      }
    }
  }

  .store_list {
    border-width: 0;
    > li {
      padding: 20px 0;
      border-style: solid;
      border-width: 0 0 1px;
      border-color: $nj_divider_gray;
      &:last-child {
        padding: 20px 0 30px;
      }
    }
  }

  .purchase_info {
    padding: 30px 0 20px 0;
    .title {
      @include font(18, 27, -1.08);
      color: #262626;
    }
  }

  .delv_addr_info {
    padding: 30px 0;

    & > span {
      display: block;
    }

    .title {
      margin-bottom: 10px;
      @include font(20, 29, -1.2);
      //@include font(18, 27, -1.08);
      color: $nj_black;
    }

    .delv_addr_txt {
      @include font(16, 24, -0.96);
      color: $nj_text_main_gray;

      & > * {
        display: block;
      }
    }
  }

  .review_wrap {
    padding: 30px 0;
    text-align: center;

    .review_msg {
      display: block;
      margin-bottom: 20px;
      @include font(16, 24, -0.96);
      color: $nj_text_main_gray;

      .review_limit_days {
        color: $nj_red;
      }
    }
  }

  .inquiry_wrap {
    padding-top: 30px;

    .inquiry_msg {
      display: block;
      margin-bottom: 20px;
      @include font(16, 22, -0.96);
      color: $nj_text_main_gray;
      text-align: center;

      .inquiry_limit {
        color: $nj_red;
      }
    }
  }
}
</style>
