<template>
  <li class="coupon_item" :class="{ disabled: !isUsable, active: isSelected }" @click="toggleCoupon">
    <div class="left">
      <span class="coupon_usable">{{ isUsable ? "사용가능" : "사용불가" }}</span>
      <span class="coupon_name mt_2">{{ couponName }}</span>
      <span class="coupon_notice_msg mt_5">
        {{ notice }}
      </span>
      <span class="coupon_min_order_price mt_20">{{ $formatPrice(minOrderPay) }}원 이상 구매시 사용가능합니다.</span>
    </div>
    <div class="right">
      <div class="coupon_sale_price_wrap">
        <span class="coupon_sale_price">
          {{ $formatPrice(discountPoint) }}
        </span>
        <span class="won_txt">원</span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "CouponItem",
  components: {},
  props: {
    couponId: Number,
    couponName: String,
    discountPoint: Number,
    minOrderPay: Number,
    enableYn: {
      type: String,
      default: "Y",
    },
    notice: String,
    totalPrice: Number,
    isSelectable: Boolean,
    isSelected: Boolean,
  },
  methods: {
    toggleCoupon: function () {
      if (!this.isSelectable || !this.isUsable) {
        return false;
      }

      this.$store.dispatch("coupon/selectCoupon", { couponId: this.couponId });
    },
  },
  computed: {
    isUsable() {
      if (this.enableYn == "N") {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon_item {
  position: relative;
  width: 100%;
  height: 180px;
  margin-bottom: 20px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
  list-style: none;

  &.active {
    border: 1px solid $nj_red;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &.disabled {
    background: $nj_disabled_gray;

    .coupon_usable {
      color: $nj_text_sub_gray !important;
    }
  }

  .left {
    float: left;
    width: calc(100% - 100px);
    height: 100%;
    padding: 30px 20px;

    & > span {
      display: block;
    }

    .coupon_usable {
      @include font(12, 22, -0.72, bold);
      color: $nj_red;
    }

    .coupon_name {
      @include font(18, 22, -1.08, medium);
      color: $nj_black;
    }

    .coupon_notice_msg {
      @include font(14, 22, -0.96);
      color: $nj_text_main_gray;
    }

    .coupon_min_order_price {
      @include font(12, 22, -0.72);
      color: $nj_placeholder_gray;
    }
  }

  .right {
    float: right;
    position: relative;
    width: 100px;
    height: 100%;
    text-align: center;
    background-image: linear-gradient($nj_placeholder_gray 33%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 1px 24px;
    background-repeat: repeat-y;

    .coupon_sale_price_wrap {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      color: $nj_black;

      .coupon_sale_price {
        display: block;
        @include font(40, 59, -2.4, medium);
        writing-mode: vertical-lr;
      }

      .won_txt {
        height: 25px;
        display: block;
        @include font(24, normal, -1.44, medium);
        transform: rotate(90deg);
      }
    }
  }
}
</style>
