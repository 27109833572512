<template>
  <div class="faq_item">
    <div class="faq_main" @click="toggle">
      <div class="faq_title">{{ title }}</div>
      <div class="toggle_wrap">
        <a href="javascript:void(0)" :class="{ active: isToggle }"></a>
      </div>
    </div>
    <transition name="slide">
      <div class="faq_detail" v-if="isToggle">
        <div class="content">{{ content }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FAQItem",
  props: {
    id: Number,
    title: String,
    content: String,
  },
  data: function () {
    return {
      isToggle: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    toggle: function () {
      this.isToggle = !this.isToggle;
    },
  },
  created: function () {},
};
</script>

<style lang="scss" scoped>
.faq_item {
  padding: 20px 0;
  border-bottom: 1px solid $nj_divider_gray;

  &:first-child {
    border-top: 1px solid $nj_divider_gray;
  }

  .faq_main {
    & > * {
      display: inline-block;
      vertical-align: top;
    }

    .faq_title {
      width: calc(100% - 80px);
      @include font(18, 28, -1.08);
      color: $nj_black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .toggle_wrap {
      float: right;
      width: 50px;
      height: 28px;
      text-align: right;

      a {
        display: inline-block;
        width: 16px;
        height: 100%;
        background-image: url("../../../assets/images/arrow_bottom.png");
        background-position: center;
        background-repeat: no-repeat;

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }

  .faq_detail {
    padding-top: 20px;
    padding-bottom: 20px;

    .content {
      @include font(16, 24, -0.32);
      color: $nj_text_main_gray;
    }
  }
}
</style>
