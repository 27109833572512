<template>
  <div class="page_wrap">
    <TopHeader
      class="top_header"
      title="신고센터"
      :isCartVisible="false"
      @goBack="goBack"
      style="position: fixed; top: 0px; width: 100%; z-index: 999"
    />
    <div class="content" style="padding: 58px 0px 0px">
      <div class="report-container">
        <div class="report-input-container">
          <label for="select">신고유형</label>
          <select id="select" class="report-select" v-model="cate">
            <option class="report-option" value="PRICE" selected>가격</option>
          </select>
        </div>
        <div class="report-input-container">
          <label for="title">제목</label>
          <input id="title" class="report-title" v-model="title" ref="title" />
        </div>
        <div class="report-input-container">
          <label for="content">내용</label>
          <textarea id="content" class="report-content" v-model="content" ref="content"> </textarea>
        </div>
        <div class="report-button-container">
          <button type="submit" class="report-button">
            <span v-if="boardId !== null" @click="editReport">수정</span>
            <span v-else @click="clickBottom">작성</span>
          </button>
        </div>
        <div>&#8251; 상품을 캡쳐해서 카카오채널 "놀러와요 시장"에 공유해주시면 좀더 빠른 민원처리가 가능합니다.</div>
        <div class="call_center" style="position: fixed; bottom: 20px; right: 20px" @click="goKakaoTalk">
          <div class="cs_talk">
            <a href="javascript:void(0)" title="카카오톡 문의">
              <img src="~svg/icon_cs_talk.svg" alt="카카오톡 문의" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../../components/TopHeader";
import { mapActions } from "vuex";

export default {
  name: "REPORT",
  props: {},
  data: function () {
    return {
      title: "",
      content: "",
      cate: "PRICE",
      reportList: [],
      boardId: null,
    };
  },
  components: {
    TopHeader,
  },
  methods: {
    goKakaoTalk() {
      this.$callNative("openKakaoChannel");
      // this.$callNative('openKaKaoChannel', null, true);
    },

    clickBottom() {
      if (this.validate()) {
        return;
      }
      const body = {
        userId: localStorage.getItem("userId"),
        title: this.title,
        content: this.content,
        cate: this.cate,
      };
      const axiosUrl = "/v3/report/board";
      this.$axios1.post(axiosUrl, body).then((response) => {
        if (response.data.responseCode) {
          alert("오류가 발생했습니다. 고객센터로 문의해주기 바랍니다.");
        } else {
          alert("게시글이 등록되었습니다");
          this.goBack();
        }
      });
    },
    editReport() {
      if (this.validate()) {
        return;
      }

      const body = {
        userId: localStorage.getItem("userId"),
        title: this.title,
        content: this.content,
        cate: this.cate,
      };

      const axiosUrl = "/v3/report/board/" + this.boardId + "?boardId=" + this.boardId;
      this.$axios1.put(axiosUrl, body).then((response) => {
        if (response.data.responseCode) {
          alert("오류가 발생했습니다. 고객센터로 문의해주기 바랍니다.");
        } else {
          alert("게시글이 등록되었습니다");
          this.goBack();
        }
      });
    },
    validate() {
      if (!this.title) {
        alert("제목을 작성해주세요");
        return true;
      }

      if (!this.content) {
        alert("내용을 작성해주세요");
        return true;
      }

      return false;
    },
    ...mapActions("product", ["goBack"]),
  },
  computed: {},
  mounted: function () {
    this.$refs.title.addEventListener("focus", () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }, 75);
    });

    this.$refs.content.addEventListener("focus", () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }, 75);
    });

    const boardId = this.$route.params.id;
    if (boardId !== undefined) {
      this.boardId = boardId;
      const userId = localStorage.getItem("userId");
      const axiosUrl = "/v3/report/board/" + boardId + "?boardId=" + boardId + "&userId=" + userId;
      this.$axios1
        .get(axiosUrl)
        .then((response) => {
          if (response.data.responseCode) {
            alert("오류가 발생했습니다. 고객센터로 문의해주기 바랍니다.");
          } else {
            const data = response.data;
            this.title = data.title;
            this.content = data.content;
            this.cate = data.cate;
          }
        })
        .catch(() => {
          alert("오류가 발생했습니다. 고객센터로 문의해주기 바랍니다.");
        });
    }
  },
  watch: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page_wrap {
  .content {
    position: relative;
    height: calc(100vh - 44px);
    padding: 20px;
    overflow-x: auto;

    .btn_wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
    }
  }
}

.footer_wrap {
  padding: 20px 0 0;
  text-align: center;

  span {
    display: block;
  }

  .title {
    @include font(16, 24, -0.96, bold);
    color: $nj_black;
  }

  .tel {
    @include font(24, 36, -0.48, bold);
    color: $nj_red;
  }

  .time {
    @include font(16, 24, -0.64);
    color: $nj_text_main_gray;
  }
}
</style>

<style lang="scss" scoped>
.report-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 0 15px;
}

.report-input-container {
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    font-weight: bold;
  }
}

.report-button-container {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
}

.report-select {
  text-align-last: center;
  text-align: center;
  min-width: 150px;
  height: 30px;
}

.report-option {
  padding-left: 10px;
}

.report-title {
  width: 100%;
}

.report-content {
  width: 100%;
  height: 300px;
  resize: none;
}

.report-button {
  border-radius: 10px;
  color: white;
  font-size: 15px;
  border-color: transparent;
  background-color: lightsalmon;
  margin: 0 auto;
  min-height: 40px;
  width: 50%;
}
</style>
