<template>
  <div id="manual_list" class="list_wrap">
    <div class="manual_item" v-for="notice in notices" v-bind:key="notice.id" @click="$emit('onNoticeClick', notice)">
      <img v-bind:src="notice.bannerImage" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ManualList",
  props: {
    notices: Array,
  },
  data: function () {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    returnDetail: function (notice) {
      const payload = {
        noticeId: notice.noticeId,
        noticeTitle: notice.title,
        noticeDetailUrl: notice.detailImage,
      };
      this.$callNative("showNoticeDetail", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.list_wrap {
  padding: 20px 0;
}

.list_wrap .manual_item {
  margin-bottom: 20px;
}

.list_wrap .manual_item > img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 1px 0px $nj_shadow_gray;
}
</style>
