<template>
  <div>
    <div class="main_wrap" :class="{ fixed: !topBanner }" id="mainWrap">
      <div class="main_header_wrap">
        <div class="main_header" style="display: flex; justify-content: space-between; align-items: center">
          <h1 class="header_logo" v-if="!appVersion">놀장</h1>
          <div class="main_address" v-else>
            <a href="javascript:void(0);" @click="moveToAddress">
              <span class="address" v-if="userAddress">
                {{ userAddress.alias ? userAddress.alias : roadName(userAddress.roadNameAddress) }}
              </span>
              <span class="address" v-else>주소 지정</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_contents" style="overflow-y: hidden">
      <Tab :tabItems="topMenuItems" @click="setContentsType({ dataType: $event.dataType })" :isPreviousData="true" tabType="type1" />
    </div>
    <div class="banner_wrap ignore_touch_x" @click="clickEvent">
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
        @touchEnd="touchEnd"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(banner, index) in banners" :key="'banner_' + index">
          <img
            :src="banner.bannerImage"
            :alt="banner.title"
            :data-title="banner.title"
            :data-detail-url="banner.detailUrl"
            :data-notice-id="banner.noticeId"
            style="width: 100%; height: 100%; object-fit: contain"
            loading="lazy"
          />
        </swiper-slide>
      </swiper>
    </div>
    <div class="tab_wrap">
      <div class="tab_contents" style="overflow-y: hidden">
        <!-- 전통시장 -->
        <!-- <DoubleTab
          :largeTabItems="this.$store.state.main.specialTabLargeTab.tabItems"
          :largeTabActiveIndex="this.$store.state.main.specialTabLargeTab.tabActiveIndex"
          @clickLargeTab="switchSeaTabLargeTabActiveIndex"
          :mediumTabItems="[]"
          tabType="type5"
          large-tab-wrap-background-color="white"
        /> -->
        <div class="product_list vertical" style="padding-top: 15px; min-height: 500px">
          <ProductList :products="mainTabProducts.list" v-if="mainTabProducts.list && mainTabProducts.list.length" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Tab from "@/components/new/Tab.vue";
import DoubleTab from "@/components/new/DoubleTab.vue";
import ProductList from "@/components/new/ProductList.vue";

export default {
  name: "SpecialOffers",
  props: {
    userId: String,
    customerMarginTop: String,
    market: Object,
    marketId: Number,
    marketName: String,
    deliveryFee: Number,
    totalPrice: Number,
    products: Array,
    largeTabItems: Array,
    imgTab: Boolean,
    largeTabActiveIndex: {
      type: Number,
      default: 0,
    },
    mediumTabItems: Array,
    mediumTabActiveIndex: {
      type: Number,
      default: 0,
    },
    isMediumTabVisible: {
      type: Boolean,
      default: false,
    },
    isHideArrow: {
      type: Boolean,
      default: false,
    },
    // 옵션 props 입니다.
    tabType: {
      type: String,
      default: "type1", // ["type1", "type2", "type3", "type4"]
    },
    tabScrollType: {
      type: String,
      default: "center", // ["center","normal"]
    },
    // 예외 case 일 경우 특정 css 적용 가능
    largeTabWrapHeight: Number,
    largeTabWrapPaddingX: Number,
    largeTabWrapBackgroundColor: String,
    largeTabWrapBorderBottomStyle: String, // ['solid','none']
    largeTabInterval: Number,
    largeTabPaddingX: Number,
    activeLargeTabTextColor: String,
    activeLargeTabBackgroundColor: String,
    activeLargeTabFontWeight: [Number, String], // ['normal','bold', 700 등등...]
    isActiveLargeTabHasBackground: Boolean,
    isLargeTabBarExist: Boolean,
    largeTabBarBackgroundColor: String,
    largeTabFontSize: Number,
    largeTabLineHeight: Number,
    largeTabLetterSpacing: Number,
    largeTabFontWeight: [Number, String],
    largeTabBorderColor: String,

    mediumTabWrapHeight: Number,
    mediumTabWrapPaddingX: Number,
    mediumTabWrapBackgroundColor: String,
    mediumTabWrapBorderBottomStyle: String, // ['solid','none']
    mediumTabInterval: Number,
    mediumTabPaddingX: Number,
    activeMediumTabTextColor: String,
    activeMediumTabBackgroundColor: String,
    activeMediumTabFontWeight: [Number, String], // ['normal','bold', 700 등등...]
    isActiveMediumTabHasBackground: Boolean,
    isMediumTabBarExist: Boolean,
    mediumTabBarBackgroundColor: String,
    mediumTabFontSize: Number,
    mediumTabLineHeight: Number,
    mediumTabLetterSpacing: Number,
    mediumTabFontWeight: [Number, String],
    mediumTabBorderColor: String,
  },
  data: function () {
    return {
      windowHeight: window.innerHeight,
      swiperOptions: {
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
        autoplay: {
          delay: 3000,
        },
        slidesPerView: "auto",
        touchRatio: 0.2,
        // slideToClickedSlide: true
      },
      discountType: process.env.VUE_APP_DISCOUNT_TYPE,
      isNotWeekend: new Date().getDay() !== 0 && new Date().getDay() !== 6,
      showScroll: false,
      lastScroll: 0,
      lunarHoliday: new Date().getDate() >= 9 && new Date().getDate() <= 12,
      showMedium: false,
      loading: false,
      mediumData: [
        {
          dataType: "all",
          categoryId: "1",
          categoryName: "전체",
          isActive: false,
          tabActiveIndex: 0,
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        },
        {
          dataType: "tuesday",
          categoryId: "2",
          categoryName: "화요일",
          isActive: false,
          tabActiveIndex: 0,
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        },
        {
          dataType: "thursday",
          categoryId: "3",
          categoryName: "목요일",
          isActive: false,
          tabActiveIndex: 0,
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        },
        {
          dataType: "etc",
          categoryId: "99",
          categoryName: "기타",
          tabActiveIndex: 0,
          categoryImg: "https://noljang-ops.s3.ap-northeast-2.amazonaws.com/category/category_icon/%E1%84%80%E1%85%B5%E1%84%90%E1%85%A1.png",
        },
      ],
      type1: {
        largeTabWrapHeight: 42,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 0,
        largeTabTextColor: "black",
        largeTabPaddingX: 15,
        isActiveLargeTabHasBackground: false,
        activeLargeTabTextColor: "#FF563F",
        activeLargeTabBackgroundColor: "white",
        activeLargeTabFontWeight: 600,
        isLargeTabBarExist: true,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 17,
        largeTabLineHeight: 20,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: 600,
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 42,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 0,
        mediumTabPaddingX: 15,
        isActiveMediumTabHasBackground: false,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: 600,
        isMediumTabBarExist: true,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 17,
        mediumTabLineHeight: 20,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: 600,
        mediumTabBorderColor: "white",
      },
      type2: {
        largeTabWrapHeight: 50,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "#F9F9F9",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "normal",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 23,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 50,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "#F9F9F9",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 23,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
      type3: {
        largeTabWrapHeight: 44,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "none",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "normal",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 24,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 44,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "none",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "white",
        activeMediumTabBackgroundColor: "#FF563F",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 24,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "white",
      },
      type4: {
        largeTabWrapHeight: 43,
        largeTabWrapPaddingX: 16,
        largeTabWrapBackgroundColor: "white",
        largeTabWrapBorderBottomStyle: "none",
        largeTabInterval: 5,
        largeTabTextColor: "black",
        largeTabPaddingX: 10,
        isActiveLargeTabHasBackground: false,
        activeLargeTabTextColor: "#FF563F",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "bold",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 15,
        largeTabLineHeight: 24,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "#FF563F",

        mediumTabWrapHeight: 43,
        mediumTabWrapPaddingX: 16,
        mediumTabWrapBackgroundColor: "white",
        mediumTabWrapBorderBottomStyle: "none",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: false,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "#FF563F",
        activeMediumTabFontWeight: "bold",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 24,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
      type5: {
        largeTabWrapHeight: 50,
        largeTabWrapPaddingX: 15,
        largeTabWrapBackgroundColor: "#F9F9F9",
        largeTabWrapBorderBottomStyle: "solid",
        largeTabInterval: 5,
        largeTabPaddingX: 10,
        largeTabTextColor: "#707070",
        isActiveLargeTabHasBackground: true,
        activeLargeTabTextColor: "white",
        activeLargeTabBackgroundColor: "#FF563F",
        activeLargeTabFontWeight: "bold",
        isLargeTabBarExist: false,
        largeTabBarBackgroundColor: "#FF563F",
        largeTabFontSize: 14,
        largeTabLineHeight: 23,
        largeTabLetterSpacing: 0,
        largeTabFontWeight: "normal",
        largeTabBorderColor: "white",

        mediumTabWrapHeight: 50,
        mediumTabWrapPaddingX: 15,
        mediumTabWrapBackgroundColor: "#F9F9F9",
        mediumTabWrapBorderBottomStyle: "solid",
        mediumTabInterval: 5,
        mediumTabPaddingX: 10,
        isActiveMediumTabHasBackground: true,
        activeMediumTabTextColor: "#FF563F",
        activeMediumTabBackgroundColor: "white",
        activeMediumTabFontWeight: "normal",
        isMediumTabBarExist: false,
        mediumTabBarBackgroundColor: "#FF563F",
        mediumTabFontSize: 15,
        mediumTabLineHeight: 23,
        mediumTabLetterSpacing: 0,
        mediumTabFontWeight: "normal",
        mediumTabBorderColor: "#FF563F",
      },
    };
  },
  components: {
    ProductList,
    DoubleTab,
    Tab,
    SwiperSlide,
    Swiper,
    // Tab,
  },
  computed: {
    ...mapState({
      isReloading: (state) => state.main.isReloading,
      isProgressing: (state) => state.main.isProgressing, // loading
      contentsType: (state) => state.main.contentsType, // 상단탭 활성화 변수
      topBanner: (state) => state.main.topBanner, // 상단 배너
      banners: (state) => state.main.banners, // 슬라이딩 배너
      slideActiveIndex: (state) => state.main.slideActiveIndex, // 슬라이딩 배너 활성화 index
      directMarkets: (state) => state.main.directMarkets, // 바로배달
      deliveryMarkets: (state) => state.main.deliveryMarkets, // 전국배송 시장별
      deliveryMarketsProducts: (state) => state.main.deliveryMarketsProducts.list, // 전국배송 상품별
      allSpecialOfferItems: (state) => state.main.allSpecialOfferItems,
      deliveryFdStoreList: (state) => state.main.deliveryFdStoreList, // 음식배달
      //mainTabs: (state) => state.brandMall.mainTabs,
      recentBuyProducts: (state) => state.main.recentBuyProducts, // 최근 구매
      favoritesStore: (state) => state.main.favoritesStore, // 단골상점
      topMenuItems: (state) => state.main.topMenuItems, // 상단메뉴

      eventContentsType: (state) => state.main.eventContentsType, // 이벤트/정보 페이지에서 활성화탭(event, notice, how)
      eventTabItems: (state) => state.main.eventTabItems, // 이벤트 서브 탭 : 이벤트-event, 공지사항-notice, 사용법-how
      eventList: (state) => state.main.eventList, //

      mainTabProducts: (state) => state.main.mainTabProducts,
      mainTabLargeTab: (state) => state.main.mainTabLargeTab,
      specialTabLargeTab: (state) => state.main.specialTabLargeTab,
      specialTabProducts: (state) => state.main.specialTabProducts,

      seaTabProducts: (state) => state.main.seaTabProducts,
      seaTabLargeTab: (state) => state.main.seaTabLargeTab,

      brandTabProducts: (state) => state.main.brandTabProducts,
      brandTabLargeTab: (state) => state.main.brandTabLargeTab,

      showAllmenu: (state) => state.main.showAllmenu, // 전체메뉴 노출여부
      deliveryMarketsType: (state) => state.main.deliveryMarketsType, // 상단탭 전체상품 or 전체시장 선택
      deliveryMarketsLocalTab: (state) => state.main.deliveryMarketsLocalTab, // 전국배송 지역별 분류 탭
      deliveryMarketsLocal: (state) => state.main.deliveryMarketsLocal, // 전국배송 지역별 시장
      deliveryMarketsProductsLargeTab: (state) => state.main.deliveryMarketsProductsLargeTab, // 전체상품 대분류 탭 리스트
      deliveryMarketsProductsMediumTab: (state) => state.main.deliveryMarketsProductsMediumTab, // 전체상품 중분류 탭 리스트
      topBannerHeight: (state) => state.main.topBannerHeight, // 전체상품 중분류 탭 리스트

      /////////////////////////////////////////////////////////////////////////////
      // 전역 store
      userId: (state) => state.userId, // 사용자 아이디
      userAddress: (state) => state.userAddress,
      userCartTotalCount: (state) => state.userCartTotalCount, // 장바구니 카운터
      appVersion: (state) => state.appVersion, // 장바구니 카운터
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper; // 배너
    },
    bannerData() {
      return this.$store.state.main.banners;
    },
  },
  methods: {
    ...mapActions("product", ["goBack"]),
    ...mapMutations("main", [
      "setSlideActiveIndex", // 슬라이딩 활성화 인덱스
      "moveToMarket", // 시장 메인으로 이동 - 더보기 클릭시
      "moveToMarketMap", // 시장 지도
      "moveToFavoritesStore",
      "moveToAddress",
      "moveToNativeSearch",
      "moveToCart",
      "reset", // 페이지 이동시 초기화
      "setAllmenu",
      "closeAllMenu",
      "setPreviousRoutePath",
      "initHtmlScrollTop",
      "setTabBarOptions",
    ]),
    ...mapActions("main", [
      "initState",
      "goBack",
      "setContentsType", // 상단 탭 클릭
      "clickCategory",
      "changeEventTabItem",
      "clickEvent", // 이벤트 클릭 목록
      "clickBanner", // 클릭 배너
      "clickExplain", // 공지사항
      "loadTopBanner",
      "scrollEvent",
      "onBackPressedEvent", // 안드로이드 백 버튼 클릭 시 이벤트
      "mainWrapTouchStart",
      "mainWrapTouchMove",
      "mainWrapTouchEnd",
      "setTopBanner",
      "getEventList",
      "switchMainTabLargeTabActiveIndex",
      "switchSeaTabLargeTabActiveIndex",
      "switchBrandTabLargeTabActiveIndex",
      "switchMainTabLargeTabActiveIndex",
      "specialTabLargeTabActiveIndex",
    ]),
    onClickLargeTab(switchLargeTabIndex) {
      this.showMedium = switchLargeTabIndex !== 0;
      this.$emit("clickLargeTab", switchLargeTabIndex);
    },
    fetchAndDispatchBanners() {
      this.$store.dispatch("main/getEventList");
      this.$store.dispatch("main/allSpecialOffers");
    },
    onBackPressed() {
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        this.onBackPressedEvent(() => {
          this.goBack();
        });
      }
    },
    touchEnd() {
      // 슬라이딩 배너에서 사용자 터치가 종료 되었을때 autoplay 적용
      const swiper = this.$refs[`brandSwiper_${this.activeTabItem}`][0].$swiper;
      swiper?.autoplay?.stop();
      setTimeout(function () {
        swiper?.autoplay.start();
      }, 1000);
    },
    onSlideChange() {
      // 현재 슬라이드 index 설정
      //this.setSlideActiveIndex((this.$refs.mySwiper.$swiper.activeIndex % this.banners.length) + 1);
    },
    tabClick: function (data) {
      this.$emit("orderTab", data);
    },
    setPacking() {
      this.$emit("orderTab", { dataType: "PACKING", marketId: this.marketId });
    },
    checkMarketTime() {
      const date = new Date();
      const hour = date.getHours();

      // 인천계산, 논산, 언양알프스 5시 이후 포장만 가능
      return (this.marketId === 33 || this.marketId === 45 || this.marketId === 42) && hour >= 17;
    },
  },
  mounted() {
    this.fetchAndDispatchBanners();

    if ((!this.isNotWeekend || this.checkMarketTime()) && this.market.orderType === "BD") {
      this.setPacking();
    }
  },
  watch: {
    banners(val) {
      if (val.length) {
        this.swiper.autoplay.start();
      }
    },
  },
  created() {
    window.initParams = this.initParams;
    window.onBackPressed = this.onBackPressed;

    window.removeEventListener("scroll", this.watchScrollY);
    window.removeEventListener("resize", this.loadTopBanner);
    window.addEventListener("scroll", this.watchScrollY);
    window.addEventListener("resize", this.loadTopBanner);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.watchScrollY);
    window.removeEventListener("resize", this.loadTopBanner);
  },
};
</script>

<style lang="scss" scoped>
.main_wrap {
  &.fixed {
    .main_header_wrap {
      //position: fixed;
      width: 100%;
      top: 0;
    }

    .main_contents {
      background-color: #f6f6f6;
      padding-top: 100px;
      //padding: 146px 0 0;
    }

    .web_contents {
      //padding-top: 100px;
    }
  }

  .scroll_top {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 7%;
    right: 15px;
    width: 70px;
    height: 70px;
    z-index: 999;
    transition: 0.5s ease-out;

    &.active {
      visibility: visible;
      opacity: 1;
      bottom: 90px;
    }

    img {
      position: absolute;
      width: 70px;
      height: 70px;
      cursor: pointer;
      object-fit: contain;
    }
  }
}
.top_banner {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #ddd;
}

.main_header_wrap {
  position: relative;
  background-color: $white;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  z-index: 97;

  &.fixed {
    position: fixed;
    width: 100%;
  }

  .main_header {
    position: relative;
    height: 58px;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    background-color: #ff563f;

    .header_logo {
      display: inline-block;
      width: 110px;
      height: 37px;
      margin: 10px 0 0 19px;
      background-repeat: no-repeat;
      background-position: 0;
      background-size: contain;
      text-indent: -9999px;
      background-image: url("~image/noljang_white.svg");
    }

    .absolute_right {
      top: 18px;
      right: 25px;
    }
  }

  .main_address {
    display: inline-block;
    position: relative;
    height: 100%;
    max-width: 40%;
    background-image: url("~svg/marker.svg");
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 20px 20px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;
      width: 10px;
      height: 10px;
      background-image: url("~svg/arrow_down.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    > a {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      margin-left: 35px;
      @include font(14, 20, -0.64, 400);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address {
      position: relative;
      color: white;
      font-weight: bold;
    }
  }
}

:root {
  --md-theme-default-primary: $gray;
  --md-theme-default-accent: $gray;
  --md-theme-default-theme: $gray;
}

.md-theme-default .main_address a:not(.md-button):hover {
  color: $gray !important;
  color: var(--md-theme-default-primary-on-background, $gray) !important;
  //color: $gray;
  //&:hover, &:active {
  //  color: $gray !important;
  //}
}

.main_contents {
  position: relative;
  top: unset;
  bottom: unset;
  overflow-y: unset;
  /*
  position: absolute;
  top: 146px;
  bottom: 80px;
  width: 100%;
  background-color: $background-color-type1;
  overflow-x: hidden;
  overflow-y: auto;
  */
  &.bg_white {
    background-color: $white;
  }
}
.main_contents_unit {
  margin: 10px 0 0;
  background-color: $white;
  &.type1 {
    padding: 0 0 15px;
  }
}
.main_contents_title {
  position: relative;
  padding: 0 0 0 15px;
  background-color: $white;

  &.type1 {
    min-height: 54px;
    padding: 17px 16px 10px 120px;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    .title {
      position: absolute;
      top: 15px;
      left: 15px;
      @include font(17, 24, 0.4, bold);
      &.icon_direct {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_direct.svg");
        background-repeat: no-repeat;
        background-position: 4px 0;
      }
      &.icon_anywhere {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_anywhere.svg");
        background-repeat: no-repeat;
        background-position: 2px 2px;
      }
      &.icon_food_delivery {
        padding: 0 0 0 34px;
        background-image: url("~svg/icon_food_delivery.svg");
        background-repeat: no-repeat;
        background-position: 2px 0;
      }
    }
    .txt {
      position: relative;
      padding: 0 0 0 20px;
      @include font(14, 20, 0);
      color: $black;
      //> span {
      //  margin: 0 7px;
      //}
    }
    .unit_line {
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 7px;
      width: 1px;
      height: 13px;
      background-color: $black;
    }
  }
  .add_type1 {
  }
  .absolute_right {
    right: 15px;
  }
}

.empty_favorites_store {
  padding: 28px 15px 40px 100px;
  background-image: url("~svg/icon_empty_favorites_store.svg");
  background-repeat: no-repeat;
  background-position: 20px 26px;
}
.empty_recent_buy {
  padding: 28px 15px 40px 100px;
  background-image: url("~svg/icon_empty_recent_buy.svg");
  background-repeat: no-repeat;
  background-position: 20px 26px;
}
.empty_favorites_store,
.empty_recent_buy {
  .accent_txt {
    @include font(16, 22, 0);
    color: $black;
    span {
      font-size: 18px;
      //@include font(18, 24, 0);
    }
  }
  .txt {
    margin: 11px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
}

.swiper-container,
.swiper-slide {
  //max-height: 347px;
  background-color: $white;
  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.banner_wrap {
  position: relative;
  &.absolute_hide {
    position: absolute;
    top: -99999px;
  }
  //max-height: 347px;
  .absolute_right {
    position: absolute;
    top: auto;
    bottom: 20px;
    right: 15px;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, 0.5);
    @include border-radius(15px);
    @include font(13, 24, -0.2);
    color: $white;
    z-index: 95;
    &:hover {
      color: $white;
    }
  }
  img {
    width: 100%;
    height: 100%;
    //max-height: 347px;
    object-fit: cover;
  }
}
.tab_wrap {
  background-color: $white;

  .event_tab {
    padding-top: 20px;
  }
}
.copyright {
  padding: 17px 17px 100px 17px;
  @include font(12, 18, -0.48);
  color: $gray-light;
  background-color: #f4f4f4;
}
.banner_list {
  li {
    padding: 0px 0px 10px 0px;
    text-align: center;
    &:last-child {
      padding: 0px;
    }
  }
}
.explain_txt {
  display: none;
  padding: 0 0 18px;
  @include font(14, 24, 0);
  color: $gray-normal;
  white-space: pre-line;
  &.active {
    display: block;
  }
}

.event_txt_list {
  padding: 5px 16px 0;
  > li {
    position: relative;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
    &:last-child {
      border-width: 0;
    }
    .title {
      display: block;
      padding: 18px 80px 10px 0;
      @include font(15, 20, 0.37, medium);
      color: $black;
      &:hover,
      &:active {
        color: $black;
      }
    }
    .date {
      position: absolute;
      top: 18px;
      right: 0;
      @include font(14, 20, 0.37);
      font-weight: normal;
      color: $gray-normal;
    }
  }

  .md-theme-default a:not(.md-button) {
    color: #000 !important;
  }
}
#marketsList {
  margin-top: 200px;
}

.deliveryMarkets_wrap {
  &.fixed {
    .deliveryMarkets_tab_wrap {
      position: fixed;
      top: 147px;
      z-index: 999;
      width: 100vw;
    }
  }
  .deliveryMarkets_tab_wrap {
    margin-bottom: 1px;
    .deliveryMarkets_type_tab_list {
      display: flex;
      height: 42px;
      .deliveryMarkets_type_tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        transition: background-color 0.15s;
        @include font(16, 16, 0, normal);
        background-color: $background-color-type1;
        color: $placeholder-color;

        &.blue {
          background-color: #0070d8;
          color: white;
          font-size: 0;
          background-size: 70px;
          background-repeat: no-repeat;
          background-position: center;

          &:first-child {
            background-image: url("~image/sea-m-2.svg");
          }

          &:last-child {
            background-size: 55px;
            background-image: url("~image/sea-b-2.svg");
          }
        }

        &.blue.active {
          background-color: white;
          color: #0070d8;
          font-size: 0;
          &:first-child {
            background-image: url("~image/sea-m-1.svg");
          }

          &:last-child {
            background-image: url("~image/sea-b-1.svg");
          }
        }

        &.green {
          background-color: green;
          color: white;
        }

        &.green.active {
          background-color: white;
          color: green;
        }

        &.active {
          @include font(16, 16, 0, bold);
          background-color: $white;
          color: $active-color;
          &.markets {
            border: {
              style: solid;
              width: 1px;
              color: transparent;
              left-color: $border-type1;
            }
          }
        }
      }
    }
  }
}

.delivery_market_products_wrap {
  min-height: calc(100vh - 318px);
}
.cart_list {
  > li {
    padding: 0 16px;
    //padding: 0 16px 10px;

    &:first-child {
      margin: 0;
    }
    //border: {
    //  style: solid;
    //  color: $border-type2;
    //  width: 0 0 1px;
    //}
  }
}

.cash_delivery {
  display: inline-block;
  padding: 1px 7px 0px;
  margin: -3px 5px 0;
  background-color: $active-color;
  @include border-radius(6px);
  @include font(13, 20);
  color: white;
  vertical-align: middle;
}

.market_name {
  position: relative;
  height: 59px;
  padding: 20px 16px 0;
  background-color: $white;
  border: {
    style: solid;
    //color: $border-type1;
    color: $gray-light;
    width: 0 0 1px;
  }

  .name {
    @include font(16, 18, -0.64, bold);
    color: $black;
  }

  .absolute_right {
    top: 16px;
    right: 16px;
    @include font(14, 18, -0.64, bold);
    .accent {
      margin: 0 0 0 3px;
      color: $active-color;
    }
    .normal {
      font-weight: normal;
    }
  }
}

.order_total_info {
  .guide_txt_wrap {
    //min-height: 36px;
    padding: 0 10px 0 20px;
    background-image: url("~svg/icon_reply.svg");
    background-position: 7px 2px;
    background-repeat: no-repeat;
    color: $gray-light;
  }

  &.type_tb {
    padding: 0 0 10px;
    margin: 20px 15px 0;
    background-color: $nj_background_gray;
    @include border-radius(5px);
  }
  &.type_bd {
    > ul {
      padding: 5px 0;
      margin: 0px 15px 0;
      border: {
        style: solid;
        color: $border-type1;
        width: 0 0 1px;
      }
      li {
        padding: 0;
        color: $gray;
        .title {
          padding: 10px 0 0;
        }

        .absolute_right {
          top: 0;
          right: 0;
        }

        .price {
          font-family: "gothic_a1_medium";
        }
      }
    }
  }
  li {
    position: relative;
    padding: 0 16px;
    .title {
      display: inline-block;
      padding: 20px 0 0;
      @include font(14, 18, -0.64, bold);
      //color: $gray-light;
      &.accent {
        font-size: 16px;
        color: $active-color;
      }
    }
    .absolute_right {
      top: 10px;
      right: 16px;
      .price {
        display: inline-block;
        font-size: 18px;
        font-family: "gothic_a1_extrabold";
        vertical-align: middle;
      }
      .won {
        display: inline-block;
        margin-left: 2px;
        @include font(14, 24, -0.64);
        vertical-align: middle;
      }
      .free {
        display: inline-block;
        padding: 5px 0 0 0;
        @include font(16, 20, -0.5);
      }
    }
  }
}

.order_total_price {
  position: relative;
  padding: 0 16px;
  .title {
    display: inline-block;
    padding: 20px 0 0;
    @include font(16, 18, -0.64, bold);
  }
  .absolute_right {
    top: 10px;
    right: 16px;
    .price {
      font-family: "gothic_a1_extrabold";
      font-size: 22px;
    }
    .won {
      @include font(19, 24, -0.64);
    }
  }
  &.type1,
  &.type2 {
    .title {
      @include font(14, 18, -0.64, bold);
      color: $gray-light;
    }
    .absolute_right {
      .price {
        font-size: 18px;
      }
      .won {
        @include font(14, 24, -0.64);
      }
    }
  }
  /*&.type2 {
      .title {
        padding: 10px 0 0;
      }
      .absolute_right {
        top: 0;
      }
    }*/
}

.delivery_date_info_wrap {
  margin-top: 20px;
  padding: 0 16px;
  //line-height: 1.5;
  > .delivery_date_info {
    padding: 12px 10px;
    @include font(14, 20, -0.48);
    color: $gray-light;
    background-color: $background-color-type1;
    text-align: center;
    border-radius: 18px;
  }
}

.btn_wrap {
  padding: 20px 16px;

  .tab-wrap {
    .tab_list {
      .tab {
        &:first-child {
          a {
            border-radius: 10px 0 0 10px !important;
          }
        }
        &:nth-child(2) {
          a {
            border-radius: 0 10px 10px 0 !important;
          }
        }
      }
    }
  }
}
</style>
