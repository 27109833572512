<template>
  <div>
    <Layer :displayLayer="displayLayer" displayType="center" :closeLayer="showHideLayer">
      <template v-slot:content>
        <div class="layer_contents">
          <div class="layer_title" v-if="title">{{ title }}</div>
          <p class="layer_txt" :class="textClass[index] ? textClass[index] : ''" v-for="(txt, index) in texts" :key="index" v-html="txt"></p>
        </div>
        <ul class="type1 mt_10" :class="btns.length == 2 ? 'half_list' : ''">
          <li v-for="(btn, index) in btns" :key="index">
            <Btn
              :type="getClass(btn.type ? btn.type : '', index)"
              :btnId="btn.btnId ? btn.btnId : null"
              :data="btn.data ? btn.data : null"
              :value="btn.value ? btn.value : null"
              @btnCallback="showHideLayer"
            />
          </li>
        </ul>
      </template>
    </Layer>
  </div>
</template>

<script>
import Layer from "@/components/Layer";
import Btn from "@/components/Btn";

export default {
  name: "Alert",
  props: {
    displayLayer: Boolean,
    title: String,
    texts: Array,
    textClass: Array,
    btns: Array,
    showHideLayer: Function,
  },
  beforeCreate: function () {},
  computed: {},
  components: {
    Layer,
    Btn,
  },
  methods: {
    getClass: function (type, index) {
      // console.log("getClass ::: " + type);
      if (type) {
        return type;
      }
      if (this.btns.length == 1) {
        return "type1 reverse";
      } else {
        if (index == 0) {
          return "type1 none_border";
        }
        if (index == 1) {
          return "type1 reverse";
        }
      }
      return "type1 none_border";
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100%;
  background-color: $nj_background_gray;
}
.contents_wrap {
  position: absolute;
  top: 44px;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.contents_area {
  padding: {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}
p {
  margin: 0;
  @include font(16, 24, -0.32);
  color: $nj_text_main_gray;
}
.absolute_bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.layer_title {
  margin: 10px 0 0;
  @include font(20, 26, -1.2, bold);
  color: $nj_black;
}
.layer_txt {
  margin: 20px 0 0;
  @include font(16, 24, -0.96);
  color: $nj_text_sub_gray;
}
</style>
