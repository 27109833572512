<template>
  <div class="list_contents">
    <ServiceCenterItem v-for="faq in faqs" v-bind:key="faq.id" :id="faq.id" :title="faq.title" :content="faq.description" />
  </div>
</template>

<script>
import ServiceCenterItem from "@/components/new/ServiceCenterItem";

export default {
  name: "ServiceCenterList",
  components: {
    ServiceCenterItem,
  },
  props: {
    faqs: Array,
  },

  data: function () {
    return {
      isProgressing: false,
      curPage: 0,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.list_contents {
  //padding: 20px;
  padding: 10px 16px 120px;
}
</style>
