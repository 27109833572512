<template>
  <div class="market_item">
    <div v-for="market in markets" :key="market.marketId">
      <div class="contents_title" :class="!clickCategory ? 'type1' : null">
        <a href="javascript:void(0);" :data-market-id="market.marketId" :data-market-name="market.marketName" @click="moveToMarket">
          <strong class="title">{{ market.marketName }}</strong>
        </a>
        <span v-if="market.distance" class="icon_view_map">{{ market.distance }}</span>
        <a
          href="javascript:void(0);"
          class="icon_more"
          :data-market-id="market.marketId"
          :data-market-name="market.marketName"
          @click="moveToMarket"
          title="더보기"
          >더보기</a
        >
      </div>

      <div class="tab_wrap type1" v-if="market[market.contentsType ? market.contentsType : 'products']">
        <!-- 카테고리 클릭 이벤트(clickCategory)가 있을때만 노출 -->
        <TabList :tabItems="market.categoryTabItems" :data="{ marketId: market.marketId }" type="type1" @click="clickTab" />
      </div>
      <div v-if="quickMarket == 'N' && market[market.contentsType ? market.contentsType : 'products']">
        <div class="product_list type1" v-if="market.contentsType != 'marketEventList'">
          <ProductList :products="market[market.contentsType ? market.contentsType : 'products']" :marketId="market.marketId" />
        </div>
        <div v-else-if="market.contentsType == 'marketEventList'">
          <ol class="market_event_list">
            <li v-for="(event, index) in market.marketEventList" :key="event.eventId">
              <a
                href="javascript:void(0);"
                class="list_area"
                :data-title="event.eventName"
                @click="moveToMarketEvent(event.eventDetailUrl, event.eventName)"
                v-if="event.eventDetailUrl"
              >
                <span class="circle">{{ $formatZeroNumber(index + 1) }}</span>
                {{ event.eventName }}
                <span class="date"
                  >{{ $moment(event.startDateTime).format("YYYY.MM.DD") }} - {{ $moment(event.endDateTime).format("YYYY.MM.DD") }}</span
                >
              </a>
              <span class="list_area" v-else>
                <span class="circle">{{ $formatZeroNumber(index + 1) }}</span>
                {{ event.eventName }}
                <span class="date"
                  >{{ $moment(event.startDateTime).format("YYYY.MM.DD") }} - {{ $moment(event.endDateTime).format("YYYY.MM.DD") }}</span
                >
              </span>
            </li>
          </ol>
        </div>
      </div>

      <div v-else-if="quickMarket == 'Y'">
        <div class="store_list type2 horizontal_list">
          <StoreList :stores="market.deliveryFdStoreList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TabList from "@/components/new/TabList";
import ProductList from "@/components/new/ProductList";
import StoreList from "@/components/new/StoreList";

export default {
  name: "MarketItem",
  props: {
    markets: Array,
    categoryTabItems: Array,
    moveToMarket: Function,
    moveToMarketMap: Function,
    clickCategory: Function,
    quickMarket: {
      type: String,
      default: "N",
    },
  },
  methods: {
    moveToMarketEvent: function (detailUrl, title) {
      // console.log(detailUrl);
      // this.$navigateTo('event-detail', {url: detailUrl, title: title});
      this.$navigateTo("open-url", { title: title, url: detailUrl });
      // this.$callNative('event-detail', {url: url});
      // this.$callNative('event-detail', {url: url}, false);
    },
    clickEvent: function (event) {
      // console.log(event.target.classList);
      // console.log(event.target.classList.contains("store_name"));
    },
    clickTab: function (data) {
      // console.log('clickTab ::::: ');
      // console.log(data);
      this.clickCategory(data);
    },
  },
  data: function () {
    return {};
  },
  components: {
    TabList,
    ProductList,
    StoreList,
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.tab_wrap {
  padding: 0 15px;
  border: {
    style: solid;
    color: $border-type1;
    width: 0 0 1px;
  }
  overflow-x: auto;
  &.type1 {
    border-width: 0;
  }
}
.market_event_list {
  padding: 10px 16px;
  > li {
    margin: 0 0 10px;
    .list_area {
      display: block;
      position: relative;
      min-height: 37px;
      padding: 3px 0 0 48px;
      @include font(14, 16, -0.56, bold);
      color: $gray-dark;
      &:hover,
      &:active {
        color: $gray-dark;
      }
    }
    .circle {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 37px;
      height: 37px;
      padding: 10px 0 0;
      background-image: url("~svg/txt_event.svg");
      background-repeat: no-repeat;
      background-position: center 7px;
      border: {
        style: solid;
        color: $border-type1;
        width: 1px;
      }
      font-family: "gothic_a1_bold";
      font-size: 16px;
      letter-spacing: -0.64px;
      color: $active-color;
      @include border-radius(20px);
      text-align: center;
    }
    .date {
      display: block;
      font-family: "gothic_a1_regular";
      font-size: 12px;
      font-weight: 100;
      letter-spacing: -0.24px;
      color: $gray-normal;
    }
  }
}
</style>
