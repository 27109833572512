<template>
  <div>
    <TopHeader class="top_header" title="고객센터" @goBack="goBack" :isCartVisible="false" />
    <div class="contents">
      <p class="contents_header">
        놀장 고객센터 상담 가능한 시간은 <br />
        <strong class="accent">평일 09:30~18:30 (점심시간 12:30~14:00)</strong> 입니다.
      </p>

      <ServiceCenterList :faqs="faqs" />

      <div v-if="appVersion" class="call_center">
        <div class="cs_talk">
          <a href="javascript:void(0)" title="카카오톡 문의" @click="goKakaoTalk"><img src="~svg/icon_cs_talk.svg" /></a>
        </div>
        <div class="cs_call">
          <a href="javascript:void(0)" title="전화 문의" @click="goCall"><img src="~svg/icon_cs_call.svg" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import ServiceCenterList from "@/components/new/ServiceCenterList";
import { mapState } from "vuex";

export default {
  name: "ServiceCenter",
  props: {},
  data: function () {
    return {
      faqs: [],
      tel: this.$getTelNumber(),
    };
  },
  computed: {
    ...mapState({
      customerServiceCenterNumber: (state) => state.customerServiceCenterNumber,
      appVersion: (state) => state.appVersion,
    }),
  },
  components: {
    ServiceCenterList,
    TopHeader,
  },
  methods: {
    onBackPressed: function () {
      // console.log("onBackPressed");
      // this.$router.back();
      this.$navigateBack();
    },

    goBack: function () {
      this.$router.push({
        name: "놀장 메인",
      });
    },
    goCall: function () {
      this.$callNative("goCall", { tel: this.customerServiceCenterNumber });
    },
    goKakaoTalk: function () {
      this.$callNative("openKakaoChannel");
      // this.$callNative('openKaKaoChannel', null, true);
    },
  },

  created() {},
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
  beforeCreate: function () {
    const axiosUrl = "/api/getFaq";
    this.$axiosLegacy
      .post(axiosUrl)
      .then((response) => {
        // console.log(response);
        if (response.data.resultCode == 200) {
          this.faqs = response.data.result.csList;
          // console.log("csList ::::");
          this.isProgressing = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this.isProgressing = false;
      })
      .catch(() => {
        this.isProgressing = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.contents {
  position: relative;
  background-color: $white;
  overflow-x: auto;
}

.contents_header {
  height: 61px;
  padding: 12px 0 0;
  background-color: $background-color-type1;
  border: {
    width: 0 0 1px 0;
    style: solid;
    color: $border-type1;
  }
  @include font(13, 18, 0, bold);
  text-align: center;
  .accent {
    color: $active-color;
  }
}

.ios {
  .contents_header {
    height: 61px;
    padding: 9px 0 0;
    background-color: $background-color-type1;
    border: {
      width: 0 0 1px 0;
      style: solid;
      color: $border-type1;
    }
    @include font(13, 18, 0, bold);
    text-align: center;
    .accent {
      color: $active-color;
    }
  }
}

.call_center {
  position: fixed;
  right: 20px;
  bottom: 40px;

  .cs_talk {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
  }
  .cs_call {
    width: 100%;
    height: 100%;
  }
}
</style>
