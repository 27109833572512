<template>
  <div>
    <!--    상단 검색 영역 -->
    <div class="input_wrapper">
      <input
        class="search_input"
        v-bind:value="keyword"
        v-on:input="onInput($event.target.value)"
        v-on:keydown="onSearchInputKeyDown"
        placeholder="건물명, 도로명 또는 지번으로 검색"
        v-on:focus="isSearching = true"
        v-on:blur="isSearching = false"
      />
      <div class="btn_search_wrap">
        <Btn type="search_red" value="검색" @btnCallback="search" />
      </div>
    </div>
    <!--    하단 리스트 영역-->
    <div class="list_wrap" @scroll="handleScroll">
      <!--      검색어 입력 중일 경우 -->
      <div class="search_tip_wrap" v-if="isSearching">
        <span class="search_tip_title">검색TIP</span>
        <div class="search_tip">
          <span class="subject">* 도로명 + 건물번호</span>
          <span class="example">ex) 송파대로 570</span>
        </div>
        <div class="search_tip">
          <span class="subject">* 동/읍/면/리 + 건물번호</span>
          <span class="example">ex) 신천동 7-30</span>
        </div>
        <div class="search_tip">
          <span class="subject">* 건물명, 아파트명</span>
          <span class="example">ex) 우리집으로가자아파트</span>
        </div>
      </div>
      <div v-else>
        <!--        주소 데이터 검색 중-->
        <LoadingIndicator v-show="isProgressing" />
        <div>
          <!--          주소 검색 중인 경우-->
          <template v-if="keyword">
            <!--          검색된 주소 데이터가 있을 경우 -->
            <div v-if="addressSearchList.length > 0">
              <div v-for="(addrItem, idx) in addressSearchList" v-bind:key="idx">
                <DeliverySearchAddrItem
                  :shipToDoro="addrItem.addressDoro"
                  :shipToJibun="addrItem.addressJibun"
                  v-bind:class="{ first: idx == 0 }"
                ></DeliverySearchAddrItem>
              </div>
            </div>
            <div class="no_delv_area_msg" v-else-if="!isProgressing">
              <span>검색된 주소가 없습니다. 다른 주소를 검색해주세요.</span>
            </div>
          </template>
          <!--         주소 검색 중이 아닌 경우 -->
          <template v-else>
            <div>
              <div class="current_ship_to" v-if="currentAddress && Object.keys(currentAddress).length > 0">
                <div class="ship_to_name">{{ currentAddress.shipToName }}</div>
                <div class="ship_to_full">{{ currentAddress.shipToFull }}</div>
              </div>
              <div v-if="addressList && addressList.length > 0">
                <span class="recent_addr">최근주소</span>
                <div v-for="(addrItem, idx) in addressList" v-bind:key="idx">
                  <DeliveryAddrItem
                    :shipToId="addrItem.shipToId"
                    :shipToJibun="addrItem.shipToJibun"
                    :shipToDoro="addrItem.shipToDoro"
                    :shipToDetail="addrItem.shipToDetail"
                    :shipToName="addrItem.shipToName"
                  />
                </div>
              </div>
              <div class="no_delv_area_msg" v-if="!Object.keys(currentAddress).length">
                <span v-if="isAddressRequired">배송지와 가까운 시장, 이벤트, 판매상품을<br />알려드리기 위해 배송지 설정이 필요합니다.</span>
                <span v-else>설정된 주소가 없습니다. 주소를 검색해주세요.</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import DeliveryAddrItem from "./DeliveryAddrItem";
import DeliverySearchAddrItem from "./DeliverySearchAddrItem";
import Btn from "@/components/Btn";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "DeliveryAddrList",
  props: {},
  data: function () {
    return {
      isSearching: false, // 검색 Input 수정 여부
    };
  },
  components: {
    LoadingIndicator,
    DeliveryAddrItem,
    DeliverySearchAddrItem,
    Btn,
  },
  methods: {
    ...mapActions("deliveryAddr", ["handleScroll", "setKeyword", "searchAddressList", "setCurrentPage"]),
    onInput: function (value) {
      this.isSearching = true;
      this.setKeyword(value);
    },
    onSearchInputKeyDown: function (e) {
      if (e.keyCode == 13) {
        this.search();
      }
    },
    search: function () {
      this.isSearching = false;
      this.setCurrentPage(1);
    },
  },
  computed: {
    ...mapState("deliveryAddr", [
      "currentAddress",
      "addressList",
      "addressSearchList",
      "currentPage",
      "pageInformation",
      "isProgressing",
      "keyword",
      "userId",
    ]),
    ...mapGetters({
      isAddressRequired: "deliveryAddr/isAddressRequired",
    }),
  },
};
</script>

<style lang="scss" scoped src="../DeliveryAddr.scss" />
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
* {
  letter-spacing: -0.96px;
  font-size: 16px;
}

.no_delv_area_msg {
  margin-top: 20px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: $nj_text_main_gray;
}

.no_delv_area_msg > span {
  color: $nj_text_main_gray;
}

.list_wrap {
  height: calc(100% - 100px);
  overflow: scroll;
}

.current_ship_to {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid $nj_divider_gray;
}

.current_ship_to > * {
  display: inline-block;
  letter-spacing: -0.96px;
}

.current_ship_to .ship_to_name {
  font: normal normal normal 16px/24px Noto Sans CJK KR Bold;
  color: $nj_red;
  margin-right: 10px;
}

.current_ship_to .ship_to_full {
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  color: $nj_black;
}

.recent_addr {
  display: block;
  font: normal normal normal 16px/24px Noto Sans CJK KR Bold;
  letter-spacing: -0.96px;
  color: $nj_black;
  margin-bottom: 20px;
}

.pagination_wrap {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: $nj_divider_gray;
  padding: 10px 20px;
  border-radius: 20px;
}

.pagination_wrap > * {
  display: inline-block;
}

.pagination_wrap > .pagination_count {
  padding: 0 20px;
}

.search_tip_wrap .search_tip_title {
  color: $nj_black;
  font: normal normal normal 16px/24px Noto Sans CJK KR Bold;
}

.search_tip_wrap > .search_tip {
  padding-top: 10px;
}

.search_tip_wrap > .search_tip > span {
  display: block;
}

.search_tip_wrap > .search_tip > .subject {
  color: $nj_text_main_gray;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
}

.search_tip_wrap > .search_tip > .example {
  color: $nj_placeholder_gray;
  padding-left: 8px;
  font: normal normal normal 16px/24px Noto Sans CJK KR;
  letter-spacing: -0.96px;
}
</style>
