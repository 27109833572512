<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <TopHeader class="top_header" title="찜 리스트" @goBack="goBack" />
    <div class="contents">
      <div class="product_list_wrap type2">
        <div class="product_list vertical">
          <ProductList
            :products="likeProduct"
            :displayLikeBtn="true"
            :callbackRemoveLike="callbackRemoveLike"
            v-if="likeProduct && likeProduct.length > 0"
          />
          <div v-else>
            <div class="msg_area" v-if="isProgressing == false">
              <p class="like_null">찜한 상품이 없습니다.</p>
              <p class="like_null msg">
                상품정보 페이지에서 <br />
                마음에 드는 상품을 찜 해 보세요.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div v-else>-->
    <!--      <div class="empty_recent_buy">-->
    <!--        <strong class="accent_txt">고객님, 아직 구매하신 상품이 없네요.</strong>-->
    <!--        <p class="txt">시장에서 당일 준비된 값 싸고, 신선한 상품을 구매해보세요. 주문 후 2시간 이내에 고객님의 문 앞까지 배달됩니다.<br/>주문시간 : 오전 10시 ~ 오후 5시</p>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import TabList from "@/components/new/TabList";
import ProductList from "@/components/new/ProductList";
import LoadingIndicator from "@/components/new/LoadingIndicator";

import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "Like",
  computed: {
    ...mapState({
      userId: (state) => state.like.markets,
      tabItems: (state) => state.like.tabItems,
      likeProduct: (state) => state.like.likeProduct,
      isProgressing: (state) => state.like.isProgressing,
    }),
  },
  components: {
    ProductList,
    TopHeader,
    TabList,
    LoadingIndicator,
  },
  methods: {
    ...mapMutations("like", ["goBack"]),
    ...mapActions("like", ["initState", "clickTab", "callbackRemoveLike"]),
    onBackPressed: function () {
      this.goBack();
    },
  },
  beforeCreate() {
    this.$store.dispatch("like/initState", this.$route.params.mypage);
  },
  // created() {
  // },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
};
</script>

<style lang="scss" scoped>
.contents {
  height: calc(100vh - 58px - 43px);
  background-color: $white;
}

.tab_wrap {
  border-bottom: 1px solid $background-color-type3;
  height: 42px;
  padding: 0 15px;
  background-color: $white;
  //overflow-x: auto;

  .active {
    padding: 20px;
  }

  &.default {
    background-color: $background-color-type2;
    border: {
      style: solid;
      color: $border-type1;
      width: 0 0 1px;
    }
  }
}

.product_list_wrap {
  padding: 21px 0;
}
.msg_area {
  position: relative;
  top: 120px;

  .like_null {
    @include font(20, 24, 0, medium);
    text-align: center;

    &.msg {
      padding-top: 20px;
      @include font(16, 20, 0, thin);
      text-align: center;
      color: $gray;
    }
  }
}
</style>
