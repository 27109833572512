<template>
  <div :class="'list_wrap list_wrap_' + this.tabId">
    <div class="list_title">{{ label }}</div>
    <template v-if="products.length > 0">
      <template v-if="tabId == 1">
        <div class="main_product_wrap" v-for="product in products" v-bind:key="product.productId">
          <div class="img_wrap" @click="moveToProduct(product.productId)">
            <img :src="product.imgUrl ? products.imgUrl : product.productImageUrl" />
            <ul class="top_right">
              <li v-if="product.productLikeCount">
                <!-- 좋아요 -->
                <Icon type="icon_like_red_small" :value="product.productLikeCount.toString()" />
              </li>
              <li>
                <!-- 장바구니 -->
                <a href="javascript:void(0)" @click.stop="moveToCart(product.productId)">
                  <Icon type="icon_cart_white_small" :value="'장바구니 담기'" />
                </a>
              </li>
            </ul>
          </div>
          <div class="info_wrap">
            <div class="name">{{ product.productName }}</div>
            <div class="origin_price">
              <template v-if="product.discountPrice">
                {{ $formatPrice(product.price) }}
              </template>
            </div>
            <div class="price_wrap">
              <span class="price"> {{ $formatPrice(product.discountPrice ? product.discountPrice : product.price) }} </span>원
              <span class="unit"> {{ product.unitNumber }}{{ product.unitName }} </span>
            </div>
            <div class="tag_wrap">
              <div class="payment_type" v-for="paymentType in product.paymentTypeList" v-bind:key="paymentType.paymentName">
                - {{ paymentType.paymentName }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-for="product in products" v-bind:key="product.productId" class="product_item" v-else>
        <div class="img_wrap" @click="moveToProduct(product.productId)">
          <img :src="product.imgUrl ? product.imgUrl : product.productImageUrl" />
          <ul class="top_right">
            <li v-if="product.productLikeCount">
              <!-- 좋아요 -->
              <Icon type="icon_like_red_small" :value="product.productLikeCount.toString()" />
            </li>
            <li>
              <!-- 장바구니 -->
              <a href="javascript:void(0)" @click.stop="moveToCart(product.productId)">
                <Icon type="icon_cart_white_small" :value="'장바구니 담기'" />
              </a>
            </li>
          </ul>
        </div>
        <div class="info_wrap">
          <div class="name">{{ product.productName }}</div>
          <div class="origin_price">
            <template v-if="product.discountPrice">
              {{ $formatPrice(product.price) }}
            </template>
          </div>
          <div class="price_wrap">
            <span class="price"> {{ $formatPrice(product.discountPrice ? product.discountPrice : product.price) }} </span>원
            <span class="unit"> {{ product.unitNumber }}{{ product.unitName }} </span>
          </div>
          <div class="tag_wrap">
            <div class="payment_type" v-for="paymentType in product.paymentTypeList" v-bind:key="paymentType.paymentName">
              - {{ paymentType.paymentName }}
            </div>
            <!--          <div class="sale_info">~ 30%할인</div>-->
          </div>
        </div>
      </div>
    </template>
    <div v-else class="no_product_wrap">표시할 상품이 없습니다.</div>
  </div>
</template>

<script>
import Icon from "@/components/new/Icon";
import { mapActions } from "vuex";
export default {
  name: "StoreProductList",
  components: { Icon },
  props: {
    label: String,
    products: Array,
    tabId: Number,
  },
  data: function () {
    return {};
  },
  methods: {
    ...mapActions("deliveryStore", ["moveToApp"]),
    moveToCart: function (productId) {
      this.moveToApp({ type: "PRODUCT_OPTION", productId: productId });
    },
    moveToProduct: function (productId) {
      this.moveToApp({ type: "PRODUCT", productId: productId });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
/* 리스트 컨테이너 CSS */
.list_wrap {
  padding-top: 20px;
  border-bottom: 1px solid $nj_divider_gray;

  /* 리스트 타이틀 CSS */
  .list_title {
    font: normal normal bold 18px/28px Noto Sans CJK KR;
    letter-spacing: -1.08px;
    color: $nj_black;
    margin-bottom: 10px;
  }

  .no_product_wrap {
    width: 100%;
    padding-bottom: 10px;
    @include font(14, 32, -0.72, medium);
    color: $nj_text_sub_gray;
  }
}

/* 상품 공통 CSS */
.main_product_wrap {
  margin-bottom: 30px;

  & > * {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  & > div:first-child {
    padding-right: 10px;
  }

  & > div:last-child {
    padding-left: 10px;
  }

  .top_right {
    right: 10px !important;
  }

  .info_wrap {
    .price_wrap {
      margin-bottom: 23px;
    }
  }
}

.product_item {
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;
  vertical-align: top;

  &:nth-child(even) {
    padding-right: 10px;
  }

  &:nth-child(odd) {
    padding-left: 10px;
  }

  .info_wrap {
    .name {
      margin-top: 5px;
    }

    .price_wrap {
      margin-bottom: 10px;
    }
  }
}

.main_product_wrap,
.product_item {
  .img_wrap {
    position: relative;

    img {
      border-radius: 20px;
      width: 100%;
      height: 140px;
      object-fit: cover;
    }

    .top_right {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 30px;
      height: 38px;
      line-height: 2.3;
      padding: 0 10px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0 20px;
      > li {
        display: inline-block;
        &:nth-child(n + 2) {
          margin-left: 5px;
        }
      }
    }
  }

  .info_wrap {
    .name {
      margin-bottom: 2px;
      @include font(16, 24, -0.96, medium);
      color: $nj_black;
      @include text_ellipsis();
    }

    .origin_price {
      min-height: 25px;
      text-decoration: line-through;
      @include font(17, 25, -1.02);
      color: $nj_text_main_gray;
      margin-bottom: -4px;
    }

    .price_wrap {
      @include font(14, 24, -0.84);
      color: $nj_text_main_gray;

      .price {
        display: inline-block;
        margin-right: 2px;
        @include font(24, 28, -1.44, bold);
        color: $nj_black;
      }

      .unit {
        float: right;
        @include font(16, 28, -0.96);
        color: $nj_text_sub_gray;
      }
    }

    .tag_wrap {
      padding: 4px 0;
      border-width: 1px 0;
      border-color: #707070;
      border-style: solid;
      @include font(10, 15, -0.6);
      color: $nj_text_main_gray;

      .payment_type {
        display: inline-block;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
