<template>
  <a
    href="javascript:void(0)"
    :class="type ? type : 'type1'"
    :title="title ? title : value"
    @click="
      $emit('btnCallback', {
        btnId: btnId,
        data: data ? data : null,
        event: $event,
      })
    "
  >
    <template v-if="hasSlot">
      <slot></slot>
    </template>
    <template v-else>
      <span v-if="type == 'type_kakao'" class="icon_kakao"></span>
      {{ value }}
    </template>
  </a>
</template>

<script>
export default {
  name: "Btn",
  props: {
    type: String,
    value: String,
    btnId: String,
    data: null,
    title: String,
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
$type1-active-color: $active-color;
$black-active-color: #686868;
a {
  color: #555;
  &.inactive {
    background-color: #f1f1f1 !important;
    color: #999 !important;
  }
  &.font_weight_normal {
    font-weight: normal;
  }
}
.type1 {
  // 실반영 되었음
  display: inline-block;
  height: 35px;
  padding: 3px 10px;
  border: {
    style: solid;
    color: $border-type1;
    width: 1px;
  }
  font-size: 15px;
  color: $gray-dark !important;
  @include border-radius(5px);
  vertical-align: middle;
  &.inverse {
    background-color: $active-background-color;
    color: $white !important;
    border-color: $active-background-color;
  }
  &.accent {
    color: $type1-active-color !important;
    //font-weight: bold;
  }
  &.bold {
    font-weight: 600;
  }
}

// 회색 테두리, 흰 배경, 회색 텍스트
.type2 {
  display: inline-block;
  width: 100%;
  height: 54px;
  background-color: $btn-type2-background;
  border: {
    style: solid;
    color: $btn-type2-border;
    width: 1px;
  }
  color: $black !important;
  @include border-radius(20px);
  vertical-align: middle;
}

.type3 {
  display: inline-block;
  //width: 100%;
  height: 26px;
  padding: 3px 10px;
  background-color: white;
  border: {
    style: solid;
    color: #cecece;
    width: 1px;
  }
  @include font(14, 18, -0.5);
  @include border-radius(5px);
  color: #555 !important;
  text-align: center;
  vertical-align: middle;
  &.reverse {
    border-color: $active-color;
    background-color: $active-color;
    color: white !important;
  }
  &.none_border {
    border-width: 0;
  }
  &.invoice {
    height: 20px;
    padding: 2px 7px;
    @include font(12, 14, -0.5);
  }
}

.type4 {
  display: inline-block;
  width: 100%;
  //height: 44px;
  height: 54px;
  @include border-radius(20px);
  background-color: $active-color;
  color: #fff !important;
  vertical-align: middle;

  &.normal {
    height: 44px;
    margin: 30px 0 0;
    background-color: white;
    @include border-radius(10px);
    @include font(16, 36, 0);
    border-style: solid;
    border-color: $gray;
    border-width: 1px;
    color: $gray !important;
  }
  &.arrow_right {
    padding: 0 17px;
    margin: 20px 0 0;
    @include font(14, 42, 0);
    background-image: url("~svg/icon_arrow_right_red.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    color: $active-color !important;
    border-color: $active-color !important;
    &.arrow_down {
      background-image: url("~svg/icon_arrow_down.svg");
      border-color: #dcdcdc !important;
      //border-color: $gray;
      color: $gray !important;
    }
  }
  &.else2 {
    margin: 0;
    padding: 0 17px;
    color: $active-color !important;
    border-color: $active-color !important;
    @include font(16, 44, 0, bold);
    text-align: center;
  }

  &.else1 {
    height: 38px;
    margin: 30px 0 0;
    @include border-radius(10px);
    @include font(16, 36, 0);
  }

  &.percent_100 {
    width: 100%;
    @include font(16, 54, 0, bold);
  }
}
.type5 {
  display: inline-block;
  width: 100%;
  height: 54px;
  @include border-radius(15px);
  background-color: $type1-active-color;
  @include font(16, 54, 0, bold);
  color: #fff !important;
  text-align: center;
  vertical-align: middle;
}

.icon_kakao {
  display: inline-block;
  width: 30px;
  height: 24px;
  background-image: url("~img/icon_kakao.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 1px;
  vertical-align: middle;
}
.type_kakao {
  display: inline-block;
  width: 100%;
  height: 54px;
  @include border-radius(15px);
  background-color: #ffee00;
  @include font(16, 54, 0, bold);
  color: #2b1718 !important;
  text-align: center;
  vertical-align: middle;
}

.type6 {
  display: inline-block;
  //width: 100%;
  height: 26px;
  padding: 4px 10px;
  background-color: $white;
  border: {
    style: solid;
    color: $btn-type2-border;
    width: 1px;
  }
  @include font(14, 18, -0.5);
  @include border-radius(9px);
  color: #555 !important;
  text-align: center;
  vertical-align: middle;
  &.reverse {
    background-color: transparent;
    border-color: $white;
    font-weight: 100;
    color: $white !important;
  }
  &.none_border {
    border-width: 0;
  }
}
.type7 {
  display: inline-block;
  width: 100%;
  height: 54px;
  //padding: 18px 0;
  background-color: $background-color-type1;
  @include font(16);
  @include border-radius(20px);
  color: #555 !important;
  text-align: center;
  &.bold {
    font-weight: bold;
  }
}

// 결제하기 > 포인트 사용 > 상세
.type8 {
  display: inline-block;
  //width: 100%;
  height: 20px;
  padding: 0 10px;
  background-color: $white;
  border: {
    style: solid;
    color: $btn-type2-border;
    width: 1px;
  }
  @include font(14, 18, -0.5);
  @include border-radius(9px);
  color: #555 !important;
  text-align: center;
  vertical-align: middle;
  &.reverse {
    background-color: transparent;
    border-color: $white;
    font-weight: 100;
    color: $white !important;
  }
  &.none_border {
    border-width: 0;
  }
}

.slide_toggle {
  display: inline-block;
  width: 54px;
  height: 30px;
  margin: 4px 0;
  background-image: url("~svg/icon_toggle_off.svg");
  background-repeat: no-repeat;
  background-position: center 0;
  text-indent: -9999px;
  &.active {
    background-image: url("~svg/icon_toggle_on.svg");
  }
}
//.type5 {
//  display: inline-block;
//  height: 35px;
//  padding: 4px 10px;
//  border: {
//    style: solid;
//    color: $type1-active-color;
//    width: 1px;
//  }
//  @include border-radius(5px);
//  color: #333;
//  vertical-align: middle;
//
//  &.accent {
//    background-color: $type1-active-color;
//    color: #fff;
//  }
//  &.normal {
//    color: #999;
//  }
//}
//
//.type_up_down {
//  display: inline-block;
//  width: 40px;
//  height: 40px;
//  background-color: rgba(255,255,255,0.7);
//  border: {
//    style: solid;
//    color: #cecece;
//    width: 1px;
//  }
//  @include border-radius(5px);
//  text-indent: -99999px;
//
//  &.down {
//    //background-image: url('~images/arrow_down.png');
//    background-repeat: no-repeat;
//    background-position: center;
//  }
//  &.up {
//    //background-image: url('~images/arrow_up.png');
//    background-repeat: no-repeat;
//    background-position: center;
//  }
//}
//
//.absolute_right {
//  position: absolute;
//  top: 5px;
//  right: 5px;
//}
//
//.btn_txt {
//  display: inline-block;
//  padding: 5px 15px 7px;
//  background-color: #dedede;
//  font-weight: bold;
//  color: #000;
//  @include border-radius(10px);
//}
//.close_x {
//  display: inline-block;
//  width: 30px;
//  height: 30px;
//  //background-image: url('~svg/icon_close.svg');
//  //background-repeat: no-repeat;
//  //background-position: center;
//  text-indent: -99999px;
//  opacity: 0.5;
//}

//.type2 {// 점점점 세로 버튼
//display: inline-block;
//width: 20px;
//height: 20px;
//background-image: url('~images/icon_dot_vertical.svg');
//background-repeat: no-repeat;
//background-position: center;
//text-indent: -99999px;
//vertical-align: middle;
//}

.icon_close_bottom_modal {
  position: absolute;
  top: 5px;
  right: 0;
  background-image: url("~svg/icon_close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  border-width: 0;
  color: transparent;
  opacity: 0.3;
  &.is_title {
    top: 15px;
  }
}
</style>
