<template>
  <div class="menu_list_wrap">
    <ul class="menu_list">
      <li v-if="userId">
        <a href="javascript:void(0)" class="item wish" @click="goWish"> 찜 리스트 </a>
      </li>
      <li v-if="userId">
        <a href="javascript:void(0)" class="item store" @click="goFavoriteStore"> 단골상점 </a>
      </li>
      <li v-if="userId != null">
        <a href="javascript:void(0)" class="order_history" @click="goOrderHistory"> 주문내역 </a>
      </li>
      <li v-if="userId">
        <a href="javascript:void(0)" class="my_coupon" @click="goCoupon"> 보유쿠폰 </a>
      </li>
      <li v-if="appVersion">
        <a href="javascript:void(0)" class="item address" @click="goAddress"> 배송지 주소 변경 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="item earth" @click="goAllMarketList"> 전체 시장 보기 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="item cs" @click="goCs"> 고객지원 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="privacy_info" @click="goPrivacyInfo"> 개인정보처리방침 </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="terms_conditions" @click="goTermsConditions"> 이용약관 </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AllMenuList",
  props: {
    userId: String,
    closeFunc: Function,
  },
  data: function () {
    return {
      // userId: localStorage.getItem('userId'),
    };
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
  methods: {
    goWish: function () {
      this.closeFunc();
      this.$router.push({ name: "찜 리스트", params: { mypage: true } });
    },
    goFavoriteStore: function () {
      this.closeFunc();
      this.$router.push({ name: "단골상점" });
    },
    goOrderHistory: function () {
      this.closeFunc();
      this.$router.push({ name: "주문내역" });
    },
    goCoupon: function () {
      this.closeFunc();
      this.$router.push({ name: "보유쿠폰" });
    },
    goAddress: function () {
      this.closeFunc();
      this.$router.push({ name: "배송지설정" });
    },
    goAllMarketList: function () {
      this.closeFunc();
      this.$router.push({ name: "전체시장 보기" });
    },
    goCs: function () {
      this.closeFunc();
      this.$router.push({ name: "고객센터" });
    },
    goPrivacyInfo: function () {
      this.closeFunc();
      this.$router.push({ name: "개인정보처리방침" });
    },
    goTermsConditions: function () {
      this.closeFunc();
      this.$router.push({ name: "이용약관" });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_list_wrap {
  margin: 0px 16px;
}

.menu_list {
  display: block;

  position: absolute;
  top: 151px;
  left: 16px;
  right: 16px;
  bottom: 0;
  overflow: auto;

  //width: 100%;
  @include font(16, 6, 0, medium);
  vertical-align: middle;

  & > span {
    display: inline-block;
    vertical-align: top;
    font-style: $black;
  }

  .title {
    color: #000000;
  }

  li {
    &:last-child {
      .item {
        border: none;
      }
    }
  }
}

.item {
  display: block;
  width: 100%;
  padding: 15px 30px;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;

  &.inactive {
    opacity: 0.35;
  }

  &.direct {
    background-image: url("~svg/icon_direct_02.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.wish {
    background-image: url("~svg/icon_wish_product.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.store {
    background-image: url("~svg/icon_star_line.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.order {
    background-image: url("~svg/icon_order_history.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.address {
    background-image: url("~svg/icon_address_white.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.earth {
    background-image: url("~svg/icon_earth.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
  &.cs {
    background-image: url("~svg/icon_cs.svg"), url("~svg/icon_more_02.svg");
    background-size: unset;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: $black;
    &:hover {
      color: $black;
    }
  }
}

.order_history {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-image: url("~svg/icon_new_order_history.svg"), url("~svg/icon_more_02.svg");
  background-size: unset;
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;
  color: $black !important;
  &:hover {
    color: $black !important;
  }
}

.my_coupon {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-image: url("~svg/icon_new_coupon.svg"), url("~svg/icon_more_02.svg");
  background-size: unset;
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;
  color: $black !important;
  &:hover {
    color: $black !important;
  }
}

.customer_service {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-image: url("~svg/icon_new_cs.svg"), url("~svg/icon_more_02.svg");
  background-size: unset;
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;
  color: $black !important;
  &:hover {
    color: $black !important;
  }
}

.privacy_info {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-image: url("~svg/icon_new_privacy.svg"), url("~svg/icon_more_02.svg");
  background-size: unset;
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;
  color: $black !important;
  &:hover {
    color: $black !important;
  }
}

.terms_conditions {
  display: block;
  width: 100%;
  padding: 20px 30px;
  background-image: url("~svg/icon_new_terms.svg"), url("~svg/icon_more_02.svg");
  background-size: unset;
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  border-bottom: 1px solid #dcdcdc;
  vertical-align: middle;
  color: $black !important;
  &:hover {
    color: $black !important;
  }
}
</style>
