const state = {
  title: "",
  pageType: null,
  isLoading: false,
  changeTitle: true,

  marketId: "",
  latitude: null,
  longitude: null,

  categoryId: "",
  categorys: null,
  categoryWidth: null,
  storeList: [],
  customerMarginTop: "-270px", // listShowHide에서 css의 value를 동적으로 관리
  refProductCategory: null,
};

const mutations = {
  moveToApp: function (state, data) {
    // console.log('moveToApp ::: ');
    // console.log(data);
    this._vm.$callNative(data.type, data.sendData);
  },
  pageDestroy: function (state) {
    // console.log("pageDestroy ::: ");
    // state['title'] = '';
    state["isLoading"] = false;
    // state['changeTitle'] = true;
    // state['marketId'] = '';
    state["categorys"] = null;
    state["categoryWidth"] = null;
    state["storeList"] = [];
    // state['customerMarginTop'] = '-270px';
    // state['refProductCategory'] = null;
  },
  setIsLoading: function (state, boolean = true) {
    // console.log('setIsLoading ::: ');
    state["isLoading"] = boolean;
  },
  setTitle: function (state, data) {
    state["title"] = data;
    state["changeTitle"] = false;
  },
  initState: function (state, data) {
    // console.log(data);
    state["title"] = data.initData.title;
    state["marketId"] = data.initData.marketId;
    state["changeTitle"] = data.initData.changeTitle;
    if (data.initData.categorys.length > 0) {
      state["categorys"] = data.initData.categorys;
      state["storeList"] = data.initData.storeList;
    }
  },
  setCategoryWidth: function (state, categoryWidth) {
    // 카테고리 영역의 가로 사이즈
    // console.log('categoryWidth ::: ');
    // console.log(categoryWidth);
    state["categoryWidth"] = categoryWidth;
  },
  clickCategory: function (state, categoryCode) {
    // 카테고리 클릭시 활성화
    // console.log(categoryCode);
    this.commit("categoryProduct/setActiveCategory", categoryCode);
  },
  setActiveCategory: function (store, categoryCode) {
    state.categorys = state.categorys.map((category) => {
      if (Number(categoryCode) == category.categoryId) {
        category.active = true;
        if (state.changeTitle) {
          state.title = category.categoryName;
        }
      } else {
        category.active = false;
      }
      return category;
    });
  },

  listShowHide: async function (state, event) {
    var targetId = event.currentTarget.dataset.storeId;
    // customerMarginTop 적용하는 시간에 지연이 있어 먼저 적용한다.
    await state.storeList.map((store) => {
      if (Number(targetId) == store.storeId) {
        if (store.expand) {
          state.customerMarginTop = "-" + String(event.currentTarget.closest("li").offsetHeight - 66 - 15) + "px";
        }
      }
    });
    // 아래 주석코드 지연 시간이 있어 두번 실행한다.
    state.storeList = state.storeList.map((store) => {
      if (Number(targetId) == store.storeId) {
        // if (store.expand) {
        //   this.customerMarginTop = '-' + String(event.currentTarget.closest('li').offsetHeight - 66) + 'px';
        // }
        store.expand = !store.expand;
      }
      return store;
    });
  },

  goBack: function (state, vueContainer) {
    // this.commit('cartShip/testFunc');// 테스트 코드
    vueContainer.$closeView();
  },
  // testFunc: function () {// 테스트 코드
  //     console.log('testFunc');
  // },
};

const actions = {
  setRefProductCategory: function (store, refProductCategory) {
    // console.log(refProductCategory);
  },
  clickCategory: function (store, event) {
    // 카테고리 클릭시 활성화
    const targetCode = event.currentTarget.parentElement.dataset.code;
    // console.log(state.marketId);
    // console.log(targetCode);
    // var initData = {};

    var axiosUrlCategory = "/v3/market/delivery/product/market/" + String(state.marketId) + "/category/" + String(targetCode) + "/products/bd";
    if (state.marketId) {
      // 시장기준
      if (state.pageType == "today") {
        axiosUrlCategory = "/v3/event/new-merchandise/" + String(state.marketId) + "/categories/" + String(targetCode) + "/store/products/bd";
      } else if (state.pageType == "hot") {
        axiosUrlCategory = "/v3/event/hot-sale/market/" + String(state.marketId) + "/categories/" + String(targetCode) + "/store/products/bd";
      } else if (state.pageType == "special") {
        axiosUrlCategory = "/v3/market/delivery/product/market/" + String(state.marketId) + "/categories/" + String(targetCode) + "/specialties";
      }
    } else if (!state.marketId && state.latitude && state.longitude) {
      if (state.pageType == "today") {
        axiosUrlCategory =
          "/v3/event/new-merchandise/" + state.latitude + "/" + state.longitude + "/categories/" + String(targetCode) + "/store/products/bd";
      } else if (state.pageType == "hot") {
        axiosUrlCategory =
          "/v3/event/hot-sale/" + state.latitude + "/" + state.longitude + "/categories/" + String(targetCode) + "/store/products/bd";
      } else if (state.pageType == "special") {
        // 특산품 목록 API 적용이 되면 작업을 해야함
        // console.log("특산품 목록 API 적용이 되면 작업을 해야함");
        // axiosUrlCategory = '/v3/event/hot-sale/' + state.latitude + '/' + state.longitude + '/categories/' + String(data.categoryId) + '/store/products/bd';
      }
    }

    // if (state.pageType == 'special') {
    //     categoryDep = '/categories/';
    //     categoryType = '/specialties';
    //     axiosUrlCategory = '/v3/market/delivery/product/market/' + String(state.marketId) + categoryDep + String(targetCode) + categoryType;
    // }
    // else if (state.pageType == 'today') {
    //
    //     console.log(state);
    //     if (!state.marketId && state.latitude && state.longitude) {// 배송지 기준
    //         axiosUrlCategory = '/v3/event/new-merchandise/' + state.latitude + '/' + state.longitude + '/categories/' + String(targetCode) + '/store/products/bd';
    //     }
    // }

    this._vm.$axios1
      .get(axiosUrlCategory)
      .then((response) => {
        // console.log('response ++++++++++++++++++++++++ 111111111');

        if (response.data.responseCode == 2000) {
          // console.log('response ++++++++++++++++++++++++ 2');
          // console.log(response.data.result);

          state.storeList = response.data.result.storeList.map((store) => {
            // store.viewCartIcon = true;
            store.expand = true;
            store.productList.push({ moreContents: true });
            return store;
          });

          store.commit("clickCategory", targetCode);
          // store.commit('initState', {
          //     initData: initData,
          // });

          this._vm.isProgressing = false;
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });
  },
  callFavorite: function () {
    // 상단 단골가게 호출
    // console.log("callFavorite");
  },
  callSearch: function () {
    // 상단 검색
    // console.log("callSearch");
  },
  clickMore: function (store, data) {
    // 더보기 클릭
    if (data.btnId == "GO-CART") {
      // 장바구니 담기
      // console.log("장바구니 담기 ::: product.id ::: ");
    } else if (data.btnId == "PRODUCT") {
      // sendData type
      store.commit("moveToApp", {
        type: "moveToProduct",
        sendData: { productId: data.link },
      });
    } else if (data.btnId == "VIEWMORE") {
      store.commit("moveToApp", {
        type: "moveToStore",
        sendData: {
          marketId: data.link.marketId,
          storeId: data.link.storeId,
        },
      });
    }
    // console.log(data);
  },
  initState: async function (store, data) {
    state.pageType = data.pageType;
    var initData = {};

    ////////////////////////////////////////////////////////////////////////////////
    // 카테고리 목록 호출 : 시장 기준, 배송지 기준
    // 구분은 marketId 유무와 latitude, longitude 로 분리한다.
    var axiosUrl = "/v3/market/delivery/category/market/" + String(data.marketId) + "/categories/sale";
    if (data.marketId) {
      // 시장 기준
      if (state.pageType == "today") {
        // 오늘 들어 왔어요
        axiosUrl = "/v3/market/delivery/category/market/" + String(data.marketId) + "/categories/new-merchandise";
      } else if (state.pageType == "hot") {
        // 핫세일
        axiosUrl = "/v3/market/delivery/category/market/" + String(data.marketId) + "/categories/hot-sale";
      }
    } else if (!data.marketId && data.latitude && data.longitude) {
      // 배송지 기준
      state.latitude = data.latitude;
      state.longitude = data.longitude;

      // 일반 상품 목록을 위도, 경도로 가져오는 로직이 필요함 ---------------------------------------------------------------------
      if (state.pageType == "today") {
        // 오늘 들어 왔어요
        axiosUrl = "/v3/market/delivery/category/" + data.latitude + "/" + data.longitude + "/categories/new-merchandise";
      } else if (state.pageType == "hot") {
        // 핫세일
        axiosUrl = "/v3/market/delivery/category/" + data.latitude + "/" + data.longitude + "/categories/hot-sale";
      }
    }
    // console.log(axiosUrl);

    await this._vm.$axios1
      .get(axiosUrl)
      .then((response) => {
        // console.log(response);
        if (response.data.responseCode == 2000) {
          initData.categorys = response.data.result.saleCategoryList; // 화면에 정의할 카테고리 목록
          initData.changeTitle = false; // 타이틀 변경 조건

          // 상단 타이틀 정의
          if (data.pageType == "hot") {
            initData.title = "핫세일";
          } else if (data.pageType == "today") {
            initData.title = "오늘 들어 왔어요";
          } else if (data.pageType == "special") {
            initData.title = "특산품";
          } else {
            data.pageType = null; // 강제로 null 설정해서 혼동을 막는다. ---> 그외 케이스는 무조건 일반 카테고리 목록임
            initData.changeTitle = true; // 타이틀 변경할수 있도록 처리
          }

          // console.log('-----------------------------------');
          // console.log(response.data.result.saleCategoryList);

          if (!initData.categorys.length) {
            // console.log("카테고리 목록이 없습니다.");
            store.commit("initState", {
              initData: initData,
            });
            // return;
          } else {
            if (!data.categoryId) {
              // 카테고리 아이디가 없을때 첫번째 값으로 설정한다.
              // console.log("categoryId ::: " + initData.categorys);
              data.categoryId = initData.categorys[0].categoryId;
              initData.categorys[0].active = true;
            }
            // console.log(initData);
            // console.log(data);

            initData.categorys.map((category) => {
              if (category.categoryId == data.categoryId) {
                // 현재 활성화된 카테고리 정의
                category.active = true;
                if (!data.pageType) {
                  // 테마성 상품 목록이 아니면 카테고리 이름이 상단 타이틀명으로 변경한다.
                  initData.title = category.categoryName;
                }
              }
            });

            ////////////////////////////////////////////////////////////////////////////////
            // 상품 목록 호출 : 시장 기준, 배송지 기준
            var axiosUrlCategory;
            if (data.marketId) {
              // 시장기준
              initData.marketId = data.marketId;
              axiosUrlCategory =
                "/v3/market/delivery/product/market/" + String(data.marketId) + "/category/" + String(data.categoryId) + "/products/bd";
              if (data.pageType == "today") {
                axiosUrlCategory =
                  "/v3/event/new-merchandise/" + String(data.marketId) + "/categories/" + String(data.categoryId) + "/store/products/bd";
              } else if (data.pageType == "hot") {
                axiosUrlCategory =
                  "/v3/event/hot-sale/market/" + String(data.marketId) + "/categories/" + String(data.categoryId) + "/store/products/bd";
              } else if (data.pageType == "special") {
                axiosUrlCategory =
                  "/v3/market/delivery/product/market/" + String(data.marketId) + "/categories/" + String(data.categoryId) + "/specialties";
              }
            } else if (!data.marketId && data.latitude && data.longitude) {
              if (data.pageType == "today") {
                axiosUrlCategory =
                  "/v3/event/new-merchandise/" +
                  state.latitude +
                  "/" +
                  state.longitude +
                  "/categories/" +
                  String(data.categoryId) +
                  "/store/products/bd";
              } else if (data.pageType == "hot") {
                axiosUrlCategory =
                  "/v3/event/hot-sale/" + state.latitude + "/" + state.longitude + "/categories/" + String(data.categoryId) + "/store/products/bd";
              } else if (data.pageType == "special") {
                // 특산품 목록 API 적용이 되면 작업을 해야함
                // console.log("특산품 목록 API 적용이 되면 작업을 해야함");
                // axiosUrlCategory = '/v3/event/hot-sale/' + state.latitude + '/' + state.longitude + '/categories/' + String(data.categoryId) + '/store/products/bd';
              }
            }

            this._vm.$axios1
              .get(axiosUrlCategory)
              .then((response) => {
                if (response.data.responseCode == 2000) {
                  initData.storeList = response.data.result.storeList.map((store) => {
                    // store.viewCartIcon = true;
                    store.expand = true;
                    store.productList.push({ moreContents: true });
                    return store;
                  });

                  store.commit("initState", {
                    initData: initData,
                  });
                  // state.isLoading = true;
                } else {
                  alert(response.data.errorObject.errorMessage);
                }
                this._vm.isProgressing = false;
              })
              .catch(() => {
                this._vm.isProgressing = false;
              });
          }
        } else {
          alert(response.data.errorObject.errorMessage);
        }
        this._vm.isProgressing = false;
      })
      .catch(() => {
        this._vm.isProgressing = false;
      });

    // var initData = {
    //     storeList: [
    //         {
    //             marketId: 1,
    //             marketName: '제주도시장',
    //             storeId: 11,
    //             storeName: '우리밭상점',
    //             favoriteCount: 7777777,
    //             moreUrl: '더보기 경로',
    //             expand: true,
    //             viewCartIcon: true,// 썸네일 우측 상단에 좋아요 카운터 옆에 장바구니 아이콘 노출 여부
    //             products: [
    //                 {
    //                     id: 111,
    //                     name: '얼음 수박 1',
    //                     like: 9999,
    //                     discount: 5000,
    //                     price: 37900,
    //                     unitNumber: 1,
    //                     unitName: 'kg',
    //                     paymentTypes: ['온누리', '제로배달', '제로배달', '제로배달', '제로배달', '제로배달', '제로배달', '제로배달'],
    //                     originName: '국내산',
    //                     imageUrl: 'https://www.100ssd.co.kr/news/photo/202006/69518_49580_3038.jpg'
    //                 },
    //                 {
    //                     id: 112,
    //                     name: '야채',
    //                     like: 7777,
    //                     // discount: 1000,
    //                     price: 3000,
    //                     unitNumber: 100,
    //                     unitName: 'g',
    //                     paymentTypes: ['온누리'],
    //                     originName: '국내산',
    //                     imageUrl: 'https://www.100ssd.co.kr/news/photo/202006/69518_49580_3038.jpg'
    //                 },
    //                 {
    //                     id: 113,
    //                     name: '순대',
    //                     // discount: 1000,
    //                     price: 3000,
    //                     unitNumber: 100,
    //                     unitName: 'g',
    //                     // paymentTypes: [],
    //                     originName: '국내산',
    //                     imageUrl: 'https://www.100ssd.co.kr/news/photo/202006/69518_49580_3038.jpg'
    //                 },
    //                 {
    //                     moreContents: true
    //                 }
    //             ]
    //         },
    //         {
    //             marketId: 2,
    //             marketName: '제주도마켓',
    //             storeId: 22,
    //             storeName: '우리밭',
    //             favoriteCount: 7777777,
    //             likeCount: 7878787,
    //             moreUrl: '더보기 경로',
    //             expand: true,
    //             viewCartIcon: true,
    //             products: [
    //                 {
    //                     id: 222,
    //                     name: '얼음 수박 1',
    //                     // like: 7777,
    //                     discount: 5000,
    //                     price: 37900,
    //                     unitNumber: 1,
    //                     unitName: 'g',
    //                     paymentTypes: ['온누리', '제로배달', '제로배달'],
    //                     originName: '국내산',
    //                     imageUrl: 'https://www.100ssd.co.kr/news/photo/202006/69518_49580_3038.jpg'
    //                 },
    //                 {
    //                     moreContents: true
    //                 }
    //             ]
    //         }
    //     ]
    // };
  },
  goBack: function (store, vueCommon) {
    store.commit("goBack", vueCommon);
  },
};

const getters = {
  // title(state){
  //     return state.title;
  // },
  // markets(state){
  //     return state.markets;
  // },
  // count(state, getters){
  //     return Math.pow(state.count, getters.weight);
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
