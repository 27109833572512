<template>
  <div class="tab-wrap ignore_touch_x" :class="{ vertical_scroll: !appVersion }" ref="tabWrap">
    <ul class="tab_list" :class="addClass" :data-id="dataId">
      <li
        v-for="(tabItem, idx) in tabItems"
        :key="idx"
        class="tab"
        :class="{
          active: activeTabItem ? activeTabItem === tabItem.dataType : isPreviousData ? tabItem.isActive : idx === tabActiveIndex,
          sea_tab: tabItem.text === '수산',
          plan_tab: tabItem.text === '농산',
          brand_tab: tabItem.text === '친환경',
          main_tab: tabType === 'type1',
        }"
      >
        <a
          href="javascript:void(0);"
          :class="{
            active: activeTabItem ? activeTabItem === tabItem.dataType : isPreviousData ? tabItem.isActive : idx === tabActiveIndex,
            special_tab: tabItem.text === '단골시장' || tabItem.text === '수산',
            traditional_tab: tabItem.text === '단골시장',
            sea_tab: tabItem.text === '수산',
            specialOffer_tab: tabItem.text === '특가',
            plan_tab: tabItem.text === '농산',
            at_tab: tabItem.text === '농산' || tabItem.text === '단골시장',
            brand_tab: tabItem.text === '친환경',
            sea_tab_align: tabItem.text === '수산' || tabItem.text === '특가' || tabItem.text === '친환경' || tabItem.text === '이벤트',
          }"
          @click="isPreviousData ? onTabClick(tabItem, $event) : onClickTab(idx)"
        >
          {{ isPreviousData ? tabItem.text : tabItem.sidoName }}
        </a>
      </li>
    </ul>
    <!--    <div class="tab_bar" />-->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: `Tab`,
  props: {
    // 필수 props 입니다.
    tabItems: Array,
    tabActiveIndex: {
      type: Number,
      default: 0,
    },
    // 옵션 props 입니다.
    tabType: {
      type: String,
      default: "type1", // ["type1", "type2", "type3", "type4"]
    },
    addClass: String,
    tabScrollType: {
      type: String,
      default: "center", // ["center","normal"]
    },
    isPreviousData: {
      type: Boolean,
      default: false,
    },
    componentOrder: {
      type: Number,
      default: 0,
    },
    activeTabItem: String,
    // 예외 case 일 경우 특정 css 적용 가능
    tabWrapHeight: Number,
    tabWrapPaddingX: Number,
    tabWrapBackgroundColor: String,
    tabWrapBorderBottomStyle: String, // ['solid','none']
    tabInterval: Number,
    tabPaddingX: Number,
    activeTabTextColor: String,
    activeTabBackgroundColor: String,
    activeTabFontWeight: [Number, String], // ['normal','bold', 700 등등...]
    isActiveTabHasBackground: Boolean,
    isTabBarExist: Boolean,
    tabBarBackgroundColor: String,
    tabFontSize: Number,
    tabLineHeight: Number,
    tabLetterSpacing: Number,
    tabFontWeight: [Number, String],
    tabBorderColor: String,
    dataId: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      rootComponent: null,
      tabItemData: [],
      type1: {
        tabWrapHeight: 45,
        tabWrapPaddingX: 15,
        tabWrapBackgroundColor: "white",
        tabWrapBorderBottomStyle: "solid",
        tabInterval: 0,
        tabPaddingX: 15,
        isActiveTabHasBackground: false,
        activeTabTextColor: "#FF563F",
        activeTabBackgroundColor: "white",
        activeTabFontWeight: 600,
        isTabBarExist: true,
        tabBarBackgroundColor: "#FF563F",
        tabFontSize: 14,
        tabLineHeight: 22,
        tabLetterSpacing: 0,
        tabFontWeight: 400,
        tabBorderColor: "white",
      },
      type2: {
        tabWrapHeight: 50,
        tabWrapPaddingX: 15,
        tabWrapBackgroundColor: "#F9F9F9",
        tabWrapBorderBottomStyle: "solid",
        tabInterval: 5,
        tabPaddingX: 10,
        isActiveTabHasBackground: true,
        activeTabTextColor: "white",
        activeTabBackgroundColor: "#FF563F",
        activeTabFontWeight: "normal",
        isTabBarExist: false,
        tabBarBackgroundColor: "#FF563F",
        tabFontSize: 14,
        tabLineHeight: 23,
        tabLetterSpacing: 0,
        tabFontWeight: "normal",
        tabBorderColor: "white",
      },
      type3: {
        tabWrapHeight: 44,
        tabWrapPaddingX: 15,
        tabWrapBackgroundColor: "white",
        tabWrapBorderBottomStyle: "none",
        tabInterval: 5,
        tabPaddingX: 10,
        isActiveTabHasBackground: true,
        activeTabTextColor: "white",
        activeTabBackgroundColor: "#FF563F",
        activeTabFontWeight: "normal",
        isTabBarExist: false,
        tabBarBackgroundColor: "#FF563F",
        tabFontSize: 14,
        tabLineHeight: 22,
        tabLetterSpacing: 0,
        tabFontWeight: "normal",
        tabBorderColor: "white",
      },
      type4: {
        tabWrapHeight: 43,
        tabWrapPaddingX: 16,
        tabWrapBackgroundColor: "white",
        tabWrapBorderBottomStyle: "none",
        tabInterval: 5,
        tabPaddingX: 10,
        isActiveTabHasBackground: false,
        activeTabTextColor: "#FF563F",
        activeTabBackgroundColor: "#FF563F",
        activeTabFontWeight: "bold",
        isTabBarExist: false,
        tabBarBackgroundColor: "#FF563F",
        tabFontSize: 14,
        tabLineHeight: 22,
        tabLetterSpacing: 0,
        tabFontWeight: "normal",
        tabBorderColor: "#FF563F",
      },
      type5: {
        tabWrapHeight: 40,
        tabWrapPaddingX: 0,
        tabWrapBackgroundColor: "#fff",
        // tabWrapBorderBottomStyle: "solid",
        tabInterval: 0,
        tabPaddingX: 15,
        isActiveTabHasBackground: true,
        activeTabTextColor: "white",
        activeTabBackgroundColor: "#FF563F",
        activeTabFontWeight: "normal",
        isTabBarExist: false,
        tabBarBackgroundColor: "#FF563F",
        tabFontSize: 14,
        tabLineHeight: 40,
        tabLetterSpacing: 0,
        tabFontWeight: "normal",
        tabBorderColor: "#FF563F",

        tabWrapDisplay: "inline-block",
        tabWrapVerticalAlign: "middle",
        tabHeight: 40,
        tabBorderRadius: 0,
        tabDefaultBorderColor: "#dedede",
        defaultTabBackgroundColor: "#fff",
        tabTextColor: "#aaa",
      },
    };
  },
  methods: {
    onClickTab(switchTabIndex) {
      this.$emit("click", switchTabIndex);
    },
    onTabClick: function (tabItem, event) {
      // console.log("ON_TAB_CLICK");
      tabItem["event"] = event;
      if (this.data) {
        tabItem["data"] = this.data;
      }
      this.$emit("click", tabItem);
      const tabActiveIndex = this.tabItems.findIndex((item) => {
        return item.dataType === tabItem.dataType;
      });
    },
    changeTabBarWidth(switchTabIndex) {
      const isTabBarExist = this.isTabBarExist || this[this.tabType].isTabBarExist;
      if (isTabBarExist) {
        const tabEl = this.rootComponent.children[0].children[switchTabIndex];
        const tabBarEl = this.rootComponent.children[1];
        const isLastIndex = this.tabItems.length - 1 === switchTabIndex;
        tabBarEl.style.width = isLastIndex
          ? tabEl.clientWidth - (this.tabWrapPaddingX || this[this.tabType].tabWrapPaddingX) + "px"
          : tabEl.clientWidth + "px";
      }
    },
    changeTabBarLeft(switchTabIndex) {
      const isTabBarExist = this.isTabBarExist || this[this.tabType].isTabBarExist;
      if (isTabBarExist) {
        const tabEl = this.rootComponent.children[0].children[switchTabIndex];
        const tabBarEl = this.rootComponent.children[1];
        tabBarEl.style.left = tabEl.offsetLeft + "px";
      }
      // 원본
      /*
      const isTabBarExist = this.isTabBarExist || this[this.tabType].isTabBarExist;
      if (isTabBarExist) {
        let left = this.tabWrapPaddingX || this[this.tabType].tabWrapPaddingX;
        for (let i = 0; i < switchTabIndex; i++) {
          const tabEl = this.rootComponent.children[0].children[i];
          left += tabEl.clientWidth + (this.tabInterval || this[this.tabType].tabInterval);
          // left += tabEl.clientWidth + (this.tabInterval || this[this.tabType].tabInterval);
        }
        const tabBarEl = this.rootComponent.children[1];
        tabBarEl.style.left = left + "px";
      }
      */
    },
    changeTabWrapScrollLeft(switchTabIndex, behavior) {
      if (this.tabScrollType === "center") {
        let scrollLeft = this.tabWrapPaddingX || this[this.tabType].tabWrapPaddingX;
        for (let i = 0; i <= switchTabIndex; i++) {
          const isLastIndex = i === switchTabIndex;
          const tabEl = this.rootComponent.children[0].children[i];
          scrollLeft += isLastIndex
            ? tabEl?.clientWidth / 2 - window.innerWidth / 2
            : tabEl?.clientWidth + (this.tabInterval || this[this.tabType].tabInterval);
        }
        scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
        const tabWrapEl = this.rootComponent;
        tabWrapEl.scrollTo({ behavior: behavior, left: scrollLeft });
      } else if (this.tabScrollType === "normal") {
        const tabEl = this.rootComponent.children[0].children[switchTabIndex];
        tabEl.scrollIntoView({ behavior: behavior, block: "nearest" });
      }
    },
  },
  mounted() {
    this.rootComponent = this.$refs.tabWrap;
    const tabWrapEl = this.rootComponent;

    const tabWrapHeight = this.tabWrapHeight || this[this.tabType].tabWrapHeight;
    const tabWrapPaddingX = this.tabWrapPaddingX || this[this.tabType].tabWrapPaddingX;
    const tabWrapBackgroundColor = this.tabWrapBackgroundColor || this[this.tabType].tabWrapBackgroundColor;
    const tabWrapBorderBottomStyle = this.tabWrapBorderBottomStyle || this[this.tabType].tabWrapBorderBottomStyle;
    const tabInterval = this.tabInterval || this[this.tabType].tabInterval;
    const tabPaddingX = this.tabPaddingX || this[this.tabType].tabPaddingX;
    const activeTabTextColor = this.activeTabTextColor || this[this.tabType].activeTabTextColor;
    const isActiveTabHasBackground = this.isActiveTabHasBackground || this[this.tabType].isActiveTabHasBackground;
    const activeTabBackgroundColor = this.activeTabBackgroundColor || this[this.tabType].activeTabBackgroundColor;
    const activeTabFontWeight = this.activeTabFontWeight || this[this.tabType].activeTabFontWeight;
    const isTabBarExist = this.isTabBarExist || this[this.tabType].isTabBarExist;
    const tabBarBackgroundColor = this.tabBarBackgroundColor || this[this.tabType].tabBarBackgroundColor;
    const tabFontSize = this.tabFontSize || this[this.tabType].tabFontSize;
    const tabLineHeight = this.tabLineHeight || this[this.tabType].tabLineHeight;
    const tabLetterSpacing = this.tabLetterSpacing || this[this.tabType].tabLetterSpacing;
    const tabFontWeight = this.tabFontWeight || this[this.tabType].tabFontWeight;
    const tabBorderColor = this.tabBorderColor || this[this.tabType].tabBorderColor;

    const tabWrapDisplay = this[this.tabType].tabWrapDisplay ? this[this.tabType].tabWrapDisplay : "block";
    const tabWrapVerticalAlign = this[this.tabType].tabWrapVerticalAlign ? this[this.tabType].tabWrapVerticalAlign : "inherit";
    const tabHeight = this[this.tabType].tabHeight ? this[this.tabType].tabHeight : 24;
    const tabBorderRadius = this[this.tabType].tabBorderRadius === 0 || this[this.tabType].tabBorderRadius ? this[this.tabType].tabBorderRadius : 12;
    const tabDefaultBorderColor = this[this.tabType].tabDefaultBorderColor ? this[this.tabType].tabDefaultBorderColor : "transparent";
    const defaultTabBackgroundColor = this[this.tabType].defaultTabBackgroundColor ? this[this.tabType].defaultTabBackgroundColor : "white";
    const tabTextColor = this[this.tabType].tabTextColor ? this[this.tabType].tabTextColor : "#444";

    tabWrapEl.style.setProperty("--tabWrapHeight", tabWrapHeight + "px");
    tabWrapEl.style.setProperty("--tabWrapBackgroundColor", tabWrapBackgroundColor);
    tabWrapEl.style.setProperty("--tabWrapBorderBottomStyle", tabWrapBorderBottomStyle);
    tabWrapEl.style.setProperty("--tabInterval", tabInterval + "px");
    tabWrapEl.style.setProperty("--tabPaddingX", tabPaddingX + "px");
    tabWrapEl.style.setProperty("--activeTabTextColor", activeTabTextColor);
    tabWrapEl.style.setProperty("--activeTabBackgroundColor", isActiveTabHasBackground ? activeTabBackgroundColor : "white");
    tabWrapEl.style.setProperty("--activeTabFontWeight", activeTabFontWeight);
    tabWrapEl.style.setProperty("--tabBarDisplay", isTabBarExist ? "inline-block" : "none");
    tabWrapEl.style.setProperty("--tabBarBackgroundColor", tabBarBackgroundColor);
    tabWrapEl.style.setProperty("--tabFontSize", tabFontSize + "px");
    tabWrapEl.style.setProperty("--tabLineHeight", tabLineHeight + "px");
    tabWrapEl.style.setProperty("--tabLetterSpacing", tabLetterSpacing + "px");
    tabWrapEl.style.setProperty("--tabFontWeight", tabFontWeight);
    tabWrapEl.style.setProperty("--tabBorderColor", tabBorderColor);

    tabWrapEl.style.setProperty("--tabWrapDisplay", tabWrapDisplay);
    tabWrapEl.style.setProperty("--tabWrapVerticalAlign", tabWrapVerticalAlign);
    tabWrapEl.style.setProperty("--tabHeight", tabHeight + "px");
    tabWrapEl.style.setProperty("--tabBorderRadius", tabBorderRadius + "px");
    tabWrapEl.style.setProperty("--tabDefaultBorderColor", tabDefaultBorderColor);
    tabWrapEl.style.setProperty("--defaultTabBackgroundColor", defaultTabBackgroundColor);
    tabWrapEl.style.setProperty("--tabTextColor", tabTextColor);

    if (this.isPreviousData) {
      const tabActiveIndex = this.tabItems.findIndex((item) => {
        return item.isActive;
      });
    }
  },
  watch: {
    tabItems: function () {
      // console.log("tabItems watch :::::");
      // console.log(this.tabItems);
      this.tabItemData = this.tabItems;
    },
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
  created() {
    // console.log("****tabItems", this.tabItems);
  },
};
</script>

<style lang="scss" scoped>
.tab-wrap {
  display: var(--tabWrapDisplay);
  position: relative;
  overflow-x: auto;
  background-color: var(--tabWrapBackgroundColor);
  border: {
    style: var(--tabWrapBorderBottomStyle);
    color: $border-type1;
    width: 0 0 1px;
  }
  vertical-align: var(--tabWrapVerticalAlign);
  .tab_list {
    display: flex;
    height: var(--tabWrapHeight);

    .tab {
      position: relative;
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      margin-right: var(--tabInterval);

      &.active {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
        }

        &.main_tab:after {
          border-bottom: 3px solid $active-color;
        }

        &.sea_tab:after {
          border-bottom: 3px solid #0070d8;
        }

        &.specialOffer_tab:after {
          border-bottom: 3px solid #f46f2d;
        }

        &.plan_tab:after {
          border-bottom: 3px solid green;
        }

        &.brand_tab:after {
          border-bottom: 3px solid green;
        }
      }

      a {
        margin-bottom: -10px;

        height: var(--tabHeight);
        padding: 0 11px;
        // padding: 0 var(--tabPaddingX);
        color: var(--tabTextColor);
        border-radius: var(--tabBorderRadius);
        // 240614 폰트 변경
        font-size: 14px;
        line-height: var(--tabLineHeight);
        letter-spacing: var(--tabLetterSpacing);
        font-weight: var(--tabFontWeight);
        background-color: var(--defaultTabBackgroundColor);
        border: {
          style: solid;
          color: var(--tabDefaultBorderColor);
          width: 1px;
        }
        &.active {
          color: var(--activeTabTextColor);
          background-color: var(--activeTabBackgroundColor);
          font-weight: var(--activeTabFontWeight);
          border: {
            style: solid;
            color: var(--tabBorderColor);
            width: 1px;
          }
        }
      }

      .sea_tab {
        // &:before {
        //   content: "수산대전상품권";
        //   position: absolute;
        //   top: 7px;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   width: 60px;
        //   max-width: 60px;
        //   font-size: 9px;
        //   font-weight: bold;
        //   color: $last-active;
        //   white-space: nowrap;
        //   text-align: center;
        //   line-height: 1;
        // }
      }

      .specialOffer_tab {
        //&:before {
        //  content: '매주2번';
        //  position: absolute;
        //  top:7px;
        //  left: 50%;
        //  transform: translateX(-50%);
        //  width: 60px;
        //  max-width: 60px;
        //  font-size: 9px;
        //  font-weight: bold;
        //  color: $last-active;
        //  white-space : nowrap;
        //  text-align: center;
        //  line-height: 1;
        //}
      }

      .sea_tab.active {
        color: #0070d8;
      }

      .plan_tab.active {
        color: green;
      }

      .at_tab {
        margin-bottom: -10px;

        &:before {
          // content: "농할 30%";
          position: absolute;
          top: 7px;
          left: 50%;
          transform: translateX(-50%);
          width: 60px;
          font-size: 9px;
          font-weight: bold;
          color: $last-active;
          text-align: center;
          line-height: 1;
        }
      }

      .brand_tab.active {
        color: green;
      }
    }

    &.border_type1 {
      .tab {
        &:first-child {
          a {
            border-width: 1px 0 1px 1px;
            border-radius: 15px 0 0 15px;
          }
        }
        &:nth-child(2) {
          a {
            border-width: 1px 1px 1px 0;
            border-radius: 0 15px 15px 0;
          }
        }
      }
    }
  }
  .tab_bar {
    display: var(--tabBarDisplay);
    position: absolute;
    top: calc(var(--tabWrapHeight) - 2px);
    height: 2px;
    background-color: var(--tabBarBackgroundColor);
    transition: left 0.3s, width 0.3s;
  }

  .sea_tab_align {
    margin-bottom: -10px;
  }
}
</style>
