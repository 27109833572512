<template>
  <div class="bottom_menu_wrap">
    <ul class="inline_list">
      <li>
        <a href="javascript:void(0);" class="bottom_icon_all_menu" @click="clickBottom('allmenu')">전체메뉴</a>
      </li>
      <li v-if="appVersion">
        <a href="javascript:void(0);" class="bottom_icon_search" @click="clickBottom('search')">검색</a>
      </li>
      <li>
        <a v-if="appVersion" href="javascript:void(0);" class="bottom_icon_noljang mobile" @click="clickBottom('home')">홈</a>
        <a v-else href="javascript:void(0);" class="bottom_icon_noljang pc" @click="clickBottom('home')">홈</a>
      </li>
      <li v-if="appVersion">
        <div class="bottom_icon_order" @click="goAuthentication">주문내역</div>
      </li>
      <li v-else>
        <router-link to="/market-list" class="bottom_icon_search">시장검색</router-link>
      </li>
      <li v-if="appVersion">
        <a href="javascript:void(0);" class="bottom_icon_cart" @click="clickBottom('cart')">
          장바구니
          <span class="cart_total_count" v-if="userCartTotalCount">{{ userCartTotalCount }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Bottom",
  props: {
    clickBottom: Function,
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
      userCartTotalCount: (state) => state.userCartTotalCount,
      userId: (state) => state.userId, // 사용자 아이디
    }),
  },
  methods: {
    goAuthentication: function () {
      if (this.userId) {
        this.$router.push({ name: "주문내역" });
      } else {
        const _this = this;
        const modalName = "authentication";
        const compProps = {
          title: "휴대전화 본인인증",
          msgArr: ["휴대전화 본인 인증이 필요합니다."],
          btnArr: [
            {
              type: "type1 inverse",
              txt: "본인 인증 하기",
              btnId: "",
              btnData: {},
              btnCallback: function () {
                _this.$modal.hideAll();
                _this.$navigateTo("authentication");
              },
            },
          ],
      
          closeModal: function () {
            _this.$modal.hideAll();
          },
        };
    
        this.$store.dispatch(
          "showModal",
          {
            name: modalName,
            compProps: compProps,
            modalProps: {
              width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
              height: "auto",
            },
          },
          { root: true },
        );
      }
    }
  }
};
</script>
<style lang="scss">
.bottom_menu_wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding: 10px 0 0;
  background-color: $white;
  border: {
    style: solid;
    color: $gray-ultra-light;
    width: 1px 0 0;
  }
  z-index: 1;
  
  .inline_list {
    display: flex;
    justify-content: space-between;
    
    li {
      display: inline-block;
      min-width: 20%;
      
      a {
        display: block;
        min-width: 24px;
        height: 59px;
        text-align: center;
        text-indent: -9999px;
      }
    }
  }
}
</style>