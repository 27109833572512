<template>
  <div>
    <div class="search_area" v-if="disableSearch !== true">
      <SearchInput placeholder="시장 이름을 검색하세요" @search="search" :tabType="tabType" />
    </div>
    <ul class="market_info_wrap" :class="addClass">
    <li v-for="market in searchList === null ? directMarkets : searchList" :key="market.key" v-if="market.marketId !== 176 && market.marketId !== 177">
      <div class="contents_title type3" :class="{ none_more: market.deliveryType == 'NM' }">
        <a href="javascript:void(0);" :data-market-id="market.marketId" :data-market-name="market.marketName" @click="moveToMarket">
          <span class="title">{{ market.marketName }}</span>
        </a>
        <span v-if="market.distance" class="icon_view_map">{{ market.distance }}</span>
        <a
          href="javascript:void(0);"
          class="icon_more"
          :data-market-id="market.marketId"
          :data-market-name="market.marketName"
          @click="moveToMarket"
          v-if="market.deliveryType != 'NM'"
          >더보기</a
        >
      </div>
      <div class="market_address">
        <!--        <div class="accent_txt">주소</div>-->
        <div class="icon_view_map">
          <div class="address_txt">{{ market.marketFullyAddress }}</div>
          <p class="market_explain">{{ market.marketIntro }}</p>
        </div>
      </div>
      <div class="market_recent_order" v-if="market.recentBuyProducts && market.recentBuyProducts.length !== 0">
        <div class="boundary" />
        <p class="title">{{ market.marketName }} 최근주문</p>
        <div class="product_list type1">
          <ProductList :products="market.recentBuyProducts" isCartExist="true" />
        </div>
      </div>
    </li>
  </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductList from "@/components/new/ProductList";
import SearchInput from "@/components/new/SearchInput.vue";

export default {
  name: "MarketInfoList",
  props: {
    directMarkets: Array,
    tabType: String,
    disableSearch: Boolean,
    moveToMarket: Function,
    moveToMarketMap: Function,
    addClass: String,
  },
  components: {
    SearchInput,
    ProductList,
  },
  methods: {
    search(value) {
      if (value === '') {
        this.searchList = null;
      } else {
        this.searchList = this.directMarkets.filter(market => {
          return market.marketName?.includes(value) ||
            market.marketIntro?.includes(value) ||
            market.marketFullyAddress?.includes(value);
        });
      }
    }
  },
  data: function () {
    return {
      searchList: null
    };
  },
  computed: {
    ...mapState({
      appVersion: (state) => state.appVersion,
    }),
  },
  watch: {
    tabType() {
      this.searchList = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.market_recent_order {
  .boundary {
    margin: 15px;
    border: {
      style: solid;
      color: $gray-ultra-light;
      width: 1px 0 0 0;
    }
  }
  .title {
    padding: 0 15px;
    margin-bottom: 12px;
    @include font(15, 20, 0.37, bold);
  }
}
//.contents_title {
//  position: relative;
//  padding: 17px 15px 0;
//  &.type1 {
//    padding: 17px 15px 7px;
//  }
//  .title {
//    @include font(17, 20, 0.37, bold);
//    color: $black;
//  }
//  .icon_more {
//    position: absolute;
//    top: 20px;
//    right: 17px;
//  }
//  .icon_view_map {
//    display: inline-block;
//    min-height: 20px;
//    padding: 0 0 0 16px;
//    margin: 0 0 0 15px;
//    background-image: url("~svg/icon_view_map.svg");
//    background-repeat: no-repeat;
//    background-position: 0 3px;
//    font-family: 'gothic_a1_regular';
//    font-size: 15px;
//    color: $gray-normal;
//  }
//}
</style>
