<template>
  <div>
    <LoadingIndicator v-if="isProgressing"></LoadingIndicator>
    <TopHeader class="top_header" title="단골상점" @goBack="goBack" />
    <div class="contents">
      <div class="store_area" v-if="FavoritesStore && FavoritesStore.length > 0">
        <div class="store_list type1 vertical_list">
          <StoreList
            :stores="FavoritesStore"
            :regularBoolean="true"
          /><!-- :regularBoolean="true" 정의되면 클릭시 상점 메인 화면에서 단골로 표기된다. -->
        </div>
      </div>
      <div v-else class="empty_favorites_store">
        <div v-if="loadComplete">
          <strong class="accent_txt"><span>고객님,</span> <br />아직 단골맺기를 하신 상점이 없네요.</strong>
          <p class="txt">
            자주 이용하는 상점은 "단골맺기"를 해보세요. 단골맺기한 상점의 상품에는 자동으로 "단골" 스티커가 부착되어 편리하게 이용하실 수 있어요.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreList from "@/components/new/StoreList";
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "FavoritesStore",
  props: {},
  data: function () {
    return {
      loadComplete: false,
      FavoritesStore: null,
      userId: localStorage.getItem("userId"),
      // marketId: null,
      // marketName: null,
      // storeId: null,
      // storeName: null,
      // storePr: null,
      // regularYn: null,
      // favoriteCount: null,
      // storeOpenYn: null
    };
  },
  components: {
    LoadingIndicator,
    TopHeader,
    StoreList,
  },
  methods: {
    onBackPressed: function () {
      // this.$router.back();
      this.$navigateBack();
    },

    goBack: function () {
      // this.$router.back();
      this.$navigateBack();
    },

    getFavoritesStore: function () {
      this.isProgressing = true;
      // this.$axios1.get('v3/user/store/userId/' + this.userId + '/stores/regular')
      this.$axios1
        .get("/v3/user/store/userId/" + this.userId + "/stores/regular/sort/NEW")
        .then((response) => {
          if (response.data.responseCode == 2000) {
            this.FavoritesStore = response.data.result.serviceStoreList;
            this.isProgressing = false;
            this.loadComplete = true;
          } else {
            this.isProgressing = false;
            alert(response.data.errorObject.errorMessage);
            return false;
          }
        })
        .catch(() => {
          this.isProgressing = false;
          return false;
        });
    },
  },

  mounted() {
    window.onBackPressed = this.onBackPressed;
  },

  created() {
    this.getFavoritesStore();
  },
};
</script>

<style lang="scss" scoped>
.store_area {
  padding: 0 15px;
  background-color: $white;
}

.empty_favorites_store {
  padding: 28px 15px 40px 100px;
  background-image: url("~svg/icon_empty_favorites_store.svg");
  background-repeat: no-repeat;
  background-position: 20px 26px;

  .accent_txt {
    @include font(16, 22, 0);
    color: $black;
    span {
      font-size: 18px;
      //@include font(18, 24, 0);
    }
  }
  .txt {
    margin: 11px 0 0;
    @include font(14, 20, 0);
    color: $gray-normal;
  }
}
</style>
