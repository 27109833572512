<template>
  <!--  장바구니 상품 목록 -->
  <PanelWrap title="목록">
    <template v-slot:content>
      <div class="list_wrap">
        <ProductItem
          v-for="product in products"
          v-bind:key="product.compoundKey"
          :compoundKey="product.compoundKey"
          :productId="product.productId"
          :marketId="marketId"
          :marketName="product.marketName"
          :storeId="product.storeId"
          :storeName="product.storeName"
          :productImageUrl="product.productImageUrl"
          :quantity="product.quantity"
          :name="product.name"
          :realPrice="product.realPrice"
          :realDiscountPrice="product.realDiscountPrice"
          :optionGroups="product.optionGroups"
          :paymentTypes="product.paymentTypes"
          :originName="product.originName"
          :unitNumber="product.unitNumber"
          :unitName="product.unitName"
          :saleYn="product.saleYn"
        />
      </div>
    </template>
  </PanelWrap>
</template>

<script>
import ProductItem from "./ProductItem";
import PanelWrap from "./PanelWrap";
export default {
  name: "ProductList",
  props: {
    products: Array,
    marketId: Number,
  },
  data: function () {
    return {};
  },
  components: {
    PanelWrap,
    ProductItem,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.list_wrap {
  padding-top: 10px;
}
</style>
