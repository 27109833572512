<template>
  <div>
    <TopHeader class="top_header" title="이용약관" @goBack="goBack" :isCartVisible="false" />
    <div class="contents">
      <h2>놀장 서비스약관 (제 3자 제공 동의, 혜택 및 광고 수신동의 포함)</h2>
      <h3>제 1 장 총 칙</h3>
      <h4>제 1 조 (목적)</h4>
      <p>
        본 약관은 서비스 이용자가 주식회사 마켓트리(이하 “회사”라 합니다)가 제공하는 놀장 서비스(이하 “서비스”라고 합니다.)에 회원으로 가입하고 이를
        이용함에 있어 회사와 회원(본 약관에 동의하고 회원등록을 완료한 서비스 이용자를 말합니다.)의 권리•의무 및 책임사항을 규정함을 목적으로 합니다.
      </p>

      <h4>제 2 조 (용어)</h4>
      <p>
        본 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.<br />
        ①회원이란 당사 "놀장" 어플리케이션을 통해 본 약관 제5조에 정해진 가입 절차에 따라 가입하여 정상적으로 놀장 서비스를 이용할 수 있는 권한을 부여
        받은 고객을 말합니다.<br />
        ②놀장 포인트(이하 "포인트"라 함)란 당사의 "놀장 서비스"를 통해 적립 및 사용이 가능하도록 제공하는 포인트를 말합니다.<br />
        ③"놀장" 서비스(이하 "서비스"라 함)란 당사가 회원에게 제공하는 포인트 적립, 사용, 이벤트, 시장 세일 정보 등의 전반적인 고객 서비스 프로그램을
        말합니다. ④제휴사라 함은 "놀장" 서비스를 이용하는 전통시장 내의 상점 및 쿠폰을 발행하는 상점을 말합니다.
      </p>

      <h4>제3조 (약관의 효력 및 개정)</h4>
      <p>
        ① 본 약관의 내용은 당사의 서비스 어플리케이션 화면에 게시하거나 기타의 방법으로 회원에게 공지하고, 이에 동의한 회원이 서비스에 가입함으로써
        효력이 발생합니다.<br />
        ② 본 약관은 서비스에 가입된 회원을 포함하여 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.<br />
        ③본 약관은 수시로 개정 가능하며 약관을 개정하고자 할 경우 당사는 개정된 약관을 적용하고자 하는 날로부터 14일 이전에 약관이 개정된다는 사실과
        개정된 내용 등을 아래 규정된 방법 중 1가지 이상의 방법으로 회원에게 통지합니다.<br />
        다만, 회원에게 불리하게 약관내용이 변경되는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 고지합니다.<br />
        - e-mail 통보<br />
        - iOS/Android "놀장" 어플리케이션 푸쉬 알림<br />
        - 서면 통보 또는 전단고지<br />
        - 기타 회원 가입 시, 회원이 제공한 연락처 정보 등을 이용한 전화 안내 등의 방법<br />
        ④당사가 영업양도 등의 방법으로 본 약관에 따른 계약관계를 이전하고자 하는 경우, 그 사실을 회원에게 개별적으로 통지하고 회원이 이에 대하여
        30일간 이의를 제기하지 않고 계속해서 서비스를 이용하는 경우에는 이에 대하여 동의한 것으로 봅니다.<br />
        ⑤당사가 e-mail 통보 또는 서면 통보의 방법으로 본 약관이 개정된 사실 및 개정된 내용을 회원에게 고지하는 경우에는 회원이 제공한 e-mail 주소나
        주소지 중 가장 최근에 제공된 곳으로 통보하며 이 경우, 당사가 적법한 통보를 완료한 것으로 봅니다.<br />
        ⑥본 규정에 의하여 개정된 약관은 원칙적으로 그 효력 발생일로부터 장래를 향하여 유효합니다.<br />
        ⑦본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를 할 수 있으며 개정 된 약관의 효력발생일까지 탈퇴하지 않거나 별도로 당사에 이의를
        제기하지 않는 경우 변경된 약관에 동의한 것으로 봅니다.<br />
        ⑧본 규정의 통지방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적인 또는 전체적인 통지의 경우에 이를 준용합니다.
      </p>

      <h4>제4조 (서비스 개요)</h4>
      <p>
        ①당사와 제휴사가 본 약관에 정해진 바에 따라 회원에게 제공하는 서비스는 아래와 같습니다.<br />
        1) 적립 서비스<br />
        회원은 당사의 서비스 내에서 쿠폰 사용, 광고 시청, 정보 제공 등의 행위를 통해 포인트를 적립 받을 수 있습니다.<br />
        2) 사용 서비스<br />
        회원은 적립된 포인트를 사용하여 제휴사에서 상품을 구입할 수 있습니다. 단, 회원이 포인트를 사용 하기 위해서는 반드시 "놀장" 어플리케이션이
        회원의 스마트폰에 설치되어 있어야 합니다.<br />
        3) 기타 서비스<br />
        당사는 상기 각 호의 서비스 이외에도 추가적인 서비스를 개발하여 회원에게 제공할 수 있습니다.
      </p>

      <h4>제5조 (회원가입)</h4>
      <p>
        ①회원 가입은 "놀장" 어플리케이션을 통해 가능합니다. 회원으로 가입하고자 하는 고객은 당사의 서비스에서 모든 약관 동의 후 휴대폰 본인인증을 통해
        회원가입을 신청합니다.<br />
        ②고객으로부터 회원가입 신청이 있는 경우 당사는 자체 기준에 따른 심사를 거친 후 고객에게 회원 자격을 부여 할 수 있으며 회원 자격이 부여된
        고객은 당사로부터 가입 완료 공지를 받은 시점부터 회원으로서의 지위를 취득합니다.<br />
        ③회원은 회원자격을 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할 수 없습니다.
      </p>

      <h4>제6조 (회원탈퇴 및 자격상실)</h4>
      <p>
        ①회원은 언제든지 서면, e-mail, 전화, 기타 당사가 정하는 방법으로 회원탈퇴를 요청할 수 있으며, 당사는 회원탈퇴 요청시 이벤트 부정 이용 방지
        등을 위하여 탈퇴 요청일로부터 30일이 지난 후 탈퇴를 진행합니다. 동 기간 중 회원탈퇴를 취소할 경우 바로 취소되며 정상적으로 서비스 이용이
        가능합니다.<br />
        ②회원이 다음 각 호에 해당하는 경우, 당사는 당해 회원에 대한 통보로써 회원 자격을 상실시킬 수 있습니다. 단, 3)호의 경우에는 회원에 대한 통보
        없이 자격이 상실됩니다.<br />
        1) 회원 가입 신청 시, 허위 내용을 등록하거나 타인의 명의를 도용한 경우<br />
        2) 회원이 부정적립, 부정사용 등 포인트를 부정한 방법 또는 목적으로 이용한 경우<br />
        - 부정적립 : 회원이 실제로 쿠폰을 사용하지 않았음에도 불구하고 당해 회원에게 포인트가 적립된 경우를 말합니다. 그러나 시스템의 오류 등 회원의
        귀책사유에 의하지 않고 포인트가 적립된 경우나 당해 제휴사 영업점주의 동의가 있는 경우는 이에 제외되며, 단, 제휴사 및 그 피고용인이 회원인
        경우에는 영업점주의 동의가 있더라도 부정 적립에 해당됩니다.<br />
        - 부정적립된 포인트는 회원 자격 상실 통보와 함께 동시 소멸하고 이에 대하여 회원은 어떠한 권리도 주장할 수 없습니다. 또한 부정적립 포인트로
        상품을 구매하는 등의 부당이득이 발생한 경우 회원 당사자 또는 부정적립 동조자가 당사 또는 제휴사에 대한 민·형사상 책임을 집니다.<br />
        - 부정사용 : 당사나 제휴사 또는 회원의 동의 없이 타 회원의 포인트를 수단과 방법에 관계 없이 임의로 사용한 경우를 말합니다.<br />
        3) 회원이 사망한 경우<br />
        4) 다른 회원의 당사나 제휴사의 서비스 이용을 부당하게 방해하거나 그 정보를 도용하는 등 관련 법령을 위반하여 전자거래질서를 위협하는 경우<br />
        5) 당사나 제휴사의 서비스를 이용하여 법령 및 이 약관에서 금지하거나 공서양속에 반하는 행위를 하는 경우<br />
        6) 기타 본 약관이 규정하는 회원의 의무를 위반하는 경우<br />
        7) 기타 "놀장" 서비스 운영을 고의로 방해하는 행위를 하는 경우<br />
        8) 당사 및 제휴사의 합리적인 판단에 기하여 "놀장" 서비스의 제공을 거부할 필요가 있다고 인정할 경우.<br />
        ③본 조 제②항에 의해 회원 자격이 상실된 회원은 당사로부터 해당 사유발생에 대한 통지를 받은 날로부터 최대 30일 이내에 본인의 의견을 소명할 수
        있으며, 당사는 회원의 소명 내용을 심사하여 회원의 주장이 타당하다고 판단하는 경우 회원으로 하여금 계속하여 정상적인 서비스를 이용할 수 있도록
        합니다.<br />
        ④본 조 제①항에 의한 회원탈퇴 또는 제②항에 의한 회원자격상실이 확정되는 시점은 아래와 같습니다.<br />
        1) 회원의 탈퇴요청의 경우에는 당사가 회원탈퇴 처리의 완료 통보하는 시점에서 탈퇴가 완료 됩니다. 다만, 당사는 완료 통보일까지 30일의 유예 기간
        동안 기 적립된 포인트 정보를 소멸시키지 아니하고 저장하였다가, 회원이 별도의 절차를 거쳐 탈퇴 취소를 요청할 경우, 모든 포인트를 재사용토록
        하며, 탈퇴 취소 없이 유예기간이 지나면, 모든 포인트가 즉시 소멸됩니다.<br />
        2) 회원자격상실의 경우 통보일에 회원 자격상실이 확정됩니다. 단 사망으로 인한 자격상실의 경우에는 당사의 통보여부와 상관없이 회원 사망일에
        자격상실이 확정되며, 당해 회원에게 제공된 기 서비스와 관련된 권리나 의무 및 포인트는 당해 회원의 상속인에게 상속되지 않습니다.<br />
        ⑥본 조 제①항에 의한 회원탈퇴 또는 제②항에 의한 회원자격상실이 된 경우 회원 정보는 다음과 같이 처리 됩니다. 1) 탈퇴한 회원의 정보는 본 약관
        제15조에 규정하는 바에 따라 일정 기간 보유 후 삭제처리 됩니다. 2) 회원 자격이 상실 된 회원의 정보는 회원 자격상실 확정 후 "놀장" 서비스 부정
        이용 방지 및 타 회원의 추가적인 피해 방지를 위해 2년간 보유 하며 이 기간 동안 재가입 및 서비스가 불가 할 수도 있습니다.
      </p>

      <h4>제8조 (포인트 적립)</h4>
      <p>
        ①포인트는 회원이 당사의 서비스를 통해 광고 시청, 쿠폰 사용, 정보 제공 및 이벤트 등과 관련하여 획득한 포인트를 말합니다.<br />
        ②제휴사의 사정에 따라 일부 제휴사에서는 포인트 사용이 불가 할 수도 있으며, 이 경우 당사에 문의바랍니다.<br />
        ③포인트는 광고 시청 후, 쿠폰 사용 후 등 포인트 적립 행위가 완료되는 시점에서 자동 적립됩니다.<br />
        ④포인트 사용 및 기준은 당사와 제휴사의 내부 방침에 따라 임의로 변경될 수 있습니다.
      </p>

      <h4>제9조 (포인트 사용)</h4>
      <p>
        ①포인트를 사용하기 위해서는 회원 가입을 통해 회원의 지위를 취득해야 합니다. 적립된 포인트 사용 순서는 회원이 보유 중인 사용 가능한 포인트
        (이하 "가용포인트"라 함)에서 중 소멸 일자가 빠른 포인트부터 우선적으로 차감 됩니다. ②현재 회원이 포인트를 사용 하기 위해서는 잔여 사용 가능
        포인트가 최소 1P 이상이어야 하며 이 조건을 충족할 경우 각 제휴사에서 아래 사용 가능 단위에 맞춰 최소 사용 포인트 금액 제한 없이 사용
        가능합니다. ③회원은 포인트를 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할 수 없습니다.
      </p>

      <h4>제10조 (포인트 정정, 취소 및 소멸)</h4>
      <p>
        ①사용 및 적립된 포인트에 오류가 있을 경우 회원은 오류발생 시점부터 30일 이내에 당사나 해당 제휴사에 정정 신청을 하여야 하며, 당사나 해당
        제휴사는 회원의 정정 신청일로부터 최대 2개월 이내에 정정절차를 마쳐야 합니다. 단, 회원은 필요한 경우 포인트 적립 및 사용 오류를 증명할 수 있는
        객관적인 자료 (영수증 등)를 제시 하여야 합니다.<br />
        ②포인트는 최초 적립된 시점부터 12개월이 지난 시점의 해당 월 말일에 먼저 적립된 포인트부터 월 단위로 자동 소멸됩니다. 당사는 회원이 보유한
        포인트를 소멸예정일이 도래하여 소멸시키는 경우 포인트 소멸과 관련된 내용을 최소 15일전에 e-mail 등으로 발송합니다. 단, 특정 이벤트를 통해
        제공된 포인트는 이벤트 진행 시 포인트 유효기간 및 소멸일을 별도로 안내하고, 소멸 안내 e-mail 발송에서는 제외될 수 있습니다. 또한, 회원이 당사
        서비스 어플리케이션에서 해당 월 소멸 예정 포인트 확인이 가능하도록 조치합니다.<br />
        ③회원의 행위로 적립되는 포인트가 아닌 당사나 제휴사가 이벤트 등의 마케팅 활동으로 회원에게 추가로 제공한 포인트의 유효기간은 당사나 제휴사의
        정책에 따라 별도로 책정 될 수 있으며 이는 별도로 공지합니다.
      </p>

      <h4>제12조 (회원탈퇴 및 포인트 처리)</h4>
      <p>
        ①본 약관 제7조 제①항에 정해진 방법으로 탈퇴를 하고자 하는 회원이 잔여 포인트를 사용하고자 할 경우 회원탈퇴 요청일 전까지 적립된 가용 포인트를
        본 약관이 정하는 바에 따라 사용해야 합니다. ②탈퇴 전까지 사용하지 않은 포인트는 제7조 제⑤항 1)에 의거 일정한 유예기간 이후 자동으로
        소멸됩니다.
      </p>

      <h4>제13조 (개인정보 수집)</h4>
      <p>
        ①당사는 회원이 가입신청을 할 때 제공하거나 서비스 이용 시 발생하는 각종 개인정보 뿐만 아니라 사전에 회원으로부터 동의를 얻어 회원이 다양한
        서비스 이용 시 발생하는 정보를 수시로 수집 저장 할 수 있으며 이를 분석하여 회원에게 보다 나은 서비스를 제공하고 고객 지향적인 마케팅 활동을
        하기 위해 사용합니다. 개인정보수집 및 활용에 관한 자세한 사항은 당사 서비스 어플리케이션에 게재되어 있는 '개인정보 처리 방침'에 따릅니다. 이에
        관한 자세한 내용은 서비스 어플리케이션 약관내용을 참고하시기 바랍니다. ②당사는 개인정보를 수집하고자 하는 경우 회원으로부터 별도 양식에 따른
        '개인정보 처리 방침 (개인정보 수집 및 활용 동의서)'을 받고 있으며 회원은 언제든지 이를 철회할 수 있습니다. ③회원은 당사에 제공한 개인정보에
        변경사항이 있는 경우에는 지체 없이 당사에 그 변경사실을 통지하여야 하며, 이를 통지하지 않아 발생한 손해에 대하여 당사는 어떠한 책임도 지지
        않습니다.
      </p>

      <h4>제14조 (개인정보 이용과 제공)</h4>
      <p>
        ①당사는 수집된 개인정보를 ‘개인정보처리방침’에 기재된 자에게만 제공하고 기재된 목적 범위 내에서만 활용하며 수집한 개인정보를 당해 회원의
        동의없이‘개인정보처리방침'에 기재된 목적 범위를 넘어선 용도로 이용하거나 기재된 자 이외의 제3자에게 제공할 수 없습니다.<br />
        다만, 다음의 경우에는 예외로 합니다.<br />
        1) 배송업무상 배송업체에게 배송에 필요한 최소한의 회원의 정보(성명, 주소, 전화번호)를 알려주는 경우<br />
        2) 통계작성 연구 또는 마케팅 활동 등을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공되어 제공하는 경우<br />
        3) 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우<br />
        4) 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우<br />
        5) 당사 또는 제휴사와 관련하여 합병, 인수, 포괄적 영업양도 등이 있는 경우 합병 후 당사, 인수당사 및 영업양수인에 대하여 제공하는 경우
      </p>

      <h4>제15조 (개인정보 보유 및 이용기간)</h4>
      <p>
        ①당사는 수집된 회원의 개인정보는 수집 목적 또는 제공 받은 목적이 달성되면 지체 없이 파기함을 원칙으로 합니다. 다만, 다음 각호의 경우 일정기간
        동안 예외적으로 수집한 회원정보의 전부 또는 일부를 보관할 수 있습니다. 1) 고객요구사항 처리 목적 : 수집한 회원정보를 회원탈퇴 후 30일간 보유
        2) 기타 당사가 필요에 의해 별도로 동의를 득한 경우 : 별도 동의를 받은 범위 (회원정보 및 보유 기간) 내에서 보유 ②위 ①항에도 불구하고 상법 및
        ‘전자상거래 등에서 소비자보호에 관한 법률' 등 관련 법령의 규정에 의하여 다음과 같이 일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한
        기간 또는 다음 각 호의 기간 동안 회원정보를 보유할 수 있습니다. 1) 계약 또는 청약철회 등에 관한 기록 : 5년 2) 대금결제 및 재화등의 공급에 관한
        기록 : 5년 3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
      </p>

      <h4>제16조 (개인정보 보호를 위한 회원의 권리)</h4>
      <p>
        ①회원은 회원탈퇴를 통해 본 약관과 관련한 개인정보의 제공 및 활용과 관련한 동의를 철회할 수 있습니다. 그 경우에도 당사는 제15조에 한하여
        회원정보를 보유할 수 있습니다.<br />
        ②회원은 당사가 고지한 개인정보보호책임자에게 당사에 대하여 본인의 개인정보에 대한 열람을 요구할 수 있으며, 자신의 개인정보에 오류가 있는 경우
        서비스 어플리케이션을 통해 직접 처리 하거나 개인정보보호책임자에게 정정을 요구할 수 있습니다.<br />
        ③당사와 제휴사는 회원으로부터 본 조 제①항의 규정에 의한 동의철회 및 본 조 제②항의 규정에 의한 열람 및 정정요구를 받은 경우에는 지체 없이
        필요한 조치를 취하도록 합니다.
      </p>

      <h4>제17조 (개인정보 처리의 위탁)</h4>
      <p>
        당사는 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 업체에 위탁할 수 있으며
        위탁업체의 세부내용은 당사나 제휴사의 서비스 어플리케이션의 ‘개인정보처리방침’을 통해 확인할 수 있습니다.
      </p>

      <h4>제18조 (개인정보 처리)</h4>
      <p>
        ①당사는 수집된 회원의 개인정보 처리를 위해 각 사별 개인정보 보호책임자와 개인정보 보호담당자를 별도로 지정해 관리하며, 개인정보 보호책임자와
        개인정보 보호담당자의 성명과 연락처는 당사 및 제휴사의 서비스 어플리케이션 내 ‘개인정보처리방침’을 통해 확인할 수 있습니다.<br />
        ②당사는 회원이 안심하고 서비스를 이용할 수 있도록 회원의 개인정보 보호를 위한 보안시스템을 갖추도록 노력합니다.
      </p>

      <h4>제19조 (서비스의 중단)</h4>
      <p>
        ①당사는 다음 각 호에 해당하는 경우 서비스 제공을 전부 또는 일부를 제한하거나 중지할 수 있습니다.<br />
        1) 컴퓨터 등 정보통신설비의 점검, 보수, 교체 및 고장, 통신의 두절 등의 부득이한 사유가 발생한 경우<br />
        2) 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br />
        3) 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br />
        4) 서비스 제공업자와의 계약 종료 등과 같은 당사의 제반 사정으로 서비스를 유지할 수 없는 경우<br />
        5) 기타 국가 비상 사태 나 천재 지변 등 불가항력적 사유가 있는 경우<br />
        ②당사는 본 조 제 ① 항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 당사는 e-mail 또는 마켓트리 홈페이지
        또는 서비스 어플리케이션에 게시하는 방법으로 회원에게 서비스 중단사실을 알려드립니다.
      </p>

      <h4>제20조 (당사의 의무)</h4>
      <p>
        ① 당사는 본 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실 된 때에는 지체
        없이 이를 수리 복구하여야 합니다. 본 약관 제 19조 ①항에 근거하여 부득이한 경우에는 그 서비스를 일시 중단하거나 중지할 수 있습니다.<br />
        ②당사는 회원의 개인정보 수집 및 이용에 관련 하여 ‘개인정보 처리 방침’을 준수합니다.<br />
        ③당사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거처 처리하여야 합니다. 처리 시 일정
        기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.<br />
        ④당사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 당사의 고의나 과실에 기해 발생한 경우에 한하여 당사에서 책임을
        부담하며, 그 책임의 범위는 예측이 가능한 통상손해에 한합니다.<br />
        ⑤당사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
      </p>

      <h4>제21조 (회원의 의무)</h4>
      <p>
        ①회원은 다음 행위를 하여서는 안됩니다.<br />
        - 신청 또는 변경 시 허위내용의 등록 또는 타인의 정보 도용<br />
        - 당사에서 게시된 정보의 무단 변경, 삭제 등 훼손 행위<br />
        - 당사가 지정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br />
        - 기타 제3자의 저작권 등 지적재산권에 대한 침해하거나 제3자의 명예 손상 및 업무를 방해하는 행위
      </p>

      <h4>제22조 (광고게재 및 광고주와의 거래)</h4>
      <p>
        ①당사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고를 통한 수익으로부터 나옵니다. 서비스를 이용하고자 하는 자는 서비스 이용
        시 푸쉬 알림받는 광고에 대해 동의하는 것으로 간주됩니다. ②당사는 회원의 동의를 얻어 영리목적의 광고성 e-mail 및 SMS, DM를 발송하거나 별도의
        안내 권유 전화를 할 수 있으며 회원이 명백히 수신거부의사를 표시할 경우 당사는 이를 즉시 중지하여야 합니다. 당사는 재화/용역에 대하여「표시
        광고의 공정화에 관한 법률」제3조 소정의 부당한 표시 광고행위를 하지 않습니다. ③당사는 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나
        교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.
      </p>

      <h4>제23조 (서비스 이용책임)</h4>
      <p>
        회원은 당사와 제휴사의 적법한 권한 있는 자로부터 구체적으로 명시적인 사전 서면 승인을 받은 경우를 제외하고는 서비스를 이용하여 상품/서비스를
        판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌이 광고, 음란 사이트 등을 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고
        발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 당사가 책임을 지지 않습니다.
      </p>

      <h4>제24조 (서비스 이용 관련 분쟁 해결)</h4>
      <p>
        ①당사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해 별도의 고객센터를 설치/운영합니다.<br />
        ②당사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 적절한 절차를 거쳐 처리합니다. 단, 처리 시 일정
        기간이 소요될 경우 회원에게 그 사유와 처리 일정을 즉시 통보 합니다.<br />
        ③당사와 회원간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
      </p>

      <h4>제25조 (서비스 종료)</h4>
      <p>
        ①서비스를 종료하고자 할 경우, 당사는 서비스를 종료하고자 하는 날("서비스 종료일")로부터 3개월 이전에 본 약관 제3조에 규정된 통지방법을
        준용하여 회원에게 알려드립니다.<br />
        ②서비스 종료일 이후 회원은 당사 및 제휴사에서 포인트 적립 및 사용, 기타 서비스 혜택을 받지 못하며, 통지일 시점에서 기 적립된 포인트는 당사가
        별도 지정하는 날(이하 "포인트 소멸일")까지 본 약관과 당사가 지정하는 바에 따라 사용하여야 하며, 포인트 소멸일 이후 미사용 잔여분은 소멸됩니다.
      </p>

      <h4>제26조 (본 약관에서 정하지 않은 사항)</h4>
      <p>본 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령 및 상관례에 따릅니다</p>

      <h4>제27조 (면책조항)</h4>
      <p>
        ①당사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br />
        ②당사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br />
        ③당사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.<br />
        ④당사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.<br />
        ⑤당사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 회원의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.
      </p>

      <h4>제28조 (준거법 및 합의 관할)</h4>
      <p>
        ①본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 대한민국 관련법령 및 상관례에 따릅니다.<br />
        ②본 약관에 의한 서비스 이용과 관련한 제반 분쟁 및 소송은 회원의 주소를 관할하는 법원에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의
        전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
      </p>

      <p>이 약관은 2018년 7월 1일부터 시행합니다.</p>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/new/TopHeader";
import LoadingIndicator from "@/components/LoadingIndicator";
import { mapState, mapActions, mapMutations } from "vuex";
// import Modal from "@/components/new/Modal";

export default {
  name: "WithdrawService",
  beforeCreate() {
    this.isProgressing = false;

    this.$store.dispatch("withdraw/initState", {
      userId: localStorage.getItem("userId"),
    });
  },
  computed: {
    ...mapState({
      userId: (state) => state.withdraw.userId,
    }),
  },
  props: {},
  data: function () {
    return {};
  },
  components: {
    LoadingIndicator,
    TopHeader,
  },
  methods: {
    ...mapMutations("withdraw", []),
    ...mapActions("withdraw", ["updateWithdraw", "withdrawal"]),

    goWithdraw: function () {
      // console.log("withdraw");

      const _this = this;
      const modalName = "withdraw";
      const compProps = {
        title: "회원탈퇴",
        msgArr: ["탈퇴 신청 30일 후 회원탈퇴가 <br>완료되며 쿠폰 포인트 및 <br>모든 데이터가 삭제됩니다.<br>회원탈퇴를 진행하시겠습니까?"],
        btnArr: [
          {
            type: "type1 inverse",
            txt: "탈퇴취소",
            btnId: "",
            btnData: {},
            btnCallback: function () {
              _this.$modal.hideAll();
            },
          },
          {
            type: "type1",
            txt: "회원탈퇴",
            btnId: "WITHDRAW",
            btnData: {},
            btnCallback: function () {
              _this.$modal.hideAll();

              _this.isProgressing = true;

              const userId = localStorage.getItem("userId");
              const axiosUrl = "/v3/user/member/information/" + userId + "/withdrawal";
              _this.$axios1
                .delete(axiosUrl)
                .then((response) => {
                  if (response.data.responseCode == 2000) {
                    // console.log(response.data);
                    _this.$callNative("withdrawal", { userId: userId }, false);
                    _this.isProgressing = false;
                    // _this.$router.push({name: '놀장 메인'});
                  } else {
                    alert(response.data.errorObject.errorMessage);
                  }
                  _this.isProgressing = false;
                })
                .catch(() => {
                  _this.isProgressing = false;
                });
            },
          },
        ],

        closeModal: function () {
          this.$modal.hideAll();
        },
      };

      this.$store.dispatch(
        "showModal",
        {
          name: modalName,
          compProps: compProps,
          modalProps: {
            width: window.innerWidth > 360 ? 360 : window.innerWidth - 30,
            height: "auto",
          },
        },
        { root: true },
      );

      return;
    },

    goBack: function () {
      this.$router.push({
        name: "놀장 메인",
      });
    },

    onBackPressed: function () {
      // console.log("onBackPressed");
      if (document.querySelector("#modals-container .vm--container")) {
        // console.log('팝업이 존재함');
        this.$modal.hideAll();
      } else {
        // this.$router.back();
        this.$navigateBack();
      }
    },
  },
  mounted() {
    window.onBackPressed = this.onBackPressed;
  },
};
</script>

<style lang="scss" scoped>
.contents {
  padding: 20px 15px;
  background-color: $white;

  h2 {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
