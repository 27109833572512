<template>
  <div class="loading_wrap">
    <div>
      <md-progress-spinner class="md-accent" md-mode="indeterminate"></md-progress-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {},
};
</script>

<style lang="scss">
.loading_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 9999;
}

.loading_wrap > div {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
</style>
