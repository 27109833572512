<template>
  <div id="notification_list" class="list_wrap">
    <div class="notification_item" v-for="notice in notices" v-bind:key="notice.id" @click="$emit('onNoticeClick', notice)">
      <img v-bind:src="notice.bannerImage" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationList",
  props: {
    size: {
      type: Number,
      default: 30, // 조회된 전체알림 데이터
    },
    notices: Array,
  },
  data: function () {
    return {
      curPage: 0, // 현재까지 조회된 페이지
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.list_wrap {
  padding: 20px 0;
}

.list_wrap .notification_item {
  width: 100%;
  margin-bottom: 20px;

  & > img {
    width: 100%;
    min-height: 216px;
    border-radius: 20px;
    box-shadow: 0px 1px 0px $nj_shadow_gray;
  }
}
</style>
